import { memoize } from "lodash";
import { ISubstrateTotalCostResponse } from "./SubstrateTotalCost";

export enum PCMMetric {
    ItemReads = "itemReads",
    ItemWrites = "itemWrites",
    ItemQueries = "itemQueries",
    EBA = "ebaCount",
    TBA = "tbaCount",
    SWSS = "swssCount",
    ServiceInstance = "serviceInstanceCount",
    RequestsProcessed = "requestsProcessed",
    DSAPIRequestsCount = "dsapiRequestsCount",
    CFMSubmittedCount = "cfmSubmittedCount",
    ItemSize = "itemSize",
    KvCache = "kvCacheSizeGb",
    SDSFastStorage = "llcSizeGb"
}

export function getPcmMetricField(metric: PCMMetric): string {
    return metric;
}

export const getPcmMetricCostField = memoize((metric: PCMMetric): keyof ISubstrateTotalCostResponse => {
    if (metric !== PCMMetric.ServiceInstance) {
        return `${metric}Cost`;
    }
    return "serviceInstanceCost";
});

export function getPCMMetricString(metric: PCMMetric): string {
    if (!metric) return '';

    switch (metric.toLowerCase()) {
        case PCMMetric.ItemReads.toLowerCase():
            return "Item Reads";
        case PCMMetric.ItemWrites.toLowerCase():
            return "Item Writes";
        case PCMMetric.ItemQueries.toLowerCase():
            return "Item Queries";
        case PCMMetric.EBA.toLowerCase():
            return "EBA";
        case PCMMetric.TBA.toLowerCase():
            return "TBA";
        case PCMMetric.SWSS.toLowerCase():
            return "SWSS";
        case PCMMetric.ServiceInstance.toLowerCase():
            return "Service Instance";
        case PCMMetric.RequestsProcessed.toLowerCase():
            return "Requests Processed";
        case PCMMetric.DSAPIRequestsCount.toLowerCase():
            return "DSAPI Requests";
        case PCMMetric.CFMSubmittedCount.toLowerCase():
            return "CFM Submitted";
        case PCMMetric.ItemSize.toLowerCase():
            return "Item Size";
        case PCMMetric.KvCache.toLowerCase():
            return "KvCache";
        case PCMMetric.SDSFastStorage.toLowerCase():
            return "SDS Fast Storage";
        default:
            return '';
    }
}

export function getPCMMetricUnit(metric: PCMMetric): string {
    if (!metric) return '';

    if (metric.toLowerCase() == PCMMetric.ItemSize.toLowerCase() || metric.toLowerCase() == PCMMetric.SDSFastStorage.toLowerCase() || metric.toLowerCase() ==PCMMetric.KvCache.toLowerCase())
    {
        return " (GB)"
    }
    
    return '';
}

export function getPCMMetricDescription(metric: PCMMetric) : string {
    switch (metric) {
        case PCMMetric.ItemReads:
            return "Item Reads Cost";
        case PCMMetric.ItemWrites:
            return "Item Writes Cost";
        case PCMMetric.ItemQueries:
            return "Item Queries Cost";
        case PCMMetric.EBA:
            return "EBA Cost";
        case PCMMetric.TBA:
            return "TBA Cost";
        case PCMMetric.SWSS:
            return "SWSS Cost";
        case PCMMetric.ServiceInstance:
            return "Service Instance Cost";
        case PCMMetric.RequestsProcessed:
            return "Requests Processed";
        case PCMMetric.DSAPIRequestsCount:
            return "DSAPI Requests Cost";
        case PCMMetric.CFMSubmittedCount:
            return "CFM Submitted Cost";
        case PCMMetric.ItemSize:
            return "Item Size Cost";
        case PCMMetric.KvCache:
            return "KvCache Cost";
        case PCMMetric.SDSFastStorage:
            return "SDS Fast Storage Cost";
    }
}

export function getPCMMetricLearnMoreLink(metric: PCMMetric) : string | undefined {
    switch (metric) {
        case PCMMetric.EBA:
            return "EBA Cost";
        case PCMMetric.TBA:
            return "TBA Cost";
        case PCMMetric.SWSS:
            return "SWSS Cost";
        case PCMMetric.DSAPIRequestsCount:
            return "DSAPI Requests Cost";
        case PCMMetric.CFMSubmittedCount:
            return "CFM Submitted Cost";
        case PCMMetric.KvCache:
            return "KvCache Cost";
        case PCMMetric.SDSFastStorage:
            return "SDS Fast Storage Cost";
    }

    return undefined;
}

export function getPCMMetricColor(metric: PCMMetric) : string {
    switch (metric) {
        case PCMMetric.ItemReads:
            return "#0099BC";
        case PCMMetric.ItemWrites:
            return "#77004D";
        case PCMMetric.ItemQueries:
            return "#4F6BED";
        case PCMMetric.EBA:
            return "#AE8C00";
        case PCMMetric.TBA:
            return "#004E8C";
        case PCMMetric.SWSS:
            return "#881798";
        case PCMMetric.ServiceInstance:
            return "#E43BA6";
        case PCMMetric.RequestsProcessed:
            return "#0E7878";
        case PCMMetric.DSAPIRequestsCount:
            return "#8764B8";
        case PCMMetric.CFMSubmittedCount:
            return "#814E29";
        case PCMMetric.ItemSize:
            return "#00758F";
        case PCMMetric.KvCache:
            return "#77004D";
        case PCMMetric.SDSFastStorage:
            return "#4661D5";
    }
}