import { Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";
import PageHeader from "../common/PageHeader";
import styles from "./SubstratePlatform.less";
import { SubstratePlatformBackend } from "./SubstratePlatformBackend";

export const SubstratePlatform = () => {
    return (
        <div className={styles.pageHeader}>
            <PageHeader
                title="Substrate Costs"
                description="Welcome to the Substrate Platform Cost dashboard!"
                linkText="Learn More"
                link="https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/431435/Substrate-Platform-Cost-Model" />
            <Pivot>
                <PivotItem headerText="Backend"><SubstratePlatformBackend /></PivotItem>
            </Pivot>
        </div>
    );
}