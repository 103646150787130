import React from 'react';
import { CosmosSourceTypeEnum, CosmosSourceTypeName } from '../../models/CosmosModels';
import CosmosChart from './chart/CosmosChart';
import CosmosCostTable from './CosmosCostTable';
import styles from './CosmosView.less';
import PageHeader, { IPageHeaderProps } from '../common/PageHeader';
import { useGetBannersByTabAndDateQuery } from '../../hooks/useBannerQuery';
import { WarningBanner } from '../banner/WarningBanner';


const CosmosViewHeadersProps: Record<CosmosSourceTypeEnum, IPageHeaderProps> = {
    [CosmosSourceTypeEnum.All]: {
        title: "COSMOS BDP",
        description: "Cosmos is Microsoft's internal Big Data processing and storage service platform. Cosmos customers use the SCOPE as the processing language and store their data in the Cosmos Store.",
        link: "https://microsoft.sharepoint.com/teams/CosmosBDP/SitePages/Wiki_Capacity%20Chargeback%20Model.aspx",
        linkText: "Learn more about Cosmos Billing",
    },
    [CosmosSourceTypeEnum.Storage]: {
        title: "Storage",
        notification: "Storage pricing model (FY25/FY24): $35.4/(TB * Year)",
        description: "Storage allocations are based in TiB (1 TiB = 1024 GBs) of physical storage.  The minimum allocation unit is 1 TiB. Physical Storage is the actual amount of data stored on the disks in the Cosmos service. This is measured after compression and includes all replication copies.",
        link: "https://microsoft.sharepoint.com/teams/CosmosBDP/SitePages/Wiki_Capacity%20Chargeback%20Model.aspx",
        linkText: "Learn more about Cosmos Billing",
    },
    [CosmosSourceTypeEnum.Processing]: {
        title: "Processing",
        notification: 'Processing pricing model: (FY25)$175.2 (FY24)$178.68 /(Token * Year)',
        description: "Scope Tokens and (Baja) Table Server tokens are equivalent in terms of Capacity allocations, costs, and hardware resources. Scope (PN) Tokens are the primary processing resource for typical Batch processing jobs.  The minimum allocation unit is determined as 2 Tokens for any VC which processes jobs.  10 tokens are commonly the lowest requested allocation for newly on-boarded teams.",
        link: "https://microsoft.sharepoint.com/teams/CosmosBDP/SitePages/Wiki_Capacity%20Chargeback%20Model.aspx",
        linkText: "Learn more about Cosmos Billing",
    }
};

interface ICosmosViewProps {
    type: CosmosSourceTypeEnum
}

const CosmosView: React.FC<ICosmosViewProps> = (props) => {
    const { data: allBanners } = useGetBannersByTabAndDateQuery(CosmosSourceTypeName[props.type].toString());

    return (
        <div className={styles.cosmosView}>
            <PageHeader {...CosmosViewHeadersProps[props.type]} className={styles.header} />
            <div className={styles.Bannerheader} >
                {
                    Boolean(allBanners?.length) &&
                    allBanners?.map(item =>
                    (
                        <WarningBanner key={item.id} bannerItem={item} />
                    ))
                }
            </div>
           
            <CosmosChart type={props.type}/>
            <CosmosCostTable type={props.type} />
        </div>
    );
};

export default CosmosView;