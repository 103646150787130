import * as React from "react";

import {
    ContextualMenuItemType,
    DatePicker,
    DefaultButton,
    DirectionalHint,
    FocusZoneDirection,
    IButton,
    IContextualMenuProps,
    IStackTokens,
    PrimaryButton,
    Stack,
    mergeStyleSets,
} from "@fluentui/react";
import { LogComponent, LogElement, LogTarget } from "../../models/LogModel";

import moment from "moment";
import styles from "./DateRangePickerV2.less";
import { trackEventCallback } from "../../utils/AppInsights";
import { useBoolean } from "@fluentui/react-hooks";
import { useDateRange } from "../../hooks/useDateSelector";
import { useState } from "react";

const outlineStackTokens: IStackTokens = {
    childrenGap: 8,
};

interface IDateRangePickerProps {
    maxDateOffset?: number;
    isUsedInPanel?: boolean;
}

// Refer to https://o365exchange.visualstudio.com/O365%20Core/_git/Jaws?path=/sources/dev/Frontends/Jaws%20Portal/src/Components/TimeScale.tsx&version=GBmaster&_a=contents
const DateRangePickerV2: React.FC<IDateRangePickerProps> = ({maxDateOffset = 7, isUsedInPanel = false}) => {

    const { startDate, endDate, setDateRange } = useDateRange();
    const [localFilters, setLocalFilters] = useState({ startDate, endDate });

    const dateRangeButtonRef = React.useRef<IButton>(null);

    const [selectedDateRange, setSelectedDateRange] = useState<string>("Last 30 days");

    const [isDateRangeSelected, { setFalse: unselectDateRange, setTrue: selectDateRange }] = useBoolean(false);

    const errorMessage = React.useMemo(() => {
        return localFilters.startDate.isSameOrBefore(localFilters.endDate) ? undefined : "Invalid date range.";
    }, [localFilters]);

    const dateFormatter = React.useCallback((date?: Date) => {
        return moment(date).format("YYYY-MM-DD");
    }, []);

    const maxEndDate = React.useMemo(() => moment().subtract(maxDateOffset, "day").toDate(), [maxDateOffset]);

    const isLastNDays = (n: number) => {
        return moment(maxEndDate).diff(endDate, 'days') == 0 && startDate.diff(endDate, 'days') == -n;
    }

    React.useEffect(
        () => {
            if (isLastNDays(7)) {
                setSelectedDateRange("Last 7 Days");
            } else if (isLastNDays(14)) {
                setSelectedDateRange("Last 14 Days");
            } else if (isLastNDays(30)) {
                setSelectedDateRange("Last 30 Days");
            } else {
                setSelectedDateRange("" + startDate.format("YYYY/MM/DD") + " - " + endDate.format("YYYY/MM/DD"))
            }
        }, [startDate, endDate]
    )

    const applyLocalFilters = React.useCallback((filters?: {startDate: moment.Moment, endDate: moment.Moment}) => {
        if (!filters) {
            filters = localFilters;
        }

        if (filters.startDate > filters.endDate) {
            return;
        }
        const maxEndMoment = moment().subtract(maxDateOffset, "day");
        if (filters.endDate.isAfter(maxEndMoment, "date")) {
            filters.endDate = maxEndMoment;
        }
        setDateRange(filters.startDate, filters.endDate);
        trackEventCallback(LogComponent.PivotHeadPane, LogElement.TimeRange, "UTC Date", LogTarget.Button, {
            startDate: filters.startDate,
            endDate: filters.endDate,
        });
    }, [localFilters.startDate, localFilters.endDate, setLocalFilters, setDateRange]);

    React.useEffect(() => {
        const maxEndMoment = moment().subtract(maxDateOffset, "day");
        if (localFilters.endDate.isAfter(maxEndMoment, "date")) {
            localFilters.endDate = maxEndMoment;
        }
        setDateRange(localFilters.startDate, localFilters.endDate);
    }, [maxDateOffset]);

    const classNames = mergeStyleSets({
        menu: {
            textAlign: "center",
            maxWidth: 220,
            selectors: {
                ".ms-ContextualMenu-item": {
                    height: "auto",
                },
            },
        },
        item: {
            display: "inline-block",
            height: 40,
            padding: 10,
            lineHeight: 40,
            textAlign: "center",
            verticalAlign: "middle",
            marginBottom: 8,
            cursor: "pointer",
            // selectors: {
            //     "&:hover": {
            //         backgroundColor: "#eaeaea",
            //     },
            // },
        },
        categoriesList: {
            margin: 0,
            padding: 0,
            listStyleType: "none",
        },
        button: {
            width: "40%",
            margin: "2%",
        },
    });

    const onChangeStartDate = React.useCallback(
        (date?: Date | null) => {
            if (date) {
                setLocalFilters({ endDate: localFilters.endDate, startDate: moment(date) });
            }
        },
        [localFilters, setLocalFilters]
    );

    const onChangeEndDate = React.useCallback(
        (date?: Date | null) => {
            if (date) {
                setLocalFilters({ startDate: localFilters.startDate, endDate: moment(date) });
            }
        },
        [localFilters, setLocalFilters]
    );

    function renderCharmMenuItem(item: any, dismissMenu: () => void): JSX.Element {
        return (
            <>
                <DatePicker
                    label="Select a start date"
                    formatDate={dateFormatter}
                    highlightSelectedMonth
                    value={localFilters.startDate.toDate()}
                    onSelectDate={onChangeStartDate}
                    maxDate={maxEndDate}
                />
                <div className={styles.endDatePickerArea}>
                    <DatePicker
                        label="Select an end date"
                        formatDate={dateFormatter}
                        highlightSelectedMonth
                        value={localFilters.endDate.toDate()}
                        onSelectDate={onChangeEndDate}
                        maxDate={maxEndDate}
                        textField={{ errorMessage: errorMessage }}
                    />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "50%" }}>
                        <PrimaryButton onClick={() => {
                            applyLocalFilters();
                            if (!isLastNDays(14) && !isLastNDays(7) && !isLastNDays(30)) {
                                setSelectedDateRange("" + localFilters.startDate.format("YYYY/MM/DD") + " - " + localFilters.endDate.format("YYYY/MM/DD"))
                            }
                            selectDateRange()
                            dismissMenu()
                            dateRangeButtonRef.current?.dismissMenu()
                        }}>OK</PrimaryButton>
                    </div>
                    <div style={{ width: "50%" }}>
                        <DefaultButton style={{ float: "right" }} onClick={() => {
                            dismissMenu()
                            dateRangeButtonRef.current?.dismissMenu()
                            }}>
                            Cancel
                        </DefaultButton>
                    </div>
                </div>
            </>
        );
    }

    const menuProps = React.useMemo<IContextualMenuProps>(() => ({
        shouldFocusOnMount: true,
        directionalHint: DirectionalHint.bottomLeftEdge,
        className: classNames.menu,
        items: [
            {
                key: "last7days",
                text: "Last 7 days",
                onClick: () => {
                    selectDateRange()
                    const endDate = moment(maxEndDate);
                    applyLocalFilters({ startDate: moment(endDate).subtract(7, "days"), endDate });
                    setSelectedDateRange("Last 7 Days");
                },
            },
            {
                key: "last14days",
                text: "Last 14 days",
                onClick: () => {
                    selectDateRange()
                    const endDate = moment(maxEndDate);
                    applyLocalFilters({ startDate: moment(endDate).subtract(14, "days"), endDate });
                    setSelectedDateRange("Last 14 Days");
                },
            },
            {
                key: "last30days",
                text: "Last 30 days",
                onClick: () => {
                    selectDateRange()
                    const endDate = moment(maxEndDate);
                    applyLocalFilters({ startDate: moment(endDate).subtract(30, "days"), endDate });
                    setSelectedDateRange("Last 30 Days");
                },
            },
            { key: "divider_1", itemType: ContextualMenuItemType.Divider },
            {
                key: "custom",
                text: "Custom",
                className: "Charm-List",
                subMenuProps: {
                    focusZoneProps: {
                        direction: FocusZoneDirection.bidirectional,
                        checkForNoWrap: true,
                    },
                    items: [{ key: "bulb", text: "Lightbulb", onRender: renderCharmMenuItem, className: classNames.item }],
                },
            },
        ],
    }), [applyLocalFilters, classNames.item, classNames.menu, maxEndDate, renderCharmMenuItem]);

    return (
        <Stack horizontal verticalAlign="center" tokens={outlineStackTokens}>
            <DefaultButton menuProps={menuProps} style={{whiteSpace: "nowrap"}} className={styles.dateRangeButtonSelected} componentRef={dateRangeButtonRef}>
                <span>Date:&nbsp;</span>
                <span style={{fontWeight: 600}}>{selectedDateRange}</span>
            </DefaultButton>
        </Stack>
    );
};

export default DateRangePickerV2;
