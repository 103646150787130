import { Checkbox, DefaultButton, Link, Panel, PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { SubstrateEntityMetrics } from "../../models/SubstrateModels";
import styles from "./SelectedList.less";

interface ISelectedListProps {
    items: SubstrateEntityMetrics[];
    globalSelectedItems: Set<SubstrateEntityMetrics>;
    fixedItem: string;
    onChange: (selectedItems: Set<SubstrateEntityMetrics>) => void;
    onDismiss: () => void;
    title: string;
    description: string;
    linkText: string;
    isPanelOpen: boolean;
}

const SelectedList: React.FC<ISelectedListProps> = (props) => {
    const [selectedItems, setSelectedItems] = React.useState(props.globalSelectedItems);
    React.useEffect(() => {
        setSelectedItems(props.globalSelectedItems);
    }, [props.globalSelectedItems]);

    const onCheckboxChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        const content = ev?.currentTarget.title as SubstrateEntityMetrics;

        if (!content) {
            return;
        }

        if (checked) {
            setSelectedItems(new Set<SubstrateEntityMetrics>(selectedItems.add(content)));
        } else {
            selectedItems.delete(content);
            setSelectedItems(new Set<SubstrateEntityMetrics>(selectedItems));
        }
    }

    const onRenderPanelFooter = (): JSX.Element => (
        <div className={styles.footer}>
            <PrimaryButton text="Apply" className={styles.applyButton} onClick={() => props.onChange(selectedItems)} />
            <DefaultButton text="Cancel" onClick={props.onDismiss} />
        </div>
    )

    return (
        <Panel
            isBlocking={false}
            hasCloseButton={false}
            isOpen={props.isPanelOpen}
            styles={{main: styles.panelMain, content: styles.panelContent}}
            isFooterAtBottom
            onRenderFooter={onRenderPanelFooter}
        >
            <h3>{props.title}</h3>
            <p>{props.description}</p>
            <Link onClick={() => setSelectedItems(new Set<SubstrateEntityMetrics>(props.items))}>{props.linkText}</Link>
            <Checkbox disabled defaultChecked label={props.fixedItem} className={styles.checkbox} />
            {
                props.items.map(item => (
                    <Checkbox key={item} label={item} title={item} checked={selectedItems.has(item)} onChange={onCheckboxChange} className={styles.checkbox} />
                ))
            }
        </Panel>
    )
}

export default SelectedList;