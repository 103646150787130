// Return plan improve status
import * as React from 'react';

import { useGetPlanStatus } from '../../../../hooks/useEfficiencyTrackerProject';
import { EfficiencyStatusEnum } from '../../../../models/EfficiencyTracker';
import EfficiencyStatus from './EfficiencyStatus';
import { useEffect } from 'react';

interface IPlanImproveStatus {
    planId: number;

    onStatusGet: (status: EfficiencyStatusEnum) => void;
}

const PlanImproveStatus: React.FC<IPlanImproveStatus> = (props) => {
    const { planId, onStatusGet } = props;
    const { status: onTrack, isLoading, isError } = useGetPlanStatus(planId);

    useEffect(() => {
        if (isLoading) {
            onStatusGet(EfficiencyStatusEnum.Loading);
        } else if (isError) {
            onStatusGet(EfficiencyStatusEnum.Error);
        } else if (onTrack) {
            onStatusGet(EfficiencyStatusEnum.OnTrack);
        } else {
            onStatusGet(EfficiencyStatusEnum.Delayed);
        }
    }, [isLoading, isError]);
    return (
        !isLoading ?
            (<>
                {isError ?
                    (<EfficiencyStatus Status={EfficiencyStatusEnum.Error} />) :
                    (<EfficiencyStatus Status={onTrack ? EfficiencyStatusEnum.OnTrack : EfficiencyStatusEnum.Delayed} />)
                }
            </>) :
            (<EfficiencyStatus Status={EfficiencyStatusEnum.Loading} />)
    );
};

export default PlanImproveStatus;
