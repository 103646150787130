export enum ActionTypes {
    DeleteDialogAction,
    GetAllSubViewDataAction,
    ModifySubstrateCostTableAction,
    SaveViewFaileDialogAction,
    SaveViewSuccessDialogAction,
    EditViewSuccessDialogAction,
    ServiceOrderAction,
    ServiceTreeRequestAction,
    SearchKeyChangeAction,
    SearchByIdAction,
    ShareViewDialogAction,
    ToggleSearchPanelAction,
    ToggleSubViewPanelAction,
    UpdateCustomDataAction,
    UpdateSavedViewAction,
    UpdateSearchBoxRef,
    UpdateSearchFiltersNextAction,
    UpdateServiceTreePanelRef,
    UpdateSubstrateCostByServiceAction,
    UpdateSubstrateDataAction,
    UpdateSubstrateOverviewAction,
    UpdateSubstrateMOMAction,
    UpdateSubtrateRingAction,
    UpdateSubstrateScenarioCostAction,
    UpdateSubstrateTopComponentsAction,
    UpdateSubstrateTotalAction,
    UpdateTransactionTabAction,
    UpdateSsdTabAction,
    UpdateSubstrateItemRWQCostAction,
    UpdateSubstrateServiceInstanceCostAction,
    UpdateSubstrateRequestsProcessedCostAction,
    UpdateSubstrateAssistantCostAction,
    UpdateSubstrateDSAPICostAction,
    UpdateSubstrateCFMCostAction,
    UpdateSubstrateLLCCostAction,
    UpdateSubstrateItemSizeCostAction,
    UpdateSubstrateKVCacheCostAction,
    RingChangeAction,
    UpdateSubstrateV2RingsAction,
}

export enum RequestStatus {
    NotStarted,
    Requested,
    Succeed,
    Failed,
}
