export const partnerMetricNames: Record<string, string> = {
    'app-core-cogs': 'App Core COGS',
    'p99-utilization': 'Daily P99 Utilization',
    'daily-app-request-cores': 'Daily App Request Cores',
    'daily-app-limit-cores': 'Daily App Limit Cores',
    'daily-app-empty-cores': 'Daily App Empty Cores'
}

export const partnerMetrics = [
    "app-core-cogs",
    "p99-utilization",
    "daily-app-request-cores",
    "daily-app-limit-cores",
    "daily-app-empty-cores"
];

export const leadershipMetricNames: Record<string, string> = {
    'total-cosmic-cost': 'Total Cosmic Costs',
    'non-vm-cost': 'Non VM Costs',
    'platform-vm-cost': 'Platform VM Costs',
    'partner-vm-cost': 'Partner VM Costs',
    'daily-cosmic-cost': 'Daily Cosmic COGS',
    'daily-cosmic-cores': 'Daily Cosmic Cores',
    'overhead-cores': 'Overhead Cores',
    'app-cores': 'App Cores',
    'overhead-with-rightsizing': 'Overhead with Rightsizing',
    'p99-app-core-utilization': 'Daily P99 Utilization',
}

export const leadershipMetrics = [
    "total-cosmic-cost",
    "non-vm-cost",
    "platform-vm-cost",
    "partner-vm-cost",
    "daily-cosmic-cost",
    "daily-cosmic-cores",
    "overhead-cores",
    "app-cores",
    "overhead-with-rightsizing",
    "p99-app-core-utilization",
];