
export const metricDescriptions : Record<string, string> = {
    "Service": "The service name is from Service Tree",
    "Scenario Tag": "Add the Scenario Tag to your HTTP request headers to filter your COGS by scenarios of your definition.",
    "Item Reads Cost" : "Every Item Read incurs CPU and DRAM in REST/XSO/Store/ESE, and HDD read IO to read off-page data.",
    "Item Writes Cost" : "Every Item Write (create, update, delete) incurs CPU and DRAM in XSO/Store/ESE, and both read and write HDD IO, and WAN network bandwidth due to geo-replication. ",
    "Item Queries Cost" : "Item Queries incur CPU and DRAM in REST/XSO/Store/ESE, but avoid HDD read IO by limiting to only reading on-page data that is expected to come from SSD. Item Queries is charged by Total RowsReturned instead of the number of queries you performed. This is to encourage more efficient queries with less access to unnecessary items.",
    "Item Size Cost": "Item Size represents the major datasets on Substrate SSD storage. It is charged by the size of storage. You may contact Store team to learn more if you want to add/remove your storage from SSD.",
    "EBA Cost" : "Event-based assistants. An item processor processes individual items as they arrive in the Substrate. It is also known as an item event processor, and it is an event-based assistant (EBA) that receives an item when an event occurs to that item that matches your event filter.",
    "TBA Cost" : "Time-based assistants. A mailbox processor does batch processing of individual mailboxes. The processor receives mailbox information spread across a defined interval",
    "SWSS Cost" : "Substrate Work Scheduling Service (SWSS) is a Substrate service that helps schedule work asynchronously in a given time period. It provides APIs that allow you to create, get, and delete SWSS jobs. The specified endpoint will be called back at the job's scheduled time based on the server resource availability.",
    "Service Instance Cost" : "The number of instances that your process is running on. Feature owner can run with fewer instances of their Service and thereby reducing DRAM and CPU consumption. ",
    "CFM Submitted Cost" : "It is a way for a system to queue up control messages that need to be delivered to other shards or service endpoints. Substrate Message Bus is great for handling scenarios such as queuing (capacitance), fanout, retry, etc.",
    "DSAPI Requests Cost" : "The Directory Services (DS) API exposes many of the Exchange APIs, thus allowing you to read user, group, and tenant-level information (that is, profile-like data) from the Exchange Directory instead of from the Azure Active Directory (AAD). DSAPI includes all the groups that exist in AAD.",
    "KvCache Cost": "A simple key-value cache available to Model B2 services for caching data that is anchored-to Substrate data-shards. KV Cache is persisted in SSD and offers Substrate's fundamental features including HA/DR, at-rest data encryption, physical data security, geo residency, etc.",
    "SDS Fast Storage Cost": "Substrate enables you to store data in SSD instead of HDD for low latency access. SSD provides low single digit milliseconds read access. You can mark any SDS collection with the PreferFastStorage configuration to replicate the data to SSD. Reads are then served from SSD instead of HDD. ",
    "Item Reads Count": "The usage of Item Reads",
    "Item Writes Count": "The usage of Item Writes",
    "Item Queries Count": "The usage of Item Queries",
    "Peak Hour": "Peak hour is defined by first picking the peak 3-hour window during the day and returns the peak 1 hour of that 3-hour windows at forest level.",
    "Requests Processed": "Vdir requests",
    "Total Carbon Emission": "In JAWS we want to empower every engineer, PM, and leader across E+D to understand the emissions associated with the services they own. That is why we have invested in creating views that enable carbon costs to be easily visible for service owners. Our methodolgy looks at emissions across 2 scopes and apportions them to services based on their relative usage. We make it a priority to be grounded in the best available math and science and thus will continue to iterate and refine the methodolgy moving forward.",
    "Carbon Scope2 Emission": "Scope 2 emissions are indirect emissions that come from the production of the electricity or heat we use. For Microsoft, this can be thought of as the emissions from all of the power, heating, and cooling we have across our campuses and data centers.",
    "Carbon Scope3 Emission": "Scope 3 emissions are the indirect emissions that come from all of the other activities in which we are engaged. This includes emissions associated with manufacturing the products we buy. For Microsoft, a large portion of this comes from the full lifecycle of our data centers. Given the breadth of scope 3 emissions, they are often larger than scope 2.",
}

export const metricLinks : Record<string, string> = {
    "Item Size" : "https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/113/Promoted-Properties-101",
    "EBA Cost" : "https://docs.substrate.microsoft.net/docs/Develop-apps/Apps-and-SDK-overview/About-item-processor.html?uid=about-item-processor",
    "TBA Cost" : "https://docs.substrate.microsoft.net/docs/Develop-apps/Apps-and-SDK-overview/About-mailbox-processor.html?uid=about-mailbox-processor",
    "SWSS Cost" : "https://docs.substrate.microsoft.net/docs/Substrate-APIs-and-services/Substrate-Work-Scheduling-Service-(SWSS)/Substrate-Work-Scheduling-Service-(SWSS).html?uid=substrate-work-scheduling-service-%28swss%29",
    "CFM Submitted Cost" : "https://docs.substrate.microsoft.net/docs/Substrate-APIs-and-services/Substrate-Bus/Substrate-Bus.html?uid=substrate-bus",
    "DSAPI Requests Cost" : "https://docs.substrate.microsoft.net/docs/Substrate-APIs-and-services/Directory-Services-API-(DS-API)/DS-API-overview.html?uid=ds-api-overview",
    "KvCache Cost": "https://docs.substrate.microsoft.net/docs/Performance/Latency/Caching/KV-Cache/Get-started-with-KV-Cache.html?uid=get-started-with-kv-cache&toc=%2Fdocs%2FPerformance%2Ftoc.html",
    "SDS Fast Storage Cost": "https://docs.substrate.microsoft.net/docs/Performance/Latency/Get-started-with-SDS-Fast-Storage.html?uid=get-started-with-sds-fast-storage&toc=%2Fdocs%2FPerformance%2Ftoc.html",
}