import * as React from 'react';
import { StackedBarChart, IChartDataPoint, IChartProps } from '@fluentui/react-charting';
import { MemoryRedundancySummary } from '../../../models/MemoryRedundancyModels';
import CommonConstants from '../../../models/constants/CommonConstants';

interface IMRBarChartProps {
    record: Record<string, MemoryRedundancySummary>;
    metrics: string[];
}

export const MemoryRedundancyBarChart: React.FunctionComponent<IMRBarChartProps> = (props) => {
    const colors: Record<string, string> = {};

    for (const metric of props.metrics) {
        colors[metric] = CommonConstants.DefaultColors[props.metrics.indexOf(metric)];
    }
    const dataPoints: IChartDataPoint[] = [];
    for (const metric of props.metrics) {
        if (props.record[metric] && props.record[metric].cost > 0)
        {
            dataPoints.push({ legend: metric, data: props.record[metric].cost, color: colors[metric] });
        }
    }
    
    const chartData: IChartProps = {
        chartData: dataPoints,
    };
    return <StackedBarChart data={chartData} />;
};

