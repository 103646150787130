import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ICalloutProps, TeachingBubble } from "@fluentui/react";
import { trackEventCallback } from '../../utils/AppInsights';
import { LogComponent, LogElement, LogTarget } from '../../models/LogModel';

const TeachingBubbleLocalStorageKey = 'tb';

function getTeachingBubbleLocalStorage() {
    let data: Record<string, number> = {};

    try {
        data = JSON.parse(localStorage.getItem(TeachingBubbleLocalStorageKey) || '') || {};
    } catch (e) {
        //
    }

    return data;
}

function isTeachingBubblesShowed(name: string) : boolean {
    return name in getTeachingBubbleLocalStorage();
}

function setTeachingBubblesShowed(name: string) {
    const data = getTeachingBubbleLocalStorage();

    data[name] = Date.now();

    localStorage.setItem(TeachingBubbleLocalStorageKey, JSON.stringify(data));
}

export interface ITeachingBubbleStepOption {
    target: string;
    title: string;
    description: string;
    calloutProps?: ICalloutProps;
}

export interface ITeachingBubbleStepsProps {
    name: string;
    steps: ITeachingBubbleStepOption[];
}

const styles = {
    footer: {
        [`& > .ms-StackItem`]: {
            display: 'flex',
            flexDirection: 'row-reverse',
        },
        [`& > .ms-StackItem > .ms-Button:not(:first-child)`]: {
            margin: '0 15px 0 0',
        },
    }
};

const TeachingBubbleSteps: React.FC<ITeachingBubbleStepsProps> = (props) => {
    const [curStepIndex, setCurStepIndex] = useState(0);
    const shouldDisplay = useRef(!isTeachingBubblesShowed(props.name));
    const totalSteps = props.steps.length;

    useEffect(() => {
        if (shouldDisplay.current) {
            setTeachingBubblesShowed(props.name);
        }
    }, []);

    const handlePreviousButtonClick = () => {
        trackEventCallback(LogComponent.TeachingBubbles, LogElement.Previous, 'Previous', LogTarget.Button, { name: props.name, curStep: props.steps[curStepIndex]?.title });

        setCurStepIndex(curStepIndex - 1);
    };

    const handleNextButtionClick = () => {
        if (curStepIndex == totalSteps - 1) {
            trackEventCallback(LogComponent.TeachingBubbles, LogElement.Finish, 'Finish', LogTarget.Button, { name: props.name, curStep: props.steps[curStepIndex]?.title });
        } else {
            trackEventCallback(LogComponent.TeachingBubbles, LogElement.Next, 'Next', LogTarget.Button, { name: props.name, curStep: props.steps[curStepIndex]?.title });
        }

        setCurStepIndex(curStepIndex + 1);
    };

    if (!shouldDisplay.current || curStepIndex >= totalSteps) return null;

    const primaryButtonText = totalSteps === 1 ? 'Close' : (curStepIndex == totalSteps - 1 ? 'Finish' : 'Next');

    return ReactDOM.createPortal(
        <>
            {props.steps.map((step, index) => curStepIndex === index && (
                <TeachingBubble
                    key={step.target}
                    styles={styles}
                    target={step.target}
                    headline={step.title}
                    calloutProps={step.calloutProps}
                    secondaryButtonProps={index > 0 ? ({
                        children: 'Previous',
                        onClick: handlePreviousButtonClick,
                    }) : undefined}
                    primaryButtonProps={{
                        children: primaryButtonText,
                        onClick: handleNextButtionClick,
                    }}
                    footerContent={totalSteps > 1 ? `${curStepIndex + 1} of ${totalSteps}` : ''}
                >
                    {step.description}
                </TeachingBubble>
            ))}
        </>
    , document.body);
};

export default TeachingBubbleSteps;
