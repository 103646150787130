import { EventType, LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import LineChart, { LineChartSeries } from "../../common/LineChart";
import { PcmV2Cost, PcmV2SceneTypeEnum } from "../../../models/PcmV2Models";
import { PcmV2MetricNames, PcmV2SceneMetrics } from "../../../models/constants/PcmV2Constants";
import React, { useMemo } from "react";

import { DefaultButton as Button } from "@fluentui/react";
import ChartContainer from "../../chart/ChartContainer";
import CommonConstants from "../../../models/constants/CommonConstants";
import { exportStringToCsv } from "../../../utils/common";
import { trackEventCallback } from "../../../utils/AppInsights";
import { useGetPcmV2MonthlyCostByCategory } from "../../../hooks/useSubstrateV2Query";
import { useTrackHovering } from "../../../hooks/useTrack";

export const SubstrateMonthlyTrends: React.FC = () => {
    const substrateCOGSMetricCostMonthlyTrends = "Substrate COGS Metric Cost Monthly Trends";
    const query = useGetPcmV2MonthlyCostByCategory();
    const trackingProps = useTrackHovering(LogComponent.SubstrateChartingPane, LogElement.SubstrateCOGSMetricCostMonthlyTrends, substrateCOGSMetricCostMonthlyTrends, LogTarget.Chart);
    const series = useMemo<LineChartSeries[]>(() => {
        const metrics = PcmV2SceneMetrics[PcmV2SceneTypeEnum.Overview];

        if (!query.data) {
            return [];
        }

        return metrics.map((metric, index) => ({
            name: PcmV2MetricNames[metric] || metric,
            color: CommonConstants.DefaultColors[index],
            data: query.data[metric]?.map((item) => [item.extractionDate.valueOf(), item.cost]) || [],
            showDeviation: true,
        }));
    }, [query.data]);

    const isEmpty = !series.find((s) => s.data?.find(([_, value]) => value && value > 0));
    const prepareCSVData = <T extends PcmV2Cost>(
        data: Record<string, T[]>,
        metrics: string[],
        metricNames: string[]
    ): string => {
        const csvData: { [extractionDate: string]: [number][] } = {};
        for (const key in data) {
            if (metrics.includes(key as string)) {
                data[key]?.forEach((item) => {
                    const extractionDate = item.extractionDate.format("MM/YYYY");
                    if (!csvData[extractionDate]) {
                        csvData[extractionDate] = [];
                    }
                    csvData[extractionDate].push([item.cost]);
                });
            }
        }
    
        const rowData = [];
        for (const key in csvData) {
            rowData.push(`${key},${csvData[key].map((item) => item.join(","))}`);
        }
    
        return ["Month", ...metricNames].join(",") + "\n" + rowData.join("\n");
    };


    const handleDownload = () => {
        if (!query.data) {
            return;
        }

        const pcmV2SceneMetricsName = PcmV2SceneMetrics[PcmV2SceneTypeEnum.Overview].map((metric) => PcmV2MetricNames[metric] || metric);
        const pcmV2SceneMetrics = PcmV2SceneMetrics[PcmV2SceneTypeEnum.Overview].map((metric) => metric.toString());

        const csvData = prepareCSVData(query.data, pcmV2SceneMetrics, pcmV2SceneMetricsName);
        exportStringToCsv(csvData, substrateCOGSMetricCostMonthlyTrends);
    };

    return (
        <div>
            <ChartContainer
                chartType="line"
                chartProps={{
                    series,
                    ignoreWeekend: false,
                    height: 240,
                    showArea: true,
                    hideEmptySeries: true,
                    tooltipDateFormatStr: "MMM",
                    dateFormatStr: "MMM",
                    containerProps: trackingProps,
                }}
                isEmpty={isEmpty}
                emptyModuleProps={{ iconName: "EmptyLineChart" }}
                headerProps={{ title: substrateCOGSMetricCostMonthlyTrends }}
                loading={query.isLoading}
                logProps={{
                    logComponent: LogComponent.SubstrateV2,
                    logElement: LogElement.CogsMetricCost,
                }}
            >
                <Button onClick={handleDownload}>Download CSV</Button>
            </ChartContainer>
        </div>
    );
};
