import { mergeStyles, Shimmer } from "@fluentui/react";
import * as React from "react";

const shimmerStyles = mergeStyles({
    padding: 2,
    selectors: {
        '& > .ms-Shimmer-container': {
        margin: '10px 0',
        },
    },
});

const LoadingState: React.FC = () => {
    return (
        <div className={shimmerStyles}>
            <Shimmer />
            <Shimmer width="75%" />
            <Shimmer width="50%" />
        </div>
    );
}

export default LoadingState;