import { IAzureComputeDetail, IAzureComputeSubscriptionDetail } from "../../../models/AzureComputeModels";

import { FilterType } from "../../filter/filterList/FilterList";
import { LogElement } from "../../../models/LogModel";
import { range } from "lodash";

export const RdosCpuBinRange = [
    "90% ~ 100%",
    "80% ~ 90%",
    "70% ~ 80%",
    "60% ~ 70%",
    "50% ~ 60%",
    "40% ~ 50%",
    "30% ~ 40%",
    "20% ~ 30%",
    "10% ~ 20%",
    "00% ~ 10%"
];

export const CpuUtilizationRange = range(0, 105, 5);

export const AzureComputeMycroftHeatmapKeys: (keyof IAzureComputeDetail)[] = [
    'vmCost', 'billingCost', 'reclaimableCores', 'reclaimableCoresAvgMaxOfMax',
    'idleCoresP50','idleCoresP95', 'idleCoresP99', 'idleCoresP999', 'idleCoresP99AvgMaxOfMax',
    'cpuP50', 'cpuP99', 'cpuP99AvgMaxOfMax', 'memoryP50', 'memoryP99', 'networkP50', 'networkP99',
    'cpuP95', 'cpuP999', 'memoryP95', 'memoryP999', 'networkP95', 'networkP999',
];

export const AzureComputeHeatmapKeys: (keyof IAzureComputeDetail)[] = [
    ...AzureComputeMycroftHeatmapKeys,
    'idleCoresP50Percentage', 'idleCoresP99Percentage',
     'idleCoresP95Percentage', 'idleCoresP999Percentage',
];

export const AzureComputeDisplayNames: Record<string, string> = {
    totalCores: 'Total Cores',
    averageCores: 'Average Cores',
    resourceUri: 'Resource Uri',
    reclaimableCores: 'Reclaimable Cores',
    reclaimableCoresAvgMaxOfMax: 'Reclaimable Cores Avg Max of Max',
    idleCoresP50: 'Idle Cores P50',
    idleCoresP99: 'Idle Cores P99',
    idleCoresP99AvgMaxOfMax: 'Idle Cores P99 Avg Max of Max',
    idleCoresP50Percentage: 'Idle Cores P50 %',
    idleCoresP99Percentage: 'Idle Cores P99 %',
    cpuP50: 'CPU P50',
    cpuP99: 'CPU P99',
    cpuP99AvgMaxOfMax: 'CPU P99 Avg Max of Max',
    feedback: 'feedback',
    memoryP50: 'Memory P50',
    memoryP99: 'Memory P99',
    networkP50: 'Network P50',
    networkP99: 'Network P99',
    serviceDevOwners: 'Dev Owners',
    servicePMOwners: 'PM Owners',
    serviceName: 'Service',
    region: 'Region',
    subscriptionName: 'Subscription',
    subscriptionId: 'Subscription ID',
    deploymentId: 'Deployment ID',
    clusterId: 'Cluster ID',
    sku: 'SKU',
    role: 'Role',
    cloudService: 'Cloud Service',
    environment: 'Environment',
    costCategory: 'Cost Category',
    machineFunction: 'Machine Function',
    perfCollector: 'Perf Collector',
    vmScaleSetName: 'VM Scale Set',
    operatingSystem: 'Operating System',
    instances: 'Instances',
    cores: 'Cores',
    billingCost: 'BillingCost',
    vmCost: 'EstimatedCost',
    subscriptionCounts: 'Subscriptions',

    reclaimableCoresV2: 'Reclaimable Cores V2',
    idleCoresP95: 'Idle Cores P95',
    idleCoresP999: 'Idle Cores P999',
    idleCoresP50V2: 'Idle Cores P50 V2',
    idleCoresP95V2: 'Idle Cores P95 V2',
    idleCoresP99V2: 'Idle Cores P99 V2',
    idleCoresP999V2: 'Idle Cores P999 V2',
    idleCoresP95Percentage: 'Idle Cores P95 %',
    idleCoresP999Percentage: 'Idle Cores P999 %',
    idleCoresP50V2Percentage: 'Idle Cores P50 % V2',
    idleCoresP95V2Percentage: 'Idle Cores P95 % V2',
    idleCoresP99V2Percentage: 'Idle Cores P99 % V2',
    idleCoresP999V2Percentage: 'Idle Cores P999 % V2',
    cpuP95: 'CPU P95',
    cpuP999: 'CPU P999',
    cpuP50V2: 'CPU P50 V2',
    cpuP95V2: 'CPU P95 V2',
    cpuP99V2: 'CPU P99 V2',
    cpuP999V2: 'CPU P999 V2',
    memoryP95: 'Memory P95',
    memoryP999: 'Memory P999',
    networkP95: 'Network P95',
    networkP999: 'Network P999',
    isMock: "Is Mock",
    isSpotVM: "Is Spot VM",
};

export const AzureComputeFilterTypes: Record<string, FilterType> = {
    totalCores: FilterType.Number,
    averageCores: FilterType.Number,
    reclaimableCores: FilterType.Number,
    reclaimableCoresAvgMaxOfMax: FilterType.Number,
    idleCoresP50: FilterType.Number,
    idleCoresP99: FilterType.Number,
    idleCoresP99AvgMaxOfMax: FilterType.Number,
    idleCoresP50Percentage: FilterType.Number,
    idleCoresP99Percentage: FilterType.Number,
    cpuP50: FilterType.Number,
    cpuP99: FilterType.Number,
    cpuP99AvgMaxOfMax: FilterType.Number,
    memoryP50: FilterType.Number,
    memoryP99: FilterType.Number,
    networkP50: FilterType.Number,
    networkP99: FilterType.Number,
    serviceDevOwners: FilterType.String,
    servicePMOwners: FilterType.String,
    serviceName: FilterType.String,
    region: FilterType.String,
    subscriptionName: FilterType.String,
    subscriptionId: FilterType.String,
    deploymentId: FilterType.String,
    clusterId: FilterType.String,
    sku: FilterType.String,
    role: FilterType.String,
    cloudService: FilterType.String,
    environment: FilterType.String,
    costCategory: FilterType.String,
    machineFunction: FilterType.String,
    perfCollector: FilterType.String,
    vmScaleSetName: FilterType.String,
    operatingSystem: FilterType.String,
    instances: FilterType.Number,
    cores: FilterType.Number,
    vmCost: FilterType.Number,
    billingCost: FilterType.Number,
    subscriptionCounts: FilterType.Number,
    resourceUri: FilterType.String,

    reclaimableCoresV2: FilterType.Number,
    idleCoresP95: FilterType.Number,
    idleCoresP999: FilterType.Number,
    idleCoresP50V2: FilterType.Number,
    idleCoresP95V2: FilterType.Number,
    idleCoresP99V2: FilterType.Number,
    idleCoresP999V2: FilterType.Number,
    idleCoresP95Percentage: FilterType.Number,
    idleCoresP999Percentage: FilterType.Number,
    idleCoresP50V2Percentage: FilterType.Number,
    idleCoresP95V2Percentage: FilterType.Number,
    idleCoresP99V2Percentage: FilterType.Number,
    idleCoresP999V2Percentage: FilterType.Number,
    cpuP95: FilterType.Number,
    cpuP999: FilterType.Number,
    cpuP50V2: FilterType.Number,
    cpuP95V2: FilterType.Number,
    cpuP99V2: FilterType.Number,
    cpuP999V2: FilterType.Number,
    memoryP95: FilterType.Number,
    memoryP999: FilterType.Number,
    networkP95: FilterType.Number,
    networkP999: FilterType.Number,
    isMock: FilterType.Boolean,
    isSpotVM: FilterType.Boolean,
};

export const AzureComputeDescriptions: Record<string, string> = {
    "billingCost": "Actual cost from AzureSpend controlled by access policy, if you don't have access, it will show as '-'. For more detail, please refer to this wiki: https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/379777/Azure-billing-cost",
    "vmCost": "Estimated cost calculated with core count and unit price"
};

export const AzureComputeHeatmapDegree = [{
    range: '0%~20%',
    color: '#627BEF',
}, {
    range: '20%~40%',
    color: '#3756E6',
}, {
    range: '40%~60%',
    color: '#102899',
}, {
    range: '60%~80%',
    color: '#0B1C6A',
}, {
    range: '80%~100%',
    color: '#040A25',
}];

export const CostCategoryDisplayNames: Record<string, string> = {
    FR: "Research & Development",
    FX: "Cost of Goods Sold",
    FS: "Sales & Marketing",
    FG: "General & Administrative",
};

export const AzureLoggingElements: Record<string, LogElement> = {
    "Overview": LogElement.Overview,
    "ServiceDetail": LogElement.ServiceDetails,
    "RegionDetail": LogElement.RegionDetails,
    "SubscriptionDetail": LogElement.SubscriptionDetails,
    "BudgetDetail": LogElement.BudgetDetails
}

export const AzureServiceDetailsColumnNames: (keyof IAzureComputeDetail)[] = ['serviceName', 'feedback', 'subscriptionCounts', 'totalCores', ...AzureComputeHeatmapKeys, 'serviceDevOwners', 'servicePMOwners'];

export const AzureRegionDetailsColumnNames: (keyof IAzureComputeDetail)[] = ['serviceName', 'feedback', 'subscriptionCounts', 'region', 'totalCores', ...AzureComputeHeatmapKeys, 'serviceDevOwners', 'servicePMOwners'];

export const AzureSubscriptionDetailsColumnNames: (keyof IAzureComputeSubscriptionDetail)[] = [
    'subscriptionName', 'feedback', 'subscriptionId', 'serviceName', 'deploymentId', 'region', 'clusterId', 'role', 'cloudService', 'sku', 'environment', 'costCategory',
    'machineFunction', 'perfCollector', 'vmScaleSetName', 'operatingSystem', 'instances', 'cores',
    'totalCores', ...AzureComputeHeatmapKeys, 'serviceDevOwners', 'servicePMOwners'
];

export const AzureMycroftServiceDetailsColumnNames: (keyof IAzureComputeDetail)[] = ['serviceName', 'feedback', 'subscriptionCounts', 'totalCores', ...AzureComputeMycroftHeatmapKeys, 'serviceDevOwners', 'servicePMOwners'];

export const AzureMycroftRegionDetailsColumnNames: (keyof IAzureComputeDetail)[] = ['serviceName', 'feedback', 'subscriptionCounts', 'region', 'totalCores', ...AzureComputeMycroftHeatmapKeys, 'serviceDevOwners', 'servicePMOwners'];

export const AzureMycroftSubscriptionDetailsColumnNames: (keyof IAzureComputeSubscriptionDetail)[] = [
    'subscriptionName', 'feedback', 'subscriptionId', 'serviceName', 'region', 'clusterId', 'sku',
    'instances', 'totalCores', ...AzureComputeMycroftHeatmapKeys, 'serviceDevOwners', 'servicePMOwners',
    'isMock', 'isSpotVM'
];

export const AzureServiceDetailsColumnNamesV3: (keyof IAzureComputeDetail)[] = [
    'serviceName', 'feedback',
    'totalCores','averageCores',  'vmCost', 'billingCost', 'reclaimableCores', 'reclaimableCoresAvgMaxOfMax',
    'idleCoresP50', 'idleCoresP95', 'idleCoresP99', 'idleCoresP999', 'idleCoresP99AvgMaxOfMax',
    'cpuP50', 'cpuP95', 'cpuP99', 'cpuP999', 'cpuP99AvgMaxOfMax',   
    'memoryP50', 'memoryP95', 'memoryP99', 'memoryP999',
    'networkP50', 'networkP95', 'networkP99', 'networkP999',
    'serviceDevOwners', 'servicePMOwners'];

export const AzureRegionDetailsColumnNamesV3: (keyof IAzureComputeDetail)[] = [
    'serviceName', 'feedback', 'region',
    'totalCores', 'averageCores', 'vmCost', 'billingCost', 'reclaimableCores', 'reclaimableCoresAvgMaxOfMax',
    'idleCoresP50', 'idleCoresP95', 'idleCoresP99', 'idleCoresP999', 'idleCoresP99AvgMaxOfMax',
    'cpuP50', 'cpuP95', 'cpuP99', 'cpuP999', 'cpuP99AvgMaxOfMax',
    'memoryP50', 'memoryP95', 'memoryP99', 'memoryP999',
    'networkP50', 'networkP95', 'networkP99', 'networkP999',
    'serviceDevOwners', 'servicePMOwners'
]

export const AzureSubscriptionDetailsColumnNamesV3: (keyof IAzureComputeSubscriptionDetail)[] = [
    'subscriptionName', 'feedback', 'subscriptionId', 'serviceName', 'resourceUri', 'region', 'clusterId', 'vmScaleSetName', 'role', 'sku', 
    'instances', 'totalCores', 'averageCores', 'vmCost', 'billingCost', 'reclaimableCores', 'reclaimableCoresAvgMaxOfMax',
    'idleCoresP50', 'idleCoresP95', 'idleCoresP99', 'idleCoresP999', 'idleCoresP99AvgMaxOfMax',
    'cpuP50', 'cpuP95', 'cpuP99', 'cpuP999', 'cpuP99AvgMaxOfMax',
    'memoryP50', 'memoryP95', 'memoryP99', 'memoryP999',
    'networkP50', 'networkP95', 'networkP99', 'networkP999',
    'serviceDevOwners', 'servicePMOwners'
]

export const NON_COMPUTE_CATEGORIES = ["Storage", "SQL Database", "Azure Cosmos DB"];