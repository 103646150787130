import { ActionButton, Callout, Checkbox, DirectionalHint, Icon } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useRef, useState } from "react";
import styles from "../../ring/RingsFilter.less";
import filterStyles from "./ManagerFilter.less";
import usageScenarioFilterStyles from "./UsageScenarioFilter.less";
import { ESUsageScenarios } from "../../../models/ESCostModels";
import { useEsUsageScenarios } from "../../../hooks/useEsUsageScenarios";
import { ESAllUsageScenario } from "../../../models/constants/ESCostConstants";

interface IProps {
    loading: boolean;
}

export const UsageScenarioFilter: React.FC<IProps> = (props) => {
    const { loading } = props;
    const buttonRef = useRef<HTMLDivElement>(null);
    const [calloutVisible, { toggle: toggleCalloutVisible, setFalse: hideCallout }] = useBoolean(false);
    const [selectedUsageScenarios, setSelectedUsageScenarios] = useEsUsageScenarios();
    const [selected, setSelected] = useState(selectedUsageScenarios);

    const handleToggleSelect = (key: ESUsageScenarios, checked: boolean) => {
        const newSelectedKeys = [...selected];

        if (checked) {
            newSelectedKeys.push(key);
        } else {
            newSelectedKeys.splice(newSelectedKeys.indexOf(key), 1);
        }

        setSelected(newSelectedKeys);
    };

    const handleClearAll = () => {
        setSelected([]);
    };

    const handleConfirm = () => {
        setSelectedUsageScenarios(selected);
        hideCallout();
    };

    return (
        <div className={styles.ringSelector}>
            <div
                className={`${styles.ringSelectorContent} ${loading ? usageScenarioFilterStyles.disabled : ""}`}
                ref={buttonRef}
                onClick={loading ? undefined : toggleCalloutVisible}
            >
                <Icon iconName="WebAppBuilderFragment" style={{ color: "#323130" }} />
                <span className={styles.ringSelectorContentText}>
                    {!selectedUsageScenarios.length || selectedUsageScenarios.length === ESAllUsageScenario.length
                        ? "All usage scenarios"
                        : `${selectedUsageScenarios.length} usage scenario${selectedUsageScenarios.length > 1 ? "s" : ""}`}
                </span>
                <Icon iconName="ChevronDown" className={styles.downIcon} />
            </div>
            {calloutVisible && (
                <Callout
                    className={filterStyles.callout}
                    directionalHint={DirectionalHint.bottomRightEdge}
                    isBeakVisible={false}
                    target={buttonRef.current}
                    onDismiss={hideCallout}
                >
                    <div className={usageScenarioFilterStyles.listWrapper}>
                        <div className={filterStyles.selectionList}>
                            {ESAllUsageScenario.map((item) => (
                                <Checkbox
                                    className={styles.ringListItem}
                                    checked={selected.indexOf(item) >= 0}
                                    label={item}
                                    styles={{ label: { width: "100%" } }}
                                    key={item}
                                    id={item}
                                    onChange={(_, checked) => handleToggleSelect(item, !!checked)}
                                />
                            ))}
                        </div>
                    </div>
                    <ActionButton
                        onClick={handleClearAll}
                        primaryDisabled={selected.length === 0}
                        className={usageScenarioFilterStyles.button}
                        iconProps={{ iconName: "Cancel" }}
                        disabled={selected.length === 0}
                    >
                        Clear All
                    </ActionButton>
                    <ActionButton onClick={handleConfirm} className={usageScenarioFilterStyles.button} iconProps={{ iconName: "CheckMark" }}>
                        Confirm
                    </ActionButton>
                </Callout>
            )}
        </div>
    );
};
