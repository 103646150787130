import { IFilterView, IPartialFilterViewContent } from "../models/FilterView";
import { IGetAllViewsAction, IGetViewAction, IUpdateViewAction, SavedViewsActions } from "../actions/SavedViewActions";
import { getAllViews, loadViewById, shareView } from "../services/viewService";

import { Flight } from "../models/Flights";
import { GetStateFunction } from "../store";
import { IToggleShareViewDialogsAction } from "../actions/DialogsActions";
import { Reducer } from "redux";
import { RequestStatus } from "../actions/ActionTypes";
import { ThunkDispatch } from "redux-thunk";
import { makeToggleShareViewDialogAction } from "./dialogsReducer";
import moment from "moment";

export interface ISavedViewData {
    savedViews?: {[key: string] : IFilterView};
    isLoading: boolean;
    errorHappened: boolean;
}

// For testing
const initialState: ISavedViewData = {
    isLoading: false,
    errorHappened: false,
}

export const savedViewReducer: Reducer<ISavedViewData, IGetAllViewsAction | IUpdateViewAction | IGetViewAction> = function(state = initialState, action): ISavedViewData {
    switch (action.type) {
        case SavedViewsActions.GetAll: {
            switch (action.status) {
                case RequestStatus.Requested:
                    return { isLoading: true, errorHappened: false, savedViews: {...state.savedViews}};
                case RequestStatus.Succeed:
                    const newState : Required<ISavedViewData> = { isLoading: false, errorHappened: false, savedViews: {}};
                    action.data?.forEach((item) => {         
                        if (state.savedViews && state.savedViews[item.id]) {
                            const filterView = state.savedViews[item.id];
                            newState.savedViews[item.id] = { ...item, isOpen: filterView.isOpen }
                            return;
                        }                  
                        newState.savedViews[item.id] = {...item}
                    })
                    return newState;
                case RequestStatus.Failed:
                    return { isLoading: false, errorHappened: true, savedViews: {...state.savedViews}}; 
                default:
                    return state;
            }
        }
        case SavedViewsActions.Update: {
            if (state.savedViews) {
                state.savedViews[action.view.id] = { ...action.view, isOpen: action.expanded };
            }

            return { isLoading: false, errorHappened: false, savedViews: state.savedViews};
        }
        case SavedViewsActions.Get: {
            const newState : Required<ISavedViewData> = { isLoading: false, errorHappened: false, savedViews: {...state.savedViews}};
            if (state.savedViews && state.savedViews[action.view.id]) {
                newState.savedViews[action.view.id] = action.view;
            }

            return newState;
        }

        default: 
            return state;
    } 
}

export function loadAllViewsIfNeeded(flight: Flight) {
    return async function (dispatch: ThunkDispatch<ISavedViewData, void, IGetAllViewsAction>, getState: GetStateFunction): Promise<void> {
        const savedViewsData = getState().savedViews;
        if (savedViewsData.isLoading || savedViewsData.errorHappened) {
            return Promise.resolve();
        }

        dispatch({type: SavedViewsActions.GetAll, status: RequestStatus.Requested});
        getAllViews(flight)
            .then( savedViews => dispatch({type: SavedViewsActions.GetAll, status: RequestStatus.Succeed, data: savedViews}))
            .catch( () => dispatch({type: SavedViewsActions.GetAll, status: RequestStatus.Failed}));
    }
}

export function loadView(viewId: number) {
    return async function (dispatch: ThunkDispatch<ISavedViewData, void, IGetViewAction>): Promise<void> {
        loadViewById(viewId)
            .then( updatedView => dispatch({type: SavedViewsActions.Get, view: updatedView}));
    }
}

export function shareViewAction(dateRangeFilterData: {startDate: moment.Moment, endDate: moment.Moment}, viewContent: IPartialFilterViewContent, viewName?: string) {
    return async function (dispatch: ThunkDispatch<ISavedViewData, void, IToggleShareViewDialogsAction>): Promise<void> {
        shareView(dateRangeFilterData.startDate, dateRangeFilterData.endDate, viewContent).then(async (response) => {
            const id = await response.text();
            dispatch(makeToggleShareViewDialogAction(true, dateRangeFilterData.startDate.format("YYYY-MM-DD"), dateRangeFilterData.endDate.format("YYYY-MM-DD"), id, viewName));
        })
    }
}

export function makeToggleFilterViewExpandedAction(expanded: boolean, view: IFilterView) : IUpdateViewAction {
    return {
        type: SavedViewsActions.Update,
        expanded,
        view: view,
    };
}