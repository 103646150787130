export function editDistance (str1: string, str2: string) : number {
    const length1 = str1.length;
    const length2 = str2.length;
    const dp : [number] = [0]
    for (let i = 1; i < length1 + 1; i++) {
        dp.push(i);
    }
    let old_dp = [...dp];
    for (let i = 1; i < length2 + 1; i++) {
        for (let j = 0; j < length1 + 1; j++) {
            if (j > 0) {
                dp[j] = Math.min(old_dp[j] + 1, dp[j-1] + 1, str1[j] == str2[i] ? old_dp[j-1] : old_dp[j-1] + 1)
            }
            else {
                dp[j] = old_dp[j] + 1
            }
        }
        old_dp = [...dp];
    }
    return dp[length1]
}