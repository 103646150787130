// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QYqNr2QlzwOjPKXdRTl8 {\n  color: #323130;\n  font-family: \"Segoe UI\";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 20px;\n  padding-bottom: 24px;\n}\n.UtKmeZadKG_ROP5wS8tC::before {\n  background-color: #323130;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EfficiencyTrackerV2/Components/RegionDetails.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;AACJ;AAEA;EACI,yBAAA;AAAJ","sourcesContent":[".title {\n    color: #323130;\n    font-family: \"Segoe UI\";\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 20px;\n    padding-bottom: 24px;\n}\n\n.separator::before {\n    background-color: #323130;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "QYqNr2QlzwOjPKXdRTl8",
	"separator": "UtKmeZadKG_ROP5wS8tC"
};
export default ___CSS_LOADER_EXPORT___;
