import * as React from "react";
import styles from "../Page.less";
import PageWrapper from "../PageWrapper";
import ResponseBoundary from "../../../ResponseBoundary/ResponseBoundary";
import {
    useGetAllBuildRoleFYArtifactCost,
    useGetAllUsageScenarioFYArtifactCost,
    useGetArtifactFYBuildQueueCost,
    useGetRepoFYArtifactCost,
    useGetReposMonthToMonthArtifactCost,
} from "../../../../hooks/useES365CostQuery";
import TopRepoCostBarChart from "../../Charts/ESArtifact/TopRepoCostBarChart";
import { BuildQueuesTopCostHistogram } from "../../Charts/CloudBuild/BuildQueuesTopCostHistogram";
import ManagersUsageScenarioOverviewPieChart from "../../Charts/CloudBuild/ManagersUsageScenarioOverviewPieChart";
import ManagersBuildRoleOverviewPieChart from "../../Charts/CloudBuild/ManagersBuildRoleOverviewPieChart";
import MonthToMonthCostTable from "../../Charts/Common/MonthToMonthCostTable";
import { ESArtifactDataType, ESCostDataDimensions } from "../../../../models/ESCostModels";

interface IProps {
    dataType?: ESArtifactDataType;
}

const ArtifactDetailsPage: React.FC<IProps> = (props) => {
    const { dataType } = props;
    const { data: esFYBuildQueueCost, isLoading: isBuildQueueCostLoading, isError: isBuildQueueCostError } = useGetArtifactFYBuildQueueCost(dataType);
    const { data: esRepoArtifactCost, isLoading, isError } = useGetRepoFYArtifactCost(dataType);
    const { data: esUsageScenarioCost, isLoading: isUsageScenarioLoading, isError: isUsageScenarioError } = useGetAllUsageScenarioFYArtifactCost(dataType);
    const { data: esBuildRoleCost, isLoading: isBuildRoleLoading, isError: isBuildRoleError } = useGetAllBuildRoleFYArtifactCost(dataType);
    const { data: esRepoCost, isLoading: isTopRepoCostLoading, isError: isTopRepoCostError } = useGetReposMonthToMonthArtifactCost(dataType);

    const dataTypeString = dataType === ESArtifactDataType.Workload ? "workload" : "cost";
    const isCurrency = dataType !== ESArtifactDataType.Workload;
    return (
        <PageWrapper
            title={`Artifact ${dataTypeString} detail`}
            description={`Artifact ${dataTypeString} detail view will bring awareness to repo, usage scenario, build roles, build queues, for both partner team and ES Build SME.`}
        >
            <div className={styles.grid}>
                <ResponseBoundary isLoading={isLoading} errorHappened={isError} data={esRepoArtifactCost}>
                    {esRepoArtifactCost !== undefined ? (
                        <TopRepoCostBarChart
                            data={esRepoArtifactCost}
                            description={`Top 10 Repos on Artifact ${dataTypeString}`}
                            legendDesc={`Artifact ${dataTypeString}`}
                            isCurrency={isCurrency}
                            maxCount={10}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
                <ResponseBoundary isLoading={isBuildQueueCostLoading} errorHappened={isBuildQueueCostError} data={esFYBuildQueueCost}>
                    {esFYBuildQueueCost !== undefined ? (
                        <BuildQueuesTopCostHistogram
                            data={esFYBuildQueueCost}
                            seriesHeader={`Artifact ${dataTypeString}`}
                            description={`Top 10 Artifact ${dataTypeString} of build queues`}
                            isCurrency={isCurrency}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
            <div className={styles.grid}>
                <ResponseBoundary isLoading={isUsageScenarioLoading} errorHappened={isUsageScenarioError} data={esUsageScenarioCost}>
                    {esUsageScenarioCost !== undefined ? <ManagersUsageScenarioOverviewPieChart data={esUsageScenarioCost} isCurrency={isCurrency} /> : <></>}
                </ResponseBoundary>
                <ResponseBoundary isLoading={isBuildRoleLoading} errorHappened={isBuildRoleError} data={esBuildRoleCost}>
                    {esBuildRoleCost !== undefined ? <ManagersBuildRoleOverviewPieChart data={esBuildRoleCost} isCurrency={isCurrency} /> : <></>}
                </ResponseBoundary>
            </div>
            <div>
                <ResponseBoundary isLoading={isTopRepoCostLoading} errorHappened={isTopRepoCostError} data={esRepoCost}>
                    {esRepoCost !== undefined ? (
                        <MonthToMonthCostTable
                            data={esRepoCost}
                            description={`Artifact ${dataTypeString} MoM comparison of all repos (top 100 ${dataTypeString} in last month)`}
                            splitDimension={ESCostDataDimensions.Repository}
                            targetDimension={ESCostDataDimensions.Cost}
                            maxCount={100}
                            isCurrency={isCurrency}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
        </PageWrapper>
    );
};

export default ArtifactDetailsPage;
