// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".u8X_1i5M8GK7CDqp8Jzv {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n.qiLfCvZ3UoRgVij6V5gf {\n  overflow-y: hidden;\n  overflow-x: hidden;\n  flex: 1 1 80%;\n  height: 100%;\n  border-left: 3px solid #ccc;\n}\n.NMKAQCrPljtA8V8LvBFF {\n  width: 100%;\n  overflow: hidden;\n}\n.cQDvzJMXamctQ4pSm0q0 {\n  width: 280px;\n  height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ESCost/PageLayout/ESCostPageLayout.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;AACJ;AAEA;EACI,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,2BAAA;AAAJ;AAGA;EACI,WAAA;EACA,gBAAA;AADJ;AAIA;EACI,YAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AAFJ","sourcesContent":[".page {\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n}\n\n.pageContainer {\n    overflow-y: hidden;\n    overflow-x: hidden;\n    flex: 1 1 80%;\n    height: 100%;\n    border-left: 3px solid #ccc\n}\n\n.stack {\n    width: 100%;\n    overflow: hidden;\n}\n\n.navigation {\n    width: 280px;\n    height: 100%;\n    overflow-y: auto;\n    overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "u8X_1i5M8GK7CDqp8Jzv",
	"pageContainer": "qiLfCvZ3UoRgVij6V5gf",
	"stack": "NMKAQCrPljtA8V8LvBFF",
	"navigation": "cQDvzJMXamctQ4pSm0q0"
};
export default ___CSS_LOADER_EXPORT___;
