/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { IDateRangeFilterData, parseFiltersToJson } from "../models/FilterView";
import { ISubstrateCostByServiceGriffinProcessor, ISubstrateDailyMetrics, ISubstrateEntity, ISubstrateEntityResponse, ISubstrateMonthOnMonthData, ISubstrateRingData, ISubstrateTopComponents, ISubstrateTotalCostDistributionData, ISubstrateTotalCostTimeSeriesData, getSubstrateCostByServiceGriffinProcessor } from "../models/SubstrateModels";
import { PCMMetric, getPcmMetricCostField, getPcmMetricField } from "../models/PCMMetrics";
import moment, { Moment } from "moment";

import { CategoryDivision } from "../models/CategoryDivision";
import { IDailyMetricReport } from "../models/Metric"
import { ISubstrateTotalCostResponse } from "../models/SubstrateTotalCost";
import { ServiceTreeItem } from "../models/serviceTree";
import { fromPairs } from "lodash";
import { postJson } from "../utils/apiServiceBase";

export async function getDailyMetrics({queryKey}: {queryKey: [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, boolean]}): Promise<ISubstrateDailyMetrics> {
    const [, dateRangeFilter, filtersData, serviceIdMap, isEnabled] = queryKey;
    const url = "api/v1.0/Substrate/GetDailyMetrics?startDate=" + dateRangeFilter.startDate.format("YYYY-MM-DD") + "&endDate=" + dateRangeFilter.endDate.format("YYYY-MM-DD");
    const response = isEnabled ? await postJson<ISubstrateTotalCostResponse[]>(url, parseFiltersToJson(filtersData, serviceIdMap)) : [];

    const timeSeries = [];
    for (const item of response) {
        const value: [Moment, number] = [moment(item.extractionDate), item.substrateCost ?? 0];
        timeSeries.push(value);
    }

    const details: [PCMMetric, ISubstrateTotalCostTimeSeriesData][] = [];
    for (const metric of Object.values(PCMMetric)) {
        details.push([metric, response.map(item => [moment(item.extractionDate), (item[getPcmMetricCostField(metric)] as number) ?? 0])]);
    }
    return {distribution: aggregateSubstrateTotalCost(response), timeSeries, details};
}

export async function getSubstrateCostByService({queryKey}: {queryKey: [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string]}): Promise<ISubstrateCostByServiceGriffinProcessor> {
    const [, dateRangeFilter, filtersData, serviceIdMap, orderType] = queryKey;

    const urlCost = "api/v1.0/Substrate/GetHierarchicalMetrics?startDate=" + dateRangeFilter.startDate.format("YYYY-MM-DD") + "&endDate=" + dateRangeFilter.endDate.format("YYYY-MM-DD") + "&orderType=" + orderType;
    const urlCarbon = "api/v1.0/Substrate/GetSubstrateCarbonEmission?startDate=" + dateRangeFilter.startDate.format("YYYY-MM-DD") + "&endDate=" + dateRangeFilter.endDate.format("YYYY-MM-DD");
    const loadCarbonData = false;

    const [responseCost, responseCarbon] = await Promise.all([
        postJson<ISubstrateEntityResponse>(urlCost, parseFiltersToJson(filtersData, serviceIdMap)),
        loadCarbonData ? postJson<ISubstrateEntityResponse>(urlCarbon, parseFiltersToJson(filtersData, serviceIdMap)) : Promise.resolve<ISubstrateEntityResponse>({}),
    ]);

    const substrateCost =  getSubstrateCostByServiceGriffinProcessor(responseCost).filter(item => item.totalCost > 1);

    if(loadCarbonData) {
        mergeSubstrateCostAndCarbonEmission(substrateCost, responseCarbon);
    }

    return substrateCost;
}

function mergeSubstrateCostAndCarbonEmission(substrateCost: ISubstrateEntity[], responseCarbon: ISubstrateEntityResponse) {
    for (const item of substrateCost) {
        const serviceId = item.name;
        if (serviceId) {
            const carbonEmissionItem = responseCarbon[serviceId];
            const carbonEmissionDataMap = new Map<string, ISubstrateTotalCostResponse>();

            if (carbonEmissionItem) {
                carbonEmissionItem.forEach(component => {
                    if (component.appName) {
                        carbonEmissionDataMap.set(component.appName, component);
                    }
                    if(component.totalCarbonEmission) {
                        item.totalCarbonEmission = item.totalCarbonEmission ? item.totalCarbonEmission + component.totalCarbonEmission : component.totalCarbonEmission;
                    }
                    if(component.carbonScope2Emission) {
                        item.carbonScope2Emission = item.carbonScope2Emission ? item.carbonScope2Emission + component.carbonScope2Emission : component.carbonScope2Emission;
                    }
                    if(component.carbonScope3Emission) {
                        item.carbonScope3Emission = item.carbonScope3Emission ? item.carbonScope3Emission + component.carbonScope3Emission : component.carbonScope3Emission;
                    }
                });

                if (item.children) {
                    for (const component of item.children) {
                        if (component.griffinApp) {
                            const carbonComponent = carbonEmissionDataMap.get(component.griffinApp);
                            if (carbonComponent) {
                                component.totalCarbonEmission = carbonComponent.totalCarbonEmission;
                                component.carbonScope2Emission = carbonComponent.carbonScope2Emission;
                                component.carbonScope3Emission = carbonComponent.carbonScope3Emission;
                            }
                        }
                    }
                }
            }
        }

    }
}

export async function getSubstrateRingCost({queryKey}: {queryKey: [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>]}): Promise<ISubstrateRingData> {
    const [, dateRangeFilter, filtersData, serviceIdMap] = queryKey;
    const url = "api/v1.0/Substrate/GetDailyMetricsByRing?startDate=" + dateRangeFilter.startDate.format("YYYY-MM-DD") + "&endDate=" + dateRangeFilter.endDate.format("YYYY-MM-DD");
    const items = await postJson<ISubstrateTotalCostResponse[]>(url, parseFiltersToJson(filtersData, serviceIdMap));
    const result: ISubstrateRingData = {};
    for (const item of items) {
        const ring = item.ring ? item.ring : "unknown";
        if (result.hasOwnProperty(ring)) {
            const values: [moment.Moment, number?][] = result[ring];
            values.push([moment(item.extractionDate), item.substrateCost]);
        } else {
            const values: [moment.Moment, number][] = [];
            values.push([moment(item.extractionDate), item.substrateCost]);
            result[ring] = values;
        }
    }
    return result;
}

export async function getSubstrateMonthONMonth({queryKey}: {queryKey: [string, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>]}): Promise<ISubstrateMonthOnMonthData> {
    const [, filtersData, serviceIdMap] = queryKey;
    const url = "api/v1.0/Substrate/GetMonthlyMetrics?dateTime=" + moment().format("YYYY-MM-DD");
    const items = await postJson<ISubstrateTotalCostResponse[]>(url, parseFiltersToJson(filtersData, serviceIdMap));
    const result = [];
    const lastMonth = items[0];
    const mblm = items[1];
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const mapping = [0, 11, 12];
    const lastMonthName = month == 1 ? "" + (year - 1) + "/12" : "" + year + "/" + (month - 1);
    const mblmName = month <= 2 ? "" + (year - 1) + "/" + mapping[month] : "" + year + "/" + (month - 2);
    const ItemReads: [string, string, number, string, number] = ["Item Reads", lastMonthName, lastMonth.itemReadsCost, mblmName, mblm.itemReadsCost];
    result.push(ItemReads);

    const ItemWrites: [string, string, number, string, number] = ["Item Writes", lastMonthName, lastMonth.itemWritesCost, mblmName, mblm.itemWritesCost];
    result.push(ItemWrites);

    const ItemQueries: [string, string, number, string, number] = ["Item Queries", lastMonthName, lastMonth.itemQueriesCost, mblmName, mblm.itemQueriesCost];
    result.push(ItemQueries);

    const EBA: [string, string, number, string, number] = ["EBA", lastMonthName, lastMonth.ebaCountCost, mblmName, mblm.ebaCountCost];
    result.push(EBA);

    const TBA: [string, string, number, string, number] = ["TBA", lastMonthName, lastMonth.tbaCountCost, mblmName, mblm.tbaCountCost];
    result.push(TBA);

    const SWSS: [string, string, number, string, number] = ["SWSS", lastMonthName, lastMonth.swssCountCost, mblmName, mblm.swssCountCost];
    result.push(SWSS);

    const ServiceInstance: [string, string, number, string, number] = ["Service Instance", lastMonthName, lastMonth.serviceInstanceCost, mblmName, mblm.serviceInstanceCost];
    result.push(ServiceInstance);

    const RequestProcessed: [string, string, number, string, number] = ["Request Processed", lastMonthName, lastMonth.requestsProcessedCost, mblmName, mblm.requestsProcessedCost];
    result.push(RequestProcessed);

    const DSAPIRequests: [string, string, number, string, number] = ["DSAPI Requests", lastMonthName, lastMonth.dsapiRequestsCountCost, mblmName, mblm.dsapiRequestsCountCost];
    result.push(DSAPIRequests);

    const CFMSubmitted: [string, string, number, string, number] = ["CFM Submitted", lastMonthName, lastMonth.cfmSubmittedCountCost, mblmName, mblm.cfmSubmittedCountCost];
    result.push(CFMSubmitted);

    const ItemSize: [string, string, number, string, number] = ["Item Size", lastMonthName, lastMonth.itemSizeCost, mblmName, mblm.itemSizeCost];
    result.push(ItemSize);

    const KvCache: [string, string, number, string, number] = ["KvCache", lastMonthName, lastMonth.kvCacheSizeGbCost, mblmName, mblm.kvCacheSizeGbCost];
    result.push(KvCache);

    const SDSFastStorage: [string, string, number, string, number] = ["SDS Fast Storage", lastMonthName, lastMonth.llcSizeGbCost, mblmName, mblm.llcSizeGbCost];
    result.push(SDSFastStorage);
    return result;
}

export async function getDailySubstrateCarbonEmission({queryKey}: {queryKey: [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>]}): Promise<IDailyMetricReport> {
    const [, dateRangeFilter, filtersData, serviceIdMap] = queryKey;
    const url = "api/v1.0/Substrate/GetDailySubstrateCarbonEmission?startDate=" + dateRangeFilter.startDate.format("YYYY-MM-DD") + "&endDate=" + dateRangeFilter.endDate.format("YYYY-MM-DD");
    const response = await postJson<ISubstrateTotalCostResponse[]>(url, parseFiltersToJson(filtersData, serviceIdMap));
    
    const result = {
        metric: "Total Carbon Emission",
        startDate: moment(dateRangeFilter.startDate),
        endDate: moment(dateRangeFilter.endDate),
        data: response.map(metric => ({
            date: moment(metric.extractionDate),
            metricValue: metric.totalCarbonEmission
        })).sort((a, b) => a.date.unix() - b.date.unix())
    };
    return result;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getSubstrateTopFiveComponents({queryKey}: {queryKey: [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string?, number?]}): Promise<ISubstrateTopComponents> {
    const [, dateRangeFilterData, filtersData, serviceIdMap, orderType, n] = queryKey;
    const orderCostQuery = orderType ? `&orderType=${orderType}` : "";
    const url = `api/v1.0/Substrate/GetOrderedHierarchicalMetrics?startDate=${dateRangeFilterData.startDate.format("YYYY-MM-DD")}&endDate=${dateRangeFilterData.endDate.format("YYYY-MM-DD")}&quantity=${n || 5}${orderCostQuery}`;
    const response = await postJson<ISubstrateTopComponents>(url, parseFiltersToJson(filtersData, serviceIdMap));
    return response;
}

export async function getSubstrateTopFiveApp({queryKey}: {queryKey: [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string?, number?]}): Promise<ISubstrateTopComponents> {
    const [, dateRangeFilterData, filtersData, serviceIdMap, orderType, n] = queryKey;
    const orderCostQuery = orderType ? `&orderType=${orderType}` : "";
    const url = `api/v1.0/substrate/GetOrderedMetrics?startDate=${dateRangeFilterData.startDate.format("YYYY-MM-DD")}&endDate=${dateRangeFilterData.endDate.format("YYYY-MM-DD")}&quantity=${n}${orderCostQuery}`;
    return await postJson<ISubstrateTopComponents>(url, parseFiltersToJson(filtersData, serviceIdMap));
}

export async function getScenarioTagCost({queryKey}: {queryKey: [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string]}): Promise<ISubstrateCostByServiceGriffinProcessor> {
    const [, dateRangeFilterData, filtersData, serviceIdMap, ring] = queryKey;
    const url = `api/v1.0/Substrate/GetScenarioTagAndServiceHierarchicalMetrics?startDate=${dateRangeFilterData.startDate.format("YYYY-MM-DD")}&endDate=${dateRangeFilterData.endDate.format("YYYY-MM-DD")}`;
    const response = await postJson<ISubstrateEntityResponse>(url, parseFiltersToJson(filtersData, serviceIdMap, ring));

    const result = getSubstrateCostByServiceGriffinProcessor(response);

    mergeScenarios(result);
    return result;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
function aggregateSubstrateTotalCost(totalCostByDate: ISubstrateTotalCostResponse[]): ISubstrateTotalCostDistributionData {
    const cost: Record<PCMMetric, number> = fromPairs(Object.values(PCMMetric).map(x => [x, 0])) as any;
    const usage: Record<PCMMetric, number> = fromPairs(Object.values(PCMMetric).map(x => [x, 0])) as any;
    for (const x of totalCostByDate) {
        const row = x as any;
        for (const metric of Object.values(PCMMetric)) {
            cost[metric] += row[getPcmMetricCostField(metric)];
            usage[metric] += row[getPcmMetricField(metric)];
        }
    }

    return { usage: usage as any, cost: cost as any };
}

function mergeScenarios(response: ISubstrateCostByServiceGriffinProcessor) {
    for (const service of response) {
        const children = service.children;
        const temp = new Map<string, ISubstrateEntity>();
        const apps = new Set<string>();
        for (const child of children!) {
            apps.add(child.griffinApp || "Other");
            if (temp.has(child.scenarioTag!)) {
                const item = temp.get(child.scenarioTag!);
                item!.itemReads += child.itemReads;
                item!.peakHourItemReads += child.peakHourItemReads;
                item!.offPeakHourItemReads += child.offPeakHourItemReads;
                item!.itemReadsCost += child.itemReadsCost;

                item!.itemWrites += child.itemWrites;
                item!.peakHourItemWrites += child.peakHourItemWrites;
                item!.offPeakHourItemWrites+= child.offPeakHourItemWrites;
                item!.itemWritesCost += child.itemWritesCost;

                item!.itemQueries += child.itemQueries;
                item!.peakHourItemQueries += child.peakHourItemQueries;
                item!.offPeakHourItemQueries += child.offPeakHourItemQueries;
                item!.itemQueriesCost += child.itemQueriesCost;

                item!.totalreadwritesqueriescost != child.totalreadwritesqueriescost;
                item!.griffinApp += "," + child.griffinApp;
            } else {
                temp.set(child.scenarioTag!, child);
            }
        }
        service.children = Array.from(temp.values());
        service.griffinApp = apps.size + " Apps";
    }
}