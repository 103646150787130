import * as React from "react";
import {
    ESCostDataDimensions,
    ESCostOrganization,
    ESCostRawUserType,
    ESOverviewTimeSelection,
    ESUsageScenarioFYCostRawResponse,
} from "../../../../models/ESCostModels";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import PieChart, { IPieChartData } from "../Common/PieChart";
import { useEsOrg } from "../../../../hooks/useEsOrg";
import { ESOrgs } from "../../../../models/constants/ESCostConstants";

interface IUsageScenarioPieChartProps {
    data: ESUsageScenarioFYCostRawResponse[];
}

function calculateOverviewValues(rawData: ESUsageScenarioFYCostRawResponse[], org: ESCostOrganization[], year: number): IPieChartData {
    const values = rawData.filter(
        (data) =>
            ESOrgs.includes(data[ESCostDataDimensions.Organization]) &&
            (org.length === 0 || org.includes(data[ESCostDataDimensions.Organization])) &&
            year === data[ESCostDataDimensions.ReturnFiscalYear] &&
            data[ESCostDataDimensions.UserType] === ESCostRawUserType.ES
    );
    const sum = values.reduce((acc, curr) => {
        const usageScenario = curr[ESCostDataDimensions.UsageScenario];
        const v = curr[ESCostDataDimensions.Cost];
        acc[usageScenario] = (acc[usageScenario] || 0) + v;
        return acc;
    }, {} as IPieChartData);
    return sum;
}

const InfraServiceUsageScenarioCostPieChart: React.FC<IUsageScenarioPieChartProps> = (props) => {
    const { data: rawData } = props;
    const [org] = useEsOrg();
    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );
    const overviewValues = React.useMemo(() => calculateOverviewValues(rawData, org, year), [org, rawData, year]);
    const total = Object.values(overviewValues).reduce((acc, curr) => acc + curr, 0);
    return (
        <PieChart
            data={overviewValues}
            description={`The cost in all usage scenarios of infra-service accounts`}
            totalCountDesc="Total Cost"
            isCurrency={true}
            total={total}
        />
    );
};

export default InfraServiceUsageScenarioCostPieChart;
