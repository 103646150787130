import React, { useEffect } from "react";

export interface Event<Type extends string, Param = undefined> {
    event: Type;
    params: Param;
}

export type EventListener<T = any> = (param: T) => void;

export class EventHub {
    static instance = new EventHub();

    private eventListeners: Map<string, Set<EventListener>> = new Map();

    addEventListener<T extends Event<string, any> = Event<string, any>>(eventType: T['event'], callback: EventListener<T['params']>) {
        if (!eventType || !callback) return;
        if (this.eventListeners.has(eventType) && this.eventListeners.get(eventType)?.has(callback)) return;

        if (!this.eventListeners.has(eventType)) {
            this.eventListeners.set(eventType, new Set());
        }

        this.eventListeners.get(eventType)?.add(callback);
    }

    removeEventListener<T extends Event<string, any> = Event<string, any>>(eventType: T['event'], callback: EventListener<T['params']>) {
        this.eventListeners.get(eventType)?.delete(callback);
    }

    removeAllEventListeners(eventType: string) {
        this.eventListeners.delete(eventType);
    }

    emit<T extends Event<string, any> = Event<string, any>>(eventType: T['event'], params?: T['params']) : void {
        this.eventListeners.get(eventType)?.forEach(callbackFn => {
            callbackFn(params);
        });
    }
}

export default function useEventBus<T extends Event<string, any>>(type?: T['event'], callback?: (params: T['params']) => void, deps: React.DependencyList = []) {
    useEffect(() => {
        if (!type || !callback) return;

        EventHub.instance.addEventListener(type, callback);

        return () => EventHub.instance.removeEventListener(type, callback);
    }, deps);

    return EventHub.instance;
}
