// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bWXcApRhgBkZGMsaWKQw {\n  margin-top: 20px;\n}\n.oMqZJQI83IVvsMJpy0vd {\n  display: flex;\n  flex-wrap: wrap;\n  row-gap: 20px;\n  column-gap: 20px;\n  margin: 20px 0;\n}\n.e9f2IS0nGO8vanmmQ1DI {\n  font-weight: 700;\n  font-size: 24px;\n  margin-bottom: 20px;\n}\n.e9f2IS0nGO8vanmmQ1DI .DLPLwVuofakHbrNGZut9 {\n  font-size: 32px;\n  color: #0078D4;\n}\n.YVeT99rk9l1qVddcmng1 {\n  font-weight: 600;\n  margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/substrateplatform/SubstratePlatformChart.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAEA;EACI,aAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;EACA,cAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,mBAAA;AADJ;AAFA;EAMQ,eAAA;EACA,cAAA;AADR;AAKA;EACI,gBAAA;EACA,mBAAA;AAHJ","sourcesContent":[".chartContainer {\n    margin-top: 20px;\n}\n\n.cardContainer {\n    display: flex;\n    flex-wrap: wrap;\n    row-gap: 20px;\n    column-gap: 20px;\n    margin: 20px 0;\n}\n\n.totalCost {\n    font-weight: 700;\n    font-size: 24px;\n    margin-bottom: 20px;\n\n    .totalValue {\n        font-size: 32px;\n        color: #0078D4;\n    }\n}\n\n.chartTitle {\n    font-weight: 600;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartContainer": "bWXcApRhgBkZGMsaWKQw",
	"cardContainer": "oMqZJQI83IVvsMJpy0vd",
	"totalCost": "e9f2IS0nGO8vanmmQ1DI",
	"totalValue": "DLPLwVuofakHbrNGZut9",
	"chartTitle": "YVeT99rk9l1qVddcmng1"
};
export default ___CSS_LOADER_EXPORT___;
