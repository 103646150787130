import React from "react";
import { Callout, DefaultButton, Icon } from "@fluentui/react";
import styles from "./AnomalyDetailsTrend.less";
import { BreakdownTypeEnum, IInsightUsage } from "../../../models/InsightsPlatformModels";
import LineChart, { LineChartSeries, LineChartProps } from "../../common/LineChart";
import CommonConstants from "../../../models/constants/CommonConstants";
import { getMedian } from "../../../utils/ChartUtils";
import { DataSourceEnum, INotification } from "../../../models/NotificationModels";
import moment from "moment";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { useTrackHovering } from "../../../hooks/useTrack";
import { trackEventCallback } from "../../../utils/AppInsights";
import { SectionHeader } from "../../common/SectionHeader";

export function getBreakdownPivotString(pivotEnum: BreakdownTypeEnum): string {
    switch (pivotEnum) {
        case BreakdownTypeEnum.Resources:
            return "Resources";
        case BreakdownTypeEnum.Cost:
            return "Cost";
    }
}

const BreakdownTypePivotList = [
    BreakdownTypeEnum.Resources,
    BreakdownTypeEnum.Cost,
];

export interface IAnomalyDetailsTrendProps {
    insightUsage: IInsightUsage;
    impactedResource: string;
}

export const IAnomalyDetailsTrend: React.FunctionComponent<IAnomalyDetailsTrendProps> = (props) => {
    const trackingProps = useTrackHovering(LogComponent.InsightCard, LogElement.InsightTrendCard, "Insight trend card", LogTarget.Chart);

    const startDate = moment(props.insightUsage.insightStartTime);

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible, setFalse: dismissCallout }] = useBoolean(false);
    const filterButtonId = useId('filterButton');
    const [pivot, setPivot] = React.useState<BreakdownTypeEnum>(BreakdownTypeEnum.Cost);

    const costTrend:[number, (number | undefined)?][] = props.insightUsage.usage.map((unit) => [moment(unit.timeStamp).valueOf(), unit.currentCost]);
    costTrend.sort((a, b) => a[0] - b[0]);
    const seriesInCost: LineChartSeries = {
        color: "#7160E8",
        name: "Pre Anomaly Cost",
        data: props.insightUsage.usage && costTrend,
    };
    
    const usageTrend:[number, (number | undefined)?][] = props.insightUsage.usage.map((unit) => [moment(unit.timeStamp).valueOf(), unit.currentUsage]);
    usageTrend.sort((a, b) => a[0] - b[0]);
    const seriesInUsage: LineChartSeries = {
        color: "#7160E8",
        name: "Pre Anomaly Usage",
        data: props.insightUsage.usage && usageTrend,
    };

    const baseLineUsageTrend:[number, (number | undefined)?][] = props.insightUsage.usage.map((unit) => [moment(unit.timeStamp).valueOf(), unit.baselineUsage]);
    baseLineUsageTrend.sort((a, b) => a[0] - b[0]);
    const baseLineSeriesInUsage: LineChartSeries = {
        color: CommonConstants.MedianColor,
        name: "Median Usage",
        dashStyle: "Dash",
        disableAnomaly: true,
        data: props.insightUsage.usage && baseLineUsageTrend,
    };

    const baseLineCostTrend:[number, (number | undefined)?][] = props.insightUsage.usage.map((unit) => [moment(unit.timeStamp).valueOf(), unit.baselineCost]);
    baseLineCostTrend.sort((a, b) => a[0] - b[0]);
    const baseLineSeriesInCost: LineChartSeries = {
        color: CommonConstants.MedianColor,
        name: "Median Cost",
        dashStyle: "Dash",
        disableAnomaly: true,
        data: props.insightUsage.usage && baseLineCostTrend,
    };

    const baseLineCost = getMedian(baseLineCostTrend);
    const baselineUsage = getMedian(baseLineUsageTrend);

    // Get the latest date.
    const latestTimestamp = Math.max.apply(null, props.insightUsage.usage.map(entry => +moment(entry.timeStamp)));
    const latestDate = moment(latestTimestamp);

    const anomaliesContent: INotification[] = [];
    const anomaly: INotification = { 
        startDate : startDate,
        endDate: moment(latestDate),
        detectionDate: startDate,
        category: "Data abnormal",
        status: "Resolving",
        affectedMetrics: pivot === BreakdownTypeEnum.Cost ? ["Cost"] : ["Usage"],
        dataSourceName: DataSourceEnum.SubstrateV2,
    }

    anomaliesContent.push(anomaly);

    const chartSeries = pivot === BreakdownTypeEnum.Cost ? seriesInCost : seriesInUsage;
    const baseLineSeries = pivot === BreakdownTypeEnum.Cost ? baseLineSeriesInCost : baseLineSeriesInUsage;
    const medianValue = pivot === BreakdownTypeEnum.Cost ? baseLineCost : baselineUsage;
    const isNotCurrency = pivot === BreakdownTypeEnum.Cost ? false : true;

    return (
        <div style={{marginBottom:48}}>
            <SectionHeader title="Details"/>
            <div style={{marginBottom:20, fontSize:20, fontWeight:600}}>Increase in <span style={{fontWeight:600, fontSize:20, color:"#3477EA"}}>{props.impactedResource}</span> since <span style={{fontWeight:600, fontSize:20, color:"#3477EA"}}>{startDate.format("MM/DD/YY")}</span></div>
            <div className={styles.summaryDescriptionText}>
                We detected an increase in your workload since {startDate.format("MM/DD/YYYY")}. To investigate, please follow the recommendations below.
            </div>
            <div className={styles.PivotByContainer} style={{marginBottom:20}}>
                <span><strong>Breakdown by: </strong></span>
                <DefaultButton
                    className={styles.pivotButton}
                    id={filterButtonId}
                    onClick={toggleIsCalloutVisible}>
                    <strong>{getBreakdownPivotString(pivot)}</strong>
                    <Icon iconName="ChevronDown" style={{marginLeft:8}}/>
                </DefaultButton>
                {
                    isCalloutVisible && (
                        <Callout
                            gapSpace={5}
                            target={`#${filterButtonId}`}
                            onDismiss={dismissCallout}
                        >
                            {
                                BreakdownTypePivotList.map(pivotEnum => (
                                    <DefaultButton
                                        className={styles.listItem}
                                        key={pivotEnum}
                                        onClick={() => {
                                            trackEventCallback(LogComponent.InsightCard, LogElement.InsightBreakdownPivot, `Insight Breakdown Pivot Set To: ${pivotEnum}`, LogTarget.Title);
                                            setPivot(pivotEnum);
                                            toggleIsCalloutVisible();
                                        }}
                                    >
                                        {pivot === pivotEnum ? "✔" : "\u00a0\u00a0\u00a0"}
                                        &nbsp;{getBreakdownPivotString(pivotEnum)}
                                    </DefaultButton>
                                ))
                            }
                        </Callout>
                    )
                }
            </div>
            <LineChart
                series={[chartSeries, baseLineSeries]}
                height={220}
                hideEmptySeries={true}
                anomalies={anomaliesContent}
                containerProps={trackingProps}
                isIOInsightsChart={true}
                deviationType="Median"
                medianValue={medianValue}
                isNotCurrency={isNotCurrency}
            />
            
        </div>
    );
}