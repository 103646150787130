import { IBannerMetricData } from "../models/BannerModels";
import { IDateRangeFilterData } from "../models/FilterView";
import { getBannersByTabAndDate } from "../services/BannerService";
import { useDateRange } from "./useDateSelector";
import { useMemo } from "react";
import { useQuery } from "react-query";

export function useGetBannersByTabAndDateQuery(bannerTab: string) {
    const dateRangeFilterData = useDateRange();
    const allBannersQuery = useQuery<IBannerMetricData, Error, IBannerMetricData, [IDateRangeFilterData]>([dateRangeFilterData], getBannersByTabAndDate);

    return useMemo(
        () => ({
            ...allBannersQuery,
            data: bannerTab == 'All' ? allBannersQuery.data : allBannersQuery.data?.filter((item) => item.tab === bannerTab),
        }),
        [allBannersQuery, bannerTab]
    );
}
