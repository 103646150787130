import * as React from "react";

import { ActionButton, Callout, Checkbox, css, DefaultButton, DirectionalHint, IStyle, SearchBox } from "@fluentui/react";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { FiltersAction, FiltersView } from "../../../../reducers/filterReducer";

import { CategoryDivision } from "../../../../models/CategoryDivision";
import { IAppState } from "../../../../store";
import { IServiceTreeData } from "../../../../reducers/serviceTreeReducer";
import { debounce, min } from "lodash";
import { stringOverflowWithEllipsis } from "../../../../reducers/stringUtils";
import styles from "./WorkloadFilter.less";
import { useBoolean } from "@fluentui/react-hooks";
import { useCategoryFilters } from "../../../../hooks/useFilters";
import { useSelector } from "react-redux";
import { FixedSizeList as List } from "react-window";

interface IWorkloadFilterProps {
    hidden?: boolean;
    category: CategoryDivision;
    optionList: string[];
    displayName: string;
    displayStringLimit?: number;
    isSearchable?: boolean;
    getCheckboxLabel?: (key: string) => string;
    isUsedInPanel?: boolean;
}

const WorkloadFilter: React.FC<IWorkloadFilterProps> = ({ hidden, category, optionList, displayName, displayStringLimit = 20, isSearchable = true, getCheckboxLabel: getDisplayString, isUsedInPanel = false }) => {
    const [selectedOwnerText, setSelectedOwnersText] = useState<string>("All");
    const serviceTree = useSelector<IAppState, IServiceTreeData>((state) => state.serviceTree);
    const globalFilters = useCategoryFilters();
    const [selectedKeys, setSelectedKeys] = React.useState<string[]>(globalFilters.filters?.filters[category] || []);
    const [isFocus, setIsFocus] = useState(false);
    const [isSelected, setIsSelected] = useState(false);

    const handleFocus = () => {
        setIsFocus(true);
    };

    const handleAbort = () => {
        setIsFocus(false);
    };

    const buttonRef = React.useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState<number>();
    const [height, setHeight] = useState<number>();
    // This function calculates width and height of the list
    const getComponentSize = () => {
        const newWidth = buttonRef.current?.clientWidth;
        setWidth(newWidth || 0);

        const newHeight = buttonRef.current?.clientHeight;
        setHeight(newHeight || 0);
    };

    useEffect(() => {
        getComponentSize();
      }, [selectedKeys]);
    const [calloutVisible, { toggle: toggleCalloutVisible, setFalse: hideCallout }] = useBoolean(false);
    const filterList = optionList;

    const handleToggleSelect = (key: string, checked: boolean) => {
        const newSelectedKeys = [...selectedKeys];

        if (checked) {
            newSelectedKeys.push(key);
        } else {
            newSelectedKeys.splice(newSelectedKeys.indexOf(key), 1);
        }
        const tempFilter = {...globalFilters.filters.filters};
        tempFilter[category] = newSelectedKeys;
        updateFilters(FiltersAction.Replace, { filters: tempFilter, view: FiltersView.AddableList });
    };

    const handleClearAll = () => {
        const tempFilter = globalFilters.filters.filters;
        tempFilter[category] = [];
        updateFilters(FiltersAction.Replace, { filters: tempFilter, view: FiltersView.AddableList });
    };
    const [searchText, setSearchText] = useState("");

    const handleSearchChange = useCallback(
        debounce((event?: ChangeEvent<HTMLInputElement>, newValue?: string) => {
            setSearchText(newValue?.toLowerCase() || "");
        }, 300),
        [setSearchText]
    );
    const updateFilters = useCategoryFilters().updateFilters;

    React.useEffect(() => {
        let newSelectedKeys = globalFilters.filters?.filters[category] || [];
        if (
            category == CategoryDivision.Division ||
            category == CategoryDivision.Organization ||
            category == CategoryDivision.ServiceGroup ||
            category == CategoryDivision.TeamGroup
        ) {
            const tempSelectedKeys: string[] = [];
            newSelectedKeys.forEach((item) => {
                tempSelectedKeys.push(serviceTree.indexMap.get(item)?.n || item);
            });
            newSelectedKeys = tempSelectedKeys.filter((item) => item.length > 0);
        }
        if (!newSelectedKeys || newSelectedKeys.length < 1) {
            setSelectedOwnersText("All");
            setIsSelected(false);
        } else {
            setIsSelected(true);
            let tempText = "";
            newSelectedKeys.map(key => getDisplayString ? getDisplayString(key) : key).forEach((item) => {
                item = item.trimStart().trimEnd();
                tempText += item + ", ";
            });
            let displayText = stringOverflowWithEllipsis(tempText.substring(0, tempText.length - 2), displayStringLimit);
            if (newSelectedKeys.length > 1) {
                displayText += "(" + newSelectedKeys.length + ")"
            }
            setSelectedOwnersText(displayText);
        }
        setSelectedKeys(newSelectedKeys);
    }, [globalFilters.filters.filters, getDisplayString]);

    const candidates = filterList
        ?.filter((item) => (searchText ? (getDisplayString ? getDisplayString(item) : item).toLocaleLowerCase().includes(searchText.toLowerCase()) : true) && !selectedKeys.includes(item));

    return (
        <>
            {!hidden && (
                <div>
                    <div className={styles.dropDownContent} ref={buttonRef}>
                        <DefaultButton
                            onClick={toggleCalloutVisible}
                            className={isSelected ? styles.dropDownButtonSelected : isFocus ? styles.dropDownButtonFocus : styles.dropDownButton}
                            onFocus={handleFocus}
                            onBlur={handleAbort}
                        >
                            <span>{displayName}:&nbsp;</span>
                            <span style={{ fontWeight: 600 }}>{selectedOwnerText}</span>
                        </DefaultButton>
                    </div>
                    {calloutVisible && (
                        <Callout directionalHint={DirectionalHint.bottomLeftEdge} isBeakVisible={false} target={buttonRef.current} onDismiss={hideCallout}>
                            {isSearchable ? (
                                <>
                                    <SearchBox
                                        className={styles.search}
                                        placeholder={"Search " + displayName}
                                        onChange={handleSearchChange}
                                        defaultValue={searchText}
                                    />
                                    <div className={styles.regionList}>
                                        {selectedKeys.length > 0 &&
                                            selectedKeys.map((item) => (
                                                <Checkbox
                                                    className={styles.listItem}
                                                    checked={selectedKeys.indexOf(item) >= 0}
                                                    label={getDisplayString ? getDisplayString(item) : item}
                                                    key={item}
                                                    id={item}
                                                    onChange={(_, checked) => handleToggleSelect(item, !!checked)}
                                                />
                                            ))}
                                        <List
                                            height={(min([candidates.length, 10]) || 0) * 32 + 16}
                                            itemCount={candidates.length}
                                            itemSize={32}
                                            width={300}>
                                                {({ index, style }) => {
                                                    const item = candidates[index];
                                                    return (
                                                        <Checkbox
                                                            className={styles.listItem}
                                                            checked={selectedKeys.indexOf(item) >= 0}
                                                            label={getDisplayString ? getDisplayString(item) : item}
                                                            key={item}
                                                            id={item}
                                                            onChange={(_, checked) => handleToggleSelect(item, !!checked)}
                                                            styles={{ root: {...style} as (IStyle | undefined) }}
                                                        />
                                                    )
                                                }}
                                        </List>
                                    </div>
                                </>
                            ) : (
                                <div className={styles.regionList}>
                                    {filterList.map((item) => (
                                        <Checkbox
                                            className={styles.listItem}
                                            checked={selectedKeys.indexOf(item) >= 0}
                                            label={getDisplayString ? getDisplayString(item) : item}
                                            key={item}
                                            id={item}
                                            onChange={(_, checked) => handleToggleSelect(item, !!checked)}
                                        />
                                    ))}
                                </div>
                            )}
                            <ActionButton
                                onClick={handleClearAll}
                                primaryDisabled={selectedKeys.length === 0}
                                className={styles.clearButton}
                                iconProps={{ iconName: "Cancel" }}
                                disabled={selectedKeys.length === 0}
                            >
                                Clear All
                            </ActionButton>
                        </Callout>
                    )}
                </div>
            )}
        </>
    );
};

export default WorkloadFilter;
