import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey } from "../models/SearchKey";

export function useRings(): [string[], (rings?: string[]) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const rings: string[] = useMemo(() => {
        const ringsStr = searchParams.get(SearchKey.Rings);
        if (!ringsStr) {
            return [];
        }
        return JSON.parse(ringsStr);
    }, [searchParams]);

    const setRings = useCallback((newRings?: string[]) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (!newRings || newRings.length === 0) {
            newSearchParams.delete(SearchKey.Rings);
        } else {
            newSearchParams.set(SearchKey.Rings, JSON.stringify(newRings));
        }

        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return [rings, setRings];
}