import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { CosmosMetricEnum, CosmosSourceTypeEnum, ICosmosCost } from "../models/CosmosModels";
import { IDailyMetricReport } from "../models/Metric";
import { ServiceTreeItem } from "../models/serviceTree";
import { batchGetCosmosDailyMetric, getCosmosCostByGem, getCosmosCostByServices, getCosmosDailyMetrics } from "../services/cosmosService";
import { IAppState } from "../store";
import { Endpoints } from "../utils/Constants";
import { useDateRange } from "./useDateSelector";
import { useCategoryFilters } from "./useFilters";

export function useGetCosmosDailyMetrics(type: CosmosSourceTypeEnum, metricType: CosmosMetricEnum) {
    const globalFilters = useCategoryFilters().filters;
    const range = useDateRange();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);

    return useQuery<
        IDailyMetricReport,
        Error,
        IDailyMetricReport,
        Parameters<typeof getCosmosDailyMetrics>['0']['queryKey']
    >([Endpoints.GetCosmosDailyMetrics, globalFilters.filters, serviceIdMap, range.startDate, range.endDate, type, metricType], getCosmosDailyMetrics);
}

export function useBatchGetCosmosDailyMetrics(sources: CosmosSourceTypeEnum[], metric: CosmosMetricEnum) {
    const globalFilters = useCategoryFilters().filters;
    const range = useDateRange();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);

    return useQuery<
        IDailyMetricReport[],
        Error,
        IDailyMetricReport[],
        Parameters<typeof batchGetCosmosDailyMetric>['0']['queryKey']
    >([Endpoints.GetCosmosDailyMetrics, globalFilters.filters, serviceIdMap, sources, metric, range.startDate, range.endDate], batchGetCosmosDailyMetric);
}

export function useGetCosmosCostByServices(type: CosmosSourceTypeEnum) {
    const globalFilters = useCategoryFilters().filters;
    const range = useDateRange();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);

    return useQuery<
        ICosmosCost[],
        Error,
        ICosmosCost[],
        Parameters<typeof getCosmosCostByServices>['0']['queryKey']
    >([Endpoints.GetCosmosCostByServices, globalFilters.filters, serviceIdMap, range.startDate, range.endDate, type], getCosmosCostByServices);
}

export function useGetCosmosCostByGEM(type: CosmosSourceTypeEnum) {
    const range = useDateRange();

    return useQuery<
        Record<string, number>,
        Error,
        Record<string, number>,
        Parameters<typeof getCosmosCostByGem>['0']['queryKey']
    >([Endpoints.GetCosmosCostByGEM, range.startDate, range.endDate, type], getCosmosCostByGem);
}
