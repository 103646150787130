import { PcmV2Cost, PcmV2MetricKeys, PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum, PcmV2WorkloadType } from "../PcmV2Models";

import moment from "moment";
import { LogComponent } from "../LogModel";

export const PcmV2CostThreshold = 10;

export const PcmV2CostMetrics: Array<PcmV2MetricKeys> = [
    "itemReadCost",
    "itemWriteCost",
    "itemQueryCost",
    "processHostingCost",
    "networkCost",
    "hddCost",
    "ssdmcdbCost",
    "ssdKvCacheCost",
];

export const PcmV2SceneCostMetric: Record<PcmV2SceneTypeEnum, PcmV2MetricKeys> = {
    [PcmV2SceneTypeEnum.Overview]: "cost",
    [PcmV2SceneTypeEnum.Transaction]: "transactionCost",
    [PcmV2SceneTypeEnum.Network]: "networkCost",
    [PcmV2SceneTypeEnum.HDD]: "hddCost",
    [PcmV2SceneTypeEnum.ProcessHosting]: "processHostingCost",
    [PcmV2SceneTypeEnum.SSD]: "ssdCost",
};

export const PcmV2SubSceneCostMetric: Partial<Record<PcmV2SubSceneTypeEnum, PcmV2MetricKeys>> = {
    [PcmV2SubSceneTypeEnum.SSDDatasetCost]: "ssdmcdbCost",
    [PcmV2SubSceneTypeEnum.SSDMailboxCost]: "ssdmcdbCost",
};

export const PcmV2SceneMetrics: Record<PcmV2SceneTypeEnum, Array<PcmV2MetricKeys>> = {
    [PcmV2SceneTypeEnum.Overview]: [
        "itemReadCost",
        "itemWriteCost",
        "itemQueryCost",
        "processHostingCost",
        "networkCost",
        "hddCost",
        "ssdmcdbCost",
        "ssdKvCacheCost",
    ],
    [PcmV2SceneTypeEnum.Transaction]: ["itemReadCost", "itemWriteCost", "itemQueryCost"],
    [PcmV2SceneTypeEnum.Network]: ["logBytesCost", "bytesReturnedCost"],
    [PcmV2SceneTypeEnum.HDD]: ["hddCost"],
    [PcmV2SceneTypeEnum.ProcessHosting]: ["processHostingCost"],
    [PcmV2SceneTypeEnum.SSD]: ["ssdmcdbCost", "ssdKvCacheCost"],
};

export const PcmV2SubSceneMetrics: Partial<Record<PcmV2SubSceneTypeEnum, Array<PcmV2MetricKeys>>> = {
    [PcmV2SubSceneTypeEnum.SSDDatasetCost]: ["ssdmcdbCost"],
    [PcmV2SubSceneTypeEnum.SSDMailboxCost]: ["ssdmcdbCost"],
};

export const SubstrateLogComponent: Record<PcmV2SceneTypeEnum, LogComponent> = {
    [PcmV2SceneTypeEnum.Overview]: LogComponent.SubstrateV2,
    [PcmV2SceneTypeEnum.Transaction]: LogComponent.TransactionV2,
    [PcmV2SceneTypeEnum.Network]: LogComponent.NetworkV2,
    [PcmV2SceneTypeEnum.HDD]: LogComponent.HddV2,
    [PcmV2SceneTypeEnum.ProcessHosting]: LogComponent.ProcessHostingV2,
    [PcmV2SceneTypeEnum.SSD]: LogComponent.SsdV2,
};

export const PcmV2MetricNames: Partial<Record<PcmV2MetricKeys, string>> = {
    transactionCost: "Transaction",
    processHostingCost: "Process Hosting",
    networkCost: "Network",
    hddCost: "HDD-Storage",
    ssdCost: "SSD-Storage",
    ssdmcdbCost: "SSD-Storage MCDB",
    ssdKvCacheCost: "SSD-Storage KvCache",
    itemReadCost: "Transaction Item-Reads",
    itemWriteCost: "Transaction Item-Writes",
    itemQueryCost: "Transaction Item-Queries",
    logBytesCost: "Log Bytes",
    bytesReturnedCost: "Bytes Returned",
};

export const SubstrateRings = ["SDFV2", "MSIT", "SIP", "WW"];

export const DefaultPcmV2Cost: PcmV2Cost = {
    cost: 0,
    transactionCost: 0,
    processHostingCost: 0,
    processHostingCpuCost: 0,
    processHostingMemoryCost: 0,
    networkCost: 0,
    hddCost: 0,
    ssdCost: 0,
    ssdmcdbCost: 0,
    ssdKvCacheCost: 0,
    itemReadCost: 0,
    itemWriteCost: 0,
    itemQueryCost: 0,
    itemReadPeakUsage: 0,
    itemWritePeakUsage: 0,
    itemQueryPeakUsage: 0,
    itemReadOffPeakUsage: 0,
    itemWriteOffPeakUsage: 0,
    itemQueryOffPeakUsage: 0,
    offPeakItemQueriesCount: 0,
    offPeakItemReadsCount: 0,
    offPeakItemWritesCount: 0,
    peakHourItemQueriesCount: 0,
    peakHourItemReadsCount: 0,
    peakHourItemWritesCount: 0,
    itemReadCount: 0,
    itemWriteCount: 0,
    itemQueryCount: 0,
    ssdmcdbUsage: 0,
    ssdKvCacheUsage: 0,
    bytesReturnedCost: 0,
    peakBytesReturned: 0,
    offPeakBytesReturned: 0,
    logBytesCost: 0,
    peakLogBytes: 0,
    offPeakLogBytes: 0,
    processHostingUsage: 0,
    dailyCpuCores: 0,
    dailyMemoryMB: 0,
    hddUsage: 0,
    startDate: moment(),
    endDate: moment(),
    extractionDate: moment(),
    identity: {
        identity: "",
        workload: "",
        ring: "",
    },
};

export const SubstrateV2WikiLink = "https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/217980";

export const AzureWikiLink = "https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/73825/Azure-Cost-Overview";
