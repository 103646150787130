import { ActionButton, Stack } from '@fluentui/react';
import { AzureComputeDetailTableOffset, AzureComputeDetailTableStyles } from './common/DetailTableConstants';
import { AzureCostTableTag, IAzureComputeServiceDetail } from '../../../models/AzureComputeModels';
import { AzureMycroftServiceDetailsColumnNames, AzureServiceDetailsColumnNames, AzureServiceDetailsColumnNamesV3 } from '../common/AzureComputeConstants';
import React, { useCallback, useEffect } from 'react';
import { getCellOption, getFooterOption, getHeaderOption, useAzureComputeHeatmapParams, useAzureComputeHeatmapQuery, useDetailTable, useDetailTableColumnSelection } from './common/DetailTableUtils';
import { useAzureSubscriptionSearchInfo, useGetAzureComputeServiceDetail } from '../../../hooks/useAzureQuery';
import { useDispatch, useSelector } from 'react-redux';

import { ColumnSelector } from '../../common/table/ColumnSelector';
import { FilterList } from "../../filter/filterList/FilterList";
import { HeatmapIndicator } from './HeatmapIndicator';
import { IAppState } from '../../../store';
import { SectionHeader } from '../../common/SectionHeader';
import { ServiceTreeItem } from '../../../models/serviceTree';
import StickyTable from '../../common/table/StickyTable';
import commonStyles from './common/DetailTable.less';
import { difference } from 'lodash';
import { downloadAzureComputeServiceHeatmap } from '../../../services/azureComputeService';
import { useCategoryFilters } from '../../../hooks/useFilters';
import { useDateRange } from '../../../hooks/useDateSelector';
import { useFlights } from '../../../hooks/useSettings';
import { useRemap } from "../../../hooks/useRemap";
import { useBoolean } from "@fluentui/react-hooks";
import { useUserPreference } from '../../../utils/preference/useUserPreference';
import { useSpotVM } from '../../../hooks/useSpotVM';

interface IAzureComputeServiceDetailProps {
    type: 'Total' | 'Public' | 'AZSC';
    defaultSorting?: string;
}

const ServiceDetail: React.FC<IAzureComputeServiceDetailProps> = ({ type, defaultSorting }) => {
    const enableMycroftData = useFlights().data?.enableMycroftData;
    const enableBillingCost = useFlights().data?.enableBillingCost;

    const enableAzureV3 = useFlights().data?.enableV3;
    const columns = React.useMemo(() => {
        const columns = enableAzureV3 ? AzureServiceDetailsColumnNamesV3 :
        (enableMycroftData ? AzureMycroftServiceDetailsColumnNames : AzureServiceDetailsColumnNames);
        if (!enableBillingCost) {
            return columns.filter(x => x !== 'billingCost');
        } else {
            return columns;
        }
    }, [enableBillingCost, enableMycroftData]);
    const heatmapParams = useAzureComputeHeatmapParams(type, columns, undefined, defaultSorting, ['serviceName', 'serviceDevOwners', 'servicePMOwners']);
    const query = useAzureComputeHeatmapQuery(useGetAzureComputeServiceDetail, type, heatmapParams);
    const {singleDate: date, startDate, endDate} = useDateRange();
    const [showRemap] = useRemap();
    const [showSpotVM] = useSpotVM();

    const searchFilters = useCategoryFilters().filters.filters;
    const dispatch = useDispatch();
    const subscriptions = useAzureSubscriptionSearchInfo();
    const { data: flights } = useFlights();
    const [isDownloadingEnabled, {setFalse: disableDownloading, setTrue: enableDownloading}] = useBoolean(true);

    const [preferedTableConfig, setPreferedTableConfig] = useUserPreference("azureComputeServiceDetail");

    const tableInstance = useDetailTable<IAzureComputeServiceDetail>(
        type,
        AzureCostTableTag.Service,
        columns,
        query.data,
        query.sort.setSort,
        query.sort.initialSortDesc,
        query.sort.initialSortKey || "totalCores",
        preferedTableConfig?.hiddenColumns || []
    );

    const { visibleColumns, changeVisibleColumns, columnSelectorOptions } = useDetailTableColumnSelection(tableInstance);
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);

    const handleDownload = useCallback(() => {
        disableDownloading();
        downloadAzureComputeServiceHeatmap(date, heatmapParams.filtersData, searchFilters, serviceIdMap, subscriptions, heatmapParams.sortByKey || "TotalCores", heatmapParams.sortDesc, type, columns, startDate, endDate, showRemap, showSpotVM, flights?.enableDateRange, enableDownloading);
    }, [disableDownloading, date, heatmapParams.filtersData, heatmapParams.sortByKey, heatmapParams.sortDesc, searchFilters, serviceIdMap, subscriptions, type, columns, startDate, endDate, showRemap, showSpotVM, flights?.enableDateRange, enableDownloading]);

    useEffect(() => {
        setPreferedTableConfig({ hiddenColumns: difference(columns, visibleColumns) as (keyof IAzureComputeServiceDetail)[] });
    }, [dispatch, visibleColumns]);

    return (
        <div className={commonStyles.detailTableView}>
            <SectionHeader
                title="Service Details"
                extra={(
                    <Stack horizontal verticalAlign="center" horizontalAlign="end" className={commonStyles.headerExtra}>
                        <HeatmapIndicator />
                        <ActionButton onClick={handleDownload} iconProps={{ iconName: 'Download' }} text={isDownloadingEnabled ? "Download" : "Downloading"} disabled={!isDownloadingEnabled} />
                        <ColumnSelector onConfirm={changeVisibleColumns} columns={columnSelectorOptions} initSelectedKeys={visibleColumns} />
                    </Stack>
                )}
            />
            <FilterList data={query.localFilters.data} options={query.localFilters.options} onChange={query.localFilters.setFilters} />
            <StickyTable
                styles={AzureComputeDetailTableStyles}
                cellOption={getCellOption}
                headerOption={getHeaderOption}
                footerOption={getFooterOption}
                loading={query.isInitialLoading}
                loadMoreText="Show more services"
                emptyText="The selected services don't have data"
                loadMoreLoadingText={`Loading more ${query.paging.pageSize} services...`}
                loadMoreLoading={query.isLoadingMore}
                loadMore={query.hasMore}
                onLoadMore={query.paging.loadMore}
                table={tableInstance}
                stickyTarget="#pageContainer"
                stickyPositon={AzureComputeDetailTableOffset}
            />
        </div>
    );
};

ServiceDetail.defaultProps = {
    defaultSorting: "TotalCores",
}

export default ServiceDetail;
