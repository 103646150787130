import { Reducer } from "redux";
import { ActionTypes } from "../actions/ActionTypes";
import { IToggleSearchPanelAction } from "../actions/SearchPanelActions";
import { CategoryDivision } from "../models/CategoryDivision";

export interface ISearchPanelData {
    isOpen: boolean;
    defaultCategory?: CategoryDivision;
}

const initialState: ISearchPanelData = {
    isOpen: false,
    defaultCategory: undefined,
};

export const searchPanelReducer: Reducer<ISearchPanelData, IToggleSearchPanelAction> = function (state = initialState, action): ISearchPanelData {
    switch (action.type) {
        case ActionTypes.ToggleSearchPanelAction:
            if (state.isOpen != action.open) return { ...state, isOpen: action.open, defaultCategory: action.defaultCategory};
            break;
    }

    return state;
}

export function makeTogglePanelAction(open: boolean, defaultCategory?: CategoryDivision): IToggleSearchPanelAction {
    return {
        type: ActionTypes.ToggleSearchPanelAction,
        open,
        defaultCategory
    };
}