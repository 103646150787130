import { Icon, Stack, Text } from '@fluentui/react';
import { getColor, grayBarColor } from './CostCardUtils';

import React from 'react';
import { currencyFormatter } from '../../../utils/currency';
import moment from 'moment';
import styles from './CostCardTooltipContent.less';

export interface ICostCardTooltipContentProps {
    date?: moment.Moment[];
    items: Array<{
        title: string;
        color: string | number;
        cost: number;
        deviationFrom?: number;
        hasError?: boolean,
    }>;
    errors?: Array<{
        title: string;
        message: string;
    }>;
    errorCollapseMessage?: string;
    onShowMoreError?: () => void;
    noPrefix?: boolean;
    suffix?: string;
    dateFormatStr?: string;
    formatter?: (value: number) => string;
    errorCollapseLimit?: number;

    smallCard?: boolean;
    totalText?: string;
}

const CostCardTooltipContent: React.FC<ICostCardTooltipContentProps> = (props) => {
    const { noPrefix, suffix, formatter, errors, items, errorCollapseLimit } = props;

    const renderErrors = () => {
        if (!errors || errors.length == 0) return;

        return <>
            <div className={styles.separator}></div>
            {(errorCollapseLimit ? errors.slice(0, errorCollapseLimit) : errors).map(error => (
                <div key={error.message} className={styles.errorBlock}>
                    <Stack horizontal className={styles.errorTitle}>
                        <Icon iconName="warning" className={styles.warningIcon} /> {error.title}
                    </Stack>
                    <div className={styles.errorMessage}>
                        {error.message}
                    </div>
                </div>
            ))}
            {!!errorCollapseLimit && (errors.length > errorCollapseLimit) && (
                <>
                    <div className={styles.errorCollapseMessage}>{props.errorCollapseMessage || `${errors.length} anomaly issues detected on the selected date`}</div>
                    <div className={styles.showMoreErrorButton} onClick={props.onShowMoreError}>Show all results ({errors.length})</div>
                </>
            )}
        </>;
    };

    const showDeviation = !!props.items.find(item => item.deviationFrom != undefined);
    const showItemError = !!props.items.find(item => item.hasError);

    return (
        <div className={styles.container}>
            {props.date && props.date.length > 0 && (
                <div className={styles.costTooltipTitle}>
                    {`${props.date[0].format(props.dateFormatStr)}${props.date.length > 1 ? ` - ${props.date[1].format(props.dateFormatStr)}` :""}`}
                </div>)}
            {(showDeviation || props.totalText) &&
                <Stack horizontal className={styles.costTooltipTitle}>
                    <Stack.Item grow={1} className={styles.costTooltipTitleText}>{props.totalText || "Metric Cost"}</Stack.Item>
                    { showDeviation && <span className={styles.costTooltipTitleDeviation}>Deviation%</span> }
                    { showItemError && <span className={styles.costTooltipTitleErrorPlaceholder} /> }
                </Stack>}
            {items.map(({ title, cost, color, deviationFrom, hasError }) => {
                const stickColor = { backgroundColor: cost === 0 ? grayBarColor : getColor(color) };
                const deviation = deviationFrom != undefined ? deviationFrom == 0 ? 0 : (cost - deviationFrom) / deviationFrom : undefined;
                const negativeDeviation = deviation !== undefined && deviation < 0;

                return (
                    props.smallCard ? (
                        <Stack horizontal verticalAlign='baseline' key={title} className={styles.costItem}>
                            <div className={styles.costItemSmallStick} style={stickColor} />
                            <Text block className={styles.costItemSmallTitle}>{title}</Text>
                            <Text block className={styles.costItemSmallCost}>
                                {formatter ? formatter(cost) : currencyFormatter(cost, 2, noPrefix ? "" : "$") + (suffix || "")}
                            </Text>
                        </Stack>
                    ) : (
                        <Stack horizontal verticalAlign="stretch" horizontalAlign="center" key={title} className={styles.costItem}>
                            <div style={stickColor} className={styles.costItemStick} />
                            <Stack.Item grow={1} style={{ minWidth: 0 }}>
                                <Text block className={styles.costItemTitle}>{title}</Text>
                                <Stack horizontal className={styles.costItemContent}>
                                    <Stack.Item grow={1} className={styles.costItemCost}>
                                        {formatter ? formatter(cost) : currencyFormatter(cost, 2, noPrefix ? "" : "$") + (suffix || "")}
                                    </Stack.Item>
                                    {deviation !== undefined && (
                                        <div className={`${styles.deviation} ${negativeDeviation ? styles.negativeDeviation : styles.positiveDeviation}`}>
                                            {deviation == 0 ? "" : deviation < 0 ? "-" : "+"}{Math.abs(deviation*100).toFixed(2)}%
                                        </div>
                                    )}
                                    {showItemError && (
                                        <div className={styles.warningIconWrapper}>
                                            {hasError && <Icon iconName="warning" className={styles.warningIcon} />}
                                        </div>
                                    )}
                                </Stack>
                            </Stack.Item>
                        </Stack>
                ));
            })}
            {renderErrors()}
        </div>
    );
};

CostCardTooltipContent.defaultProps = {
    errorCollapseLimit: 3,
    dateFormatStr: "YYYY/MM/DD"
}

export default CostCardTooltipContent;
