import { CosmicSourceTypeEnum, ICosmicHierarchicalMetricReport, ICosmicHierarchicalWorkloadReport } from "../../models/CosmicModels";
import { Link, MessageBar, MessageBarBody, MessageBarTitle } from "@fluentui/react-components";
import { LogComponent, LogElement } from "../../models/LogModel";

import CosmicSelector from "./CosmicSelector";
import { LineChartContainer } from "../azure/common/LineChartContainer";
import PartnerMetricsCard from "./PartnerMetricsCard";
import PartnerServiceDetailTable from "./PartnerServiceDetailTable";
import React from "react";
import commonStyles from "../common/styles/Common.less";
import styles from './CosmicView.less';
import { useGetCosmicWorkloadDailyAppMetric } from "../../hooks/useCosmicQuery";

interface IPartnerViewProps {
    type: CosmicSourceTypeEnum;
}

const getMetricData = (metricName: string, metricsType: ICosmicHierarchicalMetricReport[]) => {
    if (!metricsType) return [];

    return metricsType
        .filter((singleMetric) => singleMetric.metric === metricName)
        .flatMap((singleMetric) => singleMetric.data);
};

const PartnerView: React.FC<IPartnerViewProps> = (props) => {
    const { cosmicHierarchicalMetricReport: appCoresWorkloadMetrics, isLoading: isLoadingAppCores } = useGetCosmicWorkloadDailyAppMetric("AppCores");
    const { cosmicHierarchicalMetricReport: appCoreCogsWorkloadMetrics, isLoading: isLoadingAppCoreCogs } = useGetCosmicWorkloadDailyAppMetric("AppCoreCogs");

    const metricDataConfig = [
        { title: "Cosmic App Request Cores", metric: "CpuRequestCores", logElement: LogElement.CosmicAppRequestCores, workloads: getMetricData("CpuRequestCores", appCoresWorkloadMetrics) },
        { title: "App Core COGS", metric: "AppCoreCogs", logElement: LogElement.CosmicAppCoreCOGS, workloads: getMetricData("AppCoreCogs", appCoreCogsWorkloadMetrics) },
        { title: "Cosmic App Limit Cores", metric: "CpuLimitCores", logElement: LogElement.CosmicAppLimitCores, workloads: getMetricData("CpuLimitCores", appCoresWorkloadMetrics) },
        { title: "Cosmic App Empty Cores", metric: "EmptyAppCores", logElement: LogElement.CosmicAppEmptyCores, workloads: getMetricData("EmptyAppCores", appCoresWorkloadMetrics) }
    ];

    const utilizationMetricCard = {
        title: "P99 CPU Utilization",
        metric: "CpuUtilizationP99",
        logElement: LogElement.CosmicAppP99CPUUtliization,
        data: getMetricData("CpuUtilizationP99", appCoresWorkloadMetrics)
    };

    return (
        <div className={styles.cardContainer}>
            <InfoMessage />
            <LinkMessage />
            <CosmicSelector />
            <PartnerMetricsCard appCoreCogs={appCoreCogsWorkloadMetrics} isLoadingAppCoreCogs={isLoadingAppCoreCogs} />

            <MetricsCharts
                metricsConfig={metricDataConfig}
                isLoadingAppCores={isLoadingAppCores}
                isLoadingAppCoreCogs={isLoadingAppCoreCogs}
                type={props.type}
            />

            <LineChartContainer
                key={utilizationMetricCard.title}
                title={utilizationMetricCard.title}
                isLoading={!utilizationMetricCard.data}
                isNotCurrency
                series={utilizationMetricCard.data.map(singleMetric => ({
                    name: singleMetric.workload,
                    data: singleMetric.data.map(metric => [metric.date.valueOf(), (metric.metricValue ?? 0) * 100])
                }))}
                logProps={{
                    logComponent: LogComponent.CosmicPartnerView,
                    logElement: utilizationMetricCard.logElement,
                    customProperties: { type: props.type }
                }}
                fillMissingAbnormalData
                suffix="%"
                showArea
                anomalies={[]}
            />
           <div style={{ width: "100%", overflowX: "scroll" }}>
               {/* <PartnerDetailTable type={props.type}/> */}
               <PartnerServiceDetailTable defaultSorting="CpuRequestCores"/>
           </div>
        </div>
    );
};

// Renders multiple metric LineCharts
const MetricsCharts: React.FC<{ metricsConfig: { title: string; metric: string; logElement: LogElement; workloads: ICosmicHierarchicalWorkloadReport[] }[], isLoadingAppCores: boolean, isLoadingAppCoreCogs: boolean, type: CosmicSourceTypeEnum }> = ({ metricsConfig, isLoadingAppCores, isLoadingAppCoreCogs, type }) => (
    <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`} style={{ padding: "0 0 24px 0" }}>
        {metricsConfig.map(({ title, logElement, workloads }) => (
            <LineChartContainer
                key={title}
                title={title}
                isLoading={title.includes("COGS") ? isLoadingAppCoreCogs : isLoadingAppCores}
                isNotCurrency
                series={workloads.map(singleMetric => ({
                    name: singleMetric.workload,
                    data: singleMetric.data.map(metric => [metric.date.valueOf(), metric.metricValue])
                }))}
                logProps={{
                    logComponent: LogComponent.CosmicPartnerView,
                    logElement,
                    customProperties: { type }
                }}
                fillMissingAbnormalData
                showArea
                anomalies={[]}
            />
        ))}
    </div>
);

const InfoMessage = () => (
    <MessageBar intent="info" style={{ marginBottom: "24px" }}>
        <MessageBarBody>
            <MessageBarTitle>Important</MessageBarTitle>
            AIRS typically locks the data for the last month on a specific date. Therefore, please be aware that the COGS values for the last month may be refreshed on certain dates.<br />
        </MessageBarBody>
    </MessageBar>
);

const LinkMessage = () => (
    <MessageBar intent="success" style={{ marginBottom: "24px" }}>
        <MessageBarBody>
            <MessageBarTitle>SoverCloud Dashboard</MessageBarTitle>
            <Link href="https://cosmicmonitoring-b6a0cza8a4ghfnda.scus.grafana.azure.com/d/f3862af2-0222-42e9-9c7d-f79f0f4f56d0/cosmicjaws-sovcloud?orgId=1&from=now-30d&to=now&var-Namespace=All&var-Region=All&var-OS=All&var-Ring=All&var-Workload=All" target="__blank">
                Click this link to access our Sovereign Cloud Dashboard.
            </Link>
        </MessageBarBody>
    </MessageBar>
);
export default PartnerView;