import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import styles from "./Chart.less";
import { currencyFormatter, formatNumber } from "../../utils/currency";
import { chartLegendOptions, chartStyles, chartTooltipOptions } from "../../utils/chartOptions";
import CommonConstants from "../../models/constants/CommonConstants";

export interface ColumnChartProps {
    seriesHeader: string;
    x: string[];
    y: number[]; 
    containerProps?: React.HTMLProps<HTMLElement>,
    showValue?: boolean;
    isCurrency?: boolean;
}

const ColumnChart: React.FC<ColumnChartProps> = ({x, y, seriesHeader, containerProps, showValue, isCurrency }) => {
    const options: Highcharts.Options = {
        chart: {
            type: "column",
            style: chartStyles,
            height: 240
        },
        title: {
            text: undefined
        },
        xAxis: {
            categories: x,
            height: 128
        },
        yAxis: {
            title: {
                text: undefined
            },
            height: 128
        },
        credits: {
            enabled: false
        },
        tooltip: {
            ...chartTooltipOptions,
            padding: 12,
            headerFormat: `<span class="${styles.tooltipHeader}">{point.key}</span>`,
            pointFormatter: function () {
                return `
                    <div class="${styles.tooltipContent}">
                        <div class="${styles.tooltipBar}" style="background-color: ${this.color}"></div>
                        <span class="${styles.tooltipTitle}">${this.series.name}</span>
                        <span class="${styles.tooltipValue}" style="color: ${this.color}">${formatNumber(this.y || 0)}</span>
                    </div>`;
            }
        },
        legend: {
            ...chartLegendOptions,
            padding: 0
        },
        series: [{
            type: "column",
            name: seriesHeader,
            data: y
        }],
        colors: CommonConstants.DefaultColors,
        plotOptions: {
            column: {
                pointWidth: 16,
                dataLabels: {
                    enabled: !!showValue,
                    formatter: function () {
                        return `${currencyFormatter(this.point.y || 0, 2, isCurrency ? "$" : "")}`;
                    },
                }
            }
        }
    };
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={containerProps}
        />
    );
}

export default React.memo(ColumnChart);