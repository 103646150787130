import { Callout, CommandBar, CommandButton, DatePicker, DefaultButton, DirectionalHint, ICommandBarItemProps, IStackTokens, PrimaryButton, Stack } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import moment, { months } from "moment";
import * as React from "react";
import { useDateRange } from "../../hooks/useDateSelector";
import { LogComponent, LogElement, LogTarget } from "../../models/LogModel";
import { getTrackEventClickCallback, trackEventCallback } from "../../utils/AppInsights";
import styles from "./DateRangePicker.less";

const outlineStackTokens: IStackTokens = {
    childrenGap: 8
};

interface IDateRangePickerProps {
    maxDateOffset?: number;
}

// Refer to https://o365exchange.visualstudio.com/O365%20Core/_git/Jaws?path=/sources/dev/Frontends/Jaws%20Portal/src/Components/TimeScale.tsx&version=GBmaster&_a=contents
const DateRangePicker: React.FC<IDateRangePickerProps> = (props) => {
    const buttonRef = React.useRef<HTMLDivElement>(null);

    const {startDate, endDate, setDateRange} = useDateRange();
    const [showCallout, {toggle: toggleCallout, setFalse: dismissCallout}] = useBoolean(false);
    const [localFilters, setLocalFilters] = React.useState({startDate, endDate});

    const togglePickerCallout = React.useCallback(() => {
        if (!showCallout) {
            setLocalFilters({startDate, endDate});
        }
        toggleCallout();
    }, [endDate, showCallout, startDate, toggleCallout]);

    const errorMessage = React.useMemo(() => {
        return localFilters.startDate.isSameOrBefore(localFilters.endDate) ? undefined : "Invalid date range.";
    }, [localFilters]);

    const dateFormatter = React.useCallback((date?: Date) => {
        return moment(date).format("YYYY-MM-DD");
    }, []);

    const maxDateOffset = props.maxDateOffset ? props.maxDateOffset : 7;
    const maxEndDate = React.useMemo(() => moment().subtract(maxDateOffset, 'day').toDate(), [maxDateOffset]);

    const applyLocalFilters = React.useCallback(() => {
        if (localFilters.startDate > localFilters.endDate) {
            return;
        }
        const maxEndMoment = moment().subtract(maxDateOffset, 'day');
        if (localFilters.endDate.isAfter(maxEndMoment, 'date')) {
            localFilters.endDate = maxEndMoment;
        }

        setDateRange(localFilters.startDate, localFilters.endDate);
        dismissCallout();
        trackEventCallback(LogComponent.PivotHeadPane, LogElement.TimeRange, "UTC Date", LogTarget.Button, {startDate: localFilters.startDate, endDate: localFilters.endDate})
    }, [localFilters.startDate, localFilters.endDate, setDateRange, dismissCallout]);

    React.useEffect(() => {
        const maxEndMoment = moment().subtract(maxDateOffset, 'day');
        if (localFilters.endDate.isAfter(maxEndMoment, 'date')) {
            localFilters.endDate = maxEndMoment;
        }
        setDateRange(localFilters.startDate, localFilters.endDate);
    }, [maxDateOffset])

    const shortcuts: ICommandBarItemProps[] = React.useMemo(() => [
        {
            key: "last30Days",
            text: "Last 30 Days",
            onClick: () => {
                const endDate = moment(maxEndDate);
                setLocalFilters({startDate: moment(endDate).subtract(30, "days"), endDate, });
            },
        },
        {
            key: "lastMonth",
            text: "Last Month",
            onClick: () => {
                const startOfMonth = moment().startOf("month");
                let endDate = startOfMonth.subtract(1, "day");
                const startDate = moment(endDate).startOf("month");

                if (!endDate.isSameOrBefore(maxEndDate, "date")) {
                    endDate = moment(maxEndDate);
                }

                setLocalFilters({startDate: startDate, endDate })
            }
        },
        {
            key: "lastQuarter",
            text: "Last Quarter",
            onClick: () => {
                const startOfQuarter = moment().startOf("quarter");
                let endDate = startOfQuarter.subtract(1, "day");

                if (!endDate.isSameOrBefore(maxEndDate, "date")) {
                    endDate = moment(maxEndDate);
                }

                setLocalFilters({startDate: moment(startOfQuarter).subtract(1, "quarter"), endDate })
            }
        }
    ], []);


    const onChangeStartDate = React.useCallback((date?: Date | null) => {
        if (date) {
            setLocalFilters({...localFilters, startDate: moment(date)})
        }
    }, [localFilters]);

    const onChangeEndDate = React.useCallback((date?: Date | null) => {
        if (date) {
            setLocalFilters({...localFilters, endDate: moment(date)})
        }
    }, [localFilters]);

    const filtersText = React.useMemo(() => (
        `UTC Date: [${startDate.format("YYYY-MM-DD")}] - [${endDate.format("YYYY-MM-DD")}]`
    ), [endDate, startDate]);

    return (
        <Stack horizontal verticalAlign="center" tokens={outlineStackTokens}>
            <div className={styles.buttonArea} ref={buttonRef}>
                <CommandButton
                    iconProps={{iconName: "Calendar", color: "#0078D4" }}
                    text={filtersText}
                    checked={showCallout}
                    onClick={togglePickerCallout}
                    title="Modify dates"
                    menuIconProps={{iconName: "ChevronDown"}}
                />
            </div>
            <Callout
                target={buttonRef}
                hidden={!showCallout}
                onDismiss={dismissCallout}
                className={styles.callout}
                directionalHint={DirectionalHint.bottomRightEdge}
                styles={{beak: styles.calloutBeak}}
            >
                <CommandBar
                    items={shortcuts}
                    styles={{root: styles.commandBar}}
                />
                <DatePicker
                    label="From"
                    formatDate={dateFormatter}
                    highlightSelectedMonth
                    value={localFilters.startDate.toDate()}
                    onSelectDate={onChangeStartDate}
                    maxDate={maxEndDate}
                />
                <div className={styles.endDatePickerArea}>
                    <DatePicker
                        label="To"
                        formatDate={dateFormatter}
                        highlightSelectedMonth
                        value={localFilters.endDate.toDate()}
                        onSelectDate={onChangeEndDate}
                        maxDate={maxEndDate}
                        textField={{ errorMessage: errorMessage }}
                    />
                </div>
                <div>
                    <PrimaryButton onClick={applyLocalFilters}>OK</PrimaryButton>
                    <DefaultButton style={{ float: "right" }} onClick={dismissCallout}>Cancel</DefaultButton>
                </div>
            </Callout>
        </Stack>
    );
}

export default DateRangePicker;