import { useRef } from "react";

// auto clear state to 'undefined' in 100ms after state changes.
export function useFrameState<State>() : [(newState?: State) => void, () => State | undefined] {
    const frameStateRef = useRef<State | undefined>();
    const timeoutRef = useRef<number>();

    const setState = (newState?: State) => {
        window.clearTimeout(timeoutRef.current);

        frameStateRef.current = newState;
        timeoutRef.current = window.setTimeout(() => {
            frameStateRef.current = undefined;
            timeoutRef.current = undefined;
        }, 100);
    };

    const getState = () => {
        return frameStateRef.current;
    };

    return [setState, getState];
}