import { CosmosSourceTypeEnum, CosmosSourceTypeName } from "../../../models/CosmosModels";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import React, { useMemo } from "react";

import ChartContainer from "../../chart/ChartContainer";
import { SeriesBarOptions } from "highcharts";
import { currencyFormatter } from "../../../utils/currency";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useGetCosmosCostByGEM } from "../../../hooks/useCosmosQuery";
import { useTrackHovering } from "../../../hooks/useTrack";

interface ICosmosTopGemChartProps {
    source: CosmosSourceTypeEnum;
}

const CosmosTopGemChart: React.FC<ICosmosTopGemChartProps> = (props) => {
    const dates = useDateRange();
    const query = useGetCosmosCostByGEM(props.source);
    const gemCosts = useMemo(
        () =>
            query.data
                ? Object.keys(query.data || {})
                      .map((key) => ({ gemName: key, cost: query.data[key] }))
                      .sort((a, b) => b.cost - a.cost)
                      .slice(0, 10)
                : [],
        [query.data]
    );

    const title = `TOP 10 GEM Cosmos${props.source != CosmosSourceTypeEnum.All ? ` ${CosmosSourceTypeName[props.source]}` : ""} Cost Ranking `;

    const trackChartHovering = useTrackHovering(LogComponent.CosmosView, LogElement.CosmosGemRankingChart, title, LogTarget.Chart, {
        dataSource: CosmosSourceTypeName[props.source],
    });

    const categories = useMemo(() => gemCosts.map((cost) => cost.gemName), [gemCosts]);
    const series = useMemo<SeriesBarOptions[]>(() => {
        return [
            {
                type: "bar",
                name: "Cosmos Cost",
                data: gemCosts.map((cost) => ({ y: cost.cost })),
            },
        ];
    }, [categories, gemCosts]);

    return (
        <ChartContainer
            headerProps={{ title }}
            loading={query.isLoading}
            chartType="bar"
            chartProps={{
                tooltipProps: {
                    date: [dates.startDate, dates.endDate],
                },
                yAxis: { labelFormatter: currencyFormatter },
                categories,
                series,
                containerProps: trackChartHovering,
            }}
            logProps={{
                logComponent: LogComponent.CosmosView,
                logElement: LogElement.CosmosGemRankingChart,
            }}
        />
    );
};

export default CosmosTopGemChart;
