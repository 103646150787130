import * as React from "react";
import { Stack } from "@fluentui/react";
import { outerStackStyles, filterStackStyles } from "../ViewsCommonLayout";
import ArtifactDetailsFilterBar from "../../Filter/ArtifactDetailsFilterBar";
import ArtifactDetailsPage from "./ArtifactDetailsPage";
import { useGetManagers } from "../../../../hooks/useES365CostQuery";
import { returnEmptyState, returnLoadingState } from "../CloudBuild/CloudBuildGemContainer";
import { ESArtifactDataType } from "../../../../models/ESCostModels";

export const ArtifactDetailsContainer: React.FC = () => {
    const { data: esManagers, isLoading, isError } = useGetManagers();
    const returnNormalState = () => {
        if (esManagers === undefined) return returnEmptyState();
        return (
            <Stack styles={outerStackStyles} disableShrink>
                <Stack horizontal styles={filterStackStyles}>
                    <ArtifactDetailsFilterBar managers={esManagers} />
                </Stack>
                <ArtifactDetailsPage dataType={ESArtifactDataType.Cost} />
            </Stack>
        );
    };
    return isLoading ? returnLoadingState() : isError || esManagers === undefined ? returnEmptyState() : returnNormalState();
};
