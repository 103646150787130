import { ActionButton, Stack } from "@fluentui/react";
import { LogComponent, LogElement, LogTarget } from "../../../../models/LogModel";
import {
    PcmV2AggeratedLevel,
    PcmV2MetricKeys,
    PcmV2SceneTypeEnum,
    PcmV2SubSceneTypeEnum,
    PcmV2WorkloadType,
    SubstrateCarbonEmissionData,
    SubstrateCostV2
} from "../../../../models/PcmV2Models";
import { PcmV2CostThreshold, PcmV2SceneCostMetric, PcmV2SubSceneCostMetric, SubstrateRings } from "../../../../models/constants/PcmV2Constants";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ReactTableExpandableData, ReactTableRow, useReactTable, useTableColumnSelection } from "../../../common/table/TableUtils";
import { SubstrateCostTableColumnId, SubstrateTableSubRowsPlaceHolder, getSubstrateTableColumns } from "./SubstrateDetailTableConfig";
import {
    useGetAppIdNameMap,
    useGetAppIdScenarioTagCountMap,
    useGetAppIdServiceMap,
    useGetServiceNameGriffinAppCountMap,
    useGetServiceNameScenarioTagCountMap,
} from "../../../../hooks/useSearchMetadataQuery";
import { useGetCarbonEmission, useGetPCMV2CostByService, useGetStorageCost } from "../../../../hooks/useSubstrateV2Query";

import { ColumnSelector } from "../../../common/table/ColumnSelector";
import { EventTypeEnum } from "../../../../models/Event";
import { IAppState } from "../../../../store";
import { ITablePreference } from "../../../../utils/preference/UserPreferenceUtils";
import { Row } from "react-table";
import { SectionHeader } from "../../../common/SectionHeader";
import { ServiceTreeItem } from "../../../../models/serviceTree";
import StickyTable from "../../../common/table/StickyTable";
import { SubstrateV2Service } from "../../../../services/SubstrateV2Service";
import { isStorageSubScene } from "../../../../utils/PcmV2Utils";
import { parseFiltersToJson } from "../../../../models/FilterView";
import substrateStyles from "../../Substrate.less";
import { trackEventCallback } from "../../../../utils/AppInsights";
import { useCategoryFilters } from "../../../../hooks/useFilters";
import { useCustomQueryClient } from "../../../../hooks/useCustomQuery";
import { useDateRange } from "../../../../hooks/useDateSelector";
import useEventBus from "../../../../hooks/useEventBus";
import { useFlights } from "../../../../hooks/useSettings";
import { useGetPCMV2Unitprice } from "../../../../hooks/useSubstrateQuery";
import { useRings } from "../../../../hooks/useRings";
import { useSelector } from "react-redux";
import { useUserPreference } from "../../../../utils/preference/useUserPreference";

export interface ISubstrateCostTableProps {
    scene: PcmV2SceneTypeEnum;
    subScene?: PcmV2SubSceneTypeEnum;
}

const SubstrateCostTableDefaultSortBy: Partial<Record<PcmV2SceneTypeEnum | PcmV2SubSceneTypeEnum, PcmV2MetricKeys>> = {
    [PcmV2SceneTypeEnum.Overview]: "cost",
    [PcmV2SceneTypeEnum.Transaction]: "transactionCost",
    [PcmV2SceneTypeEnum.Network]: "networkCost",
    [PcmV2SceneTypeEnum.ProcessHosting]: "processHostingCost",
    [PcmV2SceneTypeEnum.SSD]: "ssdCost",
    [PcmV2SceneTypeEnum.HDD]: "hddCost",
    [PcmV2SubSceneTypeEnum.SSDDatasetCost]: "ssdmcdbCost",
    [PcmV2SubSceneTypeEnum.SSDMailboxCost]: "ssdmcdbCost",
};

const SubstrateCostTableTitle: Record<PcmV2SceneTypeEnum, string> = {
    [PcmV2SceneTypeEnum.Overview]: "Substrate and Carbon Cost",
    [PcmV2SceneTypeEnum.Transaction]: "Substrate Transaction Cost",
    [PcmV2SceneTypeEnum.Network]: "Substrate Network Cost",
    [PcmV2SceneTypeEnum.ProcessHosting]: "Substrate Process Hosting Cost",
    [PcmV2SceneTypeEnum.SSD]: "Substrate SSD Cost",
    [PcmV2SceneTypeEnum.HDD]: "Substrate HDD Cost",
};

const SubstrateCostTableLogComponent: Record<PcmV2SceneTypeEnum, LogComponent> = {
    [PcmV2SceneTypeEnum.Overview]: LogComponent.SubstrateChartingPane,
    [PcmV2SceneTypeEnum.Transaction]: LogComponent.TransChartingPane,
    [PcmV2SceneTypeEnum.Network]: LogComponent.NetworkChartingPane,
    [PcmV2SceneTypeEnum.ProcessHosting]: LogComponent.ProcessHostingChartingPane,
    [PcmV2SceneTypeEnum.SSD]: LogComponent.SSDChartingPane,
    [PcmV2SceneTypeEnum.HDD]: LogComponent.HDDChartingPane
};

const SubstrateCostTableSortLogElement: Record<PcmV2SceneTypeEnum, LogElement> = {
    [PcmV2SceneTypeEnum.Overview]: LogElement.SortSubstrate,
    [PcmV2SceneTypeEnum.Transaction]: LogElement.SortTrans,
    [PcmV2SceneTypeEnum.Network]: LogElement.SortNetwork,
    [PcmV2SceneTypeEnum.ProcessHosting]: LogElement.SortProcessHosting,
    [PcmV2SceneTypeEnum.SSD]: LogElement.SortSSD,
    [PcmV2SceneTypeEnum.HDD]: LogElement.SortHDD
};

const SubstrateCostTableDownLoadLogElement: Record<PcmV2SceneTypeEnum, LogElement> = {
    [PcmV2SceneTypeEnum.Overview]: LogElement.DownloadSubstrate,
    [PcmV2SceneTypeEnum.Transaction]: LogElement.DownloadTrans,
    [PcmV2SceneTypeEnum.Network]: LogElement.DownLoadNetwork,
    [PcmV2SceneTypeEnum.ProcessHosting]: LogElement.DownloadProcessHosting,
    [PcmV2SceneTypeEnum.SSD]: LogElement.DownloadSSD,
    [PcmV2SceneTypeEnum.HDD]: LogElement.DownloadHDD
};

const SubstrateCostTableColumnsLogElement: Record<PcmV2SceneTypeEnum, LogElement> = {
    [PcmV2SceneTypeEnum.Overview]: LogElement.ColumnsSubstrate,
    [PcmV2SceneTypeEnum.Transaction]: LogElement.ColumnsTrans,
    [PcmV2SceneTypeEnum.Network]: LogElement.ColumnsNetwork,
    [PcmV2SceneTypeEnum.ProcessHosting]: LogElement.ColumnsProcessHosting,
    [PcmV2SceneTypeEnum.SSD]: LogElement.ColumnsSSD,
    [PcmV2SceneTypeEnum.HDD]: LogElement.ColumnsHDD
};

const EmptyTableDataPlaceHolder: ReactTableExpandableData<SubstrateCostV2>[] = [];

export const SubstrateCostTable: React.FC<ISubstrateCostTableProps> = (props) => {
    const { data: flights } = useFlights();
    const costTableRef = useRef<HTMLDivElement>(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const carbonQuery = useGetCarbonEmission(props.scene, flights?.enableSubstrateV2Carbon);

    const costKey = (props.subScene ? PcmV2SubSceneCostMetric[props.subScene] : null) || PcmV2SceneCostMetric[props.scene];
    const serviceCostQuery = useGetPCMV2CostByService(props.scene, props.subScene);
    const storageCostQuery = useGetStorageCost(props.scene, props.subScene);
    const prevPropsRef = useRef<ISubstrateCostTableProps>();

    const targetQuery = isStorageSubScene(props.subScene) ? storageCostQuery : serviceCostQuery;

    const { data: unitPrice } = useGetPCMV2Unitprice();

    // delay table render to prevent browser freeze when rendering a large table.
    const [isDelayedRendering, setDelayedRendering] = useState(false);

    const tableDataQuery = useMemo(() => {
        const resultQuery = {
            ...targetQuery,
            data: targetQuery.data?.filter((item) => item[costKey] >= PcmV2CostThreshold) as SubstrateCostV2[] | undefined,
        };

        resultQuery.data?.forEach((cost) => {
            if (carbonQuery.data && cost.identity.aggregatedLevel === PcmV2AggeratedLevel.Service) {
                cost.totalCarbonEmission = carbonQuery.data.carbonEmissionByService[cost.identity.identity]?.totalCarbonEmission;
                cost.carbonScope2Emission = carbonQuery.data.carbonEmissionByService[cost.identity.identity]?.carbonScope2Emission;
                cost.carbonScope3Emission = carbonQuery.data.carbonEmissionByService[cost.identity.identity]?.carbonScope3Emission;
            }
        });

        return resultQuery;
    }, [targetQuery.data, props.scene, carbonQuery.data]);

    const hasData = useMemo(() => {
        return !!tableDataQuery.data?.find((item) => item[costKey] > 0);
    }, [tableDataQuery.data, props.scene, props.subScene]);

    const [forceUpdateIndicator, setForceUpdateIndicator] = useState(false);

    const queryClient = useCustomQueryClient();

    const { startDate, endDate } = useDateRange();
    const { filters } = useCategoryFilters();
    const [rings] = useRings();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);
    const searchFiltersRequest = useMemo(() => parseFiltersToJson(filters.filters, serviceIdMap), [filters.filters, serviceIdMap]);

    const serviceNameScenarioTagCountMap = useGetServiceNameScenarioTagCountMap();
    const serviceNameGriffinAppCountMap = useGetServiceNameGriffinAppCountMap();
    const appIdNameScenarioTagCountMap = useGetAppIdScenarioTagCountMap();
    const appIdServiceMap = useGetAppIdServiceMap();
    const appIdNameMap = useGetAppIdNameMap();

    const [preferedTableConfig, setPreferedTableConfig] = useUserPreference<string, ITablePreference<SubstrateCostTableColumnId[]>>(
        props.subScene ? props.subScene : props.scene,
        [props.scene, props.subScene]
    );

    const subRowsGetter = (() => {
        if (props.subScene === PcmV2SubSceneTypeEnum.TransactionScenarioTagCost) {
            return SubstrateV2Service.getTransactionScenarioTagCost;
        } else if (props.subScene === PcmV2SubSceneTypeEnum.NetworkScenarioTagCost) {
            return SubstrateV2Service.getNetworkScenarioTagCost;
        }

        return SubstrateV2Service.getPCMV2CostByWorkload;
    })();

    const columns = useMemo(
        () =>
            getSubstrateTableColumns(
                tableDataQuery.data || [],
                startDate,
                endDate,
                props.scene,
                props.subScene,
                unitPrice,
                serviceIdMap,
                serviceNameScenarioTagCountMap,
                appIdNameScenarioTagCountMap,
                serviceNameGriffinAppCountMap
            ).filter((col) => {
                if (col.id === "carbonScope2Emission" || col.id === "carbonScope3Emission" || col.id === "totalCarbonEmission") {
                    return flights?.enableSubstrateV2Carbon;
                }

                return true;
            }),
        [
            flights?.enableSubstrateV2Carbon,
            unitPrice,
            tableDataQuery.data,
            serviceIdMap,
            serviceNameScenarioTagCountMap,
            appIdNameScenarioTagCountMap,
            serviceNameGriffinAppCountMap,
            props.scene,
            props.subScene,
        ]
    );

    const isSubRowsLoading = (cost: SubstrateCostV2) => {
        if (isStorageSubScene(props.subScene)) {
            return queryClient.isFetching(
                SubstrateV2Service.getSubStorageCost,
                searchFiltersRequest,
                startDate,
                endDate,
                rings,
                props.scene,
                props.subScene,
                cost.identity.workload
            );
        }

        return queryClient.isFetching(
            subRowsGetter,
            searchFiltersRequest,
            startDate,
            endDate,
            rings,
            cost.identity.identity
        );
    };

    const tableData = useMemo<ReactTableExpandableData<SubstrateCostV2>[]>(() => {
        if (!tableDataQuery.data) {
            return [];
        }

        const result: ReactTableExpandableData<SubstrateCostV2>[] = [];

        tableDataQuery.data?.forEach((cost) => {
            if (isSubRowsLoading(cost)) {
                result.push({ data: cost, isSubRowsLoading: true, depth: 0 });
            } else {
                result.push({ data: cost, depth: 0 });
            }
        });

        return result;
    }, [tableDataQuery.data, forceUpdateIndicator]);

    const getSubRows = useCallback(
        (originalRow: ReactTableExpandableData<SubstrateCostV2>): ReactTableExpandableData<SubstrateCostV2>[] => {
            if (originalRow.depth != 0 || props.subScene === PcmV2SubSceneTypeEnum.SSDTotalCost) {
                return [];
            }

            let workloadData: SubstrateCostV2[] | undefined = [];

            if (isStorageSubScene(props.subScene) && originalRow.data.identity.workload) {
                workloadData = queryClient.getQueryData(
                    SubstrateV2Service.getSubStorageCost,
                    searchFiltersRequest,
                    startDate,
                    endDate,
                    rings,
                    props.scene,
                    props.subScene,
                    originalRow.data.identity.workload
                );
            } else if (!isStorageSubScene(props.subScene) && originalRow.data.identity.identity) {
                workloadData = queryClient.getQueryData(
                    subRowsGetter,
                    searchFiltersRequest,
                    startDate,
                    endDate,
                    rings,
                    originalRow.data.identity.identity
                );
            }

            workloadData = workloadData?.filter((item) => item[costKey] >= PcmV2CostThreshold);

            if (workloadData?.length) {
                if (carbonQuery.data) {
                    workloadData.forEach((item) => {
                        if (
                            item.identity.aggregatedLevel === PcmV2AggeratedLevel.Workload
                        ) {
                            const carbonData: SubstrateCarbonEmissionData = {
                                appName: item.identity.workload,
                                substrateSource: "",
                                totalCarbonEmission: 0,
                                carbonScope2Emission: 0,
                                carbonScope3Emission: 0,
                            };
                            if(item.processHostingCost > 0) {
                                if(carbonQuery.data?.carbonEmissionByGriffinApp["Process" + "#" + originalRow.data.identity.identity + "#" + item.identity.workload?.toUpperCase()] != undefined) {
                                    const temp = carbonQuery.data?.carbonEmissionByGriffinApp["Process" + "#" + originalRow.data.identity.identity + "#" + item.identity.workload?.toUpperCase()];
                                    carbonData.carbonScope2Emission += temp.carbonScope2Emission;
                                    carbonData.carbonScope3Emission += temp.carbonScope3Emission;
                                    carbonData.totalCarbonEmission += temp.totalCarbonEmission;
                                }
                                if(carbonQuery.data?.carbonEmissionByGriffinApp["Aspnet" + "#" + originalRow.data.identity.identity + "#" + item.identity.workload?.toUpperCase()] != undefined) {
                                    const temp = carbonQuery.data?.carbonEmissionByGriffinApp["Aspnet" + "#" + originalRow.data.identity.identity + "#" + item.identity.workload?.toUpperCase()];
                                    carbonData.carbonScope2Emission += temp.carbonScope2Emission;
                                    carbonData.carbonScope3Emission += temp.carbonScope3Emission;
                                    carbonData.totalCarbonEmission += temp.totalCarbonEmission;
                                }
                            }
                            if ((item.itemQueryCost > 0 || item.itemWriteCost > 0 || item.itemReadCost > 0)
                                && carbonQuery.data?.carbonEmissionByGriffinApp["Store" + "#" + originalRow.data.identity.identity + "#" + item.identity.workload?.toUpperCase()] != undefined) {
                                    const temp = carbonQuery.data?.carbonEmissionByGriffinApp["Store" + "#" + originalRow.data.identity.identity + "#" + item.identity.workload?.toUpperCase()];
                                    carbonData.carbonScope2Emission += temp.carbonScope2Emission;
                                    carbonData.carbonScope3Emission += temp.carbonScope3Emission;
                                    carbonData.totalCarbonEmission += temp.totalCarbonEmission;
                            }
                            item.totalCarbonEmission = carbonData?.totalCarbonEmission;
                            item.carbonScope2Emission = carbonData?.carbonScope2Emission;
                            item.carbonScope3Emission = carbonData?.carbonScope3Emission;
                        }
                    });
                }

                return workloadData.map((cost) => ({ data: cost, parent: originalRow, depth: 1 }));
            }

            return SubstrateTableSubRowsPlaceHolder;
        },
        [tableData, props.scene, props.subScene]
    );

    const getRowId = useCallback(
        (
            { data: { identity }, isSubRowPlaceHolder }: ReactTableExpandableData<SubstrateCostV2>,
            relativeIndex: number,
            parent?: Row<ReactTableExpandableData<SubstrateCostV2>>
        ) => {
            if (!identity && !parent) {
                return relativeIndex.toString();
            }

            return `${parent?.id}_${identity.identity}_${identity.workload}_${identity.workloadType}_${!!isSubRowPlaceHolder}`;
        },
        []
    );

    const defaultSortByKey = useMemo(() => {
        const key = props.subScene ? SubstrateCostTableDefaultSortBy[props.subScene] : undefined;

        return key || SubstrateCostTableDefaultSortBy[props.scene] || "cost";
    }, [props.scene, props.subScene]);

    // if size of data < 100, ignore delayed rendering.
    // make sure loading status when switch between views.
    const isTableLoading = (!tableDataQuery.data || tableDataQuery.data.length > 100) && (tableDataQuery.isLoading || isDelayedRendering || (props.scene !== prevPropsRef.current?.scene || props.subScene !== prevPropsRef.current?.subScene))

    const tableInstance = useReactTable<ReactTableExpandableData<SubstrateCostV2>>({
        initialState: {
            hiddenColumns: preferedTableConfig?.hiddenColumns || [],
            sortBy: [
                {
                    id: defaultSortByKey,
                    desc: true,
                },
            ],
        },
        data: isTableLoading ? EmptyTableDataPlaceHolder : tableData,
        columns,
        getSubRows,
        getRowId,
    });

    const columnSelection = useTableColumnSelection(tableInstance, SubstrateCostTableLogComponent[props.scene], SubstrateCostTableColumnsLogElement[props.scene]);

    useEffect(() => {
        // if any ring is selected, hide carbon columns
        const shouldHideCarbon = rings.length > 0 && rings.length !== SubstrateRings.length;

        tableInstance.toggleHideColumn("totalCarbonEmission", shouldHideCarbon || columnSelection.hiddenColumns.includes("totalCarbonEmission"));
        tableInstance.toggleHideColumn("carbonScope2Emission", shouldHideCarbon || columnSelection.hiddenColumns.includes("carbonScope2Emission"));
        tableInstance.toggleHideColumn("carbonScope3Emission", shouldHideCarbon || columnSelection.hiddenColumns.includes("carbonScope3Emission"));
    }, [rings]);

    useEffect(() => {
        setPreferedTableConfig({ version: "2023.03.06.01", hiddenColumns: columnSelection.hiddenColumns as SubstrateCostTableColumnId[] });
    }, [columnSelection.hiddenColumns]);

    useEffect(() => {
        if (tableInstance.toggleAllRowsExpanded) {
            tableInstance.toggleAllRowsExpanded(false);
        }
    }, [tableDataQuery.data]);

    useEffect(() => {
        prevPropsRef.current = {...props};
        setForceUpdateIndicator(v => !v);
    }, [props.scene, props.subScene]);

    useEffect(() => {
        const currentScene = props.scene;
        const currentSubScene = props.subScene;

        setDelayedRendering(true);

        setTimeout(() => {
            if (currentScene === props.scene && currentSubScene === props.subScene) {
                setDelayedRendering(false);
            }
        }, 1000);
    }, [props.scene, props.subScene]);

    const handleRowExpanded = (row: ReactTableRow<ReactTableExpandableData<any>>, isExpanded: boolean) => {
        if (isExpanded) {
            setForceUpdateIndicator((v) => !v);

            if (isStorageSubScene(props.subScene)) {
                queryClient
                    .fetchQuery(
                        SubstrateV2Service.getSubStorageCost,
                        searchFiltersRequest,
                        startDate,
                        endDate,
                        rings,
                        props.scene,
                        props.subScene,
                        row.original.data.identity.workload
                    )
                    .finally(() => setForceUpdateIndicator((v) => !v));
            } else {
                queryClient
                    .fetchQuery(
                        subRowsGetter,
                        parseFiltersToJson(filters.filters, serviceIdMap),
                        startDate,
                        endDate,
                        rings,
                        row.original.data.identity.identity
                    )
                    .finally(() => setForceUpdateIndicator((v) => !v));
            }
        }
    };

    const handleDownload = async () => {
        if (isDownloading) {
            return;
        }

        setIsDownloading(true);
        trackEventCallback(SubstrateCostTableLogComponent[props.scene], SubstrateCostTableDownLoadLogElement[props.scene], "Downloard", LogTarget.Button);
        await SubstrateV2Service.download(
            { serviceIdMap, carbonEmissionByGriffinApp: carbonQuery.data?.carbonEmissionByGriffinApp, appIdNameMap, appIdServiceMap, flights },
            parseFiltersToJson(filters.filters, serviceIdMap),
            startDate,
            endDate,
            rings,
            props.scene,
            props.subScene
        );
        setIsDownloading(false);
    };

    useEventBus(EventTypeEnum.ScrollToTableEvent, () => {
        costTableRef.current?.scrollIntoView({
            behavior: "smooth",
        });
    });

    const onSort = React.useCallback(function(columnId: string, desc?: boolean) {
        trackEventCallback(SubstrateCostTableLogComponent[props.scene], SubstrateCostTableSortLogElement[props.scene], columnId, LogTarget.Button, { desc });
    }, [props.scene]);

    return (
        <div ref={costTableRef}>
            <SectionHeader
                className={substrateStyles.tableSectionHeader}
                title={
                    props.scene === PcmV2SceneTypeEnum.Overview && !flights?.enableSubstrateV2Carbon ? "Substrate Cost" : SubstrateCostTableTitle[props.scene]
                }
                extra={
                    <Stack horizontal verticalAlign="center">
                        <ActionButton iconProps={{ iconName: "Download" }} onClick={handleDownload}>
                            Download
                        </ActionButton>
                        <ColumnSelector
                            onConfirm={columnSelection.onVisibilityChange}
                            columns={columnSelection.columnOptions}
                            initSelectedKeys={columnSelection.visibleColumns}
                        />
                    </Stack>
                }
            />
            <StickyTable
                emptyText={hasData ? "Costs under $10 are filtered out from the table. For any questions, contact jawswork@microsoft.com." : "No data"}
                expandable
                onExpanded={handleRowExpanded}
                loading={isTableLoading}
                loadMore={false}
                styles={{ container: substrateStyles.tableContainer }}
                table={tableInstance}
                stickyPositon={{ header: { offsetTop: -1 }, footer: { offsetBottom: 0 } }}
                onSort={onSort}
            />
        </div>
    );
};
