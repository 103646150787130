// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oThMUI7GkCTSkadMg5QL {\n  margin-top: 20px;\n}\n.X1swSP1oaO0_II4w4sTi {\n  border-radius: 12px;\n  background-color: #F0F0F0;\n  height: 36px;\n  border: none;\n  font-size: 18px;\n}\n.p7PYmzdcjB6Yy3l6HPbU {\n  font-size: 12px;\n  margin-left: 8px;\n}\n._B1N2VsGQ9rFBR7KQghZ {\n  display: block;\n  width: 160px;\n  border: none;\n}\n._B1N2VsGQ9rFBR7KQghZ .ms-Button-flexContainer {\n  justify-content: start;\n}\n.Gr8CVJ4FPQweL3piEe6n {\n  margin-top: 12px 0;\n  background-color: #dff6dd;\n}\n", "",{"version":3,"sources":["webpack://./src/components/substrateplatform/SubstratePlatformBackend.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAEA;EACI,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AAAJ;AAGA;EACI,eAAA;EACA,gBAAA;AADJ;AAIA;EACI,cAAA;EACA,YAAA;EACA,YAAA;AAFJ;AADA;EAMQ,sBAAA;AAFR;AAMA;EACI,kBAAA;EACA,yBAAA;AAJJ","sourcesContent":[".PivotByContainer {\n    margin-top: 20px;\n}\n\n.pivotButton {\n    border-radius: 12px;\n    background-color: #F0F0F0;\n    height: 36px;\n    border: none;\n    font-size: 18px;\n}\n\n.arrow {\n    font-size: 12px;\n    margin-left: 8px;\n}\n\n.listItem {\n    display: block;\n    width: 160px;\n    border: none;\n\n    :global(.ms-Button-flexContainer) {\n        justify-content: start;\n    }\n}\n\n.messageBar {\n    margin-top: 12px 0;\n    background-color: rgb(223, 246, 221);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PivotByContainer": "oThMUI7GkCTSkadMg5QL",
	"pivotButton": "X1swSP1oaO0_II4w4sTi",
	"arrow": "p7PYmzdcjB6Yy3l6HPbU",
	"listItem": "_B1N2VsGQ9rFBR7KQghZ",
	"messageBar": "Gr8CVJ4FPQweL3piEe6n"
};
export default ___CSS_LOADER_EXPORT___;
