
export default class CommonConstants {
    static readonly DateFormatterString = 'MM/DD/YYYY';

    static readonly DefaultColors = ["#0099BC", "#982570", "#627CEF", "#C19C00", "#125D9A", "#972AA5", "#E650AF", "#0E7878", "#9372C0", "#9C663F", "#00758F", "#77004D", "#4661D5"];

    static readonly AverageColor = "#949494";

    static readonly MedianColor = "#40A112";
}
