import React from "react";
import Highcharts, { Options, SeriesAreaOptions, SeriesLineOptions, YAxisOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getColor } from "../../../common/costCard/CostCardUtils";
import moment from "moment";
import { renderToString } from "react-dom/server";
import { currencyFormatter } from "../../../../utils/currency";
import CostCardTooltipContent from "../../../common/costCard/CostCardTooltipContent";
import ChartContainer from "../../../chart/ChartContainer";
import { EmptyModule } from "../../../common/state/EmptyModule";

export interface IStackedAreaChartProps {
    data: IStackedAreaChartData[];
    dates: IStackedAreaChartDates;
    description: string;
    yAxisTitle: string;
    isCurrency: boolean;
    tooltipTitle: string;
    hasBenchmark: boolean;
    benchmarkTitle?: string;
    benchmarkValues?: number[];
    hideStackLabels?: boolean;
    showAllValues?: boolean;
}

export interface IStackedAreaChartDates {
    dates: string[];
    format: string;
    step: number;
}

export interface IStackedAreaChartData {
    name: string;
    data: number[];
}

function getOptions(
    esData: IStackedAreaChartData[],
    d: IStackedAreaChartDates,
    yAxisTitle: string,
    tooltipTitle: string,
    isCurrency: boolean,
    hasBenchmark: boolean,
    benchmarkTitle?: string,
    benchmarkValues?: number[],
    hideStackLabels?: boolean,
    showAllValues?: boolean
): Options {
    const areaSeries: SeriesAreaOptions[] = esData.map((s, index) => ({
        type: "area",
        name: s.name,
        data: s.data,
        color: getColor(index),
    }));

    const yAxisData: Array<YAxisOptions> = [
        {
            title: {
                text: yAxisTitle,
            },
            stackLabels: {
                enabled: !hideStackLabels,
                formatter: function () {
                    return `${currencyFormatter(this.total, 2, isCurrency ? "$" : "")}`;
                },
            },
        },
    ];

    if (hasBenchmark) {
        yAxisData.push({
            title: {
                text: benchmarkTitle,
            },
            opposite: true,
        });
    }

    return {
        chart: {
            type: "line",
            style: {
                fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
                fontSize: "14px",
                paddingTop: "10px",
            },
        },
        title: {
            text: undefined,
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            categories: d.dates.map((date) => moment(date).format(d.format)),
            labels: {
                step: d.step,
            },
        },
        yAxis: yAxisData,
        tooltip: {
            shape: "square",
            borderWidth: 0,
            borderRadius: 0,
            backgroundColor: "white",
            padding: 0,
            useHTML: true,
            shared: true,
            outside: true,
            followPointer: true,
            formatter: function () {
                const visiblePoints = this.points?.filter((point) => !point.series.name.startsWith("hidden")) || [];

                return renderToString(
                    <CostCardTooltipContent
                        items={visiblePoints.map((point) => ({ title: point.series.name, cost: point.y || 0, color: (point.series as any).color }))}
                        date={[moment(this.x, d.format)]}
                        dateFormatStr={d.format}
                        errorCollapseLimit={0}
                        smallCard
                        totalText={`${tooltipTitle} (${currencyFormatter(
                            (this.points && this.points[1] && this.points[1].total) || 0,
                            2,
                            isCurrency ? "$" : ""
                        )})`}
                        noPrefix={!isCurrency}
                    />
                );
            },
        },
        plotOptions: {
            area: {
                stacking: "normal",
                lineWidth: 3,
                marker: {
                    enabled: true,
                    states: {
                        hover: {
                            enabled: true,
                            radius: 5,
                        },
                    },
                },
                dataLabels: {
                    enabled: !!showAllValues,
                    formatter: function () {
                        return `${currencyFormatter(this.point.y || 0, 2, isCurrency ? "$" : "")}`;
                    },
                },
            },
        },
        series: [
            ...(hasBenchmark
                ? [
                      {
                          type: "line",
                          name: benchmarkTitle,
                          data: benchmarkValues,
                          color: "grey",
                          dashStyle: "Dash",
                          marker: {
                              enabled: true,
                              radius: 5,
                          },
                      } as SeriesLineOptions,
                  ]
                : []),
            ...areaSeries,
        ],
    };
}

const StackedAreaChart = (props: IStackedAreaChartProps) => {
    const { data, dates, description, yAxisTitle, isCurrency, tooltipTitle, hasBenchmark, benchmarkTitle, benchmarkValues, hideStackLabels, showAllValues } =
        props;
    const options = React.useMemo(
        () => getOptions(data, dates, yAxisTitle, tooltipTitle, isCurrency, hasBenchmark, benchmarkTitle, benchmarkValues, hideStackLabels, showAllValues),
        [benchmarkTitle, benchmarkValues, data, dates, hasBenchmark, isCurrency, tooltipTitle, yAxisTitle, hideStackLabels, showAllValues]
    );

    const isNotEmpty = React.useMemo(() => data != null && data.every((item) => item.data !== null && item.data.length > 1), [data]);

    return (
        <div>
            <ChartContainer headerProps={{ title: description }}>
                {isNotEmpty ? <HighchartsReact highcharts={Highcharts} options={options} /> : <EmptyModule iconName="EmptyLineChart" />}
            </ChartContainer>
        </div>
    );
};

export default StackedAreaChart;
