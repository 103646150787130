import { CosmicSourceTypeEnum } from "../../models/CosmicModels";
import { Link, MessageBar, MessageBarBody, MessageBarTitle } from "@fluentui/react-components";

import LeadershipMetricsCard from "./LeadershipMetricsCard";
import React from "react";
import styles from './CosmicView.less';
import { useGetCosmicDailyMetric } from "../../hooks/useCosmicQuery";

interface ILeadershipViewProps {
    type: CosmicSourceTypeEnum;
}

// const getMetricData = (metricName: string, metricsType: ICosmicDailyMetricReport[]) => {
//     if (!metricsType) return [];

//     return metricsType
//         .filter((singleMetric) => singleMetric.metric === metricName)
// };


const LeadershipView: React.FC<ILeadershipViewProps> = (props) => {
    const { cosmicDailyMetricReport: totalCoresMetrics, isLoading: isLoadingTotalCores } = useGetCosmicDailyMetric("TotalCores");
    const { cosmicDailyMetricReport: totalCogsMetrics, isLoading: isLoadingTotalCogs } = useGetCosmicDailyMetric("TotalCogs");
    const { cosmicDailyMetricReport: platformLevelAppUtilizationP99, isLoading: isLoadingPlatformLevelAppUtilizationP99 } = useGetCosmicDailyMetric("PlatformLevelAppUtilizationP99");

    // const metricDataConfig = [
    //     { title: "Total Cosmic Cores", logElement: LogElement.CosmicTotalCores, data: getMetricData("TotalCores", totalCoresMetrics) },
    //     { title: "Total Cosmic COGS", logElement: LogElement.CosmicTotalCOGS, data: getMetricData("TotalCosmicCost", totalCogsMetrics) },
    //     { title: "Platform Overhead with Rightsizing", logElement: LogElement.CosmicPlatformOverheadWithRightsizing, data: getMetricData("OverheadWithRightsizingRatio", totalCoresMetrics) },
    //     { title: "Rightsizing Saved Cores", logElement: LogElement.CosmicRightsizingSavedAppCores, data: getMetricData("RightsizingSavedAppCores", totalCoresMetrics) },
    // ];

    // const utilizationMetricDataConfig = { title: "Platform level Cosmic App P99 Utilization", logElement: LogElement.CosmicPlatformLevelAppP99CpuUtilization, data: getMetricData("CpuUtilizationP99", platformLevelAppUtilizationP99) };

    return (
        <div className={styles.cardContainer}>
            < InfoMessage />
            <LeadershipMetricsCard totalCogs={totalCogsMetrics} isLoadingTotalCogs={isLoadingTotalCogs} />

        </div>
    );
}

//     <div className={styles.cardContainer}>
//         <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`} style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "24px" }}>
//             <LineChartContainer
//                 title="Total Cosmic Cores"
//                 isLoading={false}
//                 series={totalCores?.map(singleService => ({
//                     name: singleService.name,
//                     data: singleService.data.map(metric => [metric.date.valueOf(), metric.metricValue])
//                 })) || []}
//                 isNotCurrency
//                 logProps={{
//                     logComponent: LogComponent.CosmicLeadershipView,
//                     logElement: LogElement.CosmicTotalCores,
//                     customProperties: {
//                         type: props.type,
//                     }
//                 }}
//                 fillMissingAbnormalData
//                 showArea
//                 anomalies={[]}
//             />
//             <LineChartContainer
//                 title="Total Cosmic COGS"
//                 isLoading={false}
//                 series={totalCOGS?.map(singleService => ({
//                     name: singleService.name,
//                     data: singleService.data.map(metric => [metric.date.valueOf(), metric.metricValue])
//                 })) || []}
//                 logProps={{
//                     logComponent: LogComponent.CosmicLeadershipView,
//                     logElement: LogElement.CosmicTotalCOGS,
//                     customProperties: {
//                         type: props.type,
//                     }
//                 }}
//                 fillMissingAbnormalData
//                 showArea
//                 anomalies={[]}
//             />
//         </div>
//         <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`} style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "24px" }}>
//             <LineChartContainer
//                 title="Platform Overhead with Rightsizing"
//                 isLoading={false}
//                 isNotCurrency
//                 series={cosmicPlatformOverheadWithRightsizing?.map(singleService => ({
//                     name: "Platform Overhead with Rightsizing(%)",
//                     data: singleService.data.map(metric => [metric.date.valueOf(), metric.metricValue * 100])
//                 })) || []}
//                 logProps={{
//                     logComponent: LogComponent.CosmicLeadershipView,
//                     logElement: LogElement.CosmicPlatformOverheadWithRightsizing,
//                     customProperties: {
//                         type: props.type,
//                     }
//                 }}
//                 fillMissingAbnormalData
//                 suffix="%"
//                 // maxValue={100}
//                 // minValue={0}
//                 anomalies={[]}
//             />
//             <LineChartContainer
//                 title="Rightsizing Saved App Cores"
//                 isLoading={false}
//                 isNotCurrency
//                 series={cosmicRightsizingSavedAppCores?.map(singleService => ({
//                     name: "Rightsizing Saved Cores",
//                     data: singleService.data.map(metric => [metric.date.valueOf(), metric.metricValue])
//                 })) || []}
//                 logProps={{
//                     logComponent: LogComponent.CosmicLeadershipView,
//                     logElement: LogElement.CosmicRightsizingSavedAppCores,
//                     customProperties: {
//                         type: props.type,
//                     }
//                 }}
//                 fillMissingAbnormalData
//                 // suffix="%"
//                 // maxValue={100}
//                 // minValue={0}
//                 anomalies={[]}
//             />
//         </div>
//         <div style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "24px", paddingBottom: "24px" }}>
//             <LineChartContainer
//                 title="P99 App Core CPU Utliization"
//                 isLoading={false}
//                 isNotCurrency
//                 series={cosmicPlatformLevelP99CPUUitlization?.map(singleService => ({
//                     name: "P99 App Core CPU Utilization(%)",
//                     data: singleService.data.map(metric => [metric.date.valueOf(), metric.metricValue * 100])
//                 })) || []}
//                 logProps={{
//                     logComponent: LogComponent.CosmicLeadershipView,
//                     logElement: LogElement.CosmicPlatformLevelAppP99CpuUtilization,
//                     customProperties: {
//                         type: props.type,
//                     }
//                 }}
//                 fillMissingAbnormalData
//                 suffix="%"
//                 // maxValue={100}
//                 // minValue={0}
//                 anomalies={[]}
//             />
//         </div>
//     </div>


//     // const { cosmicHierarchicalMetricReport: appCoresWorkloadMetrics, isLoading: isLoadingAppCores } = useGetCosmicWorkloadDailyAppMetric("AppCores");
//     // const { cosmicHierarchicalMetricReport: appCoreCogsWorkloadMetrics, isLoading: isLoadingAppCoreCogs } = useGetCosmicWorkloadDailyAppMetric("AppCoreCogs");

//     // const metricDataConfig = [
//     //     { title: "Cosmic App Request Cores", metric: "CpuRequestCores", logElement: LogElement.CosmicAppRequestCores, workloads: getMetricData("CpuRequestCores", appCoresWorkloadMetrics) },
//     //     { title: "App Core COGS", metric: "AppCoreCogs", logElement: LogElement.CosmicAppCoreCOGS, workloads: getMetricData("AppCoreCogs", appCoreCogsWorkloadMetrics) },
//     //     { title: "Cosmic App Limit Cores", metric: "CpuLimitCores", logElement: LogElement.CosmicAppLimitCores, workloads: getMetricData("CpuLimitCores", appCoresWorkloadMetrics) },
//     //     { title: "Cosmic App Empty Cores", metric: "EmptyAppCores", logElement: LogElement.CosmicAppEmptyCores, workloads: getMetricData("EmptyAppCores", appCoresWorkloadMetrics) }
//     // ];

//     const { cosmicDailyMetricReport: totalCoreMetrics, isLoading: isLoadingTotalCores } = useGetCosmicDailyMetric("TotalCores");
//     const { cosmicDailyMetricReport: platformLevelUtilizationP99, isLoading: isLoadingPlatformLevelUtilizationP99 } = useGetCosmicDailyMetric("PlatformLevelAppUtilizationP99");
//     const { cosmicDailyMetricReport: totalCogMetrics, isLoading: isLoadingTotalCogs } = useGetCosmicDailyMetric("TotalCogs");

//     const metricDataConfig = [
//         { title: "Total Cosmic Cores", logElement: LogElement.CosmicTotalCores, data: getMetricData("TotalCores", totalCoresMetrics) },
//         { title: "Total Cosmic COGS", logElement: LogElement.CosmicTotalCOGS, data: getMetricData("TotalCogs", totalCogsMetrics) },
//         { title: "Platform Overhead with Rightsizing", logElement: LogElement.CosmicPlatformOverheadWithRightsizing, data: getMetricData("TotalCores", totalCoresMetrics) },
//         { title: "Rightsizing Saved Cores", logElement: LogElement.CosmicRightsizingSavedAppCores, data: getMetricData("TotalCores", totalCoresMetrics) },
//     ];

//     const utilizationMetricCard = {
//         title: "Platform level P99 App Utilization",
//         logElement: LogElement.CosmicPlatformLevelAppP99CpuUtilization,
//         data: getMetricData("CpuUtilizationP99", appCoresWorkloadMetrics)
//     };

//     return (
//         <div className={styles.cardContainer}>
//             <InfoMessage />
//             <LeadershipMetricsCard totalCogs={totalCogMetrics} isLoadingTotalCogs={isLoadingTotalCogs} />

//             <MetricsCharts
//                 metricsConfig={metricDataConfig}
//                 isLoadingAppCores={isLoadingAppCores}
//                 isLoadingAppCoreCogs={isLoadingAppCoreCogs}
//                 type={props.type}
//             />

//             <LineChartContainer
//                 key={utilizationMetricCard.title}
//                 title={utilizationMetricCard.title}
//                 isLoading={!utilizationMetricCard.data}
//                 isNotCurrency
//                 series={utilizationMetricCard.data.map(singleMetric => ({
//                     name: singleMetric.workload,
//                     data: singleMetric.data.map(metric => [metric.date.valueOf(), (metric.metricValue ?? 0) * 100])
//                 }))}
//                 logProps={{
//                     logComponent: LogComponent.CosmicPartnerView,
//                     logElement: utilizationMetricCard.logElement,
//                     customProperties: { type: props.type }
//                 }}
//                 fillMissingAbnormalData
//                 suffix="%"
//                 showArea
//                 anomalies={[]}
//             />
//         </div>
//     );
// };

// // Renders multiple metric LineCharts
// const MetricsCharts: React.FC<{ metricsConfig: { title: string; metric: string; logElement: LogElement; workloads: ICosmicHierarchicalWorkloadReport[] }[], isLoadingAppCores: boolean, isLoadingAppCoreCogs: boolean, type: CosmicSourceTypeEnum }> = ({ metricsConfig, isLoadingAppCores, isLoadingAppCoreCogs, type }) => (
//     <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`} style={{ padding: "0 0 24px 0" }}>
//         {metricsConfig.map(({ title, logElement, workloads }) => (
//             <LineChartContainer
//                 key={title}
//                 title={title}
//                 isLoading={title.includes("COGS") ? isLoadingAppCoreCogs : isLoadingAppCores}
//                 isNotCurrency
//                 series={workloads.map(singleMetric => ({
//                     name: singleMetric.workload,
//                     data: singleMetric.data.map(metric => [metric.date.valueOf(), metric.metricValue])
//                 }))}
//                 logProps={{
//                     logComponent: LogComponent.CosmicPartnerView,
//                     logElement,
//                     customProperties: { type }
//                 }}
//                 fillMissingAbnormalData
//                 showArea
//                 anomalies={[]}
//             />
//         ))}
//     </div>
// );

const InfoMessage = () => (
    <MessageBar intent="info" style={{ marginBottom: "24px" }}>
        <MessageBarBody>
            <MessageBarTitle>Important</MessageBarTitle>
            Inaccurate or missing data? Feature request? Contact
            <Link href="mailto:cosmicjawsfeaturecrew@service.microsoft.com" target="__blank"> cosmicjawsfeaturecrew@service.microsoft.com</Link> and let us know!
        </MessageBarBody>
    </MessageBar>
);

export default LeadershipView;