import React, { useMemo } from "react";
import { Spinner, Stack, Text } from "@fluentui/react";

import { IAppState } from "../../store";
import { IFilterView } from "../../models/FilterView";
import { ISavedViewData } from "../../reducers/savedViewReducer";
import SavedViewsItem from "./SavedViewsItem/SavedViewsItem";
import styles from "./SavedViews.less";
import { useSelector } from "react-redux";
import { keys, values } from "lodash";
import { IServiceTreeData } from "../../reducers/serviceTreeReducer";
import { ServiceTreeItem } from "../../models/serviceTree";

const SavedViews: React.FC = () => {
    const { savedViews: originalViews, isLoading, errorHappened } = useSelector<IAppState, ISavedViewData>(state => state.savedViews);
    const { serviceNodeMapByName } = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const sampleView: IFilterView | undefined = useMemo(() =>  {
        let sample: IFilterView | undefined;
        values(originalViews).forEach(view => {
            if (view.filterContent.PredefinedViewName) {
                sample = view; 
            }
        });
        return sample;
    }, [originalViews]);

    const savedViews = useMemo(() => {
        if (!originalViews) return originalViews;

        values(originalViews).forEach(view => fillServiceNodeId(view, serviceNodeMapByName));
        return originalViews;
    }, [originalViews, serviceNodeMapByName]);

    return React.useMemo(() => (
        <>
            {errorHappened && <div>Something went wrong ...</div>}
            {isLoading && (!savedViews || !keys(savedViews).length) && <Spinner label="Loading..." styles={{root: styles.spinnerStyles}} />}
            {!!savedViews && Object.keys(savedViews).length > 0 && 
                <div id="portal-bubble-anchor-saved-view">
                    <Stack className={styles.savedViewHeader} horizontal horizontalAlign={"space-between"}>
                        <Text variant={"medium"} styles={{root: styles.titleStyles}}>Saved Views</Text>         
                        {isLoading && <Spinner styles={{root: styles.spinnerStyles}} />}
                    </Stack>
                    <ul className={styles.list}>
                    {
                        values(savedViews).map(view => {
                            if (!view.filterContent.PredefinedViewName) {
                                return (
                                    <li key={view.id}>
                                        <SavedViewsItem view={view} />
                                    </li>
                                )
                            } 
                        })
                    }
                    {
                        sampleView && 
                        <li key={sampleView.id}>
                            <SavedViewsItem view={sampleView} />
                         </li>
                    }
                   
                    </ul>
                </div>
            }
            {!savedViews &&
                <p id="portal-bubble-anchor-saved-view">
                    No existing view, Welcome to create your own view!
                </p>
            }
        </>
    ), [errorHappened, isLoading, savedViews, sampleView]);
}

function fillServiceNodeId (view: IFilterView, serviceNodeMapByName: Map<string, ServiceTreeItem>) {
    if (view.filterContent.DivisionName?.length && !(view.filterContent.DivisionId)) {
        view.filterContent.DivisionId = view.filterContent.DivisionName.map(name => {
            const node = serviceNodeMapByName.get(name);
            return node?.id || "";
        });
    }
    if (view.filterContent.OrganizationName?.length && !(view.filterContent.OrganizationId)) {
        view.filterContent.OrganizationId = view.filterContent.OrganizationName.map(name => {
            const node = serviceNodeMapByName.get(name);
            return node?.id || "";
        });
    }
    if (view.filterContent.ServiceGroupName?.length && !(view.filterContent.ServiceGroupId)) {
        view.filterContent.ServiceGroupId = view.filterContent.ServiceGroupName.map(name => {
            const node = serviceNodeMapByName.get(name);
            return node?.id || "";
        });
    }
    if (view.filterContent.TeamGroupName?.length && !(view.filterContent.TeamGroupId)) {
        view.filterContent.TeamGroupId = view.filterContent.TeamGroupName.map(name => {
            const node = serviceNodeMapByName.get(name);
            return node?.id || "";
        });
    }
    if (view.filterContent.ServiceName?.length && !(view.filterContent.ServiceId)) {
        view.filterContent.ServiceId = view.filterContent.ServiceName.map(name => {
            const node = serviceNodeMapByName.get(name);
            return node?.id || "";
        });
    }
}

export default SavedViews;