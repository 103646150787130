import React from "react";
import styles from "./MemoryRedundancyCard.less";
import { DefaultButton } from "@fluentui/react";
import { MemoryRedundancySummary } from "../../../models/MemoryRedundancyModels";
import { currencyFormatter } from "../../../utils/currency";
import { MemoryRedundancyBarChart } from "./MemoryRedundancyBarChart";
import { getPercentage } from "../../../utils/common";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useRings } from "../../../hooks/useRings";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { ServiceTreeItem } from "../../../models/serviceTree";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import moment from "moment";

interface IMemoryRedundancyCardProps {
    query: MemoryRedundancySummary[];
}

export const MemoryRedundancyCard: React.FC<IMemoryRedundancyCardProps> = (props) => {
    const metrics = ['System.String', 'System.Int32[]', 'System.Byte[]', 'System.Char[]', 'System.Object[]'];
    const cutoffDate = moment(new Date("2024-01-01"));

    const query = props.query;
    const record = query?.reduce((result, item) => {
        return { ...result, [item.eventType]: item };
    }, {} as Record<string, MemoryRedundancySummary>);

    for (const metric of metrics) {
        if (!record[metric]) {
            record[metric] = { eventType: metric, cost: 0, percentage: 0 };
        }
    }

    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    const { startDate, endDate } = useDateRange();
    const [rings] = useRings();
    const ringstring: string = rings.length == 0 ? "WW, SIP, MSIT, SDFV2" : rings.join(", ");
    const filtersData = useCategoryFilters().filters;

    const filters = Array.from(new Set((filtersData.filters.Service?.map(serviceId => serviceIdMap.get(serviceId)?.n || serviceId) || []).concat(filtersData.filters.ProcessV2 || []))).join(", ");

    // Exclude Distinct Memory from the total cost.
    const sum = query?.reduce((acc, num) => {
        if (num.eventType == 'Distinct Memory') 
        {
            return acc;
        } 
        return acc + num.cost;
    }, 0);

    return(
        <div className={styles.cardContainer}>
            <div className= {styles.cardWrapper}>
                <div style={{height:35, marginTop:16}}>
                    <div style={{height:28, fontSize:"20px", fontWeight:700, display:"inline-block"}}>{sum && currencyFormatter(sum, 2, "$")} memory redundancy cost</div>
                    <div style={{display:"inline-block", marginLeft:10, marginTop:4}}><button style={{height:20, backgroundColor:"#D3F4ED", color:"#323130", fontWeight:600, fontSize:9, border:"none", borderRadius:"3px", letterSpacing:1}}>{ringstring}</button></div>
                </div>
                <div style={{height:56}}><span style={{fontSize:10, fontWeight:600, height:14, color:"#484644", marginTop: 26, display: "inline-block"}}>{startDate >= cutoffDate ? startDate.format("MM/DD/YYYY") : cutoffDate.format("MM/DD/YYYY")} - {endDate.format("MM/DD/YYYY")}; updated 1pm today</span></div>
                <div style={{width: "90%"}}>
                    {record && <MemoryRedundancyBarChart record={record} metrics={metrics}></MemoryRedundancyBarChart>}
                </div>
                <div style={{height:56, marginTop:16}}><span style={{height:56, fontSize:14}}><b  style={{fontWeight:600}}>Workloads: </b>{filters}</span></div>
                <div style={{height:220, width: "90%"}}>
                    <table className={styles.tableBody} style={{width:"100%"}}>
                        <thead style={{verticalAlign: "top", width:"100%"}}>
                            <tr>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:400}}>Frame type</td>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:400}}>Redundancy cost</td>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:400}}>Redundancy percentage</td>
                            </tr>
                            <tr>
                                <td style={{width:"30%", fontSize:14, height:20, fontWeight:600}}>System.String</td>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:400}}>{record && currencyFormatter(record["System.String"].cost, 2, "$")}</td>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:400}}><span style={{marginLeft:16,backgroundColor:"#EDEDED"}}>{record && getPercentage(record["System.String"].percentage)}</span></td>
                            </tr>
                            <tr>
                                <td style={{width:"30%", fontSize:14, height:20, fontWeight:600}}>System.Int32[]</td>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:400}}>{record && currencyFormatter(record["System.Int32[]"].cost, 2, "$")}</td>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:400}}><span style={{marginLeft:16,backgroundColor:"#EDEDED"}}>{record && getPercentage(record["System.Int32[]"].percentage)}</span></td>
                            </tr>
                            <tr>
                                <td style={{width:"30%", fontSize:14, height:20, fontWeight:600}}>System.Byte[]</td>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:400}}>{record && currencyFormatter(record["System.Byte[]"].cost, 2, "$")}</td>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:400}}><span style={{marginLeft:16,backgroundColor:"#EDEDED"}}>{record && getPercentage(record["System.Byte[]"].percentage)}</span></td>
                            </tr>
                            <tr>
                                <td style={{width:"30%", fontSize:14, height:20, fontWeight:600}}>System.Char[]</td>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:400}}>{record && currencyFormatter(record["System.Char[]"].cost, 2, "$")}</td>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:400}}><span style={{marginLeft:16,backgroundColor:"#EDEDED"}}>{record && getPercentage(record["System.Char[]"].percentage)}</span></td>
                            </tr>
                            <tr>
                                <td style={{width:"30%", fontSize:14, height:20, fontWeight:600}}>System.Object[]</td>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:400}}>{record && currencyFormatter(record["System.Object[]"].cost, 2, "$")}</td>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:400}}><span style={{marginLeft:16,backgroundColor:"#EDEDED"}}>{record && getPercentage(record["System.Object[]"].percentage)}</span></td>
                            </tr>
                            <tr>
                                <td style={{width:"30%", fontSize:14, height:20, fontWeight:600}}>Total</td>
                                <td style={{width:"30%", fontSize:12, height:16, fontWeight:700}}>{sum && currencyFormatter(sum, 2, "$")}</td>
                                <td></td>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className={styles.cardFooter}>
                    <DefaultButton className={styles.viewInsight} onClick={function (): void {
                            const url = `https://aka.ms/cprviewer?Product=EXO&Job=Daily&Day=${endDate.format("YYYY-MM-DD")}&Stream=Redundancy%20Memory%20%28Managed%20Heap%29&ProductVersion=All&Process=All&Peak=All&View=Stacks`;
                            window.open(url, '_blank');
                        }}>
                        View Insight
                    </DefaultButton>
                </div>
            </div>
        </div>
    );
}