import * as React from "react";

import {
    ActionButton,
    Callout,
    Checkbox,
    DefaultButton,
    DirectionalHint,
    Icon,
} from "@fluentui/react";
import { CategoryDivision, CategoryDivisionDisplayName } from "../../../../models/CategoryDivision";
import { FiltersAction, FiltersView } from "../../../../reducers/filterReducer";

import { setTeachingBubblesShowed } from "../../../../utils/TutorialUtil";
import styles from "./FiltersDropDown.less";
import { useBoolean } from "@fluentui/react-hooks";
import { useCategoryFilters } from "../../../../hooks/useFilters";

interface IFilterDropDownProps {
    selectedKeys: CategoryDivision[];
    onChange: (keys: CategoryDivision[]) => void;
    supportedFilters: CategoryDivision[];
    scenario?: string
}

const FiltersDropDown: React.FC<IFilterDropDownProps> = ({ selectedKeys, onChange, supportedFilters: filterList, scenario }) => {
    const buttonRef = React.useRef<HTMLDivElement>(null);
    const [calloutVisible, { toggle: toggleCalloutVisible, setFalse: hideCallout }] = useBoolean(false);
    const globalFilters = useCategoryFilters();
    const updateFilters = useCategoryFilters().updateFilters;
    const handleToggleSelect = (key: string, checked: boolean) => {
        const newSelectedKeys = [...selectedKeys];

        if (checked) {
            newSelectedKeys.push(key as CategoryDivision);
        } else {
            newSelectedKeys.splice(newSelectedKeys.indexOf(key as CategoryDivision), 1);
            const tempFilter = {...globalFilters.filters.filters};
            tempFilter[key as CategoryDivision] = [];
            updateFilters(FiltersAction.Replace, { filters: tempFilter, view: FiltersView.AddableList });
        }
        if(scenario == "COGSCalculator") {
            setTeachingBubblesShowed("COGSCalculator")
            window.dispatchEvent(new Event("storage"));
        }
        onChange(newSelectedKeys);
    };

    const handleClearAll = () => {
        onChange([]);
    };

    return (
        <div className={styles.regionSelector}>
            <div className={styles.regionSelectorContent} ref={buttonRef}>
                <DefaultButton onClick={toggleCalloutVisible} className={styles.serviceButton}>
                    <Icon iconName="Filter" style={{ color: '#005A9E' }} /><span>Add Filter</span>
                </DefaultButton>
            </div>
            {
                calloutVisible && (
                    <Callout
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        isBeakVisible={false}
                        target={buttonRef.current}
                        onDismiss={hideCallout}
                    >
                        <div className={styles.regionList}>
                            {filterList.map(item => (
                                <Checkbox
                                    className={styles.regionListItem}
                                    checked={selectedKeys.indexOf(item) >= 0}
                                    label={CategoryDivisionDisplayName[item]}
                                    key={item}
                                    id={item}
                                    onChange={(_, checked) => handleToggleSelect(item, !!checked)}
                                />
                            ))}
                        </div>
                        <ActionButton onClick={handleClearAll} primaryDisabled={selectedKeys.length === 0} className={styles.clearButton} iconProps={{ iconName: 'Cancel' }} disabled={selectedKeys.length === 0} >Clear All</ActionButton>
                    </Callout>)
            }
        </div>
    );
};

export default FiltersDropDown;
