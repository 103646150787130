import React from "react";
import { ESCostDataDimensions, ESOverviewTimeSelection, ESTotalFYCostRawResponse } from "../../../../models/ESCostModels";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import StackChart, { IStackChartDataProps } from "../Common/StackChart";
import { ESOrgs } from "../../../../models/constants/ESCostConstants";

interface IProps {
    data: ESTotalFYCostRawResponse[];
}

function reduceCosts(costs: ESTotalFYCostRawResponse[]): IStackChartDataProps[] {
    const categories: string[] = [...new Set(costs.map((cost) => cost[ESCostDataDimensions.Category]))];
    categories.sort();
    const result = categories.map((c) => ({ name: c, values: Array.from({ length: ESOrgs.length }, () => 0) }));

    costs.forEach((cost) => {
        const i = categories.indexOf(cost[ESCostDataDimensions.Category]);
        const j = ESOrgs.indexOf(cost[ESCostDataDimensions.Organization]);
        result[i].values[j] += cost[ESCostDataDimensions.Cost];
    });

    return result;
}

function getDataAndOptions(rawCost: ESTotalFYCostRawResponse[], year: number) {
    const costs = rawCost.filter((cost) => ESOrgs.includes(cost[ESCostDataDimensions.Organization]) && cost[ESCostDataDimensions.ReturnFiscalYear] === year);

    return reduceCosts(costs);
}

const TotalCostInCategoriesStackChart = (props: IProps) => {
    const { data: rawCost } = props;
    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );

    const data = React.useMemo(() => getDataAndOptions(rawCost, year), [rawCost, year]);
    return (
        <StackChart
            data={data}
            categories={ESOrgs}
            description={`Total Artifact cost in different categories (FY${String(year).substring(2)})`}
            yAxisTitle="Actual cost"
        />
    );
};

export default TotalCostInCategoriesStackChart;
