import React, { useState } from 'react';
import { Dropdown, IDropdownOption, DropdownMenuItemType } from '@fluentui/react';
import styles from "./FeedbackPanel.less";
interface Option {
    label: string;
    options: { key: string; text: string }[];
}
interface MultiLevelDropdownProps {
    onSelectionChange: (selectedOption: string | number | undefined) => void;
}

const MultiLevelDropdown: React.FC<MultiLevelDropdownProps> = ({ onSelectionChange }) => {
    const [selectedOption, setSelectedOption] = useState<string | number | undefined>(undefined);

    const options: Option[] = [
        {
            label: 'Report a Problem: for data issue',
            options: [
                { key: 'Data Issue:CostMapping', text: 'Service Tree Cost Mapping -> provide Service Name (current and corrected) ' },
                { key: 'Data Issue:Bug ', text: 'Issue/bug ' },
                { key: 'Data Issue:Other', text: 'Other' },
            ],
        },
        {
            label: 'Ask a Question',
            options: [
                { key: 'Ask a Question:(Azure, Cosmic, Substrate)', text: 'Question about a cost model (Azure, Cosmic, Substrate)' },
                { key: 'Ask a Question:Other', text: 'Other', },
            ],
        },
        {
            label: 'Suggest a Feature: for new features or capabilities',
            options: [
                { key: 'Suggest a Feature:Feature', text: 'Feature' },
            ],
        },
        {
            label: 'Other',
            options: [
                { key: 'Other: General feedback ', text: 'General feedback' },
                { key: 'Other: Suggestion', text: 'Suggestion' },
                { key: 'Other: Give a compliment', text: 'Give a compliment – positive feedback or praise 😊 ' },
            ],
        },
    ];

    const flattenedOptions: IDropdownOption[] = options.flatMap((section) => [
        { key: section.label, text: section.label, itemType: DropdownMenuItemType.Header },
        ...section.options.map((option) => ({ key: option.key, text: "\u00A0\u00A0" + option.text })),
    ]);


    const handleDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            setSelectedOption(option.key);
            onSelectionChange(option.key);
        }
    };


    return (
        <div>
            <Dropdown
                options={flattenedOptions}
                selectedKey={selectedOption}
                onChange={handleDropdownChange}
                placeholder="Select your feedback area"
                className={styles.dropdownOption}
            />
        </div>
    );
};

export default MultiLevelDropdown;
