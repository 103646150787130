import {
    ICosmicDailyMetricReport,
    ICosmicDailyMetricsByWorkload,
    ICosmicHierarchicalMetricReport,
    ICosmicServiceDetailReport,
    ICosmicServiceDetails,
} from "../models/CosmicModels";

import { CosmicService } from "../services/cosmicService";
import { IAppState } from "../store";
import { ServiceTreeItem } from "../models/serviceTree";
import { useCategoryFilters } from "./useFilters";
import { useCosmicFilters } from "./useCosmicFilters";
import { useCustomQuery } from "./useCustomQuery";
import { useDateRange } from "./useDateSelector";
import { useSelector } from "react-redux";

export function useGetCosmicDailyMetricsByWorkload(metric: string, n: number) {
    const range = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);
    const query = useCustomQuery(
        CosmicService.getTopNCosmicDailyMetricsByWorkload,
        range.startDate,
        range.endDate,
        filtersData.filters,
        serviceIdMap,
        n,
        metric
    );
    const result: { [key: string]: ICosmicDailyMetricsByWorkload[] } = {};
    if (query.data) {
        for (const item in query.data) {
            if (!result[query.data[item].workload]) {
                result[query.data[item].workload] = [];
                result[query.data[item].workload].push(query.data[item]);
            } else {
                result[query.data[item].workload].push(query.data[item]);
            }
        }
    }

    return {
        cosmicDailyMetrics: result,
        isLoading: query.isLoading,
    };
}

export function useGetCosmicDailyCost() {
    const range = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);
    const query = useCustomQuery(CosmicService.getCosmicDailyCost, range.startDate, range.endDate, filtersData.filters, serviceIdMap);
    return {
        cosmicDailyCost: query.data || [],
        isLoading: query.isLoading,
    };
}

export function useGetCosmicServiceDimensionMetric(metricType: string, cosmicSearchFilters: string) {
    const globalFilters = useCategoryFilters().filters;
    const range = useDateRange();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);
    const query = useCustomQuery(
        CosmicService.getCosmicServiceDimensionMetric,
        range.startDate,
        range.endDate,
        cosmicSearchFilters || '',
        globalFilters.filters,
        serviceIdMap,
        metricType
    );
    return {
        cosmicServiceDimension: (query.data as string[]) || [],
        isLoading: query.isLoading,
    };
}

export function useGetCosmicWorkloadDailyAppMetric(metricType: string, isSingleValue = false) {
    const globalFilters = useCategoryFilters().filters;
    const cosmicSearchFilters = useCosmicFilters('defaultFilterKey').cosmicSearchFilters;
    const { startDate, endDate } = useAdjustedDateRange(isSingleValue);
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);
    const query = useCustomQuery(
        CosmicService.getCosmicWorkloadDailyAppMetric,
        startDate,
        endDate,
        cosmicSearchFilters || '',
        globalFilters.filters,
        serviceIdMap,
        metricType
    );
    return {
        cosmicHierarchicalMetricReport: (query.data as ICosmicHierarchicalMetricReport[]) || [],
        isLoading: query.isLoading,
    };
}

export function useGetCosmicServiceDetails(limit?: number, orderby?: string, desc?: boolean, isSingleValue = true) {
    const globalFilters = useCategoryFilters().filters;
    const cosmicSearchFilters = useCosmicFilters('defaultFilterKey').cosmicSearchFilters;
    const { startDate, endDate } = useAdjustedDateRange(isSingleValue);

    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);
    const query = useCustomQuery(
        CosmicService.getCosmicServiceDetails,
        startDate,
        endDate,
        cosmicSearchFilters || '',
        globalFilters.filters,
        serviceIdMap,
        limit,
        orderby,
        desc
    );
    return {
        data: query.data as ICosmicServiceDetailReport<ICosmicServiceDetails>,
        isLoading: query.isLoading,
    };
}

export function useGetCosmicDailyMetric(metricType: string, isSingleValue = false) {
    const { startDate, endDate } = useAdjustedDateRange(isSingleValue);
    const query = useCustomQuery(
        CosmicService.getCosmicDailyMetric,
        startDate,
        endDate,
        metricType
    );
    return {
        cosmicDailyMetricReport: query.data as ICosmicDailyMetricReport[] || [],
        isLoading: query.isLoading,
    };
}

function useAdjustedDateRange(isSingleValue: boolean) {
    const range = useDateRange();
    return {
        startDate: isSingleValue ? range.endDate : range.startDate,
        endDate: range.endDate,
    };
}