import { ActionButton, Callout, DirectionalHint, FontIcon, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { AzureComputeHeatmapDegree } from '../common/AzureComputeConstants';
import styles from './HeatmapIndicator.less';

export const HeatmapIndicator : React.FC = () => {
    const [visible, setVisible] = useState(false);

    return (
        <div>
            <ActionButton id="heatmap-bg-indicator" iconProps={{ iconName: 'BackgroundColor' }} className={styles.heatmapIndicatorBtn} onClick={() => setVisible(!visible)}>
                <span>Heatmap Backgroud</span>
                <FontIcon iconName='ChevronDown' className={visible ? 'active' : ''}/>
            </ActionButton>
            { visible && (
                <Callout onDismiss={() => setVisible(false)} target="#heatmap-bg-indicator" isBeakVisible={false} directionalHint={DirectionalHint.bottomRightEdge}>
                    <div className={styles.degreeList}>
                        {AzureComputeHeatmapDegree.map(degree => (
                            <Stack horizontal verticalAlign='center' key={degree.range}>
                                <div className={styles.heatmapIndicatorColor} style={{ backgroundColor: degree.color }} />
                                <div>{degree.range}</div>
                            </Stack>
                        ))}
                    </div>
                </Callout>)}
        </div>
    );
};