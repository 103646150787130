// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h3n7ncVkRf8PWaXyoJQw,\n.j7DdJyNVzaggR26FN1Tx {\n  display: inline-block;\n}\n.h3n7ncVkRf8PWaXyoJQw {\n  font-size: 12px;\n  width: 32px;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/table/TableRowExpander.less"],"names":[],"mappings":"AACA;;EACI,qBAAA;AACJ;AAEA;EACI,eAAA;EACA,WAAA;EACA,kBAAA;AAAJ","sourcesContent":["\n.controller, .placeholder {\n    display: inline-block;\n}\n\n.controller {\n    font-size: 12px;\n    width: 32px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controller": "h3n7ncVkRf8PWaXyoJQw",
	"placeholder": "j7DdJyNVzaggR26FN1Tx"
};
export default ___CSS_LOADER_EXPORT___;
