import { ISectionHeaderProps, SectionHeader } from './SectionHeader';

import LoadingState from '../ResponseBoundary/LoadingState';
import React from 'react';
import styles from './Section.less';

export interface ISectionProps {
    headerProps: ISectionHeaderProps;
    loading?: boolean;
    error?: string;
    styles?: {
        content?: string;
    };
}

export const Section: React.FC<ISectionProps> = (props) => {
    let sectionContent: React.ReactNode;

    if (props.loading) {
        sectionContent = <LoadingState />;
    } else if (props.error) {
        sectionContent = props.error;
    } else {
        sectionContent = props.children;
    }

    return (
        <div className={styles.section}>
            <SectionHeader {...props.headerProps} />
            <div className={props.styles?.content}>
                {sectionContent}
            </div>
        </div>
    );
};