import { AuthenticationResult } from '@azure/msal-browser';
import { Separator } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuthResult } from '../../utils/apiServiceBase';
import PageHeader from '../common/PageHeader';
import AccessTokenInfo from './AcessTokenInfo';
import styles from './AuthView.less';


const AuthView: React.FC = () => {
    const navigate = useNavigate();
    if (ENVIRONMENT != "dev" && ENVIRONMENT != "localhost") navigate("/");

    const [authResult, setAuthResult] = useState({} as AuthenticationResult);

    useEffect(() => {
        getAuthResult().then(async (authResult) => {
            setAuthResult(authResult)
        })
    }, []);

    return (
        <div className={styles.authView}>
            <PageHeader
                title="My Access Token"
                description="Access tokens enable clients to securely call protected web APIs, and are used by web APIs to perform authentication and authorization, you can find more information "
                link="https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/31438/JawsPCM"
                linkText="here"
            />
            <Separator styles={{ root: styles.separator }} />
            <AccessTokenInfo authInfo={authResult} />
        </div>
    );
};

export default AuthView;
