import { Pages, SubPages } from "../../../models/Nav";
import { Pivot, PivotItem } from "@fluentui/react";

import { EmpytCostView } from "../common/EmptyCostView";
import PageHeader from "../../common/PageHeader";
import { PcmV2SceneTypeEnum } from "../../../models/PcmV2Models";
import React from "react";
import { SubstrateHddDataset } from "./SubstrateHddDataset";
import { SubstrateHddMailboxCategory } from "./SubstrateHddMailboxCategory";
import { SubstrateV2WikiLink } from "../../../models/constants/PcmV2Constants";
import { WarningBanner } from "../../banner/WarningBanner";
import commonStyles from "../Substrate.less";
import { useGetBannersByTabAndDateQuery } from "../../../hooks/useBannerQuery";
import { useGetPcmV2Cost } from "../../../hooks/useSubstrateV2Query";
import { useGotoTab } from "../../../hooks/useGotoPage";
import { useParams } from "react-router-dom";
import { trackEventCallback } from "../../../utils/AppInsights";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";

export const SubstrateHdd: React.FC = () => {
    const { data: pcmV2Cost } = useGetPcmV2Cost();
    const { data: banners } = useGetBannersByTabAndDateQuery(PcmV2SceneTypeEnum.HDD);
    const curTab = useParams().tab || "Dataset";

    const gotoTab = useGotoTab();
    const handleLinkClick = (item?: PivotItem) => {
        if (item?.props.itemKey) {
            gotoTab(`${Pages.SubstrateV2}/${SubPages.Hdd}`, item?.props.itemKey, `${Pages.SubstrateV2}/${SubPages.Hdd}`);
            trackEventCallback(LogComponent.HddStorageV2Tabs, item.props.itemKey === "Dataset" ? LogElement.HddStorageByDataset : LogElement.HddStorageByMailboxCategory, item.props.itemKey, LogTarget.Tab);
        }
    };

    return (
        <div className={commonStyles.pageWrapper}>
            <PageHeader
                className={commonStyles.pageHeader}
                title="HDD-Storage Cost View"
                description="We use this cost category to attribute the cost associated with at-rest storage of bytes on HDD storage media."
                link={SubstrateV2WikiLink}
                linkText="Learn More"
                tag="PREVIEW"
                linkLogComponent={LogComponent.HDDChartingPane}
                linkLogElement={LogElement.LearnMoreHDD}
            />
            {!!banners?.length && (
                <div className={commonStyles.bannerWrapper}>
                    {banners.map((item) => (
                        <WarningBanner key={item.id} bannerItem={item} />
                    ))}
                </div>
            )}
            {pcmV2Cost && pcmV2Cost.hddCost === 0 ? (
                <EmpytCostView />
            ) : (
                <Pivot selectedKey={curTab} onLinkClick={handleLinkClick} focusZoneProps={{ className: commonStyles.pivotList }} overflowBehavior="menu">
                    <PivotItem headerText="HDD-Storage by Dataset" itemKey="Dataset">
                        <SubstrateHddDataset />
                    </PivotItem>
                    <PivotItem headerText="HDD-Storage by Mailbox Category" itemKey="MailboxCategory">
                        <SubstrateHddMailboxCategory />
                    </PivotItem>
                </Pivot>
            )}
        </div>
    );
};
