import { ActionButton, Callout, Checkbox, DirectionalHint, Icon, SearchBox } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { debounce } from 'lodash';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useGetAzureComputeRdosRegionList } from '../../../hooks/useAzureQuery';
import styles from './RegionSelector.less';

interface IRegionSelectorProps {
    selectedKeys: string[];
    onChange: (keys: string[]) => void;
}

const RegionSelector: React.FC<IRegionSelectorProps> = ({ selectedKeys, onChange }) => {
    const buttonRef = useRef<HTMLDivElement>(null);
    const [calloutVisible, { toggle: toggleCalloutVisible, setFalse: hideCallout }] = useBoolean(false);
    const regionList = useGetAzureComputeRdosRegionList();
    const [searchText, setSearchText] = useState("");

    const handleToggleSelect = (key: string, checked: boolean) => {
        const newSelectedKeys = [...selectedKeys];

        if (checked) {
            newSelectedKeys.push(key);
        } else {
            newSelectedKeys.splice(newSelectedKeys.indexOf(key), 1);
        }

        onChange(newSelectedKeys);
    };

    const handleClearAll = () => {
        onChange([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearchChange = useCallback(debounce((event?: ChangeEvent<HTMLInputElement>, newValue?: string) => {
        setSearchText(newValue?.toLowerCase() || "");
    }, 300), [setSearchText]);

    let labelText = '';

    if (!selectedKeys.length) labelText = 'All Regions';
    else if (selectedKeys.length === 1) labelText = selectedKeys[0];
    else labelText = `${selectedKeys.length} Regions`;

    return (
        <div className={styles.regionSelector}>
            <div className={styles.regionSelectorContent} ref={buttonRef} onClick={toggleCalloutVisible}>
                <Icon iconName="POI" style={{ color: '#005A9E' }} /> <span className={styles.regionSelectorContentText}>{labelText}</span> <Icon iconName="ChevronDown" />
            </div>
            {
                calloutVisible && (
                <Callout
                    directionalHint={DirectionalHint.bottomRightEdge}
                    isBeakVisible={false}
                    target={buttonRef.current}
                    onDismiss={hideCallout}
                >
                    <SearchBox className={styles.search} placeholder="Search region" onChange={handleSearchChange} defaultValue={searchText} />
                    <div className={styles.regionList}>
                        {regionList.data?.filter(region => region.includes(searchText)).map(item => (
                            <Checkbox
                                className={styles.regionListItem}
                                checked={selectedKeys.indexOf(item) >= 0}
                                label={item}
                                key={item}
                                id={item}
                                onChange={(_, checked) => handleToggleSelect(item, !!checked)}
                            />
                        ))}
                    </div>
                    <ActionButton onClick={handleClearAll} primaryDisabled={selectedKeys.length === 0} className={styles.clearButton} iconProps={{ iconName: 'Cancel' }} disabled={selectedKeys.length === 0} >Clear All</ActionButton>
                </Callout>)
            }
        </div>
    )
};

export default RegionSelector;
