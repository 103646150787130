import React from "react";
import {
    ESCostDataDimensions,
    ESCostOrganization,
    ESOverviewTimeSelection,
    ESStampIdFYCostRawResponse,
    ESUsageScenarios,
} from "../../../../models/ESCostModels";
import { useEsUsageScenarios } from "../../../../hooks/useEsUsageScenarios";
import { useEsOrg } from "../../../../hooks/useEsOrg";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import { ESAllUsageScenario, ESOrgs } from "../../../../models/constants/ESCostConstants";
import { Histogram, IHistogramItem } from "../Common/Histogram";

interface IProps {
    data: ESStampIdFYCostRawResponse[];
}

function reduceCost(costs: ESStampIdFYCostRawResponse[]): IHistogramItem[] {
    const result: { [key: string]: number } = {};

    costs.forEach((cost) => {
        const name = cost[ESCostDataDimensions.StampID];
        if (!(name in result)) {
            result[name] = 0;
        }
        result[name] += cost[ESCostDataDimensions.Cost];
    });

    const l: IHistogramItem[] = Object.entries(result).map(([name, value]) => ({
        name,
        value,
    }));
    l.sort((a, b) => b.value - a.value);
    return l.slice(0, 10);
}

function calculateCosts(
    org: ESCostOrganization[],
    usageScenarios: ESUsageScenarios[],
    year: number,
    data: ESStampIdFYCostRawResponse[]
): IHistogramItem[] {
    const costs = data.filter(
        (cost) =>
            ESOrgs.includes(cost[ESCostDataDimensions.Organization]) &&
            ESAllUsageScenario.includes(cost[ESCostDataDimensions.UsageScenario]) &&
            (usageScenarios.length === 0 || usageScenarios.includes(cost[ESCostDataDimensions.UsageScenario])) &&
            (org.length === 0 || org.includes(cost[ESCostDataDimensions.Organization])) &&
            year === cost[ESCostDataDimensions.ReturnFiscalYear]
    );

    return reduceCost(costs);
}

export const StampIdTopCostHistogram: React.FC<IProps> = (props) => {
    const { data } = props;
    const [org] = useEsOrg();
    const [usageScenarios] = useEsUsageScenarios();
    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );

    const values: IHistogramItem[] = React.useMemo(() => calculateCosts(org, usageScenarios, year, data), [org, usageScenarios, year, data]);

    return (
        <Histogram
            values={values.map((v) => v.value)}
            xAxisLabels={values.map((v) => v.name)}
            seriesHeader="CloudBuild cost"
            description={`Top 10 CloudBuild cost of StampID (FY${String(year).substring(2)})`}
            showValue={true}
            isCurrency={true}
        />
    );
};
