import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { updateTabCache } from "../utils/pageUtils";

export function useGotoPage() {
    const navigate = useNavigate();
    const location = useLocation();

    const gotoPage = useCallback((destination: string, search?: string) => {
        navigate(destination + (search || location.search))
    }, [location.search, navigate]);

    return gotoPage;
}

export function useGotoTab() {
    const gotoPage = useGotoPage();

    const gotoTab = useCallback((page: string, tab: string, tabKey: string, search?: string) => {
        gotoPage(`${page}/${tab}`, search);
        updateTabCache(tabKey, tab);
    }, [gotoPage]);

    return gotoTab;
}