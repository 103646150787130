import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../common/PageHeader';
import styles from './SQLToolView.less';
import { ITextField, PrimaryButton, TextField, Text, TeachingBubble } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';

const SQLToolView: React.FC = () => {
    const navigate = useNavigate();
    if (ENVIRONMENT != "dev" && ENVIRONMENT != "localhost") navigate("/");
    const inputRef = useRef<ITextField>(null);

    const raw = "Paste you sql here";
    const [compressed, setCompressed] = useState("Compressed sql, please copy it to the ddl.history file");

    function compress() {
        if (inputRef.current?.value) {
            let result = "";
            const splited = inputRef.current.value.split("\n");
            for (const line of splited) {
                result += " " + line;
            }
            setCompressed(result);
        }
    }
    const tokenToClipboard = useId("sqlToClipboard");
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);

    function copyToClipBoard() {
        navigator.clipboard.writeText(compressed);
        toggleTeachingBubbleVisible();
    }

    return (
        <div className={styles.sqltoolView}>
            <PageHeader
                title="Compress Complicated SQL Command to Single Line"
                description="Tool is a supporting tool for Jaws team's db incremental deployment tool, which uses a seperated file to 
                 store the DDL commands. Currently to make things easier, the deployment tool assume that
                 each line is a single command.This tool helps compress the complicated queries like store procedure definition commands into sinlge line"
            />
            <TextField multiline rows={15} readOnly={false} placeholder={raw} componentRef={inputRef} />
            <PrimaryButton id="compresssqlbuttong" onClick={compress} text="Compress" className={styles.clipboardButton} />
            <Text>{compressed}</Text>
            <PrimaryButton id="sqlToClipboard" onClick={copyToClipBoard} text="Copy to clipboard" className={styles.clipboardButton} />
            {teachingBubbleVisible && (
                <TeachingBubble
                    target={`#${tokenToClipboard}`}
                    isWide={false}
                    hasCloseButton={true}
                    closeButtonAriaLabel="Close"
                    onDismiss={toggleTeachingBubbleVisible}
                    headline="SQL copied to clipboard."
                    hasSmallHeadline={true}
                >
                </TeachingBubble>
            )}
        </div>
    );
};

export default SQLToolView;
