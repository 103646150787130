// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IUrQn6PKMDWCQLR7Vygx {\n  margin: 20px 0;\n}\n.Bg6KCpLEEQyxhnyXKm2t {\n  height: 100%;\n  overflow: hidden;\n}\n.XH0LKZ_Wu9uh8NIEUYBd {\n  top: 48px;\n}\n.uitjwIr7HtckeNuAGI5M {\n  height: calc(100% - 122px);\n  min-height: unset;\n  overflow-y: auto;\n}\n.OXPZdYwIVSiVVyxhGFSU {\n  flex: 0;\n  padding: 16px 24px;\n}\n.tMWQZ3qaXHuLTcuuIc2v {\n  margin-right: 8px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SelectedList/SelectedList.less"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;AAEA;EACI,YAAA;EACA,gBAAA;AAAJ;AAGA;EACI,SAAA;AADJ;AAIA;EACI,0BAAA;EACA,iBAAA;EACA,gBAAA;AAFJ;AAKA;EACI,OAAA;EACA,kBAAA;AAHJ;AAMA;EACI,iBAAA;AAJJ","sourcesContent":[".checkbox {\n    margin: 20px 0;\n}\n\n.panelScrollableContent {\n    height: 100%;\n    overflow: hidden;\n}\n\n.panelMain {\n    top: 48px;\n}\n\n.panelContent {\n    height: calc(100% - 122px);\n    min-height: unset;\n    overflow-y: auto;\n}\n\n.footer {\n    flex: 0;\n    padding: 16px 24px;\n}\n\n.applyButton {\n    margin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "IUrQn6PKMDWCQLR7Vygx",
	"panelScrollableContent": "Bg6KCpLEEQyxhnyXKm2t",
	"panelMain": "XH0LKZ_Wu9uh8NIEUYBd",
	"panelContent": "uitjwIr7HtckeNuAGI5M",
	"footer": "OXPZdYwIVSiVVyxhGFSU",
	"applyButton": "tMWQZ3qaXHuLTcuuIc2v"
};
export default ___CSS_LOADER_EXPORT___;
