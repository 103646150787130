export enum CategoryDivision {
    Owner = "Owner",
    BusinessOwner = "BusinessOwners",
    Service = "Service",
    ServiceGroup = "ServiceGroup",
    Division = "Division",
    Organization = "Organization",
    TeamGroup = "TeamGroup",
    VDir = "VDir",
    Process = "Process",
    ScenarioTag = "ScenarioTag",
    GriffinApp = "GriffinApp",
    PredefinedViewName = "PredefinedViewName",
    Subscription = "Subscription",
    Cluster = "Cluster",
    GriffinProcessor = "GriffinProcessor",
    StoreClient = "StoreClient",
    StoreClientComponent = "StoreClientComponent",
    SSDDataSetName = "SSDDataSetName",

    /** PCMv2 search */
    GriffinAppV2 = "GriffinAppV2",
    GriffinProcessorV2 = "GriffinProcessorV2",
    StoreClientComponentV2 = "StoreClientComponentV2",
    StoreClientV2 = "StoreClientV2",
    ScenarioTagV2 = "ScenarioTagV2",
    VdirV2 = "VdirV2",
    ProcessV2 = "ProcessV2",
    DataSetV2 = "DataSetV2",

    /** PCMv2 search */
    PlatformProcess = "PlatformProcess",
    PlatformApp = "PlatformApp",
    PlatformSubApp = "PlatformSubApp",
    PlatformClient = "PlatformClient",
    PlatformClientComponent = "PlatformClientComponent",

    /** Efficiency Tracker Big Bets search */
    Category = "Category",
    Workload = "Workload",
    Confidence = "Confidence",
    ExecutionStatus = "ExecutionStatus",
}

export const CategoryDivisionDisplayName: Record<CategoryDivision, string> = {
    [CategoryDivision.Owner]: "Owner",
    [CategoryDivision.BusinessOwner]: "BusinessOwner",
    [CategoryDivision.Service]: "Service",
    [CategoryDivision.ServiceGroup]: "Service Group",
    [CategoryDivision.Division]: "Division",
    [CategoryDivision.Organization]: "Organization",
    [CategoryDivision.TeamGroup]: "Team Group",
    [CategoryDivision.VDir]: "VDir",
    [CategoryDivision.Process]: "Process",
    [CategoryDivision.ScenarioTag]: "Scenario Tag",
    [CategoryDivision.GriffinApp]: "Griffin App",
    [CategoryDivision.PredefinedViewName]: "Predefined View Name",
    [CategoryDivision.Subscription]: "Subscription",
    [CategoryDivision.Cluster]: "Cluster",
    [CategoryDivision.GriffinProcessor]: "Griffin Processor",
    [CategoryDivision.StoreClient]: "Store Client",
    [CategoryDivision.StoreClientComponent]: "Store Client Component",
    [CategoryDivision.SSDDataSetName]: "SSD Dataset Name",
    [CategoryDivision.GriffinAppV2]: "Griffin App",
    [CategoryDivision.GriffinProcessorV2]: "Griffin Processor",
    [CategoryDivision.StoreClientComponentV2]: "Store Client Component",
    [CategoryDivision.StoreClientV2]: "Store Client",
    [CategoryDivision.ScenarioTagV2]: "Scenario Tag",
    [CategoryDivision.VdirV2]: "Vdir",
    [CategoryDivision.ProcessV2]: "Process",
    [CategoryDivision.DataSetV2]: "DataSet",
    [CategoryDivision.PlatformProcess]: "Process",
    [CategoryDivision.PlatformApp]: "App",
    [CategoryDivision.PlatformSubApp]: "Sub App",
    [CategoryDivision.PlatformClient]: "Client",
    [CategoryDivision.PlatformClientComponent]: "Client Component",
    [CategoryDivision.Category]: "Category",
    [CategoryDivision.Workload]: "Workload",
    [CategoryDivision.Confidence]: "Confidence",
    [CategoryDivision.ExecutionStatus]: "Execution status"
};

export type ServiceTreeCategory = CategoryDivision.Service | CategoryDivision.ServiceGroup | CategoryDivision.Division | CategoryDivision.Organization | CategoryDivision.TeamGroup;

export type TagCategory = CategoryDivision.Owner | CategoryDivision.VDir | CategoryDivision.Process | CategoryDivision.ScenarioTag | CategoryDivision.GriffinApp | CategoryDivision.GriffinProcessor | CategoryDivision.StoreClient | CategoryDivision.StoreClientComponent | CategoryDivision.SSDDataSetName |
    CategoryDivision.GriffinAppV2 |
    CategoryDivision.GriffinProcessorV2 |
    CategoryDivision.StoreClientComponentV2 |
    CategoryDivision.StoreClientV2 |
    CategoryDivision.ScenarioTagV2 |
    CategoryDivision.VdirV2 |
    CategoryDivision.ProcessV2 |
    CategoryDivision.DataSetV2;

export type AzureCategory = CategoryDivision.Subscription | CategoryDivision.Cluster;

export const ServiceTreeCategories: Set<CategoryDivision> = new Set([CategoryDivision.Service, CategoryDivision.ServiceGroup, CategoryDivision.Division, CategoryDivision.Organization, CategoryDivision.TeamGroup]);

export function getCategoryString(category: CategoryDivision): string {
    switch (category) {
        case CategoryDivision.Owner:
            return "Owner";
        case CategoryDivision.BusinessOwner:
            return "BusinessOwner";
        case CategoryDivision.Service:
            return "Service";
        case CategoryDivision.ServiceGroup:
            return "Service Group";
        case CategoryDivision.Division:
            return "Division";
        case CategoryDivision.Organization:
            return "Organization";
        case CategoryDivision.TeamGroup:
            return "Team Group";
        case CategoryDivision.VDir:
            return "VDir";
        case CategoryDivision.Process:
            return "Process";
        case CategoryDivision.ScenarioTag:
            return "ScenarioTag";
        case CategoryDivision.GriffinApp:
            return "GriffinApp";
        case CategoryDivision.GriffinProcessor:
            return "GriffinProcessor";
        case CategoryDivision.PredefinedViewName:
            return "PredefinedViewName";
        case CategoryDivision.Subscription:
            return "Subscription";
        case CategoryDivision.Cluster:
            return "Cluster Id";
        case CategoryDivision.StoreClient:
            return "StoreClient";
        case CategoryDivision.StoreClientComponent:
            return "StoreClientComponent";
        case CategoryDivision.SSDDataSetName:
            return "SSDDataSetName";

        /** v2 */
        case CategoryDivision.GriffinAppV2:
            return "GriffinApp";
        case CategoryDivision.GriffinProcessorV2:
            return "GriffinProcessor";
        case CategoryDivision.StoreClientComponentV2:
            return "StoreClientComponent";
        case CategoryDivision.StoreClientV2:
            return "StoreClient";
        case CategoryDivision.ScenarioTagV2:
            return "ScenarioTag";
        case CategoryDivision.VdirV2:
            return "Vdir";
        case CategoryDivision.ProcessV2:
            return "Process";
        case CategoryDivision.DataSetV2:
            return "DataSet";

        /** PCM Platform */
        case CategoryDivision.PlatformProcess:
            return "Process";
        case CategoryDivision.PlatformApp:
            return "App";
        case CategoryDivision.PlatformSubApp:
            return "Sub App";
        case CategoryDivision.PlatformClient:
            return "Client";
        case CategoryDivision.PlatformClientComponent:
            return "Client Component";

        /** Efficiency Tracker Big Bets search */
        case CategoryDivision.Category:
            return "Category";
        case CategoryDivision.Workload:
            return "Workload";
        case CategoryDivision.Confidence:
            return "Confidence";
        case CategoryDivision.ExecutionStatus:
            return "Execution Status";
    }
}