// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".H9ZVmCPn3TdtUTwne_a9 {\n  color: #0078D4;\n  align-self: center;\n  width: 16px;\n  height: 16px;\n}\n.eFIFGs2KKaM6moHq3jWU {\n  width: 343px;\n  max-width: 90%;\n  padding: 20px 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/TreeSelect.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACJ;AAEA;EACI,YAAA;EACA,cAAA;EACA,kBAAA;AAAJ","sourcesContent":[".calloutIcon {\n    color: #0078D4;\n    align-self: center;\n    width: 16px;\n    height:16px;\n}\n\n.callout {\n    width: 343px;\n    max-width: 90%;\n    padding: 20px 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calloutIcon": "H9ZVmCPn3TdtUTwne_a9",
	"callout": "eFIFGs2KKaM6moHq3jWU"
};
export default ___CSS_LOADER_EXPORT___;
