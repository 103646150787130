/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable prefer-const */

import * as React from "react";

import ChartContainer from "../ChartContainer/ChartContainer";
import Highcharts from "highcharts";
import { ISubstrateTopComponents } from "../../../models/SubstrateModels";
import { ServiceTreeItem } from "../../../models/serviceTree";
import { currencyFormatter } from "../../../utils/currency";
import moment from "moment";
import { useId } from "@fluentui/react-hooks";
import { getAnomaliesByDate, getAnomalyDescription, getAnomalySeries, getAnomalyLegendPlaceholderSeries } from "../../../utils/AnomalyDetectionUtils";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { renderToString } from "react-dom/server";
import CostCardTooltipContent from "../../common/costCard/CostCardTooltipContent";
import chartStyles from '../../common/Chart.less';
import CommonConstants from "../../../models/constants/CommonConstants";
import { getDateSeriesByRange, isWeekend } from "../../../utils/DateUtils";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useTrackHovering } from "../../../hooks/useTrack";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import HighchartsReact from "highcharts-react-official";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { onChartSeriesLegendClick } from "../../../utils/chartOptions";

interface ISubstrateTopComponentsProps {
    data?: ISubstrateTopComponents;
    serviceIndexMap?: Map<string, ServiceTreeItem>;
    useServiceNameDirectly: boolean;
}

const TopFiveComponentsChart: React.FC<ISubstrateTopComponentsProps> = (props) => {
    const chartSeriesId = useId("substrate-rings-chart");
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.Substrate);
    const dateRange = useDateRange();
    const anomalies = anomaliesQuery.data?.filter(item => item.status != 'Resolved' && !item.affectedMetrics.includes("SubstrateCarbonEmissions")) || [];

    const services: string[] = [];

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    for (let ring in props.data!) {
        services.push(ring);
    }

    const categories: moment.Moment[] = getDateSeriesByRange(dateRange.startDate, dateRange.endDate)

    const trackChartHoveringProps = useTrackHovering(LogComponent.Substrate, LogElement.SubstrateTopFive, "Top Five Trends", LogTarget.Chart);

    const options: Highcharts.Options = React.useMemo<Highcharts.Options>(() => {
        const series: Highcharts.Options['series'] = [];

        let index = 0;

        for (let service of services) {
            const serviceDataPoints: [moment.Moment, number?][] = [];

            categories.forEach((curDate) => {
                const serviceData = props.data![service] || [];
                const dailyData = serviceData.find(data => curDate.isSame(data.extractionDate, 'date'));

                if (!dailyData || isWeekend(curDate)) {
                    serviceDataPoints.push([curDate, undefined]);
                } else {
                    serviceDataPoints.push([curDate, dailyData.substrateCost])
                }
            });

            if (serviceDataPoints.findIndex(([, val]) => val != undefined) < 0) {
                continue;
            }

            series.push(...getAnomalySeries(anomalies, serviceDataPoints, {
                name : props.useServiceNameDirectly ? service : (props.serviceIndexMap ? props.serviceIndexMap.get(service)?.n || "unknown" : service),
                color: CommonConstants.DefaultColors[index++],
            }));
        }

        if (anomalies.length) {
            series.push(getAnomalyLegendPlaceholderSeries());
        }

        return {
            chart: {
                style: {
                    fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
                    fontSize: "10px",
                    fontWeight: "semibold"
                },
            },
            title: {
                text: undefined
            },
            credits: {
                enabled: false
            },
            tooltip: {
                shape: "square",
                borderWidth: 0,
                borderRadius: 0,
                backgroundColor: "white",
                useHTML: true,
                shared: true,
                outside: true,
                followPointer: true,
                formatter: function() {
                    const abnormalEvents = getAnomaliesByDate(anomalies, moment(this.x));
                    const visiblePoints = this.points?.filter(point => !point.series.name.startsWith('hidden')) || [];

                    return renderToString(
                        <CostCardTooltipContent
                            items={visiblePoints.map(point => ({ title: point.series.name, cost: point.y || 0, color: (point.series as any).color as string }))}
                            date={[moment(this.x)]}
                            errors={abnormalEvents.map(event => ({
                                title: event.category,
                                message: getAnomalyDescription(event.category, event.affectedMetrics, event.startDate, event.endDate),
                            }))}
                            errorCollapseLimit={0}
                        />);
                }
            },
            plotOptions: {
                series: {
                    events: {
                        legendItemClick: onChartSeriesLegendClick
                    }
                }
            },
            legend: {
                align: "left",
                squareSymbol: true,
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 0,
                className: anomalies.length > 0 ? chartStyles.abnormalChartLegend : undefined,
            },
            xAxis: {
                categories: categories.map(item => item.format(CommonConstants.DateFormatterString)),
                minTickInterval: categories.length / 5,
                labels: {
                    formatter: function() {
                        return this.value.toString().substring(0, 5);
                    }
                },
            },
            yAxis: {
                title: {
                    text: undefined
                },
                plotLines:[
                ],
                labels: {
                    formatter: function() {
                        if (typeof(this.value) === "string") {
                            return this.value;
                        }
                        return currencyFormatter(this.value);
                    }
                }
            },
            series,
        };
    }, [props.data, chartSeriesId, props.serviceIndexMap, anomaliesQuery.data]);

    return (
        <ChartContainer title={`Top 5 ${props.useServiceNameDirectly ? "GriffinApps/Processes/Processors/Vdirs" : "Services"} Cost Trends`}>
            <HighchartsReact highcharts={Highcharts} options={options} containerProps={trackChartHoveringProps} />
        </ChartContainer>
    )
}

export default TopFiveComponentsChart;