import styles from './ExpandTableItem.less';

import * as React from 'react';
import { useState, useRef, useEffect } from 'react';

export interface IExpandTableItem {
    Process: string | undefined,
    RestApp: string | undefined,
    Shard: string | undefined,
    Forest: string | undefined,
    Dag: string | undefined,
    DagConfiguration: string | undefined,
    Sku: string | undefined,
    SerivceId: string | undefined,
    SubscriptionId: string | undefined,
}

const ExpandTableItem: React.FC<IExpandTableItem> = (props) => {
    const [expanded, setExpanded] = useState(false);
    const [showExpandButton, setShowExpandButton] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentRef.current) {
            const contentHeight = contentRef.current.scrollHeight;
            const isContentLong = contentHeight > 2 * 16; // Assuming 16px per line
            setShowExpandButton(isContentLong);
        }
    }, [props.Process, props.RestApp, props.Shard, props.Forest, props.Dag, props.DagConfiguration, props.Sku]);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <div style={{ position: 'relative' }}>
            {showExpandButton && (
                <div
                    onClick={toggleExpanded}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: '100px',
                        cursor: 'pointer',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                        borderRadius: '5px'
                    }}
                >
                    {expanded ? 'Collapse' : 'Expand'}
                    <span>{expanded ? '▲' : '▼'}</span>
                </div>
            )}
            <div ref={contentRef} className={expanded ? styles.expandedContent : styles.collapsedContent}>
                {props.Process && (
                    <>
                        <div><strong>Process:</strong></div>
                        <span style={{ whiteSpace: 'pre-wrap' }}>{props.Process}</span>
                    </>
                )}
                {props.RestApp && (
                    <>
                        <div><strong>RestApp:</strong></div>
                        <span style={{ whiteSpace: 'pre-wrap' }}>{props.RestApp}</span>
                    </>
                )}
                {props.Shard && (
                    <>
                        <div><strong>Shard:</strong></div>
                        <span style={{ whiteSpace: 'pre-wrap' }}>{props.Shard}</span>
                    </>
                )}
                {props.Forest && (
                    <>
                        <div><strong>Forest:</strong></div>
                        <span style={{ whiteSpace: 'pre-wrap' }}>{props.Forest}</span>
                    </>
                )}
                {props.Dag && (
                    <>
                        <div><strong>Dag:</strong></div>
                        <span style={{ whiteSpace: 'pre-wrap' }}>{props.Dag}</span>
                    </>
                )}
                {props.DagConfiguration && (
                    <>
                        <div><strong>DagConfiguration:</strong></div>
                        <span style={{ whiteSpace: 'pre-wrap' }}>{props.DagConfiguration}</span>
                    </>
                )}
                {props.Sku && (
                    <>
                        <div><strong>Sku:</strong></div>
                        <span style={{ whiteSpace: 'pre-wrap' }}>{props.Sku}</span>
                    </>
                )}
                {props.SerivceId && (
                    <>
                        <div><strong>SerivceId:</strong></div>
                        <span style={{ whiteSpace: 'pre-wrap' }}>{props.SerivceId}</span>
                    </>
                )}
                {props.SubscriptionId && (
                    <>
                        <div><strong>SubscriptionId:</strong></div>
                        <span style={{ whiteSpace: 'pre-wrap' }}>{props.SubscriptionId}</span>
                    </>
                )}
            </div>
        </div>
    );
};

export default ExpandTableItem;
