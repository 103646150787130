import commonStyles from './PartnerServiceDetailTable.less';

export const CosmicServiceDetailsTableStyles = {
    container: commonStyles.tableContainer,
    bodyContainer: commonStyles.tableSectionContainer,
    headerContainer: commonStyles.tableSectionContainer,
    footerContainer: commonStyles.tableSectionContainer,
    stickyFooterContainer: commonStyles.stickyFooterContainer,
};

export const CosmicServiceDetailsTableDefaultPageSize = 50;

export const CosmicServiceDetailsTableNumericalColumns = new Set<string>(["ContainerCount", "CpuUsageCores", "CpuRequestCores", "CpuLimitCores", "CpuUtilizationP99", "MemoryWorkingSetMB", "MemoryRequestMB", "MemoryLimitMB", "MemoryUtilizationP99", "Rightsizing"]);

export const CosmicServiceDetailsTableOffset = { header: { offsetTop: 0 }, footer: { offsetBottom: 0 } };

export const CosmicServiceDetailTableDisplayNames: Record<string, string> = {
    Date: 'Date',
    Workload: 'Workload',
    TeamGroupName: 'Team Group Name',
    ServiceGroupName: 'Service Group Name',
    ServiceName: 'Service Name',
    ServiceId: 'Service ID',
    Namespace: 'Namespace',
    Region: 'Region',
    Ring: 'Ring',
    NodeOS: 'Node OS',
    ContainerCount: 'Container Count',
    CpuUsageCores: 'CPU Usage Cores',
    CpuRequestCores: 'CPU Request Cores',
    CpuLimitCores: 'CPU Limit Cores',
    CpuUtilizationP99: 'CPU Utilization P99',
    MemoryWorkingSetMB: 'Memory Working Set MB',
    MemoryRequestMB: 'Memory Request MB',
    MemoryLimitMB: 'Memory Limit MB',
    MemoryUtilizationP99: 'Memory Utilization P99',
    Rightsizing: 'Rightsizing'
}