import {
    Combobox,
    Option,
    useId,
} from "@fluentui/react-components";

import type { ComboboxProps } from "@fluentui/react-components";
import React from 'react';
import styles from './CosmicView.less';
import { useCosmicFilters } from '../../hooks/useCosmicFilters';
import { useGetCosmicServiceDimensionMetric } from '../../hooks/useCosmicQuery';

interface CosmicComboBoxProps {
    label: string;
    filterKey: string;
}

const CosmicComboBox: React.FC<CosmicComboBoxProps> = ({ label, filterKey }) => {
    const { selectedKeys, cosmicSearchFiltersWithoutCurrentSelectedKeys, setSelectedKeys } = useCosmicFilters(filterKey);

    const { cosmicServiceDimension, isLoading } = useGetCosmicServiceDimensionMetric(label, cosmicSearchFiltersWithoutCurrentSelectedKeys || "");
    const optionsData = ["Select All", ...cosmicServiceDimension];

    const [matchingOptions, setMatchingOptions] = React.useState([...optionsData]);
    const [customSearch, setCustomSearch] = React.useState<string | undefined>();
    const [placeholder, setPlaceholder] = React.useState(selectedKeys.length ? selectedKeys.sort().join(', ') : `Type to filter ${label}`);
    const [comboSelectedOptions, setComboSelectedOptions] = React.useState<string[]>(selectedKeys);

    const onChange: ComboboxProps["onChange"] = (event) => {
        const value = event.target.value.trim();
        const matches = optionsData.filter(
            (option) => option.toLowerCase().indexOf(value.toLowerCase()) === 0
        );
        setMatchingOptions(matches);
        if (value.length && matches.length > 1) {
            setCustomSearch(value);
        } else {
            setCustomSearch(undefined);
        }
    };

    const onOptionSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
        if (!data.selectedOptions) {
            return;
        }

        setComboSelectedOptions(data.selectedOptions);
    };

    const handleOpenChange: ComboboxProps["onOpenChange"] = (e, data) => {
        if (!data.open) {
            if (comboSelectedOptions.includes('Select All')) {
                setPlaceholder(`Type to filter ${label}`);
                setSelectedKeys([]);
                setComboSelectedOptions([]);
                return;
            }

            setMatchingOptions([...optionsData]);
            setCustomSearch(undefined);
            setSelectedKeys(comboSelectedOptions);

            if (comboSelectedOptions.length) {
                setPlaceholder(comboSelectedOptions.sort().join(', '));
            } else {
                setPlaceholder(`Type to filter ${label}`);
            }
        }
        else {
            setMatchingOptions([...optionsData]);
            setCustomSearch(undefined);
        }
    }

    const comboId = useId(`combo-filter-${filterKey}`);

    console.log("selectedKeys:", selectedKeys);

    return (
        <div>
            <label id={comboId}>{label}</label>
            <Combobox
                aria-labelledby={comboId}
                placeholder={isLoading ? "Loading..." : placeholder}
                onOptionSelect={onOptionSelect}
                onChange={onChange}
                multiselect={true}
                onOpenChange={handleOpenChange}
                defaultValue={''}
                defaultSelectedOptions={selectedKeys}
                freeform
                selectedOptions={comboSelectedOptions}
                disabled={isLoading}
            >
                {customSearch ? (
                    <span key="freeform">
                        Search for {customSearch}
                    </span>
                ) : null}
                {matchingOptions.map((option) => (
                    <Option key={option} >{option}</Option>
                ))}
            </Combobox>
        </div>
    );
};

const CosmicComboBoxes = () => {
    const comboBoxConfigs = [
        { label: "Workload", filterKey: "Workloads" },
        { label: "TeamGroupName", filterKey: "TeamGroupNames" },
        { label: "ServiceGroupName", filterKey: "ServiceGroupNames" },
        { label: "ServiceName", filterKey: "ServiceNames" },
        { label: "Namespace", filterKey: "Namespaces" },
        { label: "Region", filterKey: "Regions" },
        { label: "Ring", filterKey: "Rings" },
        { label: "NodeOS", filterKey: "NodeOS" },
    ];

    return (
        <div className={styles.filterContainer}>
            {comboBoxConfigs.map(config => (
                <CosmicComboBox key={config.filterKey} label={config.label} filterKey={config.filterKey} />
            ))}
        </div>
    );
};

export default CosmicComboBoxes;
