import moment from "moment";

export enum ServiceTreeLevel {
    Division = 1,
    Organization = 2,
    ServiceGroup = 3,
    TeamGroup = 4,
    Service = 5,
}

export interface ServiceTreeItem {
    id: string;

    /**
     * Level.
     */
    l: ServiceTreeLevel;

    /**
     * Name
     */
    n: string;

    /**
     * Owner
     */
    o: string;

    /**
     * Children
     */
    c?: ServiceTreeItem[]

    /**
     * Parent
     */
    p?: ServiceTreeItem;

    PmOwner?: string;
}

export interface ServiceTree {
    items: ServiceTreeItem[];
}

export interface ComponentItem {
    name: string;
    serviceName: string;
    serviceGroupName: string;
    teamGroupName: string;
    organizationName: string;
    divisionName: string;
}

export interface ComponentItemResponse {
    n: string;
    sn: string;
    sgn: string;
    tgn: string;
    on: string;
    dn: string;
}

export interface ISubscription {
    subscriptionId: string;
    subscriptionName: string;
}

export interface IServiceIdName {
    serviceId: string;
    serviceName: string;
}

export interface PriceModel {
    version: moment.Moment;
    kvCacheSizeGbPrice: number;
    llcSizeGbPrice: number;
    itemCountPrice: number;
    itemReadsPrice: number;
    itemReadsThroughRestPrice: number;
    itemWritesPrice: number;
    itemWritesThroughRestPrice: number;
    itemQueriesPrice: number;
    itemQueriesThroughRestPrice: number;
    serviceInstanceCountPrice: number;
    serviceInstanceCpuPrice: number;
    serviceInstanceMemoryPrice: number;
    requestsProcessedPrice: number;
    eBACountPrice: number;
    tBACountPrice: number;
    swssCountPrice: number;
    dSAPIRequestsCountPrice: number;
    cFMSubmittedCountPrice: number;
    itemSizePrice: number;
    logBytesPrice: number;
}

export interface ServiceTreeBusinessOwners {
    userAlias: string;
    displayName: string;
    entityLevel: string;
    entityId: string;
}