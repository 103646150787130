// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z2hNXWGZVGDBXj0LA61m {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 24px 0;\n}\n.uFvfprYVZ7aGW6vmZXke {\n  font-weight: 700;\n  font-size: 16px;\n  line-height: 22px;\n  margin-top: 4px;\n}\n.bHp3kbSF1GmuODFT78ub {\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 4px;\n  padding: 0 32px;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/state/EmptyModule.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AACJ;AAEA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;AADJ","sourcesContent":[".emptyModule {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 24px 0;\n}\n\n.emptyModuleTitle {\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 22px;\n    margin-top: 4px;\n}\n\n.emptyModuleSubTitle {\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    margin-top: 4px;\n    padding: 0 32px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyModule": "Z2hNXWGZVGDBXj0LA61m",
	"emptyModuleTitle": "uFvfprYVZ7aGW6vmZXke",
	"emptyModuleSubTitle": "bHp3kbSF1GmuODFT78ub"
};
export default ___CSS_LOADER_EXPORT___;
