import { EventType, LogComponent, LogElement, LogTarget } from "../../models/LogModel";
import { EventTypeEnum, OpenNotificationPanelEvent } from "../../models/Event";
import { IIconProps, IconButton } from "@fluentui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { getActiveAnomalies, getAnomalyDescription } from "../../utils/AnomalyDetectionUtils";
import { PartnerMetricNames as metricNames, PartnerMetrics as metrics } from "../../models/constants/CosmicConstants";

import CommonConstants from "../../models/constants/CommonConstants";
import CostCard from "../common/costCard/CostCard";
import { DataSourceEnum } from "../../models/NotificationModels";
import FeedbackPanel from "../feedback/FeedbackPanel";
import { ICosmicHierarchicalMetricReport } from "../../models/CosmicModels";
import { Section } from "../common/Section";
import html2canvas from "html2canvas";
import styles from "./PartnerMetricsCard.less";
import { trackEventCallback } from "../../utils/AppInsights";
import { useBoolean } from "@fluentui/react-hooks";
import { useDateRange } from "../../hooks/useDateSelector";
import useEventBus from "../../hooks/useEventBus";
import { useFlights } from "../../hooks/useSettings";
import { useGetAnomaliesByDataSourceQuery } from "../../hooks/useNotificationQuery";
import { useGetCosmicWorkloadDailyAppMetric } from "../../hooks/useCosmicQuery";
import { useMsal } from "@azure/msal-react";

const feedbackIconProps: IIconProps = { iconName: "Feedback" };


interface PartnerMetricsCardProps {
    appCoreCogs: ICosmicHierarchicalMetricReport[];
    isLoadingAppCoreCogs: boolean;
}

const PartnerMetricsCard: React.FC<PartnerMetricsCardProps> = ({ appCoreCogs, isLoadingAppCoreCogs }) => {
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.SubstrateV2);
    const eventBus = useEventBus<OpenNotificationPanelEvent>(undefined, undefined, []);

    const { startDate, endDate } = useDateRange();
    const { cosmicHierarchicalMetricReport: appCores, isLoading: isLoadingAppCores } = useGetCosmicWorkloadDailyAppMetric("AppCores", true);

    const getMetricBreakdown = (metricName: string, metrics: typeof appCores) => {
        const filteredMetrics = metrics?.filter(singleMetric => singleMetric.metric === metricName) ?? [];
        return filteredMetrics.reduce((acc, curr) => acc + (curr.total ?? 0), 0);
    };

    const memorizedAppCoreCost = useMemo(() => getMetricBreakdown("AppCoreCogs", appCoreCogs), [appCoreCogs]);
    const memorizedCpuUsageCores = useMemo(() => getMetricBreakdown("CpuUsageCores", appCores), [appCores]);
    const memorizedCpuRequestCores = useMemo(() => getMetricBreakdown("CpuRequestCores", appCores), [appCores]);
    const memorizedCpuLimitCores = useMemo(() => getMetricBreakdown("CpuLimitCores", appCores), [appCores]);
    const memorizedEmptyAppCores = useMemo(() => getMetricBreakdown("EmptyAppCores", appCores), [appCores]);

    const cosmicMetricsData: Record<string, number> = {
        "app-core-cost": memorizedAppCoreCost,
        "daily-p99-utilization": memorizedCpuUsageCores / memorizedCpuRequestCores,
        "daily-app-request-cores": memorizedCpuRequestCores,
        "daily-app-limit-cores": memorizedCpuLimitCores,
        "daily-app-empty-cores": memorizedEmptyAppCores,
    }

    const [cardWidth, setCardWidth] = useState<number | undefined>(undefined);
    const hiddenCardListRef = useRef<HTMLDivElement>(null);

    const handleViewMoreAnormalyEvents = () => {
        eventBus.emit<OpenNotificationPanelEvent>(EventTypeEnum.OpenNotificationPanel, undefined);
    };

    useEffect(() => {
        let maxCardWidth = 0;

        hiddenCardListRef.current?.childNodes.forEach((elem) => {
            maxCardWidth = Math.max(maxCardWidth, (elem as HTMLDivElement).getBoundingClientRect().width);
        });

        setCardWidth(maxCardWidth || undefined);
    }, [isLoadingAppCores, isLoadingAppCoreCogs]);

    // User Feedback
    const { instance } = useMsal();
    const { data: flights } = useFlights();
    const account = instance.getActiveAccount();
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');
    const [currentScreenshotURL, setCurrentScreenshotURL] = useState<string>('');

    const captureScreenshot = async (title: string) => {
        trackEventCallback(LogComponent.CosmicPartnerView, LogElement.CosmicPartnerMetricsCards, "Send Feedback Chart", LogTarget.Button, undefined, EventType.Click);

        const url = window.location.href;
        setCurrentURL(url);

        await openFeedbackPanel();

        const targetElement = document.getElementById(`FeedbackSection_${title}`);

        if (targetElement) {
            setTimeout(() => {
                html2canvas(targetElement).then((canvas) => {
                    const screenshotDataUrl = canvas.toDataURL('image/png');
                    setCurrentScreenshotURL(screenshotDataUrl);
                });
            }, 100);

        }
    };

    const handleDismissFeedbackPanel = () => {
        setCurrentScreenshotURL('');
        dismissFeedbackPanel();
    };

    return (
        <div className={styles.cardContainer} id={`FeedbackSection_CosmicPartnerMetricsCard`} >
            {flights?.enableUserFeedback &&
                <IconButton
                    title="Send feedback"
                    className={styles.feedbackIcon}
                    iconProps={feedbackIconProps}
                    onClick={() => captureScreenshot("CosmicPartnerMetricsCard")}
                />}
            <Section headerProps={{ title: "Basic Metrics" }} styles={{ content: "" }} loading={isLoadingAppCores && isLoadingAppCoreCogs}>
                {/* <div className={styles.title}>
                    <span className={styles.titleTotalCost}>{currencyFormatter(totalCost)}</span> App Core COGS
                </div> */}
                {/** Hidden cards used for calculating card width */}
                <div className={`${styles.cardList} ${styles.hiddenCardList}`} ref={hiddenCardListRef}>
                    {metrics.map((key, index) => (
                        <div key={key}>
                            <CostCard
                                title={metricNames[key] || ""}
                                color={CommonConstants.DefaultColors[index]}
                                cost={(cosmicMetricsData && cosmicMetricsData[key]) || 0}
                            />
                        </div>
                    ))}
                </div>
                <div className={styles.cardList}>
                    {cardWidth !== undefined &&
                        metrics.map((key, index) => (
                            <div key={key} style={{ width: cardWidth }}>
                                <CostCard
                                    classNames={{ costValue: styles.costValue }}
                                    showTooltip
                                    date={key.includes("daily") ? [endDate] : [startDate, endDate]}
                                    title={
                                        key.includes("cost") && isLoadingAppCoreCogs
                                            ? `Loading ${metricNames[key]}...` 
                                            : metricNames[key] || ""
                                    }
                                    className={cosmicMetricsData?.[key] ? styles.activeCard : ""}
                                    color={CommonConstants.DefaultColors[index]}
                                    cost={cosmicMetricsData?.[key] || 0}
                                    noPrefix={!key.includes("cost")}
                                    errors={getActiveAnomalies(anomaliesQuery.data, [key]).map((event) => ({
                                        title: event.category,
                                        message: getAnomalyDescription(event.category, [event.dataSourceName], event.startDate, event.endDate),
                                    }))}
                                    onShowMoreError={handleViewMoreAnormalyEvents}
                                    formatter={key.includes("utilization") ? ((value) => (value * 100).toFixed(2) + "%") : undefined}
                                />
                            </div>
                        ))}
                </div>
            </Section>
            <FeedbackPanel
                isPanelOpen={isFeedbackPanelOpen}
                onDismiss={handleDismissFeedbackPanel}
                userName={account?.username}
                currentURL={currentURL}
                screenshotInputURL={currentScreenshotURL}
                FeedbackTab={LogComponent.CosmicPartnerView}
            />
        </div>
    );
};

export default PartnerMetricsCard;
