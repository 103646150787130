import { Dialog, DialogType, IButtonStyles, IPanel } from "@fluentui/react";
import { FiltersAction, FiltersView } from "../../../reducers/filterReducer";
import { IServiceTreeData, getCategoryByServiceTreeLevel } from "../../../reducers/serviceTreeReducer";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { ServiceTree, ServiceTreeItem } from "../../../models/serviceTree";
import { useDispatch, useSelector } from "react-redux";

import { CategoryDivision } from "../../../models/CategoryDivision";
import { DataNode } from "../../../models/DataNode";
import { IAppState } from "../../../store";
import TreeSelect from "../../TreeSelect";
import { getServiceItemListByKeys } from "../../../utils/FiltersUtils";
import { makeUpdateServiceTreePanelRefAction } from "../../../reducers/actionableItemsReducer";
import styles from "./ShareView.less";
import { useCategoryFilters } from "../../../hooks/useFilters";

interface ShareViewServiceTreeDialog {
    updateStep: Dispatch<SetStateAction<number>>;
    onCancel: (viewType: string) => void;
}

const ShareViewServiceTreeDialog: React.FC<ShareViewServiceTreeDialog> = (props) => {
    const { updateStep, onCancel } = props;
    const [internalSelectedItems, setInternalSelectedItems] = useState<ServiceTreeItem[] | undefined>(undefined);
    const [selectedItemError, setSelectedItemError] = useState<boolean>(false);

    const globalFilters = useCategoryFilters();
    const filtersNextAction = useSelector<IAppState, FiltersAction>((state) => state.filtersNextAction);

    const handleServicesChange = useCallback(
        (items: ServiceTreeItem[]) => {
            if (items.length) {
                const filters: Partial<Record<CategoryDivision, string[]>> = {};
                items.forEach((item) => {
                    const category = getCategoryByServiceTreeLevel(item.l);
                    if (!filters[category]) {
                        filters[category] = [];
                    }
                    filters[category]?.push(item.id);
                });
                globalFilters.updateFilters(filtersNextAction, { filters, view: FiltersView.Summary });

                return filters;
            }
        },
        [filtersNextAction, globalFilters]
    );

    const dispatch = useDispatch();

    const serviceTreePanelRef = React.useRef<IPanel>(null);
    useEffect(() => serviceTreePanelRef.current?.dismiss(), []);
    useEffect(() => {
        dispatch(makeUpdateServiceTreePanelRefAction(serviceTreePanelRef.current));
    }, [dispatch]);
    function createTreeData(serviceTree: ServiceTree | undefined): DataNode[] {
        function getValue(item: ServiceTreeItem) {
            return item.id === "00000000-0000-0000-0000-000000000000" ? `${item.id}(${item.l})` : item.id;
        }

        function createDataNodes(items: ServiceTreeItem[] | undefined): DataNode[] {
            if (!items) {
                return new Array<DataNode>(0);
            }
            const nodes = new Array<DataNode>(items.length);
            for (let i = 0; i < items.length; ++i) {
                const value = getValue(items[i]);
                nodes[i] = { title: items[i].n, key: value, value, data: items[i] };
                const children = items[i].c;
                if (children) {
                    nodes[i].children = createDataNodes(children);
                }
            }
            return nodes;
        }

        return createDataNodes(serviceTree?.items);
    }
    const { serviceTree: data } = useSelector<IAppState, IServiceTreeData>((state) => state.serviceTree);

    const globalSelectedServices = useMemo(() => {
        return getServiceItemListByKeys(globalFilters.filters.filters, data?.items);
    }, [data?.items, globalFilters.filters.filters]);
    const selectedIds = useMemo(() => globalSelectedServices.map((s) => s.id), [globalSelectedServices]);

    const treeData = useMemo(() => createTreeData(data), [data]);
    const [internalSelectedIds, setInternalSelectedIds] = useState<string[]>(selectedIds);

    const handleChange = useCallback((values: string[], nodes: DataNode[]) => {
        if(values && values.length > 0) {
            setSelectedItemError(false)
        }
        setInternalSelectedIds(values);
        setInternalSelectedItems(nodes.map((x) => x.data));
    }, []);

    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: "Create a new custom view",
    };

    const onShareViewCallout = function () {
        if (internalSelectedIds && internalSelectedIds.length > 0) {
            if(internalSelectedItems) {
                handleServicesChange(internalSelectedItems);
            }
            updateStep(3);
        } else {
            setSelectedItemError(true)
        }
    };

    return (
        <Dialog hidden={false} modalProps={{ containerClassName: styles.dialogContainer }} maxWidth={1000} dialogContentProps={dialogContentProps}>
            <div style={{marginBottom:10}}><label className={`${styles.labelWithAfter} ${selectedItemError ? styles.error : ''}`}>Select service tree items.</label></div>
            <TreeSelect treeData={treeData} selectedValues={internalSelectedIds} onChange={handleChange} />
            <button
                className={styles.dialogCancelButton}
                onClick={() => {
                    updateStep(0);
                    onCancel("ServiceTree");
                }}
            >
                Cancel
            </button>
            <button className={styles.dialogButton} onClick={onShareViewCallout}>
                Next
            </button>
        </Dialog>
    );
};

export default ShareViewServiceTreeDialog;
