import { sum } from "lodash";
import moment, { Moment } from "moment";
import { isWeekend } from "./DateUtils";
import { LineChartSeries } from "../components/common/LineChart";
import { INotification } from "../models/NotificationModels";

export function scaleLinear(data: number[], [rangeMin, rangeMax]: [number, number]) {
    const maxVal = Math.max(...data);
    const minVal = Math.min(...data);

    if (maxVal === minVal || rangeMin >= rangeMax) return new Array(data.length).fill(rangeMin);

    const scaled: number[] = [];

    for (let i = 0; i < data.length; ++i) {
        scaled.push(((data[i] - minVal) / (maxVal - minVal)) * (rangeMax - rangeMin) + rangeMin);
    }

    return scaled;
}

export function getAverageValue(seriesData: [number, number?][], ignoreWeekend = true) {
    const validDateCount = seriesData.filter(([date]) => !ignoreWeekend || !isWeekend(moment(date))).length;

    if (!validDateCount) return 0;

    return sum(seriesData.map(([date, value]) => ((ignoreWeekend && isWeekend(moment(date))) ? 0 : value))) / validDateCount;
}

export function getMedian(seriesData: [number, number?][]) {
    if (seriesData.length === 0) return 0;
    const arr = seriesData.map(([date, value]) => value).filter(value => value !== undefined) as number[];
    const mid = Math.floor(arr.length / 2), nums = [...arr].sort((a, b) => a - b);

    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
}

export function groupSeriesDataByWeek(data: [Moment, number | undefined][]): [Moment, number | undefined][] {
    const grouped: [Moment, number | undefined][] = [];

    for (let i = 0; i < data.length; ++i) {
        const [date, value] = data[i];
        const startOfWeek = moment(date).startOf('week');

        const index = grouped.findIndex(([week]) => week.isSame(startOfWeek));

        if (index === -1) {
            grouped.push([startOfWeek, value]);
        } else {
            grouped[index][1] = (grouped[index][1] || 0) + (value || 0);
        }
    }

    return grouped;
}

export function groupAnomaliesByWeek(notifications?: INotification[]): INotification[] {
    if (!notifications) return [];
    const result = notifications.map(notification => ({
        ...notification,
        startDate: moment(notification.startDate).startOf('week'),
        endDate: moment(notification.endDate).startOf('week'),
    }));

    // Merge overlapping anomalies
    for (let i = 0; i < result.length - 1; ++i) {
        const current = result[i];
        const next = result[i + 1];

        if (current.endDate.isSameOrAfter(next.startDate)) {
            current.endDate = next.endDate;
            result.splice(i + 1, 1);
            --i;
        }
    }

    return result;
}

export function trimSingleDays(data: [Moment, number | undefined][]) {
    let startIndex = 0;
    let endIndex = data.length - 1;

    while (startIndex < endIndex && data[startIndex][0].day() !== 0) ++startIndex;
    while (endIndex > startIndex && data[endIndex][0].day() !== 6) --endIndex;
    return data.slice(startIndex, endIndex + 1);
}

export function trimNotifications(data: [Moment, number | undefined][], notification?: INotification[]) {
    const targetRange = [data[0][0], data[data.length - 1][0]];
    return notification?.filter(({ startDate, endDate }) => {
        if (startDate.isAfter(targetRange[1]) || endDate.isBefore(targetRange[0])) return false;
        return true;
    }) || [];
}