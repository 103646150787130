import React from "react";
import { SubstrateCostTableColumnId } from "./SubstrateDetailTableConfig";
import styles from "./SubstrateCostTableTooltip.less";

export interface ISubstrateCostTableTooltipProps {
    id: SubstrateCostTableColumnId;
    descriptions?: (string | undefined)[];
    unitPrices?: string[];
}

export const SubstrateCostTableTooltip: React.FC<ISubstrateCostTableTooltipProps> = (props) => {
    return (
        <div>
            {props.descriptions && (
                <div className={styles.tableHeaderTooltipDescs}>
                    {props.descriptions?.map((d) => (
                        <div key={d}>{d}</div>
                    ))}
                </div>
            )}
            {props.unitPrices && (
                <div className={styles.tableHeaderTooltipUnitPrice}>
                    <strong>Unit Price: </strong>
                    {props.unitPrices.length > 1
                        ? props.unitPrices.map((p) => (
                              <div className={styles.tableHeaderTooltipUnitPriceItem} key={p}>
                                  • {p}
                              </div>
                          ))
                        : props.unitPrices[0]}
                </div>
            )}
        </div>
    );
};
