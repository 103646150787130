import React, { useState } from "react";
import { IIconProps, IconButton } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import { useMsal } from "@azure/msal-react";
import { useFlights } from "../../../../hooks/useSettings";
import { trackEventCallback } from "../../../../utils/AppInsights";
import useEventBus from "../../../../hooks/useEventBus";
import { EventTypeEnum, OpenFeedbackPanelEvent } from "../../../../models/Event";
import styles from "./DetailTable.less"
import { AzureCostTableTag } from "../../../../models/AzureComputeModels";
import FeedbackPanelAzureTable from "../../../feedback/FeedbackPanelAzureTable";
import { EventType, LogComponent, LogElement, LogTarget } from "../../../../models/LogModel";

interface IAzureCostTableFeedbackCellProps<T> {
    type: 'Total' | 'Public' | 'AZSC';
    Ttype: AzureCostTableTag;
    data: T | undefined;
    columns: (keyof T)[];
    defaultHiddenColumns?: string[];
}

const AzureLogComponent: Record<string, LogComponent> = {
    ["Total"]: LogComponent.AzureTabs,
    ["Public"]: LogComponent.PublicAzureTabs,
    ["AZSC"]: LogComponent.AzscTabs,
};

const AzureLogElement: Record<AzureCostTableTag, LogElement> = {
    [AzureCostTableTag.Service]: LogElement.AzureServiceTagCostTable,
    [AzureCostTableTag.Region]: LogElement.AzureRegionTagCostTable,
    [AzureCostTableTag.Subscription]: LogElement.AzureSubscriptionTagCostTable,
};

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

const AzureCostTableFeedbackCell: <T>(props: IAzureCostTableFeedbackCellProps<T>) => React.ReactElement<IAzureCostTableFeedbackCellProps<T>> = (props) => {
    const { instance } = useMsal();
    const { data: flights } = useFlights();
    const account = instance.getActiveAccount();
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');

    const captureScreenshot = async () => {
        trackEventCallback(AzureLogComponent[props.type], AzureLogElement[props.Ttype], "Feedback table", LogTarget.Button, undefined, EventType.Click);
        const url = window.location.href;
        setCurrentURL(url);
        console.log(props);
        await openFeedbackPanel();
    };

    const handleDismissFeedbackPanel = () => {
        dismissFeedbackPanel();
    };

    useEventBus<OpenFeedbackPanelEvent>(EventTypeEnum.OpenFeedbackPanel, openFeedbackPanel, []);

    return (
        <div className={styles.cardContainer} >
            {flights?.enableUserFeedback &&
                <IconButton
                    title="Send feedback"
                    className={styles.feedbackIcon}
                    iconProps={feedbackIconProps}
                    onClick={captureScreenshot}
                />}
            <FeedbackPanelAzureTable
                isPanelOpen={isFeedbackPanelOpen}
                onDismiss={handleDismissFeedbackPanel}
                userName={account?.username}
                currentURL={currentURL}
                FeedbackTab={(props.type === 'Total' ? "Azure" : props.type) + "-" + props.Ttype}
                data={props.data}
                columns={props.columns}
                defaultHiddenColumns={props.defaultHiddenColumns}
            />
        </div>
    );
};

export default AzureCostTableFeedbackCell;