import { ChoiceGroup, Dialog, DialogType, IChoiceGroupOption, IDialogStyleProps, IDialogStyles, IStyleFunctionOrObject } from "@fluentui/react";
import React, { Dispatch, SetStateAction } from "react";

import styles from "./ShareView.less";

interface ShareViewDefaultDialog {
    onViewTypeChange: (viewType: string) => void;
    updateStep: Dispatch<SetStateAction<number>>;
}

const dialogStyles: IStyleFunctionOrObject<IDialogStyleProps, IDialogStyles> = {};

const ShareViewDefaultDialog: React.FC<ShareViewDefaultDialog> = (props) => {
    const { onViewTypeChange, updateStep } = props;
    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: "Create a new custom view",
        styles: {
            innerContent: {height: 500}
        }
    };

    const options: IChoiceGroupOption[] = [
        { key: "ServiceTree", text: "Service Tree" },
        { key: "OrganizationLeader", text: "Organization Leader" },
    ];

    return (
        <Dialog hidden={false} modalProps={{ containerClassName: styles.dialogContainer }} maxWidth={1000} dialogContentProps={dialogContentProps}>
            <ChoiceGroup
                defaultSelectedKey="ServiceTree"
                options={options}
                onChange={_onChange}
                label="Select how you want to build your custom reporting view"
                required={true}
            />
            <button
                className={styles.dialogCancelButton}
                onClick={() => {
                    updateStep(0);
                    onViewTypeChange("ServiceTree");
                }}
            >
                Cancel
            </button>
            <button className={styles.dialogButton} onClick={() => updateStep(2)}>
                Next
            </button>
        </Dialog>
    );

    function _onChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void {
        if (option) {
            onViewTypeChange(option.key);
        }
    }
};

export default ShareViewDefaultDialog;
