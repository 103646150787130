export const HttpMethods = {
    DELETE: 'DELETE',
    GET: 'GET',
    PATCH: 'PATCH',
    POST: 'POST',
    PUT: 'PUT'
  }

  export enum HttpStatusCode {
    NotConnected = 0,
    Continue = 100,
    OK = 200,
    Created = 201,
    Accepted = 202,
    NoContent = 204,
    Moved = 301,
    Redirect = 302,
    RedirectMethod = 303,
    RedirectKeepVerb = 307,
    Unauthorized = 401,
    NotFound = 404,
    RequestTimeout = 408,
    PreconditionFailed = 412,
    SessionTimeout = 440,
    RetryWith = 449,
    AccountIsLocked = 456,
    InternalServerError = 500,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504,
    LastValidStatusCode = 599
  }