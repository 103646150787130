import styles from './BigBetsDetails.less';
import cardStyles from "./Tools/DiscussionCard.less";

import React, { useEffect, useState } from 'react';
import { DefaultButton, IconButton, Label, PrimaryButton, Separator, Spinner, Stack, TextField, TooltipHost } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useImmer } from 'use-immer';
import { useMsal } from '@azure/msal-react';

import { useEFTrackerBigBetsDiscussion } from '../../../../hooks/useEfficiencyTrackerProject';
import { EFTrackerBigBetsDiscussion } from '../../../../models/EfficiencyTrackerBigBets';
import DiscussionCard from './Tools/DiscussionCard';
import { IErrorDict } from '../../../../models/EfficiencyTracker';
import { postMethod } from '../../../../utils/apiServiceBase';
import { EfficiencyTrackerBaseUrl } from '../../../../services/EfficiencyTrackerService';

interface Props {
    ProjectID: number;
}

const BigBetsDetailsDiscussion: React.FC<Props> = (props) => {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    //Get All Discussion
    const { data: historyDiscussion, isLoading: isLoading, refetch, isRefetching } = useEFTrackerBigBetsDiscussion(props.ProjectID);
    const [allDiscussion, setAllDiscussion] = useState<EFTrackerBigBetsDiscussion[]>([]);

    //Set New Discussion
    const [discuss, setDiscuss] = useState<string>("");
    const [errorDict, updateErrorDict] = useImmer<IErrorDict>({});
    const [isSubmitting, { setTrue: submitting, setFalse: submitDone }] = useBoolean(false);

    //// Get ConsumptionData - setActualData & setCurrent
    useEffect(() => {
        if (!historyDiscussion || isLoading || isRefetching || !historyDiscussion.length) return;
        setAllDiscussion(historyDiscussion.sort((itemA, itemB) => itemB.Id - itemA.Id)); //order desc By Id (createTime)
    }, [historyDiscussion, isLoading]);

    const onsubmit = async () => {
        if (!discuss || discuss.length <= 0) {
            errorDict.SubmitError = "Please input the comment!";
            return;
        }

        const discussionTmp: EFTrackerBigBetsDiscussion = {
            Id: 0,
            ProjectID: props.ProjectID,
            CreateTime: new Date(),
            Note: discuss,
            Owner: account?.username || "",
        }
        // Save to backend
        submitting();

        // If edit, update separately, if add, update together.
        const response = await handlePostRequest(`${EfficiencyTrackerBaseUrl}/bigbets/addnote`, discussionTmp);

        if (response) {
            refetch();
        }

        submitDone();
    };

    const handlePostRequest = async (postURL: string, postData: any): Promise<boolean> => {
        try {
            const response = await postMethod(postURL, postData);
            const responseData = await response.text();
            if (response.ok) {
                updateErrorDict((errorDict) => {
                    if ("SubmitError" in errorDict) {
                        delete errorDict.SubmitError;
                    }
                });
                return true;
            } else {
                updateErrorDict((errorDict) => {
                    errorDict.SubmitError = responseData || "New note upload failed! Please try again later";
                });
                return false;
            }
        } catch (error) {
            updateErrorDict((errorDict) => {
                errorDict.SubmitError = 'Error when uploading New request! ' + error;
            });
            return false;
        }
    };

    return (
        <div>
            <Separator styles={{ root: styles.separator }} />
            <div className={styles.title}>
                Discussion
            </div>
            <div className={cardStyles.card}>
                <div className={cardStyles.cardContent}>
                    <TextField
                        className={styles.addNote}
                        onRenderLabel={() => {
                            return (
                                <Stack horizontal verticalAlign="center">
                                    <Label styles={{ root: { paddingRight: 0 } }}>Add comment</Label>
                                    <TooltipHost
                                        content={"Add new comments for this project."}>
                                        <IconButton
                                            iconProps={{ iconName: 'Info' }}
                                            className={styles.ExplainIcon}
                                        />
                                    </TooltipHost>
                                </Stack>
                            );
                        }}
                        placeholder="Enter a comment for this project"
                        multiline rows={4}
                        autoAdjustHeight
                        value={discuss}
                        onChange={(_, newValue) => {
                            setDiscuss(newValue ? newValue : "");
                        }}
                        onFocus={() => {
                            updateErrorDict(errorDict => {
                                if ("SubmitError" in errorDict) {
                                    delete errorDict.SubmitError;
                                }
                            });
                        }}
                        description={`${discuss.length} characters`} 
                        errorMessage={("SubmitError" in errorDict) ? errorDict.SubmitError : ""}
                    />
                    <Stack horizontal tokens={{ childrenGap: 16 }} className={styles.addNote}>
                        <PrimaryButton
                            text={`Add note`}
                            onClick={onsubmit}
                            disabled={discuss.length === 0 || isSubmitting}
                        />
                        <DefaultButton
                            text="Clear"
                            styles={{ root: { marginLeft: 'auto' } }}
                            onClick={() => {
                                setDiscuss("");
                                updateErrorDict(errorDict => {
                                    if ("SubmitError" in errorDict) {
                                        delete errorDict.SubmitError;
                                    }
                                });
                            }}
                        />
                        {isSubmitting && (
                            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '6px', paddingTop: '12px' }}>
                                <Spinner label="Uploading..." labelPosition="right" />
                            </div> 
                        )}
                    </Stack>
                </div>
            </div>
            <div key={"allDiscussion_" + props.ProjectID}>
                {isLoading ? (
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '6px', paddingTop: '12px' }}>
                        <Spinner label="Loading..." labelPosition="right" />
                    </div>
                ) : (allDiscussion.length === 0) ? (
                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '6px', paddingTop: '12px' }}>
                            No Disscussion
                        </div>
                    ) : (
                        <div> 
                            {
                                allDiscussion.map((discuss) => (
                                    <div key={"discuss_" + discuss.Id}>
                                        <DiscussionCard Owner={discuss.Owner} Discuss={discuss.Note} CreateTime={discuss.CreateTime} />
                                    </div>
                                ))
                            }
                        </div>
                )}
            </div>
        </div>
    );
};

export default BigBetsDetailsDiscussion;
