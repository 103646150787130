import { Pages, SubPages } from "../../../models/Nav";
import { Pivot, PivotItem, Stack } from "@fluentui/react";

import { EmpytCostView } from "../common/EmptyCostView";
import PageHeader from "../../common/PageHeader";
import { PcmV2SceneTypeEnum } from "../../../models/PcmV2Models";
import React, { useState } from "react";
import { SubstrateV2WikiLink } from "../../../models/constants/PcmV2Constants";
import { TransactionByScenarioTag } from "./TransactionByScenarioTag";
import { TransactionByService } from "./TransactionByService";
import { WarningBanner } from "../../banner/WarningBanner";
import commonStyles from "../Substrate.less";
import styles from "./SubstrateTransaction.less";
import { useGetBannersByTabAndDateQuery } from "../../../hooks/useBannerQuery";
import { anomalyResult, useGetIOInsightsByServiceId, useGetPcmV2Cost } from "../../../hooks/useSubstrateV2Query";
import { useGotoTab } from "../../../hooks/useGotoPage";
import { useParams } from "react-router-dom";
import { trackEventCallback } from "../../../utils/AppInsights";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { InsightsCard, getWorkLoadNames } from "../storeIOAnomaly/InsightsCard";
import InsightsDetails from "../storeIOAnomaly/InsightsDetails";
import { useBoolean } from "@fluentui/react-hooks";
import { EventTypeEnum, OpenInsightsDetailsEvent } from "../../../models/Event";
import useEventBus from "../../../hooks/useEventBus";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store";
import { useGetAppIdNameMap } from "../../../hooks/useSearchMetadataQuery";
import { ServiceTreeItem } from "../../../models/serviceTree";
import { useIOInsightId } from "../../../hooks/useIOInsightId";

export const SubstrateTransaction: React.FC = () => {
    const { data: banners } = useGetBannersByTabAndDateQuery(PcmV2SceneTypeEnum.Transaction);
    const { data: pcmV2Cost } = useGetPcmV2Cost();
    const curTab = useParams().tab || "Service";

    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    const appIdNameMap = useGetAppIdNameMap();

    const gotoTab = useGotoTab();
    const handleLinkClick = (item?: PivotItem) => {
        if (item?.props.itemKey) {
            gotoTab(`${Pages.SubstrateV2}/${SubPages.Transaction}`, item?.props.itemKey, `${Pages.SubstrateV2}/${SubPages.Transaction}`);
            trackEventCallback(LogComponent.TransChartingPane, item.props.itemKey === "Service" ? LogElement.ServiceTrans : LogElement.ScenarioTagTrans, item.props.itemKey, LogTarget.Tab);
        }
    };

    const anomalies = useGetIOInsightsByServiceId(PcmV2SceneTypeEnum.Transaction);

    const [insightId, setInsightId] = useIOInsightId();
    const [serviceId, setServiceId] = React.useState<string>("");
    const [workloadName, setWorkloadName] = React.useState<string>("");
    const [workloadType, setWorkloadType] = React.useState<string>("");
    const [itemReadTotalCost, setReadCost] = React.useState<number>(0);
    const [itemWriteTotalCost, setWriteCost] = React.useState<number>(0);
    const [itemQueryTotalCost, setQueryCost] = React.useState<number>(0);

    const [isInsightsDetailsOpen, { setTrue: openInsightsDetails, setFalse: dismissInsightsDetails }] = useBoolean(false);
    const onClickInsights = React.useCallback((insightId: string, serviceId: string, workloadName: string, workloadType: string, itemReadTotalCost: number, itemWriteTotalCost: number, itemQueryTotalCost: number) => {
        setInsightId(insightId);
        setServiceId(serviceId);
        setWorkloadName(workloadName);
        setWorkloadType(workloadType);
        setReadCost(itemReadTotalCost);
        setWriteCost(itemWriteTotalCost);
        setQueryCost(itemQueryTotalCost);
        openInsightsDetails();
        trackEventCallback(LogComponent.IOInsights, LogElement.ViewInsight, "View Insight " + insightId, LogTarget.Button);
    }, [setInsightId, setServiceId, setWorkloadName, setWorkloadType, setReadCost, setWriteCost, setQueryCost, openInsightsDetails]);

    useEventBus<OpenInsightsDetailsEvent>(EventTypeEnum.OpenInsightsDetails, openInsightsDetails, []);

    const handleDismissInsightsDetails = () => {
        dismissInsightsDetails();
        setInsightId("");
    }; 

    const insights: JSX.Element[] = React.useMemo(() => {
        if (insightId && anomalies?.some((item) => item.externalId === insightId)) {
            const insight = anomalies.find((item) => item.externalId == insightId);
            if (insight && (!isInsightsDetailsOpen || insight.workloadName != workloadName)) {            
                onClickInsights(insight.externalId, insight.serviceId, insight.workloadName, insight.workloadType, insight.itemReadTotalCost, insight.itemWriteTotalCost, insight.itemQueryTotalCost);
            }
        }
        return [
            (anomalies?.length) ? (
                <>
                    {anomalies.map((item) => (
                        <InsightsCard key={item.externalId} workloadName={item.workloadName} workloadType = {item.workloadType} serviceName={item.serviceId} startDate={item.startDate} endDate={item.endDate} itemReadTotalCost={item.itemReadTotalCost} itemWriteTotalCost={item.itemWriteTotalCost} itemQueryTotalCost={item.itemQueryTotalCost} effectedPCMMetrics={item.effectedPCMMetrics} ring={item.ring} onClick={function (): void {
                            onClickInsights(item.externalId, item.serviceId, item.workloadName, item.workloadType, item.itemReadTotalCost, item.itemWriteTotalCost, item.itemQueryTotalCost);
                        } } />
                    ))}
                </>
            ) : <></>,
            (insightId && anomalies?.some((item) => item.externalId === insightId)) ?
                <InsightsDetails isPanelOpen={isInsightsDetailsOpen} onDismiss={function (): void {
                    handleDismissInsightsDetails();
                } } externalId={insightId} serviceName={getWorkLoadNames(serviceId, "Service", serviceIdMap, appIdNameMap) || ""} workloadName={getWorkLoadNames(workloadName, workloadType, serviceIdMap, appIdNameMap) || ""} readCost={itemReadTotalCost} writeCost={itemWriteTotalCost} queryCost={itemQueryTotalCost}></InsightsDetails> : <></>
        ];
    }, [anomalies, insightId, isInsightsDetailsOpen, handleDismissInsightsDetails, onClickInsights]);

    return (
        <div className={commonStyles.pageWrapper}>
            <PageHeader
                className={commonStyles.pageHeader}
                title="Transaction Cost View"
                description="Transaction cost view gives you the cost of Item Read, Item Write, Item Query. At a hardware level, it covers the cost of CPU, IOPS, and Memory by Substrate platform components (Store, REST, CSO, Cafe, AD, etc.) on BareMetal fleet. Transaction cost metrics have Peak/Off-peak rates."
                link={SubstrateV2WikiLink}
                linkText="Learn More"
                tag="PREVIEW"
                linkLogComponent={LogComponent.TransChartingPane}
                linkLogElement={LogElement.LearnMoreTrans}
            />
            {!!banners?.length && (
                <div className={commonStyles.bannerWrapper}>
                    {banners.map((item) => (
                        <WarningBanner key={item.id} bannerItem={item} />
                    ))}
                </div>
            )}
            {pcmV2Cost && pcmV2Cost.transactionCost === 0 ? (
                <EmpytCostView />
            ) : (
                <Pivot selectedKey={curTab} onLinkClick={handleLinkClick} focusZoneProps={{ className: styles.pivotList }} overflowBehavior="menu">
                    <PivotItem headerText="Transaction by Service" itemKey="Service">
                        <TransactionByService insights={insights} />
                    </PivotItem>
                    <PivotItem headerText="Transaction by Scenario Tag" itemKey="ScenarioTag">
                        <TransactionByScenarioTag insights={insights} />
                    </PivotItem>
                </Pivot>
            )}
        </div>
    );
};
