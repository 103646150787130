import React, { useCallback, useMemo, useState } from 'react';
import { DefaultButton, DetailsList, DetailsListLayoutMode, IColumn, MessageBar, Panel, PanelType, PrimaryButton, SelectionMode, Spinner } from '@fluentui/react';
import { useGetAnomaliesQuery } from '../../hooks/useNotificationQuery';
import { INotification, SubstrateDataSourceNames, SubstrateV2DataSourceNames } from '../../models/NotificationModels';
import { buttonStyles } from './commons';
import styles from "./NotificationPanelList.less";
import { useFlights } from '../../hooks/useSettings';

interface INotificationListProps {
    isPanelOpen: boolean;
    onDismiss: () => void;
}

const NotificationPanelList: React.FC<INotificationListProps> = (props) => {
    const [showAll, setShowAll] = useState(false);
    const {data: notifications, isLoading} = useGetAnomaliesQuery(showAll);
    const [{sortColumn, isSortedDescending}, setSort] = React.useState<{sortColumn: string, isSortedDescending: boolean}>({sortColumn: "", isSortedDescending: false});
    const flights = useFlights();

    const sortedData = useMemo(() => {
        const items = (notifications !== undefined ? notifications : [])
            .filter(item => {
                if (!flights.data?.enableSubstrateV1 && SubstrateDataSourceNames.includes(item.dataSourceName)) {
                    return false;
                }

                if (!flights.data?.enableSubstrateV2 && SubstrateV2DataSourceNames.includes(item.dataSourceName)) {
                    return false;
                }

                return true;
            });

        switch (sortColumn) {
            case "impactedDate":
                return isSortedDescending ? items.sort((a, b) => b.startDate.unix() - a.startDate.unix()) : items.sort((a, b) => a.startDate.unix() - b.startDate.unix());
            case "category":
                return isSortedDescending ? items.sort((a, b) => b.category.localeCompare(a.category)) : items.sort((a, b) => a.category.localeCompare(b.category));
            case "affectedMetrics":
                return isSortedDescending ? items.sort((a, b) => b.affectedMetrics[0].localeCompare(a.affectedMetrics[0])) : items.sort((a, b) => a.affectedMetrics[0].localeCompare(b.affectedMetrics[0]));
            case "detectionDate":
                return isSortedDescending ? items.sort((a, b) => b.detectionDate.unix() - a.detectionDate.unix()) : items.sort((a, b) => a.detectionDate.unix() - b.detectionDate.unix());
            default:
                return items;
        }
    }, [isSortedDescending, notifications, sortColumn, flights.data]);

    const columns: IColumn[] = useMemo(() => [
        {
            key: 'impactedDate',
            name: 'Impacted Date',
            minWidth: 128,
            maxWidth: 248,
            isResizable: true,
            isSorted: sortColumn === 'impactedDate',
            isSortedDescending: isSortedDescending,
            onRender: (notification: INotification) => {
                if (notification.startDate.isSame(notification.endDate, 'day')) {
                    return notification.startDate.format("YYYY/MM/DD");
                } else {
                    return notification.startDate.format("YYYY/MM/DD") + "-" + notification.endDate.format("YYYY/MM/DD");
                }
            }
        },
        {
            key: 'category',
            name: 'Category',
            minWidth: 128,
            maxWidth: 200,
            isResizable: true,
            isSorted: sortColumn === 'category',
            isSortedDescending: isSortedDescending,
            onRender: (notification: INotification) => {
                return (
                    <>
                    {notification.category}
                    {
                        notification.status &&
                        <span className={getStatusClassName(notification.status)}>{notification.status}</span>
                    }
                    </>
                )
            }
        },
        {
            key: 'affectedMetrics',
            name: 'Affected Metrics',
            minWidth: 100,
            isResizable: true,
            isSorted: sortColumn === 'affectedMetrics',
            isSortedDescending: isSortedDescending,
            isMultiline: true,
            onRender: (notification: INotification) => {
                return (
                    notification.affectedMetrics.join(", ")
                );
            }
        },
        {
            key: 'detectionDate',
            name: 'Detection Date',
            minWidth: 100,
            maxWidth: 100,
            isSorted: sortColumn === 'detectionDate',
            isSortedDescending: isSortedDescending,
            onRender: (notification: INotification) => {
                return notification.detectionDate.format("YYYY/MM/DD");
            }
        }
    ], [isSortedDescending, sortColumn]);

    const onColumnClick = useCallback((_, column?: IColumn) => {
        if (!column) return;
        const { key, isSorted } = column;

        if (isSorted) {
            setSort({
                sortColumn: key,
                isSortedDescending: !isSortedDescending
            });
        } else {
            setSort({
                sortColumn: key,
                isSortedDescending: false
            });
        }
    }, [isSortedDescending]);

    const onRenderFooterContent = (): JSX.Element => (
        <div>
            <PrimaryButton onClick={props.onDismiss} styles={buttonStyles}>
                Close
            </PrimaryButton>
        </div>
    )

    const onDismiss = useCallback(() => {
        setShowAll(false);
        props.onDismiss();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onDismiss]);

    return (
        <Panel
            type={PanelType.custom}
            customWidth={"1200px"}
            headerText="Notification Center"
            isOpen={props.isPanelOpen}
            onDismiss={onDismiss}
            isBlocking={false}
            closeButtonAriaLabel="Close"
            onRenderFooterContent={onRenderFooterContent}
            isFooterAtBottom={true}
            isLightDismiss
        >
            {
                isLoading ?
                <Spinner label="Loading..." labelPosition="top" /> :
                !!sortedData.length ? 
                <>
                <DetailsList
                    items={sortedData}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    onColumnHeaderClick={onColumnClick}
                />
                {
                    !showAll && 
                    <DefaultButton
                        text="Show all notification"
                        onClick={() => setShowAll(!showAll)}
                        className={styles.showAllButton}
                    />
                }
                </> :
                <MessageBar>
                    No notifications to show
                </MessageBar>
            }
            
        </Panel>
    );
};

function getStatusClassName(status: string): string {
    switch (status) {
        case "Resolving":
            return styles.blueBox;
        case "Resolved":
            return styles.lightGreenBox;
        case "Unrecoverable Loss":
            return styles.redBox;
        default:
            return "";
    }
}

export default NotificationPanelList;