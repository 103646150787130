// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TwoXOBA9UzttHVMgO65Q {\n  max-height: none;\n}\n.e95VJXO6jXkIiV24kIvZ {\n  max-height: 32px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EfficiencyTrackerV2/Components/Tools/ExpandTableItem.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AAEA;EACI,gBAAA;AAAJ","sourcesContent":[".expandedContent {\n    max-height: none;\n}\n\n.collapsedContent {\n    max-height: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandedContent": "TwoXOBA9UzttHVMgO65Q",
	"collapsedContent": "e95VJXO6jXkIiV24kIvZ"
};
export default ___CSS_LOADER_EXPORT___;
