import { CategoryDivision, getCategoryString } from "../../models/CategoryDivision";
import { ISearchHistoryLog, UserPreferenceUtils } from "../../utils/preference/UserPreferenceUtils";
import { Icon, Stack } from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";

import styles from "./SearchHistory.less";
import { useUserPreference } from "../../utils/preference/useUserPreference";

export interface ISearchHistoryProps {
    onClick: (value: string, category?: CategoryDivision) => void;
    onEscape: () => void;
}

export const SearchHistory: React.FC<ISearchHistoryProps> = (props) => {
    const [searchHistoryLogs, , forceUpdate] = useUserPreference("searchHistory");
    const [isActive, setIsActive] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const activeIndexRef = useRef(activeIndex);
    const activeRef = useRef(isActive);

    activeIndexRef.current = activeIndex;
    activeRef.current = isActive;

    const handleClickLogItem = (logItem: ISearchHistoryLog) => {
        props.onClick(logItem.value, logItem.category);
    };

    const handleRemoveLogItem = (event: React.MouseEvent<HTMLElement, MouseEvent>, logItem: ISearchHistoryLog) => {
        event.stopPropagation();
        UserPreferenceUtils.removeHistoryLog(logItem.value, logItem.category);
        forceUpdate();
    };

    useEffect(() => {
        if (!searchHistoryLogs?.length) {
            return;
        }

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key !== "Tab" && event.key !== "Escape" && event.key != "ArrowDown" && event.key !== "ArrowUp" && event.key != "Enter") {
                return;
            }

            event.preventDefault();

            if (event.key === "Tab" && !activeRef.current) {
                setIsActive(true);
                setActiveIndex(0);
            } else if (event.key === "Escape") {
                setIsActive(false);
                props.onEscape();
            } else if (activeRef.current) {
                if (event.key === "ArrowDown") {
                    setActiveIndex((idx) => Math.min(searchHistoryLogs.length - 1, idx + 1));
                } else if (event.key === "ArrowUp") {
                    setActiveIndex((idx) => Math.max(0, idx - 1));
                } else if (event.key === "Enter") {
                    props.onClick(searchHistoryLogs[activeIndexRef.current].value, searchHistoryLogs[activeIndexRef.current].category);
                    setIsActive(false);
                }
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [searchHistoryLogs]);

    if (!searchHistoryLogs) return null;

    return (
        <div className={styles.searchHistory}>
            {searchHistoryLogs.map((item, index) => (
                <Stack
                    horizontal
                    verticalAlign="center"
                    key={`${item.category}:${item.value}`}
                    className={`${styles.logItem} ${isActive && activeIndex === index ? styles.activeLogItem : ""}`}
                    onClick={() => handleClickLogItem(item)}
                >
                    <Stack.Item>
                        <Icon className={styles.logItemIcon} iconName="History" />
                    </Stack.Item>
                    <Stack.Item className={styles.logItemValue}>
                        {item.category ? getCategoryString(item.category) : "Query"}: {item.value}
                    </Stack.Item>
                </Stack>
            ))}
        </div>
    );
};
