import { PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum, SubstrateCostV2 } from "../../../../models/PcmV2Models";
import React, { useState } from "react";
import { trackEventCallback } from "../../../../utils/AppInsights";
import { EventType, LogComponent, LogElement, LogTarget } from "../../../../models/LogModel";
import { IIconProps, IconButton } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import { useFlights } from "../../../../hooks/useSettings";
import { useBoolean } from "@fluentui/react-hooks";
import styles from "./SubstrateCostTableFeedbackCell.less";
import useEventBus from "../../../../hooks/useEventBus";
import { EventTypeEnum, OpenFeedbackPanelEvent } from "../../../../models/Event";
import FeedbackPanelSubustrateTable from "../../../feedback/FeedbackPanelSubstrateTable";
import { ReactTableExpandableData } from "../../../common/table/TableUtils";
import { Cell } from "react-table";
import { ServiceTreeItem } from "../../../../models/serviceTree";

interface ISubstrateCostTableFeedbackCellProps {
    columns: Cell<ReactTableExpandableData<SubstrateCostV2>, any> [];
    cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>;
    scene: PcmV2SceneTypeEnum;
    subScene?: PcmV2SubSceneTypeEnum;
    totalDays: number;
    serviceTreeIdMap: Map<string, ServiceTreeItem>;
    serviceNameGriffinAppCountMap: Map<string, number>;
}

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

const SubstrateCostTableTitle: Record<PcmV2SceneTypeEnum, string> = {
    [PcmV2SceneTypeEnum.Overview]: "Substrate and Carbon Cost",
    [PcmV2SceneTypeEnum.Transaction]: "Substrate Transaction Cost",
    [PcmV2SceneTypeEnum.Network]: "Substrate Network Cost",
    [PcmV2SceneTypeEnum.ProcessHosting]: "Substrate Process Hosting Cost",
    [PcmV2SceneTypeEnum.SSD]: "Substrate SSD Cost",
    [PcmV2SceneTypeEnum.HDD]: "Substrate HDD Cost",
};

const SubstrateCostTableLogComponent: Record<PcmV2SceneTypeEnum, LogComponent> = {
    [PcmV2SceneTypeEnum.Overview]: LogComponent.SubstrateChartingPane,
    [PcmV2SceneTypeEnum.Transaction]: LogComponent.TransChartingPane,
    [PcmV2SceneTypeEnum.Network]: LogComponent.NetworkChartingPane,
    [PcmV2SceneTypeEnum.ProcessHosting]: LogComponent.ProcessHostingChartingPane,
    [PcmV2SceneTypeEnum.SSD]: LogComponent.SSDChartingPane,
    [PcmV2SceneTypeEnum.HDD]: LogComponent.HDDChartingPane
};

const SubstrateCostTableLogElement: Record<PcmV2SubSceneTypeEnum, LogElement> = {
    [PcmV2SubSceneTypeEnum.TransactionCost]: LogElement.TransactionCostTable,
    [PcmV2SubSceneTypeEnum.TransactionScenarioTagCost]: LogElement.TransactionScenarioTagCostTable,
    [PcmV2SubSceneTypeEnum.NetworkCost]: LogElement.NetworkCostTable,
    [PcmV2SubSceneTypeEnum.NetworkScenarioTagCost]: LogElement.NetworkScenarioTagCostTable,
    [PcmV2SubSceneTypeEnum.HDDDatasetCost]: LogElement.HDDDatasetCostTable,
    [PcmV2SubSceneTypeEnum.HDDMailboxCost]: LogElement.HDDMailboxCostTable,
    [PcmV2SubSceneTypeEnum.SSDTotalCost]: LogElement.SSDTotalCostTable,
    [PcmV2SubSceneTypeEnum.SSDDatasetCost]: LogElement.SSDDatasetCostTable,
    [PcmV2SubSceneTypeEnum.SSDMailboxCost]: LogElement.SSDMailboxCostTable,
};

export const SubstrateCostTableFeedbackCell: React.FC<ISubstrateCostTableFeedbackCellProps> = ({ columns, cell, scene, subScene, totalDays, serviceTreeIdMap, serviceNameGriffinAppCountMap }) => {
    const { instance } = useMsal();
    const { data: flights } = useFlights();
    const account = instance.getActiveAccount();
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');

    const captureScreenshot = async () => {
        trackEventCallback(SubstrateCostTableLogComponent[scene],subScene ? SubstrateCostTableLogElement[subScene] : LogElement.SubstrateTotalCostTable, "Feedback table", LogTarget.Button, undefined, EventType.Click);
        const url = window.location.href;
        setCurrentURL(url);

        await openFeedbackPanel();
    };

    const handleDismissFeedbackPanel = () => {
        dismissFeedbackPanel();
    };

    useEventBus<OpenFeedbackPanelEvent>(EventTypeEnum.OpenFeedbackPanel, openFeedbackPanel, []);

    return (
        <div className={styles.cardContainer} >
            {flights?.enableUserFeedback &&
                <IconButton
                    title="Send feedback"
                    className={styles.feedbackIcon}
                    iconProps={feedbackIconProps}
                    onClick={captureScreenshot}
                />}
            <FeedbackPanelSubustrateTable
                isPanelOpen={isFeedbackPanelOpen}
                onDismiss={handleDismissFeedbackPanel}
                userName={account?.username}
                currentURL={currentURL}
                FeedbackTab={scene === PcmV2SceneTypeEnum.Overview && !flights?.enableSubstrateV2Carbon ? "Substrate Cost" : SubstrateCostTableTitle[scene]}
                columns={columns}
                cell={cell}
                scene={scene}
                subScene={subScene}
                totalDays={totalDays}
                serviceTreeIdMap={serviceTreeIdMap}
                serviceNameGriffinAppCountMap={serviceNameGriffinAppCountMap}
            />
        </div>
    );
};
