// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bITzo1Oo_foH8UKlC811 {\n  list-style-type: none;\n  padding-inline-start: 0;\n  margin-top: 11px;\n}\n.gQptSp9BWTpI1zqmEzmn {\n  padding: 17px 17px 0 15px;\n}\n.nAz20Dr8nE8KJ8WjAXEE {\n  font-weight: 500;\n  align-items: center;\n  display: flex;\n  overflow: hidden;\n}\n.G1E6bacbNj3uXaiJqDDx {\n  height: 100%;\n  padding: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/savedViews/SavedViews.less"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,uBAAA;EACA,gBAAA;AACJ;AAEA;EACI,yBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;AADJ;AAIA;EACI,YAAA;EACA,aAAA;AAFJ","sourcesContent":[".list {\n    list-style-type: none;\n    padding-inline-start: 0;\n    margin-top: 11px;\n}\n\n.savedViewHeader {\n    padding: 17px 17px 0 15px;\n}\n\n.titleStyles {\n    font-weight: 500;\n    align-items: center;\n    display: flex;\n    overflow: hidden;\n}\n\n.spinnerStyles {\n    height: 100%;\n    padding: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "bITzo1Oo_foH8UKlC811",
	"savedViewHeader": "gQptSp9BWTpI1zqmEzmn",
	"titleStyles": "nAz20Dr8nE8KJ8WjAXEE",
	"spinnerStyles": "G1E6bacbNj3uXaiJqDDx"
};
export default ___CSS_LOADER_EXPORT___;
