import { EventTypeEnum, ScrollToTableEvent } from "../../../models/Event";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { PcmV2AggeratedLevel, PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum } from "../../../models/PcmV2Models";
import { PcmV2MetricNames, PcmV2SceneMetrics, SubstrateLogComponent } from "../../../models/constants/PcmV2Constants";
import { PointOptionsObject, SeriesBarOptions } from "highcharts";
import React, { useCallback, useMemo } from "react";
import useEventBus, { EventHub } from "../../../hooks/useEventBus";

import ChartContainer from "../../chart/ChartContainer";
import { DefaultButton } from "@fluentui/react";
import { IAppState } from "../../../store";
import { ServiceTreeItem } from "../../../models/serviceTree";
import { SubstrateUtils } from "../utils/SubstrateUtils";
import { currencyFormatter } from "../../../utils/currency";
import styles from "./SubstrateCostRanking.less";
import { trackEventCallback } from "../../../utils/AppInsights";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useGetPcmV2TopCost } from "../../../hooks/useSubstrateV2Query";
import { useSelector } from "react-redux";
import { useTrackHovering } from "../../../hooks/useTrack";

interface ISubstrateCostRanking {
    scene: PcmV2SceneTypeEnum.Overview | PcmV2SceneTypeEnum.Transaction | PcmV2SceneTypeEnum.Network;
    subScene?: PcmV2SubSceneTypeEnum;
}

const SubstrateCostWorkloadRankingTitle: Partial<Record<PcmV2SubSceneTypeEnum, string>> = {
    [PcmV2SubSceneTypeEnum.TransactionCost]: "Griffin App/Store Client Cost Ranking (Show up to Top10)",
    [PcmV2SubSceneTypeEnum.TransactionScenarioTagCost]: "Griffin App - Scenario Tags Cost Ranking (Show up to TOP10)",
    [PcmV2SubSceneTypeEnum.NetworkCost]: "Griffin App/Store Client Cost Ranking (Show up to Top10)",
    [PcmV2SubSceneTypeEnum.NetworkScenarioTagCost]: "Griffin App - Scenario Tags Cost Ranking (Show up to TOP10)",
};

const SubstrateCostWorkloadRankingInstrumentType: Partial<Record<PcmV2SubSceneTypeEnum, LogComponent>> = {
    [PcmV2SubSceneTypeEnum.TransactionCost]: LogComponent.TransChartingPane,
    [PcmV2SubSceneTypeEnum.TransactionScenarioTagCost]: LogComponent.TransChartingPane,
    [PcmV2SubSceneTypeEnum.NetworkCost]: LogComponent.NetworkChartingPane,
    [PcmV2SubSceneTypeEnum.NetworkScenarioTagCost]: LogComponent.NetworkChartingPane
};

const SubstrateCostWorkloadRankingInstrumentTypeName: Partial<Record<PcmV2SubSceneTypeEnum, string>> = {
    [PcmV2SubSceneTypeEnum.TransactionCost]: "Total Transaction Cost Ranking",
    [PcmV2SubSceneTypeEnum.TransactionScenarioTagCost]: "Griffin App - Scenario Tags Cost Ranking (Show up to TOP10)",
    [PcmV2SubSceneTypeEnum.NetworkCost]: "Total Networking Cost Ranking",
    [PcmV2SubSceneTypeEnum.NetworkScenarioTagCost]: "Networking - Griffin App - Scenario Tags Cost Ranking (Show up to TOP10)"
};

const SubstrateCostServiceRankingTitle: Record<string, string> = {
    [PcmV2SceneTypeEnum.Transaction]: "TOP 10 Services of Total Transaction Cost Ranking",
    [PcmV2SceneTypeEnum.Network]: "TOP 10 Services of Total Network Cost Ranking",
}

export const SubstrateCostRanking: React.FC<ISubstrateCostRanking> = (props) => {
    const eventBus = useEventBus();

    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);
    const categoryFilters = useCategoryFilters();
    const dates = useDateRange();
    const aggeratedLevel = SubstrateUtils.getAggeratedLevelByCategoryFilters(categoryFilters.filters);
    const query = useGetPcmV2TopCost(props.scene, props.subScene, aggeratedLevel);
    const trackingProps = useTrackHovering(SubstrateCostWorkloadRankingInstrumentType[props.subScene!], LogElement.Top10, SubstrateCostWorkloadRankingInstrumentTypeName[props.subScene!], LogTarget.Chart);

    const chartTitle = useMemo(() => {
        if (aggeratedLevel === PcmV2AggeratedLevel.Service) {
            return SubstrateCostServiceRankingTitle[props.scene];
        } else if (props.subScene && aggeratedLevel === PcmV2AggeratedLevel.Workload) {
            return SubstrateCostWorkloadRankingTitle[props.subScene] || "";
        }

        return "";
    }, [aggeratedLevel, props.scene]);

    const chartCategories = useMemo<string[]>(() => {
        if (!query.data) {
            return [];
        }

        if (aggeratedLevel === PcmV2AggeratedLevel.Service) {
            return query.data.map(({ identity }) => (identity?.identity && serviceIdMap.get(identity.identity)?.n) || identity?.identity || "");
        } else if (aggeratedLevel === PcmV2AggeratedLevel.Workload) {
            return query.data.map(({ identity }) => {
                if (props.subScene === PcmV2SubSceneTypeEnum.TransactionScenarioTagCost || props.subScene === PcmV2SubSceneTypeEnum.NetworkScenarioTagCost) {
                    return identity?.workload?.substring(identity.workload.indexOf(".") + 1) || "";
                } else {
                    return identity?.workload || "";
                }
            });
        }

        return [];
    }, [query.data, aggeratedLevel, serviceIdMap, props.subScene]);

    const chartSeries = useMemo<SeriesBarOptions[]>(() => {
        if (!query.data) {
            return [];
        }

        return PcmV2SceneMetrics[props.scene].map((metric) => ({
            type: "bar",
            name: PcmV2MetricNames[metric] || "",
            data: query.data.map((item) => ({ y: item[metric] })),
        }));
    }, [query.data, props.scene]);

    const handleShowMore = useCallback(() => {
        eventBus.emit<ScrollToTableEvent>(EventTypeEnum.ScrollToTableEvent);
        trackEventCallback(LogComponent.TransChartingPane, LogElement.ShowMoreTop10Trans, "Show More", LogTarget.Button);
    }, []);

    return (
        <ChartContainer
            headerProps={{ title: chartTitle }}
            loading={query.isLoading}
            chartType="bar"
            chartProps={{
                tooltipProps: {
                    date: [dates.startDate, dates.endDate],
                },
                yAxis: { labelFormatter: currencyFormatter },
                categories: chartCategories,
                series: chartSeries,
                containerProps: trackingProps,
            }}
            logProps={{
                logComponent: SubstrateLogComponent[props.scene],
                logElement: LogElement.Top10,
            }}
        >
            {props.scene === PcmV2SceneTypeEnum.Transaction && (
                <DefaultButton className={styles.showMore} onClick={handleShowMore}>
                    Show More
                </DefaultButton>
            )}
        </ChartContainer>
    );
};
