import * as React from "react";
import styles from "./CommonContainer.less";

interface ConmonContainerProps {
    title?: string;
}

const CommonContainer: React.FC<ConmonContainerProps> = (props) => {
    return (
        <div className={styles.outline}>
            <div className={styles.separator} />
            {props.title && <div className={styles.title}>{props.title}</div>}
            <div className={styles.inner} >
                {props.children}
            </div>
        </div>
    );
}

export default CommonContainer;