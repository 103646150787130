import { IDataAnomalyResponse, INotification, SubstrateV2DataSourceNames, SubstrateV2DataSourceNameToMetricMap } from "../models/NotificationModels";

import { IDateRangeFilterData } from "../models/FilterView";
import { getJson } from "../utils/apiServiceBase";
import { isWeekend } from "../utils/DateUtils";
import { mergeAnomaliesByMetrics } from "../utils/AnomalyDetectionUtils";
import moment from "moment";
import qs from 'query-string';

export const DataAnomalyBaseUrl = 'api/v1.0/dataanomaly';

export async function getAnomalies({queryKey}: {queryKey: [string, IDateRangeFilterData?]}): Promise<INotification[]> {
    const [, dateRangeFilter] = queryKey;

    const url = `${DataAnomalyBaseUrl}/DataIssueReports?` + qs.stringify({
            startDate: dateRangeFilter && dateRangeFilter.startDate.format("YYYY-MM-DD"),
            endDate: dateRangeFilter && dateRangeFilter.endDate.format("YYYY-MM-DD"),
        });

    const dataAnomalies = await getJson<IDataAnomalyResponse[]>(url);

    const responseData = dataAnomalies.map((dataAnomaly) => ({
        startDate: moment(dataAnomaly.startDate),
        endDate: moment(dataAnomaly.endDate),
        detectionDate: moment(dataAnomaly.detectionDate),
        category: dataAnomaly.category,
        status: dataAnomaly.status,
        affectedMetrics: dataAnomaly.affectedMetrics,
        dataSourceName: dataAnomaly.dataSourceName,
    })).sort((a, b) => {
        return a.startDate.valueOf() - b.startDate.valueOf();
    });

    const result = mergeAnomaliesByMetrics(responseData).filter(data => {
        // ignore anomlies only have impact on weekends
        if (Math.abs(data.startDate.diff(data.endDate, 'day')) <= 1 && isWeekend(data.startDate) && isWeekend(data.endDate)) {
            return false;
        }

        return true;
    });

    result.forEach(item => {
        while (isWeekend(item.startDate)) {
            item.startDate.add(1, "day");
        }

        while (isWeekend(item.endDate)) {
            item.endDate.subtract(1, "day");
        }

        if (SubstrateV2DataSourceNames.indexOf(item.dataSourceName) >= 0) {
            item.affectedMetrics = SubstrateV2DataSourceNameToMetricMap[item.dataSourceName] || [];
        }
    });


    result.sort((a, b) => b.startDate.valueOf() - a.startDate.valueOf())

    return result;
}