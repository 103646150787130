import React from "react";
import moment from "moment";
import {
    ESCostBillingType,
    ESCostDataDimensions,
    ESCostOrganization,
    ESOverviewMonthlyCostRawResponse,
    ESOverviewTimeSelection,
    ESOverviewFYBudgetRawResponse,
} from "../../../models/ESCostModels";
import { useEsOverviewTimeRange } from "../../../hooks/useEsOverviewTimeRange";
import { useEsCostResource } from "../../../hooks/useEsCostResource";
import { useEsOrg } from "../../../hooks/useEsOrg";
import { ESCostUtils } from "../Utilities/ESCostUtils";
import StackedAreaChart, { IStackedAreaChartData } from "./Common/StackedAreaChart";
import { ESCostResource, ESOrgs } from "../../../models/constants/ESCostConstants";

interface IFYMonthlyTrendChartProps {
    data: ESOverviewMonthlyCostRawResponse[];
    budget: ESOverviewFYBudgetRawResponse[];
    fixedToCurrentFY?: boolean;
}

function getBudget(
    org: ESCostOrganization[],
    billingType: ESCostBillingType[],
    rawBudget: ESOverviewFYBudgetRawResponse[],
    dateLength: number,
    year: number
): number[] {
    const costs = rawBudget.filter(
        (cost) =>
            ESOrgs.includes(cost[ESCostDataDimensions.Organization]) &&
            ESCostResource.includes(cost[ESCostDataDimensions.BillingType]) &&
            (org.length === 0 || org.includes(cost[ESCostDataDimensions.Organization])) &&
            (billingType.length === 0 || billingType.includes(cost[ESCostDataDimensions.BillingType])) &&
            year == cost[ESCostDataDimensions.ReturnFiscalYear]
    );

    const monthlyBudget = costs.reduce((acc, cur) => acc + cur[ESCostDataDimensions.Budget], 0) / 12.0;
    return Array.from({ length: dateLength }, () => monthlyBudget);
}

function getDates(fy?: ESOverviewTimeSelection, fixedToCurrentFY?: boolean): string[] {
    const startDate =
        !fixedToCurrentFY && fy === ESOverviewTimeSelection.LastFY ? ESCostUtils.getLastFiscalYearDates()[0] : ESCostUtils.getCurrentFiscalYearDates()[0];
    const dates = Array.from({ length: 12 }, (_, i) => moment(startDate).add(i, "month").date(1).format("YYYYMMDD"));
    const now = moment().format("YYYYMMDD");
    return dates.filter((date) => date < now);
}

function reduceCosts(costs: ESOverviewMonthlyCostRawResponse[], dates: string[], resources: string[]): IStackedAreaChartData[] {
    const result = resources.map((resource) => ({ name: resource, data: Array.from({ length: dates.length }, () => 0) }));

    costs.forEach((cost) => {
        const i = resources.indexOf(cost[ESCostDataDimensions.BillingType]);
        const j = dates.indexOf(cost[ESCostDataDimensions.TimeStamp]);
        result[i].data[j] += cost[ESCostDataDimensions.Cost];
    });

    return result;
}

function calculateOverviewCost(
    org: ESCostOrganization[],
    billingType: ESCostBillingType[],
    dates: string[],
    rawCost: ESOverviewMonthlyCostRawResponse[]
): IStackedAreaChartData[] {
    const monthlyCosts = rawCost.map((item) => {
        return {
            ...item,
            [ESCostDataDimensions.TimeStamp]: moment(item[ESCostDataDimensions.TimeStamp]).date(1).format("YYYYMMDD"),
        };
    });

    const costs = monthlyCosts.filter(
        (cost) =>
            ESCostResource.includes(cost[ESCostDataDimensions.BillingType]) &&
            ESOrgs.includes(cost[ESCostDataDimensions.Organization]) &&
            (billingType.length === 0 || billingType.includes(cost[ESCostDataDimensions.BillingType])) &&
            (org.length === 0 || org.includes(cost[ESCostDataDimensions.Organization])) &&
            dates.includes(cost[ESCostDataDimensions.TimeStamp])
    );

    return reduceCosts(costs, dates, billingType.length === 0 ? ESCostResource : billingType);
}

const MonthlyTrendingChart = (props: IFYMonthlyTrendChartProps) => {
    const { data: rawCost, budget: rawBudget, fixedToCurrentFY } = props;
    const [org] = useEsOrg();
    const [billingType] = useEsCostResource();
    const [timeRange] = useEsOverviewTimeRange();

    const year = React.useMemo(
        () =>
            (!fixedToCurrentFY && timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [fixedToCurrentFY, timeRange]
    );
    const dates = React.useMemo(() => getDates(timeRange, fixedToCurrentFY), [timeRange, fixedToCurrentFY]);
    const esCost = React.useMemo(() => calculateOverviewCost(org, billingType, dates, rawCost), [billingType, dates, org, rawCost]);
    const budget: number[] = React.useMemo(() => getBudget(org, billingType, rawBudget, dates.length, year), [billingType, dates.length, org, rawBudget, year]);

    return (
        <StackedAreaChart
            data={esCost}
            dates={{ dates, format: "MMM YYYY", step: 3 }}
            description={fixedToCurrentFY ? `Current fiscal year monthly trend` : `Monthly trend: FY${String(year).substring(2)}`}
            yAxisTitle="Actual cost"
            isCurrency={true}
            tooltipTitle="Total cost"
            hasBenchmark={false}
            benchmarkTitle="Budget"
            benchmarkValues={budget}
        />
    );
};

export default MonthlyTrendingChart;
