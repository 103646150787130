import { ITelemetryResourceCost, TelemetryLogComponent, TelemetrySourceTypeEnum, TelemetrySourceTypeName } from "../../../models/TelemetryCogsModel";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import React, { useState } from "react";

import CommonConstants from "../../../models/constants/CommonConstants";
import { CostCardProps } from "../../common/costCard/CostCard";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { DonutChart } from "../../common/DonutChart";
import { currencyFormatter } from "../../../utils/currency";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import styles from "./TelemetryChart.less";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useFlights } from "../../../hooks/useSettings";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useGetTelemetryResourceCost } from "../../../hooks/useTelemetryCost";
import { useTrackHovering } from "../../../hooks/useTrack";

interface ITelemetryDonutChartProps {
    sources: TelemetrySourceTypeEnum[];
}

const TelemetryDonutChart: React.FC<ITelemetryDonutChartProps> = ({ sources }) => {
    const globalFiltersData = useCategoryFilters().filters;
    const { data: query, isLoading: telemetryLoading }  = useGetTelemetryResourceCost();
    const { data: flights } = useFlights();

    const telemetryAnomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.Telemetry);
    const activeTelemetryAnomalies = React.useMemo(
        () => getActiveAnomalies(telemetryAnomaliesQuery.data, ["Cosmos", "Blueshift", "Kusto", "GenevaMds", "GenevaMdm", "Aria"]),
        [telemetryAnomaliesQuery.data]
    );

    const formatData = (value: number) => {
        return currencyFormatter(value, 2, "$");
    };
    
    const formatAxisData = (value: number) => {
        return currencyFormatter(value, 2, "$");
    };

    function getTelemetryCost(source: TelemetrySourceTypeEnum, telemetryCost: ITelemetryResourceCost) {
        switch (source) {
            case TelemetrySourceTypeEnum.Cosmos:
                return telemetryCost.cosmosCost;
            case TelemetrySourceTypeEnum.Blueshift:
                return telemetryCost.blueShiftCost;
            case TelemetrySourceTypeEnum.Kusto:
                return telemetryCost.kustoCost;
            case TelemetrySourceTypeEnum.GenevaMds:
                return telemetryCost.genevaMdsCost;
            case TelemetrySourceTypeEnum.GenevaMdm:
                return telemetryCost.genevaMdmCost;
            case TelemetrySourceTypeEnum.Aria:
                return telemetryCost.ariaCost;
            case TelemetrySourceTypeEnum.PassiveMon:
                return telemetryCost.passiveMonCost;
            case TelemetrySourceTypeEnum.All:
                return telemetryCost.cosmosCost + telemetryCost.blueShiftCost + telemetryCost.kustoCost + telemetryCost.genevaMdsCost + telemetryCost.genevaMdmCost + telemetryCost.ariaCost;
            default:
                return 0;
        }
    }

    const telemetryCostdata = React.useMemo(
        () =>
            sources.map((source, index) => ({
                    name: TelemetrySourceTypeName[source] || "",
                    y: (query && query.reduce((sum, val) => sum + getTelemetryCost(source, val) || 0, 0)) || 0,
                    color: CommonConstants.DefaultColors[index],
                    anomalies: activeTelemetryAnomalies.filter(item => item.dataSourceName ===  TelemetrySourceTypeName[source]),
                }))
                .filter((item) => item.y > 0),
        [telemetryAnomaliesQuery.data, query]
    );
    const dateRange1 = useDateRange();

    return (
        <DonutChart
            {...{
                data: telemetryCostdata,
                dateRange: [dateRange1.startDate, dateRange1.endDate],
            }}
        />
    );
};

export default TelemetryDonutChart;