import { LogComponent, LogElement } from "./LogModel";

export interface ITelemetryServiceCost {
    serviceId: string;
    serviceName: string;
    teamGroupId: string;
    teamGroupName: string;
    serviceGroupId: string;
    serviceGroupName: string;
    organizationId: string;
    organizationName: string;
    divisionId: string;
    divisionName: string;
    cosmosCost: number;
    blueShiftCost: number;
    kustoCost: number;
    genevaMdsCost: number;
    genevaMdmCost: number;
    ariaCost: number;
    passiveMonCost: number;
    totalCost: number;
}

export interface ITelemetryResourceCost {
    timeStamp: moment.Moment;
    cosmosCost: number;
    blueShiftCost: number;
    kustoCost: number;
    genevaMdsCost: number;
    genevaMdmCost: number;
    ariaCost: number;
    passiveMonCost: number;
}

export enum TelemetrySourceTypeEnum {
    Cosmos,
    Blueshift,
    Kusto,
    GenevaMds,
    GenevaMdm,
    Aria,
    PassiveMon,
    All,
}

export const TelemetrySourceTypeName : Record<TelemetrySourceTypeEnum, string> = {
    [TelemetrySourceTypeEnum.All]: 'Telemetry',
    [TelemetrySourceTypeEnum.Cosmos]: 'Cosmos',
    [TelemetrySourceTypeEnum.Blueshift]: 'Blueshift',
    [TelemetrySourceTypeEnum.Kusto]: 'Kusto',
    [TelemetrySourceTypeEnum.GenevaMds]: 'GenevaMds',
    [TelemetrySourceTypeEnum.GenevaMdm]: 'GenevaMDM',
    [TelemetrySourceTypeEnum.Aria]: 'Aria',
    [TelemetrySourceTypeEnum.PassiveMon]: 'PassiveMon',
};

export const TelemetryLogComponent: Record<TelemetrySourceTypeEnum, LogComponent> = {
    [TelemetrySourceTypeEnum.All]: LogComponent.TelemetryView,
    [TelemetrySourceTypeEnum.Cosmos]: LogComponent.Cosmos,
    [TelemetrySourceTypeEnum.Blueshift]: LogComponent.Blueshift,
    [TelemetrySourceTypeEnum.Kusto]: LogComponent.Kusto,
    [TelemetrySourceTypeEnum.GenevaMds]: LogComponent.GenevaMds,
    [TelemetrySourceTypeEnum.GenevaMdm]: LogComponent.GenevaMdm,
    [TelemetrySourceTypeEnum.PassiveMon]: LogComponent.PassiveMonitoringView,
    [TelemetrySourceTypeEnum.Aria]: LogComponent.Aria,
};

export const TelemetryLogElement: Record<TelemetrySourceTypeEnum, LogElement> = {
    [TelemetrySourceTypeEnum.All]: LogElement.TelemetryTable,
    [TelemetrySourceTypeEnum.Cosmos]: LogElement.TelemetryCosmosTable,
    [TelemetrySourceTypeEnum.Blueshift]: LogElement.BlueshiftTable,
    [TelemetrySourceTypeEnum.Kusto]: LogElement.KustoTable,
    [TelemetrySourceTypeEnum.GenevaMds]: LogElement.GenevaMdsTable,
    [TelemetrySourceTypeEnum.GenevaMdm]: LogElement.GenevaMdmTable,
    [TelemetrySourceTypeEnum.Aria]: LogElement.AriaTable,
    [TelemetrySourceTypeEnum.PassiveMon]: LogElement.TelemetryTable,
};

export const TelemetryCostKeys: (TypedKeyOf<ITelemetryServiceCost, number>)[] = ['totalCost', 'cosmosCost', 'blueShiftCost', 'kustoCost', 'genevaMdsCost', 'genevaMdmCost', 'ariaCost', 'passiveMonCost'];

export const TelemetryCostTableKeys: Record<TelemetrySourceTypeEnum, (keyof Partial<ITelemetryServiceCost>)[]> = {
    [TelemetrySourceTypeEnum.All]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'cosmosCost', 'blueShiftCost', 'kustoCost', 'genevaMdsCost', 'genevaMdmCost', 'ariaCost'],
    [TelemetrySourceTypeEnum.Cosmos]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'cosmosCost'],
    [TelemetrySourceTypeEnum.Blueshift]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'blueShiftCost'],
    [TelemetrySourceTypeEnum.Kusto]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'kustoCost'],
    [TelemetrySourceTypeEnum.GenevaMds]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'genevaMdsCost'],
    [TelemetrySourceTypeEnum.GenevaMdm]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'genevaMdmCost'],
    [TelemetrySourceTypeEnum.Aria]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'ariaCost'],
    [TelemetrySourceTypeEnum.PassiveMon]: ['serviceName', 'organizationName', 'serviceGroupName', 'totalCost', 'passiveMonCost'],
};

export const TelemetryCostDisplayNames : Record<keyof ITelemetryServiceCost, string> = {
    serviceId: 'Service Id',
    serviceName: 'Service Name',
    teamGroupId: 'Team Group Id',
    teamGroupName: 'Team Group Name',
    serviceGroupId: 'Service Group Id',
    serviceGroupName: 'Service Group Name',
    organizationId: 'OrganizationId',
    organizationName: 'OrganizationName',
    divisionId: 'DivisionId',
    divisionName: 'DivisionName',
    cosmosCost: 'Cosmos Cost',
    blueShiftCost: 'Blueshift Cost',
    kustoCost: 'Kusto Cost',
    genevaMdsCost: 'Geneva Mds Cost',
    genevaMdmCost: 'Geneva Mdm Cost',
    ariaCost: 'Aria Cost',
    passiveMonCost: 'Passive Monitoring Cost',
    totalCost: 'Total Cost'
};