import { DataSourceEnum, INotification, SubstrateV2DataSourceNames } from "../models/NotificationModels";
import Highcharts, { PointOptionsObject, SeriesAreaOptions, SeriesLineOptions, SeriesZonesOptionsObject } from "highcharts";
import moment, { Moment } from "moment";

import CommonConstants from "../models/constants/CommonConstants";
import { intersectionWith } from "lodash";
import { isWeekend } from "./DateUtils";
import { TimeView } from "../models/SearchKey";

const AnomalyColor = "#C53601";

const AnomalyPointMarker: Highcharts.PointMarkerOptionsObject = {
    enabled: true,
    fillColor: AnomalyColor,
    symbol: "diamond",
    lineWidth: 1,
    states: {
        hover: {
            enabled: false,
        },
    },
};

function mergeAbnormalDateRanges(anomalies: Array<INotification>): Array<[Moment, Moment]> {
    const result: Array<[Moment, Moment]> = [];

    const ranges: [Moment, Moment][] = anomalies.map((item) => [item.startDate, item.endDate]);

    ranges.sort((a, b) => a[0].valueOf() - b[0].valueOf());

    for (const range of ranges) {
        if (result.length == 0 || result[result.length - 1][1].valueOf() < range[0].valueOf() - 1000 * 60 * 60 * 24) {
            result.push(range);
        } else {
            result[result.length - 1][1] = moment(Math.max(result[result.length - 1][1].valueOf(), range[1].valueOf()));
        }
    }

    return result;
}

function getAbnormalDateRanges(abnormalEvents: Array<INotification>, dataPoints: Array<[Moment, number?]>) {
    if (dataPoints.length == 0) return [];

    const ranges: [number, number][] = [];
    const mergedDateRanges = mergeAbnormalDateRanges(abnormalEvents);

    for (const [start, end] of mergedDateRanges) {
        let startIdx = -1;
        let endIdx = -1;

        if (start.isSameOrAfter(dataPoints[0][0], "date")) {
            startIdx = dataPoints.findIndex(([date]) => date.isSameOrAfter(start, "date"));

            if (startIdx < 0) continue;
        }

        if (end.isSameOrBefore(dataPoints[dataPoints.length - 1][0], "date")) {
            endIdx = dataPoints
                .slice()
                .reverse()
                .findIndex(([date]) => date.isSameOrBefore(end, "date"));

            if (endIdx < 0) continue;

            endIdx = dataPoints.length - endIdx - 1;
        }

        if ((startIdx >= 0 || endIdx >= 0) && startIdx != endIdx) {
            ranges.push([startIdx >= 0 ? startIdx : 0, endIdx >= 0 ? endIdx : dataPoints.length - 1]);
        }
    }

    return ranges;
}

function getAbnormalPlotZones(abnormalEvents: Array<INotification>, dataPoints: Array<[Moment, number?]>, useIndex = true): Array<SeriesZonesOptionsObject> {
    const zones: Array<SeriesZonesOptionsObject> = [];

    if (useIndex) {
        getAbnormalDateRanges(abnormalEvents, dataPoints)
            .filter((range) => range[1] > range[0])
            .forEach(([startIndex, endIndex]) => {
                zones.push({
                    value: startIndex,
                });
                zones.push({
                    value: endIndex,
                    color: AnomalyColor,
                });
            });
    } else {
        const mergedDateRanges = mergeAbnormalDateRanges(abnormalEvents);

        mergedDateRanges.forEach(([startDate, endDate]) => {
            const startDatePoint = dataPoints.find((p) => p[0].isSame(startDate, "day"));
            const endDatePoint = dataPoints.find((p) => p[0].isSame(endDate, "day"));

            zones.push({
                value: (startDatePoint?.[0] || startDate).valueOf(),
            });

            zones.push({
                value: (endDatePoint?.[0] || endDate).valueOf(),
                color: AnomalyColor,
            });
        });
    }

    return zones;
}

export function getFilledAbnormalData(anomalies: INotification[], data: [Moment, number?][]) {
    const fullfilledData = [...data];

    for (const anomaly of anomalies) {
        let hasData = false;

        for (const dailyData of data) {
            if (isDateBetween(anomaly.startDate, anomaly.endDate, dailyData[0])) {
                hasData = true;
                break;
            }
        }

        if (!hasData) {
            fullfilledData.push([anomaly.startDate, 0]);
        }
    }

    fullfilledData.sort((a, b) => a[0].valueOf() - b[0].valueOf());

    return fullfilledData;
}

export function getActiveAnomalies(anomalies?: INotification[], metrics?: string[], ignoreWeekend = true) {
    return (
        anomalies?.filter((item) => {
            return (
                item.status != "Resolved" &&
                (!metrics || intersectionWith(metrics, item.affectedMetrics, (a, b) => a.toLowerCase() == b.toLowerCase()).length > 0) &&
                (!ignoreWeekend || Math.abs(item.startDate.diff(item.endDate, "day")) >= 2 || !isWeekend(item.startDate) || !isWeekend(item.endDate))
            ); // ignore anomalies happend on weekends.
        }) || []
    );
}

export function getAnomaliesByDate(abnormalEvents: Array<INotification>, date: Moment, timeView: TimeView = TimeView.Daily): Array<INotification> {
    if (timeView === TimeView.Daily) {
        return abnormalEvents.filter((item) => {
            return date.isSame(item.startDate, "date") || date.isSame(item.endDate, "date") || date.isBetween(item.startDate, item.endDate, undefined, "[]");
        });
    } else {
        return abnormalEvents.filter((item) => {
            return date.isSame(item.startDate, "week") || date.isSame(item.endDate, "week") || date.isBetween(item.startDate, item.endDate, undefined, "[]");
        });
    }
}

export function isDateBetween(startDate: Moment, endDate: Moment, date: Moment) {
    return date.isSame(startDate, "date") || date.isSame(endDate, "date") || date.isBetween(startDate, endDate, undefined, "[]");
}

export function getAnomaliesByMetric(abnormalEvents: Array<INotification>, metric: string): Array<INotification> {
    return abnormalEvents
        .filter((item) => item.affectedMetrics?.findIndex((key) => metric.toLowerCase() === key.toLowerCase()) >= 0)
        .sort((a, b) => -a.startDate.valueOf() + b.startDate.valueOf());
}

export function getAnomalyDescription(category: string, metrics: string[], start: Moment, end?: Moment, dataSourceName?: DataSourceEnum) {
    if (!metrics || metrics.length == 0) return "";

    let desc = "";

    if (dataSourceName && SubstrateV2DataSourceNames.find(n => n === dataSourceName)) {
        metrics = [dataSourceName];
    }

    if (dataSourceName === DataSourceEnum.AzureCompute) {
        desc = `${category} on Azure Compute`;
    } else {
        if (metrics.length == 1) {
            desc = `${metrics} has ${category.toLowerCase()}`;
        } else {
            desc = `${category} on ${metrics.join(", ")}`;
        }
    }

    desc += ` on ${start.format(CommonConstants.DateFormatterString)}`;

    if (end && !end.isSame(start, "date")) {
        desc += ` - ${end.format(CommonConstants.DateFormatterString)}`;
    }

    return desc;
}

interface AnomalySeriesOptions {
    formatterString?: string;
    useIndex?: boolean;
    force?: boolean;
    includeWeekends?: boolean;
    fillMissingAbnormalData?: boolean;
}

const AnomalySeriesDefaultOptions: AnomalySeriesOptions = {
    formatterString: CommonConstants.DateFormatterString,
    useIndex: true,
    force: false,
    includeWeekends: false,
    fillMissingAbnormalData: false,
};

/**
 * create series with anomalies
 * @returns: array of series, the first series is the data series, the second one is the mirrored series which only shows the markers when hovering over.
 */
export function getAnomalySeries(
    anomalies: Array<INotification> = [],
    data: [Moment, number?][],
    seriesOptions?: Partial<SeriesLineOptions | SeriesAreaOptions>,
    pointOptions?: (data: [Moment, number?]) => Partial<PointOptionsObject>,
    customOptions: AnomalySeriesOptions = {}
): (SeriesLineOptions | SeriesAreaOptions)[] {
    data.sort((a, b) => a[0].valueOf() - b[0].valueOf());

    if (customOptions.force == undefined && seriesOptions?.type === "area") {
        customOptions.force = true;
    }

    const options = Object.assign({}, AnomalySeriesDefaultOptions, customOptions);
    const { formatterString, useIndex, force } = options;

    if (options.fillMissingAbnormalData) {
        data = getFilledAbnormalData(anomalies, data);
    }

    const dataOption: SeriesLineOptions["data"] = data.map((item) => {
        const point: PointOptionsObject = {
            y: !options.includeWeekends && isWeekend(item[0]) ? null : item[1],
            name: item[0].format(formatterString),
            ...(pointOptions && pointOptions(item)),
        };

        return point;
    });

    if (!force && !anomalies?.length) {
        const isSinglePoint = data.map((item) => (item[1] == undefined ? 0 : 1)).reduce<number>((sum, cur) => sum + cur, 0) == 1;

        return [
            {
                type: "line",
                connectNulls: true,
                lineWidth: 4,
                marker: {
                    enabled: isSinglePoint,
                    symbol: "square",
                    states: {
                        hover: {
                            enabled: true,
                        },
                    },
                },
                visible: true,
                data: dataOption,
                ...seriesOptions,
            },
        ];
    }

    const anomalyDataOptions: SeriesLineOptions["data"] = data.map((item) => {
        const point: PointOptionsObject = {
            y: !options.includeWeekends && isWeekend(item[0]) ? null : item[1],
            name: item[0].format(formatterString),
            ...(pointOptions && pointOptions(item)),
        };

        if (getAnomaliesByDate(anomalies, item[0]).length > 0) {
            point.marker = AnomalyPointMarker;
        }

        return point;
    });

    const dataSeries: SeriesLineOptions | SeriesAreaOptions = {
        type: "line",
        lineWidth: 4,
        marker: {
            enabled: false,
            states: {
                hover: {
                    enabled: false,
                },
            },
        },
        events: {
            hide: function () {
                this.chart.series.find((item) => item.name === `hidden_${this.name}`)?.hide();
            },
            show: function () {
                this.chart.series.find((item) => item.name === `hidden_${this.name}`)?.show();
            },
        },
        visible: true,
        connectNulls: true,
        zoneAxis: "x",
        zones: getAbnormalPlotZones(anomalies, data, useIndex),
        data: anomalyDataOptions,
        ...seriesOptions,
    };

    const dataMarkerSeries: SeriesLineOptions | SeriesAreaOptions = {
        type: "line",
        lineWidth: 4,
        data: dataOption,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lineColor: "transparent",
        marker: { symbol: "square", enabled: false, states: { hover: { enabled: true } } },
        visible: true,
        connectNulls: true,
        showInLegend: false,
        zIndex: 2,
        fillColor: "transparent",
        ...seriesOptions,
        id: "hidden_" + (seriesOptions?.id || seriesOptions?.name || ""),
        name: "hidden_" + (seriesOptions?.name || ""),
    };

    if (seriesOptions?.type == "area") {
        dataSeries.stack = 0;
        dataMarkerSeries.stack = 1;
    }

    return [dataSeries, dataMarkerSeries];
}

/**
 * @returns empty series which only shows in legends.
 */
export function getAnomalyLegendPlaceholderSeries(): SeriesLineOptions {
    return {
        name: "Anomaly Detection",
        id: "anomalies",
        type: "line",
        color: AnomalyColor,
        showInLegend: true,
        states: {
            inactive: {
                enabled: false,
            },
            select: {
                enabled: false,
            },
        },
        legendIndex: 999,
        events: {
            legendItemClick: function (e) {
                e.preventDefault();
            },
        },
    };
}

/**
 * merge affected date intervals.
 * @param anomalies
 * @returns
 */
export function mergeAnomaliesByMetrics(anomalies: INotification[]) {
    const categoryMap = new Map<string, INotification[]>();

    anomalies.forEach((anomaly) => {
        const key = `${anomaly.dataSourceName}_${anomaly.affectedMetrics.slice().sort().join()}_${anomaly.status}`;

        if (!categoryMap.has(key)) {
            categoryMap.set(key, []);
        }

        categoryMap.get(key)?.push(anomaly);
    });

    const result: INotification[] = [];
    const dayTime = 1000 * 60 * 60 * 24;

    categoryMap.forEach((categorizeAnomalies) => {
        categorizeAnomalies.sort((a, b) => a.startDate.valueOf() - b.startDate.valueOf());

        const mergedAnomalies: INotification[] = [];

        for (const anomaly of categorizeAnomalies) {
            const lastIndex = mergedAnomalies.length - 1;

            if (mergedAnomalies.length == 0 || mergedAnomalies[lastIndex].endDate.valueOf() < anomaly.startDate.valueOf() - dayTime) {
                mergedAnomalies.push(anomaly);
            } else {
                mergedAnomalies[lastIndex].endDate = moment(Math.max(mergedAnomalies[lastIndex].endDate.valueOf(), anomaly.endDate.valueOf()));
            }
        }

        result.push(...mergedAnomalies);
    });

    return result;
}
