import { Callout, DefaultButton, Icon, IStackTokens, Stack, Text } from "@fluentui/react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import * as React from "react";
import styles from "./NumberPicker.less";

const cardTokens: IStackTokens = {childrenGap: 8};
interface INumberPickerProps {
    current: number;
    list: number[];
    onselect(value: number): void;
}

export const NumberPicker: React.FC<INumberPickerProps> = (props) => {
    const buttonId = useId("button-id");
    const [isCalloutVisible, {toggle: toggleCallout, setFalse: dismissCallout}] = useBoolean(false);

    return (
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <Stack
                horizontal
                verticalAlign="center"
                tokens={cardTokens}
                onClick={toggleCallout}
                id={buttonId}
                styles={{root: {cursor: "pointer"}}}
            >
                <Icon iconName="Calendar" />
                <Text>{`Fiscal Year: FY${props.current}`}</Text>
                <Icon iconName={isCalloutVisible ? "ChevronUp" : "ChevronDown"} />
            </Stack>
            {
                isCalloutVisible &&
                <Callout
                    target={`#${buttonId}`}
                    onDismiss={dismissCallout}
                    isBeakVisible={false}
                    calloutWidth={148}
                >
                    {
                        props.list.map(fiscalYear => (
                            <DefaultButton
                                key={fiscalYear}
                                className={styles.item}
                                text={"FY" + fiscalYear}
                                onClick={() => {props.onselect(fiscalYear); dismissCallout();}}
                                styles={{label: styles.label}}
                            />
                        ))
                    }
                </Callout>
            }
        </Stack>
    )
}