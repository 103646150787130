import * as React from "react";
import OverviewFilterBar from "../Filter/OverviewFilterBar";
import { Stack } from "@fluentui/react";
import OverviewPage from "./OverviewPage";
import { outerStackStyles, filterStackStyles } from "./ViewsCommonLayout";

export const OverviewContainer: React.FC = () => {
    return (
        <Stack styles={outerStackStyles} disableShrink>
            <Stack horizontal styles={filterStackStyles}>
                <OverviewFilterBar />
            </Stack>
            <OverviewPage />
        </Stack>
    );
};
