import { Callout, DirectionalHint, Icon } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useRef } from "react";
import styles from "./TimeViewPicker.less";
import { useTimeView } from "../../../hooks/useTimeView";
import { TimeView } from "../../../models/SearchKey";

const avaliableTimeViews = [TimeView.Daily, TimeView.Weekly];

const TimeViewPicker: React.FC = () => {
    const buttonRef = useRef<HTMLDivElement>(null);
    const [isCalloutVisible, {toggle: toggleCallout, setFalse: dismissCallout}] = useBoolean(false);
    const [timeView, setTimeView] = useTimeView();

    return (
        <div className={styles.viewSelector}>
            <div className={styles.viewSelectorContent} ref={buttonRef} onClick={toggleCallout}>
                <span className={styles.viewSelectorContentText}>
                    {timeView}
                </span>
                <Icon iconName="ChevronDown" className={styles.downIcon} />
            </div>
            {isCalloutVisible && (
                <Callout directionalHint={DirectionalHint.bottomRightEdge} isBeakVisible={false} target={buttonRef.current} onDismiss={dismissCallout}>
                    <div className={styles.viewListWrapper}>
                        <div className={styles.viewList}>
                            {avaliableTimeViews.map((item) => (
                                <div className={styles.viewListItem} key={item} onClick={() => {dismissCallout(); setTimeView(item);}}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                </Callout>
            )}
        </div>
    );
};

export default TimeViewPicker;