import CommonConstants from "../../../models/constants/CommonConstants";

export const grayBarColor = "#EDEDED";

export const grayTextColor = "#A1A1A1";

export const getColor = (color: number | string) => {
    return typeof (color) === "string" ? color : CommonConstants.DefaultColors
        [color > 0 && color < CommonConstants.DefaultColors.length ? color : 0];
}
