import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import { IDialogsData, ViewType, makeEditViewSuceessDialogAction, makeSaveViewFailDialogAction, makeSaveViewSuceessDialogAction, makeToggleDeleteDialogAction, makeToggleShareViewDialogAction } from '../reducers/dialogsReducer';
import { IStackTokens, Icon, Stack, Text, TextField } from '@fluentui/react';
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Flight } from '../models/Flights';
import { IAppState } from '../store';
import { deleteView } from '../services/viewService';
import { loadAllViewsIfNeeded } from '../reducers/savedViewReducer';
import styles from "./Dialogs.less";
import { useFlights } from '../hooks/useSettings';
import { useId } from '@fluentui/react-hooks';
import { useLocation } from 'react-router-dom';

interface IDeleteDialogProps {
    isOpen: boolean;
    viewName?: string;
    viewId?: number;
    viewType: ViewType;
}

interface ISavedViewDialogProps {
    isOpen: boolean;
    isSuccess: boolean;
    headerText: string;
    content: string;
}

interface ISaveSharePageDialogProps {
    isOpen: boolean;
    startDate: string;
    endDate: string;
    viewName?: string;
    id: string;
}

export const Dialogs: React.FC = () => {
    const dialogs = useSelector<IAppState, IDialogsData>(state => state.dialogs);
    const {
        isShareViewDialogOpen,
        isSaveViewFailDialogOpen,
        isSaveViewSuccessDialogOpen,
        isEditViewSuccessDialogOpen,
        isDeleteViewDialogOpen,
        viewName,
        viewId,
        viewType,
        errorMessage,
        startDate,
        endDate,
        shareViewId
    } = dialogs;

    return (
        <>
            <DeleteViewDialog
				isOpen={!!isDeleteViewDialogOpen}
				viewName={viewName}
				viewId={viewId}
				viewType={viewType}
            />
            <SavedViewDialog
				isOpen={!!isSaveViewSuccessDialogOpen}
				isSuccess={true}
				content={"Saved view created"}
				headerText={"Success"}
            />
            <SavedViewDialog
				isOpen={!!isEditViewSuccessDialogOpen}
				isSuccess={true}
				content={"View edited"}
				headerText={"Success"}
            />
            <SavedViewDialog
				isOpen={!!isSaveViewFailDialogOpen}
				isSuccess={false}
				content={errorMessage || ""}
				headerText={"Failed"}
            />
            <SaveSharePageDialog
				isOpen={!!isShareViewDialogOpen}
				startDate={startDate || ""}
				endDate={endDate || ""}
				viewName={viewName}
				id={shareViewId || ""}
            />
        </>
    );
}

const SavedViewDialog: React.FC<ISavedViewDialogProps> = (props) => {
    const dispatch = useDispatch();
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogContentProps = useMemo(() => ({
		type: DialogType.normal,
		title: renderSaveViewDialogHeader(props.isSuccess, props.headerText),
		closeButtonAriaLabel: 'Close',
		subText: props.content,
		showCloseButton: true,
    }), [props.content, props.headerText, props.isSuccess]);
    const modalProps = useMemo(
		() => ({
			titleAriaId: labelId,
			subtitleAriaId: subTextId,
			isBlocking: false,
			isDarkOverlay: false
		}),
		[labelId, subTextId],
    );
    const toggleHideDialog = () => {
        if (props.isSuccess) {
          	dispatch(makeSaveViewSuceessDialogAction(!props.isOpen));
          	dispatch(makeEditViewSuceessDialogAction(!props.isOpen));
        } else {
          	dispatch(makeSaveViewFailDialogAction(!props.isOpen));
        }
    }

  return (
		<>
			<Dialog
				hidden={!props.isOpen}
				onDismiss={toggleHideDialog}
				dialogContentProps={dialogContentProps}
				modalProps={modalProps}
			>
				<DialogFooter>
					<PrimaryButton onClick={toggleHideDialog} text="OK" />
				</DialogFooter>
			</Dialog>
		</>
  );
}

const renderSaveViewDialogHeader = (isSuccess: boolean, headerText: string): JSX.Element => {
    return (
        <div className={styles.saveViewSuccessDialogHeader}>
            <Icon styles={{ root: isSuccess ? styles.successDialogHeaderIcon : styles.failDialogHeaderIcon }} iconName={isSuccess ? "CompletedSolid" : "StatusErrorFull"} />
            <Text styles={{ root: styles.successDialogHeaderText }} variant={"medium"}>
                {headerText}
            </Text>
        </div>
    );
}

export const DeleteViewDialog: React.FC<IDeleteDialogProps> = (props) => {
    const dispatch = useDispatch();
    const { data: flights } = useFlights();
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = useMemo(() => ({ main: { maxWidth: 500, height: 182 }, scrollableContent: { overflowY: "hidden" } }), []);
    const dialogContentProps = useMemo(() => ({
        type: DialogType.normal,
        title: 'Delete saved View',
        closeButtonAriaLabel: 'Close',
        subText: `Are you sure you want to delete the "${props.viewName}"?`,
        showCloseButton: true
    }), [props.viewName]);

    const modalProps = useMemo(
		() => ({
			titleAriaId: labelId,
			subtitleAriaId: subTextId,
			isBlocking: false,
			styles: dialogStyles,
		}),
		[dialogStyles, labelId, subTextId],
    );

    const onHandleDeleteConfirmButton = useCallback(() => {
		dispatch(makeToggleDeleteDialogAction(false, "", 0, props.viewType));
		if (props.viewId) {
			deleteView(props.viewId, props.viewType).then(() => { dispatch(loadAllViewsIfNeeded(flights as Flight)) });
		}
    }, [dispatch, props.viewId, props.viewType, flights])

    const toggleHideDialog = useCallback(() => {
      	dispatch(makeToggleDeleteDialogAction(!props.isOpen, "", 0, props.viewType));
    }, [dispatch, props.isOpen, props.viewType])

    return (
        <>
            <Dialog
				hidden={!props.isOpen}
				onDismiss={toggleHideDialog}
				dialogContentProps={dialogContentProps}
				modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton onClick={onHandleDeleteConfirmButton} text="Delete" />
                    <DefaultButton onClick={toggleHideDialog} text="Cancel" />
                </DialogFooter>
            </Dialog>
        </>
    );
};


const SaveSharePageDialog: React.FC<ISaveSharePageDialogProps> = (props) => {
    const dispatch = useDispatch();
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = useMemo(() => ({ main: styles.saveSharePageDialog, scrollableContent: { overflowY: "hidden" } }), []);
    const stackTokens: IStackTokens = { childrenGap: 8 };
    const [isCopied, setIsCopied] = useState(false);
    const buttonText = isCopied ? "again" : "link";
    const dialogContentProps = useMemo(() => ({
		type: DialogType.normal,
		title: 'Share View',
		closeButtonAriaLabel: 'Close',
		showCloseButton: true
    }), []);

    const selectedNav = useLocation().pathname
    const link = useMemo(() => constructShareViewLink(props.id, selectedNav), [props.id, selectedNav])

    const modalProps = useMemo(
		() => ({
			titleAriaId: labelId,
			subtitleAriaId: subTextId,
			isBlocking: false,
			styles: dialogStyles,
		}),
		[dialogStyles, labelId, subTextId],
    );

    const toggleHideDialog = useCallback(() => {
		dispatch(makeToggleShareViewDialogAction(!props.isOpen));
		setIsCopied(false);
    }, [dispatch, props.isOpen])

    const copyToClipboard = useCallback(() => {
        const richTextBlob = new Blob([`<a href="${link}">Jaws (${link})</a>`], {type: "text/html"});
        const textBlob = new Blob([link], {type: "text/plain"});

		navigator.clipboard.write([new ClipboardItem({
            [richTextBlob.type]: richTextBlob,
            [textBlob.type]: textBlob} as Record<string, Blob>)]);
		setIsCopied(true);
    }, [link]);

    return (
		<>
			<Dialog
				hidden={!props.isOpen}
				onDismiss={toggleHideDialog}
				dialogContentProps={dialogContentProps}
				modalProps={modalProps}
			>
				<Stack tokens={stackTokens}>
					{sharePageText(isCopied, props.viewName)}
					<Text className={styles.viewDateRangeText}>UTC Date: {props.startDate} - {props.endDate} <br />People in Microsoft with the link can access.</Text>
					<TextField disabled multiline rows={2} resizable={false} defaultValue={link} />
					<DialogFooter styles={{ actionsRight: { textAlign: "left" }, action: { marginLeft: "0" } }}>
						<PrimaryButton 
							onClick={copyToClipboard}
							styles={{ root: styles.copyLinkButton, label: styles.textContainer }} 
							iconProps={{ iconName: "Link" }} 
							text={`Copy ${buttonText}`} 
						/>
					</DialogFooter>
				</Stack>
			</Dialog>
		</>
    );
}

const sharePageText = (isCopied: boolean, viewName?: string): JSX.Element => {
    const headerText = !!viewName ? (!isCopied ? viewName : `"${viewName}" Link copied`) : (!isCopied ? "your selections" : "Link of your selections copied");
    return (
        <Text className={styles.viewName}>{isCopied && <Icon className={styles.isCopiedIcon} iconName={"CompletedSolid"} />}{headerText}</Text>
    );
}

function constructShareViewLink(id: string, nav: string) {
    return window.location.protocol + "//" + window.location.host + "/#" + nav + "?viewId=" + id.substring(1, id.length - 1);
}