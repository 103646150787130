import * as React from "react";
import { Stack } from "@fluentui/react";
import { outerStackStyles, filterStackStyles } from "../ViewsCommonLayout";
import ArtifactOverviewFilterBar from "../../Filter/ArtifactOverviewFilterBar";
import ArtifactOverviewPage from "./ArtifactOverviewPage";
import { ESArtifactDataType } from "../../../../models/ESCostModels";

export const ArtifactOverviewContainer: React.FC = () => {
    return (
        <Stack styles={outerStackStyles} disableShrink>
            <Stack horizontal styles={filterStackStyles}>
                <ArtifactOverviewFilterBar />
            </Stack>
            <ArtifactOverviewPage dataType={ESArtifactDataType.Cost} />
        </Stack>
    );
};
