export interface ESOverviewFiscalYearValues {
    [ESCostDataDimensions.BillingType]: ESCostBillingType;
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]?: number;
    [ESCostDataDimensions.Capacity]?: number;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
}

export interface ESArtifactOverviewFiscalYearValues {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
}

export interface ESOverviewMonthlyCostRawResponse {
    [ESCostDataDimensions.BillingType]: ESCostBillingType;
    [ESCostDataDimensions.TimeStamp]: string;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
}

export interface ESCloudBuildOverviewCapacityRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Capacity]: number;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
}

export interface ESOverviewFYBudgetRawResponse {
    [ESCostDataDimensions.BillingType]: ESCostBillingType;
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Budget]: number;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
}

export interface ESManagerFYCostRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.UserName]: string;
    [ESCostDataDimensions.UserType]: ESCostRawUserType;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.ArtifactWorkload]: number;
}

export interface ESTotalFYCostRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.Category]: string;
}

export interface ESManagerFYReturnTypeCostRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.UserName]: string;
    [ESCostDataDimensions.UserType]: ESCostRawUserType;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.ReturnType]: string;
}

export interface ESRepoFYArtifactCostRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.Repository]: string;
    [ESCostDataDimensions.ArtifactWorkload]: number;
}

export interface ESAllUsageScenariosFYReturnTypeCostRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.UsageScenario]: ESUsageScenarios;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.ReturnType]: string;
}

export interface ESBuildQueueFYCostRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.UsageScenario]: ESUsageScenarios;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.BuildQueue]: string;
    [ESCostDataDimensions.ArtifactWorkload]: number;
}

export interface ESMonthToMonthCostTableRawResponse {
    [ESCostDataDimensions.TimeStamp]: string;
    [ESCostDataDimensions.Cost]?: number;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.Repository]?: string;
    [ESCostDataDimensions.Service]?: string;
    [ESCostDataDimensions.ArtifactWorkload]?: number;
}

export interface ESManagerMonthToMonthCostRawResponse {
    [ESCostDataDimensions.TimeStamp]: string;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.UserName]: string;
    [ESCostDataDimensions.UserType]: ESCostRawUserType;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.ArtifactWorkload]: number;
}

export interface ESUserTypeFYCostRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.UserName]: string;
    [ESCostDataDimensions.UserType]: ESCostRawUserType;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
}

export interface ESArtifactRetentionFYCostRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.Retention]: string;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.ArtifactWorkload]: number;
}

export interface ESAzureDataSourceFYCostRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.DataSource]: string;
}

export interface ESAzureRegionSubscriptionServiceFYCostRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.Region]?: string;
    [ESCostDataDimensions.SubscriptionId]?: string;
    [ESCostDataDimensions.Service]?: string;
}

export interface ESUsageScenarioFYCostRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.UserName]: string;
    [ESCostDataDimensions.UserType]: ESCostRawUserType;
    [ESCostDataDimensions.UsageScenario]: ESUsageScenarios;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.ArtifactWorkload]: number;
}

export interface ESBuildRoleFYCostRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.UserName]: string;
    [ESCostDataDimensions.UserType]: ESCostRawUserType;
    [ESCostDataDimensions.BuildRole]: string;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.ArtifactWorkload]: number;
}

export interface ESWeeklyBuilderStateCountRawResponse {
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.Count]: number;
    [ESCostDataDimensions.TimeStamp]: string;
    [ESCostDataDimensions.UsageScenario]: ESUsageScenarios;
}

export interface ESWeeklyBuildStageCostRawResponse {
    [ESCostDataDimensions.TimeStamp]: string;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.UsageScenario]: ESUsageScenarios;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.BuildStage]: string;
}

export interface ESAzureRegionSubscriptionMonthlyCostRawResponse {
    [ESCostDataDimensions.TimeStamp]: string;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.SubscriptionId]?: string;
    [ESCostDataDimensions.Region]?: string;
}

export interface ESHourlyBuilderStateCountRawResponse {
    [ESCostDataDimensions.TimeStamp]: string;
    [ESCostDataDimensions.Count]: number;
    [ESCostDataDimensions.UsageScenario]: ESUsageScenarios;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
}

export interface ESStampIdFYCostRawResponse {
    [ESCostDataDimensions.ReturnFiscalYear]: number;
    [ESCostDataDimensions.Cost]: number;
    [ESCostDataDimensions.UsageScenario]: ESUsageScenarios;
    [ESCostDataDimensions.Organization]: ESCostOrganization;
    [ESCostDataDimensions.StampID]: string;
}

export interface ESManagersResponse {
    [ESCostDataDimensions.Organization]?: ESCostOrganization;
    [ESCostDataDimensions.Name]: string;
    [ESCostDataDimensions.Level]: ESManagerLevels;
}

export interface SubscriptionMapping {
    [ESCostDataDimensions.SubscriptionName]: string;
    [ESCostDataDimensions.SubscriptionMappingId]: string;
}

export enum ESCostRawUserType {
    Human = "Buddy",
    CloudBuildInternal = "OnDemand(CloudBuild)",
    Other = "Other",
    ES = "OnDemand(ES)",
}

export enum ESSceneType {
    // Cost API
    Billing = "Billing",
    Job = "Job",
    TopRepoMoM = "TopRepoMoM",
    BuilderStage = "BuilderStage",
    Artifact = "Artifact",
    TotalArtifactCost = "TotalArtifactCost",
    ArtifactTopRepoMoM = "ArtifactTopRepoMoM",
    Retention = "Retention",
    Azure = "Azure",

    // Capacity API
    Capacity = "Capacity",

    // Budget API
    Budget = "Budget",

    // Count API
    BuilderState = "BuilderState",
}

export enum ESCostDisplayUserType {
    Users = "Users",
    Infra = "Infra service",
}

export enum ESCostBillingType {
    Azure = "Azure",
    Lab = "Lab",
    CloudBuild = "CloudBuild",
}

export enum ESCostOrganization {
    OfficeEngineering = "Office Engineering",
    SubstrateEngineering = "Substrate Engineering",
}

export enum ESCostUseScenario {
    Build = "Build",
    Validation = "Validation",
}

export enum ESOverviewTimeSelection {
    LastFY = "lastFY",
    CurrentFY = "currentFY",
}

export enum ESArtifactDataType {
    Cost = "Cost",
    Workload = "Workload",
}

export enum ESCostDataDimensions {
    BillingType = "BillingType",
    Organization = "Organization",
    Cost = "CostInUsd",
    TimeStamp = "TimestampUTC",
    FiscalYear = "FiscalYearly",
    ReturnFiscalYear = "FiscalYear",
    Budget = "BudgetInUsd",
    Capacity = "Capacity",
    UserName = "UserName",
    UserType = "UserType",
    Repository = "Repository",
    ReturnType = "ReturnType",
    UsageScenario = "UsageScenario",
    BuildRole = "BuildRole",
    Count = "Count",
    BuildStage = "BuildStage",
    BuildQueue = "BuildQueue",
    StampID = "StampID",
    Category = "Category",
    Retention = "Retention",
    ArtifactWorkload = "ArtifactWorkload",
    Level = "Level",
    Name = "Name",
    DataSource = "DataSource",
    Region = "Region",
    Service = "Service",
    SubscriptionId = "SubscriptionId",
    SubscriptionMappingId = "Id",
    SubscriptionName = "Name",
}

export enum ESUsageScenarios {
    Gardener = "Gardener",
    Other = "Other",
    PRbuild = "PR build",
    CI = "CI",
    AutoMerge = "Auto Merge",
    O365Precheckin = "O365-Precheckin",
    CloudBuildSystemValidation = "Cloud Build System Validation",
    StandardBuild = "Standard Build",
    BreakDetector = "Break Detector",
    OfficePackageUpdater = "Office Package Updater",
    Synthetic = "Synthetic",
    AutoPublishPackages = "Auto Publish Packages",
    LKG = "LKG",
    LocalCloudBuild = "LocalCloudBuild",
    FocusShadow = "Focus-Shadow",
    TouchDownBuilds = "Touch Down Builds",
    DogfoodBuild = "Dogfood Build",
    IncrementalBuild = "Incremental Build",
    Heisenbug = "Heisenbug",
    Roslyn = "Roslyn",
    CacheWarmer = "CacheWarmer",
    CacheTest = "Cache Test",
    ESRPValidation = "ESRP Validation",
    TimeBomb = "TimeBomb",
    TestUsageScenarioTag = "Test_UsageScenarioTag",
    TestDemotion = "TestDemotion",
    OtherWithPipeline = "Other-WithPipeline",
    OtherWithPipelineHasADOBuildIdNo = "Other-WithPipeline-HasADOBuildIdNo",
    CodeQL = "CodeQL",
}

export enum ESManagerLevels {
    L5 = "L5Manager",
    L6 = "L6Manager",
}
