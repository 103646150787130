export interface DataNode {
    value: string;
    title: string;
    key: string;
    children?: DataNode[];
    ancestors?: DataNode[];
    category?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
}

export enum NodeState {
    Checked = 0b01,
    Unchecked = 0b10,
    Indeterminate = 0b11
}

export interface NodeWrapper {
    dataNode: DataNode;
    state: NodeState;
    checkedChildrenCount: number;
    indeterminateChildrenCount: number;
    parent?: NodeWrapper;
    children?: NodeWrapper[];
}