import * as React from "react";
import {
    ESCostBillingType,
    ESCostDataDimensions,
    ESCostOrganization,
    ESOverviewFiscalYearValues,
    ESOverviewTimeSelection,
} from "../../../models/ESCostModels";
import { useEsOverviewTimeRange } from "../../../hooks/useEsOverviewTimeRange";
import { useEsOrg } from "../../../hooks/useEsOrg";
import { useEsCostResource } from "../../../hooks/useEsCostResource";
import { ESCostUtils } from "../Utilities/ESCostUtils";
import PieChart, { IPieChartData } from "./Common/PieChart";
import { ESCostResource, ESOrgs } from "../../../models/constants/ESCostConstants";

type IOverviewCost = IPieChartData & {
    [key in ESCostBillingType]?: number;
};

interface IOverviewChartProps {
    data: ESOverviewFiscalYearValues[];
}

function calculateOverviewCost(
    org: ESCostOrganization[],
    billingType: ESCostBillingType[],
    year: number,
    rawCost: ESOverviewFiscalYearValues[]
): IOverviewCost {
    const costs = rawCost.filter(
        (cost) =>
            ESCostResource.includes(cost[ESCostDataDimensions.BillingType]) &&
            ESOrgs.includes(cost[ESCostDataDimensions.Organization]) &&
            (billingType.length === 0 || billingType.includes(cost[ESCostDataDimensions.BillingType])) &&
            (org.length === 0 || org.includes(cost[ESCostDataDimensions.Organization])) &&
            year === cost[ESCostDataDimensions.ReturnFiscalYear]
    );

    const costSum = costs.reduce(
        (acc, curr) => {
            const { BillingType, CostInUsd } = curr;
            if (BillingType!== undefined && CostInUsd !== undefined) {
                acc[BillingType] = (acc[BillingType] || 0) + CostInUsd;
            }
            return acc;
        },
        { [ESCostBillingType.Azure]: 0, [ESCostBillingType.Lab]: 0, [ESCostBillingType.CloudBuild]: 0 }
    );

    if (billingType.length === 0) {
        return costSum as IOverviewCost;
    } else {
        return Object.fromEntries(Object.entries(costSum).filter(([key]) => billingType.includes(key as ESCostBillingType)));
    }
}

const OverviewChart: React.FC<IOverviewChartProps> = (props) => {
    const { data: rawCost } = props;
    const [org] = useEsOrg();
    const [billingType] = useEsCostResource();
    const [timeRange] = useEsOverviewTimeRange();

    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );

    const esCost = React.useMemo(() => calculateOverviewCost(org, billingType, year, rawCost), [billingType, org, rawCost, year]);
    const total = (esCost[ESCostBillingType.Azure] || 0) + (esCost[ESCostBillingType.CloudBuild] || 0) + (esCost[ESCostBillingType.Lab] || 0);
    return (
        <PieChart
            data={esCost}
            description="Cost via billing resources"
            totalCountDesc="Total Cost"
            isCurrency={true}
            total={total} />
    );
};

export default OverviewChart;
