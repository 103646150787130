/** Renders the form for the resource information section of the project form:
 * - Resource type (VCore, Memory, etc.)
 * - Platform (AzSC, COSMIC, etc.)
 * - Service ID or Subscription ID
 */
import { PlatformTypeEnum, CopsResourceTypeEnum, IEfficiencyProjectForm, IErrorDict } from '../../../../../models/EfficiencyTracker';
import React from 'react';
import styles from "./Forms.less";

import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import { Stack } from '@fluentui/react/lib/Stack';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { Updater } from 'use-immer';
import { Label } from '@fluentui/react/lib/Label';
import { IconButton, MessageBar, MessageBarType } from '@fluentui/react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { OptionType, getLoadOptions } from '../SelectUtils';
import { useServiceIDs, useSubscriptionIDs } from '../../../../../hooks/useEfficiencyTrackerProject';
import { IServiceIdName, ISubscription } from '../../../../../models/serviceTree';
import { MultiValue } from 'react-select';


interface IProps {
    formData: IEfficiencyProjectForm;
    updateFormData: Updater<IEfficiencyProjectForm>;
    errorDict: IErrorDict;
    updateErrorDict: Updater<IErrorDict>;
    editMode: boolean;
}

interface CustomStyles {
    multiValue: (base: Record<string, any>) => Record<string, any>;
    control: (base: Record<string, any>) => Record<string, any>;
    menuList: (base: Record<string, any>) => Record<string, any>;
    valueContainer: (base: Record<string, any>) => Record<string, any>;
    container: (base: Record<string, any>) => Record<string, any>;
}

const customAsyncPaginateStyles: CustomStyles = {
    multiValue: (base) => ({ ...base, maxWidth: 250 }),
    control: (base) => ({ ...base, minWidth: 600 }),
    menuList: (base) => ({ ...base, maxHeight: 600 }),
    valueContainer: (base) => ({ ...base, maxHeight: 600, overflow: 'auto' }),
    container: (base) => ({ ...base, maxWidth: 600, flex: 1 }),
};

const ResourceInfo = ({ formData, updateFormData, errorDict, updateErrorDict, editMode }: IProps) => {

    /** Load value selection function for service id */
    const servicesToSelectOptions = React.useCallback(function (sevs: IServiceIdName[]) {
        return sevs.map((sevs) => {
            return { value: sevs.serviceId, label: `${sevs.serviceId} | (${sevs.serviceName})` };
        });
    }, []);

    const serviceDropDownList = servicesToSelectOptions(useServiceIDs());

    /** Load value selection function for subscription id */
    const subscriptionsToSelectOptions = React.useCallback(function (subs: ISubscription[]) {
        return subs.map((sub) => {
            return { value: sub.subscriptionId, label: `${sub.subscriptionId} | (${sub.subscriptionName})` };
        });
    }, []);

    const subscriptionDropDownList = subscriptionsToSelectOptions(useSubscriptionIDs());

    return (
        <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: 808 } }} >

            <div className={styles.formTitle}>{"New Plan: Select resources for " + formData.ProjectName}</div>


            <ChoiceGroup
                label="Resource type"
                selectedKey={formData.ResourceType}
                options={[
                    { key: CopsResourceTypeEnum.VCore, text: CopsResourceTypeEnum.VCore },
                ]}
                onChange={(_, newOption) => {
                    if (newOption) {
                        updateFormData(formData => {
                            formData.ResourceType = newOption.key as CopsResourceTypeEnum;
                        });
                    }
                }}
                disabled={editMode}
                required
            />


            <Dropdown
                styles={{ dropdown: { width: 300 } }}
                placeholder="Select the platform"
                label="Platform"
                onRenderLabel={() => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>Platform</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title="The platform your project is deployed on"
                                className={styles.ExplainIcon}
                            />
                        </Stack>
                    );
                }}
                selectedKey={formData.Platform}
                options={[
                    { key: PlatformTypeEnum.Public, text: PlatformTypeEnum.Public },
                    { key: PlatformTypeEnum.AzSC, text: PlatformTypeEnum.AzSC },
                    { key: PlatformTypeEnum.COSMIC, text: PlatformTypeEnum.COSMIC },
                ]}
                onFocus={() => {
                    updateErrorDict(errorDict => {
                        if ("PlatformChoiceError" in errorDict) {
                            delete errorDict.PlatformChoiceError;
                        }
                    });
                }}
                onChange={(_, option) => {
                    updateFormData(formData => {
                        formData.Platform = option ? option.key as PlatformTypeEnum : undefined;
                    });
                }}
                errorMessage={("PlatformChoiceError" in errorDict) ? errorDict.PlatformChoiceError : ""}
                disabled={editMode}
            />

            <Stack>
                <Stack horizontal verticalAlign="center">
                    <Label styles={{ root: { paddingRight: 0 } }} required>Service ID(s)</Label>
                    <IconButton
                        iconProps={{ iconName: 'Info' }}
                        title="Select the service ID(s) for the service(s) you want to track. You can select multiple service IDs."
                        className={styles.ExplainIcon}
                    />
                </Stack>
                <AsyncPaginate
                    isClearable
                    isSearchable
                    isMulti
                    name="Service ID"
                    placeholder="Enter the service ID"
                    value={formData.ServiceID.map(id => ({ value: id, label: id }))}
                    loadOptions={getLoadOptions(serviceDropDownList)}
                    closeMenuOnSelect={false}
                    onChange={(newValue: MultiValue<OptionType>) => {
                        updateFormData(formData => {
                            formData.ServiceID = newValue ? newValue.map(option => option.value) : [];
                        });
                    }}
                    styles={customAsyncPaginateStyles}
                    onFocus={() => {
                        updateErrorDict(errorDict => {
                            if ("ServiceSubscriptionIdError" in errorDict) {
                                delete errorDict.ServiceSubscriptionIdError;
                            }
                        });
                    }}
                    aria-errormessage={("ServiceSubscriptionIdError" in errorDict) ? errorDict.ServiceSubscriptionIdError : ""}
                    isDisabled={editMode}
                    required
                />
                {("ServiceSubscriptionIdError" in errorDict) ?
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        styles={{ root: { width: 600 } }}
                    >
                        {errorDict.ServiceSubscriptionIdError}
                    </MessageBar> : null}
            </Stack>

            {formData.Platform !== PlatformTypeEnum.COSMIC &&
                (<Stack>
                    <Stack horizontal verticalAlign="center">
                        <Label styles={{ root: { paddingRight: 0 } }} required>Subscription ID(s)</Label>
                        <IconButton
                            iconProps={{ iconName: 'Info' }}
                            title="Select the subscription ID(s) for the service(s) you want to track. You can select multiple subscription IDs."
                            className={styles.ExplainIcon}
                        />
                    </Stack>
                    <AsyncPaginate
                        isClearable
                        isSearchable
                        isMulti
                        name="Subscription ID"
                        placeholder="Enter the subscription ID"
                        value={formData.SubscriptionId.map(id => ({ value: id, label: id }))}
                        loadOptions={getLoadOptions(subscriptionDropDownList)}
                        closeMenuOnSelect={false}
                        onChange={(newValue: MultiValue<OptionType>) => {
                            updateFormData(formData => {
                                formData.SubscriptionId = newValue ? newValue.map(option => option.value) : [];
                            });
                        }}
                        styles={customAsyncPaginateStyles}
                        onFocus={() => {
                            updateErrorDict(errorDict => {
                                if ("ServiceSubscriptionIdError" in errorDict) {
                                    delete errorDict.ServiceSubscriptionIdError;
                                }
                            });
                        }}
                        aria-errormessage={("ServiceSubscriptionIdError" in errorDict) ? errorDict.ServiceSubscriptionIdError : ""}
                        isDisabled={editMode}
                        required
                    />
                    {("ServiceSubscriptionIdError" in errorDict) ?
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            styles={{ root: { width: 600 } }}
                        >
                            {errorDict.ServiceSubscriptionIdError}
                        </MessageBar> : null}
                </Stack>)}

        </Stack>

    );
}

export default ResourceInfo;