// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LRctSTvU1elv9WhDcByi {\n  overflow-y: auto;\n  padding: 48px;\n  flex: 1 1 80%;\n  overflow-x: hidden;\n}\n.qCOp0YG3RqT8PxQz64mr {\n  margin-bottom: 24px;\n}\n.g8xu4R6MqSkfzp27ivix {\n  display: grid;\n  grid-template-columns: repeat(2, calc(50% - 20px));\n  column-gap: 40px;\n}\n.h8IxLRg79I7X21HHS6TQ {\n  display: grid;\n  grid-template-columns: 60% 40%;\n  column-gap: 40px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ESCost/Views/Page.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,aAAA;EACA,kBAAA;AACJ;AAEA;EACI,mBAAA;AAAJ;AAGA;EACI,aAAA;EACA,kDAAA;EACA,gBAAA;AADJ;AAIA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;AAFJ","sourcesContent":[".page {\n    overflow-y: auto;\n    padding: 48px;\n    flex: 1 1 80%;\n    overflow-x: hidden;\n}\n\n.header {\n    margin-bottom: 24px;\n}\n\n.grid {\n    display: grid;\n    grid-template-columns: repeat(2, calc(50% - 20px));\n    column-gap: 40px;\n}\n\n.gridForSingleCell {\n    display: grid;\n    grid-template-columns: 60% 40%;\n    column-gap: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "LRctSTvU1elv9WhDcByi",
	"header": "qCOp0YG3RqT8PxQz64mr",
	"grid": "g8xu4R6MqSkfzp27ivix",
	"gridForSingleCell": "h8IxLRg79I7X21HHS6TQ"
};
export default ___CSS_LOADER_EXPORT___;
