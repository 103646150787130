import EmptyState from "../../common/state/EmptyState";
import React from "react";
import { Stack } from "@fluentui/react";
import { AzureWikiLink, SubstrateV2WikiLink } from "../../../models/constants/PcmV2Constants";
import commonStyles from "../../common/styles/Common.less";
import styles from "./EmptyCostView.less";

interface IEmptyCostViewProps {
    type?: "substrate" | "azure";
}

export const EmpytCostView: React.FC<IEmptyCostViewProps> = ({type}: IEmptyCostViewProps) => {
    return (
        <Stack className={styles.emptyCostView} horizontalAlign="center">
            <EmptyState />
            <div className={styles.description}>The selected service does not have cost.</div>
            <a href={type === "azure" ? AzureWikiLink : SubstrateV2WikiLink} className={commonStyles.link} target="_blank" rel="noreferrer">
                {type === "azure" ? "Azure COGS Wiki" : "Substrate COGS Wiki"}
            </a>
        </Stack>
    );
};
