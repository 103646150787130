import * as React from "react";

import Highcharts, { Point } from "highcharts";
import { chartLegendOptions, chartStyles, chartTooltipOptions, onChartPointLegendClick, onChartSeriesLegendClick } from "../../utils/chartOptions";

import CostCardTooltipContent from "./costCard/CostCardTooltipContent";
import HighchartsReact from "highcharts-react-official";
import { INotification } from "../../models/NotificationModels";
import { Icon } from "@fluentui/react";
import { getAnomalyDescription } from "../../utils/AnomalyDetectionUtils";
import moment from "moment";
import { renderToString } from "react-dom/server";

export interface IDonutChartProps {
    data: { name: string; y: number; color?: string; anomalies?: INotification[] }[];
    dateRange: [moment.Moment, moment.Moment];
    containerProps?: React.HTMLProps<HTMLElement>;
}

export const DonutChart: React.FC<IDonutChartProps> = (props) => {
    const options: Highcharts.Options = {
        chart: {
            type: "pie",
            style: chartStyles,
            height: 280,
        },
        title: {
            text: undefined,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            ...chartTooltipOptions,
            outside: true,
            formatter() {
                const dataOption = props.data.find((item) => item.name === this.point.name);

                return renderToString(
                    <CostCardTooltipContent
                        items={[{ title: this.point.name || "", cost: this.y, color: String(this.color) || 0 }]}
                        date={props.dateRange}
                        errors={dataOption?.anomalies?.map((event) => ({
                            title: event.category,
                            message: getAnomalyDescription(event.category, event.affectedMetrics, event.startDate, event.endDate, event.dataSourceName),
                        }))}
                    />
                );
            },
        },
        legend: {
            ...chartLegendOptions,
            enabled: true,
        },
        series: [
            {
                type: "pie",
                innerSize: "60%",
                data: props.data,
                states: {
                    hover: {
                        halo: {
                            size: 0,
                        },
                    },
                },
            },
        ],
        plotOptions: {
            pie: {
                size: 176,
                shadow: false,
                showInLegend: true,
                dataLabels: {
                    distance: 10,
                    formatter() {
                        const percentage = `${this.percentage.toFixed(2)}%`;
                        const dataOption = props.data.find((item) => item.name === this.point.name);

                        if (dataOption?.anomalies?.length) {
                            return renderToString(
                                <>
                                    {percentage} <Icon iconName="warning" style={{ color: "#D83B01", fontStyle: "normal" }} />
                                </>
                            );
                        }

                        return percentage;
                    },
                },
                point: {
                    events: {
                        legendItemClick: onChartPointLegendClick,
                    },
                },
            },
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} containerProps={props.containerProps} />;
};
