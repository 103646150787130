import BarChart, { IBarChartProps } from "./BarChart";
import CostCard, { CostCardProps } from "../common/costCard/CostCard";
import { DonutChart, IDonutChartProps } from "../common/DonutChart";
import { EmptyModule, IEmptyModuleProps } from "../common/state/EmptyModule";
import { ISectionProps, Section } from "../common/Section";
import LineChart, { LineChartProps } from "../common/LineChart";
import React, { useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {IconButton, IIconProps} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import html2canvas from "html2canvas";
import useEventBus from "../../hooks/useEventBus";
import { EventTypeEnum, OpenFeedbackPanelEvent } from "../../models/Event";

import { BannerModel } from "../../models/BannerModels";
import { PointOptionsObject } from "highcharts";
import { Stack } from "@fluentui/react";
import { WarningBanner } from "../banner/WarningBanner";
import styles from "./ChartContainer.less";
import FeedbackPanel from "../feedback/FeedbackPanel";
import { useFlights } from "../../hooks/useSettings";
import { EventType, LogComponent, LogElement, LogTarget } from "../../models/LogModel";
import { ICustomProperties } from "@microsoft/applicationinsights-web";
import { InsightsChartDescription } from "../substrateV2/storeIOAnomaly/InsightsChartDescription";
import { trackEventCallback } from "../../utils/AppInsights";

const feedbackIconProps: IIconProps = { iconName: "Feedback" };
interface IBaseChartContainerProps extends ISectionProps {
    cards?: CostCardProps[];
    isEmpty?: boolean;
    emptyModuleProps?: IEmptyModuleProps;
    banner?: BannerModel;
    logProps?: {
        logElement: LogElement;
        logComponent: LogComponent;
        displayName?: string;
        logTarget?: LogTarget;
        customProperties?: ICustomProperties;
    }
}

export interface IBarChartContainerProps extends IBaseChartContainerProps {
    chartType?: "bar";
    chartProps?: IBarChartProps;
    explainTextByDim?: Map<string, string>;
    isInsightsChartByDim?: boolean;
    summaryDescriptionText?: string;
    isSummaryInsightsChart?: boolean;
}

export interface ILineChartContainerProps extends IBaseChartContainerProps {
    chartType?: "line";
    chartProps?: LineChartProps;
    explainTextByDim?: Map<string, string>;
    isInsightsChartByDim?: boolean;
    summaryDescriptionText?: string;
    isSummaryInsightsChart?: boolean;
}

export interface IDonutChartContainerProps extends IBaseChartContainerProps {
    chartType?: "donut";
    chartProps?: IDonutChartProps;
    explainTextByDim?: Map<string, string>;
    isInsightsChartByDim?: boolean;
    summaryDescriptionText?: string;
    isSummaryInsightsChart?: boolean;
}

export type IChartContainerProps = IBarChartContainerProps | ILineChartContainerProps | IDonutChartContainerProps;

const ChartContainer: React.FC<IChartContainerProps> = (props) => {
    const { instance } = useMsal();
    const { data: flights } = useFlights();
    const account = instance.getActiveAccount();
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');
    const [currentScreenshotURL, setCurrentScreenshotURL] = useState<string>('');
    const { cards, ...sectionProps } = props;
    const title = props.headerProps.title;

    const captureScreenshot = async () => {
        trackEventCallback(props.logProps?.logComponent || LogComponent.FeedbackChart, props.logProps?.logElement || LogElement.SendFeedback, "Send Feedback Chart", LogTarget.Button, undefined, EventType.Click);

        const url = window.location.href;
        setCurrentURL(url);

        await openFeedbackPanel();

        const targetElement = document.getElementById(`FeedbackSection_${title}`);

        if (targetElement) {
            setTimeout(() => {
                html2canvas(targetElement).then((canvas) => {
                    const screenshotDataUrl = canvas.toDataURL('image/png');
                    setCurrentScreenshotURL(screenshotDataUrl);
                });
            }, 100);
            
        }
    };

    const handleDismissFeedbackPanel = () => {
        setCurrentScreenshotURL('');
        dismissFeedbackPanel();
    };

    const renderChart = () => {
        if (!props.chartType || !props.chartProps) {
            return null;
        }

        if (props.chartType === "bar") {
            return <BarChart {...props.chartProps} />;
        } else if (props.chartType === "line") {
            return <LineChart {...props.chartProps} />;
        } else if (props.chartType === "donut") {
            return <DonutChart {...props.chartProps} />;
        }
    };

    const emptyModuleName = useMemo(() => {
        switch (props.chartType) {
            case "bar":
                return "EmptyBarChart";
            case "line":
                return "EmptyLineChart";
            case "donut":
                return "EmptyDonutChart";
            default:
                return "";
        }
    }, [props.chartType]);

    const isEmpty = useMemo(() => {
        switch (props.chartType) {
            case "bar":
                return !props.chartProps?.series?.find((s) => s.data?.find((value) => (value as PointOptionsObject)?.y));
            case "line":
                return !props.chartProps?.series?.find((s) => s.data?.find(([_, value]) => value));
            case "donut":
                return !props.chartProps?.data?.find((d) => d.y);
            default:
                return false;
        }
    }, undefined);

    useEventBus<OpenFeedbackPanelEvent>(EventTypeEnum.OpenFeedbackPanel, openFeedbackPanel, []);

    return (
        <div className={styles.cardContainer} id={`FeedbackSection_${title}`} >
            {flights?.enableUserFeedback &&
                <IconButton
                title="Send feedback"
                className={styles.feedbackIcon}
                iconProps={feedbackIconProps}
                onClick={captureScreenshot}
            />}
            <Section {...sectionProps}>
                {isEmpty ? (
                    <EmptyModule iconName={emptyModuleName} />
                ) : (
                    <>
                        {props.banner && (
                            <div className={styles.bannerWrapper}>
                                <WarningBanner bannerItem={props.banner} />
                            </div>
                            )}
                        {props.isInsightsChartByDim && (
                            <div style={{paddingBottom:30, paddingTop:0, fontFamily:"Segoe UI"}}>
                                <InsightsChartDescription metric={props.explainTextByDim?.get("metric") || ""} cost={props.explainTextByDim?.get("cost") || ""} rca={props.explainTextByDim?.get("rca") || ""}></InsightsChartDescription>
                            </div>
                        )}
                        {props.isSummaryInsightsChart && (
                            <div style={{paddingBottom:30, paddingTop:0, fontFamily:"Segoe UI"}}>
                                <span>{props.summaryDescriptionText}</span>
                            </div>
                        )}
                        <Stack horizontal>
                            {cards?.map((card) => (
                                <Stack.Item key={card.title} className={styles.cardWrapper}>
                                    <CostCard {...card} />
                                </Stack.Item>
                            ))}
                        </Stack>
                        {renderChart()}
                        {props.children}
                    </>
                )}
            </Section>
            <FeedbackPanel
                isPanelOpen={isFeedbackPanelOpen}
                onDismiss={handleDismissFeedbackPanel}
                userName={account?.username}
                currentURL={currentURL}
                screenshotInputURL={currentScreenshotURL}
                FeedbackTab={props.logProps?.logComponent}
            />
        </div>
    );
};

export default ChartContainer;
