import React from "react";
import moment from "moment";
import { useEsOrg } from "../../../../hooks/useEsOrg";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import {
    ESOverviewTimeSelection,
    ESCostDataDimensions,
    ESCostOrganization,
    ESWeeklyBuilderStateCountRawResponse,
    ESCloudBuildOverviewCapacityRawResponse,
} from "../../../../models/ESCostModels";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import StackedAreaChart, { IStackedAreaChartData } from "../Common/StackedAreaChart";
import { ESOrgs } from "../../../../models/constants/ESCostConstants";

interface IWeeklyBuilderStateTrendingProps {
    data: ESWeeklyBuilderStateCountRawResponse[];
    capacityData: ESCloudBuildOverviewCapacityRawResponse[];
}

function getDates(data: ESWeeklyBuilderStateCountRawResponse[], fy?: ESOverviewTimeSelection): string[] {
    const dates = fy === ESOverviewTimeSelection.LastFY ? ESCostUtils.getLastFiscalYearDates() : ESCostUtils.getCurrentFiscalYearDates();
    return [
        ...new Set(
            data
                .filter((d) => {
                    const date = moment(d[ESCostDataDimensions.TimeStamp]);
                    return date >= dates[0] && date < dates[1];
                })
                .map((d) => d[ESCostDataDimensions.TimeStamp])
        ),
    ].sort();
}

function reduceCounts(costs: ESWeeklyBuilderStateCountRawResponse[], dates: string[], total: number): IStackedAreaChartData[] {
    const result = ["Idle", "Active"].map((state) => ({ name: state, data: Array.from({ length: dates.length }, () => 0) }));

    costs.forEach((cost) => {
        const j = dates.indexOf(cost[ESCostDataDimensions.TimeStamp]);
        result[1].data[j] += cost[ESCostDataDimensions.Count];
        // It's possible that active builders count is bigger than the capacity...
        const v = total - result[1].data[j];
        result[0].data[j] = v > 0 ? v : 0;
    });

    return result;
}

function calculateCounts(
    org: ESCostOrganization[],
    dates: string[],
    data: ESWeeklyBuilderStateCountRawResponse[],
    total: number,
): IStackedAreaChartData[] {
    const costs = data.filter(
        (cost) =>
            ESOrgs.includes(cost[ESCostDataDimensions.Organization]) &&
            (org.length === 0 || org.includes(cost[ESCostDataDimensions.Organization])) &&
            dates.includes(cost[ESCostDataDimensions.TimeStamp])
    );

    return reduceCounts(costs, dates, total);
}

function calculateTotal(
    year: number,
    org: ESCostOrganization[],
    data: ESCloudBuildOverviewCapacityRawResponse[]
): number {
    const capacity = data.filter(
        (d) =>
            ESOrgs.includes(d[ESCostDataDimensions.Organization]) &&
            (org.length === 0 || org.includes(d[ESCostDataDimensions.Organization])) &&
            year === d[ESCostDataDimensions.ReturnFiscalYear]
    );
    return capacity.reduce((acc, curr) => acc + curr[ESCostDataDimensions.Capacity], 0);
}

const WeeklyActiveBuilderCountChart = (props: IWeeklyBuilderStateTrendingProps) => {
    const { data: rawData, capacityData } = props;
    const [org] = useEsOrg();
    const [timeRange] = useEsOverviewTimeRange();

    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );
    const dates = React.useMemo(() => getDates(rawData, timeRange), [rawData, timeRange]);
    const total = React.useMemo(() => calculateTotal(year, org, capacityData), [org, capacityData, year]);
    const counts = React.useMemo(() => calculateCounts(org, dates, rawData, total), [dates, org, rawData, total]);

    return (
        <StackedAreaChart
            data={counts}
            dates={{ dates, format: "MMM DD, YYYY", step: 5 }}
            description={`Weekly CloudBuild builder count (FY${String(year).substring(2)})`}
            yAxisTitle="Actual count"
            isCurrency={false}
            tooltipTitle="Total count"
            hasBenchmark={false}
            hideStackLabels={true}
        />
    );
};

export default WeeklyActiveBuilderCountChart;
