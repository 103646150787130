// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tZ5kCCDVCezG7RtMzYG7 {\n  padding: 16px 24px 20px;\n}\n.HflEKykyF5vm8yJ8Xf7O {\n  display: inline-block;\n}\n.HflEKykyF5vm8yJ8Xf7O .ms-Icon {\n  color: unset;\n}\n.nGFzWgQc8IUT68LKZlnk {\n  padding-bottom: 20px;\n}\n.nGFzWgQc8IUT68LKZlnk .ms-TextField-errorMessage {\n  position: absolute;\n}\n.ms-DatePicker-goToday {\n  right: 13px;\n}\n.pqrFsDPElbrDSQad1OUw {\n  padding: 0;\n  height: 32px;\n}\n.pD3n67RsuQhpfE4xyskG {\n  right: 8px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/DateRangePicker/DateRangePicker.less"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;AAEA;EACI,qBAAA;AAAJ;AADA;EAIQ,YAAA;AAAR;AAIA;EACI,oBAAA;AAFJ;AAKA;EACI,kBAAA;AAHJ;AAMA;EACI,WAAA;AAJJ;AAOA;EACI,UAAA;EACA,YAAA;AALJ;AAQA;EACI,qBAAA;AANJ","sourcesContent":[".callout {\n    padding: 16px 24px 20px;\n}\n\n.buttonArea {\n    display: inline-block;\n\n    :global(.ms-Icon) {\n        color: unset;\n    }\n}\n\n.endDatePickerArea {\n    padding-bottom: 20px;\n}\n\n.endDatePickerArea :global(.ms-TextField-errorMessage) {\n    position: absolute;\n}\n\n:global(.ms-DatePicker-goToday) {\n    right: 13px;\n}\n\n.commandBar {\n    padding: 0;\n    height: 32px;\n}\n\n.calloutBeak {\n    right: 8px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"callout": "tZ5kCCDVCezG7RtMzYG7",
	"buttonArea": "HflEKykyF5vm8yJ8Xf7O",
	"endDatePickerArea": "nGFzWgQc8IUT68LKZlnk",
	"commandBar": "pqrFsDPElbrDSQad1OUw",
	"calloutBeak": "pD3n67RsuQhpfE4xyskG"
};
export default ___CSS_LOADER_EXPORT___;
