import styles from './ConclusionBar.less';

import * as React from 'react';

interface IConclusionBar {
    title: string;
    content: string;
    trendFlag?: boolean;
    trend?: string;
}

const ConclusionBar: React.FC<IConclusionBar> = (props) => {
    const selectColor = props.trendFlag !== undefined ? ( props.trendFlag ? styles.red : styles.green ) : styles.black;

    return (
        <div>
            <div className={styles.title}>
                {props.title}
            </div>
            <div className={`${styles.content} ${selectColor}`} style={{ display: 'flex', alignItems: 'center' }}>
                <div>{props.content}</div>
                {props.trend && (
                    <>
                        <div className={`${styles.trend} ${selectColor}`}>
                            {props.trend}
                        </div>
                        {props.trendFlag !== undefined && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                                <path d={props.trendFlag ? "M5.5 0.5L0 9.66667H11L5.5 0.5Z" : "M5.5 11.5L0 2.33333H11L5.5 11.5Z"} fill={props.trendFlag ? "#990000" : "#107C10"} />
                            </svg>
                        )}
                    </>
                   
                )}
            </div>
        </div>
    );
};

export default ConclusionBar;
