import React, { useEffect, useState } from 'react';
import LineChart from '../../../common/LineChart';
import LoadingState from '../../../ResponseBoundary/LoadingState';

interface LinearChartProps {
    isLoading: boolean;
    targetData: [number, number][];
    actualData: [number, number][];
    gapData: [number, number][];
    chartMaxXAxis: number;
}

const BigBetsLinearChart: React.FC<LinearChartProps> = (props) => {
    const [xAxisOptions, setXAxisOptions] = useState<Highcharts.XAxisOptions>();

    useEffect(() => {
        if (props.isLoading) {
            return;
        }
        const xAxisOptionsTmp: Highcharts.XAxisOptions = {
            max: props.chartMaxXAxis,
        }

        setXAxisOptions(xAxisOptionsTmp);
    }, [props]);


    return (
        <>
             {
                !props.isLoading ?
                    <div style={{ height: '250px' }}>
                        <LineChart
                            minValue={0}
                            series={[
                                {
                                    name: "Target",
                                    data: props.targetData,
                                    color: "#0078D4",
                                    type: "area",
                                },
                                {
                                    name: "Actual Value",
                                    data: props.actualData,
                                    color: "#107C10",
                                    type: "area",
                                },
                            ]}
                            ignoreWeekend={false}
                            isNotCurrency={false}
                            dateFormatStr={"M/D/YY"}
                            xAxis={xAxisOptions}
                        />
                    </div>
                     :
                    <LoadingState />
            }
        </>
    );
};

export default BigBetsLinearChart;
