import {
    AzureRegionDetailsColumnNames,
    AzureServiceDetailsColumnNames,
    AzureSubscriptionDetailsColumnNames,
} from "../../components/azure/common/AzureComputeConstants";
import { IAzureComputeRegionDetail, IAzureComputeServiceDetail, IAzureComputeSubscriptionDetail } from "../../models/AzureComputeModels";
import { PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum } from "../../models/PcmV2Models";
import { SubstrateCostTableColumnId, SubstrateCostTableDefaultHiddenColumns } from "../../components/substrateV2/common/table/SubstrateDetailTableConfig";

import { CategoryDivision } from "../../models/CategoryDivision";
import { LocalStorageUtils } from "../LocalStorageUtils";
import { SubstrateEntityMetrics } from "../../models/SubstrateModels";
import { difference, set } from "lodash";
import { msalInstance } from "../msalInstance";

export interface ISearchHistoryLog {
    category?: CategoryDivision;
    value: string;
}

export interface ITablePreference<Keys extends any[]> {
    hiddenColumns: Keys;
    version?: string;
}

export interface IUserPreference {
    version?: string;

    searchHistory?: ISearchHistoryLog[];

    substrateV1OverviewTable?: ITablePreference<SubstrateEntityMetrics[]>;

    azureComputeServiceDetail?: ITablePreference<(keyof IAzureComputeServiceDetail)[]>;
    azureComputeRegionDetail?: ITablePreference<(keyof IAzureComputeRegionDetail)[]>;
    azureComputeSubscriptionDetail?: ITablePreference<(keyof IAzureComputeSubscriptionDetail)[]>;

    [key: string]: any;
}

export class UserPreferenceUtils {
    private static getLocalStorageDataKey() {
        return `user_preference_${msalInstance.getActiveAccount()?.localAccountId || "default"}`;
    }

    private static MaxSearchHistorySize = 10;

    private static LatestVersion = "2022.12.22.01";

    private static defaultUserPreference: IUserPreference = {
        azureComputeServiceDetail: {
            hiddenColumns: difference(AzureServiceDetailsColumnNames, [
                "serviceName",
                "totalCores",
                "reclaimableCores",
                "idleCoresP99",
                "cpuP99",
                "networkP99",
                "serviceDevOwners",
                "servicePMOwners",
            ]),
        },
        azureComputeRegionDetail: {
            hiddenColumns: difference(AzureRegionDetailsColumnNames, [
                "serviceName",
                "subscriptionCounts",
                "region",
                "totalCores",
                "reclaimableCores",
                "idleCoresP99",
                "cpuP99",
                "networkP99",
                "serviceDevOwners",
                "servicePMOwners",
            ]),
        },
        azureComputeSubscriptionDetail: {
            hiddenColumns: difference(AzureSubscriptionDetailsColumnNames, [
                "subscriptionName",
                "subscriptionId",
                "deploymentId",
                "sku",
                "totalCores",
                "reclaimableCores",
                "idleCoresP99",
                "cpuP99",
                "networkP99",
                "serviceDevOwners",
                "servicePMOwners",
            ]),
        },
        substrateV1OverviewTable: {
            hiddenColumns: difference(Object.values(SubstrateEntityMetrics), [
                SubstrateEntityMetrics.TotalCost,
                SubstrateEntityMetrics.TotalCarbonEmission,
                SubstrateEntityMetrics.ItemReadsCost,
                SubstrateEntityMetrics.ItemWritesCost,
                SubstrateEntityMetrics.ItemQueriesCost,
                SubstrateEntityMetrics.EBACost,
                SubstrateEntityMetrics.TBACost,
                SubstrateEntityMetrics.SWSSCost,
                SubstrateEntityMetrics.ServiceInstanceCost,
                SubstrateEntityMetrics.DsapiRequestsCost,
                SubstrateEntityMetrics.CfmSubmittedCost,
                SubstrateEntityMetrics.ItemSizeCost,
                SubstrateEntityMetrics.KvCacheCost,
                SubstrateEntityMetrics.SdsFastStorageCost,
                SubstrateEntityMetrics.RequestProcessCost,
            ]),
        },
        [PcmV2SubSceneTypeEnum.TransactionCost]: {
            version: "2023.03.06.01",
            hiddenColumns: SubstrateCostTableDefaultHiddenColumns[PcmV2SubSceneTypeEnum.TransactionCost],
        },
        [PcmV2SubSceneTypeEnum.TransactionScenarioTagCost]: {
            version: "2023.03.06.01",
            hiddenColumns: SubstrateCostTableDefaultHiddenColumns[PcmV2SubSceneTypeEnum.TransactionScenarioTagCost],
        },
    };

    public static getUserPreferenceData<Key extends keyof IUserPreference, Value = IUserPreference[Key]>(key: Key): Value | null {
        const prefer = UserPreferenceUtils.getUserPreference();
        const defaultData = this.defaultUserPreference[key];

        if (!prefer || !prefer[key]) {
            return defaultData || null;
        }

        if (defaultData?.version && prefer.version !== defaultData.version) {
            return defaultData;
        }

        return prefer[key];
    }

    public static setUserPreferenceData<Key extends keyof IUserPreference, Value = IUserPreference[Key]>(key: Key, value: Value): boolean {
        let prefer = UserPreferenceUtils.getUserPreference();

        if (!prefer) {
            prefer = {
                version: UserPreferenceUtils.LatestVersion,
            };
        }

        prefer[key] = value;

        return LocalStorageUtils.setLocalStorageData(UserPreferenceUtils.getLocalStorageDataKey(), prefer);
    }

    public static getUserPreference(): IUserPreference | null {
        const data = LocalStorageUtils.getLocalStorageData<IUserPreference>(UserPreferenceUtils.getLocalStorageDataKey());

        if (data && data.version !== UserPreferenceUtils.LatestVersion) {
            UserPreferenceUtils.removeUserPreference();
            return null;
        }

        return data;
    }

    public static removeUserPreference(): void {
        return LocalStorageUtils.removeLocalStorageData(UserPreferenceUtils.getLocalStorageDataKey());
    }

    public static addHistoryLog(value: string, category?: CategoryDivision) {
        value = value.trim();

        if (!value) {
            return;
        }

        let logs = UserPreferenceUtils.getUserPreferenceData("searchHistory");

        if (!logs) {
            logs = [{ category, value }];
        } else {
            const idx = (logs || []).findIndex((item) => item.category === category && item.value === value);
            if (idx >= 0) {
                logs.splice(idx, 1);
            }

            logs.unshift({ category, value });
        }

        UserPreferenceUtils.setUserPreferenceData("searchHistory", logs.slice(0, UserPreferenceUtils.MaxSearchHistorySize));
    }

    public static removeHistoryLog(value: string, category?: CategoryDivision) {
        const logs = UserPreferenceUtils.getUserPreferenceData("searchHistory");
        const idx = (logs || []).findIndex((item) => item.category === category && item.value === value);

        if (logs && idx >= 0) {
            logs.splice(idx, 1);
            UserPreferenceUtils.setUserPreferenceData("searchHistory", logs);
        }
    }
}
