import { IAppState } from "../store";
import { SearchMetadataV2Service } from "../services/SearchMetadataV2Service";
import { ServiceTreeItem } from "../models/serviceTree";
import { useCustomQuery } from "./useCustomQuery";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFlights } from "./useSettings";

export function useGetServiceNameScenarioTagCountMap() {
    const query = useCustomQuery(SearchMetadataV2Service.getScenarioTags);
    const serviceNameScenarioTagCountMap = useMemo(() => {
        const countMap = new Map<string, number>();

        if (query.data) {
            for (const [_, values] of query.data) {
                values.forEach((item) => {
                    countMap.set(item.serviceName, (countMap.get(item.serviceName) || 0) + 1);
                });
            }
        }

        return countMap;
    }, [query.data]);

    return serviceNameScenarioTagCountMap;
}

export function useGetAppIdNameMap() {
    const { data: flight } = useFlights();
    const query = useCustomQuery(SearchMetadataV2Service.getGriffinApps, flight?.enableSubstrateV2);

    const appIdNameMap = useMemo(() => {
        const map = new Map<string, string>();

        if (query.data) {
            for (const [name] of query.data) {
                map.set(name.substring(name.length - 37, name.length - 1), name.substring(0, name.length - 38));
            }
        }

        return map;
    }, [query.data]);

    return appIdNameMap;
}

export function useGetAppIdScenarioTagCountMap() {
    const query = useCustomQuery(SearchMetadataV2Service.getScenarioTags);
    const appIdScenarioTagCountMap = useMemo(() => {
        const countMap = new Map<string, number>();

        if (query.data) {
            for (const [_, values] of query.data) {
                values.forEach((item) => {
                    if (item.name.includes(".")) {
                        const appId = item.name.split(".")[0];
                        countMap.set(appId, (countMap.get(appId) || 0) + 1);
                    }
                });
            }
        }

        return countMap;
    }, [query.data]);

    return appIdScenarioTagCountMap;
}

export function useGetServiceNameGriffinAppCountMap() {
    const { data: flight } = useFlights();
    const query = useCustomQuery(SearchMetadataV2Service.getGriffinApps, flight?.enableSubstrateV2);

    const serviceNameGriffinAppCountMap = useMemo(() => {
        const countMap = new Map<string, number>();

        if (query.data) {
            for (const [_, values] of query.data) {
                values.forEach((item) => {
                    countMap.set(item.serviceName, (countMap.get(item.serviceName) || 0) + 1);
                });
            }
        }

        return countMap;
    }, [query.data]);

    return serviceNameGriffinAppCountMap;
}

export function useGetAppIdServiceMap() {
    const { data: flight } = useFlights();
    const query = useCustomQuery(SearchMetadataV2Service.getGriffinApps, flight?.enableSubstrateV2);
    const serviceNameMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.serviceNodeMapByName);
    const appIdServiceMap = useMemo(() => {
        const map = new Map<string, ServiceTreeItem>();

        if (query.data) {
            for (const [name, values] of query.data) {
                values.forEach((item) => {
                    if (serviceNameMap.has(item.serviceName)) {
                        map.set(name.substring(name.length - 37, name.length - 1), serviceNameMap.get(item.serviceName) as ServiceTreeItem);
                    }
                });
            }
        }

        return map;
    }, [query.data, serviceNameMap]);

    return appIdServiceMap;
}
