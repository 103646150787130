import { IIconProps, Icon, IconButton } from "@fluentui/react";
import { EventType, LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import React, { useState } from "react";

import ChartContainer from "../../chart/ChartContainer";
import { DataSourceEnum } from "../../../models/NotificationModels";
import FeedbackPanel from "../../feedback/FeedbackPanel";
import { Section } from "../../common/Section";
import commonStyles from "../../common/styles/Common.less";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { getTotal } from "../../../utils/common";
import html2canvas from "html2canvas";
import styles from "../Substrate.less";
import { useBoolean } from "@fluentui/react-hooks";
import { useFlights } from "../../../hooks/useSettings";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useGetSubstrateDailyCarbonEmission } from "../../../hooks/useSubstrateQuery";
import { useMsal } from "@azure/msal-react";
import { useTrackHovering } from "../../../hooks/useTrack";

const feedbackIconProps: IIconProps = { iconName: "Feedback" };
import { trackEventCallback } from "../../../utils/AppInsights";

const SubstrateCarbon: React.FC = () => {
    const { isLoading, data: substrateDailyCarbonEmission } = useGetSubstrateDailyCarbonEmission();
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.Substrate);
    const activeAnomalies = React.useMemo(() => getActiveAnomalies(anomaliesQuery.data, ["SubstrateCarbonEmissions"]), [anomaliesQuery.data]);
    const trackingProps = useTrackHovering(LogComponent.SubstrateChartingPane, LogElement.SubstrateTotalCarbonEmission, "Total Substrate Carbon Emission Trends", LogTarget.Chart);
    const banner = {
        message:
            "Substrate carbon accounting is in early stage and the data is subject to change. And if there're data loss in upstream data source, we will do interpolation,",
        type: "info",
        id: 1,
        tab: "Substrate",
        startDate: "2022-01-01",
        endDate: "2022-10-10",
        metric: "SubstrateCarbon",
        url: "https://microsoft.sharepoint.com/:w:/t/szo365fnd/ESUo8Z9lNf9Pv-vEgUTj2IwBr3pR167bCHe5DAzuR6bG6A?e=SEFBxi",
        urlText: "More",
        logComoonent: LogComponent.SubstrateChartingPane,
        logElement: LogElement.CarbonMore,
        displayName: "More",
    };

    // User Feedback
    const { instance } = useMsal();
    const { data: flights } = useFlights();
    const account = instance.getActiveAccount();
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');
    const [currentScreenshotURL, setCurrentScreenshotURL] = useState<string>('');

    const captureScreenshot = async (title: string) => {
        trackEventCallback(LogComponent.Substrate, LogElement.SubstrateTotalCarbonEmission, "Send Feedback Chart", LogTarget.Button, undefined, EventType.Click);

        const url = window.location.href;
        setCurrentURL(url);

        await openFeedbackPanel();

        const targetElement = document.getElementById(`FeedbackSection_${title}`);

        if (targetElement) {
            setTimeout(() => {
                html2canvas(targetElement).then((canvas) => {
                    const screenshotDataUrl = canvas.toDataURL('image/png');
                    setCurrentScreenshotURL(screenshotDataUrl);
                });
            }, 100);

        }
    };

    const handleDismissFeedbackPanel = () => {
        setCurrentScreenshotURL('');
        dismissFeedbackPanel();
    };

    const onLinkClick =()=> {
        trackEventCallback(LogComponent.SubstrateChartingPane, LogElement.CarbonVideo, "Learn more with a video introduction", LogTarget.Button);
    }


    return (
        <>
            <div className={styles.cardContainer} id={`FeedbackSection_CPU_UtilP99`} >
                {flights?.enableUserFeedback &&
                    <IconButton
                        title="Send feedback"
                        className={styles.feedbackIcon}
                        iconProps={feedbackIconProps}
                        onClick={() => captureScreenshot("CPU_UtilP99")}
                    />}
                <Section headerProps={{ title: "Substrate Carbon Emission Guides" }}>
                    <div className={styles.carbonGuideRow}>
                        <div className={styles.carbonGuideLeft}>
                            <Icon iconName="SubstrateCarbonGuide1" />
                        </div>
                        <div className={styles.carbonGuideRight}>
                            <div className={styles.carbonGuideSubTitle}>Understanding Carbon Emissions in Substrate</div>
                            <div className={styles.carbonGuideParagraph}>
                                Substrate is a global data and intelligence platform that powers hundreds of experiences. The methodology used to apportion carbon
                                emissions to internal services leverages this understanding to create a robust mechanism to attribute emissions. The methodology
                                works by calculating scope 2, and scope 3 carbon emissions across every Substrate rack and then apportioning the emissions to
                                services running on those racks based on their CPU usage.
                            </div>
                        </div>
                    </div>
                    <div className={styles.carbonGuideRow}>
                        <div className={styles.carbonGuideLeft}>
                            <Icon iconName="SubstrateCarbonGuide2" />
                        </div>
                        <div className={styles.carbonGuideRight}>
                            <div className={styles.carbonGuideSubTitle}>Understanding the Math Behind Microsoft’s Commitment to Become Carbon Negative by 2030</div>
                            <div className={styles.carbonGuideParagraph}>What do the Scope2 and Scope3 emissions comprise of and how are they calculated?</div>
                            <a className={commonStyles.link} href="https://youtu.be/wj0UrF2T130" onClick={onLinkClick}>
                                Learn more with a video introduction
                            </a>
                        </div>
                    </div>
                </Section>
            </div>
            
            <ChartContainer
                headerProps={{ title: "Total Substrate Carbon Emission Trends" }}
                cards={[{ title: "Total Carbon Emission", cost: getTotal(substrateDailyCarbonEmission?.data), color: "#0099BC", noPrefix: true, suffix: " mtCO2e" }]}
                loading={isLoading}
                banner={banner}
                chartType="line"
                chartProps={{
                    series: [
                        {
                            name: "Carbon Emissions",
                            data: substrateDailyCarbonEmission?.data.map((singleMetric) => [singleMetric.date.valueOf(), singleMetric.metricValue]),
                        },
                    ],
                    anomalies: activeAnomalies,
                    isNotCurrency: true,
                    height: 288,
                    suffix: " mtCO2e",
                    minValue: 0,
                    hideEmptySeries: true,
                    containerProps: trackingProps,
                }}
                logProps={{
                    logComponent: LogComponent.SubstrateV2,
                    logElement: LogElement.SubstrateTotalCarbonEmission,
                }}
            />
            <FeedbackPanel
                isPanelOpen={isFeedbackPanelOpen}
                onDismiss={handleDismissFeedbackPanel}
                userName={account?.username}
                currentURL={currentURL}
                screenshotInputURL={currentScreenshotURL}
                FeedbackTab={LogComponent.SubstrateV2}
            />
        </>
    );
};

export default SubstrateCarbon;
