import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import ShareViewBusinessOwnerDialog from "./ShareViewBusinessOwnerDialog";
import ShareViewDefaultDialog from "./ShareViewDefaultDialog";
import ShareViewList from "./ShareViewList";
import ShareViewServiceTreeDialog from "./ShareViewServiceTreeDialog";
import ShareViewSubmitDialog from "./ShareViewSubmitDialog";
import { useBoolean } from "@fluentui/react-hooks";
import { useServiceTreeBusinessOwnersQuery } from "../../../hooks/useServiceTreeQuery";

interface ShareView {
    step: number;
    updateStep: Dispatch<SetStateAction<number>>;
}

const panelWidthSetting: Record<string, string> = {
    ["Init"]: "550px",
    ["OpenEdit"]: "1000px",
};

const ShareView: React.FC<ShareView> = (props) => {
    const { updateStep } = props;
    const [viewType, setViewType] = useState<string>("ServiceTree");

    switch (props.step) {
        case 1:
            return <ShareViewDefaultDialog onViewTypeChange={setViewType} updateStep={updateStep}></ShareViewDefaultDialog>;
        case 2:
            return viewType == "ServiceTree" ? (
                <ShareViewServiceTreeDialog onCancel={setViewType} updateStep={updateStep}></ShareViewServiceTreeDialog>
            ) : (
                <ShareViewBusinessOwnerDialog onCancel={setViewType} updateStep={updateStep}></ShareViewBusinessOwnerDialog>
            );
        case 3:
            return <ShareViewSubmitDialog updateStep={updateStep}></ShareViewSubmitDialog>;
        case 4:
            return <ShareViewList onCancel={setViewType} updateStep={updateStep}></ShareViewList>;
        default:
            return <></>;
    }
};

export default ShareView;
