import { mapValues } from "lodash";
import moment, { Moment } from "moment";
import { AzureComputeDisplayNames } from "../components/azure/common/AzureComputeConstants";
import { filterItemsToHeatmapLocalFilters } from "../components/azure/common/AzureComputeUtils";
import { IFilterItem } from "../components/filter/filterList/FilterList";
import { IAzureBudgetDetails, IAzureComputeDetailData, IAzureComputeRegionDetail, IAzureComputeServiceDetail, IAzureComputeSubscriptionDetail,  IAzureWorkloadMonthlyCost as IAzureWorkloadMonthlyCost, IAzureTotalBudgetReport, IHierarchicalMetricReport, IAzureComputeDetail, IAzureSubscriptionSearchInfo  } from "../models/AzureComputeModels";
import { CategoryDivision } from "../models/CategoryDivision";
import { IDateRangeFilterData, parseFiltersToJson } from "../models/FilterView";
import { IDailyMetricReport, ISingleDayMetric } from "../models/Metric";
import { ISubscription, ServiceTreeItem, ServiceTreeLevel } from "../models/serviceTree";
import { getJson, postJson } from "../utils/apiServiceBase";
import { exportToCsv } from "../utils/common";
import { FilterGroupOperator } from "../components/filter/filterList/FilterListConstants";
import { SearchproviderV1BaseUrl } from "./serviceTreeService";

export async function getAzureDailyMetrics({queryKey}: {queryKey: [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string, string, string, boolean, string[]?]}): Promise<IDailyMetricReport> {
    const [, dateRangeFilter, filtersData, serviceIdMap, metric, azureType, aggregateType, showRemap, regions] = queryKey;
    const url = "api/v1.0/azure/metrics/dailymetrics?startDate=" + dateRangeFilter.startDate.format("YYYY-MM-DD")
        + "&endDate=" + dateRangeFilter.endDate.format("YYYY-MM-DD") + "&metric=" + metric + "&azureType=" + azureType + "&aggregationType=" + aggregateType + "&showRemap=" + showRemap;
    const response = await postJson<INativeDailyMetricReport>(url, parseFiltersToJson(filtersData, serviceIdMap, undefined, regions));

    return {
        ...response,
        startDate: moment(response.startDate),
        endDate: moment(response.endDate),
        data: response.data.map(metric => ({
            date: moment(metric.date),
            metricValue: metric.metricValue
        })).sort((a, b) => a.date.unix() - b.date.unix())
    };
}

export async function getHierarchicalMetrics({queryKey}: {queryKey: [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string, string, number, string, boolean, boolean]}): Promise<IHierarchicalMetricReport[]> {
    const [, dateRangeFilter, filtersData, serviceIdMap, hierarchyLevel, metric, n, azureType, showSpotVM, showRemap] = queryKey;
    const url = "api/v1.0/azure/metrics/hierarchicalmetrics?startDate=" + dateRangeFilter.startDate.format("YYYY-MM-DD")
        + "&endDate=" + dateRangeFilter.endDate.format("YYYY-MM-DD") + "&hierarchyLevel=" + hierarchyLevel + "&metric=" + metric + "&n=" + n + "&azureType=" + azureType + "&showSpotVM=" + showSpotVM + "&showRemap=" + showRemap;
    const response = await postJson<INavHierarchicalMetricReport[]>(url, parseFiltersToJson(filtersData, serviceIdMap));

    return response.map(singleService => {
        return {
            name: singleService.name,
            data: singleService.data.map(metric => ({
                date: moment(metric.date),
                metricValue: metric.metricValue
            })).sort((a, b) => a.date.unix() - b.date.unix())
        }
    });
}

export async function getDailyCpuUtilization({queryKey}: {queryKey: [string, moment.Moment, string, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string[]?]}): Promise<Record<number, number>> {
    const [, dateFilter, azureType, filtersData, serviceIdMap, regions] = queryKey;
    const url = "api/v1.0/azure/metrics/cpuutilization?date=" + dateFilter.format("YYYY-MM-DD") + "&azureType=" + azureType;
    return await postJson<Record<number, number>>(url, parseFiltersToJson(filtersData, serviceIdMap, undefined, regions));
}

export async function getRodsCpuBin({queryKey}: {queryKey: [string, moment.Moment, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string[]?]}): Promise<Record<string, ISingleDayMetric[]>> {
    const [, dateFilter, filtersData, serviceIdMap, regions] = queryKey;
    const url = "api/v1.0/azure/metrics/rdoscpubin?date=" + dateFilter.format("YYYY-MM-DD");
    const response = await postJson<Record<string, INativeSingleDayMetric[]>>(url, parseFiltersToJson(filtersData, serviceIdMap, undefined, regions));

    return mapValues(response, function(metrics) {
        return metrics.map(metric => ({date: moment(metric.date), metricValue: metric.metricValue}))
    });
}

export async function getRegionList() : Promise<string[]> {
    return getJson(`${SearchproviderV1BaseUrl}/regions`);
}

export async function getDetailsByService({ queryKey }: { queryKey: [string, string, IFilterItem[], Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, Map<string, ServiceTreeItem>, IAzureSubscriptionSearchInfo, moment.Moment, moment.Moment, string | undefined, boolean, number, boolean, boolean?, moment.Moment?] }) : Promise<IAzureComputeDetailData<IAzureComputeServiceDetail>>{
    const [, type, localFilters, globalFilters, serviceIdMap, serviceNameMap, subscriptions, startDate, endDate, orderBy, orderDesc, limit, showRemap, useDateRange, singleDate] = queryKey;

    const url = `api/v1.0/azure/metrics/servicelevelheatmap?azureType=${type}&limit=${limit}&startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}` + (orderBy ? `&orderBy=${orderBy}&desc=${orderDesc}` : '') + "&showRemap=" + showRemap + (singleDate && !useDateRange ? `&date=${singleDate!.format('YYYY-MM-DD')}` : '');


    const azureComputeDetails = postJson<IAzureComputeDetailData<IAzureComputeServiceDetail>>(url, {
        localFilters: filterItemsToHeatmapLocalFilters(transformLocalFilters(localFilters, serviceNameMap, subscriptions)),
        globalFilters: parseFiltersToJson(globalFilters, serviceIdMap),
    });

    (await azureComputeDetails).data.forEach(fillServiceDetails(serviceIdMap, subscriptions.idMap));

    return azureComputeDetails;
}

export async function getDetailsByRegion({ queryKey }: { queryKey: [string, string, IFilterItem[], Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, Map<string, ServiceTreeItem>, IAzureSubscriptionSearchInfo, moment.Moment, moment.Moment, string | undefined, boolean, number, string[], boolean, boolean?, moment.Moment?] }) : Promise<IAzureComputeDetailData<IAzureComputeRegionDetail>> {
    const [, type, localFilters, globalFilters, serviceIdMap, serviceNameMap, subscriptions, startDate, endDate, orderBy, orderDesc, limit, regions, showRemap, useDateRange, singleDate] = queryKey;

    const url = `api/v1.0/azure/metrics/regionlevelheatmap?azureType=${type}&limit=${limit}&startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`
        + (orderBy ? `&orderBy=${orderBy}&desc=${orderDesc}` : '') + "&showRemap=" + showRemap + (singleDate && !useDateRange ? `&date=${singleDate!.format('YYYY-MM-DD')}` : '');

    const azureComputeDetails = postJson<IAzureComputeDetailData<IAzureComputeRegionDetail>>(url, {
        localFilters: filterItemsToHeatmapLocalFilters(transformLocalFilters(localFilters, serviceNameMap, subscriptions)),
        globalFilters: parseFiltersToJson(globalFilters, serviceIdMap, undefined, regions),
    });

    (await azureComputeDetails).data.forEach(fillServiceDetails(serviceIdMap, subscriptions.idMap));

    return azureComputeDetails;
}

export async function getSubscriptionSummary({ queryKey }:{ queryKey: [string, string, IFilterItem[], Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, Map<string, ServiceTreeItem>, IAzureSubscriptionSearchInfo, moment.Moment, moment.Moment, string[], boolean, boolean?, moment.Moment?] }) : Promise<IAzureComputeDetailData<IAzureComputeSubscriptionDetail>> {
    const [, type, localFilters, globalFilters, serviceIdMap, serviceNameMap, subscriptions, startDate, endDate, regions, showRemap, useDateRange, singleDate] = queryKey;

    const url = `api/v1.0/azure/metrics/rolelevelheatmapsummary?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}&azureType=${type}` + "&showRemap=" + showRemap + (singleDate && !useDateRange ? `&date=${singleDate!.format('YYYY-MM-DD')}` : '');

    const azureComputeDetails = postJson<IAzureComputeDetailData<IAzureComputeSubscriptionDetail>>(url, {
        localFilters: filterItemsToHeatmapLocalFilters(transformLocalFilters(localFilters, serviceNameMap, subscriptions)),
        globalFilters: parseFiltersToJson(globalFilters, serviceIdMap, undefined, regions),
    });

    return azureComputeDetails;
}

export async function getDetailsBySubscription({ queryKey }: { queryKey: [string, string, IFilterItem[], Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, Map<string, ServiceTreeItem>, IAzureSubscriptionSearchInfo, moment.Moment, moment.Moment, string | undefined, boolean, number, string[], boolean, number, boolean?, moment.Moment?] }) : Promise<IAzureComputeDetailData<IAzureComputeSubscriptionDetail>> {
    const [, type, localFilters, globalFilters, serviceIdMap, serviceNameMap, subscriptions, startDate, endDate, orderBy, orderDesc, limit, regions, showRemap, size, useDateRange, singleDate] = queryKey;

    const url = `api/v1.0/azure/metrics/rolelevelheatmap?azureType=${type}&limit=${limit}&startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`
        + (orderBy ? `&orderBy=${orderBy}&desc=${orderDesc}` : '') + "&showRemap=" + showRemap + (singleDate && !useDateRange ? `&date=${singleDate!.format('YYYY-MM-DD')}` : '');

    const data : IAzureComputeSubscriptionDetail[] = await postJson<Array<IAzureComputeSubscriptionDetail>>(url, {
        localFilters: filterItemsToHeatmapLocalFilters(transformLocalFilters(localFilters, serviceNameMap, subscriptions)),
        globalFilters: parseFiltersToJson(globalFilters, serviceIdMap, undefined, regions),
    });

    data.forEach(fillServiceDetails(serviceIdMap, subscriptions.idMap));

    return {
        min: {},
        max: {},
        total: {},
        rowCount: 0,
        data,
    };
}

export async function getTotalBudget({queryKey}: {queryKey: [string, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, number]}): Promise<IAzureTotalBudgetReport> {
    const [, filtersData, serviceIdMap, fiscalYear] = queryKey;
    const url = "api/v1.0/azure/metrics/totalbudget?fiscalYear=" + fiscalYear;
    return await postJson<IAzureTotalBudgetReport>(url, parseFiltersToJson(filtersData, serviceIdMap));
}

export async function getTopAzureWorkloadsMonthlyCost({queryKey}: {queryKey: [string, number, string, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, number]}): Promise<IAzureWorkloadMonthlyCost[]> {
    const [, n, azureType, filtersData, serviceIdMap, fiscalYear] = queryKey;
    const url = "api/v1.0/azure/metrics/topazureproducts?fiscalYear=" + fiscalYear + "&n=" + n + "&azureType=" + azureType;
    return await postJson<IAzureWorkloadMonthlyCost[]>(url, parseFiltersToJson(filtersData, serviceIdMap));
}

export async function getBudgetDetails({queryKey}: {queryKey: [string, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, number]}): Promise<IAzureBudgetDetails[]> {
    const [, filtersData, serviceIdMap, fiscalYear] = queryKey;
    const url = "api/v1.0/azure/metrics/budgetdetails?fiscalYear=" + fiscalYear;
    return await postJson<IAzureBudgetDetails[]>(url, parseFiltersToJson(filtersData, serviceIdMap));
}

export async function getAvailableFiscalYears(): Promise<number[]> {
    const url = `${SearchproviderV1BaseUrl}/fiscalyears`;
    return await getJson<number[]>(url);
}

function downloadAzureComputeDetailsHeatmap(
    path: string,
    date: Moment | undefined,
    heatmapFilters: IFilterItem[],
    searchFilters: Partial<Record<CategoryDivision, string[]>>,
    serviceIdMap: Map<string, ServiceTreeItem>,
    subscriptions: IAzureSubscriptionSearchInfo,
    orderBy: string,
    desc: boolean,
    azureType: string,
    columns: (keyof IAzureComputeServiceDetail)[],
    startDate: Moment,
    endDate: Moment,
    showRemap: boolean,
    useDateRange: boolean | undefined,
    enableDownloading: () => void) {
    const url = `${path}?azureType=${azureType}` + (date && !useDateRange ? `&date=${date!.format('YYYY-MM-DD')}` : '') + (orderBy ? `&orderBy=${orderBy}&desc=${desc}` : '') + `&startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}` + "&showRemap=" + showRemap;

    
    postJson<IAzureComputeServiceDetail[]>(url, {
        localFilters: filterItemsToHeatmapLocalFilters(heatmapFilters),
        globalFilters: parseFiltersToJson(searchFilters, serviceIdMap),
    }).then(response => {
        response.forEach(fillServiceDetails(serviceIdMap, subscriptions.idMap));
        exportToCsv(response, "heatmap", columns, AzureComputeDisplayNames);
    }).finally(() => enableDownloading());
}

export function downloadAzureComputeServiceHeatmap(date: Moment | undefined, heatmapFilters: IFilterItem[], searchFilters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, subscriptions: IAzureSubscriptionSearchInfo, orderBy: string, desc: boolean, azureType: string, columns: (keyof IAzureComputeServiceDetail)[], startDate: Moment, endDate: Moment, showRemap: boolean, useDateRange: boolean | undefined, enableDownloading: () => void) {
    downloadAzureComputeDetailsHeatmap("api/v1.0/azure/metrics/getservicelevelheatmaplist", date, heatmapFilters, searchFilters, serviceIdMap, subscriptions, orderBy, desc, azureType, columns, startDate, endDate, showRemap, useDateRange, enableDownloading);
}

export function downloadAzureComputeRegionHeatmap(date: Moment | undefined, heatmapFilters: IFilterItem[], searchFilters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, subscriptions: IAzureSubscriptionSearchInfo, orderBy: string, desc: boolean, azureType: string, columns: (keyof IAzureComputeServiceDetail)[], startDate: Moment, endDate: Moment, showRemap: boolean, useDateRange: boolean | undefined, enableDownloading: () => void) {
    downloadAzureComputeDetailsHeatmap("api/v1.0/azure/metrics/getregionlevelheatmap", date, heatmapFilters, searchFilters, serviceIdMap, subscriptions, orderBy, desc, azureType, columns, startDate, endDate, showRemap, useDateRange, enableDownloading);
}

export function downloadAzureComputeSubscriptionHeatmap(
    date: Moment | undefined,
    localFilters: IFilterItem[],
    globalFilters: Partial<Record<CategoryDivision, string[]>>,
    serviceIdMap: Map<string, ServiceTreeItem>,
    serviceNameMap: Map<string, ServiceTreeItem>,
    subscriptions: IAzureSubscriptionSearchInfo,
    orderBy: string,
    desc: boolean,
    azureType: string,
    regions: string[],
    columns: (keyof IAzureComputeSubscriptionDetail)[],
    startDate: Moment,
    endDate: Moment,
    showRemap: boolean,
    useDateRange: boolean | undefined,
    enableDownloading: () => void) {
    const url = `api/v1.0/azure/metrics/rolelevelheatmap?azureType=${azureType}` + (date && !useDateRange ? `&date=${date!.format('YYYY-MM-DD')}` : '') + (orderBy ? `&orderBy=${orderBy}&desc=${desc}` : '') + `&startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}` + "&showRemap=" + showRemap;

    postJson<IAzureComputeSubscriptionDetail[]>(url, {
        localFilters: filterItemsToHeatmapLocalFilters(transformLocalFilters(localFilters, serviceNameMap, subscriptions)),
        globalFilters: parseFiltersToJson(globalFilters, serviceIdMap, undefined, regions),
    }).then(response => {
        response.forEach(fillServiceDetails(serviceIdMap, subscriptions.idMap));
        exportToCsv(response, "heatmap", columns, AzureComputeDisplayNames);
    }).finally(() => {
        enableDownloading();
    });
}

export function getAzureNonComputeCosts({ queryKey }: { queryKey: [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string[]] }) {
    const [, dateRange, searchFilters, serviceIdMap, categories] = queryKey;
    const url = "api/v1.0/azure/metrics/noncomputecosts?startDate=" + dateRange.startDate.format('YYYY-MM-DD') + "&endDate=" + dateRange.endDate.format('YYYY-MM-DD');
    return postJson<Record<string, ISingleDayMetric[]>>(url, {searchFilters: parseFiltersToJson(searchFilters, serviceIdMap), categories});
}

interface INativeSingleDayMetric {
    date: moment.Moment;
    metricValue?: number;
}

interface INativeDailyMetricReport {
    startDate: moment.Moment;
    endDate: moment.Moment;
    metric: string;
    total?: number;
    data: INativeSingleDayMetric[];
}

interface INavHierarchicalMetricReport {
    name: string;
    data: INativeSingleDayMetric[];
}

function fillServiceDetails(serviceIdMap: Map<string, ServiceTreeItem>, subscriptionIdMap: Map<string, ISubscription>) {
    return function (service: IAzureComputeDetail) {
        service.subscriptionName = subscriptionIdMap.get(service.subscriptionId)?.subscriptionName || "";
        service.serviceName = serviceIdMap.get(service.serviceId)?.n || "";
        service.serviceDevOwners = serviceIdMap.get(service.serviceId)?.o || "";
        service.servicePMOwners = serviceIdMap.get(service.serviceId)?.PmOwner || "";
    }
}

function transformLocalFilters(filterItems: IFilterItem[], serviceNameMap: Map<string, ServiceTreeItem>, subscriptions: IAzureSubscriptionSearchInfo) {
    const result: IFilterItem[] = [];
    filterItems.forEach(filterItem => {
        switch(filterItem.key) {
            case "subscriptionName":
                if (filterItem.operator === FilterGroupOperator.EQ) {
                    filterItem.keyword &&
                    result.push({
                        key: "subscriptionId",
                        operator: FilterGroupOperator.EQ,
                        keyword: subscriptions.nameMap.get(filterItem.keyword)?.subscriptionId
                    });
                } else if (filterItem.operator === FilterGroupOperator.CN) {
                    filterItem.keyword &&
                    result.push({
                        key: "subscriptionId",
                        operator: FilterGroupOperator.IN,
                        keywords: Array.from(subscriptions.idMap.entries())
                        .filter(entry => entry[1].subscriptionName?.toLowerCase().includes(filterItem.keyword!.toLowerCase()))
                        .map(entry => entry[0])
                    });
                }
                break;
            case "serviceName":
                if (filterItem.operator === FilterGroupOperator.EQ) {
                    filterItem.keyword &&
                    result.push({
                        key: "serviceId",
                        operator: FilterGroupOperator.EQ,
                        keyword: serviceNameMap.get(filterItem.keyword)?.id
                    });
                } else if (filterItem.operator === FilterGroupOperator.CN) {
                    filterItem.keyword &&
                    result.push({
                        key: "serviceId",
                        operator: FilterGroupOperator.IN,
                        keywords: Array.from(serviceNameMap.entries())
                        .filter(entry => entry[1].l === ServiceTreeLevel.Service && entry[0].toLowerCase().includes(filterItem.keyword!.toLowerCase()))
                        .map(entry => entry[1].id)
                    });
                }
                break;
            default:
                result.push(filterItem);
        }
    });
    return result;
}