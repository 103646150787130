import { Reducer } from "redux";
import { ActionTypes } from "../actions/ActionTypes";
import { IRingChangeAction, IUpdateSubstrateV2RingsAction } from "../actions/RingActions";

export interface IRingData {
    ring?: string;
    rings?: string[];
}

export const ringReducer: Reducer<IRingData, IRingChangeAction> = function(state: IRingData = {}, action: IRingChangeAction|IUpdateSubstrateV2RingsAction): IRingData {
    if (action.type === ActionTypes.RingChangeAction) {
        return {
            ...state,
            ring: action.ring,
        };
    } else if (action.type === ActionTypes.UpdateSubstrateV2RingsAction) {
        return {
            ...state,
            rings: (action as IUpdateSubstrateV2RingsAction).rings,
        }
    }
    return state;
}
