import { FontIcon, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { Cell, Column, Row, UseExpandedRowProps, UseSortByColumnOptions } from "react-table";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { SubstrateEntityMetrics } from "../../../models/SubstrateModels";
import { trackEventCallback } from "../../../utils/AppInsights";

const intlFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
});

const intlNumFormatter = new Intl.NumberFormat("en-US",
    {
        maximumFractionDigits: 0
    });

const currencyFormatter = ({ cell }: { cell: Cell<Row, number> }) => intlFormatter.format(cell.value);

const numberFormatter = ({ cell }: { cell: Cell<Row, number> }) => intlNumFormatter.format(cell.value);

const carbonEmissionFormatter = ({ cell }: { cell: Cell<Row, number> }) => cell.value ? intlNumFormatter.format(cell.value) + ' mtCO2e' : '-';

const rawFormater = ({ cell }: { cell: Cell<Row, number> }) => cell.value || "0 Tag";
const pms = new Set<string>();
const devs = new Set<string>();

export interface ITableRow {
    uniqueId?: string;
    name: string;
    totalCost: number;
    itemReadsCost: number;
    itemWritesCost: number;
    itemQueriesCost: number;
    ebaCost: number;
    tbaCost: number;
    swssCost: number;
    ebaCount: number;
    tbaCount: number;
    swssCount: number;
    totalAssistantsCost: number;
    serviceInstanceCost: number;
    serviceInstanceCount: number;
    serviceInstanceCpu: number;
    serviceInstanceMemory: number;
    requestProcessedCost: number;
    cfmSubmittedCost: number;
    cfmSubmittedCount: number;
    dsApiRequestsCost: number;
    dsApiRequestsCount: number;
    itemSizeCost: number;
    maxItemSize: number;
    kvCacheCost: number;
    kvCacheSize: number;
    sdsFastStorageCost: number;
    sdsFastStorageSize: number;
    itemReads: number;
    itemWrites: number;
    itemQueries: number;
    requestsProcessed: number;
    offPeakHourItemReads: number;
    offPeakHourItemWrites: number;
    offPeakHourItemQueries: number;
    offPeakHourRequestsProcessed: number;
    peakHourItemReads: number;
    peakHourItemWrites: number;
    peakHourItemQueries: number;
    peakHourRequestsProcessed: number;
    pmOwner: string;
    devOwner: string;
    tags?: string;
    totalreadwritesqueriescost?: number;
    griffinapp?: string | null;
    maxServiceInstanceCount: number;
    maxServiceInstanceCpu: number;
    maxServiceInstanceMemory: number;
    maxLLCSizeGb: number;
    griffinProcessor?: string;
    totalCarbonEmission?: number;
    carbonScope2Emission?: number;
    carbonScope3Emission?: number;
}

export interface IExpandedTableRow extends ITableRow {
    subRows?: IExpandedTableRow[];
}

export const tableHeaders: [(keyof IExpandedTableRow), SubstrateEntityMetrics][] = [
    ["totalCost", SubstrateEntityMetrics.TotalCost],
    ["itemReadsCost", SubstrateEntityMetrics.ItemReadsCost],
    ["itemWritesCost", SubstrateEntityMetrics.ItemWritesCost],
    ["itemQueriesCost", SubstrateEntityMetrics.ItemQueriesCost],
    ["ebaCost", SubstrateEntityMetrics.EBACost],
    ["tbaCost", SubstrateEntityMetrics.TBACost],
    ["swssCost", SubstrateEntityMetrics.SWSSCost],
    ["ebaCount", SubstrateEntityMetrics.EBACount],
    ["tbaCount", SubstrateEntityMetrics.TBACount],
    ["swssCount", SubstrateEntityMetrics.SWSSCount],
    ["totalAssistantsCost", SubstrateEntityMetrics.TotalAssistantsCost],
    ["serviceInstanceCost", SubstrateEntityMetrics.ServiceInstanceCost],
    ["serviceInstanceCount", SubstrateEntityMetrics.ServiceInstanceCount],
    ["serviceInstanceCpu", SubstrateEntityMetrics.ServiceInstanceCpu],
    ["serviceInstanceMemory", SubstrateEntityMetrics.ServiceInstanceMemory],
    ["requestProcessedCost", SubstrateEntityMetrics.RequestProcessCost],
    ["cfmSubmittedCost", SubstrateEntityMetrics.CfmSubmittedCost],
    ["cfmSubmittedCount", SubstrateEntityMetrics.CfmSubmittedCount],
    ["dsApiRequestsCost", SubstrateEntityMetrics.DsapiRequestsCost],
    ["dsApiRequestsCount", SubstrateEntityMetrics.DsapiRequestsCount],
    ["itemSizeCost", SubstrateEntityMetrics.ItemSizeCost],
    ["maxItemSize", SubstrateEntityMetrics.MaxItemSize],
    ["kvCacheCost", SubstrateEntityMetrics.KvCacheCost],
    ["kvCacheSize", SubstrateEntityMetrics.KvCacheSize],
    ["sdsFastStorageCost", SubstrateEntityMetrics.SdsFastStorageCost],
    ["maxLLCSizeGb", SubstrateEntityMetrics.MaxLLCSizeGb],
    ["itemReads", SubstrateEntityMetrics.ItemReadsCount],
    ["itemWrites", SubstrateEntityMetrics.ItemWritesCount],
    ["itemQueries", SubstrateEntityMetrics.ItemQueriesCount],
    ["requestsProcessed", SubstrateEntityMetrics.RequestProcessedCount],
    ["offPeakHourItemReads", SubstrateEntityMetrics.OffPeakHourItemReadsCount],
    ["offPeakHourItemWrites", SubstrateEntityMetrics.OffPeakHourItemWritesCount],
    ["offPeakHourItemQueries", SubstrateEntityMetrics.OffPeakHourItemQueriesCount],
    ["offPeakHourRequestsProcessed", SubstrateEntityMetrics.OffPeakHourRequestProcessedCount],
    ["peakHourItemReads", SubstrateEntityMetrics.PeakHourItemReadsCount],
    ["peakHourItemWrites", SubstrateEntityMetrics.PeakHourItemWritesCount],
    ["peakHourItemQueries", SubstrateEntityMetrics.PeakHourItemQueriesCount],
    ["peakHourRequestsProcessed", SubstrateEntityMetrics.PeakHourRequestProcessedCount],
    ["pmOwner", SubstrateEntityMetrics.PmOwner],
    ["devOwner", SubstrateEntityMetrics.DevOwner],
    ["tags", SubstrateEntityMetrics.ScenarioTag],
    ["totalreadwritesqueriescost", SubstrateEntityMetrics.TotalItemReadsWritesQueriesCost],
    ["griffinapp", SubstrateEntityMetrics.GriffinApp],
    ["maxServiceInstanceCount", SubstrateEntityMetrics.MaxServiceInstanceCount],
    ["maxServiceInstanceCpu", SubstrateEntityMetrics.MaxServiceInstanceCpu],
    ["maxServiceInstanceMemory", SubstrateEntityMetrics.MaxServiceInstanceMemory],
    ["griffinProcessor", SubstrateEntityMetrics.GriffinProcessor],
];
    tableHeaders.push(
        ["totalCarbonEmission", SubstrateEntityMetrics.TotalCarbonEmission],
        ["carbonScope2Emission", SubstrateEntityMetrics.CarbonScope2Emission],
        ["carbonScope3Emission", SubstrateEntityMetrics.CarbonScope3Emission]
    );
    
function getPMNo(rows: ITableRow[]): string {
    pms.clear();
    rows.forEach(row => {
        const pmlist = row.pmOwner.split(";");
        for (const pm of pmlist) {
            pms.add(pm);
        }
    })
    return pms.size + " PM Owners";
}

function getDevNo(rows: ITableRow[]): string {
    devs.clear();
    rows.forEach(row => {
        const devlist = row.devOwner.split(";");
        for (const pm of devlist) {
            devs.add(pm);
        }
    })
    return devs.size + " Dev Owners";
}

export function getColumns(rows: IExpandedTableRow[], logComponent: LogComponent): (Column<ITableRow> & UseSortByColumnOptions<ITableRow>)[] {
    const carbonColumns: (Column<ITableRow> & UseSortByColumnOptions<ITableRow>)[] = [
        {
            id: SubstrateEntityMetrics.TotalCarbonEmission,
            Header: SubstrateEntityMetrics.TotalCarbonEmission,
            accessor: "totalCarbonEmission",
            Cell: carbonEmissionFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev +  (current.totalCarbonEmission ? current.totalCarbonEmission : 0), 0)) + ' mtCO2e',
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.CarbonScope2Emission,
            Header: SubstrateEntityMetrics.CarbonScope2Emission,
            accessor: "carbonScope2Emission",
            Cell: carbonEmissionFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + (current.carbonScope2Emission ? current.carbonScope2Emission : 0), 0)) + ' mtCO2e',
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.CarbonScope3Emission,
            Header: SubstrateEntityMetrics.CarbonScope3Emission,
            accessor: "carbonScope3Emission",
            Cell: carbonEmissionFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + (current.carbonScope3Emission ? current.carbonScope3Emission : 0), 0)) + ' mtCO2e',
            sortType: "number"
        },
    ];    
    return [
        {
            id: SubstrateEntityMetrics.Name,
            Header: SubstrateEntityMetrics.Name,
            Cell: ({ row, cell }: { row: Row & UseExpandedRowProps<ITableRow>, cell: Cell<Row, number> }) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    {
                        row.canExpand &&
                        <FontIcon
                            iconName={row.isExpanded ? "ChevronDown" : "ChevronRight"}
                            style={{ paddingLeft: row.depth * 16, cursor: "pointer" }}
                            onClick={function () {
                                row.toggleRowExpanded();
                                trackEventCallback(logComponent, LogElement.Fold, "Unfold/Fold", LogTarget.Button)
                            }}
                        />
                    }
                    <span style={{ marginLeft: (row.canExpand ? 0 : (row.depth * 16 + 12)) + 16 }}>
                        {
                            cell.value.toString().endsWith("*") ?
                                <TooltipHost
                                    content="This service name is recently been removed on Service Tree.
                            The old service name is shown here until new name is updated."
                                    calloutProps={{
                                        isBeakVisible: false,
                                        calloutWidth: 180
                                    }}
                                    styles={{
                                        root: {
                                            height: 16
                                        }
                                    }}
                                >
                                    {cell.value}
                                </TooltipHost> :
                                (cell.value.toString().endsWith("*") ? cell.value.toString().replace("*", "") : cell.value)
                        }
                    </span>
                </div>
            ),
            accessor: "name",
            Footer: "Total"
        },
        {
            id: SubstrateEntityMetrics.ScenarioTag,
            Header: SubstrateEntityMetrics.ScenarioTag,
            accessor: "tags",
            Cell: rawFormater,
            Footer: ""
        },
        {
            id: SubstrateEntityMetrics.GriffinApp,
            Header: SubstrateEntityMetrics.GriffinApp,
            accessor: "griffinapp",
            Footer: "-"
        },
        {
            id: SubstrateEntityMetrics.GriffinProcessor,
            Header: SubstrateEntityMetrics.GriffinProcessor,
            accessor: "griffinProcessor",
            Footer: "-"
        },
        {
            id: SubstrateEntityMetrics.TotalCost,
            Header: SubstrateEntityMetrics.TotalCost,
            accessor: "totalCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.totalCost, 0)),
            sortType: "number"
        },
        ...carbonColumns,
        {
            id: SubstrateEntityMetrics.TotalItemReadsWritesQueriesCost,
            Header: SubstrateEntityMetrics.TotalItemReadsWritesQueriesCost,
            accessor: "totalreadwritesqueriescost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.itemReadsCost + current.itemWritesCost + current.itemQueriesCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.ItemReadsCost,
            Header: SubstrateEntityMetrics.ItemReadsCost,
            accessor: "itemReadsCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.itemReadsCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.ItemWritesCost,
            Header: SubstrateEntityMetrics.ItemWritesCost,
            accessor: "itemWritesCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.itemWritesCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.ItemQueriesCost,
            Header: SubstrateEntityMetrics.ItemQueriesCost,
            accessor: "itemQueriesCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.itemQueriesCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.TotalAssistantsCost,
            Header: SubstrateEntityMetrics.TotalAssistantsCost,
            accessor: "totalAssistantsCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.totalAssistantsCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.EBACost,
            Header: SubstrateEntityMetrics.EBACost,
            accessor: "ebaCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.ebaCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.TBACost,
            Header: SubstrateEntityMetrics.TBACost,
            accessor: "tbaCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.tbaCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.SWSSCost,
            Header: SubstrateEntityMetrics.SWSSCost,
            accessor: "swssCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.swssCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.ServiceInstanceCost,
            Header: SubstrateEntityMetrics.ServiceInstanceCost,
            accessor: "serviceInstanceCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.serviceInstanceCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.ServiceInstanceCount,
            Header: SubstrateEntityMetrics.ServiceInstanceCount,
            accessor: "maxServiceInstanceCount",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.maxServiceInstanceCount, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.ServiceInstanceCpu,
            Header: SubstrateEntityMetrics.ServiceInstanceCpu,
            accessor: "maxServiceInstanceCpu",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.maxServiceInstanceCpu, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.ServiceInstanceMemory,
            Header: SubstrateEntityMetrics.ServiceInstanceMemory,
            accessor: "maxServiceInstanceMemory",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.maxServiceInstanceMemory, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.RequestProcessCost,
            Header: SubstrateEntityMetrics.RequestProcessCost,
            accessor: "requestProcessedCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.requestProcessedCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.CfmSubmittedCost,
            Header: SubstrateEntityMetrics.CfmSubmittedCost,
            accessor: "cfmSubmittedCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.cfmSubmittedCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.DsapiRequestsCost,
            Header: SubstrateEntityMetrics.DsapiRequestsCost,
            accessor: "dsApiRequestsCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.dsApiRequestsCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.ItemSizeCost,
            Header: SubstrateEntityMetrics.ItemSizeCost,
            accessor: "itemSizeCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.itemSizeCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.MaxItemSize,
            Header: SubstrateEntityMetrics.MaxItemSize,
            accessor: "maxItemSize",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.maxItemSize, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.KvCacheCost,
            Header: SubstrateEntityMetrics.KvCacheCost,
            accessor: "kvCacheCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.kvCacheCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.KvCacheSize,
            Header: SubstrateEntityMetrics.KvCacheSize,
            accessor: "kvCacheSize",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.kvCacheSize, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.SdsFastStorageCost,
            Header: SubstrateEntityMetrics.SdsFastStorageCost,
            accessor: "sdsFastStorageCost",
            Cell: currencyFormatter,
            Footer: intlFormatter.format(rows.reduce((prev, current) => prev + current.sdsFastStorageCost, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.MaxLLCSizeGb,
            Header: SubstrateEntityMetrics.MaxLLCSizeGb,
            accessor: "maxLLCSizeGb",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.maxLLCSizeGb, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.ItemReadsCount,
            Header: SubstrateEntityMetrics.ItemReadsCount,
            accessor: "itemReads",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.itemReads, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.OffPeakHourItemReadsCount,
            Header: SubstrateEntityMetrics.OffPeakHourItemReadsCount,
            accessor: "offPeakHourItemReads",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.offPeakHourItemReads, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.PeakHourItemReadsCount,
            Header: SubstrateEntityMetrics.PeakHourItemReadsCount,
            accessor: "peakHourItemReads",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.peakHourItemReads, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.ItemWritesCount,
            Header: SubstrateEntityMetrics.ItemWritesCount,
            accessor: "itemWrites",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.itemWrites, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.OffPeakHourItemWritesCount,
            Header: SubstrateEntityMetrics.OffPeakHourItemWritesCount,
            accessor: "offPeakHourItemWrites",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.offPeakHourItemWrites, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.PeakHourItemWritesCount,
            Header: SubstrateEntityMetrics.PeakHourItemWritesCount,
            accessor: "peakHourItemWrites",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.peakHourItemWrites, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.ItemQueriesCount,
            Header: SubstrateEntityMetrics.ItemQueriesCount,
            accessor: "itemQueries",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.itemQueries, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.OffPeakHourItemQueriesCount,
            Header: SubstrateEntityMetrics.OffPeakHourItemQueriesCount,
            accessor: "offPeakHourItemQueries",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.offPeakHourItemQueries, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.PeakHourItemQueriesCount,
            Header: SubstrateEntityMetrics.PeakHourItemQueriesCount,
            accessor: "peakHourItemQueries",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.peakHourItemQueries, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.RequestProcessedCount,
            Header: SubstrateEntityMetrics.RequestProcessedCount,
            accessor: "requestsProcessed",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.requestsProcessed, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.OffPeakHourRequestProcessedCount,
            Header: SubstrateEntityMetrics.OffPeakHourRequestProcessedCount,
            accessor: "offPeakHourRequestsProcessed",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.offPeakHourRequestsProcessed, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.PeakHourRequestProcessedCount,
            Header: SubstrateEntityMetrics.PeakHourRequestProcessedCount,
            accessor: "peakHourRequestsProcessed",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.peakHourRequestsProcessed, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.EBACount,
            Header: SubstrateEntityMetrics.EBACount,
            accessor: "ebaCount",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.ebaCount, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.TBACount,
            Header: SubstrateEntityMetrics.TBACount,
            accessor: "tbaCount",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.tbaCount, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.SWSSCount,
            Header: SubstrateEntityMetrics.SWSSCount,
            accessor: "swssCount",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.swssCount, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.DsapiRequestsCount,
            Header: SubstrateEntityMetrics.DsapiRequestsCount,
            accessor: "dsApiRequestsCount",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.dsApiRequestsCount, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.CfmSubmittedCount,
            Header: SubstrateEntityMetrics.CfmSubmittedCount,
            accessor: "cfmSubmittedCount",
            Cell: numberFormatter,
            Footer: intlNumFormatter.format(rows.reduce((prev, current) => prev + current.cfmSubmittedCount, 0)),
            sortType: "number"
        },
        {
            id: SubstrateEntityMetrics.DevOwner,
            Header: SubstrateEntityMetrics.DevOwner,
            accessor: "devOwner",
            Footer: getDevNo(rows)
        },
        {
            id: SubstrateEntityMetrics.PmOwner,
            Header: SubstrateEntityMetrics.PmOwner,
            accessor: "pmOwner",
            Footer: getPMNo(rows)
        },
    ];
}

export function downloadCsv(exportRows: IExpandedTableRow[], headers: [(keyof IExpandedTableRow), SubstrateEntityMetrics][], exportName: string): void {
    let result = "GriffinApp/Processor,Service Name," + headers.map(header => header[1]).join(",") + "\r\n";
    exportRows.forEach(row => {
        const serviceName = row.name;

        row.subRows?.forEach(innerRow => {
            result += innerRow.name + "," + serviceName + "," + headers.map(header => innerRow[header[0]]).join(",") + "\r\n";
        });
    });

    const url = window.URL.createObjectURL(new Blob([result], { type: "text/csv" }));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", url);
    downloadAnchorNode.setAttribute("download", exportName + ".csv");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
    window.URL.revokeObjectURL(url);
}