import React from "react";
import ColumnChart from "../../../common/ColumnChart";
import ChartContainer from "../../../chart/ChartContainer";
import { EmptyModule } from "../../../common/state/EmptyModule";

export interface IHistogramProps {
    values: number[];
    xAxisLabels: string[];
    seriesHeader: string;
    description: string;
    showValue?: boolean;
    isCurrency?: boolean;
}

export interface IHistogramItem {
    name: string;
    value: number;
}

export const Histogram: React.FC<IHistogramProps> = (props) => {
    const { seriesHeader, description, xAxisLabels, values, showValue, isCurrency } = props;
    const isEmpty = React.useMemo(() => values === null || values.length === 0 || values.every((v) => v === 0), [values]);

    return (
        <div>
            <ChartContainer headerProps={{ title: description }}>
                {isEmpty ? (
                    <EmptyModule iconName="EmptyBarChart" />
                ) : (
                    <ColumnChart seriesHeader={seriesHeader} x={xAxisLabels} y={values} showValue={showValue} isCurrency={isCurrency} />
                )}
            </ChartContainer>
        </div>
    );
};
