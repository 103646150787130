// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eWrZcP43_nyqvHOs1wbJ {\n  display: flex;\n  margin: 0 0 16px;\n  max-width: 100%;\n  overflow: hidden;\n}\n.q0uf4BSxFnDn2nW82D0d {\n  flex: 1;\n  font-weight: bold;\n  display: flex;\n  align-items: center;\n  margin: 0;\n}\n.rPWbK_Tn6qcIxs0Cx0k2::before {\n  background-color: #323130;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/SectionHeader.less"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;AAGA;EACI,OAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AADJ;AAIA;EACI,yBAAA;AAFJ","sourcesContent":["\n.sectionHeaderContent {\n    display: flex;\n    margin: 0 0 16px;\n    max-width: 100%;\n    overflow: hidden;\n}\n\n.sectionHeaderTitle {\n    flex: 1;\n    font-weight: bold;\n    display: flex;\n    align-items: center;\n    margin: 0;\n}\n\n.separator::before {\n    background-color: #323130;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionHeaderContent": "eWrZcP43_nyqvHOs1wbJ",
	"sectionHeaderTitle": "q0uf4BSxFnDn2nW82D0d",
	"separator": "rPWbK_Tn6qcIxs0Cx0k2"
};
export default ___CSS_LOADER_EXPORT___;
