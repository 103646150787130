import * as React from "react";

import {
    Checkbox,
    ChoiceGroup,
    DefaultButton,
    IChoiceGroupOption,
    ISpinButtonStyles,
    ITextFieldProps,
    Icon,
    SpinButton,
    TextField,
    TooltipHost,
} from "@fluentui/react";
import { IChartDataPoint, MultiStackedBarChart } from "@fluentui/react-charting";

import { CategoryDivision } from "../../models/CategoryDivision";
import { FiltersBanner } from "../common/FiltersBanner/FiltersBanner";
import { FormEvent } from "react";
import LoadingState from "../ResponseBoundary/LoadingState";
import PageHeader from "../common/PageHeader";
import { SubstratePlatformPivotEnum } from "../../models/SubstratePlatform";
import { filter } from "lodash";
import { formatNumber } from "../../utils/currency";
import { isTeachingBubblesShowed } from "../../utils/TutorialUtil";
import styles from "./CogsCalculator.less";
import { useBoolean } from "@fluentui/react-hooks";
import { useCategoryFilters } from "../../hooks/useFilters";
import { useGetSubstratePlatformDailyReportQuery } from "../../hooks/useSubstratePlatformQuery";

interface ResourceMetric {
    Name: string;
    DisplayName: string;
    Hint: string;
    Selected: boolean;
    Hidden: boolean;
    OldValue: number;
    OldValueManually: number;
    // ratio %
    NewValueRatio: number;
    NewValueRatioManually: number;
    OldValueDaily: number;
    Suffix: string;
}

interface ResourceEstimatedCost {
    Name: string;
    DisplayName: string;
    Hidden: boolean;
    Selected: boolean;
    BaselineCost: number;
    UpdatedCost: number;
}

export const CogsCalculator: React.FC = () => {
    const { data: dailyReports, isLoading } = useGetSubstratePlatformDailyReportQuery(SubstratePlatformPivotEnum.Resource, true);
    // TODO use the price in database
    const PlatformDailyUnitPrice: Record<string, number> = {
        CPUCores: 0.041525524,
        MemoryInGB: 0.005851502,
        IO: 0.000755452,
        DbDiskReadsIO: 0.000755452,
        DbDiskWritesIO: 0.000755452,
        HddStorage: 0.000070434,
        SsdStorage: 0.000473518,
    };

    const [selectedCalculatorMode, setSelectedCalculatorMode] = React.useState<string>("Automated");
    const [showTutorialVideo, setShowTutorialVideo] = React.useState<boolean>(true);
    const [onlyStoreServiceSelected, setOnlyStoreServiceSelected] = React.useState<boolean>(false);
    const globalFilters = useCategoryFilters();

    const [isPlatformWorkloadSelected, { setTrue: selectPlatformWorkload, setFalse: unselectPlatformWorkload }] = useBoolean(false);

    React.useEffect(() => {
        const filters = globalFilters.filters.filters;
        if (
            (filters.Service?.length || 0) > 0 ||
            (filters.PlatformApp?.length || 0) > 0 ||
            (filters.PlatformClient?.length || 0) > 0 ||
            (filters.PlatformClientComponent?.length || 0) > 0 ||
            (filters.PlatformProcess?.length || 0) > 0
        ) {
            // only select store service
            if (
                filters.Service?.length == 1 &&
                filters.Service[0] == "46aaeeb2-219f-4a93-b74d-d7301e448ce2" &&
                (filters.PlatformApp?.length || 0) == 0 &&
                (filters.PlatformClient?.length || 0) == 0 &&
                (filters.PlatformClientComponent?.length || 0) == 0 &&
                (filters.PlatformProcess?.length || 0) == 0
            ) {
                setOnlyStoreServiceSelected(true);
            } else {
                setOnlyStoreServiceSelected(false);
            }
            selectPlatformWorkload();
        } else {
            unselectPlatformWorkload();
        }
    }, [globalFilters.filters]);

    React.useEffect(() => {
        setShowTutorialVideo(!isTeachingBubblesShowed("COGSCalculator"));
        const handleStorageChange = () => {
            setShowTutorialVideo(!isTeachingBubblesShowed("COGSCalculator"));
        };
        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    function setResourceHidden(resource: string, hidden: boolean) {
        const tempResources: Record<string, ResourceMetric> = { ...resources };
        const tempResourcesEstimatedCost = { ...resourcesEstimatedCost };
        tempResources[resource].Hidden = hidden;
        tempResourcesEstimatedCost[resource].Hidden = hidden;
        setResources(tempResources);
    }

    const [CostBarChartPoints, setCostBarChartPoints] = React.useState<IChartDataPoint[]>([]);

    const [costFrequency, setCostFrequency] = React.useState<string>("Daily");

    const [resources, setResources] = React.useState<Record<string, ResourceMetric>>({
        CPUCores: {
            DisplayName: "CPU / Avg.",
            Hint: "the average Cores used (or reserved) over the defined period of time.",
            Name: "CPUCores",
            Selected: true,
            Hidden: false,
            OldValue: 0,
            OldValueManually: 0,
            NewValueRatio: 100,
            NewValueRatioManually: 100,
            OldValueDaily: 0,
            Suffix: " Cores",
        },
        MemoryInGB: {
            DisplayName: "Memory / Avg.",
            Hint: "the average GB of Memory used (or reserved) over the defined period of time.",
            Name: "MemoryInGB",
            Selected: true,
            Hidden: false,
            OldValue: 0,
            OldValueManually: 0,
            NewValueRatio: 100,
            NewValueRatioManually: 100,
            OldValueDaily: 0,
            Suffix: " GB",
        },
        DbDiskReadsIO: {
            DisplayName: "IOReads / Count",
            Hint: "The total DB reads count from the Store over the defined period of time. Used to calculate IO cost.",
            Name: "DbDiskReadsIO",
            Selected: true,
            Hidden: false,
            OldValue: 0,
            OldValueManually: 0,
            NewValueRatio: 100,
            NewValueRatioManually: 100,
            OldValueDaily: 0,
            Suffix: " Reads",
        },
        DbDiskWritesIO: {
            DisplayName: "IOWrites / Count",
            Hint: "The total DB writes count to the Store over the defined period of time. Used to calculate IO cost",
            Name: "DbDiskWritesIO",
            Selected: true,
            Hidden: false,
            OldValue: 0,
            OldValueManually: 0,
            NewValueRatio: 100,
            NewValueRatioManually: 100,
            OldValueDaily: 0,
            Suffix: " Writes",
        },
        HddStorage: {
            DisplayName: "HddStorage / Avg.",
            Hint: "the average GB of Hdd storage over the defined period of time.",
            Name: "HddStorage",
            Selected: true,
            Hidden: false,
            OldValue: 0,
            OldValueManually: 0,
            NewValueRatio: 100,
            NewValueRatioManually: 100,
            OldValueDaily: 0,
            Suffix: " GB",
        },
        SsdStorage: {
            DisplayName: "SsdStorage / Avg.",
            Hint: "the average GB of Ssd storage over the defined period of time.",
            Name: "SsdStorage",
            Selected: true,
            Hidden: false,
            OldValue: 0,
            OldValueManually: 0,
            NewValueRatio: 100,
            NewValueRatioManually: 100,
            OldValueDaily: 0,
            Suffix: " GB",
        },
    });

    React.useEffect(() => {
        const tempResources: Record<string, ResourceMetric> = { ...resources };

        if (selectedCalculatorMode == "Automated") {
            tempResources["DbDiskWritesIO"].Hint = "The total DB writes count to the Store over the defined period of time. Used to calculate IO cost.";
            tempResources["DbDiskReadsIO"].Hint = "The total DB reads count from the Store over the defined period of time. Used to calculate IO cost.";
            tempResources["CPUCores"].Hint = "the average Cores used (or reserved) over the defined period of time.";
            tempResources["MemoryInGB"].Hint = "the average GB of Memory used (or reserved) over the defined period of time.";
            tempResources["HddStorage"].Hint = "the average GB of Hdd storage over the defined period of time.";
            tempResources["SsdStorage"].Hint = "the average GB of Ssd storage over the defined period of time.";
            tempResources["DbDiskWritesIO"].DisplayName = "IOWrites / Count";
            tempResources["DbDiskReadsIO"].DisplayName = "IOReads / Count";
        } else {
            tempResources["DbDiskWritesIO"].Hint = "The total DB writes count to the Store over 1 month.";
            tempResources["DbDiskReadsIO"].Hint = "The total DB reads count from the Store over 1 month.";
            tempResources["CPUCores"].Hint = "the average Cores used (or reserved) over 1 month.";
            tempResources["MemoryInGB"].Hint = "the average GB of Memory used (or reserved) over 1 month.";
            tempResources["HddStorage"].Hint = "the average GB of Hdd storage over 1 month.";
            tempResources["SsdStorage"].Hint = "the average GB of Ssd storage over 1 month.";
            tempResources["DbDiskWritesIO"].DisplayName = "IOWrites / Monthly Count";
            tempResources["DbDiskReadsIO"].DisplayName = "IOReads / Monthly Count";
        }
        setResources(tempResources);
    }, [selectedCalculatorMode]);

    React.useEffect(() => {
        if (!isLoading && dailyReports) {
            const tempResources: Record<string, ResourceMetric> = { ...resources };
            for (const item of dailyReports) {
                if (["CPUCores", "MemoryInGB", "DbDiskReadsIO", "DbDiskWritesIO", "HddStorage", "SsdStorage"].indexOf(item.metric) < 0) {
                    continue;
                }
                const days = item.data.length;
                if (["CPUCores", "MemoryInGB", "HddStorage", "SsdStorage"].indexOf(item.metric) >= 0) {
                    tempResources[item.metric].OldValue = (item.total || 0) / days;
                } else {
                    tempResources[item.metric].OldValue = item.total || 0;
                }
                tempResources[item.metric].OldValueDaily = (item.total || 0) / days;
            }
            setResources(tempResources);
        }
    }, [dailyReports, isLoading]);

    function ReCalculateEstimatedCost() {
        const tempPoints: IChartDataPoint[] = [];
        const tempResourcesEstimatedCost = { ...resourcesEstimatedCost };
        const days = costFrequency == "Daily" ? 1 : costFrequency == "Monthly" ? 30.5 : costFrequency == "Annual" ? 365 : 1;
        let i = 0;
        let totalUpdatedCost = 0;
        let totalBaselineCost = 0;
        for (const item of Object.values(resources)) {
            let baselineCost = 0;
            let cost = 0;
            if (selectedCalculatorMode == "Automated") {
                baselineCost = item.OldValueDaily * days * PlatformDailyUnitPrice[item.Name];
                cost = item.OldValueDaily * (item.NewValueRatio / 100) * days * PlatformDailyUnitPrice[item.Name];
            } else {
                baselineCost =
                    (item.OldValueManually / (["DbDiskReadsIO", "DbDiskWritesIO"].indexOf(item.Name) >= 0 ? 30.5 : 1)) *
                    days *
                    PlatformDailyUnitPrice[item.Name];
                cost = baselineCost;
            }
            tempResourcesEstimatedCost[item.Name].UpdatedCost = Math.round(cost || 0);
            tempResourcesEstimatedCost[item.Name].BaselineCost = Math.round(baselineCost || 0);
            if (item.Selected && !item.Hidden) {
                if (onlyStoreServiceSelected || ["HddStorage", "SsdStorage"].indexOf(item.Name) < 0) {
                    totalUpdatedCost += cost || 0;
                    totalBaselineCost += baselineCost || 0;
                }
                tempPoints.push({
                    legend: tempResourcesEstimatedCost[item.Name].DisplayName,
                    data: cost || 0,
                    color: costDistributionChartColors[i++],
                });
            }
        }
        tempResourcesEstimatedCost["Total"] = {
            Name: "Total",
            DisplayName: "Total",
            Hidden: false,
            Selected: true,
            UpdatedCost: Math.round(totalUpdatedCost || 0),
            BaselineCost: Math.round(totalBaselineCost || 0),
        };
        setResourceEstimatedCost(tempResourcesEstimatedCost);
        setCostBarChartPoints(tempPoints);
    }

    const [resourcesEstimatedCost, setResourceEstimatedCost] = React.useState<Record<string, ResourceEstimatedCost>>({
        CPUCores: {
            DisplayName: "CPU",
            Name: "CPUCores",
            Selected: true,
            Hidden: false,
            BaselineCost: 0,
            UpdatedCost: 0,
        },
        MemoryInGB: {
            DisplayName: "Memory",
            Name: "MemoryInGB",
            Selected: true,
            Hidden: false,
            BaselineCost: 0,
            UpdatedCost: 0,
        },
        DbDiskReadsIO: {
            DisplayName: "IOReads",
            Name: "DbDiskReadsIO",
            Selected: true,
            Hidden: false,
            BaselineCost: 0,
            UpdatedCost: 0,
        },
        DbDiskWritesIO: {
            DisplayName: "IOWrites",
            Name: "DbDiskWritesIO",
            Selected: true,
            Hidden: false,
            BaselineCost: 0,
            UpdatedCost: 0,
        },
        HddStorage: {
            DisplayName: "HddStorage",
            Name: "HddStorage",
            Selected: true,
            Hidden: false,
            BaselineCost: 0,
            UpdatedCost: 0,
        },
        SsdStorage: {
            DisplayName: "SsdStorage",
            Name: "SsdStorage",
            Selected: true,
            Hidden: false,
            BaselineCost: 0,
            UpdatedCost: 0,
        },
    });

    const costDistributionChartColors: string[] = ["#0099BC", "#77004D", "#4F68ED", "#AE8C00"];

    React.useEffect(() => {
        ReCalculateEstimatedCost();
    }, [resources, costFrequency, selectedCalculatorMode]);

    const suffix = "%";
    const min = 0;
    const max = 100;
    // By default the field grows to fit available width. Constrain the width instead.
    const spinbuttonStyles: Partial<ISpinButtonStyles> = { spinButtonWrapper: { minWidth: 40 } };

    /** Remove the suffix or any other text after the numbers, or return undefined if not a number */
    const getNumericPart = (value: string): number | undefined => {
        const valueRegex = /^(-?\d+(\.\d+)?).*/;
        if (valueRegex.test(value)) {
            const numericValue = Number(value.replace(valueRegex, "$1"));
            return isNaN(numericValue) ? undefined : numericValue;
        }
        return undefined;
    };

    /** Increment the value (or return nothing to keep the previous value if invalid) */
    const onIncrement = React.useCallback(
        (resource: string): string | void => {
            const tempResources = { ...resources };
            if (selectedCalculatorMode == "Automated") {
                tempResources[resource].NewValueRatio += 1;
                setResources(tempResources);
                return tempResources[resource].NewValueRatio - 100 + suffix;
            } else {
                tempResources[resource].NewValueRatioManually += 1;
                setResources(tempResources);
                return tempResources[resource].NewValueRatioManually - 100 + suffix;
            }
        },
        [selectedCalculatorMode]
    );

    /** Decrement the value (or return nothing to keep the previous value if invalid) */
    const onDecrement = React.useCallback(
        (resource: string): string | void => {
            const tempResources = { ...resources };
            if (selectedCalculatorMode == "Automated") {
                tempResources[resource].NewValueRatio -= 1;
                setResources(tempResources);
                return tempResources[resource].NewValueRatio - 100 + suffix;
            } else {
                tempResources[resource].NewValueRatioManually -= 1;
                setResources(tempResources);
                return tempResources[resource].NewValueRatioManually - 100 + suffix;
            }
        },
        [selectedCalculatorMode]
    );

    /**
     * Clamp the value within the valid range (or return nothing to keep the previous value
     * if there's not valid numeric input)
     */
    const onValidate = (value: string, event?: React.SyntheticEvent<HTMLElement>): string | void => {
        const numericValue = getNumericPart(value);
        if (numericValue !== undefined) {
            return String(numericValue) + suffix;
        }
    };

    /** This will be called after each change */
    const onChange = (resource: string, value: string): void => {
        const tempResources = { ...resources };
        let numericValue = Math.round(getNumericPart(value) || 0);
        if (numericValue !== undefined) {
            numericValue = Math.max(numericValue, -100);
            if (selectedCalculatorMode == "Automated") {
                tempResources[resource].NewValueRatio = 100 + numericValue;
            } else {
                tempResources[resource].NewValueRatioManually = 100 + numericValue;
            }
            setResources(tempResources);
        }
    };

    function changeCostFrequency(frequency: string) {
        setCostFrequency(frequency);
    }

    const SupportedFilters = [
        CategoryDivision.Service,
        CategoryDivision.PlatformProcess,
        CategoryDivision.PlatformApp,
        CategoryDivision.PlatformClient,
        CategoryDivision.PlatformClientComponent,
    ];

    function calcTotalResources(resource: ResourceMetric): number {
        if (selectedCalculatorMode == "Automated") {
            return Math.round((resource.OldValue * resource.NewValueRatio) / 100);
        } else {
            return Math.round((resource.OldValueManually * resource.NewValueRatioManually) / 100);
        }
    }

    function GetOldValue(resource: ResourceMetric): number {
        if (selectedCalculatorMode == "Automated") {
            return Math.round(resource.OldValue);
        } else {
            return Math.round(resource.OldValueManually);
        }
    }

    function GetNewValueRatio(resource: ResourceMetric): number {
        if (selectedCalculatorMode == "Automated") {
            return Math.round(resource.NewValueRatio);
        } else {
            return Math.round(resource.NewValueRatioManually);
        }
    }

    const onCalculatorModeChange = React.useCallback((ev: FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined) => {
        if (option) {
            switch (option.key) {
                case "yes":
                    setSelectedCalculatorMode("Manual");
                    break;
                case "no":
                    setSelectedCalculatorMode("Automated");
                    break;
                default:
                    break;
            }
        }
    }, []);

    const onManuallyInputMetrics = React.useCallback((resource: string, oldValue?: string | undefined) => {
        const tempResources = { ...resources };
        let numericValue = 0;
        if (oldValue) {
            numericValue = Number(oldValue.replace(/\D/g, ""));
        }
        if (numericValue > Number.MAX_SAFE_INTEGER) {
            numericValue = Number.MAX_SAFE_INTEGER;
        }
        if (!isNaN(numericValue) && numericValue >= 0) {
            tempResources[resource].OldValueManually = numericValue;
            setResources(tempResources);
        }
    }, []);

    const calculatorModeOptions: IChoiceGroupOption[] = [
        { key: "yes", text: "Yes" },
        { key: "no", text: "No" },
    ];

    function onClickActionButton(resource: string) {
        const tempResources = { ...resources };
        const tempCost = { ...resourcesEstimatedCost };
        tempResources[resource].Selected = !tempResources[resource].Selected;
        tempCost[resource].Selected = !tempCost[resource].Selected;
        setResources(tempResources);
        setResourceEstimatedCost(tempCost);
    }

    function GetShowedCost(cost: number, selected: boolean, name: string): string {
        if (!selected) {
            return "$0";
        }
        const result = "$" + formatNumber(cost, 0);
        return result;
    }

    const onRenderLabel = (labelName: string, hintContent: string) => (
        <th style={{ fontSize: "14", fontWeight: "600" }}>
            {labelName}
            <TooltipHost styles={{ root: styles.tooltipIcon }} content={hintContent}>
                <Icon iconName="Info" />
            </TooltipHost>
        </th>
    );

    const calculateImpact = (cost: ResourceEstimatedCost) => {
        if (!cost.Selected) {
            return "$0";
        }
        const impact = cost.UpdatedCost - cost.BaselineCost;
        const impactSign = impact > 0 ? "+" : impact < 0 ? "-" : "";
        const result = `${impactSign}$${formatNumber(Math.abs(impact), 0)}`;
        return result;
    };

    return (
        <div className={styles.page}>
            <PageHeader
                title="Substrate COGS Calculator"
                description="Welcome to Substrate COGS calculator!"
                link="https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/411331/How-to-use-COGS-Calculator"
                linkText="Learn more"
                inlineLink
                className={styles.pageHeader}
            />
            {
                <>
                    {selectedCalculatorMode == "Automated" && (
                        <FiltersBanner supportedFilters={SupportedFilters} showViewComponents={false} scenario="COGSCalculator" />
                    )}
                    {isPlatformWorkloadSelected ? (
                        <div className={styles.layoutCard}>
                            <div className={styles.calculatorHeader}>Calculator</div>
                            <div className={styles.greySplitter}></div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "stretch",
                                    paddingLeft: window.screen.width * window.devicePixelRatio > 1920 ? "40px" : "16px",
                                    paddingTop: "30px",
                                    paddingRight: window.screen.width * window.devicePixelRatio > 1920 ? "40px" : "16px",
                                }}
                            >
                                <div style={{ width: "60%", height: 670, boxSizing: "border-box", position: "relative", marginRight: "40px" }}>
                                    <div>
                                        <ChoiceGroup
                                            defaultSelectedKey="no"
                                            options={calculatorModeOptions}
                                            label="Do you want to manually input metrics?"
                                            className={styles.inlineflex}
                                            required={true}
                                            onChange={onCalculatorModeChange}
                                        />
                                    </div>
                                    <div className={styles.section} style={{ marginTop: "10px" }}>
                                        <div className={styles.sectionHeaderContent2}>
                                            <h3 className={styles.sectionHeaderContent2}>Baseline resources</h3>
                                        </div>
                                    </div>
                                    <div className={styles.blackSplitter} style={{ marginBottom: "10px", marginTop: "49.5px" }}></div>
                                    <div
                                        className={
                                            selectedCalculatorMode == "Automated"
                                                ? window.screen.width * window.devicePixelRatio > 1920
                                                    ? styles.CalculatorHeaderGrid
                                                    : styles.CalculatorHeaderGridSmallScreen
                                                : styles.CalculatorHeaderGridManualMode
                                        }
                                    >
                                        {selectedCalculatorMode == "Automated" && (
                                            <>
                                                <th style={{ fontSize: "small", fontWeight: "400", textAlign: "left" }}>Included</th>
                                                <th style={{ fontSize: "small", fontWeight: "400", textAlign: "left" }}>Metric</th>
                                                <th style={{ fontSize: "small", fontWeight: "400", textAlign: "left" }}>Baseline Resources</th>
                                                <th style={{ fontSize: "small", fontWeight: "400", textAlign: "left" }}>Change %</th>
                                                <th style={{ fontSize: "small", fontWeight: "400", textAlign: "left" }}>Updated Resources</th>
                                            </>
                                        )}
                                        {selectedCalculatorMode == "Manual" && (
                                            <>
                                                <th style={{ fontSize: "small", fontWeight: "400", textAlign: "left" }}>Included</th>
                                                <th style={{ fontSize: "small", fontWeight: "400", textAlign: "left" }}>Metric</th>
                                                <th style={{ fontSize: "small", fontWeight: "400", textAlign: "left" }}>Resources</th>
                                            </>
                                        )}
                                    </div>
                                    <div className={styles.rowBorder} />
                                    {!isLoading || selectedCalculatorMode == "Manual" ? (
                                        <div
                                            className={
                                                selectedCalculatorMode == "Automated"
                                                    ? window.screen.width * window.devicePixelRatio > 1920
                                                        ? styles.CalculatorGrid
                                                        : styles.CalculatorGridSmallScreen
                                                    : styles.CalculatorGridManualMode
                                            }
                                        >
                                            {Object.values(resources)
                                                .filter((item) => onlyStoreServiceSelected || ["HddStorage", "SsdStorage"].indexOf(item.Name) < 0)
                                                .map((value) => (
                                                    <>
                                                        <React.Fragment key={value.DisplayName}>
                                                            <td style={{ verticalAlign: "center" }}>
                                                                <Checkbox
                                                                    className={styles.checkBox}
                                                                    checked={value.Selected}
                                                                    onChange={() => onClickActionButton(value.Name)}
                                                                />
                                                            </td>
                                                            <td style={{ fontSize: "14px", fontWeight: "600", verticalAlign: "center", textAlign: "left" }}>
                                                                {value.DisplayName}
                                                                <TooltipHost styles={{ root: styles.tooltipIcon }} content={value.Hint}>
                                                                    <Icon iconName="Info" />
                                                                </TooltipHost>
                                                            </td>
                                                            <TextField
                                                                disabled={selectedCalculatorMode == "Automated" ? true : false}
                                                                style={{ fontWeight: "600", fontSize: "14px" }}
                                                                suffix={value.Suffix}
                                                                onChange={(event, oldValue) => onManuallyInputMetrics(value.Name, oldValue)}
                                                                value={formatNumber(Math.round(GetOldValue(value)), 0)}
                                                                styles={{
                                                                    subComponentStyles: { label: { padding: "7px 0" } },
                                                                    suffix: { backgroundColor: selectedCalculatorMode == "Automated" ? "#f3f2f1" : "white" },
                                                                }}
                                                            />
                                                            {selectedCalculatorMode == "Automated" && (
                                                                <>
                                                                    <SpinButton
                                                                        value={GetNewValueRatio(value) - 100 + suffix}
                                                                        disabled={value.Selected ? false : true}
                                                                        min={min}
                                                                        max={max}
                                                                        onValidate={onValidate}
                                                                        onIncrement={() => onIncrement(value.Name)}
                                                                        onDecrement={() => onDecrement(value.Name)}
                                                                        onChange={(event, changeValue) => onChange(value.Name, changeValue || "0%")}
                                                                        incrementButtonAriaLabel={value.Name}
                                                                        decrementButtonAriaLabel={value.Name}
                                                                        styles={spinbuttonStyles}
                                                                        style={{ fontWeight: "600", fontSize: "14px" }}
                                                                    />
                                                                    <div
                                                                        className={
                                                                            value.Selected ? styles.updatedResourceSpan : styles.updatedResourceSpanDisabled
                                                                        }
                                                                        style={{ fontWeight: "600", fontSize: "14px" }}
                                                                    >
                                                                        {formatNumber(calcTotalResources(value), 0) + value.Suffix}
                                                                    </div>
                                                                </>
                                                            )}
                                                        </React.Fragment>
                                                    </>
                                                ))}
                                        </div>
                                    ) : (
                                        <LoadingState />
                                    )}
                                </div>
                                <div style={{ width: "40%", height: 670, boxSizing: "border-box", position: "relative" }}>
                                    <div style={{ visibility: "hidden" }}>
                                        <ChoiceGroup
                                            defaultSelectedKey="no"
                                            options={calculatorModeOptions}
                                            label="Do you want to manually input metrics?"
                                            className={styles.inlineflex}
                                            required={true}
                                            onChange={onCalculatorModeChange}
                                        />
                                    </div>
                                    <div className={styles.section} style={{ marginTop: "10px" }}>
                                        <div className={styles.sectionHeaderContent2}>
                                            <h3 className={styles.sectionHeaderContent2}>Estimated cost results</h3>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <div
                                            className={costFrequency == "Daily" ? styles.FrequencyButtonSelected : styles.FrequencyButton}
                                            onClick={() => changeCostFrequency("Daily")}
                                        >
                                            Daily
                                        </div>
                                        <div
                                            className={costFrequency == "Monthly" ? styles.FrequencyButtonSelected : styles.FrequencyButton}
                                            onClick={() => changeCostFrequency("Monthly")}
                                        >
                                            Monthly
                                        </div>
                                        <div
                                            className={costFrequency == "Annual" ? styles.FrequencyButtonSelected : styles.FrequencyButton}
                                            onClick={() => changeCostFrequency("Annual")}
                                        >
                                            Annual
                                        </div>
                                    </div>
                                    <div className={styles.blackSplitter} style={{ marginBottom: "10px", marginTop: "19px" }}></div>
                                    <div
                                        className={selectedCalculatorMode == "Automated" ? styles.ResultHeaderGrid : styles.ResultHeaderGridManualMode}
                                        style={{ marginTop: "10px" }}
                                    >
                                        <>
                                            {selectedCalculatorMode == "Automated" && (
                                                <>
                                                    <th style={{ fontSize: "small", fontWeight: "400", textAlign: "right", visibility: "hidden" }}>
                                                        Resources
                                                    </th>
                                                    <th style={{ fontSize: "small", fontWeight: "400", textAlign: "right" }}>
                                                        Baseline Cost
                                                        <TooltipHost
                                                            styles={{ root: styles.tooltipIcon }}
                                                            content="the cost based on the historical resource usage data."
                                                        >
                                                            <Icon iconName="Info" />
                                                        </TooltipHost>
                                                    </th>
                                                    <th style={{ fontSize: "small", fontWeight: "400", textAlign: "right" }}>
                                                        Updated Cost
                                                        <TooltipHost
                                                            styles={{ root: styles.tooltipIcon }}
                                                            content="the estimated cost after you adjust the resource usage."
                                                        >
                                                            <Icon iconName="Info" />
                                                        </TooltipHost>
                                                    </th>
                                                    <th style={{ fontSize: "small", fontWeight: "400", textAlign: "right" }}>
                                                        Impact
                                                        <TooltipHost styles={{ root: styles.tooltipIcon }} content="the increase or decrease of cost.">
                                                            <Icon iconName="Info" />
                                                        </TooltipHost>
                                                    </th>
                                                </>
                                            )}
                                            {selectedCalculatorMode == "Manual" && (
                                                <>
                                                    <th style={{ fontSize: "small", fontWeight: "400", textAlign: "right" }}>Metric</th>
                                                    <th style={{ fontSize: "small", fontWeight: "400", textAlign: "right" }}>
                                                        Cost
                                                        <TooltipHost styles={{ root: styles.tooltipIcon }} content="the cost based on your input metrics.">
                                                            <Icon iconName="Info" />
                                                        </TooltipHost>
                                                    </th>
                                                </>
                                            )}
                                        </>
                                    </div>
                                    <div className={styles.rowBorder} />
                                    {!isLoading || selectedCalculatorMode == "Manual" ? (
                                        <div
                                            className={selectedCalculatorMode == "Automated" ? styles.ResultBodyGrid : styles.ResultBodyGridManualMode}
                                            style={{ marginTop: "10px" }}
                                        >
                                            {Object.values(resourcesEstimatedCost)
                                                .filter((item) => onlyStoreServiceSelected || ["HddStorage", "SsdStorage"].indexOf(item.Name) < 0)
                                                .map((value) => (
                                                    <React.Fragment key={value.Name}>
                                                        <div
                                                            style={{
                                                                textAlign: selectedCalculatorMode == "Automated" ? "center" : "right",
                                                                fontWeight: value.DisplayName == "Total" ? "700" : "600",
                                                                fontSize: value.DisplayName == "Total" ? "16px" : "14px",
                                                            }}
                                                        >
                                                            {value.DisplayName == "Total" && value.DisplayName + " / " + costFrequency + ":"}
                                                            {selectedCalculatorMode == "Manual" && value.DisplayName != "Total" && value.DisplayName}
                                                        </div>
                                                        {selectedCalculatorMode == "Automated" && (
                                                            <div
                                                                className={value.Selected || value.Name == "Total" ? styles.costCell : styles.costCellExcluded}
                                                                style={{
                                                                    textAlign: "right",
                                                                    fontWeight: value.Name == "Total" ? "700" : "600",
                                                                    fontSize: value.Name == "Total" ? "16px" : "14px",
                                                                }}
                                                            >
                                                                {GetShowedCost(value.BaselineCost, value.Selected, value.Name)}
                                                            </div>
                                                        )}
                                                        <div
                                                            className={value.Selected || value.Name == "Total" ? styles.costCell : styles.costCellExcluded}
                                                            style={{
                                                                textAlign: selectedCalculatorMode == "Automated" ? "right" : "right",
                                                                fontWeight: value.Name == "Total" ? "700" : "600",
                                                                fontSize: value.Name == "Total" ? "16px" : "14px",
                                                            }}
                                                        >
                                                            {GetShowedCost(value.UpdatedCost, value.Selected, value.Name)}
                                                        </div>
                                                        {selectedCalculatorMode == "Automated" && (
                                                            <div
                                                                className={value.Selected || value.Name == "Total" ? styles.costCell : styles.costCellExcluded}
                                                                style={{
                                                                    textAlign: "right",
                                                                    fontWeight: value.Name == "Total" ? "700" : "600",
                                                                    fontSize: value.Name == "Total" ? "16px" : "14px",
                                                                }}
                                                            >
                                                                {calculateImpact(value)}
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                        </div>
                                    ) : (
                                        <LoadingState />
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <h3>Please click on &#34;Add Filter&#34; button to select workloads you need to start the calculation!</h3>
                            {showTutorialVideo && (
                                <>
                                    <h3 style={{ marginTop: "50px" }}>Tutorial Video:</h3>
                                    <video
                                        controls
                                        width={window.screen.width * window.devicePixelRatio > 1920 ? "1280" : "853"}
                                        height={window.screen.width * window.devicePixelRatio > 1920 ? "720" : "480"}
                                    >
                                        <source src={require<string>("./Tutorial.mp4")} type="video/mp4" />
                                    </video>
                                </>
                            )}
                        </>
                    )}
                </>
            }
        </div>
    );
};
