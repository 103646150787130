// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rAPuQjQhLKFhZAmzNkFi {\n  width: 300px;\n  padding: 13px 26px 16px;\n  background: #FFFFFF;\n  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);\n  border-radius: 2px;\n}\n.wwJjtLFrd0dAFb5LFfXF {\n  margin-bottom: 12px;\n  font-weight: 600;\n}\n.P77xnec8LYYAz4WQiKdg {\n  width: 94px;\n  margin-right: 16px;\n}\n.Xe9UyNvMnc60LlgksNbr {\n  margin-top: 24px;\n}\n.Xe9UyNvMnc60LlgksNbr .ms-Button {\n  border: none;\n  margin-right: 8px;\n}\n.Xe9UyNvMnc60LlgksNbr .ms-Button--default {\n  background: #E8E8E8;\n}\n.Xe9UyNvMnc60LlgksNbr .ms-Button--default:hover {\n  background: #C8C6C4;\n}\n", "",{"version":3,"sources":["webpack://./src/components/filter/filterList/FilterForm.less"],"names":[],"mappings":"AACA;EACI,YAAA;EACA,uBAAA;EAEA,mBAAA;EACA,2CAAA;EACA,kBAAA;AADJ;AAIA;EACI,mBAAA;EACA,gBAAA;AAFJ;AAKA;EACI,WAAA;EACA,kBAAA;AAHJ;AAMA;EACI,gBAAA;AAJJ;AAGA;EAIQ,YAAA;EACA,iBAAA;AAJR;AADA;EASQ,mBAAA;AALR;AAMQ;EACI,mBAAA;AAJZ","sourcesContent":["\n.filterForm {\n    width: 300px;\n    padding: 13px 26px 16px;\n    \n    background: #FFFFFF;\n    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);\n    border-radius: 2px;\n}\n\n.filterFormTitle {\n    margin-bottom: 12px;\n    font-weight: 600;\n}\n\n.filterFormOperator {\n    width: 94px;\n    margin-right: 16px;\n}\n\n.filterFormActions {\n    margin-top: 24px;\n\n    :global(.ms-Button) {\n        border: none;\n        margin-right: 8px;\n    }\n\n    :global(.ms-Button--default) {\n        background: #E8E8E8;\n        &:hover {\n            background: #C8C6C4;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterForm": "rAPuQjQhLKFhZAmzNkFi",
	"filterFormTitle": "wwJjtLFrd0dAFb5LFfXF",
	"filterFormOperator": "P77xnec8LYYAz4WQiKdg",
	"filterFormActions": "Xe9UyNvMnc60LlgksNbr"
};
export default ___CSS_LOADER_EXPORT___;
