import { Callout, Icon } from '@fluentui/react';
import React, { useMemo, useState } from 'react';
import { FilterForm } from './FilterForm';
import { FilterGroupOperator } from './FilterListConstants';
import { FilterType, IFilterItem } from './FilterList';
import styles from './FilterListAddBtn.less';

export interface IFilterListProps {
    data: IFilterItem[];
    options: IFilterOption[];
    onChange: (filters: IFilterItem[]) => void;
}

export interface IFilterOption {
    key: string;
    displayName: string;
    type: FilterType;
}

export interface IFilterListAddProps {
    options: IFilterOption[];
    onAdd: (data: IFilterItem) => void;
    validator?: (key: string, value: string, filterType: FilterType) => string | null;
}

export const FilterListAddBtn : React.FC<IFilterListAddProps> = (props: IFilterListAddProps) => {
    const [selectedKey, setSelectedKey] = useState<string>('');
    const [active, setActive] = useState<boolean>(false);

    const clear = () => {
        setActive(false);
        setSelectedKey('');
    };

    const handleAddConfirm = (operator: FilterGroupOperator, type: FilterType, value: string) => {
        props.onAdd(type !== FilterType.String ? {
            operator,
            value,
            key: selectedKey,
        } : {
            operator,
            keyword: value?.trim() || '',
            key: selectedKey,
        });

        clear();
    };

    const filterKeyDisplayNames = useMemo(() => {
        return props.options.reduce<Record<string, string>>((acc, cur) => {
            acc[cur.key] = cur.displayName;
            return acc;
        }, {});
    }, [props.options]);

    const filterTypes = useMemo(() => {
        return props.options.reduce<Record<string, FilterType>>((acc, cur) => {
            acc[cur.key] = cur.type;
            return acc;
        }, {});
    }, [props.options]);

    const addBtnRef = React.useRef<HTMLDivElement>(null);

    return (
        <div ref={addBtnRef} className={`${styles.filterListAddBtn} ${active ? 'active' : ''}`} onClick={() => setActive(!active)}>
            <Icon iconName="AddFilter" style={{ marginRight: 6 }} />
            <span>Add Filter</span>
            { active && !selectedKey && (
                <Callout gapSpace={6} target={addBtnRef} isBeakVisible={false} onDismiss={clear}>
                    <div className={styles.optionList}>{props.options.map(item => <div key={item.key} className={styles.optionListItem} onClick={() => setSelectedKey(item.key)}>{item.displayName}</div>)}</div>
                </Callout>)}
            { active && selectedKey && (
                <Callout gapSpace={6} target={addBtnRef} isBeakVisible={false} onDismiss={clear}>
                    <FilterForm validator={props.validator} filterKey={selectedKey} filterName={filterKeyDisplayNames[selectedKey]} filterType={filterTypes[selectedKey]} onCancel={clear} onConfirm={handleAddConfirm} />
                </Callout>)}
        </div>
    );
};
