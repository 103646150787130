import * as React from "react";
import styles from "../Page.less";
import PageWrapper from "../PageWrapper";
import {
    useGetAzureRegionFYCost,
    useGetAzureServiceFYCost,
    useGetAzureSubscriptionFYCost,
    useGetAzureSubscriptionMonthlyCost,
    useGetAzureRegionMonthlyCost,
    useGetAzureServiceMonthToMonthCost,
    useGetSubscriptionMapping,
} from "../../../../hooks/useES365CostQuery";
import { ESCostDataDimensions, SubscriptionMapping } from "../../../../models/ESCostModels";
import ResponseBoundary from "../../../ResponseBoundary/ResponseBoundary";
import TopAzureCostBarChart from "../../Charts/Azure/TopAzureCostBarChart";
import { TopRegionSubscriptionCostMonthlyTrend } from "../../Charts/Azure/TopRegionSubscriptionCostMonthlyTrend";
import MonthToMonthCostTable from "../../Charts/Common/MonthToMonthCostTable";

export type SubscriptionNameMapping = { [key: string]: string };

export function changeToDict(rawData?: SubscriptionMapping[]): SubscriptionNameMapping {
    if (rawData === undefined) {
        return {};
    }
    return rawData.reduce((acc, item) => {
        acc[item[ESCostDataDimensions.SubscriptionMappingId]] = item[ESCostDataDimensions.SubscriptionName];
        return acc;
    }, {} as SubscriptionNameMapping);
}

const AzureDetailsPage: React.FC = () => {
    const { data: subscriptionMapping, isLoading: isSubscriptionMappingLoading, isError: isSubscriptionMappingError } = useGetSubscriptionMapping();
    const { data: esAzureDataSourceCost, isLoading: isAzureDataSourceCostLoading, isError: isAzureDataSourceCostError } = useGetAzureServiceFYCost();
    const { data: esAzureSubscriptionCost, isLoading: isAzureSubscriptionLoading, isError: isAzureSubscriptionError } = useGetAzureSubscriptionFYCost();
    const {
        data: esAzureSubscriptionMonthlyCost,
        isLoading: isAzureSubscriptionMonthlyLoading,
        isError: isAzureSubscriptionMonthlyError,
    } = useGetAzureSubscriptionMonthlyCost();
    const { data: esAzureRegionCost, isLoading: isAzureRegionLoading, isError: isAzureRegionError } = useGetAzureRegionFYCost();
    const { data: esAzureRegionMonthlyCost, isLoading: isAzureRegionMonthlyLoading, isError: isAzureRegionMonthlyError } = useGetAzureRegionMonthlyCost();
    const { data: esServiceCost, isLoading: isServiceCostLoading, isError: isServiceCostError } = useGetAzureServiceMonthToMonthCost();

    const mapping = React.useMemo(() => changeToDict(subscriptionMapping), [subscriptionMapping]);
    return (
        <PageWrapper
            title={`Azure cost detail`}
            description={`Azure cost detail view will bring awareness to repo, usage scenario, build roles, build queues, for both partner team and ES Build SME.`}
        >
            <div>
                <ResponseBoundary
                    isLoading={isAzureRegionLoading || isAzureRegionMonthlyLoading}
                    errorHappened={isAzureRegionError || isAzureRegionMonthlyError}
                    data={esAzureRegionCost && esAzureRegionMonthlyCost}
                >
                    {esAzureRegionCost !== undefined && esAzureRegionMonthlyCost !== undefined ? (
                        <TopRegionSubscriptionCostMonthlyTrend
                            yearlyData={esAzureRegionCost}
                            monthlyData={esAzureRegionMonthlyCost}
                            description="Top 10 Azure cost in different regions"
                            targetDimension={ESCostDataDimensions.Region}
                            top={10}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
            <div>
                <ResponseBoundary
                    isLoading={isAzureSubscriptionLoading || isAzureSubscriptionMonthlyLoading || isSubscriptionMappingLoading}
                    errorHappened={isAzureSubscriptionError || isAzureSubscriptionMonthlyError || isSubscriptionMappingError}
                    data={esAzureSubscriptionCost && esAzureSubscriptionMonthlyCost && subscriptionMapping}
                >
                    {esAzureSubscriptionCost !== undefined && esAzureSubscriptionMonthlyCost !== undefined ? (
                        <TopRegionSubscriptionCostMonthlyTrend
                            yearlyData={esAzureSubscriptionCost}
                            monthlyData={esAzureSubscriptionMonthlyCost}
                            description="Top 10 Azure cost in different subscriptions"
                            targetDimension={ESCostDataDimensions.SubscriptionId}
                            top={10}
                            subscriptionMapping={mapping}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
            <div className={styles.gridForSingleCell}>
                <ResponseBoundary isLoading={isAzureDataSourceCostLoading} errorHappened={isAzureDataSourceCostError} data={esAzureDataSourceCost}>
                    {esAzureDataSourceCost !== undefined ? (
                        <TopAzureCostBarChart
                            data={esAzureDataSourceCost}
                            description={"Top 10 Azure cost in different services"}
                            targetDimension={ESCostDataDimensions.Service}
                            top={10}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
            <div>
                <ResponseBoundary isLoading={isServiceCostLoading} errorHappened={isServiceCostError} data={esServiceCost}>
                    {esServiceCost !== undefined ? (
                        <MonthToMonthCostTable
                            data={esServiceCost}
                            targetDimension={ESCostDataDimensions.Cost}
                            splitDimension={ESCostDataDimensions.Service}
                            description={`Azure cost MoM comparison of all services`}
                            isCurrency={true}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
        </PageWrapper>
    );
};

export default AzureDetailsPage;
