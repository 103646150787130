import { FiltersAction, FiltersView } from "../reducers/filterReducer";
import { IButtonStyles, IIconProps, ISpinnerStyles, IStackItemStyles, IStackStyles, IStackTokens, IconButton, MotionAnimations, Spinner, Stack } from "@fluentui/react";
import { IServiceTreeData, getCategoryByServiceTreeLevel } from "../reducers/serviceTreeReducer";
import { LogComponent, LogElement, LogTarget } from "../models/LogModel";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import AppBanner from "./banner/AppBanner";
import { CategoryDivision } from "../models/CategoryDivision";
import { Dialogs } from "./Dialogs";
import FilterBar from "./filter/FilterBar";
import { IAppState } from "../store";
import Navigation from "./Navigation";
import PortalTeachingBubbles from "./teaching/PortalTeachingBubbles";
import SavedViews from "./savedViews/SavedViews";
import { ServiceTreeItem } from "../models/serviceTree";
import ServiceTreeNav from "./ServiceTreeNav";
import { getServiceItemListByKeys } from "../utils/FiltersUtils";
import styles from "./PageLayout.less";
import { trackEventCallback } from "../utils/AppInsights";
import { useBoolean } from "@fluentui/react-hooks";
import { useCategoryFilters } from "../hooks/useFilters";
import { useFlights } from "../hooks/useSettings";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

const outerStackStyles: IStackStyles = {
    root: {
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden"
    }
};

const filterStackStyles: IStackStyles = {
    root: {
        width: "100%",
        height: 50,
        backgroundColor: "#E9E9E9",
        borderBottom: "1px solid #C8C8C8",
        alignItems: "center"
    }
};

const contentStackStyles: IStackStyles = {
    root: {
        height: "calc(100% - 98px)"
    }
};

const stackItemStyles: IStackItemStyles = {
    root: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
    },
};

const buttonStyles: IButtonStyles = {
    root: {
        width: 48,
        height: 48,
        color: "black"
    }
};

const spinnerStyles: ISpinnerStyles = {
    root: {
        height: "100%",
        marginTop: 12,
    }
};

const navIcon: IIconProps = { iconName: "GlobalNavButton" };

const filterStackTokens: IStackTokens = {
    childrenGap: 16
};

export interface IPageLayoutProps {
    containerClassName?: string;
}

const PageLayout: React.FC<IPageLayoutProps> = (props) => {
    const flexibleViewBreakPoint = 1080;
    const { search, pathname } = useLocation();
    const [isFlexibleView, setIsFlexibleView] = useState(window.innerWidth < flexibleViewBreakPoint);
    const [open, { toggle, setFalse: setSidebarHide, setTrue: setSidebarOpen }] = useBoolean(!pathname.startsWith("/LandingPage") && window.innerWidth >= flexibleViewBreakPoint);
    const isManualCloseSidebarRef = useRef(false);
    const isFlexibleViewRef = useRef(isFlexibleView);
    const query = React.useMemo(() => new URLSearchParams(search), [search]);
    const globalFilters = useCategoryFilters();
    const filtersNextAction = useSelector<IAppState, FiltersAction>(state => state.filtersNextAction);
    const {data : flights} = useFlights();
    isFlexibleViewRef.current = isFlexibleView;

    const handleManualToggleSidebar = () => {
        isManualCloseSidebarRef.current = !isFlexibleView && open;

        toggle();
    };

    const handleHideMask = () => {
        if (open && isFlexibleView) {
            setSidebarHide();
        }
    };

    const containerRef = useRef<HTMLDivElement>()

    useEffect(() => {
        containerRef.current?.scrollTo(0, 0)
      }, [containerRef.current, pathname])

    useEffect(() => {
        const source = query.get("source");
        if (source) {
            trackEventCallback(LogComponent.Url, LogElement.Source, source, LogTarget.Other);
        }

        window.addEventListener('resize', () => {
            const nextIsFlexibleView = window.innerWidth < flexibleViewBreakPoint;

            if (nextIsFlexibleView === isFlexibleViewRef.current) return;

            if (!nextIsFlexibleView && !isManualCloseSidebarRef.current) {
                setSidebarOpen();
            }

            if (nextIsFlexibleView) {
                setSidebarHide();
            }

            setIsFlexibleView(nextIsFlexibleView);
        });
    }, []);

    const { serviceTree: data, isLoading, errorHappened } = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const globalSelectedServices = useMemo(() => {
        return getServiceItemListByKeys(globalFilters.filters.filters, data?.items);
    }, [data?.items, globalFilters.filters.filters]);

    const handleServicesChange = useCallback((items: ServiceTreeItem[]) => {
        if (items.length) {
            const filters: Partial<Record<CategoryDivision, string[]>> = {};
            items.forEach(item => {
                const category = getCategoryByServiceTreeLevel(item.l);
                if (!filters[category]) {
                    filters[category] = [];
                }
                filters[category]?.push(item.id);
            });
            globalFilters.updateFilters(filtersNextAction, {filters, view: FiltersView.Summary});
        }
    }, [filtersNextAction, globalFilters]);

    const selectedIds = useMemo(() => globalSelectedServices.map(s => s.id), [globalSelectedServices]);

    useEffect(() => {
        window.dispatchEvent(new Event("resize"));
    }, [open]);

    const sidebarRef = useRef<HTMLDivElement>(null);

    const onMouseDownOnResizer = useCallback(function(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        // Get the current mouse position
        const x = e.clientX;

        const sidebar = sidebarRef.current as HTMLDivElement;

        const w = sidebar.offsetWidth;

        function clickHandler(e: MouseEvent) {
            e.stopImmediatePropagation();
        }

        function mouseMoveHandler(e: MouseEvent) {
            // Determine how far the mouse has been moved
            const dx = e.clientX - x;

            sidebar.style.width = `${w + dx}px`;
        }
    
        // When user releases the mouse, remove the existing event listeners
        function mouseUpHandler() {
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);

            // Wait 500ms to avoid mis-click.
            setTimeout(function() { document.removeEventListener('click', clickHandler, {capture: true}) }, 500);
        }
 
        // Attach listeners for document's events
        document.addEventListener('click', clickHandler, {capture: true});
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    }, []);

    return (
        <Stack styles={outerStackStyles} disableShrink>
            { open && isFlexibleView && <div onClick={handleHideMask} className={`${styles.flexibleSidebarMask}`} style={{ animation: MotionAnimations.fadeIn }} /> }
            <div onClick={handleHideMask} className={styles.appBannerContainer}><AppBanner /></div>
            {!pathname.startsWith("/CogsCalculator") && !pathname.startsWith("/SubstratePlatform") && <Stack horizontal styles={filterStackStyles} className={open && isFlexibleView ? styles.filterStackActive : undefined} tokens={filterStackTokens}>
                {(!pathname.startsWith("/LandingPage") || !flights?.enableCreateView) && 
                <Stack.Item styles={stackItemStyles} className={styles.toggleSidebarButton}>
                    <IconButton
                        id="toggle-sidebar-btn"
                        iconProps={navIcon}
                        styles={buttonStyles}
                        onClick={handleManualToggleSidebar}
                        ariaLabel="Toggle sidebar"
                    />
                </Stack.Item>}
                
                <FilterBar />
            </Stack>}
            <Stack horizontal styles={contentStackStyles}>
                {open && !pathname.startsWith("/CogsCalculator") && !pathname.startsWith("/LandingPage") && !pathname.startsWith("/SubstratePlatform") &&
                    <div className={`${styles.sidebar} ${isFlexibleView ? styles.flexibleSidebar : ''}`} ref={sidebarRef}>
                        <Stack style={{position: "relative", minHeight: "100%"}}>
                            {(!flights?.enableCreateView || !pathname.startsWith("/LandingPage")) && 
                            (
                                <><Stack.Item shrink={0}>
                                    <SavedViews />
                                </Stack.Item><Stack.Item shrink={0}>
                                        <div className={styles.separator} />
                                    </Stack.Item><Stack.Item shrink={1}>
                                        {errorHappened && <div>Something went wrong ...</div>}
                                        {isLoading && <Spinner label="Loading..." styles={spinnerStyles} />}
                                        {data &&
                                            <ServiceTreeNav serviceTree={data} selectedIds={selectedIds} onChange={handleServicesChange} />}
                                    </Stack.Item></>
                            )}
                            <div className={styles.resizer} onMouseDown={onMouseDownOnResizer} />
                        </Stack>
                    </div>
                }
                {(!pathname.startsWith("/LandingPage") && !pathname.startsWith("/CogsCalculator")) && (<Stack.Item shrink={false} styles={{root: styles.sidebar}}>
                    <Navigation />
                </Stack.Item>)}
                <Dialogs />
                {/* eslint-disable @typescript-eslint/ban-ts-comment */}
                {/** @ts-ignore */}
                <div className={`${props.containerClassName || ''} ${styles.pageContainer}`} pathname={pathname} ref={containerRef}>
                    {props.children}
                </div>
                {/* eslint-enable @typescript-eslint/ban-ts-comment */}
            </Stack>
            {!pathname.startsWith("/CogsCalculator") && <PortalTeachingBubbles isSidebarHidden={!open} />}
        </Stack>
    );
};

export default PageLayout;
