import moment from "moment";

export function isWeekend(date: moment.Moment): boolean {
    const dayOfWeek = date.day();
    return dayOfWeek === 0 || dayOfWeek === 6;
}

export function getDateSeriesByRange(start: moment.Moment, end: moment.Moment) {
    const result : moment.Moment[] = [];

    let current = start;

    while (!current.isAfter(end, 'date')) {
        result.push(moment(current));

        current = moment(current).add(1, 'days');
    }

    return result;
}
