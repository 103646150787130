import * as React from "react";
import styles from "../Page.less";
import PageWrapper from "../PageWrapper";
import {
    useGetCloudBuildCapacityOverview,
    useGetManagerFYCloudBuildCost,
    useGetOverviewFYCost,
    useGetReposMonthToMonthCloudBuildCost,
} from "../../../../hooks/useES365CostQuery";
import ResponseBoundary from "../../../ResponseBoundary/ResponseBoundary";
import OverviewPieChart from "../../Charts/Common/OverviewPieChart";
import { ESCloudBuildOverviewCapacityRawResponse, ESCostBillingType, ESCostDataDimensions, ESOverviewFiscalYearValues } from "../../../../models/ESCostModels";
import TopManagerCostBarChart from "../../Charts/Common/TopManagerCostBarChart";
import MonthToMonthCostTable from "../../Charts/Common/MonthToMonthCostTable";

function changeDataStructure(esFYCapacity?: ESCloudBuildOverviewCapacityRawResponse[]): ESOverviewFiscalYearValues[] | undefined {
    if (esFYCapacity === undefined) {
        return undefined;
    }
    const esFYValues: ESOverviewFiscalYearValues[] = [];
    esFYCapacity.forEach((data) => {
        esFYValues.push({
            ...data,
            [ESCostDataDimensions.BillingType]: ESCostBillingType.CloudBuild,
        });
    });
    return esFYValues;
}

const CloudBuildOverviewPage: React.FC = () => {
    const { data: esFYCost, isLoading, isError } = useGetOverviewFYCost();
    const { data: esFYCapacity, isLoading: isCapacityLoading, isError: isCapacityError } = useGetCloudBuildCapacityOverview();
    const esFYValues = React.useMemo(() => changeDataStructure(esFYCapacity), [esFYCapacity]);
    const { data: esManagerCost, isLoading: isManagerCostLoading, isError: isManagerCostError } = useGetManagerFYCloudBuildCost();
    const { data: esRepoCost, isLoading: isTopRepoCostLoading, isError: isTopRepoCostError } = useGetReposMonthToMonthCloudBuildCost();
    return (
        <PageWrapper
            title="CloudBuild cost overview"
            description="CloudBuild cost overview will be beneficial for understanding CloudBuild cost better, seeking cost optimization opportunities, and making budget planning accordingly. "
        >
            <div className={styles.grid}>
                <ResponseBoundary isLoading={isLoading} errorHappened={isError} data={esFYCost}>
                    {esFYCost !== undefined ? (
                        <OverviewPieChart
                            data={esFYCost}
                            description="Total cost of CloudBuild"
                            totalCountDesc="Total Cost"
                            isCurrency={true}
                            billingType={ESCostBillingType.CloudBuild}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
                <ResponseBoundary isLoading={isCapacityLoading} errorHappened={isCapacityError} data={esFYValues}>
                    {esFYValues !== undefined ? (
                        <OverviewPieChart
                            data={esFYValues}
                            description="Total CloudBuild builders"
                            totalCountDesc="Total builders"
                            isCurrency={false}
                            billingType={ESCostBillingType.CloudBuild}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
            <div className={styles.gridForSingleCell}>
                <ResponseBoundary isLoading={isManagerCostLoading} errorHappened={isManagerCostError} data={esManagerCost}>
                    {esManagerCost !== undefined ? (
                        <TopManagerCostBarChart
                            data={esManagerCost}
                            description="Top 10 GEMs on CloudBuild cost"
                            legendDesc="CloudBuild cost"
                            isCurrency={true}
                            maxCount={10}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
            <div>
                <ResponseBoundary isLoading={isTopRepoCostLoading} errorHappened={isTopRepoCostError} data={esRepoCost}>
                    {esRepoCost !== undefined ? (
                        <MonthToMonthCostTable
                            data={esRepoCost}
                            description="CloudBuild cost MoM comparison of repositories (top 100 cost in last month)"
                            splitDimension={ESCostDataDimensions.Repository}
                            targetDimension={ESCostDataDimensions.Cost}
                            maxCount={100}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
        </PageWrapper>
    );
};

export default CloudBuildOverviewPage;
