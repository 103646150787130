import * as React from "react";
import { ESCostDataDimensions, ESCostOrganization, ESArtifactOverviewFiscalYearValues, ESOverviewTimeSelection } from "../../../../models/ESCostModels";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import PieChart, { IPieChartData } from "../Common/PieChart";
import { ESOrgs } from "../../../../models/constants/ESCostConstants";

type IOverviewCost = IPieChartData & {
    [key in ESCostOrganization]?: number;
};

interface IOverviewPieChartProps {
    data: ESArtifactOverviewFiscalYearValues[];
    description: string;
    totalCountDesc: string;
    isCurrency: boolean;
}

function calculateOverviewValues(year: number, rawData: ESArtifactOverviewFiscalYearValues[]): IOverviewCost {
    const values = rawData.filter((data) => year === data[ESCostDataDimensions.ReturnFiscalYear] && ESOrgs.includes(data[ESCostDataDimensions.Organization]));

    const sum = values.reduce(
        (acc, curr) => {
            const org = curr[ESCostDataDimensions.Organization];
            const v = curr[ESCostDataDimensions.Cost];
            acc[org] = (acc[org] || 0) + (v ?? 0);
            return acc;
        },
        { [ESCostOrganization.OfficeEngineering]: 0, [ESCostOrganization.SubstrateEngineering]: 0 }
    );

    return sum;
}

const ArtifactOverviewPieChart: React.FC<IOverviewPieChartProps> = (props) => {
    const { data: rawData, description, totalCountDesc, isCurrency } = props;
    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );
    const overviewValues = React.useMemo(() => calculateOverviewValues(year, rawData), [rawData, year]);
    const total = Object.values(overviewValues).reduce((acc, curr) => acc + curr, 0);
    return <PieChart data={overviewValues} description={description} totalCountDesc={totalCountDesc} isCurrency={isCurrency} total={total} />;
};

export default ArtifactOverviewPieChart;
