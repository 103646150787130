// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bF4kZiNc4BHD12UldfRT {\n  margin: 5px;\n  padding-left: 5px;\n  padding-right: 5px;\n  border-radius: 4px;\n  background-color: #ffffff;\n  box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px;\n  position: relative;\n}\n.T8XMqNUYy9pBQxnFfT33 {\n  width: 1px;\n  background-color: #C8C8C8;\n  height: 28px;\n  margin-left: 5px;\n  margin-right: 5px;\n}\n.czEmQTopBytxV064A2HC {\n  font-size: 14;\n  color: #1c6fb3;\n  text-decoration: underline;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/FiltersBanner/FiltersBanner.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,6FAAA;EACA,kBAAA;AACJ;AAEA;EACI,UAAA;EACA,yBAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAGA;EACI,aAAA;EACA,cAAA;EACA,0BAAA;EACA,eAAA;AADJ","sourcesContent":[".layoutFilterBarCard {\n    margin: 5px;\n    padding-left: 5px;\n    padding-right: 5px;\n    border-radius: 4px;\n    background-color: rgb(255, 255, 255);\n    box-shadow: rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px ;\n    position : relative\n}\n\n.splitter {\n    width: 1px;\n    background-color: #C8C8C8;\n    height: 28px;\n    margin-left: 5px;\n    margin-right: 5px;\n}\n\n.showMoreFilters {\n    font-size: 14;\n    color: #1c6fb3;\n    text-decoration: underline;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layoutFilterBarCard": "bF4kZiNc4BHD12UldfRT",
	"splitter": "T8XMqNUYy9pBQxnFfT33",
	"showMoreFilters": "czEmQTopBytxV064A2HC"
};
export default ___CSS_LOADER_EXPORT___;
