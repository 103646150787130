/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpMethods } from "./HttpConstants";
import { msalInstance as defaultMsalInstance } from "./msalInstance";
import { loginRequest } from "../authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

export class ESCostApiService {
    private readonly baseUrl: string;
    private readonly msalInstance: PublicClientApplication;
    
    constructor(baseUrl: string, msalInstance?: PublicClientApplication) {
        this.baseUrl = baseUrl;
        this.msalInstance = msalInstance ?? defaultMsalInstance;
    }

    private async getAuthHeader() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await this.msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });

        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json; charset=utf-8");
        return headers;
    }

    public async post(url: string, body: any): Promise<Response> {
        const options: RequestInit = {
            method: HttpMethods.POST,
            headers: await this.getAuthHeader(),
            body: JSON.stringify(body)
        };
        return fetch(`${this.baseUrl}/${url}`, options);
    }

    public async postJson<T>(url: string, body: any): Promise<T> {
        const response = await this.post(url, body);
        return response.json();
    }

    public async get(url: string): Promise<Response> {
        const options: RequestInit = {
            method: HttpMethods.GET,
            headers: await this.getAuthHeader()
        };
        return fetch(`${this.baseUrl}/${url}`, options);
    }

    public async getJson<T>(url: string): Promise<T> {
        const response = await this.get(url);
        return response.json();
    }
}

const esAPIService = new ESCostApiService(ES_COST_ENDPOINT);

export async function postJson<T>(url: string, body: any): Promise<T> {
    const response = await esAPIService.postJson<T>(url, body);
    return response;
}

export async function getJson<T>(url: string): Promise<T> {
    const response = await esAPIService.getJson<T>(url);
    return response;
}

