import * as React from "react";

import { DefaultButton, IIconProps, IconButton, Separator, Stack } from "@fluentui/react";
import { EventType, LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { useGetAzureDailyCpuQuery, useGetAzureDailyMetricsQuery, useGetRdosCpuBinQuery } from "../../../hooks/useAzureQuery";

import ColumnChart from "../../common/ColumnChart";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { Endpoints } from "../../../utils/Constants";
import LineChart from "../../common/LineChart";
import { LineChartContainer } from "../common/LineChartContainer";
import LoadingState from "../../ResponseBoundary/LoadingState";
import { CpuUtilizationRange, RdosCpuBinRange } from "../common/AzureComputeConstants";
import { SingleDatePicker } from "../../common/SingleDatePicker";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { getAverage } from "../../../utils/common";
import moment from "moment";
import { isEmpty } from "lodash";
import styles from "../Azure.less";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useTrackHovering } from "../../../hooks/useTrack";
import { useDateRange } from "../../../hooks/useDateSelector";
import { TimeView } from "../../../models/SearchKey";
import { useMsal } from "@azure/msal-react";
import { useFlights } from "../../../hooks/useSettings";
import { useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import html2canvas from "html2canvas";
import FeedbackPanel from "../../feedback/FeedbackPanel";
import { trackEventCallback } from "../../../utils/AppInsights";

interface IAzureUtilizationProps {
    updateSelectedTab: (tab: string) => void;
}

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

export const AzureUtilization: React.FC<IAzureUtilizationProps> = (props) => {
    const [cpuUtilizationDay, setCpuUtilizationDay] = React.useState(moment().startOf("date").subtract(7, "days"));
    const [rdosCpuBinDay, setRdosCpuBinDay] = React.useState(moment().subtract(7, "days"));
    const cpuUtilization = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, "CPU", "Total", "P99");
    const memoryUtilization = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, "Memory", "Total", "P99");
    const networkUtilization = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, "Network", "Total", "P99");

    const cpuUtilizationSingleDay = useGetAzureDailyCpuQuery(Endpoints.GetAzureDailyCpuUtilization, cpuUtilizationDay, "Total");
    const rdosCpuBin = useGetRdosCpuBinQuery(Endpoints.GetAzureRdosCpuBin, rdosCpuBinDay);

    const trackCpuCoresHoveringProps = useTrackHovering(LogComponent.Azure, LogElement.AzureCpuCoresCountP99, "CPU Utilization - Core Count Histogram (P99)", LogTarget.Chart);
    const trackRdosChartProps = useTrackHovering(LogComponent.Azure, LogElement.AzureRdosSamplePointCount, "CPU Utilization - Sample Points Count (Rdos)", LogTarget.Chart);
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.AzureCompute);
    const activeAnomalies = React.useMemo(() => getActiveAnomalies(anomaliesQuery.data, ["TotalCores"]), [anomaliesQuery.data]);
    const { startDate, endDate } = useDateRange();
    const dateRange = [startDate, endDate];

    // User Feedback
    const { instance } = useMsal();
    const { data: flights } = useFlights();
    const account = instance.getActiveAccount();
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');
    const [currentScreenshotURL, setCurrentScreenshotURL] = useState<string>('');

    const captureScreenshot = async (title: string) => {
        trackEventCallback(LogComponent.AzureTabs, LogElement.AzureUtilizationP99, "Send Feedback Chart", LogTarget.Button, undefined, EventType.Click);

        const url = window.location.href;
        setCurrentURL(url);

        await openFeedbackPanel();

        const targetElement = document.getElementById(`FeedbackSection_${title}`);

        if (targetElement) {
            setTimeout(() => {
                html2canvas(targetElement).then((canvas) => {
                    const screenshotDataUrl = canvas.toDataURL('image/png');
                    setCurrentScreenshotURL(screenshotDataUrl);
                });
            }, 100);

        }
    };

    const handleDismissFeedbackPanel = () => {
        setCurrentScreenshotURL('');
        dismissFeedbackPanel();
    };

    return (
        <>
        <Separator alignContent="start">Utilization</Separator>
        <div className={styles.content}>
            <div>
                <LineChartContainer
                    title="Azure Utilization(P99)"
                    isLoading={cpuUtilization.isLoading || memoryUtilization.isLoading || networkUtilization.isLoading}
                    cardProps={[
                        {title: "Average CPU Utilization", cost: getAverage(cpuUtilization.data?.data), color: "#0099BC", noPrefix: true, suffix: "%", showTooltip: true, date: dateRange},
                        {title: "Average Memory Utilization", cost: getAverage(memoryUtilization.data?.data), color: "#982570", noPrefix: true, suffix: "%", showTooltip: true, date: dateRange},
                        {title: "Average Network Utilization", cost: getAverage(networkUtilization.data?.data), color: "#6674E3", noPrefix: true, suffix: "%", showTooltip: true, date: dateRange},
                    ]}
                    series={[
                        {
                            name: "CPU",
                            data: cpuUtilization.data?.data.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue]),
                        },
                        {
                            name: "Memory",
                            data: memoryUtilization.data?.data.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                        },
                        {
                            name: "Network",
                            data: networkUtilization.data?.data.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                        }
                    ]}
                    isNotCurrency
                    maxValue={100}
                    suffix="%"
                    logProps={{
                        logComponent: LogComponent.AzureTabs,
                        logElement: LogElement.AzureUtilizationP99,
                    }}
                    anomalies={activeAnomalies}
                    fillMissingAbnormalData
                    timeView={TimeView.Daily}
                />
            </div>
            <div className={styles.cardContainer} id={`FeedbackSection_CPU_UitilizationP99`} >
                {flights?.enableUserFeedback &&
                    <IconButton
                        title="Send feedback"
                        className={styles.feedbackIcon}
                        iconProps={feedbackIconProps}
                        onClick={() => captureScreenshot("CPU_UitilizationP99")}
                    />}
                <Separator styles={{root: styles.separator}} />
                <Stack horizontal horizontalAlign="space-between" styles={{root: styles.title}}>
                    <h4 className={styles.titleText}>CPU Utilization - Core Count Histogram (P99)</h4>
                    <SingleDatePicker
                        selectedDate={cpuUtilizationDay}
                        onSelectDate={setCpuUtilizationDay}
                    />
                </Stack>
                <div className={styles.chart}>
                    {
                        !cpuUtilizationSingleDay.isLoading && cpuUtilizationSingleDay.data ?
                        <ColumnChart
                            seriesHeader="CPU Cores"
                            x={CpuUtilizationRange.map(key => `${key}%`)}
                            y={CpuUtilizationRange.map(key => cpuUtilizationSingleDay.data[key])}
                            containerProps={trackCpuCoresHoveringProps}
                        /> :
                        <LoadingState />
                    }
                </div>
            </div>
            {
                (rdosCpuBin.isLoading || !isEmpty(rdosCpuBin.data)) &&
                <div className={styles.cardContainer} id={`FeedbackSection_CPU_UitilizationRdos`} >
                    {flights?.enableUserFeedback &&
                        <IconButton
                            title="Send feedback"
                            className={styles.feedbackIcon}
                            iconProps={feedbackIconProps}
                            onClick={() => captureScreenshot("CPU_UitilizationRdos")}
                        />}
                    <Separator styles={{root: styles.separator}} />
                    <Stack horizontal horizontalAlign="space-between" styles={{root: styles.title}}>
                        <h4 className={styles.titleText}>CPU Utilization - Sample Points Count (Rdos)</h4>
                        <SingleDatePicker
                            selectedDate={rdosCpuBinDay}
                            onSelectDate={setRdosCpuBinDay}
                        />
                    </Stack>
                    <div className={styles.chart}>
                        {
                            !rdosCpuBin.isLoading && rdosCpuBin.data ?
                            <LineChart
                                series={RdosCpuBinRange.map(rdosCpuBinSingleSeries => ({
                                    name: rdosCpuBinSingleSeries,
                                    data: rdosCpuBin.data[rdosCpuBinSingleSeries]?.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                                }))}
                                isNotCurrency
                                showArea
                                keepOrder
                                ignoreWeekend={false}
                                tooltipDateFormatStr="hh:mm"
                                dateFormatStr="hh:mm"
                                colors={["#5B003B", "#88105E", "#B34F90", "#D696C0", "#E9C4DC", "#A5DEEB", "#60C4DA", "#0099BC", "#0099BC", "#00566A"]}
                                containerProps={trackRdosChartProps}
                            /> :
                            <LoadingState />
                        }
                    </div>
                    <DefaultButton className={styles.linkButton} text="Drill-down by Region" onClick={() => props.updateSelectedTab("RegionDetail")} />
                </div>
            }
            </div>
            <FeedbackPanel
                isPanelOpen={isFeedbackPanelOpen}
                onDismiss={handleDismissFeedbackPanel}
                userName={account?.username}
                currentURL={currentURL}
                screenshotInputURL={currentScreenshotURL}
                FeedbackTab={LogComponent.AzureTabs}
            />
        </>
    )
}