// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VewIskpHeMU7oj1f3Mic {\n  font-size: 1.5em;\n  font-weight: bold;\n  margin-right: 20px;\n}\n.b6RX_iqHJDGJmZlKsr79 {\n  color: white;\n  height: 100%;\n  cursor: pointer;\n}\n.b6RX_iqHJDGJmZlKsr79:hover {\n  background-color: #323130;\n  color: white;\n}\n", "",{"version":3,"sources":["webpack://./src/components/banner/AppBanner.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AAEA;EACI,YAAA;EACA,YAAA;EACA,eAAA;AAAJ;AAEI;EACI,yBAAA;EACA,YAAA;AAAR","sourcesContent":[".logo {\n    font-size: 1.5em;\n    font-weight: bold;\n    margin-right: 20px;\n}\n\n.iconButton {\n    color: white;\n    height: 100%;\n    cursor: pointer;\n\n    &:hover {\n        background-color: #323130;\n        color: white;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "VewIskpHeMU7oj1f3Mic",
	"iconButton": "b6RX_iqHJDGJmZlKsr79"
};
export default ___CSS_LOADER_EXPORT___;
