import { IStackStyles } from "@fluentui/react/lib/Stack";

export const outerStackStyles: IStackStyles = {
    root: {
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "hidden"
    }
};

export const filterStackStyles: IStackStyles = {
    root: {
        width: "100%",
        height: 50,
        backgroundColor: "#E9E9E9",
        borderBottom: "1px solid #C8C8C8",
        alignItems: "center",
    }
};