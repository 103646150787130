import React from 'react';
import styles from './FeedbackPanel.less';

interface CellValue {
    id: string;
    value: string;
}

interface Props {
    allCellValues: CellValue[];
    title: string;
}

const TableView: React.FC<Props> = ({ allCellValues, title }) => {
    return (
        <div className={styles.panelContent}>
            <div className={styles.divBlank}></div>
            <table className={styles.table}>
                <caption className={styles.caption}>{title}</caption>
                <tbody>
                    {allCellValues.map((cellValue, index) => (
                        <React.Fragment key={index}>
                            <tr className={styles.row}>
                                <td className={styles.td}>{cellValue.id}</td>
                                <td className={styles.td}>{cellValue.value}</td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableView;
