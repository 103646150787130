import * as React from "react";
import ChartContainer from "../../../chart/ChartContainer";
import { SeriesBarOptions } from "highcharts";
import { currencyFormatter } from "../../../../utils/currency";
import { ESCostDataDimensions, ESRepoFYArtifactCostRawResponse, ESOverviewTimeSelection } from "../../../../models/ESCostModels";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import { ESCostUtils } from "../../Utilities/ESCostUtils";

interface IHorizontalBarChartProps {
    data: ESRepoFYArtifactCostRawResponse[];
    description: string;
    legendDesc: string;
    isCurrency: boolean;
    maxCount?: number;
}

function filterCosts(costs: ESRepoFYArtifactCostRawResponse[], year: number): ESRepoFYArtifactCostRawResponse[] {
    return costs.filter((data) => year === data[ESCostDataDimensions.ReturnFiscalYear]);
}

const TopRepoCostBarChart: React.FC<IHorizontalBarChartProps> = (props) => {
    const dimension = props.isCurrency === false ? ESCostDataDimensions.ArtifactWorkload : ESCostDataDimensions.Cost;

    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );
    const dates = timeRange === ESOverviewTimeSelection.LastFY ? ESCostUtils.getLastFiscalYearDates() : ESCostUtils.getCurrentFiscalYearDates();
    const repoCost = React.useMemo(() => filterCosts(props.data, year), [props.data, year]);
    const sortedData = React.useMemo(() => {
        const l = [...repoCost].sort((a, b) => b[dimension] - a[dimension]);
        if (props.maxCount !== undefined && props.maxCount > 0) {
            return l.slice(0, props.maxCount);
        } else {
            return l;
        }
    }, [repoCost, props.maxCount, dimension]);
    const series = React.useMemo<SeriesBarOptions[]>(() => {
        return [
            {
                type: "bar",
                name: props.legendDesc,
                data: sortedData.map((d) => ({ y: d[dimension] })),
            },
        ];
    }, [props.legendDesc, sortedData, dimension]);
    const repos = React.useMemo(() => sortedData.map((d) => d[ESCostDataDimensions.Repository]), [sortedData]);

    return (
        <ChartContainer
            headerProps={{ title: props.description.concat(" (FY", String(year).substring(2), ")") }}
            chartType="bar"
            chartProps={{
                tooltipProps: {
                    date: [dates[0], dates[1]],
                    noPrefix: props.isCurrency === false,
                },
                yAxis: { labelFormatter: (num: number) => currencyFormatter(num, 2, props.isCurrency ? "$" : "") },
                categories: repos,
                series,
            }}
        />
    );
};

export default TopRepoCostBarChart;
