import * as React from "react";

import {
    Callout,
    Checkbox,
    ContextualMenuItemType,
    DefaultButton,
    DirectionalHint,
    FocusZoneDirection,
    ICommandBarItemProps,
    IContextualMenuItem,
    IContextualMenuProps,
    IStackTokens,
    PrimaryButton,
    SearchBox,
    Stack,
    mergeStyleSets,
    values,
} from "@fluentui/react";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { FiltersAction, FiltersView } from "../../../../reducers/filterReducer";
import { IServiceTreeData, getCategoryByServiceTreeLevel } from "../../../../reducers/serviceTreeReducer";
import { ServiceTree, ServiceTreeItem, ServiceTreeLevel } from "../../../../models/serviceTree";
import { getServiceCount, getServiceItemListByKeys, makeGlobalFiltersFromView } from "../../../../utils/FiltersUtils";

import { CategoryDivision } from "../../../../models/CategoryDivision";
import { DataNode } from "../../../../models/DataNode";
import { IAppState } from "../../../../store";
import { IFilterView } from "../../../../models/FilterView";
import { ISavedViewData } from "../../../../reducers/savedViewReducer";
import TreeSelect from "../../../TreeSelect";
import { debounce } from "lodash";
import { getServiceString } from "../../../../reducers/SearchUtils";
import { id } from "date-fns/locale";
import { stringOverflowWithEllipsis } from "../../../../reducers/stringUtils";
import styles from "./ServiceFilter.less";
import { useBoolean } from "@fluentui/react-hooks";
import { useCategoryFilters } from "../../../../hooks/useFilters";
import { useConst } from "@fluentui/react-hooks";
import useId from "@mui/material/utils/useId";
import { useSelector } from "react-redux";

const outlineStackTokens: IStackTokens = {
    childrenGap: 8,
};

interface IServiceFilterProps {
    maxDateOffset?: number;
    buttonId?: string;
    isUsedInPanel?: boolean;
}

// Refer to https://o365exchange.visualstudio.com/O365%20Core/_git/Jaws?path=/sources/dev/Frontends/Jaws%20Portal/src/Components/TimeScale.tsx&version=GBmaster&_a=contents
const ServiceFilter: React.FC<IServiceFilterProps> = ({maxDateOffset, buttonId = "callout-button", isUsedInPanel = false}) => {
    const { serviceTree: data } = useSelector<IAppState, IServiceTreeData>((state) => state.serviceTree);
    const filtersNextAction = useSelector<IAppState, FiltersAction>((state) => state.filtersNextAction);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

    const labelId = useId("callout-label");
    const descriptionId = useId("callout-description");
    const [serviceFilterText, setServiceFilterText] = useState<string>("All");

    const [selectedServiceCount, setSelectedServiceCount] = useState<number>(0);

    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);


    function createTreeData(serviceTree: ServiceTree | undefined): DataNode[] {
        function getValue(item: ServiceTreeItem) {
            return item.id === "00000000-0000-0000-0000-000000000000" ? `${item.id}(${item.l})` : item.id;
        }

        function createDataNodes(items: ServiceTreeItem[] | undefined): DataNode[] {
            if (!items) {
                return new Array<DataNode>(0);
            }
            const nodes = new Array<DataNode>(items.length);
            for (let i = 0; i < items.length; ++i) {
                const value = getValue(items[i]);
                nodes[i] = { title: items[i].n, key: value, value, data: items[i] };
                const children = items[i].c;
                if (children) {
                    nodes[i].children = createDataNodes(children);
                }
            }
            return nodes;
        }

        return createDataNodes(serviceTree?.items);
    }

    const handleChange = useCallback((values: string[], nodes: DataNode[]) => {
        if (values && values.length > 0) {
            setSelectedItemError(false);
        }
        setInternalSelectedIds(values);
        setInternalSelectedItems(nodes.map((x) => x.data));
    }, []);

    const globalFilters = useCategoryFilters();

    const treeData = useMemo(() => createTreeData(data), [data]);
    const globalSelectedServices = useMemo(() => {
        return getServiceItemListByKeys(globalFilters.filters.filters, data?.items);
    }, [data?.items, globalFilters.filters.filters]);
    const selectedIds = useMemo(() => globalSelectedServices.map((s) => s.id), [globalSelectedServices]);
    const [internalSelectedItems, setInternalSelectedItems] = useState<ServiceTreeItem[] | undefined>(undefined);
    const [selectedItemError, setSelectedItemError] = useState<boolean>(false);
    const [internalSelectedIds, setInternalSelectedIds] = useState<string[]>(selectedIds);

    const handleServicesChange = useCallback(
        (items: ServiceTreeItem[]) => {
            if (items.length) {
                const filters: Partial<Record<CategoryDivision, string[]>> = {};
                items.forEach((item) => {
                    const category = getCategoryByServiceTreeLevel(item.l);
                    if (!filters[category]) {
                        filters[category] = [];
                    }
                    filters[category]?.push(item.id);
                });
                globalFilters.updateFilters(filtersNextAction, { filters, view: FiltersView.Summary });
            }
            if (items.length <= 0) {
                const tempFilter = {...(globalFilters.filters.filters)};
                tempFilter[CategoryDivision.Division] = [];
                tempFilter[CategoryDivision.Organization] = [];
                tempFilter[CategoryDivision.ServiceGroup] = [];
                tempFilter[CategoryDivision.TeamGroup] = [];
                tempFilter[CategoryDivision.Service] = [];
                tempFilter[CategoryDivision.Owner] = [];
                globalFilters.updateFilters(FiltersAction.Replace, { filters: tempFilter, view: FiltersView.AddableList });
            }
        },
        [filtersNextAction, globalFilters]

    );

    React.useEffect(
        () => {
            const serviceCount = getServiceCount(globalFilters.filters.filters, serviceIdMap);
            setSelectedServiceCount(serviceCount)
            if(serviceCount == 0) {
                setServiceFilterText("All")
            }
            if (serviceCount > 1) {
                setServiceFilterText(getServiceString(serviceCount) + " Selected")
            }
            if (serviceCount == 1 && globalFilters.filters.filters.Service) {
                const serviceName = serviceIdMap.get(globalFilters.filters.filters.Service[0])?.n
                setServiceFilterText(stringOverflowWithEllipsis("" + serviceName, 20))
            }
        },
        [globalFilters.filters.filters, serviceIdMap]
    )

    const openAndClear = useCallback(() => {
        toggleIsCalloutVisible()
        setInternalSelectedIds(selectedIds);
        setInternalSelectedItems(undefined);
    }, [selectedIds]);

    const apply = useCallback(() => {
        handleServicesChange(internalSelectedItems || []);

        toggleIsCalloutVisible()

    }, [internalSelectedItems, selectedServiceCount, globalFilters.filters.filters, handleServicesChange]);

    // service search
    const [searchText, setSearchText] = useState("");

    const handleSearchChange = useCallback(
        debounce((event?: ChangeEvent<HTMLInputElement>, newValue?: string) => {
            setSearchText(newValue?.toLowerCase() || "");
        }, 300),
        [setSearchText]
    );
    const serviceTree = useSelector<IAppState, IServiceTreeData>((state) => state.serviceTree);
    const serviceTreeItemList = Array.from(serviceTree.indexMap)
    const serviceItemList : ServiceTreeItem[] = serviceTreeItemList.filter(item => item[1].l == ServiceTreeLevel.Service).map(item => item[1])

    const optionList : [string, ServiceTreeItem][] = serviceItemList.map(item => [buildPathNameForService(item), item]);

    function buildPathNameForService(serviceTreeItem: ServiceTreeItem) : string {
        if (!serviceTreeItem.p) {
            return serviceTreeItem.n
        } else {
            return buildPathNameForService(serviceTreeItem.p) + " > " + serviceTreeItem.n 
        }
    }


    const handleToggleSelect = (key: string, checked: boolean) => {
        const newSelectedKeys = [...internalSelectedIds];

        if (checked) {
            newSelectedKeys.push(key);
        } else {
            newSelectedKeys.splice(newSelectedKeys.indexOf(key), 1);
        }
        setInternalSelectedIds(newSelectedKeys)
        setInternalSelectedItems(newSelectedKeys.map(item => serviceTree.indexMap.get(item)!))
    };

    

    return (
        <div>
            <DefaultButton
                id={buttonId}
                onClick={openAndClear}
                style={{ whiteSpace: "nowrap" }}
                className={selectedServiceCount > 0 ? styles.serviceButtonSelected : styles.serviceButton}
            >
                <span>Service:&nbsp;</span>
                <span style={{ fontWeight: 600 }}>{serviceFilterText}</span>
            </DefaultButton>
            {isCalloutVisible && (
                <Callout
                    className={styles.callout}
                    style={{ maxHeight: window.screen.height * window.devicePixelRatio > 1900 ? 500 : 250 }}
                    ariaLabelledBy={labelId}
                    ariaDescribedBy={descriptionId}
                    role="dialog"
                    gapSpace={0}
                    target={`#${buttonId}`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                >
                    <div style={{marginBottom: '10px'}}>
                        <SearchBox className={styles.search} placeholder={"Search Service"} onChange={handleSearchChange} defaultValue={searchText}/>
                    </div>
                    {searchText.trim().length ? (
                        <div className={styles.regionList} style={{maxHeight: window.screen.height * window.devicePixelRatio > 1900 ? 400 : 200, overflowY : 'auto'}}>
                            {optionList
                                ?.filter((item) => item[1].n.toLocaleLowerCase().includes(searchText.toLowerCase()))
                                .slice(0, 100)
                                .map((item) => (
                                    <Checkbox
                                        className={styles.listItem}
                                        checked={internalSelectedIds.indexOf(item[1].id) >= 0}
                                        label={item[0]}
                                        key={item[0]}
                                        id={item[1].id}
                                        onChange={(_, checked) => handleToggleSelect(item[1].id, !!checked)}
                                    />
                                ))}
                        </div>
                    ) : (
                        <div style={{ maxHeight: window.screen.height * window.devicePixelRatio > 1900 ? 400 : 200 }} className={styles.serviceTreeDiv}>
                            <TreeSelect treeData={treeData} selectedValues={internalSelectedIds} onChange={handleChange} />
                        </div>
                    )}

                    <PrimaryButton onClick={apply} style={{ marginTop: 10 }}>
                        Apply
                    </PrimaryButton>
                </Callout>
            )}
        </div>
    );
};

export default ServiceFilter;
