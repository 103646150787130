import { ICustomProperties } from "@microsoft/applicationinsights-web";
import { useCallback, useEffect, useRef } from "react";
import { EventType, LogComponent, LogElement, LogTarget } from "../models/LogModel";
import { trackEventCallback } from "../utils/AppInsights";

export function useTrackHovering(
    componentName?: LogComponent, elementName?: LogElement, displayName?: string, logTarget?: LogTarget, customProperties?: ICustomProperties | undefined,
    minDuration = 3000,
) {
    const enterTs = useRef(0);
    const customPropertiesRef = useRef(customProperties);
    customPropertiesRef.current = customProperties;

    useEffect(() => {
        enterTs.current = 0;
    }, [componentName, elementName, displayName, logTarget])

    const onMouseEnter = useCallback(() => {
        enterTs.current = Date.now();
    }, []);

    const onMouseLeave = useCallback(() => {
        if (!componentName || !elementName || !displayName || !logTarget) {
            return;
        }

        if (enterTs.current && Date.now() - enterTs.current >= minDuration) {
            const properties = {
                ...customPropertiesRef.current,
                duration: Date.now() - enterTs.current,
            };

            trackEventCallback(componentName, elementName, displayName, logTarget, properties, EventType.Hovering);
        }

        enterTs.current = 0;
    }, [componentName, elementName, displayName, logTarget, minDuration]);

    return {
        onMouseEnter,
        onMouseLeave,
    };
}