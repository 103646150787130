import { AbnormalIssue, CogsInfo, MetricCostReport, OriginalComponentInfo } from "../../../models/IOInsightsModels";
import { AnomalyTotalCostTypeEnum, useGetIOInsights } from "../../../hooks/useSubstrateV2Query";
import { DataSourceEnum, INotification } from "../../../models/NotificationModels";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { PcmV2SceneMetrics, SubstrateLogComponent } from "../../../models/constants/PcmV2Constants";
import React, { useMemo } from "react";
import { currencyFormatter, formatNumber } from "../../../utils/currency";
import { getAverageValue, getMedian } from "../../../utils/ChartUtils";

import ChartContainer from "../../chart/ChartContainer";
import CommonConstants from "../../../models/constants/CommonConstants";
import { CostCardProps } from "../../common/costCard/CostCard";
import { LineChartSeries } from "../../common/LineChart";
import {Moment} from "moment";
import { PcmV2SceneTypeEnum } from "../../../models/PcmV2Models";
import { filterDeviation } from "../../../utils/DeviationFilter";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import moment from "moment";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useTimeView } from "../../../hooks/useTimeView";
import { useTrackHovering } from "../../../hooks/useTrack";

export interface IStoreInsightsByDimensionProps {
    scene: PcmV2SceneTypeEnum;
    id: string;
    serviceName: string;
    workloadName: string;
    startDate: string;
    showAll: boolean;
    updateHideDimState: (hideShowMoreBtn: boolean) => void;
}

const mideanValueMap: Map<string, number> = new Map();
const increasedValueMap: Map<string, number> = new Map();

export const IOAnomalyCostInsightsTrend: React.FC<IStoreInsightsByDimensionProps> = (props) => {
    const query = useGetIOInsights(props.scene);
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.SubstrateV2);
    const trackingProps = useTrackHovering(LogComponent.IOInsights, LogElement.AnomalyByDimTrend, "Anomaly Trends By Dim", LogTarget.Chart);
    const [ timeView ] = useTimeView();
    const iRWQ: string[] = ["Item Reads", "Item Writes", "Item Queries"];
    const anomaliesContent: INotification[] = [];
    
    const errorsSet = new Map<string, Map<number, Array<object>>>();
    const seriesSet = useMemo<Map<string, LineChartSeries[]>>(() => {
        const serieses: Map<string, LineChartSeries[]> = new Map();
        if (query.data == undefined) {
            return serieses;
        }

        
        let anomalyIssue: AbnormalIssue | undefined;

        for (const issue of query.data as AbnormalIssue[]) {
            if (issue.externalID == props.id){
                anomalyIssue = issue;
                break;
            }
        }

        if (anomalyIssue == undefined){
            return serieses;
        }

        const issueComponentInfo: OriginalComponentInfo = JSON.parse(anomalyIssue.originalComponentInfo.toString());
        const isTBAEBA: boolean = issueComponentInfo.Client == "TimeBasedAssistants" || issueComponentInfo.Client == "EventBasedAssistants";

        const iRWQBaselineCosts: MetricCostReport = JSON.parse(anomalyIssue.baselineCost.toString());
        const iRWQAbnormalCosts: MetricCostReport = JSON.parse(anomalyIssue.abnormalCost.toString());

        const readsAbnormalCosts: Map<string, Map<Moment, CogsInfo>> = JSON.parse(JSON.stringify(iRWQAbnormalCosts.ItemReadCostByDimension));
        const readsBaseCosts: Map<string, Map<Moment, CogsInfo>> = JSON.parse(JSON.stringify(iRWQBaselineCosts.ItemReadCostByDimension));
        const writesAbnormalCosts: Map<string, Map<Moment, CogsInfo>> = JSON.parse(JSON.stringify(iRWQAbnormalCosts.ItemWriteCostByDimension));
        const writesBaseCosts: Map<string, Map<Moment, CogsInfo>> = JSON.parse(JSON.stringify(iRWQBaselineCosts.ItemWriteCostByDimension));
        const queriesAbnormalCosts: Map<string, Map<Moment, CogsInfo>> = JSON.parse(JSON.stringify(iRWQAbnormalCosts.ItemQueryCostByDimension));
        const queriesBaseCosts: Map<string, Map<Moment, CogsInfo>> = JSON.parse(JSON.stringify(iRWQBaselineCosts.ItemQueryCostByDimension));
        const costPairs = [[readsAbnormalCosts, readsBaseCosts], [writesAbnormalCosts, writesBaseCosts], [queriesAbnormalCosts, queriesBaseCosts]];

        let iterator = 0;
        for (const [abnormalCost, baseCost] of costPairs) {
            for (const [dimension, abnormalCostbyDim] of Array.from(Object.entries(abnormalCost))) {
                const abnormalCosts: Map<Moment, CogsInfo> = JSON.parse(JSON.stringify(abnormalCostbyDim));
                const newBaseCost = new Map(Object.entries(baseCost));
                const baseCosts: Map<Moment, CogsInfo> = newBaseCost.get(dimension) == undefined ? new Map<Moment, CogsInfo>() : JSON.parse(JSON.stringify(newBaseCost.get(dimension)));
                const baselineMap = new Map<number, number>();
                const map = new Map<number, number>();
                const truMap = new Map<number, number>();
                const ioPerCallMap = new Map<number, number>();
                const errors = new Map<number, Array<object>>();
    
                for (const [momentString, costInfo] of Array.from(Object.entries(abnormalCosts))) {
                    const day: number = moment(momentString).valueOf();
                    map.set(day, costInfo.Cost);
                    truMap.set(day, costInfo.TRU);
                    ioPerCallMap.set(day, costInfo.IOPerCall);
                }

                const abnoramlAvg = getAverageValue(Array.from(map.keys()).map((date) => [date, map.get(date)]) || [], false);
    
                for (const [momentString, costInfo] of Array.from(Object.entries(baseCosts))) {
                    const day: number = moment(momentString).valueOf();
                    map.set(day, costInfo.Cost);
                    truMap.set(day, costInfo.TRU);
                    ioPerCallMap.set(day, costInfo.IOPerCall);
                    baselineMap.set(day, costInfo.Cost);
                }
    
                const dates = Array.from(map.keys());
                for (const date of dates){
                    let error = "";
                    const ioPerCall = ioPerCallMap.get(date);
                    const tru = truMap.get(date);
                    error += "1. IOPerCall: ";
                    error += formatNumber(ioPerCall || 0, 2);
                    error += "; ";
                    error += "2. TRU: ";
                    error += formatNumber(tru || 0, 2);
                    errors.set(date, [{
                        title: "What's going wrong?",
                        message: error,
                    }])
                }
                const ApiName = dimension.split(":").pop()?.trimStart();
                const ApiKey = ApiName + "-" + iRWQ[iterator];
                errorsSet.set(ApiKey, errors);

                const costSeries: LineChartSeries = {
                    name: ApiName + " " + (isTBAEBA && (dimension.split(":").shift()?.trimStart() == "OperationMethod") ? "OperationMethod": "API") + " Cost",
                    data: dates.map((date) => [date, map.get(date)]),
                    type: "line",
                    showDeviation: true,
                    anomalies: getActiveAnomalies(anomaliesQuery.data, PcmV2SceneMetrics[props.scene]),
                };
    
                const baselinDates = Array.from(baselineMap.keys());
                const baselineData: [number, number | undefined][] = baselinDates.map((date) => [date, baselineMap.get(date)])
    
                const medianCost = getMedian(baselineData);

                const baselineAvg = getAverageValue(baselineData, false);
                const increasedValue = (abnoramlAvg - baselineAvg) * 30;

                mideanValueMap.set(ApiKey, medianCost);
                increasedValueMap.set(ApiKey, increasedValue);
                serieses.set(ApiKey, [
                    costSeries,
                    {
                        name: "Normal week median Cost",
                        color: CommonConstants.MedianColor,
                        dashStyle: "Dash",
                        disableAnomaly: true,
                        data: costSeries.data?.map(([date, value]) => [date, medianCost]),
                    },
                ]);
            }
            iterator = iterator + 1;    
        }

        // Anomaly Notification.
        const startDate = moment(anomalyIssue.startTime);
        const endDate = moment(anomalyIssue.endTime);

        const anomaly: INotification = { 
            startDate : startDate,
            endDate: endDate,
            detectionDate: anomalyIssue.detectTime,
            category: "Data abnormal",
            status: "Resolving",
            affectedMetrics: JSON.parse(anomalyIssue.effectedPCMMetrics),
            dataSourceName: DataSourceEnum.SubstrateV2,
        }

        anomaliesContent.push(anomaly); 

        return serieses;
    }, [query.data, props.scene, anomaliesQuery.data, timeView, props.id]);

    const chartCards: CostCardProps[] | undefined = [];

    const sortedSeries = new Map([...seriesSet.entries()].filter((x) => ((increasedValueMap.get(x[0])) || 0) > 10).sort((a, b) => (increasedValueMap.get(b[0]) || 0) - (increasedValueMap.get(a[0]) || 0)));

    const collaspedSeries = filterDeviation(sortedSeries);

    const dispalySeries = props.showAll? sortedSeries : collaspedSeries;

    React.useEffect(() => {
        if (collaspedSeries.size != sortedSeries.size){
            props.updateHideDimState(true);
        } else {
            props.updateHideDimState(false);
        }
    });

    return (
        <>
        {Array.from(dispalySeries.entries()).map(([operation, series]) => {
            const description: Map<string, string> = 
            new Map([
                    ["metric", operation.split("-").pop() || ""],
                    ["cost", currencyFormatter(JSON.parse(increasedValueMap.get(operation)?.toString() || "0"), 2, "$")],
                    ["rca", (series[0].name.split(" Cost").shift()) || "" ]
                ]);
            return <div key={operation} style={{marginBottom:48}}><ChartContainer
                key={operation}
                cards={chartCards}
                headerProps={{ title: "Anomaly Details - " + operation }}
                loading={query.isLoading}
                chartType="line"
                chartProps={{
                    series,
                    height: 220,
                    hideEmptySeries: true,
                    anomalies: anomaliesContent,
                    containerProps: trackingProps,
                    deviationType: "Median",
                    isIOInsightsChart: true,
                    medianValue: mideanValueMap.get(operation) || 0,
                    errors: errorsSet.get(operation) as Map<number, {
                        title: string;
                        message: string;
                    }[]>,
                }}
                explainTextByDim={description}
                isInsightsChartByDim={true}
            /></div>
        })
        }
        </>
    )
};
