import { ActionTypes, RequestStatus } from "../actions/ActionTypes";

import { GetStateFunction } from "../store";
import { IFilterView } from "../models/FilterView";
import { IGetAllSubViewsAction } from "../actions/SavedViewActions";
import { Reducer } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { loadSubViewsById } from "../services/viewService";

export interface ISubViewsData {
    subviews?: {[key: string] : IFilterView};
    isLoading: boolean;
    errorHappened: boolean;
}

const initialState: ISubViewsData = {
    isLoading: false,
    errorHappened: false
}


export const subViewReducer: Reducer<ISubViewsData, IGetAllSubViewsAction> = function(state = initialState, action): ISubViewsData {
    if (action.type !== ActionTypes.GetAllSubViewDataAction) {
        return state;
    }
    
    switch (action.status) {
        case RequestStatus.Requested:
            return { isLoading: true, errorHappened: false, subviews: {...state.subviews} };
        case RequestStatus.Succeed:
            const newState : Required<ISubViewsData> = { isLoading: false, errorHappened: false, subviews: {...state.subviews}};
            action.data?.forEach((item) => {                     
                newState.subviews[item.id] = {...item}
            })

            return newState;
        case RequestStatus.Failed:
            return { isLoading: false, errorHappened: true, subviews: {...state.subviews}};

        default:
            return state;
    }
}

export function getAllSubViewsUnderSelectedView(viewId: number) {
    return async function (dispatch: ThunkDispatch<ISubViewsData, void, IGetAllSubViewsAction>, getState: GetStateFunction): Promise<void> {
        const subViewsData = getState().subViewsData;
        if (subViewsData.isLoading || subViewsData.errorHappened) {
            return Promise.resolve();
        }

        dispatch({type: ActionTypes.GetAllSubViewDataAction, status: RequestStatus.Requested});
        loadSubViewsById(viewId)
            .then( subviews => dispatch({type: ActionTypes.GetAllSubViewDataAction, status: RequestStatus.Succeed, data: subviews}))
            .catch( () => dispatch({type: ActionTypes.GetAllSubViewDataAction, status: RequestStatus.Failed}));
    }
}

