import { ISingleDayMetric } from './Metric';
import { ISubscription } from './serviceTree';
import { LogComponent } from './LogModel';

export interface IHierarchicalMetricReport {
    name: string;
    data: ISingleDayMetric[];
}

export interface IAzureComputeDetail {
    id: number;
    subscriptionId: string;
    serviceId: string;
    sku: string;

    totalCores: number;
    vmCost: number;
    billingCost: number;
    averageCores: number;
    reclaimableCores: number;
    reclaimableCoresAvgMaxOfMax: number;
    idleCoresP50: number;
    idleCoresP99: number;
    idleCoresP99AvgMaxOfMax: number;
    idleCoresP50Percentage: number;
    idleCoresP99Percentage: number;
    cpuP50: number;
    cpuP99: number;
    cpuP99AvgMaxOfMax: number;
    memoryP50: number;
    memoryP99: number;
    networkP50: number;
    networkP99: number;

    reclaimableCoresV2: number;
    idleCoresP95: number;
    idleCoresP999: number;
    idleCoresP50V2: number;
    idleCoresP95V2: number;
    idleCoresP99V2: number;
    idleCoresP999V2: number;
    idleCoresP95Percentage: number;
    idleCoresP999Percentage: number;
    idleCoresP50V2Percentage: number;
    idleCoresP95V2Percentage: number;
    idleCoresP99V2Percentage: number;
    idleCoresP999V2Percentage: number;
    cpuP95: number;
    cpuP999: number;
    cpuP50V2: number;
    cpuP95V2: number;
    cpuP99V2: number;
    cpuP999V2: number;
    memoryP95: number;
    memoryP999: number;
    networkP95: number;
    networkP999: number;

    serviceName: string;
    feedback: string;
    region: string;
    serviceDevOwners?: string;
    servicePMOwners?: string;
    subscriptionCounts: number;
    subscriptionName: string;
}

export type IAzureComputeServiceDetail = IAzureComputeDetail;

export type IAzureComputeRegionDetail = IAzureComputeDetail;

export interface IAzureComputeSubscriptionDetail extends IAzureComputeDetail {
    deploymentId: string;
    clusterId: string;
    resourceGroupName: string;
    cloudService: string;
    role: string;
    sku: string;
    environment: string;
    costCategory: string;
    machineFunction: string;
    perfCollector: string;
    vmScaleSetName: string;
    operatingSystem: string;
    instances: number;
    cores: number;
    isMock?: boolean;
    isSpotVM?: boolean;
    azsc?: number;
    isSovereign?: number;
    resourceUri: string;
}

export interface IAzureSubscriptionSearchInfo {
    nameMap: Map<string, ISubscription>;
    idMap: Map<string, ISubscription>;
}

export interface IAzureComputeDetailData<D extends IAzureComputeDetail = IAzureComputeDetail> {
    total: Partial<D>;
    max: Partial<D>;
    min: Partial<D>;
    data: D[];
    rowCount: number,
}

export interface IAzureTotalBudgetReport {
    publicBudgets: number[];
    publicCosts: (number | null)[];
    azscBudgets: number[];
    azscCosts: (number | null)[];
}

export interface IAzureWorkloadMonthlyCost {
    productName: string;
    monthlyCosts: (number | null)[];
}

export interface IAzureServiceGroupCostDetails {
    serviceGroupName: string;
    organizationName: string;
    publicCosts: number[];
    azscCosts: number[];
}

export interface IAzureBudgetDetails {
    product: string;
    serviceGroupCosts: IAzureServiceGroupCostDetails[];
    publicBudgets: number[];
    azscBudgets: number[];
}

export const SubComputeLogComponent: Record<string, LogComponent> = {
    ["Public"]: LogComponent.PublicAzureTabs,
    ["AZSC"]: LogComponent.AzscTabs,
};

export enum AzureCostTableTag {
    Service = "Service",
    Region = "Region",
    Subscription = "Subscription",
}