import { PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum } from "../../../models/PcmV2Models";
import React, { useMemo } from "react";

import { SubstrateCostRanking } from "../common/SubstrateCostRanking";
import { SubstrateCostTable } from "../common/table/SubstrateCostTable";
import { SubstrateTopFiveTrends } from "../common/SubstrateTopFiveTrends";
import { SubstrateUtils } from "../utils/SubstrateUtils";
import commonStyles from "../../common/styles/Common.less";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { useFlights } from "../../../hooks/useSettings";

interface ITransactionByScenarioTagProps {
    insights: JSX.Element[];
}

export const TransactionByScenarioTag: React.FC<ITransactionByScenarioTagProps> = (props) => {
    const categoryFilter = useCategoryFilters();
    const { data: flights } = useFlights();

    const hasScenarioTag = useMemo(() => {
        const filters = categoryFilter.filters.filters;

        return !!(SubstrateUtils.hasServiceLevelAndAboveFilter(categoryFilter.filters) || filters.GriffinAppV2?.length || filters.ScenarioTagV2?.length);
    }, [categoryFilter.filters]);

    return (
        <div>
            {hasScenarioTag && (
                <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`}>
                    {flights?.enableStoreIOInsights && props.insights}
                    <SubstrateTopFiveTrends scene={PcmV2SceneTypeEnum.Transaction} subScene={PcmV2SubSceneTypeEnum.TransactionScenarioTagCost} />
                    <SubstrateCostRanking scene={PcmV2SceneTypeEnum.Transaction} subScene={PcmV2SubSceneTypeEnum.TransactionScenarioTagCost} />
                </div>
            )}
            <SubstrateCostTable scene={PcmV2SceneTypeEnum.Transaction} subScene={PcmV2SubSceneTypeEnum.TransactionScenarioTagCost} />
        </div>
    );
};
