// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pDgK8uKQ8DJXF3V1SuYd {\n  background: #E8E8E8;\n  border: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/substrateV2/common/SubstrateCostRanking.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,YAAA;AACJ","sourcesContent":[".showMore {\n    background: #E8E8E8;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showMore": "pDgK8uKQ8DJXF3V1SuYd"
};
export default ___CSS_LOADER_EXPORT___;
