import * as React from "react";
import { Link, Stack } from "@fluentui/react";
import styles from "./PageHeader.less";
import { LogComponent, LogElement, LogTarget } from "../../models/LogModel";
import { getTrackEventClickCallback } from "../../utils/AppInsights";
import commonStyles from "./styles/Common.less";

export interface IPageHeaderProps {
    title: string;
    tag?: string;
    notification?: string;
    description: string;
    link?: string;
    linkText?: string;
    inlineLink?: boolean;
    linkLogComponent?: LogComponent;
    linkLogElement?: LogElement;
    className?: string;
}

const PageHeader: React.FC<IPageHeaderProps> = (props) => {
    const handleLinkClick = React.useMemo(() => {
        return props.linkLogComponent
            ? getTrackEventClickCallback(props.linkLogComponent, props.linkLogElement ? props.linkLogElement : LogElement.LearnMore , props.linkText || "", LogTarget.Link)
            : undefined;
    }, [props.linkText, props.linkLogComponent]);

    const linkElement = props.link ? (
        <Link target="__blank" href={props.link} onClick={handleLinkClick}>
            {props.linkText}
        </Link>
    ) : null;

    return (
        <Stack className={props.className}>
            <div className={`${commonStyles.centeredRow} ${styles.header}`}>
                <div className={styles.title}>{props.title}</div>
                {props.tag && <div className={styles.tag}>{props.tag}</div>}
            </div>
            <div className={styles.content}>
                <div>{props.notification && <strong>{props.notification}</strong>}</div>
                {props.inlineLink ? (
                    <>
                        <div>
                            {props.description} {linkElement}
                        </div>
                    </>
                ) : (
                    <>
                        <div>
                            {props.description}
                            <br />
                        </div>
                        <div>{linkElement}</div>
                    </>
                )}
            </div>
        </Stack>
    );
};

PageHeader.defaultProps = {
    inlineLink: true,
};

export default PageHeader;
