import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, ICustomProperties } from "@microsoft/applicationinsights-web";
import { createHashHistory } from "history";
import { EventType, LogComponent, LogElement, LogTarget } from "../models/LogModel";
import { msalInstance } from "./msalInstance";

const hashHistory = createHashHistory();
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: "fc53cdf6-c917-4308-a880-5d440e217c8a",
        appId: "43be6384-654a-4f3e-932c-05300cbbe7ce",
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: {history: hashHistory}
        },
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
        enableAjaxPerfTracking: true,
        enableUnhandledPromiseRejectionTracking: true,
        autoTrackPageVisitTime: true,
    }
});

appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((item) => {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        return;
    }
    if (!item.tags) {
        item.tags = [];
    }

    item.tags["ai.device.id"] = navigator.userAgent;
    item.tags["ai.user.id"] = account.localAccountId;
    item.tags["ai.device.type"] = ENVIRONMENT;
})

export function trackEventCallback(componentName: LogComponent, elementName: LogElement, displayName: string, elementType: LogTarget, customProperties?: ICustomProperties, eventType: EventType = EventType.Click): void {
    const path = location.hash.substring(1, location.hash.indexOf("?") > -1 ? location.hash.indexOf("?") : location.hash.length);
    appInsights.trackEvent({name: `${path}/${componentName}/${elementName}`}, {...customProperties, displayName, elementType, eventType});
}

export function getTrackEventClickCallback(componentName: LogComponent, elementName: LogElement, displayName: string, elementType: LogTarget, customProperties?: ICustomProperties, eventType: EventType = EventType.Click) {
    return function(): void {
        const path = location.hash.substring(1, location.hash.indexOf("?") > -1 ? location.hash.indexOf("?") : location.hash.length);
        appInsights.trackEvent({name: `${path}/${componentName}/${elementName}`}, {...customProperties, displayName, elementType, eventType});
    }
}