import { CosmosMetricEnum, CosmosSourceTypeEnum, CosmosSourceTypeName } from "../../../models/CosmosModels";
import { Stack, StackItem } from "@fluentui/react";

import CosmosTopGemChart from "./CosmosTopGemChart";
import CosmosTrendChart from "./CosmosTrendChart";
import React from "react";
import commonStyles from "../../common/styles/Common.less";
import styles from "./CosmosChart.less";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { values } from "lodash";

interface ICosmosChartProps {
    type: CosmosSourceTypeEnum;
}

const CosmosChart: React.FC<ICosmosChartProps> = (props) => {
    const globalFiltersData = useCategoryFilters().filters;
    const isAllServiceView = !values(globalFiltersData.filters).reduce((prevValue, categoryFilters) => prevValue + categoryFilters.length, 0);

    let secondaryMetricType = CosmosMetricEnum.Costs;
    let secondarySourceTypes = [CosmosSourceTypeEnum.Storage, CosmosSourceTypeEnum.Processing];
    let secondaryTitle = "Storage and Processing Cost Trends";

    switch (props.type) {
        case CosmosSourceTypeEnum.Storage: {
            secondaryMetricType = CosmosMetricEnum.PhysicalSize;
            secondarySourceTypes = [CosmosSourceTypeEnum.Storage];
            secondaryTitle = "Storage Utilization Trends";
            break;
        }
        case CosmosSourceTypeEnum.Processing: {
            secondaryMetricType = CosmosMetricEnum.Token;
            secondarySourceTypes = [CosmosSourceTypeEnum.Processing];
            secondaryTitle = "Processing Utilization Trends";
            break;
        }
    }

    if (isAllServiceView) {
        return (
            <div className={commonStyles.gridWrapper}>
                <div className={commonStyles.gridColumn1}>
                    <CosmosTrendChart sources={[props.type]} metric={CosmosMetricEnum.Costs} title={`${CosmosSourceTypeName[props.type]} Cost Trends`} />
                </div>
                <div className={commonStyles.gridColumn2}>
                    <CosmosTrendChart sources={secondarySourceTypes} metric={secondaryMetricType} title={secondaryTitle} />
                    <CosmosTopGemChart source={props.type} />
                </div>
            </div>
        );
    }

    return (
        <div className={commonStyles.gridWrapper}>
            <div className={commonStyles.gridColumn2}>
                <CosmosTrendChart sources={[props.type]} metric={CosmosMetricEnum.Costs} title={`${CosmosSourceTypeName[props.type]} Cost Trends`} />
                <CosmosTrendChart sources={secondarySourceTypes} metric={secondaryMetricType} title={secondaryTitle} />
            </div>
        </div>
    );
};

export default CosmosChart;
