import { DirectionalHint } from '@fluentui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../store';
import TeachingBubbleSteps, { ITeachingBubbleStepOption } from './TeachingBubbleSteps';

interface IPortalTeachingBubblesProps {
    isSidebarHidden?: boolean;
}

const PortalTeachingBubbles : React.FC<IPortalTeachingBubblesProps> = (props) => {
    const savedViewState = useSelector((state: IAppState) => state.savedViews);
    const serviceTreeState = useSelector((state: IAppState) => state.serviceTree);
    const isTargetReady = !savedViewState.isLoading && !savedViewState.errorHappened
        && !serviceTreeState.isLoading && !serviceTreeState.errorHappened;

    // avoid initial state isLoading == false
    const [isStateReady, setStateReady] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setStateReady(true);
        }, 100);
    }, []);

    const steps = useMemo(() => {
        const data: ITeachingBubbleStepOption[] = [{
            target: '#portal-bubble-anchor-search',
            title: 'Support Multi-select Search',
            description: 'You can search owner alias, organizations, services, apps, subscriptions and clusters.',
            calloutProps: {
                gapSpace: 8,
                directionalHint: DirectionalHint.bottomCenter
            }
        }];

        if (props.isSidebarHidden) {
            data.push({
                target: '#toggle-sidebar-btn',
                title: 'Saved View and Service Tree',
                description: 'Open the sidebar to select your service on Service Tree or access your Saved Views.',
                calloutProps: {
                    directionalHint: DirectionalHint.bottomLeftEdge,
                },
            });
        } else {
            data.push({
                target: '#portal-bubble-anchor-saved-view',
                title: 'Saved View',
                description: 'Save your search/filter results here for quick revisit.',
                calloutProps: {
                    directionalHint: DirectionalHint.rightCenter,
                }
            });
        }

        return data;
    }, [props.isSidebarHidden]);

    return isStateReady && isTargetReady ? (<TeachingBubbleSteps name="portal" steps={steps} />) : null;
};

export default PortalTeachingBubbles;
