// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XSVj0OSiqwQeW1E51xst {\n  word-break: break-word;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/Section.less"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ","sourcesContent":[".section {\n    word-break: break-word;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "XSVj0OSiqwQeW1E51xst"
};
export default ___CSS_LOADER_EXPORT___;
