import * as React from "react";

import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { PCMMetric, getPCMMetricColor, getPCMMetricString } from "../../../models/PCMMetrics";
import { getActiveAnomalies, getAnomaliesByDate, getAnomaliesByMetric, getAnomalyDescription, getAnomalyLegendPlaceholderSeries, getAnomalySeries } from "../../../utils/AnomalyDetectionUtils";

import ChartContainer from "../ChartContainer/ChartContainer";
import CostCardTooltipContent from "../../common/costCard/CostCardTooltipContent";
import { DataSourceEnum } from "../../../models/NotificationModels";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { INotification } from "../../../models/NotificationModels";
import { ISubstrateTotalCostTimeSeriesData } from "../../../models/SubstrateModels";
import chartStyles from '../../common/Chart.less';
import { currencyFormatter } from "../../../utils/currency";
import { isWeekend } from "../../../utils/DateUtils";
import moment from "moment";
import { renderToString } from 'react-dom/server';
import styles from "./TotalCostTrendingChart.less";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useTrackHovering } from "../../../hooks/useTrack";

interface ITotalCostTrendingProps {
    title: string;
    totalCostTimeSeries?: ISubstrateTotalCostTimeSeriesData;
    details?: [PCMMetric, ISubstrateTotalCostTimeSeriesData][];
}

const TotalCostTrendingChart: React.FC<ITotalCostTrendingProps> = (props) => {
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.Substrate);
    const anomalies: INotification[] = React.useMemo(() => getActiveAnomalies(anomaliesQuery.data?.filter(item => !item.affectedMetrics.includes("SubstrateCarbonEmissions")) || []), [anomaliesQuery.data]);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const average = React.useMemo(() => props.totalCostTimeSeries!.reduce((a, b) => a + (isWeekend(b[0]) ? 0 : b[1]) , 0) / props.totalCostTimeSeries!.reduce( (a, b) => a + (isWeekend(b[0]) ? 0 : 1), 0), [props.totalCostTimeSeries]);

    const trackChartHoveringProps = useTrackHovering(LogComponent.Substrate, LogElement.SubstrateTotalCostTrends, props.title, LogTarget.Chart);

    const options: Highcharts.Options = React.useMemo<Highcharts.Options>(() => {
        const series: Highcharts.Options["series"] = [
            {
                name : "Average Total Cost",
                type : "line",
                dashStyle: "Dash",
                lineWidth: 4,
                color: "#949494",
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                data : props.totalCostTimeSeries!.map(item => ({y: isWeekend(item[0]) ? null : average, name: item[0].format("MM/DD/YYYY")})),
                marker: {symbol: "square", enabled: false, states: { hover: {enabled: true}}},
                visible: true,
                connectNulls: true,
            }
        ];

        props.details?.map(([metric, data]) => {
            series.push(...getAnomalySeries(getAnomaliesByMetric(anomalies, metric), data, {
                name: getPCMMetricString(metric),
                type: "area",
                color: getPCMMetricColor(metric),
                stacking: "normal",
                connectNulls: false,
            }, ([date, cost]) => ({ y: isWeekend(date) ? undefined : cost })))
        });

        if (anomalies.length) {
            series.push(getAnomalyLegendPlaceholderSeries());
        }

        return {
            chart: {
                style: {
                    fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
                    fontSize: "10px",
                    fontWeight: "semibold"
                },
                events: {
                    load: function() {
                        this.series.forEach(s => (s as any).markerGroup.toFront());
                    }
                }
            },
            title: {
                text: undefined
            },
            credits: {
                enabled: false
            },
            tooltip: {
                shape: "square",
                borderWidth: 0,
                borderRadius: 0,
                backgroundColor: "white",
                padding: 0,
                useHTML: true,
                shared: true,
                outside: true,
                followPointer: true,
                formatter: function() {
                    const abnormalEvents = getAnomaliesByDate(anomalies, moment(this.x));
                    const visiblePoints = this.points?.filter(point => !point.series.name.startsWith('hidden')) || [];

                    return renderToString(
                        <CostCardTooltipContent
                            items={visiblePoints.map(point => ({ title: point.series.name, cost: point.y || 0, color: (point.series as any).color }))}
                            date={[moment(this.x)]}
                            errors={
                                abnormalEvents.map(event => ({
                                    title: event.category,
                                    message: getAnomalyDescription(event.category, event.affectedMetrics, event.startDate, event.endDate),
                                }))
                            }
                            errorCollapseLimit={0}
                            smallCard
                            totalText={`Total Cost ($${currencyFormatter(this.points && this.points[1] && this.points[1].total || 0, 2, "")})`}
                        />);
                }
            },
            legend: {
                align: "left",
                squareSymbol: true,
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 0,
                useHTML: true,
                className: anomalies.length > 0 ? chartStyles.abnormalChartLegend : undefined,
            },
            xAxis: {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                categories: props.totalCostTimeSeries!.map(item => item[0].format("MM/DD/YYYY")),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                minTickInterval: props.totalCostTimeSeries!.length / 5,
                labels: {
                    formatter: function() {
                        return this.value.toString().substring(0, 5);
                    }
                }
            },
            yAxis: {
                title: {
                    text: undefined
                },
                labels: {
                    formatter: function() {
                        if (typeof(this.value) === "string") {
                            return this.value;
                        }
                        return currencyFormatter(this.value);
                    }
                },
                min: 0
            },
            series,
        };
    }, [anomalies, props.totalCostTimeSeries, props.details, average]);

    return (
        <ChartContainer title={props.title}>
            <HighchartsReact highcharts={Highcharts} options={options} containerProps={trackChartHoveringProps} />
        </ChartContainer>
    )
}

export default TotalCostTrendingChart;