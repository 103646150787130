import { IEditViewSuccessDialogsAction, ISaveViewFailDialogsAction, ISaveViewSuccessDialogsAction, IToggleDeleteDialogsAction, IToggleShareViewDialogsAction } from "../actions/DialogsActions";

import { ActionTypes } from "../actions/ActionTypes";
import { Reducer } from "redux";

export enum ViewType {
    View = 0,
    SubView = 1
}
export interface IDialogsData {
    isDeleteViewDialogOpen?: boolean;
    isSaveViewSuccessDialogOpen?: boolean;
    isEditViewSuccessDialogOpen?: boolean;
    isSaveViewFailDialogOpen?: boolean;
    isShareViewDialogOpen?: boolean;
    errorMessage?: string;
    viewName?: string;
    viewId?: number;
    viewType: ViewType;
    startDate?: string;
    endDate?: string;
    shareViewId?: string;
}

const initialState: IDialogsData = {
    isDeleteViewDialogOpen: false,
    isSaveViewSuccessDialogOpen: false,
    isSaveViewFailDialogOpen: false,
    isShareViewDialogOpen: false,
    viewType: ViewType.View
}

export const dialogsReducer: Reducer<IDialogsData, IToggleDeleteDialogsAction | ISaveViewSuccessDialogsAction | ISaveViewFailDialogsAction |  IToggleShareViewDialogsAction | IEditViewSuccessDialogsAction> = function (state = initialState, action): IDialogsData {
    switch (action.type) {
        case ActionTypes.DeleteDialogAction:
            return { ...state, isDeleteViewDialogOpen: action.isOpen, viewName: action.viewName, viewId: action.viewId, viewType: action.viewType};
        case ActionTypes.SaveViewSuccessDialogAction:
            return { ...state, isSaveViewSuccessDialogOpen: action.isOpen}
        case ActionTypes.EditViewSuccessDialogAction:
            return { ...state, isEditViewSuccessDialogOpen: action.isOpen}
        case ActionTypes.ShareViewDialogAction:
            return { ...state, isShareViewDialogOpen: action.isOpen, startDate: action.startDate, endDate:action.endDate, viewName: action.viewName, shareViewId: action.id }
        default:
            return { ...state, isSaveViewFailDialogOpen: action.isOpen, errorMessage: action.errorMessage }
    }
}

export function makeToggleDeleteDialogAction(open: boolean, viewName: string, viewId: number, viewType: ViewType): IToggleDeleteDialogsAction {
    return {
        type: ActionTypes.DeleteDialogAction,
        isOpen: open,
        viewName,
        viewId,
        viewType
    };
}

export function makeSaveViewSuceessDialogAction(open: boolean): ISaveViewSuccessDialogsAction {
    return {
        type: ActionTypes.SaveViewSuccessDialogAction,
        isOpen: open,
    };
}

export function makeEditViewSuceessDialogAction(open: boolean): IEditViewSuccessDialogsAction{
    return {
        type: ActionTypes.EditViewSuccessDialogAction,
        isOpen: open,
    };
}

export function makeSaveViewFailDialogAction(open: boolean, errorMessage = ""): ISaveViewFailDialogsAction {
    return {
        type: ActionTypes.SaveViewFaileDialogAction,
        errorMessage, 
        isOpen: open,
    };
}

export function makeToggleShareViewDialogAction(open: boolean, startDate?: string, endDate?: string, id?: string, viewName?: string) : IToggleShareViewDialogsAction {
    return {
        type: ActionTypes.ShareViewDialogAction,
        isOpen: open,
        startDate,
        endDate,
        id,
        viewName
    }
}