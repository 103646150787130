import { Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import TopNReclaimableCoreChart from "./TopNReclaimableCoreChart";
import AzureComputeCostChart from "./AzureComputeCostChart";
import styles from "../Azure.less";
import { AzureUtilization } from "./AzureUtilization";
import { AzureBudget } from "./AzureBudget";
import AzureCostChart from "./AzureCostChart";
import { useFlights } from "../../../hooks/useSettings";
import { useGetAzureCostOverview } from "../../../hooks/useAzureQuery";

interface IOverviewProps {
    updateSelectedTab: (tab: string) => void;
    setDefaultRegionDetails: (region: string) => void;
    setDefaultServiceDetails: (service: string) => void;
}

const Overview: React.FC<IOverviewProps> = (props) => {
    const { data: flights } = useFlights();
    const { publicCost, azscCost, isLoading } = useGetAzureCostOverview();
    const totalCost = publicCost + azscCost;
    
    return (
        <>
        <Stack horizontal tokens={{childrenGap: 24}}>
            <StackItem className={styles.migrationStatus} shrink grow={false}>
                <AzureCostChart />
            </StackItem>
            <StackItem className={styles.migrationStatus} shrink grow={false}>
                <AzureComputeCostChart />
            </StackItem>
        </Stack>
        {
            flights?.enableBudgets &&
            <AzureBudget updateSelectedTab={props.updateSelectedTab} />
        }
        {
            !isLoading && totalCost > 0 &&
            <>
            <TopNReclaimableCoreChart updateSelectedTab={props.updateSelectedTab} setDefaultServiceDetails={props.setDefaultServiceDetails} setDefaultRegionDetails={props.setDefaultRegionDetails} />
            <AzureUtilization updateSelectedTab={props.updateSelectedTab} />
            </>
        }
        </>
    );
}

export default Overview;