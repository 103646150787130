import React from "react";
import {
    ESCostBillingType,
    ESCostDataDimensions,
    ESCostOrganization,
    ESOverviewFYBudgetRawResponse,
    ESOverviewFiscalYearValues,
} from "../../../models/ESCostModels";
import { useEsCostResource } from "../../../hooks/useEsCostResource";
import { useEsOrg } from "../../../hooks/useEsOrg";
import { ESCostUtils } from "../Utilities/ESCostUtils";
import StackChart, { IStackChartDataProps } from "./Common/StackChart";
import { ESCostResource, ESOrgs } from "../../../models/constants/ESCostConstants";

interface IYoYStackChartProps {
    data: ESOverviewFiscalYearValues[];
    budget: ESOverviewFYBudgetRawResponse[];
}

function reduceCosts(costs: ESOverviewFiscalYearValues[], fy: number[], resources: string[]): IStackChartDataProps[] {
    const result = resources.map((resource) => ({ name: resource, values: Array.from({ length: fy.length }, () => 0) }));

    costs.forEach((cost) => {
        const i = resources.indexOf(cost[ESCostDataDimensions.BillingType]);
        const j = fy.indexOf(cost[ESCostDataDimensions.ReturnFiscalYear]);
        result[i].values[j] += cost[ESCostDataDimensions.Cost] ?? 0;
    });

    return result;
}

function getDataAndOptions(org: ESCostOrganization[], billingType: ESCostBillingType[], rawCost: ESOverviewFiscalYearValues[]) {
    const lastFY = ESCostUtils.getLastFiscalYearDates()[0].year() + 1;

    const costs = rawCost.filter(
        (cost) =>
            ESOrgs.includes(cost[ESCostDataDimensions.Organization]) &&
            ESCostResource.includes(cost[ESCostDataDimensions.BillingType]) &&
            (org.length === 0 || org.includes(cost[ESCostDataDimensions.Organization])) &&
            (billingType.length === 0 || billingType.includes(cost[ESCostDataDimensions.BillingType]))
    );

    const allResources = ESCostResource;
    const resourceList =
        billingType.length === 0
            ? allResources
            : allResources.filter((o) => billingType.includes(o)).sort((a, b) => allResources.indexOf(a) - allResources.indexOf(b));

    return {
        data: reduceCosts(costs, [lastFY, lastFY + 1], resourceList),
        categories: [`FY${String(lastFY).substring(2)}`, `FY${String(lastFY + 1).substring(2)}`],
    };
}

function getBudget(org: ESCostOrganization[], billingType: ESCostBillingType[], rawBudget: ESOverviewFYBudgetRawResponse[]): number[] {
    const costs = rawBudget.filter(
        (cost) =>
            ESOrgs.includes(cost[ESCostDataDimensions.Organization]) &&
            ESCostResource.includes(cost[ESCostDataDimensions.BillingType]) &&
            (org.length === 0 || org.includes(cost[ESCostDataDimensions.Organization])) &&
            (billingType.length === 0 || billingType.includes(cost[ESCostDataDimensions.BillingType]))
    );

    const lastFY = ESCostUtils.getLastFiscalYearDates()[0].year() + 1;
    const budgets = Array.from({ length: 2 }, () => 0);

    costs.forEach((cost) => {
        budgets[cost[ESCostDataDimensions.ReturnFiscalYear] - lastFY] += cost[ESCostDataDimensions.Budget];
    });

    return budgets;
}

const YearToYearStackChart = (props: IYoYStackChartProps) => {
    const { data: rawCost, budget: rawBudget } = props;
    const [org] = useEsOrg();
    const [billingType] = useEsCostResource();
    const budget: number[] = React.useMemo(() => getBudget(org, billingType, rawBudget), [billingType, org, rawBudget]);
    const { data, categories } = React.useMemo(() => getDataAndOptions(org, billingType, rawCost), [billingType, org, rawCost]);

    return (
        <StackChart
            data={data}
            categories={categories}
            hasBenchmark={false}
            benchmarkTitle="Total Budget"
            benchmarkValues={budget}
            description="Cost of recent two Fiscal Years"
            yAxisTitle="Actual cost"
        />
    );
};

export default YearToYearStackChart;
