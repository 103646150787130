// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T6mdLgCxeFTC69urEENq {\n  padding: 0;\n}\n.rFZydu_Q7_oh8z0r23MX {\n  background-color: #f4f4f4;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App.less"],"names":[],"mappings":"AACA;EACI,UAAA;AAAJ;AAGA;EACI,yBAAA;AADJ","sourcesContent":["\n.container {\n    padding: 0;\n}\n\n.landingPageContainer {\n    background-color: #f4f4f4\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "T6mdLgCxeFTC69urEENq",
	"landingPageContainer": "rFZydu_Q7_oh8z0r23MX"
};
export default ___CSS_LOADER_EXPORT___;
