import * as React from "react";
import { Stack } from "@fluentui/react";
import { outerStackStyles, filterStackStyles } from "../ViewsCommonLayout";
import CloudBuildGemFilterBar from "../../Filter/CloudBuildGemFilterBar";
import CloudBuildGemPage from "./CloudBuildGemPage";
import { useGetManagers } from "../../../../hooks/useES365CostQuery";
import EmptyState from "../../../common/state/EmptyState";
import LoadingState from "../../../ResponseBoundary/LoadingState";

export function returnEmptyState() {
    return <EmptyState />;
}

export function returnLoadingState() {
    return <LoadingState />;
}

export const CloudBuildGemContainer: React.FC = () => {
    const { data: esManagers, isLoading, isError } = useGetManagers();
    const returnNormalState = () => {
        if (esManagers === undefined) return returnEmptyState();
        return (
            <Stack styles={outerStackStyles} disableShrink>
                <Stack horizontal styles={filterStackStyles}>
                    <CloudBuildGemFilterBar managers={esManagers} />
                </Stack>
                <CloudBuildGemPage />
            </Stack>
        );
    };

    return isLoading ? returnLoadingState() : isError || esManagers === undefined ? returnEmptyState() : returnNormalState();
};
