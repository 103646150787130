// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X4zj5YlNEuczS_w3p0Lt {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.xPMA31sD5phSZNLaTZv7::before {\n  background-color: #323130;\n}\n.GtOBDeusLDr7vMGILXQE {\n  margin-top: 12px;\n  font-size: 14px;\n  font-weight: 600;\n}\n.Z4B5U4i_EiHeUB3jWJll {\n  margin: 8px 0 24px;\n}\n._A_oe50WLjuWnih1AL80 {\n  margin-top: 4px;\n  font-size: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/substrate/ChartContainer/ChartContainer.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAEA;EACI,yBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,gBAAA;AADJ;AAIA;EACI,kBAAA;AAFJ;AAKA;EACI,eAAA;EACA,eAAA;AAHJ","sourcesContent":[".outline {\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.separator::before {\n    background-color: #323130;\n}\n\n.summary {\n    margin-top: 12px;\n    font-size: 14px;\n    font-weight: 600;\n}\n\n.title {\n    margin: 8px 0 24px;\n}\n\n.subTitle {\n    margin-top: 4px;\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outline": "X4zj5YlNEuczS_w3p0Lt",
	"separator": "xPMA31sD5phSZNLaTZv7",
	"summary": "GtOBDeusLDr7vMGILXQE",
	"title": "Z4B5U4i_EiHeUB3jWJll",
	"subTitle": "_A_oe50WLjuWnih1AL80"
};
export default ___CSS_LOADER_EXPORT___;
