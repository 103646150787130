import { CosmosMetricEnum, CosmosSourceTypeEnum } from "../models/CosmosModels";
import { INavLink, INavLinkGroup, INavStyles, IRenderGroupHeaderProps, IStackStyles, Icon, Nav, Stack, calculatePrecision } from "@fluentui/react";
import { ISubstratePlatformBackendTotalCostCard, SubstratePlatformResourceTypeEnum } from "../models/SubstratePlatform";
import { LogComponent, LogElement, LogTarget } from "../models/LogModel";
import { Pages, SubPages } from "../models/Nav";
import { PcmV2Cost, PcmV2SceneTypeEnum } from "../models/PcmV2Models";
import React, { useCallback, useMemo } from "react";
import { sum, sumBy, values } from "lodash";
import { useGetAzureCostOverview, useGetAzureDailyMetricsQuery, useGetAzureNonComputeCosts } from "../hooks/useAzureQuery";
import { useGetIOInsightsByServiceId, useGetPcmV2Cost } from "../hooks/useSubstrateV2Query";

import { Endpoints } from "../utils/Constants";
import { Flight } from "../models/Flights";
import { IconButton } from "@fluentui/react";
import { NON_COMPUTE_CATEGORIES } from "./azure/common/AzureComputeConstants";
import { PCMMetric } from "../models/PCMMetrics";
import { currencyFormatter } from "../utils/currency";
import { getTabCache } from "../utils/pageUtils";
import styles from "./Navigation.less";
import { trackEventCallback } from "../utils/AppInsights";
import { useBillingCost } from "../hooks/useBillingCost";
import { useFlights } from "../hooks/useSettings";
import { useGetCosmicWorkloadDailyAppMetric } from "../hooks/useCosmicQuery";
import { useGetCosmosDailyMetrics } from "../hooks/useCosmosQuery";
import { useGetDailyMetricsQuery } from "../hooks/useSubstrateQuery";
import { useGetSubstratePlatformBackendTotalCost } from "../hooks/useSubstratePlatformQuery";
import { useGetTelemetryResourceCost } from "../hooks/useTelemetryCost";
import { useGotoPage } from "../hooks/useGotoPage";
import { useLocation } from "react-router-dom";

const outerStackStyles: IStackStyles = {
    root: {
        flex: "0 0 10%",
        height: "100%",
        boxSizing: "border-box",
        border: "1px solid #E1E1E1",
        overflowY: "auto",
        minWidth: "205px",
        background: "white",
    },
};

const navStyles: Partial<INavStyles> = {
    groupContent: {
        marginBottom: 12,
    },
    linkText: {
        left: "48px",
        top: calculatePrecision((50 % -"20px") / 2),
        height: "20px",
        width: "184px",
        fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
    },
    link: {
        selectors: {
            "&:after": {
                "border-left": "4px solid",
                "border-left-color": "#0078D4",
            },
        },
        ".ms-Icon": {
            "font-size": "14px",
            color: "#323130",
            width: 16,
        },
        ".ms-Button-flexContainer": {
            "margin-left": "12px",
        },
    },
};

const homePageNavStyles: Partial<INavStyles> = {
    groupContent: {
        marginBottom: 0,
    },
    linkText: {
        left: "48px",
        top: calculatePrecision((50 % -"20px") / 2),
        height: "20px",
        width: "184px",
        fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
    },
    link: {
        ".ms-Icon": {
            "font-size": "14px",
            color: "#323130",
            width: 16,
        },
        ".ms-Button-flexContainer": {
            "margin-left": "12px",
        },
    },
};

const expertInsightsStyles: Partial<INavStyles> = {
    groupContent: {
        marginBottom: 12,
    },
    linkText: {
        left: "48px",
        top: calculatePrecision((50 % -"20px") / 2),
        height: "20px",
        width: "184px",
        fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
    },
    link: {
        selectors: {
            "&:after": {
                "border-left": "4px solid",
                "border-left-color": "#0078D4",
            },
        },
        ".ms-Icon": {
            "font-size": "14px",
            color: "#323130",
            width: 16,
        },
        ".ms-Button-flexContainer": {
            "margin-left": "12px",
        },
    },
};

const quickLinkStyles: Partial<INavStyles> = {
    groupContent: {
        marginBottom: 12,
    },
    linkText: {
        left: "48px",
        top: calculatePrecision((50 % -"20px") / 2),
        height: "20px",
        width: "184px",
        fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
    },
    link: {
        selectors: {
            "&:after": {
                "border-left": "4px solid",
                "border-left-color": "#0078D4",
            },
        },
        ".ms-Icon": {
            "font-size": "14px",
            color: "#323130",
            width: 16,
        },
        ".ms-Button-flexContainer": {
            "margin-left": "12px",
        },
    },
};

const getQuickLinks = function(flights?: Flight): INavLinkGroup[] {
    const links = [
        {
            name: "COSMIC Jaws v2 PBI Dashboard",
            url: "https://msit.powerbi.com/groups/b61e509d-d7b3-4375-a9ae-0f2681bc59c8/reports/262f632f-f91f-43d9-8ef1-2d808eb414a4/ReportSection170f082303eedd090d13?experience=power-bi",
            target: "_blank",
            icon: "ODLink",
            logElement: LogElement.CosmicJaws,
        },
        {
            name: "AzSC Migration",
            url: "https://msit.powerbi.com/groups/af2aae51-edc3-4f59-84bb-969042d05813/reports/5555a378-27e1-454a-8cb9-0da8ab6677c4/ReportSectionac2e2a06b8658bc79301",
            target: "_blank",
            icon: "ODLink",
            LogElement: LogElement.AzSCMigration,
        },
        {
            name: "Efficiency Tracker",
            url: window.location.origin + "/#/EfficiencyTrackerV2",
            target: "_blank",
            icon: "ODLink",
            LogElement: LogElement.EfficiencyTracker,
        }
    ];

    if (flights?.enableSubstratePlatform) {
        links.push({
                name: "Substrate Platform",
                url: window.location.origin + "/#/SubstratePlatform",
                target: "_blank",
                icon: "ODLink",
                LogElement: LogElement.SubstratePlatform,
            });
    }

    if (flights?.enablePlatformCogsCalculator) {
        links.push({
                name: "COGS Calculator",
                url: window.location.origin + "/#/CogsCalculator",
                target: "_blank",
                icon: "ODLink",
                LogElement: LogElement.CogsCalculator,
            });
    }

    return [
        {
            name: "Quick Links",
            links
        },
    ];
}

const useCosmosNavLink = (): INavLink[] => {
    const totalCost = useGetCosmosDailyMetrics(CosmosSourceTypeEnum.All, CosmosMetricEnum.Costs);
    const processingCosts = useGetCosmosDailyMetrics(CosmosSourceTypeEnum.Processing, CosmosMetricEnum.Costs);
    const storageCosts = useGetCosmosDailyMetrics(CosmosSourceTypeEnum.Storage, CosmosMetricEnum.Costs);

    return useMemo(() => {
        return [
            {
                name: "COSMOS BDP",
                url: "/COSMOS",
                key: "/COSMOS",
                cost: totalCost?.data?.total || 0,
                icon: "AzureCosmosDB",
                logElement: LogElement.CosmosNavLink,
            },
            {
                name: "Storage",
                key: "/COSMOS/Storage",
                url: "/COSMOS/Storage",
                cost: storageCosts?.data?.total || 0,
                logElement: LogElement.CosmosStorageNavLink,
            },
            {
                name: "Processing",
                key: "/COSMOS/Processing",
                url: "/COSMOS/Processing",
                cost: processingCosts?.data?.total || 0,
                logElement: LogElement.CosmosProcessingNavLink,
            },
        ];
    }, [totalCost.data, processingCosts.data, storageCosts.data]);
};

const useCosmicNavLink = (): INavLink[] => {
    const { cosmicHierarchicalMetricReport: appCoreCogsWorkloadMetrics} = useGetCosmicWorkloadDailyAppMetric("AppCoreCogs");
    const totalCost = appCoreCogsWorkloadMetrics.filter((singleMetric) => singleMetric.metric === "AppCoreCogs").reduce((acc, curr) => acc + (curr.total ?? 0), 0);

    // comment Leadership View
    // return useMemo(() => {
    //     return [
    //         {
    //             name: "COSMIC L&P",
    //             url: "/COSMIC",
    //             key: "/COSMIC",
    //             cost: null,
    //             icon: "CosmicIcon",
    //             logElement: LogElement.CosmicNavLink,
    //         },
    //         {
    //             name: "Partner",
    //             key: "/COSMIC/Partner",
    //             url: "/COSMIC/Partner",
    //             cost: 21.41 * 1000 * 1000,
    //             logElement: LogElement.CosmicPartnerNavLink,
    //         },
    //         {
    //             name: "Leadership",
    //             key: "/COSMIC/Leadership",
    //             url: "/COSMIC/Leadership",
    //             cost: 39.67 * 1000 * 1000,
    //             logElement: LogElement.CosmicLeadershipNavLink,
    //         }
    //     ];
    // }, [totalCost, leadershipCost, platformCost]);

    return useMemo(() => {
        return [
            {
                name: "COSMIC",
                url: "/COSMIC/Partner",
                key: "/COSMIC/Partner",
                cost: totalCost,
                icon: "CosmicIcon",
                logElement: LogElement.CosmicPartnerNavLink,
            }
        ];
    }, [totalCost]);
};


const useTelemetryNavLink = (): INavLink[] => {
    const telemetryCost = useGetTelemetryResourceCost();

    return useMemo(() => {
        return [
            {
                name: "Telemetry",
                url: "/Telemetry",
                key: "/Telemetry",
                cost: telemetryCost?.data?.reduce((sum, val) => sum + val.cosmosCost + val.blueShiftCost + val.kustoCost + val.genevaMdsCost + val.genevaMdmCost + val.ariaCost, 0) || 0,
                icon: "AzureCosmosDB",
                logElement: LogElement.TelemetryNavLink,
            },
            {
                name: "Cosmos",
                url: "/Telemetry/Cosmos",
                key: "/Telemetry/Cosmos",
                cost: telemetryCost?.data?.reduce((sum, val) => sum + val.cosmosCost, 0) || 0,
                logElement: LogElement.TelemetryCosmosNavLink,
            },
            {
                name: "Blueshift",
                url: "/Telemetry/Blueshift",
                key: "/Telemetry/Blueshift",
                cost: telemetryCost?.data?.reduce((sum, val) => sum + val.blueShiftCost, 0) || 0,
                logElement: LogElement.BlueshiftNavLink,
            },
            {
                name: "Kusto",
                url: "/Telemetry/Kusto",
                key: "/Telemetry/Kusto",
                cost: telemetryCost?.data?.reduce((sum, val) => sum + val.kustoCost, 0) || 0,
                logElement: LogElement.KustoNavLink,
            },
            {
                name: "Geneva MDS",
                url: "/Telemetry/GenevaMds",
                key: "/Telemetry/GenevaMds",
                cost: telemetryCost?.data?.reduce((sum, val) => sum + val.genevaMdsCost, 0) || 0,
                logElement: LogElement.GenevaMdsNavLink,
            },
            {
                name: "Geneva MDM",
                url: "/Telemetry/GenevaMdm",
                key: "/Telemetry/GenevaMdm",
                cost: telemetryCost?.data?.reduce((sum, val) => sum + val.genevaMdmCost, 0) || 0,
                logElement: LogElement.GenevaMdmNavLink,
            },
            {
                name: "Aria",
                url: "/Telemetry/Aria",
                key: "/Telemetry/Aria",
                cost: telemetryCost?.data?.reduce((sum, val) => sum + val.ariaCost, 0) || 0,
                logElement: LogElement.AriaNavLink,
            },
            {
                name: "PassiveMonitoring",
                url: "/Telemetry/PassiveMonitoring",
                key: "/Telemetry/PassiveMonitoring",
                cost: telemetryCost?.data?.reduce((sum, val) => sum + val.passiveMonCost, 0) || 0,
                logElement: LogElement.PassiveMonitoringNavLink,
            },
        ];
    }, [telemetryCost.data]);
};

const getSubstrateV2Links = (pcmv2Cost?: PcmV2Cost): INavLink[] => {
    return [
        {
            name: "Substrate",
            key: Pages.SubstrateV2,
            url: Pages.SubstrateV2,
            icon: "CharticulatorLinkingData",
            cost: pcmv2Cost?.cost || 0,
            logElement: LogElement.SubstrateV2
        },
        {
            name: "Transaction",
            key: `${Pages.SubstrateV2}/${SubPages.Transaction}`,
            url: `${Pages.SubstrateV2}/${SubPages.Transaction}`,
            cost: pcmv2Cost?.transactionCost || 0,
            logElement: LogElement.TransactionV2,
        },
        {
            name: "Process Hosting",
            key: `${Pages.SubstrateV2}/${SubPages.ProcessHosting}`,
            url: `${Pages.SubstrateV2}/${SubPages.ProcessHosting}`,
            cost: pcmv2Cost?.processHostingCost || 0,
            logElement: LogElement.ProcessHostingV2
        },
        {
            name: "Network",
            key: `${Pages.SubstrateV2}/${SubPages.Network}`,
            url: `${Pages.SubstrateV2}/${SubPages.Network}`,
            cost: pcmv2Cost?.networkCost || 0,
            logElement: LogElement.NetworkV2
        },
        {
            name: "HDD-Storage",
            key: `${Pages.SubstrateV2}/${SubPages.Hdd}`,
            url: `${Pages.SubstrateV2}/${SubPages.Hdd}`,
            cost: pcmv2Cost?.hddCost || 0,
            logElement: LogElement.HddV2
        },
        {
            name: "SSD-Storage",
            key: `${Pages.SubstrateV2}/${SubPages.Ssd}`,
            url: `${Pages.SubstrateV2}/${SubPages.Ssd}`,
            cost: pcmv2Cost?.ssdCost || 0,
            logElement: LogElement.SsdV2
        },
    ];
};

const getSubstratePlatformLinks = (substrateplatformCost?: ISubstratePlatformBackendTotalCostCard[]): INavLink[] => {
    const totalCost = substrateplatformCost?.reduce((accumulator, current) => {
        return accumulator + (current.cost || 0);
    }, 0) || 0;
    return [
        {
            name: "Substrate Platform",
            key: Pages.SubstratePlatform,
            url: Pages.SubstratePlatform,
            icon: "CharticulatorLinkingData",
            cost: totalCost || 0,
            logElement: LogElement.SubstratePlatform,
        },
        {
            name: "CPU",
            key: "CPU",
            url: '',
            cost: substrateplatformCost?.find(item => item.type === SubstratePlatformResourceTypeEnum.CPU)?.cost || 0,
            logElement: LogElement.SubstratePlatform,
        },
        {
            name: "Memory",
            key: "Memory",
            url: '',
            cost: substrateplatformCost?.find(item => item.type === SubstratePlatformResourceTypeEnum.Memory)?.cost || 0,
            logElement: LogElement.SubstratePlatform
        },
        {
            name: "IO",
            key: "IO",
            url: '',
            cost: substrateplatformCost?.find(item => item.type === SubstratePlatformResourceTypeEnum.IO)?.cost || 0,
            logElement: LogElement.SubstratePlatform
        },
        {
            name: "Storage HDD",
            key: "Storage HDD",
            url: '',
            cost: substrateplatformCost?.find(item => item.type === SubstratePlatformResourceTypeEnum.HDDStorage)?.cost || 0,
            logElement: LogElement.SubstratePlatform
        },
        {
            name: "Storage SSD",
            key: "Storage SSD",
            url: '',
            cost: substrateplatformCost?.find(item => item.type === SubstratePlatformResourceTypeEnum.SSDStorage)?.cost || 0,
            logElement: LogElement.SubstratePlatform
        },
    ];
};

const Navigation: React.FC = () => {
    const location = useLocation();
    const gotoPage = useGotoPage();
    const { data: flights } = useFlights();
    const [showBillingCost] = useBillingCost();
    const { publicCost, azscCost, nonCompute } = useGetAzureCostOverview(showBillingCost && flights?.enableBillingCost);

    const azureNavLinks = useMemo(
        () => getAzureNavLinks(publicCost, azscCost, nonCompute),
        [publicCost, azscCost, nonCompute]
    );

    const { data: substrateV2Cost } = useGetPcmV2Cost();
    const { data: substratePlatformCost } = useGetSubstratePlatformBackendTotalCost();
    const { data: dailyMetrics } = useGetDailyMetricsQuery(flights?.enableSubstrateV1);
    const cosmosNavLinks = useCosmosNavLink();
    const telemetryNavLinks = useTelemetryNavLink();
    const homePageLink = flights?.enableLandingPage ? [{
        name: "Home Page",
        key: Pages.LandingPage,
        url: Pages.LandingPage,
        logElement: LogElement.HomePage,
        icon: "HomePageIcon",
    }] : []
    const substrateV2Links = flights?.enableSubstrateV2 ? getSubstrateV2Links(substrateV2Cost) : [];
    const substratePlatformLinks = flights?.enableSubstratePlatform ? getSubstratePlatformLinks(substratePlatformCost) : [];
    const substrateLinks = !location.pathname.startsWith("/SubstratePlatform") ? substrateV2Links : substratePlatformLinks;
    const substrateV1NavLinks = useMemo(() => flights?.enableSubstrateV1 ? getSubstrateNavLinks(dailyMetrics?.distribution.cost) : [], [dailyMetrics, flights]);

    // initialize cosmic links into navigation
    const cosmicNavLinks = useCosmicNavLink();

    const handleClick = useCallback(
        (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
            if (item?.target !== "_blank") {
                ev?.preventDefault();
                if (item?.url) {
                    const tab = getTabCache(item.tabKey || item.url);
                    gotoPage(item.url + (tab ? `/${tab}` : ""));
                }
            }
            trackEventCallback(LogComponent.PivotNavPane, item?.logElement, item?.title || "", LogTarget.Nav);
        },
        [gotoPage]
    );

    const anomalies = useGetIOInsightsByServiceId(PcmV2SceneTypeEnum.Transaction);
    const quickLinks = useMemo(() => getQuickLinks(flights), [flights]);

    const renderLinkWithSeparator = (navLink: INavLink | undefined) => {
        if (navLink?.icon) {
            return (
                <div style={{ width: '100%', display: 'flex', float: 'left'}}>
                    <div className={styles.navBarTotal}>
                        <span>{navLink?.name}</span>
                        <span>{currencyFormatter(navLink?.cost, 2, "$")}</span>
                    </div>
                </div>
            );
        }

        return (
            <div style={{ width: '100%', display: 'flex', float: 'left' }}>
                <div className={styles.navBar}>
                    <span>{navLink?.name}</span>
                    <span>{currencyFormatter(navLink?.cost, 2, "$")}</span>
                </div>
                {anomalies?.length > 0 && navLink?.name === "Transaction" && (
                    <div style={{ width: 15, marginLeft: 5, marginRight: 0 }}>
                        <Icon title="Explore IO Insights for your Service!" iconName="Lightbulb" />
                    </div>
                )}
            </div>
        );
    };

    return (
        <Stack styles={outerStackStyles}>
            {flights?.enableLandingPage && (<>
                <Nav
                    styles={homePageNavStyles}
                    groups={[
                        {
                            links: [...homePageLink],
                        },
                    ]}
                    onLinkClick={handleClick}
                    selectedKey={getActivePage(location.pathname)} />
                <Stack.Item shrink={0}>
                    <div className={(styles.separator)} />
                </Stack.Item></>)}
            {!flights?.enableTelemetryCostFlight && flights?.enableCosmicPartnerViewFlight && (
                <Nav
                    styles={navStyles}
                    groups={[
                        {
                            links: [...substrateV1NavLinks, ...substrateLinks, ...azureNavLinks, ...cosmicNavLinks, ...cosmosNavLinks],
                        },
                    ]}
                    onLinkClick={handleClick}
                    selectedKey={getActivePage(location.pathname)}
                    onRenderLink={(navLink) => renderLinkWithSeparator(navLink)}
                />
            )}
            {!flights?.enableTelemetryCostFlight && !flights?.enableCosmicPartnerViewFlight && (
                <Nav
                    styles={navStyles}
                    groups={[
                        {
                            links: [...substrateV1NavLinks, ...substrateLinks, ...azureNavLinks, ...cosmosNavLinks],
                        },
                    ]}
                    onLinkClick={handleClick}
                    selectedKey={getActivePage(location.pathname)}
                    onRenderLink={(navLink) => renderLinkWithSeparator(navLink)}
                />
            )}
            {flights?.enableTelemetryCostFlight && flights?.enableCosmicPartnerViewFlight && (
                <Nav
                    styles={navStyles}
                    groups={[
                        {
                            links: [...substrateV1NavLinks, ...substrateLinks, ...azureNavLinks, ...cosmicNavLinks, ...telemetryNavLinks],
                        },
                    ]}
                    onLinkClick={handleClick}
                    selectedKey={getActivePage(location.pathname)}
                    onRenderLink={(navLink) => renderLinkWithSeparator(navLink)}
                />
            )}
            {flights?.enableTelemetryCostFlight && !flights?.enableCosmicPartnerViewFlight && (
                <Nav
                    styles={navStyles}
                    groups={[
                        {
                            links: [...substrateV1NavLinks, ...substrateLinks, ...azureNavLinks, ...telemetryNavLinks],
                        },
                    ]}
                    onLinkClick={handleClick}
                    selectedKey={getActivePage(location.pathname)}
                    onRenderLink={(navLink) => renderLinkWithSeparator(navLink)}
                />
            )}
            <Stack.Item shrink={0}>
                <div className={styles.separator} />
            </Stack.Item>
            <Nav onRenderGroupHeader={_onRenderGroupHeader} styles={quickLinkStyles} groups={quickLinks} onLinkClick={handleClick} />
        </Stack>
    );
};

function _onRenderGroupHeader(group?: IRenderGroupHeaderProps): JSX.Element | null {
    if (!group) return null;
    return <div className={styles.groupHeader}>{group.name}</div>;
}

function getSubstrateNavLinks(costs?: Record<PCMMetric, number>): INavLink[] {
    const totalCost = sum(values(costs));
    const ssdCost = costs ? costs.itemSize + costs.kvCacheSizeGb + costs.llcSizeGb : 0;
    const transactionCost = totalCost - ssdCost;

    return [
        {
            name: "Substrate",
            key: Pages.Substrate,
            url: Pages.Substrate,
            icon: "CharticulatorLinkingData",
            cost: sum(values(costs)),
            logElement: LogElement.Substrate,
        },
        {
            name: "Transaction",
            key: `${Pages.Substrate}/${SubPages.Transaction}`,
            url: `${Pages.Substrate}/${SubPages.Transaction}`,
            cost: transactionCost,
            logElement: LogElement.Transaction,
        },
        {
            name: "SSD",
            key: `${Pages.Substrate}/${SubPages.Ssd}`,
            url: `${Pages.Substrate}/${SubPages.Ssd}`,
            cost: ssdCost,
            logElement: LogElement.SSD,
        },
    ];
}

function getAzureNavLinks(publicCost?: number, azscCost?: number, nonComputeTotal?: number): INavLink[] {
    return [
        {
            name: "Azure Costs",
            key: Pages.Azure,
            url: Pages.Azure,
            icon: "AzureIcon",
            cost: (publicCost || 0) + (azscCost || 0) + (nonComputeTotal || 0),
            logElement: LogElement.AzureCompute,
        },
        {
            name: "Public Compute",
            key: `${Pages.Azure}/${SubPages.Public}`,
            url: `${Pages.Azure}/${SubPages.Public}`,
            tabKey: Pages.Azure,
            cost: publicCost || 0,
            logElement: LogElement.AzurePublic,
        },
        {
            name: "AzSC Compute",
            key: `${Pages.Azure}/${SubPages.AzSC}`,
            url: `${Pages.Azure}/${SubPages.AzSC}`,
            tabKey: Pages.Azure,
            cost: azscCost || 0,
            logElement: LogElement.AzureAzsc,
        },
    ];
}

function getActivePage(path: string): string {
    if (path.startsWith(`${Pages.SubstrateV2}/${SubPages.Transaction}`)) {
        return `${Pages.SubstrateV2}/${SubPages.Transaction}`;
    } else if (path.startsWith(`${Pages.SubstrateV2}/${SubPages.ProcessHosting}`)) {
        return `${Pages.SubstrateV2}/${SubPages.ProcessHosting}`;
    } else if (path.startsWith(`${Pages.SubstrateV2}/${SubPages.Network}`)) {
        return `${Pages.SubstrateV2}/${SubPages.Network}`;
    } else if (path.startsWith(`${Pages.SubstrateV2}/${SubPages.Hdd}`)) {
        return `${Pages.SubstrateV2}/${SubPages.Hdd}`;
    } else if (path.startsWith(`${Pages.SubstrateV2}/${SubPages.Ssd}`)) {
        return `${Pages.SubstrateV2}/${SubPages.Ssd}`;
    } else if (path.startsWith(`${Pages.SubstrateV2}`)) {
        return Pages.SubstrateV2;
    } else if (path.startsWith(`${Pages.Substrate}/${SubPages.Transaction}`)) {
        return `${Pages.Substrate}/${SubPages.Transaction}`;
    } else if (path.startsWith(`${Pages.Substrate}/${SubPages.Ssd}`)) {
        return `${Pages.Substrate}/${SubPages.Ssd}`;
    } else if (path.startsWith(Pages.Substrate)) {
        return Pages.Substrate;
    } else if (path.startsWith(`${Pages.Azure}/${SubPages.Public}`)) {
        return `${Pages.Azure}/${SubPages.Public}`;
    } else if (path.startsWith(`${Pages.Azure}/${SubPages.AzSC}`)) {
        return `${Pages.Azure}/${SubPages.AzSC}`;
    } else if (path.startsWith(Pages.Azure)) {
        return Pages.Azure;
    } else {
        return path;
    }
}

export default Navigation;
