import { Stack } from "@fluentui/react";
import * as React from "react";
import { useGotoPage } from "../../hooks/useGotoPage";
import CommonConstants from "../../models/constants/CommonConstants";
import { LogComponent, LogElement, LogTarget } from "../../models/LogModel";
import { trackEventCallback } from "../../utils/AppInsights";
import { currencyFormatter } from "../../utils/currency";
import CostCard from "../common/costCard/CostCard";
import LoadingState from "../ResponseBoundary/LoadingState";
import styles from "./LandingPage.less";
import { useGetSubstratePlatformBackendTotalCost, useGetSubstratePlatformDailyReportQuery } from "../../hooks/useSubstratePlatformQuery";
import { getSubstratePlatformResourceTypeString, SubstratePlatformPivotEnum } from "../../models/SubstratePlatform";
import { sumBy } from "lodash";
import LineChart from "../common/LineChart";

export const SubstratePlatformCard = () => {
    const { data: substratePlatformCost, isLoading: substratePlatformLoading } = useGetSubstratePlatformBackendTotalCost();
    const { data: dailyCost, isLoading: dailyCostLoading } = useGetSubstratePlatformDailyReportQuery(SubstratePlatformPivotEnum.Resource, false);
    const gotoPage = useGotoPage();
    const totalCost = React.useMemo(
        () =>
            sumBy(substratePlatformCost, (item) => item.cost),
        [substratePlatformCost]
    );
    
    return (
        <>
        <div className={styles.section}>
            <Stack horizontal horizontalAlign="space-between" className={styles.sectionHeaderContent2}>
                <h4
                    className={styles.sectionHeaderContent2}
                    onClick={() => {
                        trackEventCallback(LogComponent.LandingPage, LogElement.NavigateToSubstratePlatform, "Navigate to Substrate Platform", LogTarget.Title);
                        gotoPage("/SubstratePlatform");
                    }}
                >
                    Substrate
                </h4>
            </Stack>
        </div>
        {!substratePlatformLoading ? (
            <>
                <div className={styles.title}>
                    <span className={styles.titleTotalCost}>{currencyFormatter(totalCost, 2, "$")}</span> Total Cost
                </div>
                <div className={styles.grid}>
                    {
                        substratePlatformCost?.map((item, index) => (
                            <div key={item.type}>
                                <CostCard
                                    title={getSubstratePlatformResourceTypeString(item.type)}
                                    color={CommonConstants.DefaultColors[index]}
                                    cost={item.cost}
                                />
                            </div>
                        ))
                    }
                </div>
            </>
        ) : (
            <LoadingState />
        )}
        <div className={styles.sectionHeaderContent}>
            <h4 className={styles.sectionHeaderTitle}>Daily Cost Trend</h4>
            {!dailyCostLoading ? (
                dailyCost ? (
                    <LineChart
                        series={dailyCost.map(report => ({
                            name: report.metric,
                            data: report.data.map(singleDayMetric => [singleDayMetric.date.valueOf(), singleDayMetric.metricValue])
                        }))}
                        totalTextPrefix="Total Costs: "
                        colors={CommonConstants.DefaultColors}
                        height={280}
                    />
                ) : (
                    <></>
                )
            ) : (
                <LoadingState />
            )}
        </div>
        <button
            className={styles.navButton}
            onClick={() => {
                trackEventCallback(LogComponent.LandingPage, LogElement.NavigateToSubstratePlatform, "Navigate to Substrate Platform", LogTarget.Button);
                gotoPage("/SubstratePlatform");
            }}
        >
            View Substrate
        </button>
        </>
    )
}