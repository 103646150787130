import LineChart, { LineChartSeries } from "../../common/LineChart";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import React, { useMemo } from "react";

import ChartContainer from "../../chart/ChartContainer";
import CommonConstants from "../../../models/constants/CommonConstants";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { SubstrateRings } from "../../../models/constants/PcmV2Constants";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useGetPcmV2CostByRing } from "../../../hooks/useSubstrateV2Query";
import { useTrackHovering } from "../../../hooks/useTrack";

export const SubstrateRingCostTrends: React.FC = () => {
    const query = useGetPcmV2CostByRing();
    const trackingProps = useTrackHovering(LogComponent.SubstrateChartingPane, LogElement.SubstrateTrendByDeploymentRing, "COGS Trend by Deployment Rings", LogTarget.Chart);
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.SubstrateV2);

    const series: LineChartSeries[] = useMemo(() => {
        if (!query.data) {
            return [];
        }

        return SubstrateRings.map<LineChartSeries | null>((ring, index) => {
            return {
                name: ring,
                data: query.data[ring]?.map((item) => [item.extractionDate.valueOf(), item.cost]) || [],
                showDeviation: true,
                color: CommonConstants.DefaultColors[index],
            };
        }).filter((s) => !!s) as LineChartSeries[];
    }, [query.data]);

    return (
        <ChartContainer
            headerProps={{ title: "COGS Trend by Deployment Rings" }}
            loading={query.isLoading}
            chartType="line"
            chartProps={{
                height: 220,
                series,
                hideEmptySeries: true,
                anomalies: getActiveAnomalies(anomaliesQuery.data),
                containerProps: trackingProps,
            }}
            logProps={{
                logComponent: LogComponent.SubstrateV2,
                logElement: LogElement.SubstrateTrendByDeploymentRing,
            }}
        />
    );
};
