/** Renders the form for the resource information section of the project form:
 * - Resource type (VCore, Memory, etc.)
 * - Platform (AzSC, COSMIC, etc.)
 * - Service ID or Subscription ID
 */
import { CopsResourceTypeEnum, IEfficiencyProjectForm, IErrorDict  } from '../../../../../models/EfficiencyTracker';
import React, { useRef, useState } from 'react';
import styles from "./Forms.less";
import { ChoiceGroup } from '@fluentui/react/lib/ChoiceGroup';
import { Stack } from '@fluentui/react/lib/Stack';
import { Updater } from 'use-immer';
import { Label } from '@fluentui/react/lib/Label';
import { DefaultButton, IconButton, MessageBar, MessageBarType, Spinner } from '@fluentui/react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { OptionType, getLoadOptions } from '../SelectUtils';
import { Component, ComponentTypeEnum, EfficiencyTargetCategoryEnum } from '../../../../../models/EfficiencyTracker';
import { useComponents } from '../../../../../hooks/useEfficiencyTrackerProject';
import { MultiValue } from 'react-select';

interface IProps {
    formData: IEfficiencyProjectForm;
    updateFormData: Updater<IEfficiencyProjectForm>;
    errorDict: IErrorDict;
    updateErrorDict: Updater<IErrorDict>;
    editMode: boolean;
}

const BaremetalResourceType = [
    { key: CopsResourceTypeEnum.IO, text: CopsResourceTypeEnum.IO, },
    { key: CopsResourceTypeEnum.CPU, text: CopsResourceTypeEnum.CPU },
    { key: CopsResourceTypeEnum.Memory, text: CopsResourceTypeEnum.Memory },
    { key: CopsResourceTypeEnum.HDD, text: CopsResourceTypeEnum.HDD },
    { key: CopsResourceTypeEnum.SSD, text: CopsResourceTypeEnum.SSD },
];

interface CustomStyles {
    multiValue: (base: Record<string, any>) => Record<string, any>;
    control: (base: Record<string, any>) => Record<string, any>;
    menuList: (base: Record<string, any>) => Record<string, any>;
    valueContainer: (base: Record<string, any>) => Record<string, any>;
    container: (base: Record<string, any>) => Record<string, any>;
}

const customAsyncPaginateStyles: CustomStyles = {
    multiValue: (base) => ({ ...base, maxWidth: 150 }),
    control: (base) => ({ ...base, minWidth: 400 }),
    menuList: (base) => ({ ...base, maxHeight: 300 }),
    valueContainer: (base) => ({ ...base, maxHeight: 60, overflow: 'auto' }),
    container: (base) => ({ ...base, flex: 1, marginTop: 5, marginBottom: 10 }),
};

const ResourceInfoBaremetal = ({ formData, updateFormData, errorDict, updateErrorDict, editMode }: IProps) => {
    // Init value
    const [storageCategory, setStorageCategory] = useState<EfficiencyTargetCategoryEnum>(formData.EfficiencyTargetCatagory || EfficiencyTargetCategoryEnum.Demands);
    const [trackingGap, setTrackingGap] = useState<number>(formData.TrackingGap || 0);
    const [uploadComponentType, setUploadComponentType] = useState<ComponentTypeEnum>(ComponentTypeEnum.RestApp);

    // Load Value selection Function
    const DistinctAndMapToSelectOptions = React.useCallback(function (items: string[]) {
        const distinctItems = items;
        return distinctItems.map((item) => {
            return { value: item, label: item };
        });
    }, []);

    const restAppDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.RestApp));
    const skuDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.Sku));
    const forestDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.Forest));
    const procDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.Process));
    const dagDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.Dag));
    const shardDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.Shard));
    const dagConfigurationDropDownList = DistinctAndMapToSelectOptions(useComponents(ComponentTypeEnum.DagConfiguration));

    const handleStorageCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStorageCategory(event.target.value as EfficiencyTargetCategoryEnum);
        updateFormData(formData => {
            formData.EfficiencyTargetCatagory = event.target.value as EfficiencyTargetCategoryEnum;
        });
    };

    const handleTrackingGapChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const gap = (event.target.value === 'Yes' ? 1 : 0);
        setTrackingGap(gap);
        updateFormData(formData => {
            formData.TrackingGap = gap;
        });
    };

    const handleComponentDropdownChange = (newValue: MultiValue<OptionType>, component: ComponentTypeEnum) => {
        const multivalue: Component[] = newValue.map(option => ({
            Type: component,
            Value: option.value,
        }))

        switch (component) {
            case ComponentTypeEnum.RestApp:
                updateFormData(formData => {
                    formData.RestApp = multivalue;
                });
                break;
            case ComponentTypeEnum.Process:
                updateFormData(formData => {
                    formData.Process = multivalue;
                });
                break;
            case ComponentTypeEnum.Shard:
                updateFormData(formData => {
                    formData.Shard = multivalue;
                });
                break;
            case ComponentTypeEnum.Forest:
                updateFormData(formData => {
                    formData.Forest = multivalue;
                });
                break;
            case ComponentTypeEnum.Sku:
                updateFormData(formData => {
                    formData.Sku = multivalue;
                });
                break;
            case ComponentTypeEnum.Dag:
                updateFormData(formData => {
                    formData.Dag = multivalue;
                });
                break;
            case ComponentTypeEnum.DagConfiguration:
                updateFormData(formData => {
                    formData.DagConfiguration = multivalue;
                });
                break;
            default:
                break;
        }
    };

    const fileInputRef = useRef<HTMLInputElement>(null);

    //Upload Selected csv file button
    const handleUpload = (componentType: ComponentTypeEnum) => {
        fileInputRef.current?.click();
        setUploadComponentType(componentType);
    };

    const checkUploadingItems = (cleanedData: string[], componentType: ComponentTypeEnum): boolean => {
        switch (componentType) {
            case ComponentTypeEnum.RestApp:
                const invalidRestAppEntries = cleanedData.filter(option => !restAppDropDownList.map(item => item.value).includes(option));
                if (invalidRestAppEntries.length > 0) {
                    updateErrorDict(errorDict => {
                        errorDict.RestAppComponentError = "Please Upload right RestApp!";
                    });
                    return false;
                }
                if ("RestAppComponentError" in errorDict) {
                    delete errorDict.RestAppComponentError;
                }
                return true;
            case ComponentTypeEnum.Process:
                const invalidProcEntries = cleanedData.filter(option => !procDropDownList.map(item => item.value).includes(option));
                if (invalidProcEntries.length > 0) {
                    updateErrorDict(errorDict => {
                        errorDict.ProcComponentError = "Please Upload right Process!";
                    });
                    return false;
                }
                if ("ProcComponentError" in errorDict) {
                    delete errorDict.ProcComponentError;
                }
                return true;
            case ComponentTypeEnum.Shard:
                const invalidShardEntries = cleanedData.filter(option => !shardDropDownList.map(item => item.value).includes(option));
                if (invalidShardEntries.length > 0) {
                    updateErrorDict(errorDict => {
                        errorDict.ShardComponentError = "Please Upload right Shard!";
                    });
                    return false;
                }
                if ("ShardComponentError" in errorDict) {
                    delete errorDict.ShardComponentError;
                }
                return true;
            case ComponentTypeEnum.Forest:
                const invalidForestEntries = cleanedData.filter(option => !forestDropDownList.map(item => item.value).includes(option));
                if (invalidForestEntries.length > 0) {
                    updateErrorDict(errorDict => {
                        errorDict.ForestComponentError = "Please Upload right Forest!";
                    });
                    return false;
                }
                if ("ForestComponentError" in errorDict) {
                    delete errorDict.ForestComponentError;
                }
                return true;
            case ComponentTypeEnum.Sku:
                const invalidSkuEntries = cleanedData.filter(option => !skuDropDownList.map(item => item.value).includes(option));
                if (invalidSkuEntries.length > 0) {
                    updateErrorDict(errorDict => {
                        errorDict.SkuComponentError = "Please Upload right Sku!";
                    });
                    return false;
                }
                if ("SkuComponentError" in errorDict) {
                    delete errorDict.SkuComponentError;
                }
                return true;
            case ComponentTypeEnum.Dag:
                const invalidDagEntries = cleanedData.filter(option => !dagDropDownList.map(item => item.value).includes(option));
                if (invalidDagEntries.length > 0) {
                    updateErrorDict(errorDict => {
                        errorDict.DagComponentError = "Please Upload right Dag!";
                    });
                    return false;
                }
                if ("DagComponentError" in errorDict) {
                    delete errorDict.DagComponentError;
                }
                return true;
            case ComponentTypeEnum.DagConfiguration:
                const invalidDagConfigEntries = cleanedData.filter(option => !dagConfigurationDropDownList.map(item => item.value).includes(option));
                if (invalidDagConfigEntries.length > 0) {
                    updateErrorDict(errorDict => {
                        errorDict.DagConfigurationComponentError = "Please Upload right DagConfiguration!";
                    });
                    return false;
                }
                if ("DagConfigurationComponentError" in errorDict) {
                    delete errorDict.DagConfigurationComponentError;
                }
                return true;
            default:
                return false;
        }
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target?.result as string;
                const lines = text.split('\n');
                const cleanedData = lines.map(line => line.trim()).filter(line => line);
                event.target.value = '';//Reset the value of the file input control

                if (!checkUploadingItems(cleanedData, uploadComponentType)) {
                    return;
                }

                const multivalue: Component[] = cleanedData.map(option => ({
                    Type: uploadComponentType,
                    Value: option,
                }));
                switch (uploadComponentType) {
                    case ComponentTypeEnum.RestApp:
                        updateFormData(formData => {
                            formData.RestApp = multivalue;
                        });
                        break;
                    case ComponentTypeEnum.Process:
                        updateFormData(formData => {
                            formData.Process = multivalue;
                        });
                        break;
                    case ComponentTypeEnum.Shard:
                        updateFormData(formData => {
                            formData.Shard = multivalue;
                        });
                        break;
                    case ComponentTypeEnum.Forest:
                        updateFormData(formData => {
                            formData.Forest = multivalue;
                        });
                        break;
                    case ComponentTypeEnum.Sku:
                        updateFormData(formData => {
                            formData.Sku = multivalue;
                        });
                        break;
                    case ComponentTypeEnum.Dag:
                        updateFormData(formData => {
                            formData.Dag = multivalue;
                        });
                        break;
                    case ComponentTypeEnum.DagConfiguration:
                        updateFormData(formData => {
                            formData.DagConfiguration = multivalue;
                        });
                        break;
                    default:
                        break;
                }
            };
            reader.readAsText(file);
        }
    };

    //Check whether have component in the list
    const CheckSelect = (componentType: ComponentTypeEnum) => {
        switch (componentType) {
            case ComponentTypeEnum.RestApp:
                if (formData.RestApp.length) return true;
                break;
            case ComponentTypeEnum.Process:
                if (formData.Process.length) return true;
                break;
            case ComponentTypeEnum.Shard:
                if (formData.Shard.length) return true;
                break;
            case ComponentTypeEnum.Forest:
                if (formData.Forest.length) return true;
                break;
            case ComponentTypeEnum.Sku:
                if (formData.Sku.length) return true;
                break;
            case ComponentTypeEnum.Dag:
                if (formData.Dag.length) return true;
                break;
            case ComponentTypeEnum.DagConfiguration:
                if (formData.DagConfiguration.length) return true;
                break;
        }
        return false;
    };

    //Download Selected options button
    const handleDownload = (componentType: ComponentTypeEnum) => {
        let csvData = "";
        switch (componentType) {
            case ComponentTypeEnum.RestApp:
                csvData = formData.RestApp.map(item => item.Value).join('\n');
                break;
            case ComponentTypeEnum.Process:
                csvData = formData.Process.map(item => item.Value).join('\n');
                break;
            case ComponentTypeEnum.Shard:
                csvData = formData.Shard.map(item => item.Value).join('\n');
                break;
            case ComponentTypeEnum.Forest:
                csvData = formData.Forest.map(item => item.Value).join('\n');
                break;
            case ComponentTypeEnum.Sku:
                csvData = formData.Sku.map(item => item.Value).join('\n');
                break;
            case ComponentTypeEnum.Dag:
                csvData = formData.Dag.map(item => item.Value).join('\n');
                break;
            case ComponentTypeEnum.DagConfiguration:
                csvData = formData.DagConfiguration.map(item => item.Value).join('\n');
                break;
        }

        const blob = new Blob([csvData], { type: 'text/csv' });

        const url = URL.createObjectURL(blob);

        const downloadSelect = document.createElement('a');
        downloadSelect.href = url;
        downloadSelect.download = componentType.toString() + '-Selected.csv';
        document.body.appendChild(downloadSelect);
        downloadSelect.click();
        document.body.removeChild(downloadSelect);
        URL.revokeObjectURL(url);
    };

    //Download All options button
    const handleDownloadAll = (componentType: ComponentTypeEnum) => {
        let csvData = "";
        switch (componentType) {
            case ComponentTypeEnum.RestApp:
                csvData = restAppDropDownList.map(item => item.value).join('\n');
                break;
            case ComponentTypeEnum.Process:
                csvData = procDropDownList.map(item => item.value).join('\n');
                break;
            case ComponentTypeEnum.Shard:
                csvData = shardDropDownList.map(item => item.value).join('\n');
                break;
            case ComponentTypeEnum.Forest:
                csvData = forestDropDownList.map(item => item.value).join('\n');
                break;
            case ComponentTypeEnum.Sku:
                csvData = skuDropDownList.map(item => item.value).join('\n');
                break;
            case ComponentTypeEnum.Dag:
                csvData = dagDropDownList.map(item => item.value).join('\n');
                break;
            case ComponentTypeEnum.DagConfiguration:
                csvData = dagConfigurationDropDownList.map(item => item.value).join('\n');
                break;
        }

        const blob = new Blob([csvData], { type: 'text/csv' });

        const url = URL.createObjectURL(blob);

        const downloadAll = document.createElement('a');
        downloadAll.href = url;
        downloadAll.download = componentType.toString() + '-All.csv';
        document.body.appendChild(downloadAll);
        downloadAll.click();
        document.body.removeChild(downloadAll);
        URL.revokeObjectURL(url);
    };


    const switchResourceType = (resourceType: CopsResourceTypeEnum) => {
        updateFormData(formData => {
            formData.ResourceType = resourceType;
        });

        // Delete error
        updateErrorDict(errorDict => {
            if ("RestAppComponentError" in errorDict) {
                delete errorDict.RestAppComponentError;
            }
            if ("ProcComponentError" in errorDict) {
                delete errorDict.ProcComponentError;
            }
            if ("ShardComponentError" in errorDict) {
                delete errorDict.ShardComponentError;
            }
            if ("ForestComponentError" in errorDict) {
                delete errorDict.ForestComponentError;
            }
            if ("SkuComponentError" in errorDict) {
                delete errorDict.SkuComponentError;
            }
            if ("DagComponentError" in errorDict) {
                delete errorDict.DagComponentError;
            }
            if ("DagConfigurationComponentError" in errorDict) {
                delete errorDict.DagConfigurationComponentError;
            }
        });

        // clean up component
        switch (resourceType) {
            case CopsResourceTypeEnum.IO:
                setStorageCategory(EfficiencyTargetCategoryEnum.Demands);
                setTrackingGap(0);
                updateFormData(formData => {
                    formData.EfficiencyTargetCatagory = EfficiencyTargetCategoryEnum.Demands;
                    formData.TrackingGap = 0;
                    formData.Process = [];
                    formData.Shard = [];
                    formData.Forest = [];
                    formData.Sku = [];
                    formData.Dag = [];
                    formData.DagConfiguration = [];
                });
                break;
            case CopsResourceTypeEnum.CPU :
                setStorageCategory(EfficiencyTargetCategoryEnum.Demands);
                setTrackingGap(0);
                updateFormData(formData => {
                    formData.EfficiencyTargetCatagory = EfficiencyTargetCategoryEnum.Demands;
                    formData.TrackingGap = 0;
                    formData.Shard = [];
                    formData.Forest = [];
                    formData.Sku = [];
                    formData.Dag = [];
                    formData.DagConfiguration = [];
                });
                break;
            case CopsResourceTypeEnum.Memory:
                setStorageCategory(EfficiencyTargetCategoryEnum.Demands);
                setTrackingGap(0);
                updateFormData(formData => {
                    formData.EfficiencyTargetCatagory = EfficiencyTargetCategoryEnum.Demands;
                    formData.TrackingGap = 0;
                    formData.RestApp = [];
                    formData.Shard = [];
                    formData.Forest = [];
                    formData.Sku = [];
                    formData.Dag = [];
                    formData.DagConfiguration = [];
                });
                break;
            case CopsResourceTypeEnum.HDD:
                updateFormData(formData => {
                    formData.Process = [];
                    formData.RestApp = [];
                });
                break;
            case CopsResourceTypeEnum.SSD:
                updateFormData(formData => {
                    formData.Process = [];
                    formData.RestApp = [];
                });
                break;
            default:
                break;
        }
    };

    //Message Bar
    const renderMessageBar = (errorMessage: string) => {
        if (errorMessage in errorDict) {
            return (
                <MessageBar messageBarType={MessageBarType.error}>
                    {errorDict[errorMessage]}
                </MessageBar>
            );
        } else {
            return null;
        }
    };

    // Upload & download buttons
    const renderLoadButtons = (component: ComponentTypeEnum) => {
        if (!editMode) {
            return (
                <>
                    <DefaultButton
                        text="Upload"
                        iconProps={{ iconName: 'Upload' }}
                        className={styles.resourceButton}
                        onClick={() => handleUpload(component)}
                    />
                    <DefaultButton
                        text="Download Select"
                        iconProps={{ iconName: 'Download' }}
                        className={styles.resourceButton}
                        onClick={() => handleDownload(component)}
                        disabled={!CheckSelect(component)}
                    />
                    <DefaultButton
                        text="Download All"
                        iconProps={{ iconName: 'Download' }}
                        className={styles.resourceButton}
                        onClick={() => handleDownloadAll(component)}
                    />
                </>
            );
        } else {
            return (
                <>
                    <DefaultButton
                        text="Download Select"
                        iconProps={{ iconName: 'Download' }}
                        className={styles.resourceButton}
                        onClick={() => handleDownload(component)}
                        disabled={!CheckSelect(component)}
                    />
                </>
            );
        }
    };

    return (
        <Stack tokens={{ childrenGap: 24 }} styles={{ root: { width: 808 } }} >

            <div className={styles.formTitle}>{"New Plan: Select resources for " + formData.ProjectName}</div>

            <ChoiceGroup
                className={styles.formChoice}
                label="Resource type"
                selectedKey={formData.ResourceType}
                options={BaremetalResourceType}
                onChange={(_, newOption) => {
                    if (newOption) {
                        switchResourceType(newOption.key as CopsResourceTypeEnum);
                    }
                }}
                disabled={editMode}
                required
            />

            <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />

            {(formData.ResourceType === CopsResourceTypeEnum.IO || formData.ResourceType === CopsResourceTypeEnum.CPU)&& (<div>
                <Stack horizontal verticalAlign="center">
                    <Label styles={{ root: { paddingRight: 0 } }}>REST Application Names</Label>
                    <IconButton
                        iconProps={{ iconName: 'Info' }}
                        title="AKA scenario tag, your application tracking in store when call Rest APIs"
                        className={styles.ExplainIcon}
                    />
                    {renderLoadButtons(ComponentTypeEnum.RestApp)}
                </Stack>
                {
                    restAppDropDownList.length !== 0 || editMode ?  (
                        <AsyncPaginate
                            isClearable
                            isSearchable
                            isMulti
                            name="REST Application Names"
                            placeholder="Please provide your REST application name"
                            value={formData.RestApp.map(option => ({ value: option.Value, label: option.Value }))}
                            loadOptions={getLoadOptions(restAppDropDownList)}
                            closeMenuOnSelect={false}
                            onChange={(newValue: MultiValue<OptionType>) => handleComponentDropdownChange(newValue, ComponentTypeEnum.RestApp)}
                            styles={customAsyncPaginateStyles}
                            onFocus={() => {
                                updateErrorDict(errorDict => {
                                    if ("RestAppComponentError" in errorDict) {
                                        delete errorDict.RestAppComponentError;
                                    }
                                    if ("ProcComponentError" in errorDict) {
                                        delete errorDict.ProcComponentError;
                                    }
                                });
                            }}
                            aria-errormessage={("RestAppComponentError" in errorDict) ? errorDict.RestAppComponentError : ""}
                            isDisabled={editMode}
                        />
                    ) : (<Spinner label="Loading REST Application Names..." labelPosition="right" style={{ marginTop: 5 }} />)
                }
                {renderMessageBar("RestAppComponentError")}
            </div>)}

            {(formData.ResourceType === CopsResourceTypeEnum.CPU || formData.ResourceType === CopsResourceTypeEnum.Memory) && (<div>
                <Stack horizontal verticalAlign="center">
                    <Label styles={{ root: { paddingRight: 0 } }}>Process Names</Label>
                    <IconButton
                        iconProps={{ iconName: 'Info' }}
                        title="Process"
                        className={styles.ExplainIcon}
                    />
                    {renderLoadButtons(ComponentTypeEnum.Process)}
                </Stack>
                <AsyncPaginate
                    isClearable
                    isSearchable
                    isMulti
                    name="Process Names"
                    placeholder="Please provide your Process Names"
                    value={formData.Process.map(option => ({ value: option.Value, label: option.Value }))}
                    loadOptions={getLoadOptions(procDropDownList)}
                    closeMenuOnSelect={false}
                    onChange={(newValue: MultiValue<OptionType>) => handleComponentDropdownChange(newValue, ComponentTypeEnum.Process)}
                    styles={customAsyncPaginateStyles}
                    onFocus={() => {
                        updateErrorDict(errorDict => {
                            if ("RestAppComponentError" in errorDict) {
                                delete errorDict.RestAppComponentError;
                            }
                            if ("ProcComponentError" in errorDict) {
                                delete errorDict.ProcComponentError;
                            }
                        });
                    }}
                    aria-errormessage={("ProcComponentError" in errorDict) ? errorDict.ProcComponentError : ""}
                    isDisabled={editMode}
                />
                {renderMessageBar("ProcComponentError")}
            </div>)}

            {(formData.ResourceType === CopsResourceTypeEnum.HDD || formData.ResourceType === CopsResourceTypeEnum.SSD) && (<>
                <div>
                    <Stack horizontal verticalAlign="center">
                        <Label styles={{ root: { paddingRight: 0 } }} required>Storage Efficiency Target Category</Label>
                        <IconButton
                            iconProps={{ iconName: 'Info' }}
                            title="Choose Capacity to restrict growth rate, choose Demand to optimize based on the current value."
                            className={styles.ExplainIcon}
                        />
                        <input
                            type="radio"
                            id="Capacity Bytes"
                            name="StorageCategoryOptions"
                            value={EfficiencyTargetCategoryEnum.Capacity}
                            checked={storageCategory === EfficiencyTargetCategoryEnum.Capacity}
                            onChange={handleStorageCategoryChange}
                            disabled={editMode}
                        />
                        <label htmlFor="Capacity Bytes">Capacity Bytes</label>
                        <input
                            type="radio"
                            id="Demand Bytes"
                            name="StorageCategoryOptions"
                            value={EfficiencyTargetCategoryEnum.Demands}
                            checked={storageCategory === EfficiencyTargetCategoryEnum.Demands}
                            onChange={handleStorageCategoryChange}
                            disabled={editMode}
                        />
                        <label htmlFor="Demand Bytes">Demand Bytes</label>
                    </Stack>

                    {storageCategory === EfficiencyTargetCategoryEnum.Capacity && (
                        <Stack horizontal verticalAlign="center">
                            <Label styles={{ root: { paddingRight: 0 } }} required>Track Gap</Label>
                            <IconButton
                                iconProps={{ iconName: 'Info' }}
                                title="Choose Yes to set baseline to 0. This option can be changed after creation."
                                className={styles.ExplainIcon}
                            />
                            <input
                                type="radio"
                                id="TrackingGap Yes"
                                name="TrackingGapOptions"
                                value={"Yes"}
                                checked={trackingGap > 0}
                                onChange={handleTrackingGapChange}
                            />
                            <label htmlFor="TrackingGap Yes">Yes</label>
                            <input
                                type="radio"
                                id="TrackingGap No"
                                name="TrackingGapOptions"
                                value={"No"}
                                checked={trackingGap === 0}
                                onChange={handleTrackingGapChange}
                            />
                            <label htmlFor="TrackingGap No">No</label>
                        </Stack>
                    )}
                </div>

                <div>
                    <Stack horizontal verticalAlign="center">
                        <Label styles={{ root: { paddingRight: 0 } }} required>Shards</Label>
                        <IconButton
                            iconProps={{ iconName: 'Info' }}
                            title="Storage space allocated by class of shards, see details Click."
                            className={styles.ExplainIcon}
                            onClick={() => {
                                window.open("https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/85/Schema", "_blank", "noopener noreferrer");
                            }}
                        />
                        {renderLoadButtons(ComponentTypeEnum.Shard)}
                    </Stack>
                    <AsyncPaginate
                        isClearable
                        isSearchable
                        isMulti
                        name="Shards"
                        placeholder="Please select Shard Names from the list"
                        value={formData.Shard.map(option => ({ value: option.Value, label: option.Value }))}
                        loadOptions={getLoadOptions(shardDropDownList)}
                        closeMenuOnSelect={false}
                        onChange={(newValue: MultiValue<OptionType>) => handleComponentDropdownChange(newValue, ComponentTypeEnum.Shard)}
                        styles={customAsyncPaginateStyles}
                        onFocus={() => {
                            updateErrorDict(errorDict => {
                                if ("ShardComponentError" in errorDict) {
                                    delete errorDict.ShardComponentError;
                                }
                            });
                        }}
                        aria-errormessage={("ShardComponentError" in errorDict) ? errorDict.ShardComponentError : ""}
                        required
                        isDisabled={editMode}
                    />
                    {renderMessageBar("ShardComponentError")}

                    <Stack horizontal verticalAlign="center">
                        <Label styles={{ root: { paddingRight: 0 } }}>Forest</Label>
                        <IconButton
                            iconProps={{ iconName: 'Info' }}
                            title="Deployed Forest"
                            className={styles.ExplainIcon}
                        />
                        {renderLoadButtons(ComponentTypeEnum.Forest)}
                    </Stack>
                    <AsyncPaginate
                        isClearable
                        isSearchable
                        isMulti
                        name="Forest"
                        placeholder="Please select Forest Names from the list"
                        value={formData.Forest.map(option => ({ value: option.Value, label: option.Value }))}
                        loadOptions={getLoadOptions(forestDropDownList)}
                        closeMenuOnSelect={false}
                        onChange={(newValue: MultiValue<OptionType>) => handleComponentDropdownChange(newValue, ComponentTypeEnum.Forest)}
                        styles={customAsyncPaginateStyles}
                        onFocus={() => {
                            updateErrorDict(errorDict => {
                                if ("ForestComponentError" in errorDict) {
                                    delete errorDict.ForestComponentError;
                                }
                            });
                        }}
                        aria-errormessage={("ForestComponentError" in errorDict) ? errorDict.ForestComponentError : ""}
                        isDisabled={editMode}
                    />
                    {renderMessageBar("ForestComponentError")}

                    <Stack horizontal verticalAlign="center">
                        <Label styles={{ root: { paddingRight: 0 } }}>Dag Name</Label>
                        <IconButton
                            iconProps={{ iconName: 'Info' }}
                            title="The DAG Name"
                            className={styles.ExplainIcon}
                        />
                        {renderLoadButtons(ComponentTypeEnum.Dag)}
                    </Stack>
                    <AsyncPaginate
                        isClearable
                        isSearchable
                        isMulti
                        name="Dag"
                        placeholder="Please select Dag Names from the list"
                        value={formData.Dag.map(option => ({ value: option.Value, label: option.Value }))}
                        loadOptions={getLoadOptions(dagDropDownList)}
                        closeMenuOnSelect={false}
                        onChange={(newValue: MultiValue<OptionType>) => handleComponentDropdownChange(newValue, ComponentTypeEnum.Dag)}
                        styles={customAsyncPaginateStyles}
                        onFocus={() => {
                            updateErrorDict(errorDict => {
                                if ("DagComponentError" in errorDict) {
                                    delete errorDict.DagComponentError;
                                }
                            });
                        }}
                        aria-errormessage={("DagComponentError" in errorDict) ? errorDict.DagComponentError : ""}
                        isDisabled={editMode}
                    />
                    {renderMessageBar("DagComponentError")}

                    <Stack horizontal verticalAlign="center">
                        <Label styles={{ root: { paddingRight: 0 } }}>Dag Configuration Name</Label>
                        <IconButton
                            iconProps={{ iconName: 'Info' }}
                            title="The DAG Configuration"
                            className={styles.ExplainIcon}
                        />
                        {renderLoadButtons(ComponentTypeEnum.DagConfiguration)}
                    </Stack>
                    <AsyncPaginate
                        isClearable
                        isSearchable
                        isMulti
                        name="Dag Configuration"
                        placeholder="Please select Dag Configuration from the list"
                        value={formData.DagConfiguration.map(option => ({ value: option.Value, label: option.Value }))}
                        loadOptions={getLoadOptions(dagConfigurationDropDownList)}
                        closeMenuOnSelect={false}
                        onChange={(newValue: MultiValue<OptionType>) => handleComponentDropdownChange(newValue, ComponentTypeEnum.DagConfiguration)}
                        styles={customAsyncPaginateStyles}
                        onFocus={() => {
                            updateErrorDict(errorDict => {
                                if ("DagConfigurationComponentError" in errorDict) {
                                    delete errorDict.DagConfigurationComponentError;
                                }
                            });
                        }}
                        aria-errormessage={("DagConfigurationComponentError" in errorDict) ? errorDict.DagConfigurationComponentError : ""}
                        isDisabled={editMode}
                    />
                    {renderMessageBar("DagConfigurationComponentError")}

                    <Stack horizontal verticalAlign="center">
                        <Label styles={{ root: { paddingRight: 0 } }}>SKU</Label>
                        <IconButton
                            iconProps={{ iconName: 'Info' }}
                            title="The Machine's SKU"
                            className={styles.ExplainIcon}
                        />
                        {renderLoadButtons(ComponentTypeEnum.Sku)}
                    </Stack>
                    <AsyncPaginate
                        isClearable
                        isSearchable
                        isMulti
                        name="Sku"
                        placeholder="Please select SKU from the list"
                        value={formData.Sku.map(option => ({ value: option.Value, label: option.Value }))}
                        loadOptions={getLoadOptions(skuDropDownList)}
                        closeMenuOnSelect={false}
                        onChange={(newValue: MultiValue<OptionType>) => handleComponentDropdownChange(newValue, ComponentTypeEnum.Sku)}
                        styles={customAsyncPaginateStyles}
                        onFocus={() => {
                            updateErrorDict(errorDict => {
                                if ("SkuComponentError" in errorDict) {
                                    delete errorDict.SkuComponentError;
                                }
                            });
                        }}
                        aria-errormessage={("SkuComponentError" in errorDict) ? errorDict.SkuComponentError : ""}
                        isDisabled={editMode}
                    />
                    {renderMessageBar("SkuComponentError")}
                </div>
            </>)}
        </Stack>

    );
}

export default ResourceInfoBaremetal;