import { Icon } from "@fluentui/react";
import React from "react";
import styles from "./EmptyModule.less";

export interface IEmptyModuleProps {
    iconName?: string;
}

export const EmptyModule: React.FC<IEmptyModuleProps> = (props) => {
    return (
        <div className={styles.emptyModule}>
            <Icon iconName={props.iconName} />
            <div className={styles.emptyModuleTitle}>No Data</div>
            <div className={styles.emptyModuleSubTitle}>The selected service has no data in the module</div>
        </div>
    );
};
