import React from "react";
import styles from "./DataTable.less";
import { TableInstance } from "react-table";
import { ESCostDataDimensions } from "../../../../models/ESCostModels";
import { getHeaderOption, getFooterOption } from "../../../azure/detail/common/DetailTableUtils";
import { SectionHeader } from "../../../common/SectionHeader";
import StickyTable from "../../../common/table/StickyTable";

export const MonthBeforeLastAccessor = "monthBeforeLast";
export const LastMonthAccessor = "lastMonth";
export const RelativeChangeAccessor = "delta";

export interface ITableRow {
    [ESCostDataDimensions.Repository]?: string;
    [ESCostDataDimensions.Service]?: string;
    [ESCostDataDimensions.UserName]?: string;
    [MonthBeforeLastAccessor]: number;
    [LastMonthAccessor]: number;
    [RelativeChangeAccessor]: string;
}

interface ISimpleTableProps {
    tableInstance: TableInstance<ITableRow>;
    description: string;
    maxCount?: number;
}

const DataTable: React.FC<ISimpleTableProps> = (props) => {
    return (
        <div className={styles.tableView}>
            <SectionHeader className={styles.sectionHeader} title={props.description} />
            <div className={styles.tableContainer}>
                <StickyTable
                    styles={{
                        bodyContainer: styles.tableBodyContainer,
                        footerContainer: styles.tableSectionContainer,
                    }}
                    headerOption={getHeaderOption}
                    footerOption={getFooterOption}
                    table={props.tableInstance}
                    loadMore={false}
                    footerVisible={false}
                />
            </div>
        </div>
    );
};

export default DataTable;
