import { ActionButton, Stack } from '@fluentui/react';
import { AzureComputeDetailTableOffset, AzureComputeDetailTableStyles } from './common/DetailTableConstants';
import { AzureCostTableTag, IAzureComputeSubscriptionDetail } from '../../../models/AzureComputeModels';
import { AzureMycroftSubscriptionDetailsColumnNames, AzureSubscriptionDetailsColumnNames, AzureSubscriptionDetailsColumnNamesV3 } from '../common/AzureComputeConstants';
import React, { useCallback, useEffect } from 'react';
import { getCellOption, getFooterOption, getHeaderOption, useAzureComputeHeatmapParams, useAzureComputeHeatmapQuery, useDetailTable, useDetailTableColumnSelection } from './common/DetailTableUtils';
import { useAzureSubscriptionSearchInfo, useGetAzureComputeSubscriptionDetail, useGetAzureComputeSubscriptionSummary } from '../../../hooks/useAzureQuery';

import { ColumnSelector } from '../../common/table/ColumnSelector';
import { FilterList } from "../../filter/filterList/FilterList";
import { HeatmapIndicator } from './HeatmapIndicator';
import { IAppState } from '../../../store';
import { SectionHeader } from '../../common/SectionHeader';
import { ServiceTreeItem } from '../../../models/serviceTree';
import StickyTable from '../../common/table/StickyTable';
import commonStyles from './common/DetailTable.less';
import { difference } from 'lodash';
import { downloadAzureComputeSubscriptionHeatmap } from '../../../services/azureComputeService';
import { useBillingCost } from '../../../hooks/useBillingCost';
import { useBoolean } from "@fluentui/react-hooks";
import { useCategoryFilters } from '../../../hooks/useFilters';
import { useDateRange } from '../../../hooks/useDateSelector';
import { useFlights } from '../../../hooks/useSettings';
import { useRemap } from '../../../hooks/useRemap';
import { useSelector } from 'react-redux';
import { useUserPreference } from '../../../utils/preference/useUserPreference';
import { useSpotVM } from '../../../hooks/useSpotVM';

interface IAzureComputeSubscriptionDetailProps {
    type: 'Total' | 'Public' | 'AZSC';
    regions: string[];
}

const SubscriptionDetail: React.FC<IAzureComputeSubscriptionDetailProps> = ({ type, regions }) => {
    const enableMycroftData = useFlights().data?.enableMycroftData;
    const enableAzureV3 = useFlights().data?.enableV3;
    const additionalFilters = enableAzureV3 ? 
        ['subscriptionName', 'serviceName'] :
        ['subscriptionName', 'subscriptionId', 'serviceName', 'deploymentId', 'region', 'clusterId', 'role', 'cloudService', 'sku', 'environment', 'costCategory', 'machineFunction', 'perfCollector', 'vmScaleSetName', 'operatingSystem', 'serviceDevOwners', 'servicePMOwners'];
    
    const enableBillingCost = useFlights().data?.enableBillingCost;

    const columns = React.useMemo(() => {
        const columns = enableAzureV3 ? AzureSubscriptionDetailsColumnNamesV3 :
        (enableMycroftData ? AzureMycroftSubscriptionDetailsColumnNames : AzureSubscriptionDetailsColumnNames);
        if (!enableBillingCost) {
            return columns.filter(x => x !== 'billingCost');
        } else {
            return columns;
        }
    }, [enableBillingCost, enableMycroftData]);
    const heatmapParams = useAzureComputeHeatmapParams(type, columns, regions, "TotalCores", additionalFilters);
    const summaryQuery = useGetAzureComputeSubscriptionSummary(type, heatmapParams.filtersData, regions);
    const query = useAzureComputeHeatmapQuery(useGetAzureComputeSubscriptionDetail, type, heatmapParams, regions, summaryQuery.data);
    const {singleDate: date, startDate, endDate} = useDateRange();
    const [showRemap] = useRemap();   
    const [showSpotVM] = useSpotVM();   
    const searchFilters = useCategoryFilters().filters.filters;
    const subscriptions = useAzureSubscriptionSearchInfo();
    const [isDownloadingEnabled, {setFalse: disableDownloading, setTrue: enableDownloading}] = useBoolean(true);

    const getRowId = useCallback((data: IAzureComputeSubscriptionDetail) => {
        return data.id?.toString() || `${data.serviceId}_${data.subscriptionId}_${data.region}_${data.azsc}_${data.clusterId}_${data.sku}__${data.isSpotVM}_${data.isMock}_${data.isSovereign}`;
    }, []);

    const [preferedTableConfig, setPreferedTableConfig] = useUserPreference("azureComputeSubscriptionDetail");

    const tableInstance = useDetailTable<IAzureComputeSubscriptionDetail>(
        type, AzureCostTableTag.Subscription, columns, query.data, query.sort.setSort, query.sort.initialSortDesc, query.sort.initialSortKey || "totalCores", preferedTableConfig?.hiddenColumns || [], getRowId
    );

    const { visibleColumns, changeVisibleColumns, columnSelectorOptions } = useDetailTableColumnSelection(tableInstance);

    useEffect(() => {
        setPreferedTableConfig({ hiddenColumns: difference(columns, visibleColumns) as (keyof IAzureComputeSubscriptionDetail)[] })
    }, [visibleColumns]);
    const { data: flights } = useFlights();

    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);
    const serviceNameMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.serviceNodeMapByName);

    const handleDownload = useCallback(() => {
        disableDownloading();
        downloadAzureComputeSubscriptionHeatmap(date, heatmapParams.filtersData, searchFilters, serviceIdMap, serviceNameMap, subscriptions, heatmapParams.sortByKey || "TotalCores", heatmapParams.sortDesc, type, regions, columns, startDate, endDate, showRemap, showSpotVM, flights?.enableDateRange, enableDownloading);
    }, [disableDownloading, date, heatmapParams.filtersData, heatmapParams.sortByKey, heatmapParams.sortDesc, searchFilters, serviceIdMap, serviceNameMap, subscriptions, type, regions, columns, startDate, endDate, showRemap, showSpotVM, flights?.enableDateRange, enableDownloading]);

    return (
        <div className={commonStyles.detailTableView}>
            <SectionHeader
                title="Subscription Details"
                extra={(
                    <Stack horizontal verticalAlign="center" horizontalAlign="end" className={commonStyles.headerExtra}>
                        <HeatmapIndicator />
                        <ActionButton onClick={handleDownload} iconProps={{ iconName: 'Download' }} text={isDownloadingEnabled ? "Download" : "Downloading"} disabled={!isDownloadingEnabled} />
                        <ColumnSelector onConfirm={changeVisibleColumns} columns={columnSelectorOptions} initSelectedKeys={visibleColumns} />
                    </Stack>
                )}
            />
            <FilterList data={query.localFilters.data} options={query.localFilters.options} onChange={query.localFilters.setFilters} />
            <StickyTable
                styles={AzureComputeDetailTableStyles}
                cellOption={getCellOption}
                headerOption={getHeaderOption}
                footerOption={getFooterOption}
                loading={query.isInitialLoading}
                loadMoreText="Show more subscriptions"
                emptyText="The selected services don't have data"
                loadMoreLoadingText={`Loading more ${query.paging.pageSize} subscriptions...`}
                loadMoreLoading={query.isLoadingMore}
                loadMore={query.hasMore}
                onLoadMore={query.paging.loadMore}
                table={tableInstance}
                stickyTarget="#pageContainer"
                stickyPositon={AzureComputeDetailTableOffset}
            />
        </div>
    );
};

export default SubscriptionDetail;
