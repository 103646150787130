import { ActionTypes } from "../actions/ActionTypes";
import { IToggleSubViewPanelAction } from "../actions/SubViewPanelActions";
import { Reducer } from "redux";

export interface ISubViewPanelData {
    isOpen: boolean;
    viewId: number;
}

const initialState: ISubViewPanelData = {
    isOpen: false,
    viewId: -1
};

export const subviewPanelReducer: Reducer<ISubViewPanelData, IToggleSubViewPanelAction> = function (state = initialState, action): ISubViewPanelData {
    switch (action.type) {
        case ActionTypes.ToggleSubViewPanelAction:
            if (state.isOpen != action.open) return { ...state, isOpen: action.open, viewId: action.viewId};
            break;
    }

    return state;
}

export function makeTogglePanelAction(open: boolean, id: number): IToggleSubViewPanelAction {
    return {
        type: ActionTypes.ToggleSubViewPanelAction,
        open,
        viewId: id,
    };
}