import { ITelemetryResourceCost, TelemetryLogComponent, TelemetrySourceTypeEnum, TelemetrySourceTypeName } from "../../../models/TelemetryCogsModel";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";

import ChartContainer from "../../chart/ChartContainer";
import CommonConstants from "../../../models/constants/CommonConstants";
import { CostCardProps } from "../../common/costCard/CostCard";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { LineChartContainer } from "../../azure/common/LineChartContainer";
import { LineChartSeries } from "../../common/LineChart";
import React from "react";
import { currencyFormatter } from "../../../utils/currency";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useGetTelemetryResourceCost } from "../../../hooks/useTelemetryCost";
import { useTrackHovering } from "../../../hooks/useTrack";

interface ITelemetryTrendChartProps {
    title: string;
    sources: TelemetrySourceTypeEnum[];
}

const formatData = (value: number) => {
    return currencyFormatter(value, 2, "$");
};

const formatAxisData = (value: number) => {
    return currencyFormatter(value, 2, "$");
};

function getTelemetryCost(source: TelemetrySourceTypeEnum, telemetryCost: ITelemetryResourceCost) {
    switch (source) {
        case TelemetrySourceTypeEnum.Cosmos:
            return telemetryCost.cosmosCost;
        case TelemetrySourceTypeEnum.Blueshift:
            return telemetryCost.blueShiftCost;
        case TelemetrySourceTypeEnum.Kusto:
            return telemetryCost.kustoCost;
        case TelemetrySourceTypeEnum.GenevaMds:
            return telemetryCost.genevaMdsCost;
        case TelemetrySourceTypeEnum.GenevaMdm:
            return telemetryCost.genevaMdmCost;
        case TelemetrySourceTypeEnum.Aria:
            return telemetryCost.ariaCost;
        case TelemetrySourceTypeEnum.PassiveMon:
            return telemetryCost.passiveMonCost;
        case TelemetrySourceTypeEnum.All:
            return telemetryCost.cosmosCost + telemetryCost.blueShiftCost + telemetryCost.kustoCost + telemetryCost.genevaMdsCost + telemetryCost.genevaMdmCost + telemetryCost.ariaCost;
        default:
            return 0;
    }
}

const TelemetryTrendChart: React.FC<ITelemetryTrendChartProps> = ({ sources, title }) => {
    const query = useGetTelemetryResourceCost();

    const telemetryAnomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.Telemetry);
    const activeTelemetryAnomalies = React.useMemo(
        () => getActiveAnomalies(telemetryAnomaliesQuery.data, ["Cosmos", "Blueshift", "Kusto", "GenevaMds", "GenevaMdm", "Aria", "PassiveMon"]),
        [telemetryAnomaliesQuery.data]
    );

    const cards: CostCardProps[] = sources.map((source, index) => ({
        title: TelemetrySourceTypeName[source] === 'Telemetry' ? 'Total Cost' : `${TelemetrySourceTypeName[source]} Cost`,
        cost: (query.data && query.data.reduce((sum, val) => sum + getTelemetryCost(source, val) || 0, 0)) || 0,
        color: CommonConstants.DefaultColors[index],
        formatter: (value: number) => formatData(value),
    }));

    const series: LineChartSeries[] = sources.map((source, index) => ({
        color: CommonConstants.DefaultColors[index],
        name: TelemetrySourceTypeName[source] === 'Telemetry' ? 'Total' : `${TelemetrySourceTypeName[source]}`,
        data: query.data && query.data.map((singleMetric) => [singleMetric.timeStamp.valueOf(), getTelemetryCost(source, singleMetric)]),
        anomalies: TelemetrySourceTypeName[source] === 'Telemetry' ? activeTelemetryAnomalies : 
        (TelemetrySourceTypeName[source] === 'PassiveMon' ? activeTelemetryAnomalies.filter(item => item.dataSourceName === TelemetrySourceTypeName[TelemetrySourceTypeEnum.Kusto]  || item.dataSourceName ===  TelemetrySourceTypeName[TelemetrySourceTypeEnum.GenevaMds]) : 
        activeTelemetryAnomalies.filter(item => item.dataSourceName ===  TelemetrySourceTypeName[source]))
    }));

    const trackChartHovering = useTrackHovering(LogComponent.TelemetryView, LogElement.TelemetryTrendsChart, title, LogTarget.Chart, {
        dataSources: sources.map((item) => TelemetrySourceTypeName[item]).join(","),
    });

    return (
        <LineChartContainer
            title={ title }
            isLoading={query.isLoading}
            cardProps={cards}
            series = {series}
            //height = {220}
            //tooltipYFormatter=(value: number) => formatData(value)
            //yFormatter=(value: number) => formatAxisData(value)
            containerProps={trackChartHovering}
            fillMissingAbnormalData
            anomalies={activeTelemetryAnomalies}
            logProps={{
                logComponent: TelemetryLogComponent[sources.length > 1 ? TelemetrySourceTypeEnum.All : sources[0]],
                logElement: LogElement.TelemetryTrendsChart,
            }}
        />
    );
};

export default TelemetryTrendChart;
