import * as React from "react";

import { CategoryDivision, CategoryDivisionDisplayName } from "../../models/CategoryDivision";
import { CellContext, ColumnDef, ColumnDefBase, ExpandedState, Row, RowData, SortingState, flexRender, getCoreRowModel, getExpandedRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { DefaultButton, FontIcon, IconButton, PrimaryButton, SpinButton, Spinner, Stack, TextField, Toggle } from "@fluentui/react";
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from "@mui/material";
import { clearEmptyBudgets, getBudgetFiscalYear, getBudgetId, getDefaultBlendedRate, getLastFiscalYear, recalculateServiceBudget, suppleBudgets } from "../../utils/BudgetUtils";
import { flatten, has, isEmpty, orderBy, sortBy, sum, toNumber, uniq } from "lodash";
import { useGetServiceLevelBudget, useInitializeNewFinOpsBudgets, useSignOffFromGM } from "../../hooks/useFinOpsBudget";

import { BudgetLayout } from "./BudgetLayout";
import { Endpoints } from "../../utils/Constants";
import { FinOpsReport } from "./FinOpsReport";
import { IAppState } from "../../store";
import { IFinOpsBudget } from "../../models/FinOpsBudgets";
import { IServiceTreeData } from "../../reducers/serviceTreeReducer";
import { TooltipHost } from "@fluentui/react";
import { WarningBanner } from '../banner/WarningBanner';
import WorkloadFilter from "../common/FiltersBanner/WorkloadFilter/WorkloadFilter";
import { currencyFormatter } from "../../utils/currency";
import moment from "moment";
import styles from "./Budget.less";
import { useCategoryFilters } from "../../hooks/useFilters";
import { useFlights } from "../../hooks/useSettings";
import { useGetBannersByTabAndDateQuery } from '../../hooks/useBannerQuery';
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";

declare module '@tanstack/react-table' {
    interface TableMeta<TData extends RowData> {
      updateData: (rowId: string, columns: Record<string, unknown>) => void
    }

    interface ColorKeyColumnBase<TData extends RowData, TValue = unknown> extends ColumnDefBase<TData, TValue> {
        headerStyles: React.CSSProperties;
    }
}
  
const numberCellFormatter = (cell: CellContext<IFinOpsBudget, number | string | undefined>) => {
    if (cell.row.depth === 0) {
        if (cell.column.id === 'increaseRate') return cell.getValue();
        if (cell.column.id === 'costBudget') return currencyFormatter(toNumber(cell.getValue()));
        if (cell.column.id === 'quantityBudget') return "";
    }
    if (cell.column.id === 'quantityBudget' && !cell.row.original.lastYearQuantity && !getDefaultBlendedRate(cell.row.original)) {
        return undefined;
    }
    if (cell.column.id === 'increaseRate' && !cell.row.original.lastYearCost) {
        return undefined;
    }
    const updateTable = (rowId: string, columnId: string, value: number) => {
        let newIncreaseRate = 0;
        let newQuantityBudget = undefined;
        let newCostBudget = 0;
        if (columnId === 'increaseRate') {
            newIncreaseRate = value;
            newQuantityBudget = cell.row.original.lastYearQuantity && cell.row.original.lastYearQuantity * (100 + value) / 100;
            newCostBudget = cell.row.original.lastYearCost * (100 + value) / 100;
        } else if (columnId === 'quantityBudget') {
            newIncreaseRate = cell.row.original.lastYearQuantity ? (value - cell.row.original.lastYearQuantity) / cell.row.original.lastYearQuantity * 100 : 0;
            newQuantityBudget = value;
            newCostBudget = cell.row.original.lastYearQuantity ?
                (newIncreaseRate + 100) * cell.row.original.lastYearCost / 100 :
                getDefaultBlendedRate(cell.row.original) ? newQuantityBudget * getDefaultBlendedRate(cell.row.original) :
                cell.row.original.costBudget;
        } else if (columnId === 'costBudget') {
            newIncreaseRate = cell.row.original.lastYearCost ? (value - cell.row.original.lastYearCost) / cell.row.original.lastYearCost * 100 : 0;
            newCostBudget = value;
            newQuantityBudget = cell.row.original.lastYearCost ?
                cell.row.original.lastYearQuantity && cell.row.original.lastYearQuantity * (100 + newIncreaseRate) / 100 :
                getDefaultBlendedRate(cell.row.original) ? newCostBudget / getDefaultBlendedRate(cell.row.original) :
                cell.row.original.quantityBudget;
        } else {
            return;
        }
        cell.table.options.meta?.updateData(rowId, {
            increaseRate: newIncreaseRate.toFixed(2),
            quantityBudget: newQuantityBudget,
            costBudget: newCostBudget
        });
    }
    const initialValue = cell.getValue();
    // We need to keep and update the state of the cell normally
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = React.useState<string | number | undefined>(Math.round(toNumber(initialValue || 0)));
    // If the initialValue is changed external, sync it up with our state
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    const justification = cell.row.original.justification;

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ width: '20px', textAlign: 'center' }}>
                {
                    cell.column.id === 'increaseRate' && toNumber(value) > 4 && !justification &&
                    <TooltipHost content={<span style={{ color: 'red' }}>Please provide justifications for growth rate over 4%.</span>}>
                        <FontIcon iconName="Warning" className={styles.warningIcon} />
                    </TooltipHost>
                }
            </div>
            <SpinButton
                styles={{ spinButtonWrapper: cell.column.id === 'costBudget' ? styles.costSpinButton : undefined, root: { width: 'calc(100% - 20px)' } }}
                value={formatNumberCell(cell.column.id, value).toString()}
                step={cell.column.id === 'increaseRate' ? 0.1 : 100}
                onIncrement={(value, _e) => {
                    const numericValue = cell.column.id === 'increaseRate' ? toNumber(value) : Math.round(toNumber(value.replace(/,/g, '')));
                    const newValue = cell.column.id === 'increaseRate' ? numericValue + 0.1 : numericValue + 100;
                    updateTable(cell.row.id, cell.column.id, newValue);
                    return cell.column.id === 'increaseRate' ? newValue.toString() : newValue.toLocaleString();
                }}
                onDecrement={(value, _e) => {
                    const numericValue = cell.column.id === 'increaseRate' ? toNumber(value) : Math.round(toNumber(value.replace(/,/g, '')));
                    const newValue = cell.column.id === 'increaseRate' ? numericValue - 0.1 : numericValue - 100;
                    updateTable(cell.row.id, cell.column.id, newValue);
                    return cell.column.id === 'increaseRate' ? newValue.toString() : newValue.toLocaleString();
                }}
                onChange={(_e, value) => value && updateTable(cell.row.id, cell.column.id, cell.column.id === 'increaseRate' ? toNumber(value) : Math.round(toNumber(value.replace(/,/g, ''))))}
            />
        </div>
    );
};

const textCellFormatter = (cell: CellContext<IFinOpsBudget, number | string | undefined>) => {
    if (cell.row.depth === 0) return cell.getValue();
    const updateTable = (rowId: string, columnId: string, value: string) => {
        cell.table.options.meta?.updateData(rowId, {
            [columnId]: value
        });
    }
    const initialValue = cell.getValue();
    // We need to keep and update the state of the cell normally
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = React.useState(initialValue);
    // If the initialValue is changed external, sync it up with our state
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    return (
        <TooltipHost content="Please provide a detailed justification for the budget. For example: 'Increase due to security wave', 'Additional resources needed for new project', etc.">
            <TextField
                multiline
                value={(value || '').toString()}
                onChange={(_e, value) => updateTable(cell.row.id, cell.column.id, value || '')}
            />
        </TooltipHost>
    );
};

const typeUnitToolTips: { [key: string]: { [key: string]: string } } = {
    "Public Compute VMs": {
        "Cores": "Virtual machines in the public cloud, measured by the number of CPU cores allocated."
    },
    "Cosmic": {
        "Cores": "Compute resources in the Cosmic Container environment, measured by the number of vCPU cores allocated."
    },
    "Legacy GPU": {
        "Cores": "Older generation GPU resources, measured by the number of GPU cores allocated."
    },
    "Transactions": {
        "10K": "Number of storage transactions, measured in units of 10,000 transactions."
    },
    "Storage - Other": {
        "GB": "General storage usage, measured in gigabytes."
    },
    "Managed SSD Disks": {
        "GB": "Managed solid-state drive storage, measured in gigabytes."
    },
    "Managed HDD Disks": {
        "GB": "Managed hard disk drive storage, measured in gigabytes."
    },
    "Bandwidth": {
        "GB": "Data transfer for Geo-Replication bandwidth, measured in gigabytes."
    },
    "Other": {
        "": "Other storage-related metrics or categories that use different units of measure measurement not GBs."
    },
    "LongHaul T2": {
        "Mbps": "Long-haul MCIO network bandwidth for T2 connections, measured in megabits per second."
    },
    "LongHaul T0": {
        "Mbps": "Long-haul MCIO network bandwidth for T0 connections, measured in megabits per second."
    },
    "Azure Bandwidth": {
        "GB": "Bandwidth usage for Azure services, measured in gigabytes."
    },
    "Egress": {
        "Mbps": "Data transfer out of the MCIO network, measured in megabits per second."
    },
    "Synapse Analytics": {
        "vCore": "Virtual cores used in Synapse Analytics, measured in vCores.",
        "": "General monitoring metrics for Synapse Analytics.",
        "GB": "Data stored in Synapse Analytics, measured in gigabytes.",
        "DWUs": "Data Warehouse Units used in Synapse Analytics, measuring compute resources."
    },
    "Azure Monitor": {
        "": "General monitoring metrics for Azure Monitor.",
        "1 GB": "Data collected by Azure Monitor, measured in gigabytes.",
        "10M": "Number of monitoring events collected by Azure Monitor, measured in units of 10 million.",
        "1GB/Day": "Daily data collected by Azure Monitor, measured in gigabytes per day.",
        "1M": "Number of monitoring events collected by Azure Monitor, measured in units of 1 million."
    },
    "Azure Log Analytics": {
        "GB": "Log data collected by Azure Log Analytics, measured in gigabytes.",
        "": "General monitoring metrics for Azure Log Analytics."
    },
    "SQL": {
        "": "General monitoring metrics for SQL databases."
    },
    "Redis": {
        "": "General monitoring metrics for Redis databases."
    },
    "Cosmos DB": {
        "RU/s": "Request units per second for Cosmos DB, measuring throughput.",
        "GB": "Data stored in Cosmos DB, measured in gigabytes.",
        "": "General monitoring metrics for Cosmos DB."
    },
    "Azure Front Door": {
        "": "General monitoring metrics for Azure Front Door.",
        "GB": "Data transferred through Azure Front Door, measured in gigabytes.",
        "10K": "Number of requests handled by Azure Front Door, measured in units of 10,000 requests."
    },
    "ADF": {
        "": "General metrics for Azure Data Factory operations."
    },
    "Kusto": {
        "GB": "Data stored in Kusto, measured in gigabytes.",
        "CPU": "CPU usage for Kusto operations."
    },
    "GenevaMdsIndirect": {
        "GB": "Indirect bill for data stored in GenevaMds, measured in gigabytes of volume per service."
    },
    "GenevaMds": {
        "GB": "Data stored in GenevaMds, measured in gigabytes."
    },
    "GenevaMdm": {
        "WeightedTimeSeriesCount": "Weighted count of time series data in GenevaMdm.",
        "EventRate/Min": "Rate of events per minute in GenevaMdm."
    },
    "Others": {
        "": "Other miscellaneous metrics or categories with no common units."
    }
};

// eslint-disable-next-line react/display-name
const serviceFlagCellFormatter = (editing: boolean) => ((cell: CellContext<IFinOpsBudget, number | string | undefined>) => {
    if (cell.row.depth > 0) return undefined;
    const updateTable = (serviceRow: Row<IFinOpsBudget>, columnId: string, value?: boolean) => {
        if (value === undefined) return;
        const change = { [columnId]: value ? 1 : 0 };
        cell.table.options.meta?.updateData(serviceRow.id, change);
        serviceRow.subRows?.forEach(budgetRow => {
            cell.table.options.meta?.updateData(budgetRow.id, change);
        });
    }
    const initialValue = cell.getValue();
    // We need to keep and update the state of the cell normally
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setValue] = React.useState(initialValue);
    // If the initialValue is changed external, sync it up with our state
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    return (
        <Toggle
            checked={value === 1 ? true : false}
            disabled={!editing}
            onChange={(_e, value) => updateTable(cell.row, cell.column.id, value)}
        />
    );
});

const CURRENT_BUDGET_FISCAL_YEAR = getBudgetFiscalYear();

const HiddenServiceList = ['c7d6dbf7-31a4-4b35-ba59-efc48b9e9ffd',//'Cosmic Dev-Debug'
                            '478ae0ce-50f8-44e7-99a6-cf4b9b40d97e',//'COSMIC Platform'
                            ];

interface IBudgetProps {
    showReport?: boolean;
}



export const Budget: React.FC<IBudgetProps> = ({showReport}) => {
    const [editing, setEditing] = React.useState(false);

    const selectedOwners = useCategoryFilters().filters.filters.Owner;
    const currentOwner = selectedOwners && selectedOwners.length > 0 ? selectedOwners[0] : undefined;
    const {data: initialData, isLoading} = useGetServiceLevelBudget({
      fiscalYear: CURRENT_BUDGET_FISCAL_YEAR,
      serviceGM: currentOwner
    });
    const [modifiedRows, setModifiedRows] = React.useState(new Set());

    const filteredData = React.useMemo(() => {
        return initialData?.filter(service => !HiddenServiceList.includes(service.serviceId)) || [];
    }, [initialData]);
    const { data: flights } = useFlights();    
    const { data: allBanners } = useGetBannersByTabAndDateQuery("FinOps");
    console.log(allBanners);

    const columns = React.useMemo<ColumnDef<IFinOpsBudget, number | string>[]>(
        () => {
            let baseColumns: ColumnDef<IFinOpsBudget, number | string | undefined>[] = [
                {
                    id: 'service',
                    header: 'Service',
                    accessorFn: row => serviceTree.indexMap.get(row.serviceId)?.n || row.serviceId || '',
                    footer: 'Total',
                    enableSorting: true,
                    columns: [
                        {
                            id: 'category',
                            header: 'Category',
                            accessorKey: 'category',
                            cell: ({row, getValue}) => (
                                <>
                                {getValue()}
                                </>
                            ),
                            enableSorting: false,
                        },
                        {
                            id: 'type',
                            header: 'Type',
                            accessorKey: 'type',
                            enableSorting: false,
                            cell: ({getValue}) => (
                                <>
                                    {getValue()}
                                </>)
                        }
                    ]
                },
                {
                    id: 'isServiceAffectedByCopilot',
                    header: 'Affected by Copilot',
                    accessorKey: 'isServiceAffectedByCopilot',
                    cell: serviceFlagCellFormatter(editing),
                    enableSorting: false,
                },
                {
                    id: 'lastYearCost',
                    header: `${getLastFiscalYear(CURRENT_BUDGET_FISCAL_YEAR)} Spend`,
                    accessorKey: 'lastYearCost',
                    cell: cell => currencyFormatter(toNumber(cell.getValue())),
                    footer: ({table}) => {
                        return currencyFormatter(sum(table.getFilteredRowModel().rows.map(row => row.original.lastYearCost)));
                    },
                    enableSorting: true,
                },
                {
                    id: 'lastYearQuantity',
                    header: `${getLastFiscalYear(CURRENT_BUDGET_FISCAL_YEAR)} Quantity`,
                    accessorKey: 'lastYearQuantity',
                    cell: cell => cell.getValue() ? Math.round(toNumber(cell.getValue())).toLocaleString() : "",
                    enableSorting: true,
                },
                {
                    id: 'unit',
                    header: 'Unit',
                    accessorKey: 'unit',
                    enableSorting: false,
                    cell: ({ row, getValue }) => {
                        const type = row.original.type;
                        const unit = getValue() as string;
                        const tooltip = typeUnitToolTips[type]?.[unit] || "No tooltip available";
                        return (
                            <TooltipHost content={tooltip}>
                                {unit}
                            </TooltipHost>
                        );
                    }
                },
                {
                    id: 'blendedRate',
                    header: 'Blended Rate',
                    accessorFn: row => {
                        let n = 0;
                        if (row.lastYearQuantity) n = row.lastYearCost / (row.lastYearQuantity || 1);
                        if (n === 0) n = getDefaultBlendedRate(row);
                        if (n === 0) return "";
                        const numberBeforeDecimal = Math.floor(n);
                        n -= numberBeforeDecimal;
                        if (n === 0) {
                            return numberBeforeDecimal;
                        }
                        return parseFloat(numberBeforeDecimal + n.toFixed(1-Math.floor(Math.log(n)/Math.log(10))));
                    },
                    enableSorting: false,
                }];

            // Budget related info.
            baseColumns = baseColumns.concat([{
                id: 'increaseRate',
                header: `${CURRENT_BUDGET_FISCAL_YEAR} Growth Rate %`,
                accessorKey: 'increaseRate',
                cell: !editing ? cell => (cell.row.original.lastYearCost > 0 ? cell.getValue() : undefined) : numberCellFormatter,
                enableSorting: true,
            },
            {
                id: 'costBudget',
                header: `${CURRENT_BUDGET_FISCAL_YEAR} Cost`,
                accessorKey: 'costBudget',
                cell: !editing ? cell => currencyFormatter(Math.round(toNumber(cell.getValue()))) : numberCellFormatter,
                footer: ({table}) => {
                    return currencyFormatter(sum(table.getFilteredRowModel().rows.map(row => toNumber(row.getValue('costBudget')))));
                },
                enableSorting: true,
            },
            {
                id: 'quantityBudget',
                header: `${CURRENT_BUDGET_FISCAL_YEAR} Quantity`,
                accessorKey: 'quantityBudget',
                cell: !editing ? cell => (cell.getValue() ? Math.round(toNumber(cell.getValue())).toLocaleString() : "") : numberCellFormatter,
                enableSorting: true,
            },
            {
                id: 'justification',
                header: 'Justification',
                accessorKey: 'justification',
                cell: !editing ? cell => cell.getValue() : textCellFormatter,
            }]);

            // Only show review info when not editing
            if (!editing) {
                baseColumns = baseColumns.concat(
                    [{
                        id: 'submitter',
                        header: 'Submitter',
                        accessorKey: 'submitter',
                    },
                    {
                        id: 'version',
                        header: 'Version',
                        accessorFn: row => row.version?.format('YYYY-MM-DD') || '',
                    },
                    {
                        id: 'approver',
                        header: 'Approver',
                        accessorKey: 'approver',
                    },
                    {
                        id: 'approved',
                        header: 'Status',
                        accessorKey: 'approved',
                        cell: 
                            cell => cell.row.original.submitter ? (cell.getValue() == 2 ? 'Approved':
                                cell.getValue() == 1 ? 'Rejected' :
                                cell.getValue() === 0 ? 'Not reviewed' : '') : '',
                    },
                    {
                        id: 'approvedTime',
                        header: 'Approved Time',
                        accessorFn: row => row.approvedTime?.format('YYYY-MM-DD') || '',
                    },
                    {
                        id: 'comment',
                        header: 'Comment',
                        accessorKey: 'comment',
                        cell: cell => cell.getValue(),
                    },
                ]);
            }

            return baseColumns;
        },
        [editing]
      );
      const [data, setData] = React.useState(filteredData || []);
      React.useEffect(() => {
        setData(filteredData || []);
    }, [filteredData]);
    
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [expanded, setExpanded] = React.useState<ExpandedState>({});
    React.useEffect(() => {
        if (editing) {
            let newData: IFinOpsBudget[] = data.map(serviceLevelBudget => ({
                ...serviceLevelBudget,
                children: suppleBudgets(CURRENT_BUDGET_FISCAL_YEAR, serviceLevelBudget.serviceId, serviceLevelBudget.children || [])
            }));

            if (!isEmpty(sorting)) {
                const columnId = sorting[0].id as keyof IFinOpsBudget;
                const desc = sorting[0].desc;
                newData = orderBy(
                    newData.map(serviceLevelBudget => {
                        serviceLevelBudget.children = serviceLevelBudget.children &&
                            orderBy(serviceLevelBudget.children, s => s[columnId], desc ? 'desc' : 'asc');

                        return serviceLevelBudget;
                    }),
                    s => s[columnId],
                    desc ? 'desc' : 'asc');
            }
            setData(newData);
        } else {
            setData(data.map(serviceLevelBudget => ({
                ...serviceLevelBudget,
                children: clearEmptyBudgets(serviceLevelBudget.children)
            })));
        }
      }, [editing]);
    
      const customCategoryOrder = ['Compute', 'Storage', 'Network', 'Monitoring', 'Data Operations', 'Passive Monitoring', 'Others'];

      const customSortFunction = (a: IFinOpsBudget, b: IFinOpsBudget) => {
        const orderA = customCategoryOrder.indexOf(a.category);
        const orderB = customCategoryOrder.indexOf(b.category);
        return orderA - orderB;
      };

      const table = useReactTable({
        data,
        columns,
        state: {
            sorting: editing ? [] : sorting,
            expanded
        },
        sortDescFirst: true,
        getRowId: getBudgetId,
        onSortingChange: setSorting,
        onExpandedChange: setExpanded,
        getSubRows: row => row.children ? row.children.sort(customSortFunction) : [],
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        // Provide our updateData function to our table meta
        meta: {
            updateData: (rowId, columns) => {
                setData(old =>
                    old.map((serviceRow) => {
                        if (getBudgetId(serviceRow) === rowId) {
                            return {
                                ...serviceRow,
                                ...columns
                            };
                        }
                    let row: IFinOpsBudget = {
                        ...serviceRow,
                        children: serviceRow.children?.map((budgetRow) => {
                        if (getBudgetId(budgetRow) === rowId) {
                            setModifiedRows(prev => new Set(prev).add(rowId));
                            return {
                            ...budgetRow,
                            ...columns
                            }
                        }
                        return budgetRow;
                        })
                    };
                    if (has(columns, 'increaseRate') || has(columns, 'quantityBudget') || has(columns, 'costBudget')) {
                        row = recalculateServiceBudget(row);
                    }
                    return row;
                    })
                )},
        }
      });

      const ownerFilter = CategoryDivision.Owner;
      const serviceTree = useSelector<IAppState, IServiceTreeData>((state) => state.serviceTree);
      const ownerList = React.useMemo(() =>
      uniq(sortBy(Array.from(serviceTree.owners.keys()).filter(x => x).concat(['martri', 'nmyhre', 'rakalapa', 'skini']))),
      [serviceTree.owners]);
  
      const queryClient = useQueryClient();
      const mutation = useInitializeNewFinOpsBudgets(
      () => {
          queryClient.invalidateQueries([Endpoints.GetCostAndBudget]);
          setEditing(false);
      },
      (error) => {
          alert(error?.message ? error.message : "Failed to submit!");
      }
      );

    const formatNumber = (num: number) => {
        if (num < 1000) {
            return num.toFixed(2);
        } else if (num < 1e6) {
            return (num / 1e3).toFixed(2) + 'K';
        } else {
            return (num / 1e6).toFixed(2) + 'M';
        }
    };
    
    const fy25TotalSpend = data.reduce((total, service) => total + (service.lastYearCost || 0), 0);
    const fy26Goal = fy25TotalSpend * 1.04;
    const formattedFy26Goal = formatNumber(fy26Goal);

    const mutationGMSubmit = useSignOffFromGM();
    const hasServiceCell = 0;

      if (showReport) return <FinOpsReport />;
      return (
        <BudgetLayout>
            <h1>Submit Ask</h1>
            {
                editing && (
                    <div className={styles.Bannerheader} >
                        {
                            Boolean(allBanners?.length) &&
                            allBanners?.map(item =>
                            (
                                <WarningBanner key={item.id} bannerItem={item} />
                            ))
                        }
                    </div>
                )
            }
            <Stack horizontal tokens={{childrenGap: 20}} style={{ paddingTop:'30px', paddingBottom: '30px' }}>
                <WorkloadFilter
                    key={ownerFilter}
                    category={ownerFilter}
                    optionList={ownerList}
                    displayName={CategoryDivisionDisplayName[ownerFilter]}
                    isSingleSelect
                />
                {
                    currentOwner && (flights?.enableGMAccess || flights?.enableFinOpsUserAccess) && !editing && (
                    <PrimaryButton
                        text="Create Ask"
                        onClick={() => setEditing(true)}
                    />
                )}
                { 
                    currentOwner && flights?.enableGMAccess && editing && (
                    mutation.isLoading || mutationGMSubmit.isLoading ? (
                        <Spinner label='Be patient, we are submitting ...' labelPosition="right" />
                    ) : (
                        <>
                            <PrimaryButton
                                text="Submit Ask"
                                disabled={mutation.isLoading}
                                onClick={() => {
                                    alert("The process may take some time, we kindly ask for your patience!");
                                    const modifiedData = flatten(data.map(serviceData => serviceData.children || []))
                                        .filter(b => modifiedRows.has(getBudgetId(b)) || !b.version)
                                        .map(b => ({
                                            ...b,
                                            id: 0,
                                        }));
                                    mutation.mutate(modifiedData, {
                                        onSuccess: () => {
                                            mutationGMSubmit.mutateAsync({ fiscalYear: CURRENT_BUDGET_FISCAL_YEAR, signOffTime: moment()}, {
                                                onSuccess: () => {
                                                    alert('Submit successful!');
                                                }
                                            });
                                            // Reset modifiedRows and fetch the latest data
                                            setModifiedRows(new Set());
                                            queryClient.invalidateQueries(['serviceLevelBudget', CURRENT_BUDGET_FISCAL_YEAR, currentOwner]);
                                        }
                                    });
                                }} />
                        </>
                    )
                )}
                {
                    currentOwner && (flights?.enableGMAccess || flights?.enableFinOpsUserAccess) && editing && (
                                <DefaultButton
                                    disabled={mutation.isLoading}
                                    onClick={() => {
                                        if (modifiedRows.size > 0) {
                                            setModifiedRows(new Set());
                                            setData(filteredData || []);
                                        }
                                        setEditing(false);
                                    }}
                                    text="Discard Changes" />
                    )
                }
            </Stack>
            {!currentOwner && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                    Please select an owner to proceed.
                </div>
            )}
            {currentOwner && (
            <div style={{ position: 'absolute', top: 40, right: 50, backgroundColor: 'white', paddingLeft: '10px', paddingRight: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
                <h3>FY26 Goal</h3>
                <p>{formattedFy26Goal}</p>
            </div>
            )}
            {currentOwner && <Table stickyHeader>
                <TableHead>
                    {
                        table.getHeaderGroups().map(headerGroup => (
                            <TableRow key={headerGroup.id}>
                                {
                                    headerGroup.headers.map(header => (
                                        headerGroup.depth > 0 && !['category', 'type'].includes(header.column.id) ?
                                        undefined :
                                        <TableCell key={header.id} colSpan={header.colSpan} align="center"
                                            rowSpan={headerGroup.depth === 0 && header.column.id !== 'service' ? 2 : 1}
                                            className={header.column.id == 'category' ? styles.categoryHeader :
                                                header.column.id == 'type' ? styles.typeHeader :
                                                header.column.id == 'service' ? styles.serviceHeader :
                                                header.column.id == 'justification' ? styles.justificationHeader : styles.tableHeader
                                            }
                                            onClick={(editing || !header.column.getCanSort()) ? undefined : header.column.getToggleSortingHandler()}
                                            style={{cursor: editing ? 'auto' : 'pointer'}}>
                                            {(
                                                flexRender(header.column.columnDef.header, header.getContext())
                                            )}
                                            {
                                                {
                                                    asc: <FontIcon iconName='SortUp'/>,
                                                    desc: <FontIcon iconName='SortDown'/>
                                                }[header.column.getIsSorted() as string] ?? null
                                            }
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableHead>
                <TableBody>
                    {table.getRowModel().rows.map(row => {
                    return (
                        <TableRow key={row.id} className={styles.tableRow}>
                            {row.getVisibleCells().map(cell => {
                                if (cell.column.id === 'type' && row.depth === 0) {
                                    return null;
                                }
                                const isServiceCell = (row.depth === 0 && cell.column.id === 'category');
                                return (
                                <TableCell key={cell.id} align={getColumnAlignment(cell.column.id)}
                                    colSpan={isServiceCell ? 2 : 1}
                                    className={(cell.column.id == 'category') ?
                                        isServiceCell ? styles.serviceColumn :
                                        styles.categoryColumn : cell.column.id == 'type' ? styles.typeColumn : ''
                                    }>
                                    {
                                        isServiceCell ?
                                        <Stack horizontal verticalAlign="center" tokens={{childrenGap: 10}}>
                                        <IconButton
                                            iconProps={{ iconName: row.getIsExpanded() ? 'ChevronDown' : 'ChevronRight' }}
                                            onClick={row.getToggleExpandedHandler()} />
                                        {serviceTree.indexMap.get(row.original.serviceId)?.n ?? ""}
                                        {row.getIsExpanded() && editing && (
                                            mutation.isLoading ? (
                                                <Spinner label='Saving ...' labelPosition="right" />
                                            ) : (<PrimaryButton
                                            text="Save Changes"
                                            onClick={() => {
                                                const modifiedData = flatten(data.map(serviceData => serviceData.children || []))
                                                .filter(b => b.serviceId === row.original.serviceId && (modifiedRows.has(getBudgetId(b)) || !b.version))
                                                .map(b => ({
                                                ...b,
                                                id: 0,
                                                }));
                                                mutation.mutate(modifiedData, {
                                                    onSuccess: () => {
                                                        // Reset modifiedRows and fetch the latest data
                                                        alert('Submitted successfully');
                                                        setModifiedRows(new Set());
                                                        queryClient.invalidateQueries(['serviceLevelBudget', CURRENT_BUDGET_FISCAL_YEAR, currentOwner]);
                                                    }
                                            });}}
                                            styles={{ root: { marginLeft: 10 } }}
                                        />))}
                                        </Stack> : 
                                        flexRender(cell.column.columnDef.cell,cell.getContext())
                                    }
                                </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                    })}
                </TableBody>
                <TableFooter>
                    {
                        table.getFooterGroups().slice(1).map(footerGroup => (
                            <TableRow key={footerGroup.id} className={styles.tableFooter}>
                                {
                                    footerGroup.headers.map(header => (
                                        <TableCell align={getColumnAlignment(header.column.id)} key={header.id} colSpan={header.colSpan}
                                            className={header.column.id == 'service' ? styles.serviceFooter : ''
                                            }>
                                            {(
                                                flexRender(header.column.columnDef.footer, header.getContext())
                                            )}
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        ))
                    }
                </TableFooter>
            </Table>}
        </BudgetLayout>
      )
}

function getColumnAlignment(columnId: string) {
    switch (columnId) {
        case 'costBudget':
        case 'quantityBudget':
        case 'lastYearCost':
        case 'lastYearQuantity':
        case 'increaseRate':
        case 'blendedRate':
        case 'unit':
            return 'right';
        default:
            return 'left';
    }
}

function formatNumberCell(columnId: string, value?: string | number) {
    if (!value) return 0;

    const formatedValue = toNumber(value);
    if (columnId === 'increaseRate') {
        return parseFloat(formatedValue.toFixed(2)).toString();
    }
    if (columnId === 'costBudget' || columnId === 'quantityBudget') {
        return Math.round(formatedValue).toLocaleString();
    }

    return formatedValue;
}