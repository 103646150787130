import { IIconProps, IconButton, Separator, Stack } from "@fluentui/react";
import moment from "moment";
import React, { useState } from "react";
import { useGetAzureDailyCpuQuery, useGetAzureDailyMetricsQuery } from "../../../hooks/useAzureQuery";
import { getAverage } from "../../../utils/common";
import { Endpoints } from "../../../utils/Constants";
import ColumnChart from "../../common/ColumnChart";
import { SingleDatePicker } from "../../common/SingleDatePicker";
import LoadingState from "../../ResponseBoundary/LoadingState";
import { LineChartContainer } from "../common/LineChartContainer";
import styles from "../Azure.less";
import { range } from "lodash";
import { EventType, LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { useTrackHovering } from "../../../hooks/useTrack";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { useDateRange } from "../../../hooks/useDateSelector";
import { TimeView } from "../../../models/SearchKey";
import { SubComputeLogComponent } from "../../../models/AzureComputeModels";
import { useMsal } from "@azure/msal-react";
import { useBoolean } from "@fluentui/react-hooks";
import { useFlights } from "../../../hooks/useSettings";
import html2canvas from "html2canvas";
import FeedbackPanel from "../../feedback/FeedbackPanel";
import { trackEventCallback } from "../../../utils/AppInsights";

const cpuUtilizationRange = range(0, 105, 5);

interface ISubUtilizationProps {
    type: "Public" | "AZSC";
}

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

export const SubUtilization: React.FC<ISubUtilizationProps> = (props) => {
    const [cpuUtilizationDay, setCpuUtilizationDay] = React.useState(moment().startOf("date").subtract(7, "days"));
    const cpuUtilization = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, "CPU", props.type, "P99");
    const memoryUtilization = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, "Memory", props.type, "P99");
    const networkUtilization = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, "Network", props.type, "P99");

    const cpuUtilizationSingleDay = useGetAzureDailyCpuQuery(Endpoints.GetAzureDailyCpuUtilization, cpuUtilizationDay, props.type);
    const trackCpuCoresHoveringProps = useTrackHovering(LogComponent.Azure, LogElement.AzureCores, "CPU Utilization - Core Count Histogram (P99)", LogTarget.Chart, { type: props.type });
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.AzureCompute);
    const activeAnomalies = React.useMemo(() => getActiveAnomalies(anomaliesQuery.data, ["TotalCores"]), [anomaliesQuery.data]);
    const { startDate, endDate } = useDateRange();
    const dateRange = [startDate, endDate];

    // User Feedback
    const { instance } = useMsal();
    const { data: flights } = useFlights();
    const account = instance.getActiveAccount();
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');
    const [currentScreenshotURL, setCurrentScreenshotURL] = useState<string>('');

    const captureScreenshot = async (title: string) => {
        trackEventCallback(SubComputeLogComponent[props.type], LogElement.AzureCores, "Send Feedback Chart", LogTarget.Button, undefined, EventType.Click);

        const url = window.location.href;
        setCurrentURL(url);

        await openFeedbackPanel();

        const targetElement = document.getElementById(`FeedbackSection_${title}`);

        if (targetElement) {
            setTimeout(() => {
                html2canvas(targetElement).then((canvas) => {
                    const screenshotDataUrl = canvas.toDataURL('image/png');
                    setCurrentScreenshotURL(screenshotDataUrl);
                });
            }, 100);

        }
    };

    const handleDismissFeedbackPanel = () => {
        setCurrentScreenshotURL('');
        dismissFeedbackPanel();
    };

    return (
        <>
        <Separator alignContent="start">Utilization</Separator>
        <div className={styles.content}>
            <div>
                <LineChartContainer
                    title="Azure Utilization(P99)"
                    isLoading={cpuUtilization.isLoading || memoryUtilization.isLoading || networkUtilization.isLoading}
                    cardProps={[
                        {title: "Average CPU Utilization", cost: getAverage(cpuUtilization.data?.data), color: "#0099BC", noPrefix: true, suffix: "%", showTooltip: true, date: dateRange},
                        {title: "Average Memory Utilization", cost: getAverage(memoryUtilization.data?.data), color: "#982570", noPrefix: true, suffix: "%", showTooltip: true, date: dateRange},
                        {title: "Average Network Utilization", cost: getAverage(networkUtilization.data?.data), color: "#6674E3", noPrefix: true, suffix: "%", showTooltip: true, date: dateRange},
                    ]}
                    series={[
                        {
                            name: "CPU",
                            data: cpuUtilization.data?.data.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                        },
                        {
                            name: "Memory",
                            data: memoryUtilization.data?.data.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                        },
                        {
                            name: "Network",
                            data: networkUtilization.data?.data.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                        }
                    ]}
                    isNotCurrency
                    maxValue={100}
                    suffix="%"
                    logProps={{
                        logComponent: SubComputeLogComponent[props.type],
                        logElement: LogElement.AzureUtilizationP99,
                        customProperties: {
                            type: props.type,
                        }
                    }}
                    anomalies={activeAnomalies}
                    fillMissingAbnormalData
                    timeView={TimeView.Daily}
                />
            </div>
            <div className={styles.cardContainer} id={`FeedbackSection_CPU_UtilP99`} >
                {flights?.enableUserFeedback &&
                    <IconButton
                        title="Send feedback"
                        className={styles.feedbackIcon}
                        iconProps={feedbackIconProps}
                        onClick={() => captureScreenshot("CPU_UtilP99")}
                    />}
                <Separator styles={{root: styles.separator}} />
                <Stack horizontal horizontalAlign="space-between" styles={{root: styles.title}}>
                    <h4 className={styles.titleText}>CPU Utilization - Core Count Histogram (P99)</h4>
                    <SingleDatePicker
                        selectedDate={cpuUtilizationDay}
                        onSelectDate={setCpuUtilizationDay}
                    />
                </Stack>
                <div className={styles.chart} {...trackCpuCoresHoveringProps}>
                    {
                        !cpuUtilizationSingleDay.isLoading && cpuUtilizationSingleDay.data ?
                        <ColumnChart
                            seriesHeader="CPU Cores"
                            x={cpuUtilizationRange.map(key => `${key}%`)}
                            y={cpuUtilizationRange.map(key => cpuUtilizationSingleDay.data[key])}
                        /> :
                        <LoadingState />
                    }
                </div>
            </div>
        </div>
        <FeedbackPanel
            isPanelOpen={isFeedbackPanelOpen}
            onDismiss={handleDismissFeedbackPanel}
            userName={account?.username}
            currentURL={currentURL}
            screenshotInputURL={currentScreenshotURL}
            FeedbackTab={SubComputeLogComponent[props.type]}
        />
        </>
    )
}