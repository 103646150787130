import { Checkbox, DefaultButton, Label, Link, Panel, PanelType, PrimaryButton, SearchBox, Text, TextField } from "@fluentui/react";
import { ErrorMessage, ViewInputValidator } from "../../validators/ViewInputValidators";
import { IFilterView, IPartialFilterViewContent } from "../../models/FilterView";
import React, { useCallback, useMemo, useState } from "react";
import { ServiceTree, ServiceTreeItem, ServiceTreeLevel } from "../../models/serviceTree";
import { makeSaveViewFailDialogAction, makeSaveViewSuceessDialogAction } from "../../reducers/dialogsReducer";
import { useDispatch, useSelector } from "react-redux";

import { DataNode } from "../../models/DataNode";
import { IAppState } from "../../store";
import { IServiceTreeData } from "../../reducers/serviceTreeReducer";
import TreeSelect  from "../TreeSelect";
import { getAllSubViewsUnderSelectedView } from "../../reducers/subviewReducer";
import { loadView } from "../../reducers/savedViewReducer";
import { makeTogglePanelAction } from "../../reducers/subviewPanelReducer";
import { saveSubView } from "../../services/viewService";
import styles from "./SubViewPanel.less";

interface SubViewPanelProps {
    view: IFilterView;
}

const SubViewPanel: React.FC<SubViewPanelProps> = (props) => {
    const dispatch = useDispatch();
    const onRenderPanelFooter = (): JSX.Element => (
        <div className={styles.footer}>
            <PrimaryButton onClick={onSaveSubView} style={{marginRight: 16}}>Apply ({totalCount})</PrimaryButton>
            <DefaultButton onClick={onDismissPanel}>Cancel</DefaultButton>
        </div>
    )

    const [subViewNameError, setSubViewNameError] = useState(false);
    const { serviceTree: data} = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const  deepClonedView =  useMemo(() => JSON.parse(JSON.stringify(props.view)) as IFilterView, [props.view]);
    const serviceData = useMemo(() => { return (data && searchServiceNodeFromServiceTree(data, deepClonedView.filterContent)) || []}, [data, deepClonedView]);
    const tagData = useMemo(() => { return wrapTagNode(deepClonedView.filterContent)}, [deepClonedView.filterContent]);
    const filterViewData = useMemo(() => [...serviceData, ...tagData], [serviceData, tagData]);
    const [subViewName, setSubViewName] = useState('');
    const selectedIds = useMemo(() => filterViewData.map((node) => node.value), [filterViewData]);
    const [internalSelectedIds, setInternalSelectedIds] = useState<string[]>(selectedIds);
    const [internalSelectedItems, setInternalSelectedItems] = useState<DataNode[] | undefined>(undefined);
    const totalCount = internalSelectedIds.length;
    const initialFilterCount = filterViewData.length;
    const initialOuterMostItemsArray = filterViewData.map((item) => (item.value));
    const initialOuterMostItemsSet: Set<string> = new Set<string>(initialOuterMostItemsArray); 
    const selectedOuterMostItemCount = internalSelectedIds.reduce((prevValue, current) =>{
            return prevValue + (initialOuterMostItemsSet.has(current) ? 1 : 0);
        }, 0);
    const handleChange = useCallback((values: string[], nodes: DataNode[]) => {
        setInternalSelectedIds(values);
        setInternalSelectedItems(nodes);
    }, []);

    const onChangeViewName = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setSubViewName(newValue || '');
      }, []);
    
    const onSaveSubView = (): void => {
        if (!ViewInputValidator.validateSubViewName(subViewName)) {
            setSubViewNameError(true);
            return;
        }

        setSubViewNameError(false);
        if (!internalSelectedItems || internalSelectedItems.length == 0) {
            return;
        }
          
        if (internalSelectedItems) {
            saveSubView(props.view.id, subViewName, createSubViewFilter(internalSelectedItems)).then(async (response) => {  
                if (response.ok) {
                    dispatch(getAllSubViewsUnderSelectedView(props.view.id));
                    dispatch(loadView(props.view.id));
                    dispatch(makeSaveViewSuceessDialogAction(true));
                } else {
                    if (response.status === 400) {
                        const message = await response.text();
                        return Promise.reject(message);
                    } else {
                        const message = "Saving view was blocked due to server error, please to refresh Jaws and try again."
                        return Promise.reject(message);
                    }
                }
            }).catch((exception) => {
                dispatch(makeSaveViewFailDialogAction(true, exception)); 
            }).finally(() => {
                setSubViewNameError(false);
                setSubViewName('');
                dispatch(makeTogglePanelAction(false, props.view.id));
            }); 
        }
    } 

    const onDismissPanel = (): void => {
        setSubViewNameError(false);
        setSubViewName('');
        dispatch(makeTogglePanelAction(false, props.view.id));
    } 

    const onToggleCheckAllBox = useCallback((event?: React.FormEvent<HTMLElement>, checked?: boolean) => {
        if (!!checked) {
            setInternalSelectedIds(selectedIds);
            setInternalSelectedItems(filterViewData);
        } else {
            setInternalSelectedIds([]);
            setInternalSelectedItems([]);
        }
    }, [filterViewData, selectedIds]);

    return (
        <Panel
            isBlocking={false}
            isOpen={true}
            headerText={`Add a Sub-View in "${props.view.viewName}"`}
            headerClassName={styles.panelHeaderText}
            onRenderFooter={onRenderPanelFooter}
            isFooterAtBottom
            onDismiss={onDismissPanel}
            type={PanelType.largeFixed}
            styles={{
                scrollableContent: styles.panelScrollableContent,
                commands: styles.panelCommand,
                content: styles.panelContent,
                main: styles.panelMain,
                header: styles.panelHeader,
                headerText:styles.panelHeaderText
            }}
        >
            <Text>How to use Sub-View? <Link href='https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/31475/Jaws-Portal-Guide?anchor=feature-tagging/personalized-dashboard-view' target='_blank'>Here</Link> is the description</Text>
            <TextField 
                className={styles.subviewNameBox}
                errorMessage={subViewNameError ? ErrorMessage.SubViewNameWarning : ""}
                onChange={onChangeViewName} 
                label="Sub-view name"
                placeholder="No more than 60 Characters"
                required
            />
            <Checkbox
                className={styles.selectAllBox}
                label="All"
                indeterminate={selectedOuterMostItemCount !== initialFilterCount && totalCount > 0}
                checked={initialFilterCount === selectedOuterMostItemCount}
                onChange={onToggleCheckAllBox}
            />
            <div className={styles.pivotItemContainer}>
                <TreeSelect
                    treeData={filterViewData}
                    selectedValues={internalSelectedIds}
                    onChange={handleChange}
                />
            </div>
        </Panel>
    )
}

function searchServiceNodeFromServiceTree(serviceTree: ServiceTree, view: IPartialFilterViewContent): DataNode[] {
    const initIdMap = new Map<string, Set<string>>();
    let k : keyof IPartialFilterViewContent;
    const idSet = new Set<string>(["DivisionId", "OrganizationId", "ServiceGroupId", "TeamGroupId", "ServiceId"]);
    for (k in view) {
        if (idSet.has(k)) {      
            view[k]?.forEach((i) => {
                if (!initIdMap.has(k)) {
                    initIdMap.set(k, new Set<string>());
                }

                initIdMap.get(k)?.add(i);
            });
        }
    }
    
    const serviceData : DataNode[] = [];  
    function search(item: ServiceTreeItem, ancestors: DataNode[]) {
        const value = getValue(item);
        const category = getServiceCategory(item.l);
        const node : DataNode = { title: item.n, key: value, value, data: item, category: category};
        if (item.c) {
            node.children = generateChildDataNode(item);
        }
        
        ancestors = [...ancestors, node];
        if (initIdMap.has(category) && initIdMap.get(category)?.has(item.id)) {
            node.ancestors = ancestors;
            serviceData.push(node);
        }

        if (item.c) {
            item.c.forEach((child) => search(child, ancestors));
        }
    }

    serviceTree.items.forEach((item: ServiceTreeItem) => {
        search(item, []);
    });

    return serviceData;
}

function wrapTagNode(view: IPartialFilterViewContent) : DataNode[] {
    const tagData : DataNode[] = []; 
    const nameSet = new Set<string>(["Process", "GriffinApplicationName", "ScenarioTag", "VDir", "ServiceOwner"]);
    let k : keyof IPartialFilterViewContent;
    for (k in view) {  
        if (nameSet.has(k)) {      
            view[k]?.forEach((i) => {
                tagData.push({ title: i, key: i, value: i, data: i, category: k});
            });
        }
    }

    return tagData;
}

function generateChildDataNode(item: ServiceTreeItem) : DataNode[] | undefined {
    return item.c?.map((i) => { 
        const value = getValue(i);
        return { title: i.n, key: value, value, data: i, category: getServiceCategory(i.l)};
    })
}

function getValue(item: ServiceTreeItem) {
    return item.id === "00000000-0000-0000-0000-000000000000" ? `${item.id}(${item.l})` : item.id;
}

function createSubViewFilter(selectedItems: DataNode[]) : IPartialFilterViewContent {
    const filterView :IPartialFilterViewContent = createBlankFilters();
    selectedItems.forEach((item) => {
        let category: keyof IPartialFilterViewContent;
        if (item.data.l) {
            category = getServiceCategory(item.data.l) as keyof IPartialFilterViewContent;
        } else {
            category = item.category as keyof IPartialFilterViewContent;
        }   

        filterView[category]?.push(item.title);
    });

    return filterView;
}

function getServiceCategory(level: number) : string {
    let category: string;
    
    switch(level) {
        case ServiceTreeLevel.Service:
            category = "ServiceId";
            break;
        case ServiceTreeLevel.ServiceGroup:
            category = "ServiceGroupId";
            break;
        case ServiceTreeLevel.Division:
            category = "DivisionId";
            break;
        case ServiceTreeLevel.Organization:
            category = "OrganizationId";
            break;
        default:
            category = "TeamGroupId";
            break;
    }

    return category;
}

function createBlankFilters(): IPartialFilterViewContent {
    return {
        OrganizationId: [],
        OrganizationName: [],
        ServiceGroupId: [],
        ServiceGroupName: [],
        TeamGroupId: [],
        TeamGroupName: [],
        ServiceId: [],
        ServiceName: [],
        DivisionId: [],
        DivisionName: [],
        Process: [],
        ServiceOwner: [],
        VDir: [],
        ScenarioTag: [],
        GriffinApplicationName: []
    };
}

export default SubViewPanel;