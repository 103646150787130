import { AbnormalIssue, CogsInfo, MetricCostReport } from "../../../models/IOInsightsModels";
import { AnomalyTotalCostTypeEnum, useGetIOInsights } from "../../../hooks/useSubstrateV2Query";
import { DataSourceEnum, INotification } from "../../../models/NotificationModels";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { PcmV2SceneMetrics, SubstrateLogComponent } from "../../../models/constants/PcmV2Constants";
import React, { useMemo, useState } from "react";

import ChartContainer from "../../chart/ChartContainer";
import CommonConstants from "../../../models/constants/CommonConstants";
import { CostCardProps } from "../../common/costCard/CostCard";
import { LineChartSeries } from "../../common/LineChart";
import {Moment} from "moment";
import { PcmV2SceneTypeEnum } from "../../../models/PcmV2Models";
import { currencyFormatter } from "../../../utils/currency";
import { filterDeviation } from "../../../utils/DeviationFilter";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { getMedian } from "../../../utils/ChartUtils";
import moment from "moment";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useTimeView } from "../../../hooks/useTimeView";
import { useTrackHovering } from "../../../hooks/useTrack";

const TransactionCostSeriesName: Record<AnomalyTotalCostTypeEnum, string> = {
    [AnomalyTotalCostTypeEnum.Read]: "Transaction Item-Reads Cost",
    [AnomalyTotalCostTypeEnum.Write]: "Transaction Item-Writes Cost",
    [AnomalyTotalCostTypeEnum.Query]: "Transaction Item-Queries Cost",
};

const TransactionCostSeriesColor: Record<AnomalyTotalCostTypeEnum, string> = {
    [AnomalyTotalCostTypeEnum.Read]: "#0099BC",
    [AnomalyTotalCostTypeEnum.Write]: "#982570",
    [AnomalyTotalCostTypeEnum.Query]: "#627CEF",
};

const TransactionMetricMapping: Record<string, AnomalyTotalCostTypeEnum> = {
    "Item Reads": AnomalyTotalCostTypeEnum.Read,
    "Item Writes": AnomalyTotalCostTypeEnum.Write,
    "Item Queries": AnomalyTotalCostTypeEnum.Query,
};

const convertMetricNamingStyles: Record<string, string> = {
    "Item Reads": "ItemReads",
    "Item Writes": "ItemWrites",
    "Item Queries": "ItemQueries",
};

const iRWQ: string[] = ["Item Reads", "Item Writes", "Item Queries"];

const effectedCosts: Map<string, number> = new Map();


export interface IStoreInsightsSummaryProps {
    scene: PcmV2SceneTypeEnum;
    id: string;
    startDate:string;
    readCost: number;
    writeCost: number;
    queryCost: number;
    showAll: boolean;
    effectedMetrics: string;
    updateHideState: (hideShowMoreBtn: boolean) => void;
}

const mideanValueMap: Map<string, number> = new Map();

export const IOAnomalyCostTrend: React.FC<IStoreInsightsSummaryProps> = (props) => {
    const query = useGetIOInsights(props.scene);
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.SubstrateV2);
    const trackingProps = useTrackHovering(LogComponent.IOInsights, LogElement.AnomalyTrend, "Anomaly Trends", LogTarget.Chart);
    const [ timeView ] = useTimeView();
    const anomaliesContent: INotification[] = [];

    effectedCosts.set("Item Reads", props.readCost).set("Item Writes", props.writeCost).set("Item Queries", props.queryCost);
    
    let medianValue = 0;
    const seriesSet = useMemo<Map<string, LineChartSeries[]>>(() => {
        const serieses: Map<string, LineChartSeries[]> = new Map();
        if (query.data == undefined) {
            return serieses;
        }

        let anomalyIssue: AbnormalIssue | undefined;

        for (const issue of query.data as AbnormalIssue[]) {
            if (issue.externalID == props.id){
                anomalyIssue = issue;
                break;
            }
        }

        if (anomalyIssue == undefined){
            return serieses;
        }

        const iRWQBaselineCosts: MetricCostReport = JSON.parse(anomalyIssue.baselineCost.toString());
        const iRWQAbnormalCosts: MetricCostReport = JSON.parse(anomalyIssue.abnormalCost.toString());

        const readsAbnormalCosts: Map<Moment, CogsInfo> = JSON.parse(JSON.stringify(iRWQAbnormalCosts.ItemReadTotalCost));
        const readsBaseCosts: Map<Moment, CogsInfo> = JSON.parse(JSON.stringify(iRWQBaselineCosts.ItemReadTotalCost));
        const writesAbnormalCosts: Map<Moment, CogsInfo> = JSON.parse(JSON.stringify(iRWQAbnormalCosts.ItemWriteTotalCost));
        const writesBaseCosts: Map<Moment, CogsInfo> = JSON.parse(JSON.stringify(iRWQBaselineCosts.ItemWriteTotalCost));
        const queriesAbnormalCosts: Map<Moment, CogsInfo> = JSON.parse(JSON.stringify(iRWQAbnormalCosts.ItemQueryTotalCost));
        const queriesBaseCosts: Map<Moment, CogsInfo> = JSON.parse(JSON.stringify(iRWQBaselineCosts.ItemQueryTotalCost));
        const costPairs = [[readsAbnormalCosts, readsBaseCosts], [writesAbnormalCosts, writesBaseCosts], [queriesAbnormalCosts, queriesBaseCosts]];

        let iterator = 0;
        for (const [abnormalCost, baseCost] of costPairs) {

            const map = new Map<number, number>();
            const baselineMap = new Map<number, number>();

            for (const [momentString, costInfo] of Array.from(Object.entries(abnormalCost))) {
                const day: number = moment(momentString).valueOf();
                map.set(day, costInfo.Cost);
            }

            for (const [momentString, costInfo] of Array.from(Object.entries(baseCost))) {
                const day: number = moment(momentString).valueOf();
                map.set(day, costInfo.Cost);
                baselineMap.set(day, costInfo.Cost);
            }

            const dates = Array.from(map.keys());
            const costSeries: LineChartSeries = {
                name: TransactionCostSeriesName[TransactionMetricMapping[iRWQ[iterator]] as AnomalyTotalCostTypeEnum],
                data: dates.map((date) => [date, map.get(date)]),
                type: "line",
                color: TransactionCostSeriesColor[TransactionMetricMapping[iRWQ[iterator]] as AnomalyTotalCostTypeEnum],
                showDeviation: true,
                anomalies: getActiveAnomalies(anomaliesQuery.data, PcmV2SceneMetrics[props.scene]),
            };

            const baselinDates = Array.from(baselineMap.keys());
            const baselineSeries: LineChartSeries = {
                name: TransactionCostSeriesName[TransactionMetricMapping[iRWQ[iterator]] as AnomalyTotalCostTypeEnum],
                data: baselinDates.map((date) => [date, baselineMap.get(date)]),
            };

            const baselineData: [number, number | undefined][] = baselinDates.map((date) => [date, baselineMap.get(date)])

            const medianCost = getMedian(baselineData);

            const metricName = iRWQ[iterator];

            medianValue = getMedian(baselineSeries.data || []);

            mideanValueMap.set(metricName, medianValue);

            serieses.set(metricName, [
                costSeries,
                {
                    name: "Normal week median Cost",
                    color: CommonConstants.MedianColor,
                    dashStyle: "Dash",
                    disableAnomaly: true,
                    data: costSeries.data?.map(([date, value]) => [date, medianCost]),
                },
            ]);
            iterator = iterator + 1;
        }

        // Anomaly Notification.
        const startDate = moment(anomalyIssue.startTime);
        const endDate = moment(anomalyIssue.endTime);

        const anomaly: INotification = { 
            startDate : startDate,
            endDate: endDate,
            detectionDate: anomalyIssue.detectTime,
            category: "Data abnormal",
            status: "Resolving",
            affectedMetrics: JSON.parse(anomalyIssue.effectedPCMMetrics),
            dataSourceName: DataSourceEnum.SubstrateV2,
        }

        anomaliesContent.push(anomaly); 

        return serieses;
    }, [query.data, props.scene, anomaliesQuery.data, timeView, props.id]);

    const chartCards: CostCardProps[] | undefined = [];

    const sortedSeries = new Map([...seriesSet.entries()].filter(o => props.effectedMetrics.includes(convertMetricNamingStyles[o[0]])).filter((x) => ((effectedCosts.get(x[0])) || 0) > 10).sort((a, b) => (effectedCosts.get(b[0]) || 0) - (effectedCosts.get(a[0]) || 0)));

    const collaspedSeries = filterDeviation(sortedSeries, 0.75);

    const dispalySeries = props.showAll? sortedSeries : collaspedSeries;

    React.useEffect(() => {
        if (collaspedSeries.size != sortedSeries.size){
            props.updateHideState(true);
        } else {
            props.updateHideState(false);
        }
    });

    return (
        <>
        {Array.from(dispalySeries.entries()).map(([metric, series]) => {
        return <div key={metric} style={{marginBottom:48}}><ChartContainer
            cards={chartCards}
            headerProps={{ title: metric + " Anomaly Details" }}
            loading={query.isLoading}
            chartType="line"
            chartProps={{
                series,
                height: 220,
                hideEmptySeries: true,
                anomalies: anomaliesContent,
                containerProps: trackingProps,
                isIOInsightsChart: true,
                deviationType: "Median",
                medianValue: mideanValueMap.get(metric) || 0,
            }}
            summaryDescriptionText={metric + " Trend: the Service/ Workload has " + currencyFormatter((effectedCosts.get(metric) || 0), 2, "$") + " monthly cost increase on " + metric + " since " +  moment(props.startDate).format("MM/DD/YYYY")}
            isSummaryInsightsChart={true}
        /></div>
        })}        
        </>
    );
};
