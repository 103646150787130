// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wNGp4Sx9CSyucy0wQHXV {\n  color: #107C10;\n}\n.xchrt151lqWt7yqKxQLt {\n  color: #F56A00;\n}\n.htnyIPhn5zA8UntWFlj2 {\n  vertical-align: sub;\n  margin-right: 12px;\n  margin-left: 48px;\n}\n.y5i4cNnIZv2htRyy9MGZ {\n  vertical-align: sub;\n  margin-right: 12px;\n  margin-left: 60px;\n}\n.mm4H7Nff3EMcEjBX2_ux {\n  cursor: pointer;\n  margin: 0 12px;\n}\n.S4eoz0ixOBiNNjHjuk0t {\n  margin: 0 24px;\n}\n.jXOwztL8e5suUcg1u60D {\n  height: 1px;\n  overflow-x: hidden;\n}\n.Dm62Z4G7KuteG36ifEsU {\n  text-align: right;\n}\n.Dm62Z4G7KuteG36ifEsU th {\n  padding: 0 15px;\n}\n.hZ9LbO5vIMHJ_EBiAzCa {\n  padding: 0 15px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/azure/budget/BudgetTable.less"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;AAEA;EACI,cAAA;AAAJ;AAGA;EACI,mBAAA;EACA,kBAAA;EACA,iBAAA;AADJ;AAIA;EACI,mBAAA;EACA,kBAAA;EACA,iBAAA;AAFJ;AAKA;EACI,eAAA;EACA,cAAA;AAHJ;AAMA;EACI,cAAA;AAJJ;AAOA;EACI,WAAA;EACA,kBAAA;AALJ;AAQA;EACI,iBAAA;AANJ;AAKA;EAIQ,eAAA;AANR;AAUA;EACI,eAAA;AARJ","sourcesContent":[".completedIcon {\n    color: #107C10;\n}\n\n.warningIcon {\n    color: #F56A00;\n}\n\n.rowIcon {\n    vertical-align: sub;\n    margin-right: 12px;\n    margin-left: 48px;\n}\n\n.detailsRowIcon {\n    vertical-align: sub;\n    margin-right: 12px;\n    margin-left: 60px;\n}\n\n.chevronIcon {\n    cursor: pointer;\n    margin: 0 12px;\n}\n\n.organization {\n    margin: 0 24px;\n}\n\n.tableFooter {\n    height: 1px;\n    overflow-x: hidden;\n}\n\n.tableHeader {\n    text-align: right;\n\n    th {\n        padding: 0 15px;\n    }\n}\n\n.tableCell {\n    padding: 0 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"completedIcon": "wNGp4Sx9CSyucy0wQHXV",
	"warningIcon": "xchrt151lqWt7yqKxQLt",
	"rowIcon": "htnyIPhn5zA8UntWFlj2",
	"detailsRowIcon": "y5i4cNnIZv2htRyy9MGZ",
	"chevronIcon": "mm4H7Nff3EMcEjBX2_ux",
	"organization": "S4eoz0ixOBiNNjHjuk0t",
	"tableFooter": "jXOwztL8e5suUcg1u60D",
	"tableHeader": "Dm62Z4G7KuteG36ifEsU",
	"tableCell": "hZ9LbO5vIMHJ_EBiAzCa"
};
export default ___CSS_LOADER_EXPORT___;
