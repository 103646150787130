// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".deggsNLcmWOrb6MeJJwo {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding-left: 4px;\n}\n.aQVxYKwlIzS62r7agOlD {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding-left: 4px;\n  font-weight: bold;\n}\n.JBsC7bM_5pdqHWbJ8gbN {\n  background-color: #C8C8C8;\n  margin: 0 16px;\n  height: 1px;\n}\n.WGicHt3mUJeX0Y0X4cqo {\n  height: 48px;\n  line-height: 48px;\n  margin-left: 16px;\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Navigation.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,8BAAA;EACA,iBAAA;AACJ;AAEA;EACI,WAAA;EACA,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,iBAAA;AAAJ;AAGA;EACI,yBAAA;EACA,cAAA;EACA,WAAA;AADJ;AAIA;EACI,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;AAFJ","sourcesContent":[".navBar {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    padding-left: 4px;\n}\n\n.navBarTotal {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    padding-left: 4px;\n    font-weight: bold;\n}\n\n.separator {\n    background-color: #C8C8C8;\n    margin: 0 16px;\n    height: 1px;\n}\n\n.groupHeader {\n    height: 48px;\n    line-height: 48px;\n    margin-left: 16px;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navBar": "deggsNLcmWOrb6MeJJwo",
	"navBarTotal": "aQVxYKwlIzS62r7agOlD",
	"separator": "JBsC7bM_5pdqHWbJ8gbN",
	"groupHeader": "WGicHt3mUJeX0Y0X4cqo"
};
export default ___CSS_LOADER_EXPORT___;
