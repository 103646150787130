import { currencyFormatter, formatNumber } from "../../../utils/currency";

export class TableColumnRenderer {
    static renderCurrency(dollar: number | undefined | null) {
        if (dollar == undefined || dollar == null) return "-";
        return currencyFormatter(dollar, 2);
    }

    static renderNumber(value: number | undefined | null = 0, precision = 0) {
        if (value == undefined || value == null) return "-";
        return formatNumber(value, precision);
    }

    static renderCarbonEmission(value?: number) {
        if (typeof value != "number") {
            return "-";
        }

        return formatNumber(value, 1) + " mtCO2e";
    }
}
