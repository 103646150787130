import styles from './Table.less';

import React from "react";
import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from "@fluentui/react";

import { Component, CopsResourceTypeEnum, EfficiencyStatusEnum, resourceTypeUnitDictionary } from "../../../../models/EfficiencyTracker";
import EfficiencyStatus from "../Tools/EfficiencyStatus";
import { formatValue } from "../Tools/ExportFunction";
import { formatNumber } from "../../../../utils/currency";


const columns: IColumn[] = [
    {
        key: 'ImproveStatus', name: 'Status', minWidth: 30, maxWidth: 65, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'ConfidenceLevel', name: 'Confidence', minWidth: 20, maxWidth: 75, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'EfficiencyTargetCatagory', name: 'Category', minWidth: 20, maxWidth: 75, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'ResourceTypeUOM', name: 'Resource UOM', minWidth: 100, maxWidth: 100, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'BaseLineValue', name: 'Baseline Value', minWidth: 120, maxWidth: 150, isResizable: true, className: styles.nomarlColumn,
    },
    {
        key: 'ReductionTarget', name: 'Reduction Target', minWidth: 50, maxWidth: 110, isResizable: false, className: styles.nomarlColumn
    },
];

const columns1: IColumn[] = [
    {
        key: 'ImproveStatus', name: 'Status', minWidth: 30, maxWidth: 65, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'ConfidenceLevel', name: 'Confidence', minWidth: 20, maxWidth: 75, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'EfficiencyTargetCatagory', name: 'Category', minWidth: 20, maxWidth: 75, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'TrackingGap', name: 'Track Gap', minWidth: 20, maxWidth: 90, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'ResourceTypeUOM', name: 'Resource UOM', minWidth: 100, maxWidth: 100, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'BaseLineValue', name: 'Baseline Value', minWidth: 120, maxWidth: 150, isResizable: true, className: styles.nomarlColumn,
    },
    {
        key: 'ReductionTarget', name: 'Reduction Target', minWidth: 50, maxWidth: 110, isResizable: false, className: styles.nomarlColumn
    },
];

interface IRegionDetailsTableProps {
    ImproveStatus: boolean;
    ConfidenceLevel: string;
    EfficiencyTargetCatagory: string;
    TrackingGap: number;
    Components: Component[];
    ResourceType: CopsResourceTypeEnum;
    BaseLineValue: string;
    ReductionTarget: number;
}

const RegionDetailsTable: React.FC<IRegionDetailsTableProps> = (props) => {
    const array = [props];

    function _renderItemColumn(item: IRegionDetailsTableProps, index?: number, column?: IColumn): React.ReactNode {
        const fieldContent = item[column?.key as keyof IRegionDetailsTableProps];

        switch (column?.key) {
            case "BaseLineValue":
                return <span>{formatNumber(fieldContent as number)}</span>
            case "TrackingGap":
                return <span>{item.TrackingGap > 0 ? "Yes" : "No"}</span>;
            case "ImproveStatus":
                const state = item.ImproveStatus ? EfficiencyStatusEnum.Delayed : EfficiencyStatusEnum.OnTrack;
                return <EfficiencyStatus Status={state} />;
            case "ResourceTypeUOM":
                return <span>{formatValue(resourceTypeUnitDictionary[item.ResourceType])}</span>
            case "ConfidenceLevel":
                return <EfficiencyStatus Status={item.ConfidenceLevel as EfficiencyStatusEnum} />;
            case "ReductionTarget":
                return <span>{formatValue(fieldContent) + "%"}</span>;
            default:
                return <span>{formatValue(fieldContent)}</span>;
        }
    }

    return (
        <div>
            <DetailsList
                items={array}
                setKey="set"
                compact={true}
                columns={
                    (props.ResourceType === CopsResourceTypeEnum.HDD || props.ResourceType === CopsResourceTypeEnum.SSD)
                        ? columns1 : columns}
                onRenderItemColumn={_renderItemColumn}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                checkboxVisibility={CheckboxVisibility.hidden}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                selectionZoneProps={{
                    disableAutoSelectOnInputElements: true,
                    isSelectedOnFocus: false
                }}
            />
        </div>
    );
}



export default RegionDetailsTable;