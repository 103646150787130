// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".P3OwJ6UKkf9hYuLVI2E5 {\n  padding-top: 20px;\n  overflow-y: auto;\n  height: calc(100% - 50px - 64px - 30px);\n  min-height: calc(100% - 50px - 64px - 30px) !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ServiceTreeNav.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,gBAAA;EACA,uCAAA;EACA,sDAAA;AACJ","sourcesContent":[".panelContent {\n    padding-top: 20px;\n    overflow-y: auto;\n    height: calc(100% - 50px - 64px - 30px);\n    min-height: calc(100% - 50px - 64px - 30px) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelContent": "P3OwJ6UKkf9hYuLVI2E5"
};
export default ___CSS_LOADER_EXPORT___;
