/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ActionTypes } from "../actions/ActionTypes";
import { IModifySubstrateCostTableAction } from "../actions/SubstrateActions";
import { Reducer } from "redux";
import { SubstrateEntityMetrics } from "../models/SubstrateModels";
import { UserPreferenceUtils } from "../utils/preference/UserPreferenceUtils";
import { difference } from "lodash";

export const substrateCostTableColumnNames = [
    SubstrateEntityMetrics.TotalCost,
    SubstrateEntityMetrics.TotalCarbonEmission,
    SubstrateEntityMetrics.CarbonScope2Emission,
    SubstrateEntityMetrics.CarbonScope3Emission,
    SubstrateEntityMetrics.ItemReadsCost,
    SubstrateEntityMetrics.ItemWritesCost,
    SubstrateEntityMetrics.ItemQueriesCost,
    SubstrateEntityMetrics.EBACost,
    SubstrateEntityMetrics.TBACost,
    SubstrateEntityMetrics.SWSSCost,
    SubstrateEntityMetrics.EBACount,
    SubstrateEntityMetrics.TBACount,
    SubstrateEntityMetrics.SWSSCount,
    SubstrateEntityMetrics.TotalAssistantsCost,
    SubstrateEntityMetrics.ServiceInstanceCost,
    SubstrateEntityMetrics.ServiceInstanceCount,
    SubstrateEntityMetrics.ServiceInstanceCpu,
    SubstrateEntityMetrics.ServiceInstanceMemory,
    SubstrateEntityMetrics.DsapiRequestsCost,
    SubstrateEntityMetrics.DsapiRequestsCount,
    SubstrateEntityMetrics.CfmSubmittedCost,
    SubstrateEntityMetrics.CfmSubmittedCount,
    SubstrateEntityMetrics.ItemSizeCost,
    SubstrateEntityMetrics.MaxItemSize,
    SubstrateEntityMetrics.KvCacheCost,
    SubstrateEntityMetrics.KvCacheSize,
    SubstrateEntityMetrics.SdsFastStorageCost,
    SubstrateEntityMetrics.MaxLLCSizeGb,
    SubstrateEntityMetrics.DevOwner,
    SubstrateEntityMetrics.PmOwner,
    SubstrateEntityMetrics.RequestProcessCost,
    SubstrateEntityMetrics.ScenarioTag,
    SubstrateEntityMetrics.ItemQueriesCount,
    SubstrateEntityMetrics.ItemReadsCount,
    SubstrateEntityMetrics.ItemWritesCount,
    SubstrateEntityMetrics.OffPeakHourItemQueriesCount,
    SubstrateEntityMetrics.OffPeakHourItemReadsCount,
    SubstrateEntityMetrics.OffPeakHourItemWritesCount,
    SubstrateEntityMetrics.PeakHourItemQueriesCount,
    SubstrateEntityMetrics.PeakHourItemReadsCount,
    SubstrateEntityMetrics.PeakHourItemWritesCount,
    SubstrateEntityMetrics.RequestProcessedCount,
    SubstrateEntityMetrics.OffPeakHourRequestProcessedCount,
    SubstrateEntityMetrics.PeakHourRequestProcessedCount,
    SubstrateEntityMetrics.GriffinApp,
    SubstrateEntityMetrics.TotalItemReadsWritesQueriesCost,
    SubstrateEntityMetrics.GriffinProcessor,
];

export interface ISubstrateData {
    substrateCostTableSelectedColumns: Set<SubstrateEntityMetrics>;
}

export const substrateReducer: Reducer<ISubstrateData, IModifySubstrateCostTableAction> = function (state = initialState, action): ISubstrateData {
    switch (action.type) {
        case ActionTypes.ModifySubstrateCostTableAction:
            return {
                ...state,
                substrateCostTableSelectedColumns: action.selectedColumns,
            };
        default:
            return state;
    }
};

export function makeModifySubstrateCostTableAction(selectedColumns: Set<SubstrateEntityMetrics>): IModifySubstrateCostTableAction {
    return {
        type: ActionTypes.ModifySubstrateCostTableAction,
        selectedColumns,
    };
}

export const initialState: ISubstrateData = {
    substrateCostTableSelectedColumns: new Set<SubstrateEntityMetrics>(
        difference(substrateCostTableColumnNames, UserPreferenceUtils.getUserPreferenceData("substrateV1OverviewTable")?.hiddenColumns || [])
    ),
};
