import React, { useEffect, useRef, useState } from "react";

import CommonConstants from "../../../models/constants/CommonConstants";
import CostCard from "../../common/costCard/CostCard";
import { currencyFormatter } from "../../../utils/currency";
import styles from "../../substrateV2/overview/SubstrateCostCards.less";
import { sum } from "lodash";

export interface IInsightPortalCostCardProps {
    data: Record<string, number>;
}

const InsightPortalCostCard: React.FC<IInsightPortalCostCardProps> = (props) => {

    // totalCost is the sum of all the costs in the data object.
    const totalCost = sum(Object.entries(props.data).map(([key, value]) => (props.data && key ? value : 0)));

    const [cardWidth, setCardWidth] = useState<number | undefined>(undefined);
    const hiddenCardListRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (hiddenCardListRef.current) {
            const cardElements = hiddenCardListRef.current.children;
            if (cardElements.length > 0) {
                setCardWidth(cardElements[0].clientWidth);
            }
        }
    }, [props.data]);

    return (
        <div className={styles.cardContainer}>
            <div className={styles.title} style={{fontSize:20, marginBottom: 20}}>
                <span style={{color:"#990000"}}>{currencyFormatter(totalCost)}</span> total monthly cost increase
            </div>
            {/** Hidden cards used for calculating card width */}
            <div className={`${styles.cardList} ${styles.hiddenCardList}`} style={{marginBottom: 20}} ref={hiddenCardListRef}>
                {Object.entries(props.data).map(([key, value], index) => (
                    <div key={key}>
                        <CostCard
                            title={key || ""}
                            color={CommonConstants.DefaultColors[index]}
                            cost={(value) || 0}
                        />
                    </div>
                ))}
            </div>
            <div className={styles.cardList} style={{marginBottom: 40}}>
                {cardWidth !== undefined &&
                    Object.entries(props.data).map(([key, value], index) => (
                        <div key={key} style={{ width: cardWidth }}>
                            <CostCard
                                showTooltip
                                title={key || ""}
                                className={value ? styles.activeCard : ""}
                                color={CommonConstants.DefaultColors[index]}
                                cost={value || 0}
                                classNames={{ costValue: styles.costValue }}
                            />
                        </div>
                    ))}
            </div>      
        </div>         
    );
};

export default InsightPortalCostCard;
