import CostCardTooltipContent, { ICostCardTooltipContentProps } from "./costCard/CostCardTooltipContent";
import Highcharts, { DashStyleValue } from "highcharts";
import React, { useMemo } from "react";
import { chartLegendOptions, chartStyles, chartTooltipOptions, onChartSeriesLegendClick } from "../../utils/chartOptions";
import { getAnomaliesByDate, getAnomalyDescription, getAnomalyLegendPlaceholderSeries, getAnomalySeries } from "../../utils/AnomalyDetectionUtils";
import moment, { Moment } from "moment";

import { BannerModel } from "../../models/BannerModels";
import CommonConstants from "../../models/constants/CommonConstants";
import HighchartsReact from "highcharts-react-official";
import { INotification } from "../../models/NotificationModels";
import { currencyFormatter } from "../../utils/currency";
import { getAverageValue, getMedian, groupAnomaliesByWeek, groupSeriesDataByWeek, trimNotifications, trimSingleDays } from "../../utils/ChartUtils";
import { isWeekend } from "../../utils/DateUtils";
import { renderToString } from "react-dom/server";
import styles from "./Chart.less";
import { TimeView } from "../../models/SearchKey";
import { sum } from "lodash";
import { useTimeView } from "../../hooks/useTimeView";
import { useLocation } from "react-router-dom";

window.moment = moment;

export type LineChartSeries = {
    name: string;
    id?: string;
    data?: [number, number?][];
    color?: string;
    anomalies?: INotification[];
    dashStyle?: DashStyleValue;
    disableAnomaly?: boolean;
    showDeviation?: boolean;
    type?: "line" | "area";
};

export interface LineChartProps {
    series: LineChartSeries[];
    hideLegend?: boolean;
    isNotCurrency?: boolean;
    showArea?: boolean;
    maxValue?: number;
    minValue?: number;
    suffix?: string;
    dateFormatStr?: string;
    yFormatter?: (value: number) => string;
    tooltipDateFormatStr?: string;
    tooltipYFormatter?: (value: number) => string;
    keepOrder?: boolean;
    colors?: string[];
    height?: number;
    containerProps?: React.HTMLProps<HTMLElement>;
    anomalies?: INotification[];
    fillMissingAbnormalData?: boolean;
    totalTextPrefix?: string;
    ignoreWeekend?: boolean;
    isStacking?: boolean;
    banner?: BannerModel;
    xAxis?: Highcharts.XAxisOptions;
    hideEmptySeries?: boolean;
    timeView?: TimeView;
    isIOInsightsChart?: boolean;
    deviationType?: string;
    medianValue?: number;
    errors?: Map<number, Array<{
        title: string;
        message: string;
    }>>;
}

const LineChart: React.FC<LineChartProps> = (props) => {
    const prefix = props.isNotCurrency ? "" : "$";
    const chartType = props.showArea ? "area" : "line";
    const chartColors = props.colors || CommonConstants.DefaultColors;
    const [globalTimeView] = useTimeView();
    const { pathname } = useLocation();

    // Always show daily view for area chart and pages other than SubstrateV2 pages.
    const timeView = props.timeView || ((props.showArea || !pathname.startsWith("/SubstrateV2")) ? TimeView.Daily : globalTimeView);

    const [chartSeries, hasAnomalies, tickPositions] = useMemo(() => {
        const chartSeries: Highcharts.Options["series"] = [];
        let hasAnomalies = false;
        let tickPositions = undefined;

        props.series.forEach((s, index) => {
            const isEmptySeries = !s.data?.find(([_, value]) => (value || 0) > 0);
            if (isEmptySeries && props.hideEmptySeries) {
                return;
            }

            let data: [Moment, number | undefined][];
            let anomalies: INotification[] | undefined;

            if (timeView === TimeView.Daily && props.ignoreWeekend) {
                data = s.data?.filter(([timestamp]) => !isWeekend(moment(timestamp))).map(([timestamp, value]) => [moment(timestamp), value]) || [];
            } else {
                data = s.data?.map(([timestamp, value]) => [moment(timestamp), value]) || [];
            }

            data.sort((a, b) => a[0].valueOf() - b[0].valueOf());

            if (timeView === TimeView.Weekly) {
                data = groupSeriesDataByWeek(trimSingleDays(data));
                anomalies = groupAnomaliesByWeek(trimNotifications(data, s.anomalies || props.anomalies));
                tickPositions = data.map(([date]) => date.valueOf());
            } else if (props.isIOInsightsChart) {
                anomalies = props.anomalies;
            } else {
                anomalies = (s.anomalies || props.anomalies);
            }

            if ((anomalies)?.length && !s.disableAnomaly) {
                chartSeries.push(
                    ...getAnomalySeries(
                        anomalies,
                        data,
                        {
                            color: s.color || chartColors[index],
                            name: s.name,
                            id: s.id || s.name,
                            type: s.type || chartType,
                            dashStyle: s.dashStyle,
                        },
                        ([date]) => ({ x: date.valueOf() }),
                        { formatterString: props.dateFormatStr, useIndex: false, fillMissingAbnormalData: props.fillMissingAbnormalData, includeWeekends: timeView !== TimeView.Daily }
                    )
                );

                hasAnomalies = true;
            } else {
                chartSeries.push({
                    type: s.type || chartType,
                    marker: {
                        symbol: "square",
                        enabled: data.length == 1,
                    },
                    color: s.color,
                    name: s.name,
                    id: s.id || s.name,
                    data: data.map(([date, value]) => [date.valueOf(), value]),
                    visible: true,
                    dashStyle: s.dashStyle,
                });
            }
        });
        return [chartSeries, hasAnomalies, tickPositions];
    }, [chartColors, chartType, props.anomalies, props.dateFormatStr, props.fillMissingAbnormalData, props.hideEmptySeries, props.ignoreWeekend, props.series, timeView]);

    if (hasAnomalies) {
        chartSeries.push(getAnomalyLegendPlaceholderSeries());
    }

    const isSinglePoint = !chartSeries.find((s) => ((s as Highcharts.SeriesLineOptions | Highcharts.SeriesAreaOptions).data?.length || 0) >= 2);

    const areaPlotOptions = useMemo<Partial<Highcharts.PlotAreaOptions>>(() => {
        const isSingleArea = props.showArea
            ? props.series.filter((val) => !val.type || val.type === "area").length === 1
            : props.series.filter((val) => val.type === "area").length === 1;

        const targetAreaSeriesIndex = props.showArea
            ? props.series.findIndex((s) => !s.type || s.type === "area")
            : props.series.findIndex((s) => s.type === "area");

        if (!isSingleArea || targetAreaSeriesIndex < 0) {
            return {
                fillOpacity: 0.2,
            };
        }

        const areaColor = props.series[targetAreaSeriesIndex].color || CommonConstants.DefaultColors[targetAreaSeriesIndex];

        return {
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                },
                stops: [
                    [0, Highcharts.color(areaColor).setOpacity(0.6).get("rgba").toString()],
                    [1, Highcharts.color(areaColor).setOpacity(0.3).get("rgba").toString()],
                ],
            },
        };
    }, [props.series, props.showArea]);

    const options: Highcharts.Options = {
        chart: {
            type: chartType,
            style: chartStyles,
            height: props.height,
            spacingLeft: 0,
        },
        title: {
            text: undefined,
        },
        credits: {
            enabled: false,
        },
        time: {
            useUTC: false,
            moment: moment,
        },
        tooltip: {
            ...chartTooltipOptions,
            shape: "square",
            shared: true,
            outside: true,
            padding: 0,
            formatter() {
                const abnormalEvents = getAnomaliesByDate(props.anomalies || [], moment(this.x), timeView);
                const visiblePoints = this.points?.filter((point) => !point.series.name.startsWith("hidden")) || [];

                if (!props.keepOrder) {
                    visiblePoints.sort((a, b) => b.y - a.y);
                }

                const costCardItems: ICostCardTooltipContentProps["items"] = visiblePoints.map((point) => {
                    const highchartSeriesOption = point.series.options as Highcharts.SeriesLineOptions | Highcharts.SeriesAreaOptions;
                    if (timeView === TimeView.Daily) {
                        const seriesOption = props.series.find((s) => s.name === highchartSeriesOption.name);
                        const validDateCount =
                            (!props.ignoreWeekend
                                ? seriesOption?.data?.length
                                : seriesOption?.data?.filter(([timestamp]) => !isWeekend(moment(timestamp))).length) || 0;
                        const avgValue = !seriesOption?.showDeviation || !validDateCount ? undefined : props.deviationType == "Median" ? props.medianValue : getAverageValue(seriesOption.data || []);


                        return {
                            title: point.series.name,
                            cost: point.y || 0,
                            color: (point.series as any).color,
                            deviationFrom: avgValue,
                            hasError: getAnomaliesByDate(seriesOption?.anomalies || [], moment(this.x), timeView).length > 0,
                        };
                    } else {
                        const seriesOption = chartSeries.find((s) => s.name === highchartSeriesOption.name) as Highcharts.SeriesLineOptions;
                        const sourceSeries = props.series.find(s => s.name === seriesOption.name);
                        
                        return {
                            title: point.series.name,
                            cost: point.y || 0,
                            color: (point.series as any).color,
                            deviationFrom: !sourceSeries?.showDeviation ? undefined : sum((seriesOption.data)?.map((d) => Array.isArray(d) ? d[1] : (d as Highcharts.PointOptionsObject).y) || []) / (seriesOption.data?.length || 1),
                            hasError: getAnomaliesByDate(sourceSeries?.anomalies || [], moment(this.x), timeView).length > 0,
                        };
                    }
                });

                return renderToString(
                    <CostCardTooltipContent
                        items={costCardItems}
                        date={timeView === TimeView.Weekly ? [moment(this.x), moment(this.x).add(6, "days")] : [moment(this.x)]}
                        suffix={props.suffix}
                        noPrefix={props.isNotCurrency}
                        formatter={props.tooltipYFormatter}
                        dateFormatStr={props.tooltipDateFormatStr}
                        errors={props.errors !== undefined && props.errors.has(this.x) ? props.errors.get(this.x)?.map((y) => { y.message = y.message.replace('\n', '<br />'); return y;}): abnormalEvents.map((event) => ({
                            title: event.category,
                            message: getAnomalyDescription(event.category, event.affectedMetrics, event.startDate, event.endDate, event.dataSourceName),
                        }))}
                        errorCollapseLimit={0}
                        totalText={
                            props.totalTextPrefix && this.points && this.points[1] && this.points[1].total ?
                            `${props.totalTextPrefix} (${currencyFormatter(this.points[1].total, 2, prefix)})` :
                            undefined
                        }
                    />
                );
            },
        },
        legend: {
            ...chartLegendOptions,
            enabled: !props.hideLegend,
            className: hasAnomalies ? styles.abnormalChartLegend : undefined,
        },
        xAxis: {
            type: timeView === TimeView.Weekly ? "category" : "datetime",
            labels: {
                formatter: timeView === TimeView.Weekly ?
                    function () {
                        return moment(this.value).format(props.dateFormatStr) + " - " + moment(this.value).add(6, "days").format(props.dateFormatStr);
                    } :
                    function () {
                        return moment(this.value).format(props.dateFormatStr);
                    },
            },
            minPadding: 0,
            maxPadding: 0,
            tickPositions: tickPositions,
            ...props.xAxis,
        },
        yAxis: {
            title: {
                text: undefined,
            },
            min: props.minValue,
            max: props.maxValue,
            labels: {
                formatter: function () {
                    if (typeof this.value === "string") {
                        return this.value;
                    }
                    return props.yFormatter ? props.yFormatter(this.value) : currencyFormatter(this.value, 2, prefix) + (props.suffix || "");
                },
            },
        },
        plotOptions: {
            line: {
                lineWidth: 4,
                marker: {
                    enabled: isSinglePoint,
                },
                connectNulls: true,
            },
            area: {
                lineWidth: 4,
                marker: {
                    enabled: isSinglePoint,
                },
                ...areaPlotOptions,
            },
            series: {
                events: {
                    legendItemClick: onChartSeriesLegendClick,
                },
                stacking: props.showArea || props.isStacking ? "normal" : undefined,
            },
        },
        series: chartSeries,
        colors: chartColors,
    };

    return <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" }, ...props.containerProps }} />;
};

LineChart.defaultProps = {
    dateFormatStr: "MM/DD",
    ignoreWeekend: true,
    minValue: 0,
};

export default React.memo(LineChart);
