import { Point, Series } from "highcharts";

export const chartStyles: Highcharts.CSSObject = {
    fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
    fontSize: "10px",
    fontWeight: "semibold",
};

export const chartTitleStyles: Highcharts.CSSObject = {
    fontFamily: "Segoe UI",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#323130",
};

export const chartTooltipOptions: Highcharts.TooltipOptions = {
    borderWidth: 0,
    borderRadius: 0,
    backgroundColor: "white",
    padding: 0,
    useHTML: true,
};

export const chartLegendOptions: Highcharts.LegendOptions = {
    align: "left",
    squareSymbol: true,
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 0,
    padding: 0,
    maxHeight: 64,
    margin: 18,
};

export const handleChartLegendItemClick = (index: number, series: Series[], points?: Point[]) => {
    const plots: Series[] | Point[] = points || series;

    if (plots[index].visible) {
        // Check if all other series are hidden, if so make all the series visible again.
        const isOthersVisible = plots.some((plot, i) => plot.name !== "Anomaly Detection" && !plot.name.startsWith("hidden_") && plot.visible && i !== index);

        if (!isOthersVisible) {
            // Make all the series visible again.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            plots.forEach((plot) => plot.setVisible(true));
        } else {
            // Hide all the series except the target series.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            plots.forEach((plot, i) => plot.setVisible(i === index));
        }
    } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        plots[index].setVisible(true);
    }
};

export const onChartSeriesLegendClick: Highcharts.SeriesLegendItemClickCallbackFunction = function () {
    handleChartLegendItemClick(this.index, this.chart.series);

    this.chart.series.find((item) => item.name == "Anomaly Detection")?.show();

    return false;
};

export const onChartPointLegendClick: Highcharts.PointLegendItemClickCallbackFunction = function () {
    handleChartLegendItemClick(this.index, this.series.chart.series, this.series.points);

    return false;
};
