import { FilterGroupOperator } from "./FilterListConstants";

export function match<T>(lval: T, operator: FilterGroupOperator, rval: T) {
    switch (operator) {
        case FilterGroupOperator.EQ: return lval === rval;
        case FilterGroupOperator.GT: return lval > rval;
        case FilterGroupOperator.GTE: return lval >= rval;
        case FilterGroupOperator.LT: return lval < rval;
        case FilterGroupOperator.LTE: return lval <= rval;
        case FilterGroupOperator.NE: return lval !== rval;
    }

    return false;
}
