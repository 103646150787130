import { ActionTypes } from "../actions/ActionTypes"
import { IUpdateGlobalFiltersNextAction } from "../actions/FilterActions"
import { Reducer } from "redux"

export enum FiltersView {
    Breadcrumb = "breadcrumb",
    Summary = "summary",
    AddableList = "addableList",
    List = "list",
}

export enum FiltersAction {
    Add,
    Replace
}

export const filterReducer: Reducer<FiltersAction, IUpdateGlobalFiltersNextAction> = function(state = FiltersAction.Replace, action): FiltersAction {
    if (action.type === ActionTypes.UpdateSearchFiltersNextAction) {
        return action.nextAction;
    } else {
        return state;
    }
}