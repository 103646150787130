import { Stack, Separator, Text } from "@fluentui/react";
import { DonutChart, IChartDataPoint } from '@fluentui/react-charting';
import { toPairs } from "lodash";
import * as React from "react";
import { useState} from "react";
import { getPCMMetricString, PCMMetric } from "../../../models/PCMMetrics";
import { ISubstrateTotalCostDistributionData } from "../../../models/SubstrateModels";
import styles from "./OverviewChart.less";
import { currencyFormatter } from "../../../utils/currency";
import CostCard from "../../common/costCard/CostCard";
import { scaleLinear } from "../../../utils/ChartUtils";
import { getAnomalyDescription, getAnomaliesByMetric } from "../../../utils/AnomalyDetectionUtils";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import CostCardTooltipContent from "../../common/costCard/CostCardTooltipContent";
import useEventBus from "../../../hooks/useEventBus";
import { EventTypeEnum, OpenNotificationPanelEvent } from "../../../models/Event";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useGotoTab } from "../../../hooks/useGotoPage";
import { useTrackHovering } from "../../../hooks/useTrack";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { DataSourceEnum } from "../../../models/NotificationModels";

const colors = ["#0099BC", "#982570", "#627CEF", "#C19C00", "#125D9A", "#972AA5", "#E650AF", "#0E7878", "#9372C0", "#9C663F", "#00758F", "#77004D", "#4661D5"];
const minBlockWidth = 120;

interface IOverviewChartProps {
    items?: ISubstrateTotalCostDistributionData;
    total: number;
}

const OverviewChart: React.FC<IOverviewChartProps> = (props) => {
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.Substrate);
    const anomalies = anomaliesQuery.data?.filter(item => item.status != 'Resolved') || [];
    const eventBus = useEventBus<OpenNotificationPanelEvent>(undefined, undefined, []);

    const trackChartHoveringProps = useTrackHovering(LogComponent.Substrate, LogElement.SubstrateOverviewDonutChart, "Substrate Costs", LogTarget.Chart);
    const trackCostBlockHoveringProps = useTrackHovering(LogComponent.Substrate, LogElement.SubstrateOverviewCostCards, "Substrate Cost Cards", LogTarget.Chart);

    const handleViewMoreAnormalyEvents = () => {
        eventBus.emit<OpenNotificationPanelEvent>(EventTypeEnum.OpenNotificationPanel, undefined);
    };
    const dateRangeFilterData = useDateRange();
    const [metricBlockWidth, setMetricBlockWidth] = useState<number>(minBlockWidth);
    const costMetricBlockContainerRef = React.useRef<HTMLDivElement>(null);
    const gotoTab = useGotoTab();

    const changeRoute = (title: string, cost: number) => {
        if (cost === 0) {
            return
        }
        let keyParam = ""
        let destination = "/Substrate"
        const transactionArray: Array<string> = ["Service Instance", "Requests Processed", "DSAPI Requests"
                                                , "CFM Submitted"];
        const ssdArray: Array<string> = ["Item Size", "SDS Fast Storage", "KvCache"];
        if (title === "Item Reads" || title === "Item Writes" || title === "Item Queries")
        {
            destination += "/Transaction"
            keyParam = "Item Reads, Writes and Queries"
        }
        else if (title === "TBA" || title === "EBA" || title === "SWSS")
        {
            destination += "/Transaction"
            keyParam = "Assistants"
        }
        else if (transactionArray.includes(title))
        {
            destination += "/Transaction"
            keyParam = title
        }
        else if (ssdArray.includes(title))
        {
            destination += "/SSD"
            keyParam = title
        }
        else
        {
            return;
        }
        gotoTab(destination, keyParam, destination);
    };

    const getCostByKey = (key?: string) => {
        if (!props.items || !key) return 0;
        return props.items.cost[key as PCMMetric] || 0;
    }

    // update block width when content changes
    React.useEffect(() => {
        if (!costMetricBlockContainerRef.current) return;

        let maxElemWidth = 0;

        costMetricBlockContainerRef.current.querySelectorAll('.hidden-metric-block').forEach((block) => {
            maxElemWidth = Math.max(maxElemWidth, block.getBoundingClientRect().width);
        });

        setMetricBlockWidth(Math.max(minBlockWidth, maxElemWidth));
    }, [props.items]);

    const points: IChartDataPoint[] = React.useMemo(() => {
        const data = toPairs(props.items?.cost).map(([costKey, costValue], index) => ({
            legend: getPCMMetricString(costKey as PCMMetric),
            data: costValue,
            yAxisCalloutData: currencyFormatter(costValue || 0, 2, "$"),
            originData: costValue,
            xAxisCalloutData: costKey,
            color: colors[index],
        })).filter(item => item.data > 0);

        const scaledValues = scaleLinear(data.map(item => item.data), [1, 49]);

        for (let i = 0; i < scaledValues.length; ++i) {
            data[i].data = scaledValues[i];
        }

        return data;
    }, [props.items]);

    return (
        <>
        <Separator styles={{root: styles.separator }} />
        <Text block className={styles.totalCostText}>Total Substrate and Metric Cost</Text>
        <Stack className={styles.overviewChart} horizontal verticalAlign="center">
            <div className={styles.metricBlockContainer}>
                <div className={styles.metricBlockContainerTitle}><span style={{color: "#0078D4"}}>{currencyFormatter(props.total)}</span> Total Substrate Cost</div>
                <div className={`${styles.metricBlockList} ${styles.metricBlockHiddenList}`} ref={costMetricBlockContainerRef}>
                    {
                        toPairs(props.items?.cost).map(([costKey, costValue], index) => (
                            <div className={`${styles.metricBlock} hidden-metric-block`} key={costKey}>
                                <CostCard title={getPCMMetricString(costKey as PCMMetric)} cost={costValue} color={index} />
                            </div>
                        ))
                    }
                </div>
                <div className={styles.metricBlockList} {...trackCostBlockHoveringProps}>
                    {
                        toPairs(props.items?.cost).map(([costKey, costValue], index) => (
                            <div
                                onClick={() => changeRoute(getPCMMetricString(costKey as PCMMetric), costValue)}
                                style={{ width: metricBlockWidth }}
                                className={styles.metricBlock}
                                key={costKey}
                            >
                                <CostCard
                                    title={getPCMMetricString(costKey as PCMMetric)}
                                    cost={costValue}
                                    color={index}
                                    date={[dateRangeFilterData.startDate, dateRangeFilterData.endDate]}
                                    errors={
                                        getAnomaliesByMetric(anomalies, costKey)
                                            .map(event => ({
                                                title: event.category,
                                                message: getAnomalyDescription(event.category, [getPCMMetricString(costKey as PCMMetric)], event.startDate, event.endDate),
                                            }))
                                    }
                                    onShowMoreError={handleViewMoreAnormalyEvents}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className={styles.donutChartContainer} {...trackChartHoveringProps}>
                <DonutChart
                    showXAxisLablesTooltip
                    onRenderCalloutPerDataPoint={(dataPoint) => (
                        <CostCardTooltipContent
                            items={[{ title: dataPoint?.legend || '', cost: getCostByKey(dataPoint?.xAxisCalloutData), color: dataPoint?.color || 0 }]}
                            date={[dateRangeFilterData.startDate, dateRangeFilterData.endDate]}
                            errors={getAnomaliesByMetric(anomalies, dataPoint?.xAxisCalloutData || '').map(event => ({
                                title: event.category,
                                message: getAnomalyDescription(event.category, dataPoint?.xAxisCalloutData ? [getPCMMetricString(dataPoint?.xAxisCalloutData as PCMMetric)] : [], event.startDate, event.endDate),
                            }))}
                            onShowMoreError={handleViewMoreAnormalyEvents}
                        />
                    )}
                    hideLegend
                    innerRadius={60}
                    height={220}
                    width={176}
                    data={{ chartData: points }}
                />
            </div>
        </Stack>
        </>
    );
}

export default OverviewChart;