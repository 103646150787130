import React from "react";
import { ESCostDataDimensions, ESCostOrganization, ESMonthToMonthCostTableRawResponse } from "../../../../models/ESCostModels";
import { Column, TableInstance, TableOptions, UseExpandedInstanceProps, UseSortByOptions, useExpanded, useSortBy, useTable } from "react-table";
import { useEsOrg } from "../../../../hooks/useEsOrg";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import moment from "moment";
import DataTable, { ITableRow, LastMonthAccessor, MonthBeforeLastAccessor, RelativeChangeAccessor } from "./DataTable";
import { ESOrgs } from "../../../../models/constants/ESCostConstants";
import { TableColumnRenderer } from "../../../common/table/TableColumnRenderer";

interface ISimpleTableProps {
    data: ESMonthToMonthCostTableRawResponse[];
    description: string;
    splitDimension: ESCostDataDimensions.Repository | ESCostDataDimensions.Service;
    targetDimension: ESCostDataDimensions.ArtifactWorkload | ESCostDataDimensions.Cost;
    maxCount?: number;
    isCurrency?: boolean;
}

function filterAndChangeToTableData(
    rawData: ESMonthToMonthCostTableRawResponse[],
    org: ESCostOrganization[],
    splitDimension: ESCostDataDimensions.Repository | ESCostDataDimensions.Service,
    targetDimension: ESCostDataDimensions.ArtifactWorkload | ESCostDataDimensions.Cost,
): ITableRow[] {
    const d = rawData.filter(
        (data) => ESOrgs.includes(data[ESCostDataDimensions.Organization]) && (org.length === 0 || org.includes(data[ESCostDataDimensions.Organization]))
    );
    const dates = ESCostUtils.getMonthToMonthDates().map((d) => d.format("YYYYMMDD"));
    const tempData = {} as { [key: string]: { [key: string]: number } };
    d.forEach((data) => {
        const t = moment(data[ESCostDataDimensions.TimeStamp]).date(1).format("YYYYMMDD");
        if (dates.includes(t)) {
            const repo = data[splitDimension] ?? "";
            if (!(repo in tempData)) {
                tempData[repo] = {};
            }
            tempData[repo][t] = data[targetDimension] ?? 0;
        }
    });
    const tableData: ITableRow[] = [];
    Object.keys(tempData).forEach((repo) => {
        const obj = tempData[repo];
        const row: ITableRow = {
            [splitDimension]: repo,
            [MonthBeforeLastAccessor]: obj[dates[0]] ?? 0,
            [LastMonthAccessor]: obj[dates[1]] ?? 0,
            [RelativeChangeAccessor]: ESCostUtils.getRelativeChange(obj[dates[0]], obj[dates[1]]),
        };
        tableData.push(row);
    });
    tableData.sort((a, b) => b[LastMonthAccessor] - a[LastMonthAccessor]);
    return tableData;
}

const MonthToMonthCostTable: React.FC<ISimpleTableProps> = (props) => {
    const [org] = useEsOrg();
    const tableData: ITableRow[] = React.useMemo(() => {
        const d = filterAndChangeToTableData(props.data, org, props.splitDimension, props.targetDimension);
        return props.maxCount !== undefined && props.maxCount > 0 ? d.slice(0, props.maxCount) : d;
    }, [org, props.data, props.maxCount, props.splitDimension, props.targetDimension]);
    const months = React.useMemo(() => ESCostUtils.getMonthToMonthDates().map((d) => d.format("MMM")), []);
    const columns = React.useMemo<Column<ITableRow>[]>(
        () => [
            { Header: props.splitDimension, accessor: props.splitDimension },
            {
                Header: months[0],
                accessor: MonthBeforeLastAccessor,
                Cell: (cell) => (props.isCurrency === false ? TableColumnRenderer.renderNumber(cell.value) : TableColumnRenderer.renderCurrency(cell.value)),
            },
            {
                Header: months[1],
                accessor: LastMonthAccessor,
                Cell: (cell) => (props.isCurrency === false ? TableColumnRenderer.renderNumber(cell.value) : TableColumnRenderer.renderCurrency(cell.value)),
            },
            { Header: "Relative change", accessor: RelativeChangeAccessor },
        ],
        [props.isCurrency, props.splitDimension, months]
    );
    const tableInstance: TableInstance<ITableRow> = useTable(
        {
            data: tableData,
            columns,
        } as TableOptions<ITableRow> & UseSortByOptions<ITableRow>,
        useSortBy,
        useExpanded
    ) as TableInstance<ITableRow> & UseExpandedInstanceProps<ITableRow>;

    return <DataTable tableInstance={tableInstance} description={props.description} maxCount={props.maxCount} />;
};

export default MonthToMonthCostTable;
