import {
    ChoiceGroup,
    DefaultButton,
    Dialog,
    DialogType,
    IButtonStyles,
    IChoiceGroupOption,
    IDialog,
    IPanel,
    IPanelStyles,
    Panel,
    PanelType,
    PrimaryButton,
    TextField,
} from "@fluentui/react";
import { FiltersAction, FiltersView } from "../../../reducers/filterReducer";
import { IServiceTreeData, getCategoryByServiceTreeLevel } from "../../../reducers/serviceTreeReducer";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { ServiceTree, ServiceTreeItem } from "../../../models/serviceTree";
import { getServiceCount, getServiceItemListByKeys, getViewItemKeyFromFilterCategory } from "../../../utils/FiltersUtils";
import { useDispatch, useSelector } from "react-redux";

import { CategoryDivision } from "../../../models/CategoryDivision";
import { DataNode } from "../../../models/DataNode";
import { IAppState } from "../../../store";
import { IPartialFilterViewContent } from "../../../models/FilterView";
import TreeSelect from "../../TreeSelect";
import { ViewInputValidator } from "../../../validators/ViewInputValidators";
import { createView } from "../../../services/viewService";
import { entries } from "lodash";
import { getServiceString } from "../../../reducers/SearchUtils";
import { makeSaveViewFailDialogAction } from "../../../reducers/dialogsReducer";
import { makeUpdateServiceTreePanelRefAction } from "../../../reducers/actionableItemsReducer";
import { loadAllViewsIfNeeded, shareViewAction } from "../../../reducers/savedViewReducer";
import styles from "./ShareView.less";
import { useBoolean } from "@fluentui/react-hooks";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useFlights } from "../../../hooks/useSettings";
import { Flight } from "../../../models/Flights";

interface ShareViewSubmitDialog {
    updateStep: Dispatch<SetStateAction<number>>;
}

const panelWidthSetting: Record<string, string> = {
    ["Init"]: "550px",
    ["OpenEdit"]: "1000px",
};

const ShareViewSubmitDialog: React.FC<ShareViewSubmitDialog> = (props) => {
    const { updateStep } = props;
    const buttonStyles: IButtonStyles = { root: { marginRight: 16 } };
    const [internalSelectedItems, setInternalSelectedItems] = useState<ServiceTreeItem[] | undefined>(undefined);

    const globalFilters = useCategoryFilters();
    const filtersNextAction = useSelector<IAppState, FiltersAction>((state) => state.filtersNextAction);

    const handleServicesChange = useCallback(
        (items: ServiceTreeItem[]) => {
            if (items.length) {
                const filters: Partial<Record<CategoryDivision, string[]>> = {};
                items.forEach((item) => {
                    const category = getCategoryByServiceTreeLevel(item.l);
                    if (!filters[category]) {
                        filters[category] = [];
                    }
                    filters[category]?.push(item.id);
                });
                globalFilters.updateFilters(filtersNextAction, { filters, view: FiltersView.Summary });

                return filters;
            }
        },
        [filtersNextAction, globalFilters]
    );

    const dispatch = useDispatch();

    const serviceTreePanelRef = React.useRef<IPanel>(null);
    useEffect(() => serviceTreePanelRef.current?.dismiss(), []);
    useEffect(() => {
        dispatch(makeUpdateServiceTreePanelRefAction(serviceTreePanelRef.current));
    }, [dispatch]);
    function createTreeData(serviceTree: ServiceTree | undefined): DataNode[] {
        function getValue(item: ServiceTreeItem) {
            return item.id === "00000000-0000-0000-0000-000000000000" ? `${item.id}(${item.l})` : item.id;
        }

        function createDataNodes(items: ServiceTreeItem[] | undefined): DataNode[] {
            if (!items) {
                return new Array<DataNode>(0);
            }
            const nodes = new Array<DataNode>(items.length);
            for (let i = 0; i < items.length; ++i) {
                const value = getValue(items[i]);
                nodes[i] = { title: items[i].n, key: value, value, data: items[i] };
                const children = items[i].c;
                if (children) {
                    nodes[i].children = createDataNodes(children);
                }
            }
            return nodes;
        }

        return createDataNodes(serviceTree?.items);
    }
    const { serviceTree: data } = useSelector<IAppState, IServiceTreeData>((state) => state.serviceTree);

    const globalSelectedServices = useMemo(() => {
        return getServiceItemListByKeys(globalFilters.filters.filters, data?.items);
    }, [data?.items, globalFilters.filters.filters]);
    const selectedIds = useMemo(() => globalSelectedServices.map((s) => s.id), [globalSelectedServices]);

    const treeData = useMemo(() => createTreeData(data), [data]);
    const [internalSelectedIds, setInternalSelectedIds] = useState<string[]>(selectedIds);
    const handleChange = useCallback((values: string[], nodes: DataNode[]) => {
        setInternalSelectedIds(values);
        setInternalSelectedItems(nodes.map((x) => x.data));
    }, []);

    const options: IChoiceGroupOption[] = [
        { key: "ServiceTree", text: "Service Tree" },
        { key: "OrganizationLeader", text: "Organization Leader" },
    ];

    const apply = useCallback(() => {
        updateStep(0);
        if (internalSelectedItems) {
            handleServicesChange(internalSelectedItems);
        }
    }, [internalSelectedItems, handleServicesChange]);

    const dateRangeFilterData = useDateRange();
    const [viewName, setViewName] = useState('');

    const onShareViewCallout = function () {
        updateStep(0);
        dispatch(shareViewAction(dateRangeFilterData, buildViewFromFilters(globalFilters.filters.filters)));
    };

    const [viewNameError, setViewNameError] = useState(false);
    const [saveViewCalloutVisible, {setFalse: dismissSaveViewCallout, toggle: toggleSaveViewCallout}] = useBoolean(false);

    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: "Create a new custom view",
        styles: {
            innerContent: {height: 500}
        }
    };
    const { data: flights } = useFlights();


    const onSaveView = useCallback(() => {
        if (!ViewInputValidator.validateViewName(viewName)) {
            setViewNameError(true);
            return;
        }
        updateStep(0);

        // trackEventCallback(LogComponent.SaveViewDial, LogElement.Save, "Save", LogTarget.Button);
        setViewNameError(false);
        createView(viewName, buildViewFromFilters(globalFilters.filters.filters)).then(async (response) => {
            if (response.ok) {
                dispatch(loadAllViewsIfNeeded(flights as Flight));
                return Promise.resolve();
            } else {
                const message = response.text();
                return Promise.reject(message);
            }

        }).catch((exception) => {
            exception.then((message: string | undefined) => {
                dispatch(makeSaveViewFailDialogAction(true, message));
            })
        }).finally(() => {
            setViewName('');
            setViewNameError(false);
            dismissSaveViewCallout();
        });
        dispatch(shareViewAction(dateRangeFilterData, buildViewFromFilters(globalFilters.filters.filters)));
        
    }, [viewName, globalFilters.filters.filters, dispatch, dismissSaveViewCallout]);

    function buildViewFromFilters(filters: Partial<Record<CategoryDivision, string[]>>): IPartialFilterViewContent {
        const filterView: IPartialFilterViewContent = {};
        console.log(filters);
        entries(filters).forEach(([category, value]) => {
            if (!value || value.length === 0) {
                return;
            }

            const key: keyof IPartialFilterViewContent = getViewItemKeyFromFilterCategory(category as CategoryDivision);
            if (key === "Subscription") {
                filterView.Subscription = value.map((filter) => filter.substring(0, filter.indexOf("(")));
            } else {
                filterView[key] = [...value];
            }
        });

        return filterView;
    }
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);

    const totalCount = getServiceCount(globalFilters.filters.filters, serviceIdMap);

    return (
        <Dialog hidden={false} modalProps={{containerClassName: styles.dialogContainer}} maxWidth={1000} dialogContentProps={dialogContentProps}>
            <span className={styles.summaryText}>{`${getServiceString(totalCount)} Selected`}</span>
            <TextField label="View name" value={viewName} placeholder="Please enter text here" onChange={(_, value) => setViewName(value || '')} required/>
            <button className={styles.dialogButton} onClick={() => updateStep(2)}>
                Previous
            </button>
            <button className={styles.dialogButton} onClick={onSaveView}>
                Submit
            </button>
        </Dialog>
    );
};

export default ShareViewSubmitDialog;
