// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XaeL1CSIhfajepH_7pdE {\n  padding-top: 5px;\n  width: 280px;\n  background-Color: #E9E9E9;\n  flex: 0 0 10%;\n  height: 100%;\n  box-sizing: border-box;\n  border: 1px solid #E1E1E1;\n  overflow-y: auto;\n}\n.ik0f1XaIO8Wh5xjt14mv {\n  background-color: #C8C8C8;\n  margin: 0 16px;\n  height: 1px;\n}\n.vwoSsIBTXGrTDq7uQ7QH {\n  height: 48px;\n  line-height: 48px;\n  margin-left: 16px;\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ESCost/ESNavigation.less"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,YAAA;EACA,yBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,yBAAA;EACA,gBAAA;AADJ;AAIA;EACI,yBAAA;EACA,cAAA;EACA,WAAA;AAFJ;AAKA;EACI,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;AAHJ","sourcesContent":["@Navigation: \"../Navigation.less\";\n\n.navigation {\n    padding-top: 5px;\n    width: 280px;\n    background-Color: #E9E9E9;\n    flex: 0 0 10%;\n    height: 100%;\n    box-sizing: border-box;\n    border: 1px solid #E1E1E1;\n    overflow-y: auto;\n}\n\n.separator {\n    background-color: #C8C8C8;\n    margin: 0 16px;\n    height: 1px;\n}\n\n.groupHeader {\n    height: 48px;\n    line-height: 48px;\n    margin-left: 16px;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": "XaeL1CSIhfajepH_7pdE",
	"separator": "ik0f1XaIO8Wh5xjt14mv",
	"groupHeader": "vwoSsIBTXGrTDq7uQ7QH"
};
export default ___CSS_LOADER_EXPORT___;
