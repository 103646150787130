import { useMemo } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { useSelector } from "react-redux";
import { CategoryDivision } from "../models/CategoryDivision";
import { IDateRangeFilterData } from "../models/FilterView";
import { ISubstrateCostByServiceGriffinProcessor, ISubstrateDailyMetrics, ISubstrateMonthOnMonthData, ISubstrateDailyCarbonEmission, ISubstrateRingData, ISubstrateTopComponents } from "../models/SubstrateModels";
import { IRingData } from "../reducers/ringReducer";
import { getDailyMetrics, getDailySubstrateCarbonEmission, getScenarioTagCost, getSubstrateCostByService, getSubstrateMonthONMonth, getSubstrateRingCost, getSubstrateTopFiveApp, getSubstrateTopFiveComponents } from "../services/substrateCostService";
import { IAppState } from "../store";
import { Endpoints } from "../utils/Constants";
import { useDateRange } from "./useDateSelector";
import { useCategoryFilters } from "./useFilters";
import { IDailyMetricReport } from "../models/Metric"
import { ServiceTreeItem } from "../models/serviceTree";
import { PCMv2UnitPrice } from "../models/PcmV2Models";
import { SubstrateV2Service } from "../services/SubstrateV2Service";

export function useGetDailyMetricsQuery(isEnabled = true): UseQueryResult<ISubstrateDailyMetrics, Error> {
    const dateRangeFilterData = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        ISubstrateDailyMetrics,
        Error,
        ISubstrateDailyMetrics,
        [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, boolean]>([Endpoints.GetDailyMetrics, dateRangeFilterData, filtersData.filters, serviceIdMap, isEnabled], getDailyMetrics);
}

export function useGetSubstrateCostByService(selectTab?: string): UseQueryResult<ISubstrateCostByServiceGriffinProcessor, Error> {
    const dateRangeFilterData = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    
    const [orderType, queryKey] = useMemo(() => {
        switch(selectTab) {
            case "Item Reads, Writes and Queries":
                return ["itemRWQCost", Endpoints.GetTransactionItemRWQCost];
            case "Assistants":
                return ["assistantCost", Endpoints.GetTransactionAssistantCost];
            case "Service Instance":
                return ["serviceInstanceCost", Endpoints.GetTransactionServiceInstanceCost];
            case "Requests Processed":
                return ["requestsProcessedCost", Endpoints.GetTransactionRequestsProcessedCost];
            case "DSAPI Requests":
                return ["dsapiRequestsCountCost", Endpoints.GetTransactionDsapiRequestsCountCost];
            case "CFM Submitted":
                return ["cfmSubmittedCountCost", Endpoints.GetTransactionCFMSubmittedCountCost];
            case "SDS Fast Storage":
                return ["llcSizeGbCost", Endpoints.GetSsdLlcSizeGbCost];
            case "KvCache":
                return ["kvCacheSizeGbCost", Endpoints.GetSsdKvCacheSizeGbCost];
            case "Item Size":
                return ["itemSizeCost", Endpoints.GetSsdItemSizeCost];
            default:
                return ["SubstrateCost", Endpoints.GetSubstrateCostByService];
        }
    }, [selectTab]);

    return useQuery<
        ISubstrateCostByServiceGriffinProcessor,
        Error,
        ISubstrateCostByServiceGriffinProcessor,
        [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string]>([queryKey, dateRangeFilterData, filtersData.filters, serviceIdMap, orderType], getSubstrateCostByService);
}

export function useGetSubstrateRingCost(): UseQueryResult<ISubstrateRingData, Error> {
    const dateRangeFilterData = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        ISubstrateRingData,
        Error,
        ISubstrateRingData,
        [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>]>([Endpoints.GetSubstrateRingCost, dateRangeFilterData, filtersData.filters, serviceIdMap], getSubstrateRingCost);
}

export function useGetSubstrateMonthONMonth(): UseQueryResult<ISubstrateMonthOnMonthData, Error> {
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        ISubstrateMonthOnMonthData,
        Error,
        ISubstrateMonthOnMonthData,
        [string, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>]>([Endpoints.GetSubstrateMonthONMonth, filtersData.filters, serviceIdMap], getSubstrateMonthONMonth);
}

export function useGetSubstrateDailyCarbonEmission(): UseQueryResult<IDailyMetricReport, Error> {
    const dateRangeFilterData = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        IDailyMetricReport,
        Error,
        IDailyMetricReport,
        [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>]>([Endpoints.GetSubstrateDailyCarbonEmission, dateRangeFilterData, filtersData.filters, serviceIdMap], getDailySubstrateCarbonEmission);
}

export function useGetPCMV2Unitprice() {
    return useQuery<
        PCMv2UnitPrice,
        Error,
        PCMv2UnitPrice,
        [string]
    >([Endpoints.GetPCMV2Unitprice], SubstrateV2Service.getPCMV2Unitprice);
}

export function useGetSubstrateTopFiveComponents(metric?: string, n = 5): UseQueryResult<ISubstrateTopComponents, Error> {
    const dateRangeFilterData = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        ISubstrateTopComponents,
        Error,
        ISubstrateTopComponents,
        [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string?, number?]>([Endpoints.GetSubstrateTopFiveComponents, dateRangeFilterData, filtersData.filters, serviceIdMap, metric, n], getSubstrateTopFiveComponents);
}

export function useGetSubstrateTopFiveApp(metric?: string, n = 10): UseQueryResult<ISubstrateTopComponents, Error> {
    const dateRangeFilterData = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        ISubstrateTopComponents,
        Error,
        ISubstrateTopComponents,
        [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string?, number?]>([Endpoints.GetSubstrateTopFiveApp, dateRangeFilterData, filtersData.filters, serviceIdMap, metric, n], getSubstrateTopFiveApp);
}

export function useGetScenarioTagCost(): UseQueryResult<ISubstrateCostByServiceGriffinProcessor, Error> {
    const dateRangeFilterData = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const ring = useSelector<IAppState, IRingData>(state => state.ring);
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        ISubstrateCostByServiceGriffinProcessor,
        Error,
        ISubstrateCostByServiceGriffinProcessor,
        [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string]>([Endpoints.GetScenarioTagCost, dateRangeFilterData, filtersData.filters, serviceIdMap, ring.ring || "All"], getScenarioTagCost);
}