import { ActionButton, Icon, Spinner, SpinnerSize, TooltipHost } from "@fluentui/react";
import { Cell, HeaderGroup, Row, UseExpandedRowProps } from "react-table";
import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import { ReactTableColumnOption, ReactTableExpandableData, ReactTableRow, ReactTableInstance } from "./TableUtils";

import EmptyState from "../state/EmptyState";
import styles from "./StickyTable.less";
import { useFrameState } from "../../../hooks/useFrameState";
import { TableRowExpander } from "./TableRowExpander";

export interface IStickyPosition {
    offsetTop?: number;
    offsetBottom?: number;
}

export interface IStickyTableHeaderOption {
    props: React.HTMLProps<HTMLTableHeaderCellElement>;
    tooltip?: string | JSX.Element | JSX.Element[];
}

export interface IStickyTableCellOption {
    props?: React.HTMLProps<HTMLTableCellElement>;
}

export interface IStickyTableFooterOption {
    props?: React.HTMLProps<HTMLTableCellElement>;
}

export interface IStickyTableProps {
    table: ReactTableInstance<any>;
    stickyPositon?: {
        header?: IStickyPosition;
        footer?: IStickyPosition;
    };

    stickyTarget?: string;

    cellOption?: (cell: Cell) => IStickyTableCellOption;
    headerOption?: (header: HeaderGroup) => IStickyTableHeaderOption;
    footerOption?: (footer: HeaderGroup) => IStickyTableFooterOption;

    styles?: {
        header?: string;
        footer?: string;
        body?: string;
        headerContainer?: string;
        footerContainer?: string;
        bodyContainer?: string;
        container?: string;
        stickyFooterContainer?: string;
    };

    loading?: boolean;
    loadingText?: string;

    onLoadMore?: () => void;
    loadMore?: boolean;
    loadMoreText?: string;
    loadMoreLoading?: boolean;
    loadMoreLoadingText?: string;

    emptyText?: string;

    renderRow?: (row: Row<any> & UseExpandedRowProps<any>) => React.ReactNode;

    footerVisible?: boolean;

    expandable?: boolean;
    onExpanded?: (row: ReactTableRow<ReactTableExpandableData<any>>, isExpanded: boolean) => void;

    onSort?: (columnId: string, isDesc: boolean) => void;
}

function getCellsWidthList(elems: NodeListOf<HTMLElement>) {
    const widthList: number[] = [];

    elems.forEach((e) => {
        widthList.push(e.getBoundingClientRect().width);
    });

    return widthList;
}

const StickyTable: React.FC<IStickyTableProps> = (props: IStickyTableProps) => {
    const { table: tableInstance } = props;
    const bodyElementRef = useRef<HTMLDivElement>(null);
    const headerElementRef = useRef<HTMLDivElement>(null);
    const footerElementRef = useRef<HTMLDivElement>(null);
    const bodyTableElementRef = useRef<HTMLTableElement>(null);
    const scrollBarElementRef = useRef<HTMLDivElement>(null);
    const scrollBarPlaceholderElementRef = useRef<HTMLTableElement>(null);
    const lastScrollLeft = useRef(0);
    const [setScrollTarget, getScrollTarget] = useFrameState<HTMLDivElement>();

    const isEmpty = !props.loading && !props.loadMoreLoading && !props.table.data.length;

    const handleScroll = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
        const currentScrollTarget = getScrollTarget();

        // prevent scroll event triggered by forceScroll
        if (!currentScrollTarget || currentScrollTarget == event.target) {
            if (lastScrollLeft.current === event.currentTarget.scrollLeft) return;

            lastScrollLeft.current = event.currentTarget.scrollLeft;

            setScrollTarget(event.currentTarget);

            forceScroll(event.currentTarget.scrollLeft, bodyElementRef.current);
            forceScroll(event.currentTarget.scrollLeft, headerElementRef.current);
            forceScroll(event.currentTarget.scrollLeft, footerElementRef.current);
            forceScroll(event.currentTarget.scrollLeft, scrollBarElementRef.current);
        }
    }, []);

    const forceScroll = (scrollLeft: number, element: HTMLDivElement | null) => {
        if (!element || getScrollTarget() == element || element.scrollLeft == scrollLeft) return;

        element.scrollLeft = scrollLeft;
    };

    const syncTableColumnWidth = () => {
        if (!bodyTableElementRef.current) return;

        const cellsWidth: number[] = getCellsWidthList(bodyTableElementRef.current.querySelectorAll("th"));

        if (headerElementRef.current) {
            headerElementRef.current.querySelectorAll("th").forEach((cell, index) => {
                cell.style.width = `${cellsWidth[index]}px`;
            });
        }
        if (footerElementRef.current) {
            footerElementRef.current.querySelectorAll("td").forEach((cell, index) => {
                cell.style.width = `${cellsWidth[index]}px`;
            });
        }

        if (scrollBarPlaceholderElementRef.current) {
            if (footerElementRef.current) {
                scrollBarPlaceholderElementRef.current.style.width = `${footerElementRef.current.scrollWidth}px`;
            } else if (headerElementRef.current) {
                scrollBarPlaceholderElementRef.current.style.width = `${headerElementRef.current.scrollWidth}px`;
            }
        }
    };

    // initialize
    useEffect(() => {
        if (!bodyTableElementRef.current || !headerElementRef.current) return;

        const tableResizeObserver = new ResizeObserver(syncTableColumnWidth);
        const tableHeaderMutationObserver = new MutationObserver(() => {
            tableResizeObserver.disconnect();

            bodyTableElementRef.current?.querySelectorAll("th").forEach((elem) => tableResizeObserver.observe(elem));
        });

        bodyTableElementRef.current.querySelectorAll("th").forEach((elem) => tableResizeObserver.observe(elem));
        headerElementRef.current.querySelectorAll("tr").forEach((headerRow) => tableHeaderMutationObserver.observe(headerRow, { childList: true }));

        return () => {
            tableResizeObserver.disconnect();
            tableHeaderMutationObserver.disconnect();
        };
    }, [bodyTableElementRef.current, headerElementRef.current]);

    useEffect(() => {
        if (!isEmpty) {
            syncTableColumnWidth();
        }
    }, [isEmpty]);

    useEffect(() => {
        if (props.loadMoreLoading && props.stickyTarget) {
            document.querySelector(props.stickyTarget)?.scrollBy({
                top: Number.MAX_SAFE_INTEGER,
            });
        }
    }, [props.loadMoreLoading]);

    // render methods
    const renderHeader = () => {
        return (
            <thead className={props.styles?.header}>
                {tableInstance.headerGroups.map((group) => (
                    /* eslint-disable react/jsx-key */
                    <tr {...group.getHeaderGroupProps()}>
                        {group.headers.map((header, index) => {
                            const currentColumn: ReactTableColumnOption<any> | undefined = props.table.columns.find((col) => col.id === header.id);
                            const headerOption = props?.headerOption ? props?.headerOption(header) : null;
                            const elemProps = {
                                ...header.getHeaderProps(header.getSortByToggleProps()),
                                ...currentColumn?.columnProps,
                                ...(headerOption?.props || {}),
                                ...currentColumn?.headerProps,
                            };
                            const tooltip = currentColumn?.headerTooltip || headerOption?.tooltip;

                            elemProps.className = (elemProps.className || "") + " " + (index ? styles.headerCol : styles.stickyCol);
                            if (header.isSorted) {
                                elemProps.className += " " + styles.active;
                            }

                            return (
                                <th
                                    {...elemProps}
                                    data-index={index}
                                    onClick={() => {
                                        header.toggleSortBy(!header.isSortedDesc, false);
                                        props.onSort && props.onSort(header.id, !header.isSortedDesc);
                                    }}>
                                    {header.render("Header")}
                                    {tooltip ? (
                                        <TooltipHost styles={{ root: styles.tooltipIcon }} content={tooltip}>
                                            <Icon iconName="Info" />
                                        </TooltipHost>
                                    ) : null}
                                    {header.isSorted && <Icon iconName={header.isSortedDesc ? "SortDown" : "SortUp"}></Icon>}
                                    <div className={styles.resizer} onMouseDown={onMouseDownOnResizer}>
                                        <div className={styles.resizerLine} />
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                    /* eslint-disable react/jsx-key */
                ))}
            </thead>
        );
    };

    const renderContent = (renderRow?: (row: ReactTableRow<any>) => ReactNode) => {
        return (
            <tbody className={props.styles?.body}>
                {(tableInstance.rows as ReactTableRow<any>[]).map((row) => {
                    tableInstance.prepareRow(row);

                    if (renderRow) {
                        return <tr {...row.getRowProps()}>{renderRow(row)}</tr>;
                    }

                    if (props.expandable && (row as ReactTableRow<ReactTableExpandableData<any>>).original.isSubRowPlaceHolder) {
                        return null;
                    }

                    /* eslint-disable react/jsx-key,@typescript-eslint/ban-ts-comment */
                    return (
                        <>
                            <tr data-depth={row.depth || 0} {...row.getRowProps()}>
                                {row.cells.map((cell, cellIndex) => {
                                        const elemProps = {
                                            ...cell.getCellProps(),
                                            ...(cell.column as ReactTableColumnOption<any>)?.columnProps,
                                            ...props.cellOption?.call(null, cell)?.props,
                                            ...(cell.column as ReactTableColumnOption<any>)?.cellProps,
                                        };

                                        if (cellIndex == 0) {
                                            elemProps.className += " " + styles.stickyCol;
                                        }

                                        return (
                                            // @ts-ignore
                                            <td column={cell.column.id} {...elemProps}>
                                                    {props.expandable && cellIndex === 0 && <TableRowExpander onExpanded={props.onExpanded} row={row as ReactTableRow<ReactTableExpandableData<any>>} />}
                                                    {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                            </tr>
                            {props.expandable &&  (row as ReactTableRow<ReactTableExpandableData<any>>).original.isSubRowsLoading && (
                                <div>
                                    <Spinner style={{ padding: "8px 0" }} className={styles.spinner} size={SpinnerSize.small} />
                                </div>
                            )}
                        </>);
                })}
            </tbody>
        );
    };

    const renderFooter = () => {
        return (
            <tfoot className={props.styles?.footer}>
                {/* eslint-disable react/jsx-key */}
                {tableInstance.footerGroups.map((group) => (
                    <tr {...group.getFooterGroupProps()}>
                        {group.headers.map((item, cellIndex) => {
                            const currentColumn: ReactTableColumnOption<any> | undefined = props.table.columns.find((col) => col.id === item.id);
                            const elemProps = {
                                ...item.getFooterProps(),
                                ...currentColumn?.columnProps,
                                ...props.footerOption?.call(null, item)?.props,
                                ...currentColumn?.footerProps,
                            };

                            if (cellIndex == 0) {
                                elemProps.className += " " + styles.stickyCol;
                            }

                            return <td {...elemProps}>{item.render("Footer")}</td>;
                        })}
                    </tr>
                ))}
                {/* eslint-enable react/jsx-key */}
            </tfoot>
        );
    };

    const onMouseDownOnResizer = useCallback(function(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        // Get the current mouse position
        const x = e.clientX;

        const thElement = e.currentTarget.parentElement as HTMLDivElement;

        // Calculate the current width of column
        const styles = window.getComputedStyle(thElement);
        const w = parseInt(styles.width, 10);

        function clickHandler(e: MouseEvent) {
            e.stopImmediatePropagation();
        }

        function mouseMoveHandler(e: MouseEvent) {
            // Determine how far the mouse has been moved
            const dx = e.clientX - x;

            const bodyTableElement = bodyElementRef.current;
            const index = thElement.getAttribute("data-index");
    
            // Update the width of column
            if (bodyTableElement && index) {
                bodyTableElement.querySelectorAll("th")[parseInt(index, 10)].style.minWidth = `${w + dx}px`;
            }
        }
    
        // When user releases the mouse, remove the existing event listeners
        function mouseUpHandler() {
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);

            // Wait 500ms to avoid mis-click.
            setTimeout(function() { document.removeEventListener('click', clickHandler, {capture: true}) }, 500);
        }
 
        // Attach listeners for document's events
        document.addEventListener('click', clickHandler, {capture: true});
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
    }, []);

    if (!props.loading && !props.loadMoreLoading && !props.table.data.length) {
        return (
            <div className={styles.empty}>
                <div>
                    <EmptyState />
                </div>
                <p>{props.emptyText}</p>
            </div>
        );
    }

    return (
        <div className={`${styles.stickyTable} ${props.styles?.container || ""}`}>
            {/* table header */}
            <div style={{ position: "sticky", zIndex: 10, top: props.stickyPositon?.header?.offsetTop }}>
                <div
                    className={`${props.styles?.headerContainer || ""} ${styles.hiddenScrollbar} ${styles.stickyTableHeader}`}
                    ref={headerElementRef}
                    onScroll={handleScroll}
                >
                    <table>{renderHeader()}</table>
                </div>
            </div>

            {/* table loading prompt */}
            {props.loading && (
                <div className={styles.loading}>
                    <Spinner className={styles.spinner} size={SpinnerSize.large} label={props.loadingText} labelPosition="top" />
                </div>
            )}

            {/* table body */}
            <div
                className={`${props.styles?.bodyContainer || ""} ${styles.hiddenScrollbar} ${styles.stickyTableBody} ${props.loading ? styles.hidden : ""}`}
                ref={bodyElementRef}
                onScroll={handleScroll}
            >
                <table ref={bodyTableElementRef}>
                    {renderHeader()}
                    {renderContent(props.renderRow)}
                    {renderFooter()}
                </table>
            </div>

            {!props.loading && props.loadMore && !props.loadMoreLoading && (
                <div className={styles.loadMore}>
                    <ActionButton onClick={() => props.onLoadMore && props.onLoadMore()} text={props.loadMoreText} />
                </div>
            )}

            {!props.loading && props.loadMore && props.loadMoreLoading && (
                <div className={styles.loadMore}>
                    <Spinner className={styles.spinner} size={SpinnerSize.large} label={props.loadMoreLoadingText} labelPosition="bottom" />
                </div>
            )}

            {/* table visible scrollbar */}
            {!props.footerVisible && (
                <div
                    ref={scrollBarElementRef}
                    style={{ overflowX: "auto", position: "sticky", bottom: props.stickyPositon?.footer?.offsetBottom || 0 }}
                    onScroll={handleScroll}
                    className={props.loading ? styles.hidden : ""}
                >
                    <div style={{ height: 1 }} ref={scrollBarPlaceholderElementRef} />
                </div>
            )}

            {/* table footer */}
            {props.footerVisible && (
                <div
                    className={`${props.styles?.stickyFooterContainer || ""} ${styles.stickyFooterContainer}`}
                    style={{ position: "sticky", bottom: props.stickyPositon?.footer?.offsetBottom }}
                >
                    <div
                        className={`${props.styles?.footerContainer || ""} ${styles.stickyTableFooter} ${props.loading ? styles.hidden : ""}`}
                        ref={footerElementRef}
                        onScroll={handleScroll}
                    >
                        <table>{renderFooter()}</table>
                    </div>
                </div>
            )}
        </div>
    );
};

StickyTable.defaultProps = {
    loading: false,
    loadingText: "Loading",

    loadMore: true,
    loadMoreText: "Show More",
    loadMoreLoading: false,
    loadMoreLoadingText: "Loading More...",

    emptyText: "No data",
    footerVisible: true,

    stickyPositon: { header: { offsetTop: -40 }, footer: { offsetBottom: 0 } },
};

export default StickyTable;
