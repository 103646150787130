import * as React from "react";

import { EventType, LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { PcmV2MetricNames, PcmV2SceneMetrics } from "../../../models/constants/PcmV2Constants";

import ChartContainer from "../../chart/ChartContainer";
import CommonConstants from "../../../models/constants/CommonConstants";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { PcmV2SceneTypeEnum } from "../../../models/PcmV2Models";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { trackEventCallback } from "../../../utils/AppInsights";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useGetPcmV2Cost } from "../../../hooks/useSubstrateV2Query";
import { useTrackHovering } from "../../../hooks/useTrack";

export const SubstrateCostProportion: React.FC = () => {
    const { data: substrateV2Cost, isLoading } = useGetPcmV2Cost();
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.SubstrateV2);
    const dateRange = useDateRange();
    const trackingProps = useTrackHovering(LogComponent.SubstrateChartingPane,LogElement.SubstrateCostProportion, "Substrate Cost by Metrics", LogTarget.Chart);

    const data = React.useMemo(
        () =>
            PcmV2SceneMetrics[PcmV2SceneTypeEnum.Overview]
                .map((metric, index) => ({
                    name: PcmV2MetricNames[metric] || "",
                    y: substrateV2Cost?.[metric] || 0,
                    color: CommonConstants.DefaultColors[index],
                    anomalies: getActiveAnomalies(anomaliesQuery.data, [metric]),
                }))
                .filter((item) => item.y > 0),
        [substrateV2Cost]
    );

    return (
        <ChartContainer
            headerProps={{ title: "Substrate Cost by Metrics" }}
            loading={isLoading}
            chartType="donut"
            chartProps={{
                data,
                dateRange: [dateRange.startDate, dateRange.endDate],
                containerProps: trackingProps,
            }}
            logProps={{
                logComponent: LogComponent.SubstrateV2,
                logElement: LogElement.SubstrateCostProportion,
            }}
        />
    );
};
