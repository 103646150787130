import { IAzureBudgetDetails, IAzureComputeDetailData, IAzureComputeRegionDetail, IAzureComputeServiceDetail, IAzureComputeSubscriptionDetail, IAzureSubscriptionSearchInfo, IAzureTotalBudgetReport, IAzureWorkloadMonthlyCost, IHierarchicalMetricReport } from "../models/AzureComputeModels";
import { IDailyMetricReport, ISingleDayMetric } from '../models/Metric';
import { ISubscription, ServiceTreeItem } from "../models/serviceTree";
import { UseQueryResult, useQuery } from "react-query";
import { getAvailableFiscalYears, getAzureDailyMetrics, getAzureNonComputeCosts, getBudgetDetails, getDailyCpuUtilization, getDetailsByRegion, getDetailsByService, getDetailsBySubscription, getHierarchicalMetrics, getRegionList, getRodsCpuBin, getSubscriptionSummary, getTopAzureWorkloadsMonthlyCost, getTotalBudget } from "../services/azureComputeService";

import { CategoryDivision } from "../models/CategoryDivision";
import { Endpoints } from "../utils/Constants";
import { IAppState } from "../store";
import { IDateRangeFilterData } from "../models/FilterView";
import { IFilterItem } from "../components/filter/filterList/FilterList";
import { IServiceTreeData } from "../reducers/serviceTreeReducer";
import { NON_COMPUTE_CATEGORIES } from "../components/azure/common/AzureComputeConstants";
import moment from "moment";
import { sumBy } from "lodash";
import { useAzureSubscriptionsQuery } from "./useServiceTreeQuery";
import { useCategoryFilters } from "./useFilters";
import { useDateRange } from "./useDateSelector";
import { useFlights } from "./useSettings";
import { useMemo } from "react";
import { useRemap } from "../hooks/useRemap"
import { useSelector } from "react-redux";

export function useGetAzureDailyMetricsQuery(endpoint: Endpoints, metric: string, azureType: string, aggregateType: string, regions?: string[]): UseQueryResult<IDailyMetricReport, Error> {
    const dateRangeFilterData = useDateRange();
    const [showRemap] = useRemap();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        IDailyMetricReport,
        Error,
        IDailyMetricReport,
        [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string, string, string, boolean, string[]?]>([endpoint + metric + azureType + aggregateType, dateRangeFilterData, filtersData.filters, serviceIdMap, metric, azureType, aggregateType, showRemap, regions], getAzureDailyMetrics);
}

export function useGetAzureHierarchicalMetricsQuery(endpoint: Endpoints, hierarchyLevel:string, metric: string, n:number, azureType: string, showSpotVM = true): UseQueryResult<IHierarchicalMetricReport[], Error> {
    const dateRangeFilterData = useDateRange();
    const [showRemap] = useRemap();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        IHierarchicalMetricReport[],
        Error,
        IHierarchicalMetricReport[],
        [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string, string, number, string, boolean, boolean]>([endpoint + hierarchyLevel + metric + n + azureType, dateRangeFilterData, filtersData.filters, serviceIdMap, hierarchyLevel, metric, n, azureType, showSpotVM, showRemap], getHierarchicalMetrics);
}

export function useGetAzureDailyCpuQuery(endpoint: Endpoints, date: moment.Moment, azureType: string, regions?: string[]): UseQueryResult<Record<number, number>, Error> {
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        Record<number, number>,
        Error,
        Record<number, number>,
        [string, moment.Moment, string, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string[]?]>([endpoint + azureType, date, azureType, filtersData.filters, serviceIdMap, regions], getDailyCpuUtilization);
}

export function useGetRdosCpuBinQuery(endpoint: Endpoints, date: moment.Moment, regions?: string[]): UseQueryResult<Record<string, ISingleDayMetric[]>> {
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        Record<string, ISingleDayMetric[]>,
        Error,
        Record<string, ISingleDayMetric[]>,
        [string, moment.Moment, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string[]?]>([endpoint, date, filtersData.filters, serviceIdMap, regions], getRodsCpuBin);
}

export function useGetAzureComputeServiceDetail(type: string, localFilters: IFilterItem[], orderBy: string | undefined, orderDesc: boolean, limit: number) {
    const globalFilters = useCategoryFilters().filters;
    const {startDate, endDate, singleDate} = useDateRange();
    const { data: flights } = useFlights();
    const [showRemap] = useRemap();
    const serviceTree = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const subscriptions = useAzureSubscriptionSearchInfo();

    return useQuery<
        IAzureComputeDetailData<IAzureComputeServiceDetail>,
        Error,
        IAzureComputeDetailData<IAzureComputeServiceDetail>,
        Parameters<typeof getDetailsByService>['0']['queryKey']
    >([Endpoints.GetAzureDetailsByService, type, localFilters, globalFilters.filters, serviceTree.indexMap, serviceTree.serviceNodeMapByName, subscriptions, startDate, endDate, orderBy, orderDesc, limit, showRemap, flights?.enableDateRange, singleDate], getDetailsByService);
}

export function useGetAzureComputeRdosRegionList() {
    return useQuery<
        string[],
        Error,
        string[],
        [string]
    >([Endpoints.GetAzureRdosRegionList], getRegionList);
}

export function useGetAzureComputeRegionDetail(type: string, localFilters: IFilterItem[], orderBy: string | undefined, orderDesc: boolean, limit: number, regions: string[]) {
    const globalFilters = useCategoryFilters().filters;
    const {startDate, endDate, singleDate} = useDateRange();
    const { data: flights } = useFlights();
    const [showRemap] = useRemap();
    const serviceTree = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const subscriptions = useAzureSubscriptionSearchInfo();

    return useQuery<
        IAzureComputeDetailData<IAzureComputeRegionDetail>,
        Error,
        IAzureComputeDetailData<IAzureComputeRegionDetail>,
        Parameters<typeof getDetailsByRegion>['0']['queryKey']
    >([Endpoints.GetAzureDetailsByRegion, type, localFilters, globalFilters.filters, serviceTree.indexMap, serviceTree.serviceNodeMapByName, subscriptions, startDate, endDate, orderBy, orderDesc, limit, regions, showRemap, flights?.enableDateRange, singleDate], getDetailsByRegion);
}

export function useGetAzureComputeSubscriptionDetail(type: string, localFilters: IFilterItem[], orderBy: string | undefined, orderDesc: boolean, limit: number, regions: string[]) {
    const globalFilters = useCategoryFilters().filters;
    const {startDate, endDate, singleDate} = useDateRange();
    const { data: flights } = useFlights();
    const [showRemap] = useRemap();
    const serviceTree = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const subscriptions = useAzureSubscriptionSearchInfo();

    return useQuery<
        IAzureComputeDetailData<IAzureComputeSubscriptionDetail>,
        Error,
        IAzureComputeDetailData<IAzureComputeSubscriptionDetail>,
        Parameters<typeof getDetailsBySubscription>['0']['queryKey']
    >([Endpoints.GetAzureDetailsBySubscription, type, localFilters, globalFilters.filters, serviceTree.indexMap, serviceTree.serviceNodeMapByName, subscriptions, startDate, endDate, orderBy, orderDesc, limit, regions, showRemap, subscriptions.idMap.size, flights?.enableDateRange, singleDate], getDetailsBySubscription);
}

export function useGetAzureComputeSubscriptionSummary(type: string, localFilters: IFilterItem[],regions: string[]) {
    const globalFilters = useCategoryFilters().filters;
    const {startDate, endDate, singleDate} = useDateRange();
    const { data: flights } = useFlights();
    const [showRemap] = useRemap();
    const serviceTree = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const subscriptions = useAzureSubscriptionSearchInfo();

    return useQuery<
        IAzureComputeDetailData<IAzureComputeSubscriptionDetail>,
        Error,
        IAzureComputeDetailData<IAzureComputeSubscriptionDetail>,
        Parameters<typeof getSubscriptionSummary>['0']['queryKey']
    >([Endpoints.GetAzureDetailsBySubscription, type, localFilters, globalFilters.filters, serviceTree.indexMap, serviceTree.serviceNodeMapByName, subscriptions, startDate, endDate, regions, showRemap, flights?.enableDateRange, singleDate], getSubscriptionSummary);
}

export function useGetTotalBudgetQuery(fiscalYear: number): UseQueryResult<IAzureTotalBudgetReport> {
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        IAzureTotalBudgetReport,
        Error,
        IAzureTotalBudgetReport,
        [string, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, number]>([Endpoints.GetAzureTotalBudget, filtersData.filters, serviceIdMap, fiscalYear], getTotalBudget);
}

export function useGetTopAzureWorkloadsMonthlyCostQuery(azureType: string, fiscalYear: number, n = 8): UseQueryResult<IAzureWorkloadMonthlyCost[]> {
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        IAzureWorkloadMonthlyCost[],
        Error,
        IAzureWorkloadMonthlyCost[],
        [string, number, string, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, number]>([Endpoints.GetAzureTopNServicesMonthlyCost, n, azureType, filtersData.filters, serviceIdMap, fiscalYear], getTopAzureWorkloadsMonthlyCost);
}

export function useGetAzureBudgetDetailsQuery(fiscalYear: number): UseQueryResult<IAzureBudgetDetails[]> {
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        IAzureBudgetDetails[],
        Error,
        IAzureBudgetDetails[],
        [string, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, number]>([Endpoints.GetAzureBudgetDetails, filtersData.filters, serviceIdMap, fiscalYear], getBudgetDetails);
}

export function useGetAzureFiscalYears(): UseQueryResult<number[]> {
    return useQuery<number[]>([Endpoints.GetAzureFiscalYears], getAvailableFiscalYears);
}

export function useGetAzureNonComputeCosts(categories: string[]): UseQueryResult<Record<string, ISingleDayMetric[]>> {
    const filtersData = useCategoryFilters().filters;
    const dateRange = useDateRange();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    return useQuery<
        Record<string, ISingleDayMetric[]>,
        Error,
        Record<string, ISingleDayMetric[]>,
        [string, IDateRangeFilterData, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, string[]]>([Endpoints.GetAzureNonComputeCosts, {startDate: dateRange.startDate, endDate: dateRange.endDate}, filtersData.filters, serviceIdMap, categories], getAzureNonComputeCosts);
}

export function useAzureSubscriptionSearchInfo(): IAzureSubscriptionSearchInfo {
    const subscriptions = useAzureSubscriptionsQuery().data;

    return useMemo(() => {
        const nameMap = new Map<string, ISubscription>();
        subscriptions?.forEach(subscription => nameMap.set(subscription.subscriptionName, subscription));
        const idMap = new Map<string, ISubscription>();
        subscriptions?.forEach(subscription => idMap.set(subscription.subscriptionId, subscription));
        return {nameMap, idMap};
    }, [subscriptions]);
}

export function useGetAzureCostOverview(showBillingCost?: boolean) {
    const publicAzureDailyCost = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, showBillingCost ? "BillingCost" : "VMCost", "Public", "Sum");
    const azscDailyCost = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, showBillingCost ? "BillingCost" : "VMCost", "AZSC", "Sum");
    const {data: azureNonComputeDailyCost, isLoading: isNonComputeLoading} = useGetAzureNonComputeCosts(NON_COMPUTE_CATEGORIES);
    const nonComputeTotal = useMemo(() => {
        if (!azureNonComputeDailyCost) {
            return 0;
        }
        return NON_COMPUTE_CATEGORIES.reduce((preVal, current) => preVal + sumBy(azureNonComputeDailyCost[current], element => element.metricValue || 0), 0);
    }, [azureNonComputeDailyCost]);

    return {
        publicCost: publicAzureDailyCost.data?.total || 0,
        azscCost: azscDailyCost.data?.total || 0,
        nonCompute: nonComputeTotal,
        isLoading: publicAzureDailyCost.isLoading || azscDailyCost.isLoading || isNonComputeLoading,
    };
}