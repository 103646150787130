import Highcharts from "highcharts";
import { dateFormat } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import * as React from "react";
import { IAzureWorkloadMonthlyCost } from "../../../models/AzureComputeModels";
import { onChartSeriesLegendClick, chartLegendOptions, chartStyles, chartTooltipOptions } from "../../../utils/chartOptions";
import { currencyFormatter } from "../../../utils/currency";
import lineChartStyles from "../../common/Chart.less";

const chartColors = ["#000000", "#9C663F", "#0099BC", "#982570", "#627CEF", "#C19C00", "#972AA5", "#E650AF", "#0E7878", "#9372C0"];

interface IBudgetChartProps {
    budgetPlan: number[][] | undefined;
    cost: (number | null)[][] | undefined;
    top8Workloads: IAzureWorkloadMonthlyCost[];
    monthsList: number[];
}

export const BudgetChart: React.FC<IBudgetChartProps> = (props) => {
    const options: Highcharts.Options = React.useMemo(() => ({
        chart: {
            type: "area",
            style: chartStyles
        },
        title: {
            text: undefined
        },
        credits: {
            enabled: false
        },
        time: {
            useUTC: false,
            moment: moment
        },
        tooltip: {
            ...chartTooltipOptions,
            shape: "square",
            shared: true,
            outside: true,
            formatter() {
                return this.points?.reduce(function (prev, point) {
                    return prev + `
                        <div class="${lineChartStyles.tooltipContent}">
                            <div class="${lineChartStyles.tooltipBar}" style="background-color: ${point.color};"></div>
                            <span class="${lineChartStyles.tooltipTitle}">${point.series.name}</span>
                            <span class="${lineChartStyles.tooltipValue}" style="color: ${point.color};">${currencyFormatter(point.y || 0, 2)}</span>
                        </div>`
                }, `<span class="${lineChartStyles.tooltipHeader}">${dateFormat("%B", this.x)}</span>`)
            }
        },
        legend: chartLegendOptions,
        xAxis: {
            type: "datetime",
            labels: {
                format: `{value:%b}`
            },
            height: 128
        },
        yAxis: {
            title: {
                text: undefined
            },
            labels: {
                formatter: function () {
                    if (typeof (this.value) === "string") {
                        return this.value;
                    }
                    return currencyFormatter(this.value, 2);
                }
            },
            height: 128
        },
        plotOptions: {
            line: {
                lineWidth: 4,
                marker: {
                    symbol: "square",
                    enabled: false
                }
            },
            area: {
                lineWidth: 4,
                marker: {
                    symbol: "square",
                    enabled: false
                },
                fillOpacity: 0.5,
                stacking: "normal"
            },
            series: {
                events: {
                    legendItemClick: onChartSeriesLegendClick
                }
            }
        },
        series: [
            {
                type: "line",
                dashStyle: "Dot",
                name: "Total Workload Budget",
                data: props.budgetPlan
            } as Highcharts.SeriesLineOptions,
            {
                type: "line",
                name: "Total Workload Cost",
                data: props.cost
            } as Highcharts.SeriesLineOptions,
            ...((props.top8Workloads || []).map(workload => ({
                name: workload.productName,
                type: "area",
                data: workload.monthlyCosts.map((monthlyCost, i) => [props.monthsList[i], monthlyCost]),
                stacking: "normal"
            } as Highcharts.SeriesAreaOptions)))
        ],
        colors: chartColors
    }), [props.budgetPlan, props.cost, props.monthsList, props.top8Workloads]);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            containerProps={{style: {height: "100%"}}}
        />
    );
}