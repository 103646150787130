import moment from "moment";
import { IFinOpsBudget } from "../models/FinOpsBudgets";
import { toNumber } from "lodash";

const Categories = [
    'Compute:AIRS:Public Compute VMs:Cores',
    'Compute:AIRS:Cosmic:Cores',
    'Compute:AIRS:Legacy GPU:Cores',
    'Network:MCIO Network:LongHaul T0:Mbps',
    'Network:MCIO Network:LongHaul T2:Mbps',
    'Network:MCIO Network:Egress:Mbps',
    'Storage:AIRS:Transactions:10K',
    'Storage:AIRS:Storage - Other:GB',
    'Storage:AIRS:Bandwidth:GB',
    'Storage:AIRS:Managed SSD Disks:GB',
    'Storage:AIRS:Managed HDD Disks:GB',
    'Storage:AIRS:Other:',
    'Others:AIRS:Others:',
    'Monitoring:AIRS:SQL:',
    'Monitoring:AIRS:Redis:',
    'Data Operations:AIRS:ADF:',
    'Monitoring:AIRS:Cosmos DB:RU/s',
    'Monitoring:AIRS:Cosmos DB:GB',
    'Monitoring:AIRS:Cosmos DB:',
    'Monitoring:AIRS:Azure Front Door:',
    'Monitoring:AIRS:Azure Front Door:GB',
    'Monitoring:AIRS:Azure Front Door:10K',
    'Monitoring:AIRS:Azure Log Analytics:GB',
    'Monitoring:AIRS:Azure Log Analytics:',
    'Monitoring:AIRS:Synapse Analytics:',
    'Monitoring:AIRS:Synapse Analytics:vCore',
    'Monitoring:AIRS:Synapse Analytics:GB',
    'Monitoring:AIRS:Synapse Analytics:DWUs',
    'Network:AIRS:Azure Bandwidth:GB',
    'Monitoring:AIRS:Azure Monitor:',
    'Monitoring:AIRS:Azure Monitor:1 GB',
    'Monitoring:AIRS:Azure Monitor:10M',
    'Monitoring:AIRS:Azure Monitor:1GB/Day',
    'Monitoring:AIRS:Azure Monitor:1M',
    'Passive Monitoring:AIRS:GenevaMdm:WeightedTimeSeriesCount',
    'Passive Monitoring:AIRS:GenevaMdm:EventRate/Min',
    'Passive Monitoring:Non-AIRS:GenevaMdsIndirect:GB',
    'Passive Monitoring:AIRS:GenevaMds:GB',
    'Passive Monitoring:AIRS:Kusto:GB',
    'Passive Monitoring:AIRS:Kusto:CPU',
];

function blankBudget(
    fiscalYear: string,
    serviceId: string,
    category: string,
    type: string,
    unit: string): IFinOpsBudget {
    return {
        id: 0,
        fiscalYear: fiscalYear,
        serviceId: serviceId,
        category: category,
        type: type,
        lastYearCost: 0,
        lastYearQuantity: 0,
        unit: unit,
        increaseRate: 0,
        quantityBudget: 0,
        costBudget: 0
    };
}

function getCategory(category: string, type: string) {
    switch (category) {
        case 'Network':
            if (type === 'Azure Bandwidth') return 'AIRS';
            else return 'MCIO Network';
        case 'Passive Monitoring':
            if (type === 'GenevaMdsIndirect') return 'Non-AIRS';
            else return 'AIRS';
        default:
            return 'AIRS';
    }
}

export function suppleBudgets(
    fiscalYear: string,
    serviceId: string,
    budgets: IFinOpsBudget[]) {
    const set = new Set(Categories);
    budgets.forEach(budget => set.delete(`${budget.category}:${getCategory(budget.category, budget.type)}:${budget.type}:${budget.unit || ''}`));
    return [
        ...budgets.filter(budget => budget.category !== null),
        ...Array.from(set).map(item => {
            const [category, _, type, unit] = item.split(':');
            return blankBudget(fiscalYear, serviceId, category, type, unit);
        })
    ]
}

export function clearEmptyBudgets(budgets?: IFinOpsBudget[]) {
    if (!budgets) return budgets;
    return budgets.filter(budget => budget.lastYearCost !== 0 || budget.lastYearQuantity !== 0 || budget.costBudget !== 0 || budget.submitter);
}

export function getLastFiscalYear(fiscalYear: string) {
    if (fiscalYear.length < 4) return fiscalYear;
    return `FY${parseInt(fiscalYear.substring(2)) - 1}`;
}

export function getBudgetFiscalYear() {
    const now = moment();
    const month = now.month();
    return 'FY' + (parseInt(now.format('YY')) + 1 + (month > 6 ? 1 : 0));
}

export function getBudgetId(budget: IFinOpsBudget) {
    return `${budget.serviceId}-${budget.category}-${budget.type}-${budget.unit}`;
}

export function recalculateServiceBudget(serviceBudget: IFinOpsBudget) {
    const costBudget = serviceBudget.children?.reduce((sum, budget) => sum + budget.costBudget, 0) || serviceBudget.costBudget;
    const lastYearServiceCost = serviceBudget.children?.reduce((sum, budget) => sum + budget.lastYearCost, 0) || serviceBudget.lastYearCost;

    return {
        ...serviceBudget,
        costBudget,
        increaseRate: lastYearServiceCost !== 0 ? toNumber((costBudget / lastYearServiceCost * 100 - 100).toFixed(1)) : 0
    }
}

const DefaultBlendedRates: { [key: string]: number } = {
    "Monitoring:Cosmos DB:GB":0.118401124736337,
    "Monitoring:Cosmos DB:":0.0131378867625526,
    "Monitoring:Cosmos DB:RU/s":0.00297755476233779,
    "Monitoring:Azure Log Analytics:GB":0.0679907872981772,
    "Monitoring:Azure Log Analytics:":0.436729136856282,
    "Monitoring:Azure Monitor:":0.00089881709594122,
    "Monitoring:Azure Monitor:10M":0.0364902860630842,
    "Monitoring:Azure Monitor:1GB/Day":3010.21711347686,
    "Monitoring:Azure Monitor:1 GB":0.0569917563371282,
    "Monitoring:Azure Front Door:GB":0.0113577726910489,
    "Monitoring:Azure Front Door:":0.205707160523623,
    "Monitoring:Azure Front Door:10K":0.00315311413265204,
    "Monitoring:Synapse Analytics:GB":0.109922410374672,
    "Monitoring:Synapse Analytics:":0.0539877434824353,
    "Monitoring:Synapse Analytics:vCore":0.0387166784554428,
    "Monitoring:Synapse Analytics:DWUs":0.724478540768339,
    "Storage:Transactions:10K":0.000951771458682175,
    "Storage:Storage - Other:GB":0.00326985316666074,
    "Storage:Bandwidth:GB":0.00408862357888628,
    "Storage:Managed SSD Disks:GB":0.0661396633482012,
    "Storage:Managed HDD Disks:GB":0.00941965809295017,
    "Network:Azure Bandwidth:GB":0.00460920451366063,
    "Network:LongHaul T0:Mbps":0.867003946686895,
    "Network:Egress:Mbps":0.583210175995812,
    "Network:LongHaul T2:Mbps":0.476756391191679,
    "Network:Metro:Mbps":11.4554386323427,
    "Compute:Public Compute VMs:Cores":8.47868179963876,
    "Compute:Legacy GPU:Cores":16.7203830671522,
    "Compute:Cosmic:Cores":5.30048554699674,
    "Passive Monitoring:Kusto:GB":0.000507778117408832,
    "Passive Monitoring:Kusto:CPU":4.81646369944787E-05,
    "Passive Monitoring:GenevaMds:GB":0.000412587276213039,
    "Passive Monitoring:GenevaMdm:EventRate/Min":1.76095598552661E-06,
    "Passive Monitoring:GenevaMdm:WeightedTimeSeriesCount":1.63398923114378E-06,
    "Passive Monitoring:GenevaMdsIndirect:GB":0.00876665373727924,
}

export function getDefaultBlendedRate(budget: IFinOpsBudget) {
    return DefaultBlendedRates[`${budget.category}:${budget.type}:${budget.unit}`] || 0;
}