// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LgFLOX_5Tw9U14tXRlA4 {\n  margin-top: 8px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/banner/WarningBanner.less"],"names":[],"mappings":"AAAA;EACI,0BAAA;AACJ","sourcesContent":[".banner {\n    margin-top: 8px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "LgFLOX_5Tw9U14tXRlA4"
};
export default ___CSS_LOADER_EXPORT___;
