import { IStackTokens, Separator, Stack } from "@fluentui/react";
import { sum } from "lodash";
import React from "react";
import { IAzureWorkloadMonthlyCost, IAzureTotalBudgetReport } from "../../../models/AzureComputeModels";
import { fiscalMonthsList } from "../../../utils/Constants";
import CostCard from "../../common/costCard/CostCard";
import LoadingState from "../../ResponseBoundary/LoadingState";
import styles from "../Azure.less";
import { BudgetChart } from "./BudgetChart";

const cardTokens: IStackTokens = {childrenGap: 24};

interface IBudgetChartProps {
    type?: "Public" | "AZSC";
    isTotalBudgetLoading: boolean;
    totalBudget?: IAzureTotalBudgetReport;
    isGetTop8WorkloadLoading: boolean;
    top8Workloads?: IAzureWorkloadMonthlyCost[];
    fiscalYear: number;
}

export const BudgetChartContainer: React.FC<IBudgetChartProps> = (props) => {
    const {isTotalBudgetLoading, totalBudget, isGetTop8WorkloadLoading, top8Workloads: top8Workloads} = props;
    const isDataLoading = isTotalBudgetLoading && isGetTop8WorkloadLoading;

    const publicBudget = React.useMemo(() => sum(totalBudget?.publicBudgets), [totalBudget?.publicBudgets]);
    const azscBudget = React.useMemo(() => sum(totalBudget?.azscBudgets), [totalBudget?.azscBudgets]);
    const budget = React.useMemo(() => publicBudget + azscBudget, [azscBudget, publicBudget]);
    const {title, budgetPlan, actualCost} = React.useMemo(() => {
        switch(props.type) {
            case "Public":
                return {
                    title: `Workload Public Budget and Workload Top8 Workloads Public Cost Trends`,
                    budgetPlan: totalBudget?.publicBudgets.map((budget, i) => [fiscalMonthsList[i], budget]),
                    actualCost: totalBudget?.publicCosts.map((cost, i) => [fiscalMonthsList[i], cost])
                };
            case "AZSC":
                return {
                    title: `Workload AzSC Budget and Workload Top8 Workloads AzSC Cost Trends`,
                    budgetPlan: totalBudget?.azscBudgets.map((budget, i) => [fiscalMonthsList[i], budget]),
                    actualCost: totalBudget?.azscCosts.map((cost, i) => [fiscalMonthsList[i], cost])
                };
            default:
                return {
                    title: `Workload Budget and Workload Top8 Workloads Cost Trends`,
                    budgetPlan: totalBudget && totalBudget.publicBudgets.map((value, i) => value + totalBudget.azscBudgets[i]).map((budget, i) => [fiscalMonthsList[i], budget]),
                    actualCost: totalBudget && totalBudget.publicCosts.map((value, i) => (value === null && totalBudget.azscCosts[i] === null) ? null : ((value || 0) + (totalBudget.azscCosts[i] || 0))).map((cost, i) => [fiscalMonthsList[i], cost])
                }
        }
    }, [props.type, totalBudget]);

    return (
        <>
        <Separator styles={{root: styles.separator}} />
        <h4 className={styles.title}>{title}</h4>
        {
            !props.type &&
            <Stack className={styles.cardSection} horizontal tokens={cardTokens}>
                {
                    !isDataLoading ?
                    <>
                    <CostCard
                        title={`FY${props.fiscalYear} Total Workload Budget`}
                        cost={budget}
                        color="#000000"
                    />
                    <CostCard
                        title={`FY${props.fiscalYear} Total Workload Public Budget`}
                        cost={publicBudget}
                        color="#000000"
                    />
                    <CostCard
                        title={`FY${props.fiscalYear} Total Workload AzSC Budget`}
                        cost={azscBudget}
                        color="#000000"
                    />
                    </> :
                    <LoadingState />
                }
            </Stack>
        }
        <div className={styles.chart}>
            {
                !isDataLoading && totalBudget && top8Workloads ?
                <BudgetChart
                    budgetPlan={budgetPlan}
                    cost={actualCost}
                    top8Workloads={top8Workloads}
                    monthsList={fiscalMonthsList}
                /> :
                <LoadingState />
            }
        </div>
        </>
    );
}