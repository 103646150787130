import moment from "moment";
import { Moment } from "moment";

export class ESCostUtils {
    // The range of fiscal year should be from July 1st to June 30th.
    static getCurrentFiscalYearDates(): Moment[] {
        const today = moment();
        const fiscalYearStartDate = moment(today)
            .subtract(today.month() < 6 ? 1 : 0, "year")
            .month(6)
            .date(1)
            .startOf("day");
        const fiscalYearEndDate = fiscalYearStartDate.clone().add(1, "year").subtract(1, "day");
        return [fiscalYearStartDate, fiscalYearEndDate];
    }

    static getLastFiscalYearDates(): Moment[] {
        const [currentFiscalYearStartDate, ] = ESCostUtils.getCurrentFiscalYearDates();
        const lastFiscalYearEndDate = currentFiscalYearStartDate.clone().subtract(1, "day");
        const lastFiscalYearStartDate = currentFiscalYearStartDate.clone().subtract(1, "year");
        return [lastFiscalYearStartDate, lastFiscalYearEndDate];
    }

    // Two months for comparing should be the last month and the month before last month.
    static getMonthToMonthDates(): Moment[] {
        const lastMonth = moment().subtract(1, "month").startOf("month");
        const monthBeforeLastMonth = lastMonth.clone().subtract(1, "month").startOf("month");
        return [monthBeforeLastMonth, lastMonth];
    }

    // The percentage change comparing to the value before.
    static getRelativeChange(before: number, current: number): string {
        if (before === 0 || current === 0) {
            return "N/A";
        }
        return `${(((current - before) * 100) / before).toFixed(2)}%`;
    }
}
