import { sum, sumBy, values } from "lodash";
import * as React from "react";
import { useGetAzureDailyMetricsQuery, useGetAzureNonComputeCosts } from "../../../hooks/useAzureQuery";
import { Endpoints } from "../../../utils/Constants";
import { NON_COMPUTE_CATEGORIES } from "../common/AzureComputeConstants";
import { LineChartContainer } from "../common/LineChartContainer";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useBillingCost } from "../../../hooks/useBillingCost";
import { LogComponent, LogElement } from "../../../models/LogModel";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { DataSourceEnum } from "../../../models/NotificationModels";

const AzureCostChart: React.FC = () => {
    const { data: azureNonComputeDailyCost, isLoading: isAzureNonComputeLoading } = useGetAzureNonComputeCosts(NON_COMPUTE_CATEGORIES);
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.AzureCompute);
    const activeAnomalies = React.useMemo(() => getActiveAnomalies(anomaliesQuery.data, ["Storage", "SQL Database", "Azure Cosmos DB"]), [anomaliesQuery.data]);
    const { startDate, endDate } = useDateRange();
    const [showBillingCost] = useBillingCost();
    const dateRange = [startDate, endDate];
    const {data: azureComputeDailyCost, isLoading: isAzureComputeLoading} = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, showBillingCost ? "BillingCost": "VMCost", "Total", "Sum");

    return (
        <LineChartContainer
            title="Azure Costs"
            isLoading={isAzureComputeLoading || isAzureNonComputeLoading}
            cardProps={[
                {title: "Total Cost", cost: (azureComputeDailyCost?.total || 0) + sumBy(values(azureNonComputeDailyCost), dailyCosts => sumBy(dailyCosts, dailyCost => dailyCost.metricValue || 0)), color: "#000000", showTooltip: true, date: dateRange},
                {title: "Azure Compute", cost: azureComputeDailyCost?.total || 0, color: "#0099BC", showTooltip: true, date: dateRange},
                {title: "Storage", cost: (azureNonComputeDailyCost && azureNonComputeDailyCost["Storage"] && sum(azureNonComputeDailyCost["Storage"].map(singleMetric => singleMetric.metricValue || 0))) || 0, color: "#982570", showTooltip: true, date: dateRange},
                {title: "SQL Database", cost: (azureNonComputeDailyCost && azureNonComputeDailyCost["SQL Database"] && sum(azureNonComputeDailyCost["SQL Database"].map(singleMetric => singleMetric.metricValue || 0))) || 0, color: "#627CEF", showTooltip: true, date: dateRange},
                {title: "Azure Cosmos DB", cost: (azureNonComputeDailyCost && azureNonComputeDailyCost["Azure Cosmos DB"] && sum(azureNonComputeDailyCost["Azure Cosmos DB"].map(singleMetric => singleMetric.metricValue || 0))) || 0, color: "#C19C00", showTooltip: true, date: dateRange},
            ]}
            fillMissingAbnormalData
            anomalies={activeAnomalies}
            minValue={0}
            series={[
                {
                    name: "Azure Compute",
                    data: azureComputeDailyCost?.data.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                },
                ...(NON_COMPUTE_CATEGORIES.map(category => ({
                    name: category,
                    data: azureNonComputeDailyCost?.[category] && azureNonComputeDailyCost[category].map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue] as [number, number | undefined])
                })))
            ]}
            totalTextPrefix="Total Costs: "
            showArea
            logProps={{
                logComponent: LogComponent.AzureTabs,
                logElement: LogElement.AzureCostChart,
            }}
        />
    )
}

export default AzureCostChart;