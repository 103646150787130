import React from "react";
import styles from "./ESCostPageLayout.less";
import { Stack } from "@fluentui/react";
import ESBanner from "../Banner/ESBanner";
import ESNavigation from "../ESNavigation";

export interface IPageLayoutProps {
    containerClassName?: string;
}

const ESCostPageLayout: React.FC<IPageLayoutProps> = (props) => {
    return (
        <Stack grow className={styles.page}>
            <ESBanner />
            <Stack grow horizontal className={styles.stack}>
                <Stack.Item className={styles.navigation}>
                    <ESNavigation />
                </Stack.Item>
                <Stack.Item className={styles.pageContainer}>{props.children}</Stack.Item>
            </Stack>
        </Stack>
    );
};

export default ESCostPageLayout;
