import * as React from "react";
import styles from "../Page.less";
import PageWrapper from "../PageWrapper";
import ResponseBoundary from "../../../ResponseBoundary/ResponseBoundary";
import {
    useGetAllUsageScenarioFYCloudBuildCost,
    useGetAllUsageScenariosFYBuildQueueCost,
    useGetAllUsageScenariosFYReturnTypeCost,
    useGetStampIdFYCost,
} from "../../../../hooks/useES365CostQuery";
import WeeklyActiveBuilderCountChart from "../../Charts/CloudBuild/WeeklyActiveBuilderCountChart";
import { CloudBuildReturnTypeHistogram } from "../../Charts/CloudBuild/CloudBuildReturnTypeHistogram";
import { BuildQueuesTopCostHistogram } from "../../Charts/CloudBuild/BuildQueuesTopCostHistogram";
import { BuildStageCostWeeklyTrend } from "../../Charts/CloudBuild/BuildStageCostWeeklyTrend";
import { BuilderActivityHourlyTrend } from "../../Charts/CloudBuild/BuilderActivityHourlyTrend";
import { StampIdTopCostHistogram } from "../../Charts/CloudBuild/StampIdTopCostHistogram";
import {
    ESCloudBuildOverviewCapacityRawResponse,
    ESHourlyBuilderStateCountRawResponse,
    ESWeeklyBuildStageCostRawResponse,
    ESWeeklyBuilderStateCountRawResponse,
} from "../../../../models/ESCostModels";
import InfraServiceUsageScenarioCostPieChart from "../../Charts/CloudBuild/InfraServiceUsageScenarioCostPieChart";

interface IProps {
    weeklyBuilderState?: ESWeeklyBuilderStateCountRawResponse[];
    weeklyBuildStage?: ESWeeklyBuildStageCostRawResponse[];
    hourlyBuilderState?: ESHourlyBuilderStateCountRawResponse[];
    capacity?: ESCloudBuildOverviewCapacityRawResponse[];
    weeklyBuilderStateLoading: boolean;
    weeklyBuildStageLoading: boolean;
    hourlyBuilderStateLoading: boolean;
    capacityLoading: boolean;
    weeklyBuilderStateError: boolean;
    weeklyBuildStageError: boolean;
    hourlyBuilderStateError: boolean;
    capacityError: boolean;
}

const CloudBuildUsageScenarioPage: React.FC<IProps> = (props) => {
    const {
        weeklyBuilderState: esWeeklyBuilderState,
        weeklyBuildStage: esWeeklyBuildStage,
        hourlyBuilderState: esHourlyBuilderState,
        capacity: esFYCapacity,
        weeklyBuilderStateLoading: isLoading,
        weeklyBuildStageLoading: isBuildStageLoading,
        hourlyBuilderStateLoading: isHourlyBuilderStateLoading,
        capacityLoading: isCapacityLoading,
        weeklyBuilderStateError: isError,
        weeklyBuildStageError: isBuildStageError,
        hourlyBuilderStateError: isHourlyBuilderStateError,
        capacityError: isCapacityError,
    } = props;
    const { data: esFYReturnTypeCost, isLoading: isReturnTypeCostLoading, isError: isReturnTypeCostError } = useGetAllUsageScenariosFYReturnTypeCost();
    const { data: esFYBuildQueueCost, isLoading: isBuildQueueCostLoading, isError: isBuildQueueCostError } = useGetAllUsageScenariosFYBuildQueueCost();
    const { data: esStampIdCost, isLoading: isStampIdCostLoading, isError: isStampIdCostError } = useGetStampIdFYCost();
    const { data: esUsageScenarioCost, isLoading: isUsageScenarioLoading, isError: isUsageScenarioError } = useGetAllUsageScenarioFYCloudBuildCost();
    return (
        <PageWrapper
            title="CloudBuild drill down cost on usage scenarios"
            description="CloudBuild cost drill down on usage scenarios will bring ES365 team and partner team the transparency of each scenario cost so that transfer more insights into cost optimization opportunities."
        >
            <div className={styles.grid}>
                <ResponseBoundary
                    isLoading={isLoading || isCapacityLoading}
                    errorHappened={isError || isCapacityError}
                    data={esWeeklyBuilderState && esFYCapacity}
                >
                    {esWeeklyBuilderState !== undefined && esFYCapacity !== undefined ? (
                        <WeeklyActiveBuilderCountChart data={esWeeklyBuilderState} capacityData={esFYCapacity} />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
                <ResponseBoundary isLoading={isReturnTypeCostLoading} errorHappened={isReturnTypeCostError} data={esFYReturnTypeCost}>
                    {esFYReturnTypeCost !== undefined ? <CloudBuildReturnTypeHistogram data={esFYReturnTypeCost} /> : <></>}
                </ResponseBoundary>
            </div>
            <div className={styles.grid}>
                <ResponseBoundary isLoading={isBuildQueueCostLoading} errorHappened={isBuildQueueCostError} data={esFYBuildQueueCost}>
                    {esFYBuildQueueCost !== undefined ? (
                        <BuildQueuesTopCostHistogram
                            data={esFYBuildQueueCost}
                            seriesHeader="CloudBuild cost"
                            description={"Top 10 CloudBuild cost of build queues"}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
                <ResponseBoundary isLoading={isBuildStageLoading} errorHappened={isBuildStageError} data={esWeeklyBuildStage}>
                    {esWeeklyBuildStage !== undefined ? <BuildStageCostWeeklyTrend data={esWeeklyBuildStage} /> : <></>}
                </ResponseBoundary>
            </div>
            <div className={styles.grid}>
                <ResponseBoundary isLoading={isHourlyBuilderStateLoading} errorHappened={isHourlyBuilderStateError} data={esHourlyBuilderState}>
                    {esHourlyBuilderState !== undefined ? <BuilderActivityHourlyTrend data={esHourlyBuilderState} /> : <></>}
                </ResponseBoundary>
                <ResponseBoundary isLoading={isStampIdCostLoading} errorHappened={isStampIdCostError} data={esStampIdCost}>
                    {esStampIdCost !== undefined ? <StampIdTopCostHistogram data={esStampIdCost} /> : <></>}
                </ResponseBoundary>
            </div>
            <div className={styles.gridForSingleCell}>
                <ResponseBoundary isLoading={isUsageScenarioLoading} errorHappened={isUsageScenarioError} data={esUsageScenarioCost}>
                    {esUsageScenarioCost !== undefined ? <InfraServiceUsageScenarioCostPieChart data={esUsageScenarioCost} /> : <></>}
                </ResponseBoundary>
            </div>
        </PageWrapper>
    );
};

export default CloudBuildUsageScenarioPage;
