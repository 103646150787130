/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Reducer } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypes, RequestStatus } from "../actions/ActionTypes";
import { ServiceTreeAction } from "../actions/ServiceTreeActions";
import { CategoryDivision, ServiceTreeCategory } from "../models/CategoryDivision";
import { ComponentItem, PriceModel, ServiceTree, ServiceTreeItem, ServiceTreeLevel } from "../models/serviceTree";
import { getAppScenarios, getGriffinApps, getOwners, getProcesses, getScenarioTags, getServiceTree, getVdirs, getGriffinProcessors, getStoreClients, getStoreClientComponents, getSSDSetName, getPriceModel, getPlatformServiceIds, getPlatformProcesses, getPlatformApps, getPlatformClients, getPlatformClientComponents, getPlatformSubApps } from "../services/serviceTreeService";
import { GetStateFunction } from "../store";
import { QueryClient } from "react-query";
import { CustomQueryClient } from "../hooks/useCustomQuery";
import { SearchMetadataV2Service } from "../services/SearchMetadataV2Service";
import { Flight } from "../models/Flights";

export interface IServiceTreeData {
    serviceTree?: ServiceTree;
    indexMap: Map<string, ServiceTreeItem>;
    isLoading: boolean;
    errorHappened: boolean;
    isOrderServiceLoading: boolean;

    vdirs: Map<string, ComponentItem[]>;
    processes: Map<string, ComponentItem[]>;
    scenarioTags: Map<string, ComponentItem[]>;
    griffinApps: Map<string, ComponentItem[]>;
    griffinProcessors: Map<string, ComponentItem[]>;
    scenarioNoMap: Map<string, number>;
    appScenarios: Map<string, string>;
    substrateOrderedServices?: string[];
    transactionOrderedServices?: string[];
    ssdOrderedServices?: string[];
    owners: Map<string, ComponentItem[]>;
    storeClients: Map<string, ComponentItem[]>;
    storeClientComponents: Map<string, ComponentItem[]>;
    ssdDataSetNames: Map<string, ComponentItem[]>;
    priceModel?: PriceModel;

    griffinAppV2: Map<string, ComponentItem[]>;
    griffinProcessorV2: Map<string, ComponentItem[]>;
    storeClientComponentV2: Map<string, ComponentItem[]>;
    storeClientV2: Map<string, ComponentItem[]>;
    scenarioTagV2: Map<string, ComponentItem[]>;
    vdirV2: Map<string, ComponentItem[]>;
    processV2: Map<string, ComponentItem[]>;
    datasetV2: Map<string, ComponentItem[]>;

    platformServices: string[];
    platformProcesses: string[];
    platformApps: string[];
    platformSubApps: string[];
    platformClients: string[];
    platformClientComponents: string[];

    serviceNodeMapByName: Map<string, ServiceTreeItem>;
}

const initialState: IServiceTreeData = {
    isLoading: false,
    isOrderServiceLoading: false,
    errorHappened: false,
    vdirs: new Map<string, ComponentItem[]>(),
    processes: new Map<string, ComponentItem[]>(),
    scenarioTags: new Map<string, ComponentItem[]>(),
    griffinApps: new Map<string, ComponentItem[]>(),
    indexMap: new Map<string, ServiceTreeItem>(),
    scenarioNoMap: new Map<string, number>(),
    appScenarios: new Map<string, string>(),
    owners: new Map<string, ComponentItem[]>(),
    griffinProcessors: new Map<string, ComponentItem[]>(),
    storeClients: new Map<string, ComponentItem[]>(),
    storeClientComponents: new Map<string, ComponentItem[]>(),
    ssdDataSetNames: new Map<string, ComponentItem[]>(),
    serviceNodeMapByName: new Map<string, ServiceTreeItem>(),

    griffinAppV2: new Map<string, ComponentItem[]>(),
    griffinProcessorV2: new Map<string, ComponentItem[]>(),
    storeClientComponentV2: new Map<string, ComponentItem[]>(),
    storeClientV2: new Map<string, ComponentItem[]>(),
    scenarioTagV2: new Map<string, ComponentItem[]>(),
    vdirV2: new Map<string, ComponentItem[]>(),
    processV2: new Map<string, ComponentItem[]>(),
    datasetV2: new Map<string, ComponentItem[]>(),

    platformServices: [],
    platformProcesses: [],
    platformApps: [],
    platformSubApps: [],
    platformClients: [],
    platformClientComponents: [],
}

export const serviceTreeReducer: Reducer<IServiceTreeData, ServiceTreeAction> = function (state: IServiceTreeData = initialState, action: ServiceTreeAction): IServiceTreeData {
    if (action.type == ActionTypes.ServiceOrderAction) {
        switch (action.status) {
            case RequestStatus.Requested:
            case RequestStatus.Succeed:
                return { ...state, ...action, isOrderServiceLoading: false};
            case RequestStatus.Failed:
                return { ...state, isOrderServiceLoading: false};
            default:
                return state;
        }
    }

    if (action.type != ActionTypes.ServiceTreeRequestAction) {
        return state;
    }

    switch (action.status) {
        case RequestStatus.Requested:
            return { ...state, isLoading: true, errorHappened: false};
        case RequestStatus.Succeed:
            const indexMap = new Map<string, ServiceTreeItem>();
            const serviceNodeMapByName = new Map<string, ServiceTreeItem>();
            const scenarioTagNos = new Map<string, number>();
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            constructScenarioTagNoMap(scenarioTagNos, action.scenarioTags!);
            establishIndexTree(indexMap, serviceNodeMapByName, action.serviceTree?.items);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return { ...state, ...action, isLoading: false, errorHappened: false,
                 serviceTree: action.serviceTree, indexMap, scenarioNoMap: scenarioTagNos,
                 appScenarios: action.appScenarios!, priceModel: action.priceModel,
                 owners: action.owners!, serviceNodeMapByName};
        case RequestStatus.Failed:
            return { ...state, isLoading: false, errorHappened: true};

        default:
            return state;
    }
}

export function requestServiceTreeIfNeeded(flight: Flight | undefined, queryClient: CustomQueryClient) {
    return async function (dispatch: ThunkDispatch<IServiceTreeData, void, ServiceTreeAction>, getState: GetStateFunction): Promise<ServiceTreeAction | void> {
        const serviceTreeData = getState().serviceTree;

        if ((serviceTreeData.isLoading || serviceTreeData.errorHappened || serviceTreeData.serviceTree)) {
            return Promise.resolve();
        }

        dispatch({type: ActionTypes.ServiceTreeRequestAction, status: RequestStatus.Requested})
        Promise.all([getServiceTree(),
                flight?.enableSubstrateV1 ? getVdirs() : new Map<string, ComponentItem[]>(),
                flight?.enableSubstrateV1 ? getProcesses() : new Map<string, ComponentItem[]>(),
                flight?.enableSubstrateV1 ? getScenarioTags() : new Map<string, ComponentItem[]>(),
                flight?.enableSubstrateV1 ? getGriffinApps() : new Map<string, ComponentItem[]>(),
                flight?.enableSubstrateV1 ? getAppScenarios() : new Map<string, string>(),
                getOwners(),
                flight?.enableSubstrateV1 ? getGriffinProcessors() : new Map<string, ComponentItem[]>(),
                flight?.enableSubstrateV1 ? getStoreClients() : new Map<string, ComponentItem[]>(),
                flight?.enableSubstrateV1 ? getStoreClientComponents() : new Map<string, ComponentItem[]>(),
                flight?.enableSubstrateV1 ? getSSDSetName() : new Map<string, ComponentItem[]>(),
                !flight?.enableSubstrateV2 ? new Map<string, ComponentItem[]>() : queryClient.fetchQuery(SearchMetadataV2Service.getGriffinApps, true),
                !flight?.enableSubstrateV2 ? new Map<string, ComponentItem[]>() : queryClient.fetchQuery(SearchMetadataV2Service.getGriffinProcessors),
                !flight?.enableSubstrateV2 ? new Map<string, ComponentItem[]>() : queryClient.fetchQuery(SearchMetadataV2Service.getClients),
                !flight?.enableSubstrateV2 ? new Map<string, ComponentItem[]>() : queryClient.fetchQuery(SearchMetadataV2Service.getClientComponent),
                !flight?.enableSubstrateV2 ? new Map<string, ComponentItem[]>() : queryClient.fetchQuery(SearchMetadataV2Service.getScenarioTags),
                !flight?.enableSubstrateV2 ? new Map<string, ComponentItem[]>() : queryClient.fetchQuery(SearchMetadataV2Service.getVdirs),
                !flight?.enableSubstrateV2 ? new Map<string, ComponentItem[]>() : queryClient.fetchQuery(SearchMetadataV2Service.getProcesses),
                !flight?.enableSubstrateV2 ? new Map<string, ComponentItem[]>() : queryClient.fetchQuery(SearchMetadataV2Service.getDataSets),
                getPlatformServiceIds(),
                getPlatformProcesses(),
                getPlatformApps(),
                getPlatformSubApps(),
                getPlatformClients(),
                getPlatformClientComponents(),
            ]).then(values => {
            dispatch({
                type: ActionTypes.ServiceTreeRequestAction,
                status: RequestStatus.Succeed,
                serviceTree: values[0],
                vdirs: values[1],
                processes: values[2],
                scenarioTags: values[3],
                griffinApps: values[4],
                appScenarios: values[5],
                owners: values[6],
                griffinProcessors: values[7],
                storeClients: values[8],
                storeClientComponents: values[9],
                ssdDataSetNames: values[10],

                griffinAppV2: values[11],
                griffinProcessorV2: values[12],
                storeClientV2: values[13],
                storeClientComponentV2: values[14],
                scenarioTagV2: values[15],
                vdirV2: values[16],
                processV2: values[17],
                datasetV2: values[18],

                platformServices: values[19],
                platformProcesses: values[20],
                platformApps: values[21],
                platformSubApps: values[22],
                platformClients: values[23],
                platformClientComponents: values[24],
            });
        }).catch(() => dispatch({type: ActionTypes.ServiceTreeRequestAction, status: RequestStatus.Failed}))
    }
}

export function getCategoryByServiceTreeLevel(level: ServiceTreeLevel): ServiceTreeCategory {
    switch(level) {
        case ServiceTreeLevel.Division:
            return CategoryDivision.Division;
        case ServiceTreeLevel.Organization:
            return CategoryDivision.Organization;
        case ServiceTreeLevel.TeamGroup:
            return CategoryDivision.TeamGroup;
        case ServiceTreeLevel.ServiceGroup:
            return CategoryDivision.ServiceGroup;
        case ServiceTreeLevel.Service:
            return CategoryDivision.Service;
    }
}


function establishIndexTree(serviceIdMap: Map<string, ServiceTreeItem>, serviceNodeMapByName: Map<string, ServiceTreeItem>, serviceTreeItems?: ServiceTreeItem[]) {
    if (!serviceTreeItems) {
        return;
    }

    serviceTreeItems.forEach(item => {
        serviceIdMap.set(item.id, item);
        serviceNodeMapByName.set(item.n, item);
        establishIndexTree(serviceIdMap, serviceNodeMapByName, item.c);
    })
}

function constructScenarioTagNoMap(scenarioTagNos: Map<string, number>, scenarioTags: Map<string, ComponentItem[]>) {
    const result = new Map<string, Set<string>>();
    scenarioTags.forEach((value) => {
        // eslint-disable-next-line prefer-const
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        for (const item of value) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const serviceName = item.serviceName;
            const scenario = item.name;
            if (!result.has(serviceName)) {
                result.set(serviceName, new Set<string>());
            }
            const sets = result.get(serviceName);
            sets?.add(scenario);
        }
    });
    result.forEach((value, key) => {
        scenarioTagNos.set(key, value.size);
    });
}