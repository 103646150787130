import { DataSourceEnum, INotification } from "../../../models/NotificationModels";
import { EventType, LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import LineChart, { LineChartSeries } from "../../common/LineChart";
import { PcmV2SceneMetrics, SubstrateLogComponent } from "../../../models/constants/PcmV2Constants";
import React, { useMemo } from "react";
import { isUndefined, sum } from "lodash";

import ChartContainer from "../../chart/ChartContainer";
import CommonConstants from "../../../models/constants/CommonConstants";
import { CostCardProps } from "../../common/costCard/CostCard";
import { PcmV2SceneTypeEnum } from "../../../models/PcmV2Models";
import { TimeView } from "../../../models/SearchKey";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { getAverageValue } from "../../../utils/ChartUtils";
import { trackEventCallback } from "../../../utils/AppInsights";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useGetPcmV2CostByCategory } from "../../../hooks/useSubstrateV2Query";
import { useTimeView } from "../../../hooks/useTimeView";
import { useTrackHovering } from "../../../hooks/useTrack";

const SubstrateTotalCostTrendsTitle: Record<PcmV2SceneTypeEnum, string> = {
    [PcmV2SceneTypeEnum.Overview]: "Total Substrate Cost Trends",
    [PcmV2SceneTypeEnum.Transaction]: "Total Transaction Cost Trends",
    [PcmV2SceneTypeEnum.Network]: "Total Network Cost Trends",
    [PcmV2SceneTypeEnum.HDD]: "Total HDD Storage Cost Trends",
    [PcmV2SceneTypeEnum.ProcessHosting]: "Total Process Hosting Cost Trends",
    [PcmV2SceneTypeEnum.SSD]: "Total SSD Storage Cost Trends",
};

const SubstrateTotalCostSeriesName: Record<PcmV2SceneTypeEnum, string> = {
    [PcmV2SceneTypeEnum.Overview]: "Total Cost",
    [PcmV2SceneTypeEnum.Transaction]: "Transaction Cost",
    [PcmV2SceneTypeEnum.Network]: "Network Cost",
    [PcmV2SceneTypeEnum.HDD]: "HDD Storage Cost",
    [PcmV2SceneTypeEnum.ProcessHosting]: "Process Hosting Cost",
    [PcmV2SceneTypeEnum.SSD]: "SSD Storage Cost",
};

const SubstrateTotalCostCardTitle: Record<PcmV2SceneTypeEnum, string> = {
    [PcmV2SceneTypeEnum.Overview]: "Total Cost",
    [PcmV2SceneTypeEnum.Transaction]: "Total Transaction Cost",
    [PcmV2SceneTypeEnum.Network]: "Total Network Cost",
    [PcmV2SceneTypeEnum.HDD]: "Total HDD Storage Cost",
    [PcmV2SceneTypeEnum.ProcessHosting]: "Total Process Hosting Cost",
    [PcmV2SceneTypeEnum.SSD]: "Total SSD Storage Cost",
};

const Component: Record<PcmV2SceneTypeEnum, LogComponent> = {
    [PcmV2SceneTypeEnum.Overview]: LogComponent.SubstrateChartingPane,
    [PcmV2SceneTypeEnum.Transaction]: LogComponent.TransChartingPane,
    [PcmV2SceneTypeEnum.Network]: LogComponent.NetworkChartingPane,
    [PcmV2SceneTypeEnum.HDD]: LogComponent.HDDChartingPane,
    [PcmV2SceneTypeEnum.ProcessHosting]:LogComponent.ProcessHostingChartingPane,
    [PcmV2SceneTypeEnum.SSD]: LogComponent.SSDChartingPane,
};

const Element: Record<PcmV2SceneTypeEnum, LogElement> = {
    [PcmV2SceneTypeEnum.Overview]: LogElement.SubstrateTotalCostTrends,
    [PcmV2SceneTypeEnum.Transaction]: LogElement.SubstrateTransactionCostTrends,
    [PcmV2SceneTypeEnum.Network]: LogElement.SubstrateNetworkingCostTrends,
    [PcmV2SceneTypeEnum.HDD]: LogElement.SubstrateHDDCostTrends,
    [PcmV2SceneTypeEnum.ProcessHosting]: LogElement.SubstrateProcesHostCostTrends,
    [PcmV2SceneTypeEnum.SSD]: LogElement.SubstrateSSDCostTrends,
};

export interface ISubstrateTotalCostTrend {
    scene: PcmV2SceneTypeEnum;
}

export const SubstrateTotalCostTrends: React.FC<ISubstrateTotalCostTrend> = (props) => {
    const query = useGetPcmV2CostByCategory();
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.SubstrateV2);
    const trackingProps = useTrackHovering(Component[props.scene], Element[props.scene], SubstrateTotalCostTrendsTitle[props.scene], LogTarget.Chart);

    const dateRange = useDateRange();
    const [ timeView ] = useTimeView();

    const series = useMemo<LineChartSeries[]>(() => {
        if (!query.data) {
            return [];
        }

        const map = new Map<number, number>();
        const metrics = PcmV2SceneMetrics[props.scene];
        for (const metric of metrics) {
            const costs = query.data[metric];
            if (costs != null && costs != undefined) {
                for (const cost of costs) {
                    const date = cost.extractionDate.valueOf();
                    map.set(date, cost.cost + (map.get(date) || 0));
                }
            }
        }

        const dates = Array.from(map.keys());
        const costSeries: LineChartSeries = {
            name: SubstrateTotalCostSeriesName[props.scene],
            data: dates.map((date) => [date, map.get(date)]),
            type: "area",
            showDeviation: true,
            anomalies: getActiveAnomalies(anomaliesQuery.data, PcmV2SceneMetrics[props.scene]),
        };

        const averageCost = getAverageValue(costSeries.data || [], timeView === TimeView.Daily);

        return [
            costSeries,
            {
                name: "Average Cost",
                color: CommonConstants.AverageColor,
                dashStyle: "Dash",
                disableAnomaly: true,
                data: costSeries.data?.map(([date, value]) => [date, isUndefined(value) ? undefined : averageCost]),
            },
        ];
    }, [query.data, props.scene, anomaliesQuery.data, timeView]);

    const chartCards = useMemo<CostCardProps[]>(() => {
        if (!query.data || props.scene === PcmV2SceneTypeEnum.Overview) {
            return [];
        }

        let totalCost = 0;

        for (const metric of PcmV2SceneMetrics[props.scene]) {
            totalCost += sum(query.data[metric].map((cost) => cost.cost));
        }

        return [
            {
                title: SubstrateTotalCostCardTitle[props.scene],
                cost: totalCost,
                color: CommonConstants.DefaultColors[0],
                showTooltip: true,
                date: [dateRange.startDate, dateRange.endDate]
            },
        ];
    }, [dateRange.endDate, dateRange.startDate, props.scene, query.data]);

    return (
        <ChartContainer
            cards={chartCards}
            headerProps={{ title: SubstrateTotalCostTrendsTitle[props.scene] }}
            loading={query.isLoading}
            chartType="line"
            chartProps={{
                series,
                height: 220,
                hideEmptySeries: true,
                anomalies: getActiveAnomalies(anomaliesQuery.data, PcmV2SceneMetrics[props.scene]),
                containerProps: trackingProps,
            }}
            logProps={{
                logComponent: SubstrateLogComponent[props.scene],
                logElement: LogElement.SubstrateTotalCostTrends,
            }}
        />
    );
};
