import styles from './MonthTableComponent.less';

import * as React from 'react';

interface IMonthTableComponent {
    trendFlag: boolean;
    gap: string;
    gapTrend: string;
    target: string;
    current: string;
}

const BigBetsMonthTableComponent: React.FC<IMonthTableComponent> = (props) => {
    const selectColor = styles.black; //!props.trendFlag ? styles.red : styles.green;

    return (
        <div>
            <div className={`${styles.gap} ${selectColor}`} style={{ display: 'flex', alignItems: 'center' }} >
                {props.gap}
                <div className={`${styles.trend} ${selectColor}`}>
                    {props.gap !== "0" && (
                        <>
                            {props.gapTrend + " "}
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 11 12" fill="none">
                                <path d={props.trendFlag ? "M5.5 0.5L0 9.66667H11L5.5 0.5Z" : "M5.5 11.5L0 2.33333H11L5.5 11.5Z"} fill={!props.trendFlag ? "#990000" : "#107C10"} />
                            </svg>
                        </>
                    )}
                </div>
            </div>
            <div className={`${styles.content}`} style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ color: '#0078D4' }}>
                    {props.target}
                </div>
                <div>|</div>
                <div className={`${styles.green}`}>
                    {props.current}
                </div>
            </div>
        </div>
    );
};

export default BigBetsMonthTableComponent;
