export const PartnerMetricNames: Record<string, string> = {
    'app-core-cost': 'App Core Cost',
    'daily-p99-utilization': 'Daily P99 Utilization',
    'daily-app-request-cores': 'Daily App Request Cores',
    'daily-app-limit-cores': 'Daily App Limit Cores',
    'daily-app-empty-cores': 'Daily App Empty Cores'
}

export const PartnerMetrics = [
    "app-core-cost",
    "daily-p99-utilization",
    "daily-app-request-cores",
    "daily-app-limit-cores",
    "daily-app-empty-cores"
];

export const LeadershipMetricNames: Record<string, string> = {
    'total-cosmic-cost': 'Total Cosmic Costs',
    'non-vm-cost': 'Non VM Costs',
    'platform-vm-cost': 'Platform VM Costs',
    'partner-vm-cost': 'Partner VM Costs',
    'daily-cosmic-cost': 'Daily Cosmic COGS',
    'daily-cosmic-cores': 'Daily Cosmic Cores',
    'daily-overhead-cores': 'Daily Overhead Cores',
    'daily-app-cores': 'Daily App Cores',
    'daily-overhead-with-rightsizing': 'Daily Overhead with Rightsizing',
    'daily-p99-app-core-utilization': 'Daily P99 Utilization',
}

export const LeadershipMetrics = [
    "total-cosmic-cost",
    "non-vm-cost",
    "platform-vm-cost",
    "partner-vm-cost",
    "daily-cosmic-cost",
    "daily-cosmic-cores",
    "daily-overhead-cores",
    "daily-app-cores",
    "daily-overhead-with-rightsizing",
    "daily-p99-app-core-utilization",
];