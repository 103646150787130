/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DirectionalHint, IPivotItemProps, IStackTokens, Link, Pivot, PivotItem, Stack, TooltipDelay, TooltipHost } from "@fluentui/react";
import {
    ISubstrateData,
    makeModifySubstrateCostTableAction,
} from "../../../reducers/substrateReducer";
import { ISubstrateTopComponents, SubstrateEntityMetrics } from "../../../models/SubstrateModels";
import LineChart, { LineChartSeries } from "../../common/LineChart";
import { PCMMetric, getPCMMetricDescription, getPCMMetricLearnMoreLink, getPCMMetricString, getPcmMetricCostField, getPCMMetricUnit } from "../../../models/PCMMetrics";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { metricDescriptions, metricLinks } from "../SubstrateCostTable/PCMMetricsConstants";
import { useDispatch, useSelector } from "react-redux";
import CommonContainer from "../../common/CommonContainer";
import CostCard from "../../common/costCard/CostCard";
import EmptyState from "../../common/state/EmptyState";
import { IAppState } from "../../../store";
// import { IDateRangeFilterData } from "../../../reducers/dateRangeReducer";
import { IServiceTreeData } from "../../../reducers/serviceTreeReducer";
import { ISubstrateTotalCostResponse } from "../../../models/SubstrateTotalCost";
import ResponseBoundary from "../../ResponseBoundary/ResponseBoundary";
import { ServiceTreeItem } from "../../../models/serviceTree";
import SubstrateCostTable from "../SubstrateCostTable/SubstrateCostTable";
import { currencyFormatter } from "../../../utils/currency";
import moment from "moment";
import styles from "./Ssd.less";
import { syncChildrenClassName } from "../../../utils/Constants";
import { toPairs } from "lodash";
import { useLocation, useParams } from "react-router-dom";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { trackEventCallback } from "../../../utils/AppInsights";
import { useGetDailyMetricsQuery, useGetSubstrateCostByService, useGetSubstrateTopFiveApp, useGetSubstrateTopFiveComponents } from "../../../hooks/useSubstrateQuery";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { useGotoTab } from "../../../hooks/useGotoPage";
import { bannerTabs, Pages, SubPages } from "../../../models/Nav";
import PageHeader from "../../common/PageHeader";
import { useTrackHovering } from "../../../hooks/useTrack";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { useGetBannersByTabAndDateQuery } from "../../../hooks/useBannerQuery";
import { WarningBanner } from "../../banner/WarningBanner";

const tabMetrics: PCMMetric[][] = [
    [PCMMetric.ItemSize],
    [PCMMetric.SDSFastStorage],
    [PCMMetric.KvCache]
]

const costTypes = tabMetrics.map(metrics => metrics.map(metric => getPCMMetricString(metric)).join(", "));

const costLogElements = [
    LogElement.ItemSize,
    LogElement.SDSFastStorage,
    LogElement.KvCache
];

const stackToken: IStackTokens = { childrenGap: 48 };

const subStackToken: IStackTokens = { childrenGap: 32 };

const columnListConfigs = {
    item: [
        SubstrateEntityMetrics.ItemSizeCost,
        SubstrateEntityMetrics.MaxItemSize,
        SubstrateEntityMetrics.DevOwner,
        SubstrateEntityMetrics.PmOwner
    ],
    sds: [
        SubstrateEntityMetrics.SdsFastStorageCost,
        SubstrateEntityMetrics.MaxLLCSizeGb,
        SubstrateEntityMetrics.DevOwner,
        SubstrateEntityMetrics.PmOwner
    ],
    kvchache: [
        SubstrateEntityMetrics.KvCacheCost,
        SubstrateEntityMetrics.KvCacheSize,
        SubstrateEntityMetrics.DevOwner,
        SubstrateEntityMetrics.PmOwner
    ],
}

const defaultColumnConfigs = {
    item: new Set<SubstrateEntityMetrics>([
        SubstrateEntityMetrics.ItemSizeCost,
        SubstrateEntityMetrics.MaxItemSize,
        SubstrateEntityMetrics.DevOwner,
        SubstrateEntityMetrics.PmOwner
    ]),
    sds: new Set<SubstrateEntityMetrics>([
        SubstrateEntityMetrics.SdsFastStorageCost,
        SubstrateEntityMetrics.MaxLLCSizeGb,
        SubstrateEntityMetrics.DevOwner,
        SubstrateEntityMetrics.PmOwner
    ]),
    kvchache: new Set<SubstrateEntityMetrics>([
        SubstrateEntityMetrics.KvCacheCost,
        SubstrateEntityMetrics.KvCacheSize,
        SubstrateEntityMetrics.DevOwner,
        SubstrateEntityMetrics.PmOwner
    ]),
}

function updateColumnList(key: string): SubstrateEntityMetrics[] {
    if (key == "SDS Fast Storage") {
        return columnListConfigs.sds;
    }
    if (key == "KvCache") {
        return columnListConfigs.kvchache;
    }
    if (key == "Item Size") {
        return columnListConfigs.item;
    }
    return columnListConfigs.item;
}

function updateColumns(key: string): Set<SubstrateEntityMetrics> {
    if (key == "SDS Fast Storage") {
        return defaultColumnConfigs.sds;
    }
    if (key == "KvCache") {
        return defaultColumnConfigs.kvchache;
    }
    if (key == "Item Size") {
        return defaultColumnConfigs.item;
    }
    return defaultColumnConfigs.item;
}

const SsdCostView: React.FC = () => {
    const dispatch = useDispatch();
    const substrateData = useSelector<IAppState, ISubstrateData>(state => state.substrate);
    const filtersData = useCategoryFilters().filters;
    const {isLoading: isDailyMetricsLoading, data: dailyMetrics, isError: isDailyMetricsError} = useGetDailyMetricsQuery();
    const metricUsage = useMemo(() => dailyMetrics?.distribution.usage, [dailyMetrics?.distribution.usage]);
    const metricCosts = useMemo(() => dailyMetrics?.distribution.cost, [dailyMetrics?.distribution.cost]);
    const ssdCosts = useMemo(() => tabMetrics.map(tab => tab.reduce((prev, cur) => prev + (metricCosts?.[cur] ?? 0), 0)), [metricCosts]);
    const { tab } = useParams();
    const selectedCostType = useMemo(() => tab || costTypes[0], [tab]);
    const gotoTab = useGotoTab();
    const {isLoading: isCostByServiceLoading, data: costByService, isError: isCostByServiceError} = useGetSubstrateCostByService(selectedCostType);
    const selectedMetrics = useMemo(() => tabMetrics[costTypes.indexOf(selectedCostType)], [selectedCostType])
    const selectedCost = useMemo(() => selectedMetrics.reduce((prev, cur) => prev + (metricCosts?.[cur] ?? 0), 0), [metricCosts, selectedMetrics]);
    const { data: allBanners } = useGetBannersByTabAndDateQuery(bannerTabs.Ssd);

    const trackChartHoveringProps = useTrackHovering(LogComponent.Substrate, LogElement.SubstrateTopFive, "SSD Top Five Cost", LogTarget.Chart);

    const serviceTree = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const [columns, setColumns] = useState(defaultColumnConfigs.item);
    const [mounted, setMounted] = useState(false)
    const state = useLocation()['state'] as Record<string, string>
    if (!mounted) {
        if (state) 
        {   
            const key = state['key'] as string
            gotoTab(`${Pages.Substrate}/${SubPages.Ssd}`, key, `${Pages.Substrate}/${SubPages.Ssd}`)
            setColumns(updateColumns(key));
        }
        setMounted(true)
    }
    
    const [columnList, setAllColumnList] = useState(columnListConfigs.item);

    const handleLinkClick = useCallback((item?: PivotItem) => {
        if (item?.props.itemKey) {
            gotoTab(`${Pages.Substrate}/${SubPages.Ssd}`, item.props.itemKey, `${Pages.Substrate}/${SubPages.Ssd}`);
        }
    }, [gotoTab]);
    const headerRenderer = useCallback((index: number, link?: IPivotItemProps, defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null) => {
        if (!link || !defaultRenderer) {
            return null;
        }

        return (
            <>
                {defaultRenderer({ ...link })}
                &nbsp;&nbsp;
                <span className={styles.costColor}>{isDailyMetricsLoading ? "loading..." : currencyFormatter(ssdCosts[index], 0)}</span>
            </>
        );
    }, [isDailyMetricsLoading, ssdCosts]);

    const pivotItems = useMemo(() => costTypes.map((t, i) => <PivotItem headerText={t} itemKey={t} key={t} onRenderItemLink={(link, defaultRender) => headerRenderer(i, link, defaultRender)} />), [headerRenderer]);
    const costKeys = useMemo(() => selectedMetrics.map(m => getPcmMetricCostField(m)), [selectedMetrics]);

    //const dateRangeFilterData = useSelector<IAppState, IDateRangeFilterData>(state => state.dateRangeFilterData);
    const topNMetric = getPcmMetricCostField(selectedMetrics[0]);
    const topServicesNumber = useMemo(() => {
        return 5;
    }, []);
    const topAppsNumber = useMemo(() => {
        return 5;
    }, []);
    useEffect(() => {
        dispatch(makeModifySubstrateCostTableAction(columns));
    }, [columns, dispatch]);
    useEffect(() => {
        setColumns(updateColumns(selectedCostType));
        setAllColumnList(updateColumnList(selectedCostType));
        trackEventCallback(LogComponent.SSDChartingPane, costLogElements[costTypes.indexOf(selectedCostType)], selectedCostType, LogTarget.Tab);
    }, [selectedCostType])
    const topN = 5;
    const topAppData = useGetSubstrateTopFiveApp(topNMetric, topAppsNumber);
    const topServiceData = useGetSubstrateTopFiveComponents(topNMetric, topServicesNumber);
    const userNameDirectly = useMemo(() => {
        return (filtersData.filters.Service?.length == 1 && !filtersData.filters.GriffinApp
            && !filtersData.filters.SSDDataSetName && !filtersData.filters.Process
            && !filtersData.filters.VDir) ||
            (!filtersData.filters.Service && Boolean(filtersData.filters.GriffinApp
                || filtersData.filters.SSDDataSetName || filtersData.filters.Process
                || filtersData.filters.VDir));
    }, [filtersData.filters]);
    const type = userNameDirectly ? "DataSets" : "Services";
    const serviceLineSeries = useMemo(
        () =>  createLineSeriesBy(userNameDirectly, topServiceData.data, serviceTree.indexMap, costKeys[0] as keyof ISubstrateTotalCostResponse),
        [costKeys, serviceTree.indexMap, topServiceData.data, userNameDirectly]
    );

    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.Substrate);
    const activeAnomalies = useMemo(() => getActiveAnomalies(anomaliesQuery.data, selectedMetrics), [anomaliesQuery.data, selectedMetrics]);

    return (
        <Stack tokens={stackToken}>
            <div className={syncChildrenClassName}>
            <PageHeader
                title="SSD Cost View"
                description="SSD Cost is charged by storage space.   There are 3 metrics that represents the SSD services on Substrate. Click the tabs below for more details."
            />
            </div>
            {
                Boolean(allBanners?.length) &&
                allBanners?.map(item => (
                    <WarningBanner key={item.id} bannerItem={item} />
                ))
            }
            <Pivot
                className={`${styles.pivot} ${syncChildrenClassName}`}
                selectedKey={selectedCostType}
                onLinkClick={handleLinkClick}
                headersOnly={true}
                overflowBehavior="menu"
            >
                {pivotItems}
            </Pivot>
            {selectedCost === 0 && !isDailyMetricsLoading
                ? <Stack horizontalAlign="center">
                    <div className={styles.emptySvg}><EmptyState /></div>
                    <div className={styles.noDataTitle}>No Costs yet</div>
                    <div className={styles.noDataDescription}>The selected workload have no cost here yet.</div>
                    <Link className={styles.link}>Learn more about Substrate</Link>
                </Stack>
                : <Stack tokens={stackToken}>
                    <Stack horizontal tokens={stackToken} className={syncChildrenClassName}>
                        <CommonContainer title={"Total " + selectedCostType + " Cost"}>
                            <ResponseBoundary
                                isLoading={isDailyMetricsLoading}
                                errorHappened={isDailyMetricsError}
                                data={dailyMetrics?.distribution}
                            >
                                    {selectedMetrics.map((metric, i) => {
                                        const metricString = getPCMMetricString(metric);
                                        const description = getPCMMetricDescription(metric);
                                        const learnMoreLink = getPCMMetricLearnMoreLink(metric);
                                        return (
                                        <React.Fragment key={metric}>
                                            <div className={styles.grid}>
                                                <CostCard
                                                    title={metricString + " Cost"}
                                                    cost={metricCosts?.[metric] ?? 0}
                                                    color={i}
                                                />
                                            </div>
                                            <TooltipHost
                                                key={metric}
                                                tooltipProps={{
                                                    onRenderContent: () => (
                                                        <div>
                                                            {metricDescriptions[description]}
                                                        </div>
                                                    ),
                                                }}
                                                delay={TooltipDelay.zero}
                                                id="tooltipId"
                                                directionalHint={DirectionalHint.bottomCenter}
                                            >
                                                <span id="tooltipId" className={styles.metricDescription}>{metricDescriptions[description]}</span>
                                                {learnMoreLink && <Link target="_blank" href={metricLinks[learnMoreLink]}>Learn More</Link>}
                                            </TooltipHost>
                                        </React.Fragment>
                                        );
                                    })}
                            </ResponseBoundary>
                        </CommonContainer>
                        <CommonContainer title={`Top ${topN} ${type} of ${selectedCostType} Cost Trends`}>
                            <ResponseBoundary
                                data={topAppData.data || topServiceData.data}
                                isLoading={topAppData.isLoading || topServiceData.isLoading || serviceTree.isLoading}
                                errorHappened={topAppData.isError || topServiceData.isError}
                            >
                                <Stack tokens={subStackToken}>
                                    <LineChart series={serviceLineSeries!} containerProps={trackChartHoveringProps} anomalies={activeAnomalies} />
                                </Stack>
                            </ResponseBoundary>
                        </CommonContainer>
                    </Stack>
                    <ResponseBoundary
                        isLoading={isCostByServiceLoading}
                        errorHappened={isCostByServiceError}
                        data={costByService}
                    >
                        <div>
                            <SubstrateCostTable
                                columns={columnList}
                                defaultSortingColumn={columnList[0]}
                                costByService={costByService}
                                globalSelectedColumns={substrateData.substrateCostTableSelectedColumns}
                                serviceTreeIndexMap={serviceTree.indexMap}
                                fixedColumn="Service - App/Process/Client"
                                makeChangeColumnsAction={makeModifySubstrateCostTableAction}
                                scenarioNoMap={serviceTree.scenarioNoMap}
                                appScenarios={serviceTree.appScenarios}
                                scenarioView={false}
                                logComponent={LogComponent.SSDChartingPane}
                            />
                        </div>
                    </ResponseBoundary>
                </Stack>
            }
        </Stack>
    )
}

 function createLineSeriesBy(
     userNameDirectly = false,
     data: ISubstrateTopComponents | undefined,
     serviceTreeMap: Map<string, ServiceTreeItem> | undefined,
     iteratee: keyof ISubstrateTotalCostResponse | ((value: ISubstrateTotalCostResponse) => number)): LineChartSeries[] {
    const exactIteratee = typeof iteratee === "string" ? (value: ISubstrateTotalCostResponse) => value[iteratee] : iteratee;
    return toPairs(data).map(([id, points]) => {
        const list: [number, number][] = [];
        let sum = 0;
        for (const point of points) {
            const y = exactIteratee(point);
            if (y && typeof y === "number") {
                list.push([moment.utc(point.extractionDate).valueOf(), y]);
                sum += y;
            }
        }
        return {
            name: userNameDirectly ? id : (serviceTreeMap ? serviceTreeMap.get(id)?.n || "unknown" : id),
            data: list,
            sum
        }
    }).sort((a, b) => b.sum - a.sum);
}

export default SsdCostView;