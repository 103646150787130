import React from "react";
import Highcharts, { SeriesLineOptions, YAxisOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { currencyFormatter } from "../../../../utils/currency";
import { getColor } from "../../../common/costCard/CostCardUtils";
import ChartContainer from "../../../chart/ChartContainer";
import { EmptyModule } from "../../../common/state/EmptyModule";

export interface IStackChartDataProps {
    name: string;
    values: number[];
}

interface IStackChartProps {
    data: IStackChartDataProps[];
    categories: string[];
    description: string;
    yAxisTitle: string;
    hasBenchmark?: boolean;
    benchmarkTitle?: string;
    benchmarkValues?: number[];
}

export function customFormat(this: Highcharts.TooltipFormatterContextObject) {
    const { point, series } = this;
    const { total } = point;
    const percentage = point === undefined || total === undefined ? 0 : ((point.y || 0) / total) * 100;

    let tooltipContent = `<b>${this.x}</b><br><br>`;

    series.chart.series.forEach((s: Highcharts.Series) => {
        const dataPoint = s.points.find((p: Highcharts.Point) => p.x === point.x);
        const seriesName = s.name;
        const seriesValue = dataPoint?.y ?? 0;
        const formattedSeriesValue = currencyFormatter(seriesValue, 2, "$");
        if (s === series) {
            tooltipContent += `<b>${seriesName}: ${formattedSeriesValue}</b><br/>`;
        } else {
            tooltipContent += `${seriesName}: ${formattedSeriesValue}<br/>`;
        }
    });

    if (!this.series.name.toLowerCase().includes("budget")) {
        tooltipContent += `<br/><b>Total: ${currencyFormatter(total ?? 0, 2, "$")}<br/>Percentage: ${percentage.toFixed(2)}%<b/>`;
    }
    return tooltipContent;
}

function getOptions(
    data: IStackChartDataProps[],
    categories: string[],
    yAxisTitle: string,
    hasBenchmark?: boolean,
    benchmarkTitle?: string,
    benchmarkValues?: number[]
) {
    const dataSeries = data.map(({ name, values }, i) => ({
        name,
        data: values,
        color: getColor(i),
        type: "column",
    }));

    const yAxisData: Array<YAxisOptions> = [
        {
            min: 0,
            title: {
                text: yAxisTitle,
            },
            stackLabels: {
                enabled: false,
            },
        },
    ];

    if (hasBenchmark) {
        yAxisData.push({
            title: {
                text: benchmarkTitle,
            },
            opposite: true,
        });
    }

    return {
        chart: {
            type: "column",
            style: {
                fontFamily: "Segoe UI, sans-serif",
                fontSize: "14px",
                paddingTop: "10px",
            },
        },
        title: {
            text: undefined,
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            categories,
        },
        yAxis: yAxisData,
        legend: {
            align: "right",
            x: 0,
            verticalAlign: "top",
            y: -10,
            floating: true,
            backgroundColor: "white",
            borderColor: "#CCC",
            borderWidth: 1,
            shadow: true,
        },
        tooltip: {
            formatter: customFormat,
        },
        plotOptions: {
            column: {
                stacking: "normal",
                dataLabels: {
                    enabled: false,
                },
            },
        },
        series: [
            ...dataSeries,
            ...(hasBenchmark
                ? [
                      {
                          type: "line",
                          name: benchmarkTitle,
                          data: benchmarkValues,
                          color: "grey",
                          dashStyle: "Dash",
                          marker: {
                              enabled: true,
                              radius: 3,
                          },
                      } as SeriesLineOptions,
                  ]
                : []),
        ],
    };
}

const StackChart = (props: IStackChartProps) => {
    const { data, categories, description, yAxisTitle, hasBenchmark, benchmarkTitle, benchmarkValues } = props;
    const options = React.useMemo(
        () => getOptions(data, categories, yAxisTitle, hasBenchmark, benchmarkTitle, benchmarkValues),
        [data, categories, yAxisTitle, hasBenchmark, benchmarkTitle, benchmarkValues]
    );
    const isEmpty = React.useMemo(
        () => data === null || data.length === 0 || data.every((d) => d.values === null || d.values.length === 0 || d.values.every((v) => v === 0)),
        [data]
    );

    return (
        <ChartContainer headerProps={{ title: description }}>
            {isEmpty ? <EmptyModule iconName="EmptyBarChart" /> : <HighchartsReact highcharts={Highcharts} options={options} />}
        </ChartContainer>
    );
};

export default StackChart;
