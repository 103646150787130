import { PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum } from "../../../models/PcmV2Models";

import React from "react";
import { SubstrateCategoryCostTrends } from "../common/SubstrateCategoryCostTrends";
import { SubstrateCostRanking } from "../common/SubstrateCostRanking";
import { SubstrateCostTable } from "../common/table/SubstrateCostTable";
import { SubstrateTopFiveTrends } from "../common/SubstrateTopFiveTrends";
import { SubstrateTotalCostTrends } from "../common/SubstrateTotalCostTrends";
import commonStyles from "../../common/styles/Common.less";
import { useFlights } from "../../../hooks/useSettings";

interface ITransactionByServiceProps {
    insights: JSX.Element[];
}

export const TransactionByService: React.FC<ITransactionByServiceProps> = (props) => {
    const { data: flights } = useFlights();
    return (
        <div>
            <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`}>
                {flights?.enableStoreIOInsights && props.insights.map((x) => {
                return x;})}
                <SubstrateTotalCostTrends scene={PcmV2SceneTypeEnum.Transaction} />
                <SubstrateCategoryCostTrends scene={PcmV2SceneTypeEnum.Transaction} />
                <SubstrateTopFiveTrends scene={PcmV2SceneTypeEnum.Transaction} subScene={PcmV2SubSceneTypeEnum.TransactionCost} />
                <SubstrateCostRanking scene={PcmV2SceneTypeEnum.Transaction} subScene={PcmV2SubSceneTypeEnum.TransactionCost} />
            </div>
            <SubstrateCostTable scene={PcmV2SceneTypeEnum.Transaction} subScene={PcmV2SubSceneTypeEnum.TransactionCost} />
        </div>
    );
};
