import moment from "moment";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey } from "../models/SearchKey";

export function useDateRange() {
    const [searchParams, setSearchParams] = useSearchParams();

    return useMemo(() => {
        const startDateStr = searchParams.get(SearchKey.StartDate);
        const endDateStr = searchParams.get(SearchKey.EndDate);
        const singleDateStr = searchParams.get(SearchKey.SingleDate);
        const fiscalYearStr = searchParams.get(SearchKey.FiscalYear);

        let startDate: moment.Moment;
        let endDate: moment.Moment;
        if (startDateStr && endDateStr) {
            startDate = moment(startDateStr);
            endDate = moment(endDateStr);
        } else {
            startDate = moment().subtract(37, "days");
            endDate = moment().subtract(7, "days");
        }

        const lastMonth = endDate.clone().subtract(1, "month").subtract(3, "days");
        const lastYear = lastMonth.clone().subtract(1, "year");

        let singleDate: moment.Moment;
        if (singleDateStr) {
            singleDate = moment(singleDateStr);
        } else {
            singleDate = moment().startOf("day").subtract(7, "days");
        }

        let fiscalYear: number;
        if (fiscalYearStr) {
            fiscalYear = parseInt(fiscalYearStr);
        } else {
            fiscalYear = 22;
        }

        const setDateRange = (startDateParam?: moment.Moment, endDateParam?: moment.Moment) => {
            if (startDate.isSame(startDateParam) && endDate.isSame(endDateParam)) {
                return;
            }
            const newSearchParams = new URLSearchParams(searchParams);
            if (startDateParam && endDateParam) {
                newSearchParams.set(SearchKey.StartDate, startDateParam.format("YYYY-MM-DD"));
                newSearchParams.set(SearchKey.EndDate, endDateParam.format("YYYY-MM-DD"));
            } else {
                newSearchParams.delete(SearchKey.StartDate);
                newSearchParams.delete(SearchKey.EndDate);
            }
            setSearchParams(newSearchParams);
        };

        const setDate = (date?: moment.Moment) => {
            const newSearchParams = new URLSearchParams(searchParams);
            if (date) {
                newSearchParams.set(SearchKey.SingleDate, date.format("YYYY-MM-DD"));
            } else {
                newSearchParams.delete(SearchKey.SingleDate);
            }
            setSearchParams(newSearchParams);
        };

        const setFiscalYear = (fiscalYear?: number) => {
            const newSearchParams = new URLSearchParams(searchParams);
            if (fiscalYear) {
                newSearchParams.set(SearchKey.FiscalYear, fiscalYear.toString());
            } else {
                newSearchParams.delete(SearchKey.FiscalYear);
            }
            setSearchParams(newSearchParams);
        };

        return {startDate, endDate, singleDate, fiscalYear, setDateRange, setDate, setFiscalYear, lastYear, lastMonth};
    }, [searchParams, setSearchParams]);
}