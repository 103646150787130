import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey } from "../models/SearchKey";
import { ESArtifactDataType } from "../models/ESCostModels";

export function useEsArtifactDataType(): [ESArtifactDataType | undefined, (newDataType?: ESArtifactDataType) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const dataType: ESArtifactDataType | undefined = useMemo(() => {
        const dataType = searchParams.get(SearchKey.ESArtifactDataType);
        if (!dataType) {
            return undefined;
        }
        return dataType as ESArtifactDataType;
    }, [searchParams]);

    const setDataType = useCallback((newDataType?: ESArtifactDataType) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (!newDataType) {
            newSearchParams.delete(SearchKey.ESArtifactDataType);
        } else {
            newSearchParams.set(SearchKey.ESArtifactDataType, newDataType);
        }

        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return [dataType, setDataType];
}