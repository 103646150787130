import { values } from "lodash";
import { PCMMetric } from "./PCMMetrics";
import moment from "moment";
import { ISubstrateTotalCostResponse } from "./SubstrateTotalCost";

export interface ISubstrateTotalCostDistributionData {
    cost: Record<PCMMetric, number>;
    usage: Record<PCMMetric, number>;
}

export type ISubstrateTotalCostTimeSeriesData = [moment.Moment, number][];

export interface ISubstrateDailyMetrics {
    distribution: ISubstrateTotalCostDistributionData;
    timeSeries: ISubstrateTotalCostTimeSeriesData;
    details: [PCMMetric, ISubstrateTotalCostTimeSeriesData][];
}

export interface ISubstrateEntity {
    name?: string | null;
    totalCost: number;
    itemReadsCost: number;
    itemWritesCost: number;
    itemQueriesCost: number;
    ebaCost: number;
    tbaCost: number;
    swssCost: number;
    ebaCount: number;
    tbaCount: number;
    swssCount: number;
    totalAssistantsCost: number,
    serviceInstanceCost: number;
    serviceInstanceCount: number;
    serviceInstanceCpu: number;
    serviceInstanceMemory: number;
    cfmSubmittedCost: number;
    cfmSubmittedCount: number;
    dsApiRequestsCost: number;
    dsApiRequestsCount: number;
    kvCacheCost: number;
    kvCacheSize: number;
    requestProcessedCost: number;
    sdsFastStorageCost: number;
    sdsFastStorageSize: number;
    itemSizeCost: number;
    itemSize: number;
    itemQueries: number;
    itemReads: number;
    itemWrites: number;
    requestsProcessed: number;
    offPeakHourItemQueries: number;
    offPeakHourItemReads: number;
    offPeakHourItemWrites: number;
    offPeakHourRequestsProcessed: number;
    peakHourItemQueries: number;
    peakHourItemReads: number;
    peakHourItemWrites: number;
    peakHourRequestsProcessed: number;
    griffinApp?: string | null;
    scenarioTag?: string;
    scenarioTagCount: number;
    totalreadwritesqueriescost: number;
    devOwner: string;
    pmOwner: string;

    maxServiceInstanceCount: number;
    maxServiceInstanceCpu: number;
    maxServiceInstanceMemory: number;
    maxLLCSizeGb: number;
    maxItemSize: number;
    children?: ISubstrateEntity[];
    griffinProcessor?: string;

    // carbon
    totalCarbonEmission?: number;
    carbonScope2Emission?: number;
    carbonScope3Emission?: number;
}

export enum SubstrateEntityMetrics {
    Name = "Service",
    TotalCost = "Total Substrate Cost",
    ItemReadsCost = "Item Reads Cost",
    ItemWritesCost = "Item Writes Cost",
    ItemQueriesCost = "Item Queries Cost",
    EBACost = "EBA Cost",
    TBACost = "TBA Cost",
    SWSSCost = "SWSS Cost",
    EBACount = "EBA Count",
    TBACount = "TBA Count",
    SWSSCount = "SWSS Count",
    TotalAssistantsCost = "Total Assistants Cost",
    ServiceInstanceCost = "Service Instance Cost",
    ServiceInstanceCount = "Service Instance Count (Monthly Max)",
    ServiceInstanceCpu = "Used Cores (Monthly Max)",
    ServiceInstanceMemory = "Used Memory (Monthly Max)",
    RequestProcessCost = "Requests Processed Cost",
    DsapiRequestsCost = "DSAPI Requests Cost",
    DsapiRequestsCount = "DSAPI Requests Count",
    CfmSubmittedCost = "CFM Cost",
    CfmSubmittedCount = "CFM Count",
    ItemSizeCost = "Item Size Cost",
    ItemSize = "Item Size (GB)",
    KvCacheCost = "KvCache Cost",
    KvCacheSize = "KvCache Size (GB) (Monthly Max)",
    SdsFastStorageCost = "SDS Fast Storage Cost",
    MaxLLCSizeGb = "SDS Fast Storage Size (GB) (Monthly Max)",
    DevOwner = "Dev Owner",
    PmOwner = "PM Owner",
    ScenarioTag = "Scenario Tag",
    ItemQueriesCount = "Item Queries Rows Returned",
    ItemReadsCount = "Item Reads Count",
    ItemWritesCount = "Item Writes Count",
    OffPeakHourItemQueriesCount = "Off-peak Hour Item Queries Rows Returned",
    OffPeakHourItemReadsCount = "Off-peak Hour Item Reads Count",
    OffPeakHourItemWritesCount = "Off-peak Hour Item Writes Count",
    PeakHourItemQueriesCount = "Peak Hour Item Queries Rows Returned",
    PeakHourItemReadsCount = "Peak Hour Item Reads Count",
    PeakHourItemWritesCount = "Peak Hour Item Writes Count",
    RequestProcessedCount = "Request Processed Count",
    OffPeakHourRequestProcessedCount = "Off-peak Hour Request Processed Count",
    PeakHourRequestProcessedCount = "Peak Hour Request Processed Count",
    GriffinApp = "Griffin App",
    TotalItemReadsWritesQueriesCost = "Total Item Reads, Writes, Queries Cost",
    MaxServiceInstanceCount = "Service Instance Count (Monthly Max)",
    MaxServiceInstanceCpu = "Used Cores (Monthly Max)",
    MaxServiceInstanceMemory = "Used Memory (Monthly Max)",
    GriffinProcessor = "Griffin Processor",
    MaxItemSize = "Item Size (GB) (Monthly Max)",
    TotalCarbonEmission = "Total Carbon Emission",
    CarbonScope2Emission = "Carbon Scope2 Emission",
    CarbonScope3Emission = "Carbon Scope3 Emission"
}

export type ISubstrateCostByServiceGriffinProcessor = ISubstrateEntity[];
export type ISubstrateMonthOnMonthData = [string, string, number, string, number][];
export type ISubstrateDailyCarbonEmission = [string, number][];
export type ISubstrateRingData = { [key: string]: [moment.Moment, number][] };
export type ISubstrateTopComponents = { [key: string]: [ISubstrateTotalCostResponse] };

export interface ISubstrateEntityResponse {
    [name: string]: ISubstrateTotalCostResponse[];
}

export function getSubstrateCostByServiceGriffinProcessor(response: ISubstrateEntityResponse): ISubstrateCostByServiceGriffinProcessor {
    return values(response).map(serviceItem => {
        const components = serviceItem.map(transferToSubstrateEntity);

        const service: ISubstrateEntity = {
            name: serviceItem[0].serviceId,
            itemReadsCost: 0,
            itemQueriesCost: 0,
            itemWritesCost: 0,
            ebaCost: 0,
            tbaCost: 0,
            swssCost: 0,
            ebaCount: 0,
            tbaCount: 0,
            swssCount: 0,
            totalAssistantsCost: 0,
            serviceInstanceCost: 0,
            serviceInstanceCount: 0,
            serviceInstanceCpu: 0,
            serviceInstanceMemory: 0,
            cfmSubmittedCost: 0,
            cfmSubmittedCount: 0,
            dsApiRequestsCost: 0,
            dsApiRequestsCount: 0,
            kvCacheCost: 0,
            kvCacheSize: 0,
            requestProcessedCost: 0,
            sdsFastStorageCost: 0,
            sdsFastStorageSize: 0,
            itemSizeCost: 0,
            itemSize: 0,
            itemQueries: 0,
            scenarioTagCount: 0,
            itemReads: 0,
            itemWrites: 0,
            requestsProcessed: 0,
            offPeakHourItemQueries: 0,
            offPeakHourItemReads: 0,
            offPeakHourItemWrites: 0,
            offPeakHourRequestsProcessed: 0,
            peakHourItemQueries: 0,
            peakHourItemReads: 0,
            peakHourItemWrites: 0,
            peakHourRequestsProcessed: 0,
            devOwner: serviceItem[0].serviceDevOwners || "-",
            pmOwner: serviceItem[0].servicePMOwners || "-",
            totalCost: 0,
            totalreadwritesqueriescost: 0,
            maxServiceInstanceCount: 0,
            maxServiceInstanceCpu: 0,
            maxServiceInstanceMemory: 0,
            maxLLCSizeGb: 0,
            maxItemSize: 0,
            griffinProcessor: "-",
        };

        components.forEach(component => {
            service.itemReadsCost += component.itemReadsCost;
            service.itemWritesCost += component.itemWritesCost;
            service.itemQueriesCost += component.itemQueriesCost;
            service.ebaCost += component.ebaCost;
            service.tbaCost += component.tbaCost;
            service.swssCost += component.swssCost;
            service.ebaCount += component.ebaCount;
            service.tbaCount += component.tbaCount;
            service.swssCount += component.swssCount;
            service.totalAssistantsCost += component.totalAssistantsCost;
            service.serviceInstanceCost += component.serviceInstanceCost;
            service.serviceInstanceCount += component.serviceInstanceCount;
            service.serviceInstanceCpu += component.serviceInstanceCpu;
            service.serviceInstanceMemory += component.serviceInstanceMemory;
            service.cfmSubmittedCost += component.cfmSubmittedCost;
            service.cfmSubmittedCount += component.cfmSubmittedCount;
            service.dsApiRequestsCost += component.dsApiRequestsCost;
            service.dsApiRequestsCount += component.dsApiRequestsCount;
            service.kvCacheCost += component.kvCacheCost;
            service.kvCacheSize += component.kvCacheSize;
            service.requestProcessedCost += component.requestProcessedCost;
            service.sdsFastStorageCost += component.sdsFastStorageCost;
            service.sdsFastStorageSize += component.sdsFastStorageSize;
            service.itemSizeCost += component.itemSizeCost;
            service.itemSize += component.itemSize;
            service.totalCost += component.totalCost;
            service.itemWrites += component.itemWrites;
            service.itemQueries += component.itemQueries;
            service.itemReads += component.itemReads;
            service.requestsProcessed += component.requestsProcessed;
            service.offPeakHourItemWrites += component.offPeakHourItemWrites;
            service.offPeakHourItemQueries += component.offPeakHourItemQueries;
            service.offPeakHourItemReads += component.offPeakHourItemReads;
            service.offPeakHourRequestsProcessed += component.offPeakHourRequestsProcessed;
            service.peakHourItemWrites += component.peakHourItemWrites;
            service.peakHourItemQueries += component.peakHourItemQueries;
            service.peakHourItemReads += component.peakHourItemReads;
            service.peakHourRequestsProcessed += component.peakHourRequestsProcessed;
            service.totalreadwritesqueriescost += component.totalreadwritesqueriescost;
            service.maxServiceInstanceMemory += component.maxServiceInstanceMemory;
            service.maxLLCSizeGb += component.maxLLCSizeGb;
            service.maxItemSize += component.maxItemSize;
            service.maxServiceInstanceCount += component.maxServiceInstanceCount;
            service.maxServiceInstanceCpu += component.maxServiceInstanceCpu;
            service.scenarioTagCount += component.scenarioTagCount;
        });

        service.children = components.filter(item => item.totalCost > 1);

        return service;
    });
}

export function transferToSubstrateEntity(costResponse: ISubstrateTotalCostResponse): ISubstrateEntity {
    const itemReadsCost = costResponse.itemReadsCost;
    const itemWritesCost = costResponse.itemWritesCost;
    const itemQueriesCost = costResponse.itemQueriesCost;
    const ebaCost = costResponse.ebaCountCost;
    const tbaCost = costResponse.tbaCountCost;
    const swssCost = costResponse.swssCountCost;
    const ebaCount = costResponse.ebaCount;
    const tbaCount = costResponse.tbaCount;
    const swssCount = costResponse.swssCount;
    const serviceInstanceCost = costResponse.serviceInstanceCost;
    const serviceInstanceCount = costResponse.serviceInstanceCount;
    const serviceInstanceCpu = costResponse.serviceInstanceCpu;
    const serviceInstanceMemory = costResponse.serviceInstanceMemory;
    const cfmSubmittedCost = costResponse.cfmSubmittedCountCost;
    const cfmSubmittedCount = costResponse.cfmSubmittedCount;
    const dsApiRequestsCost = costResponse.dsapiRequestsCountCost;
    const dsApiRequestsCount = costResponse.dsapiRequestsCount;
    const kvCacheCost = costResponse.kvCacheSizeGbCost;
    const kvCacheSize = costResponse.kvCacheSizeGb;
    const requestProcessedCost = costResponse.requestsProcessedCost;
    const sdsFastStorageCost = costResponse.llcSizeGbCost;
    const sdsFastStorageSize = costResponse.llcSizeGb;
    const itemSizeCost = costResponse.itemSizeCost;
    const itemSize = costResponse.itemSize;
    const itemQueries = costResponse.itemQueries;
    const itemReads = costResponse.itemReads;
    const itemWrites = costResponse.itemWrites;
    const requestsProcessed = costResponse.requestsProcessed;
    const offPeakHourItemQueries = costResponse.itemQueries - costResponse.peakHourItemQueries;
    const offPeakHourItemReads = costResponse.itemReads - costResponse.peakHourItemReads;
    const offPeakHourItemWrites = costResponse.itemWrites - costResponse.peakHourItemWrites;
    const offPeakHourRequestsProcessed = costResponse.requestsProcessed - costResponse.peakHourRequestsProcessed;
    const peakHourItemQueries = costResponse.peakHourItemQueries;
    const peakHourItemReads = costResponse.peakHourItemReads;
    const peakHourItemWrites = costResponse.peakHourItemWrites;
    const peakHourRequestsProcessed = costResponse.peakHourRequestsProcessed;
    const scenarioTag = costResponse.scenarioTag;
    const scenarioTagCount = costResponse.scenarioTagCount;
    const maxLLCSizeGb = costResponse.maxLLCSizeGb;
    const maxServiceInstanceCount = costResponse.maxServiceInstanceCount;
    const maxServiceInstanceCpu = costResponse.maxServiceInstanceCpu;
    const maxServiceInstanceMemory = costResponse.maxServiceInstanceMemory;

    return {
        name: costResponse.appName,
        itemReadsCost,
        itemWritesCost,
        itemQueriesCost,
        ebaCost,
        tbaCost,
        swssCost,
        ebaCount,
        tbaCount,
        swssCount,
        totalAssistantsCost: ebaCost + tbaCost + swssCost,
        serviceInstanceCost,
        serviceInstanceCount,
        serviceInstanceCpu,
        serviceInstanceMemory,
        cfmSubmittedCost,
        cfmSubmittedCount,
        dsApiRequestsCost,
        dsApiRequestsCount,
        kvCacheCost,
        kvCacheSize,
        requestProcessedCost,
        sdsFastStorageCost,
        sdsFastStorageSize,
        itemSizeCost,
        itemSize,
        itemQueries,
        itemReads,
        itemWrites,
        requestsProcessed,
        offPeakHourItemQueries,
        offPeakHourItemReads,
        offPeakHourItemWrites,
        offPeakHourRequestsProcessed,
        peakHourItemQueries,
        peakHourItemReads,
        peakHourItemWrites,
        peakHourRequestsProcessed,
        devOwner: "",
        pmOwner: "",
        totalCost: itemReadsCost + itemWritesCost + itemQueriesCost + ebaCost + tbaCost + swssCost + serviceInstanceCost + cfmSubmittedCost + dsApiRequestsCost + kvCacheCost + requestProcessedCost + sdsFastStorageCost + itemSizeCost,
        scenarioTag,
        scenarioTagCount,
        totalreadwritesqueriescost: itemReadsCost + itemWritesCost + itemQueriesCost,
        griffinApp: costResponse.appName,
        maxLLCSizeGb,
        maxServiceInstanceCount,
        maxServiceInstanceCpu,
        maxServiceInstanceMemory,
        griffinProcessor: costResponse.griffinProcessor,
        maxItemSize: costResponse.maxItemSize,
    }
}