import { Configuration, RedirectRequest } from "@azure/msal-browser";

export const CLIENT_ID = "43be6384-654a-4f3e-932c-05300cbbe7ce";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: CLIENT_ID, // JawsPortalV2
        authority: "https://login.microsoftonline.com/microsoft.onmicrosoft.com",
        redirectUri: ENVIRONMENT === "pr" ? "/indexpcm.html" : "/",
        postLogoutRedirectUri: "/",
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints. TODO: replace it with a configurable variable
export const loginRequest: RedirectRequest = {
    scopes: ["api://20984d37-5817-4498-8f0b-73287c5fc974/user_impersonation"]
};