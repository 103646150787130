import { ESCostBillingType, ESCostOrganization, ESCostUseScenario, ESUsageScenarios } from "../ESCostModels";

export const ESOrgs = Object.values(ESCostOrganization).sort();

export const ESAllUsageScenario = Object.values(ESUsageScenarios).sort();

export const ESCostUsage = Object.values(ESCostUseScenario).sort();

export const ESCostResource = Object.values(ESCostBillingType).sort();

export enum ESGranularity {
    Monthly = "Monthly",
    FiscalYearly = "FiscalYearly",
    Weekly = "Weekly",
    Hourly = "Hourly",
}
