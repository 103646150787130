import { AzureLoggingElements, NON_COMPUTE_CATEGORIES } from "./common/AzureComputeConstants";
import { LogComponent, LogTarget } from "../../models/LogModel";
import { Pages, bannerTabs } from "../../models/Nav";
import { Pivot, PivotItem } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { useGetAzureCostOverview, useGetAzureDailyMetricsQuery, useGetAzureNonComputeCosts } from "../../hooks/useAzureQuery";

import { Budget } from "./budget/Budget";
import { EmpytCostView } from "../substrateV2/common/EmptyCostView";
import { Endpoints } from "../../utils/Constants";
import Overview from "./Overview/Overview";
import PageHeader from "../common/PageHeader";
import RegionDetail from "./detail/RegionDetail";
import RegionSelector from "./common/RegionSelector";
import ServiceDetail from "./detail/ServiceDetail";
import SubscriptionDetail from "./detail/SubscriptionDetail";
import { WarningBanner } from "../banner/WarningBanner";
import styles from "./Azure.less";
import { trackEventCallback } from "../../utils/AppInsights";
import { useFlights } from "../../hooks/useSettings";
import { useGetBannersByTabAndDateQuery } from "../../hooks/useBannerQuery";
import { useGotoTab } from "../../hooks/useGotoPage";
import { useParams } from "react-router-dom";
import { useRegion } from "../../hooks/useRegion";

export const Azure: React.FC = () => {
    const { tab: curTab } = useParams();
    const gotoTab = useGotoTab();
    const [defaultRegionSorting, setDefaultRegionSorting] = useState<string | undefined>(undefined);
    const [defaultServiceSorting, setDefaultServiceSorting] = useState<string | undefined>(undefined);
    const { data: allBanners } = useGetBannersByTabAndDateQuery(bannerTabs.AzureCompute);
    const { data: flights } = useFlights();
    const {publicCost, azscCost, nonCompute, isLoading} = useGetAzureCostOverview();
    const totalCost = publicCost + azscCost + nonCompute;

    const handleTabChange = (item?: PivotItem) => {
        if (!item || !item.props.itemKey) return;

        gotoTab(Pages.Azure, item.props.itemKey, Pages.Azure);
        trackEventCallback(LogComponent.AzureTabs, AzureLoggingElements[item.props.itemKey], item.props.itemKey, LogTarget.Tab);
    };

    const hasExtra = curTab === "RegionDetail" || curTab === "SubscriptionDetail";

    const [regions, setRegions] = useRegion();
    const setCurTab = useCallback((tab: string) => {
        gotoTab(Pages.Azure, tab, Pages.Azure);
    }, [gotoTab]);

    return (
        <div className={styles.page}>
        <PageHeader
            title="Azure Costs"
            description="Below you will find details regarding Azure costs (including Compute/Storage/SQL Database/Azure Cosmos DB) for a specified date range. These costs include the discount applied for AZSC. "
            link={"https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/73824/Azure-Compute-Page"}
            linkText="Learn more."
            inlineLink
        />
        {Boolean(allBanners?.length) &&
            allBanners?.map(item =>
            (
                <WarningBanner key={item.id} bannerItem={item} />
            ))
        }
        { hasExtra && <RegionSelector selectedKeys={regions} onChange={setRegions} />}
        {
            isLoading || totalCost > 0 ?
            <Pivot className={`${styles.pivot} ${hasExtra ? styles.pivotWithExtra : ''}`} overflowBehavior="menu" onLinkClick={handleTabChange} selectedKey={curTab || "Overview"}>
                <PivotItem itemKey="Overview" headerText="Overview" className={styles.pivotItem}><Overview updateSelectedTab={setCurTab} setDefaultRegionDetails={setDefaultRegionSorting} setDefaultServiceDetails={setDefaultServiceSorting} /></PivotItem>
                <PivotItem itemKey="ServiceDetail" headerText="Service details"><ServiceDetail type="Total" defaultSorting={defaultServiceSorting} /></PivotItem>
                <PivotItem itemKey="RegionDetail" headerText="Region details"><RegionDetail type="Total" regions={regions} defaultSorting={defaultRegionSorting} /></PivotItem>
                <PivotItem itemKey="SubscriptionDetail" headerText="Subscription details"><SubscriptionDetail regions={regions} type="Total" /></PivotItem>
                {flights?.enableBudgets && <PivotItem itemKey="BudgetDetail" headerText="Budget details" className={styles.pivotItem}><Budget /></PivotItem>}
            </Pivot> :
            <EmpytCostView type="azure"/>
        }
        </div>
    )
}