// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Zd73q6WUB9uoAoALYia1 {\n  min-height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.UHXEayW1pi9I8P5dV2nQ::before {\n  background-color: #323130;\n}\n", "",{"version":3,"sources":["webpack://./src/components/auth/AuthView.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAEA;EACI,yBAAA;AAAJ","sourcesContent":[".authView {\n    min-height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.separator::before {\n    background-color: #323130;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authView": "Zd73q6WUB9uoAoALYia1",
	"separator": "UHXEayW1pi9I8P5dV2nQ"
};
export default ___CSS_LOADER_EXPORT___;
