import { AuthenticationResult } from '@azure/msal-browser';
import { PrimaryButton, TextField, Text, Label, TeachingBubble } from '@fluentui/react';
import React from 'react';
import styles from './AccessTokenInfo.less';
import { useBoolean, useId } from '@fluentui/react-hooks';

interface IAccessTokenInfoProps {
    authInfo: AuthenticationResult
}


const AccessTokenInfo: React.FC<IAccessTokenInfoProps> = (props) => {

    const tokenToClipboard = useId('tokenToClipboard');
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);

    function copyToClipBoard() {
        navigator.clipboard.writeText(props.authInfo.accessToken);
        toggleTeachingBubbleVisible();
    }

    return (
        <div className={styles.headerDescriptionAndLink}>
            <Label>Authenticated user</Label>
            <Text block>{props.authInfo.account?.username}</Text>
            <Label>Token valid until</Label>
            <Text block>{props.authInfo.expiresOn?.toLocaleString()}</Text>
            <TextField label="Access Token" multiline rows={15} readOnly={true} value={props.authInfo.accessToken} />
            <PrimaryButton id={tokenToClipboard} onClick={copyToClipBoard} text="Copy to clipboard" className={styles.clipboardButton} />
            {teachingBubbleVisible && (
                <TeachingBubble
                    target={`#${tokenToClipboard}`}
                    isWide={false}
                    hasCloseButton={true}
                    closeButtonAriaLabel="Close"
                    onDismiss={toggleTeachingBubbleVisible}
                    headline="Token copied to clipboard."
                    hasSmallHeadline={true}
                >
                </TeachingBubble>
            )}
        </div>
    );
};

export default AccessTokenInfo;