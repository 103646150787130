import React, { useCallback } from "react";
import { ReactTableExpandableData, ReactTableRow } from "./TableUtils";
import styles from "./TableRowExpander.less";
import { Icon } from "@fluentui/react";

const TableRowDepthWidth = 32;

export interface ITableRowExpanderProps {
    row: ReactTableRow<any>;
    onExpanded?: (row: ReactTableRow<ReactTableExpandableData<any>>, isExpanded: boolean) => void;
}

export const TableRowExpander: React.FC<ITableRowExpanderProps> = (props) => {
    const elemProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> = props.row.getToggleRowExpandedProps();

    const handleToggleExpanded = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
            elemProps.onClick && elemProps.onClick(event);
            props.onExpanded && props.onExpanded(props.row, !props.row.isExpanded);
        },
        [elemProps.onClick]
    );

    if (!props.row.canExpand) {
        return <div className={styles.placeholder} style={{ width: props.row.depth * TableRowDepthWidth }}></div>;
    }

    return (
        <div className={styles.controller} {...elemProps} onClick={handleToggleExpanded}>
            {props.row.isExpanded ? <Icon iconName="ChevronDown" /> : <Icon iconName="ChevronRight" />}
        </div>
    );
};
