import * as React from "react";

import {
    ActionButton,
    DefaultButton,
    Dialog,
    DialogType,
    DirectionalHint,
    IContextualMenuProps,
    IStackTokens,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    TextField,
    mergeStyleSets,
} from "@fluentui/react";
import { ErrorMessage, ViewInputValidator } from "../../../../validators/ViewInputValidators";
import { LogComponent, LogElement, LogTarget } from "../../../../models/LogModel";
import { createView, editView } from "../../../../services/viewService";
import { entries, keys } from "lodash";
import { makeEditViewSuceessDialogAction, makeSaveViewFailDialogAction, makeSaveViewSuceessDialogAction } from "../../../../reducers/dialogsReducer";
import { useCallback, useMemo, useState } from "react";

import { CategoryDivision } from "../../../../models/CategoryDivision";
import { Flight } from "../../../../models/Flights";
import { IPartialFilterViewContent } from "../../../../models/FilterView";
import { Text } from '@fluentui/react/lib/Text';
import { getViewItemKeyFromFilterCategory } from "../../../../utils/FiltersUtils";
import { loadAllViewsIfNeeded } from "../../../../reducers/savedViewReducer";
import styles from "./SaveView.less";
import { trackEventCallback } from "../../../../utils/AppInsights";
import { useBoolean } from "@fluentui/react-hooks";
import { useCategoryFilters } from "../../../../hooks/useFilters";
import { useDispatch } from "react-redux";
import { useFlights } from "../../../../hooks/useSettings";
import useId from "@mui/material/utils/useId";

const actionStackTokens: IStackTokens = {
    childrenGap: 8
};

const classNames = mergeStyleSets({
    menu: {
        textAlign: "center",
        maxWidth: 220,
        selectors: {
            ".ms-ContextualMenu-item": {
                height: "auto",
            },
        },
    },
    item: {
        display: "inline-block",
        height: 40,
        padding: 10,
        lineHeight: 40,
        textAlign: "center",
        verticalAlign: "middle",
        marginBottom: 8,
        cursor: "pointer",
    },
    categoriesList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
    },
    button: {
        width: "40%",
        margin: "2%",
    },
});

interface SaveViewProps  {
    selectedViewName: string,
    selectedViewId: number,
    onSucceedChangeView: (inputNewViewName: string) => void;
}

const SaveView: React.FC<SaveViewProps> = ({ selectedViewId, selectedViewName, onSucceedChangeView }) => {
    const saveViewButtonId = useId("save-view-button");
    const [saveViewCalloutVisible, { setFalse: dismissSaveViewCallout, toggle: toggleSaveViewCallout }] = useBoolean(false);
    const [createViewCalloutVisible, { setFalse: dismissCreateViewCallout, toggle: toggleCreateViewCallout }] = useBoolean(false);

    const [isSaveViewEnable, {setFalse: setSaveViewDisable, setTrue: setSaveViewEnable}] = useBoolean(true);

    const { filters: globalFilters } = useCategoryFilters();
    const { data: flights } = useFlights();

    React.useEffect(() => {
        if (keys(globalFilters.filters).length) {
            setSaveViewEnable()
        } else {
            setSaveViewDisable()
        }
    }, [globalFilters.filters])

    const onToggleSaveViewCallout = useCallback(() => {
        toggleSaveViewCallout();
        trackEventCallback(LogComponent.PivotHeadPane, LogElement.SaveView, "Save views", LogTarget.Button);
    }, [toggleSaveViewCallout]);

    const createViewDialogProps = {
        type: DialogType.normal,
        title: 'Create a custom view',
        closeButtonAriaLabel: 'Close',
        showCloseButton: true
    };
    const saveViewDialogProps = {
        type: DialogType.normal,
        title: 'Save custom view',
        closeButtonAriaLabel: 'Close',
        showCloseButton: true
    };
    const subTextId = useId('subTextLabel');
    const dialogStyles = useMemo(() => ({ main: styles.saveSharePageDialog, scrollableContent: { overflowY: "hidden" } }), []);
    const labelId = useId("callout-label");
    const modalProps = useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [dialogStyles, labelId, subTextId],
    );
    const [viewNameError, setViewNameError] = useState(false);
    const [viewIdError, setViewIdError] = useState(false);
    const [viewName, setViewName] = useState('');

    const onChangeViewName = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setViewName(newValue || '');
    }, []);

    const dispatch = useDispatch();

    const menuProps = React.useMemo<IContextualMenuProps>(() => ({
        shouldFocusOnMount: true,
        directionalHint: DirectionalHint.bottomLeftEdge,
        className: classNames.menu,
        items: [
            {
                key: "saveview",
                text: "Save custom view",
                iconProps: {iconName: 'Save'},
                onClick: () => {
                    onToggleSaveViewCallout()
                },
            },
            {
                key: "createView",
                text: "Save as new custom view",
                iconProps: {iconName: 'SaveAs'},
                onClick: () => {
                    toggleCreateViewCallout()
                },
            },
        ],
    }), [onToggleSaveViewCallout, toggleCreateViewCallout]);

    function buildViewFromFilters(filters: Partial<Record<CategoryDivision, string[]>>): IPartialFilterViewContent {
        const filterView: IPartialFilterViewContent = {};
        entries(filters).forEach(([category, value]) => {
            if (!value || value.length === 0) {
                return;
            }

            const key: keyof IPartialFilterViewContent = getViewItemKeyFromFilterCategory(category as CategoryDivision);
            if (key === "Subscription") {
                filterView.Subscription = value.map(filter => filter.substring(0, filter.indexOf("(")));
            } else {
                filterView[key] = [...value];
            }
        })

        return filterView;
    }

    const onCreateView = useCallback(() => {
        if (!ViewInputValidator.validateViewName(viewName)) {
            setViewNameError(true);
            return;
        }

        trackEventCallback(LogComponent.SaveViewDial, LogElement.Save, "Save", LogTarget.Button);
        setViewNameError(false);
        createView(viewName, buildViewFromFilters(globalFilters.filters)).then(async (response) => {
            if (response.ok) {
                dispatch(loadAllViewsIfNeeded(flights as Flight));
                dispatch(makeSaveViewSuceessDialogAction(true));
                onSucceedChangeView(viewName);
                return Promise.resolve();
            } else {
                const message = response.text();
                return Promise.reject(message);
            }

        }).catch((exception) => {
            exception.then((message: string | undefined) => {
                dispatch(makeSaveViewFailDialogAction(true, message));
            })
        }).finally(() => {
            setViewName('');
            setViewNameError(false);
            dismissCreateViewCallout();
        });

    }, [viewName, globalFilters.filters, dispatch, flights, dismissCreateViewCallout]);


    const onEditView = useCallback(() => {
        if (!ViewInputValidator.validateViewId(selectedViewId)) {
            setViewIdError(true);
            return;
        }

        trackEventCallback(LogComponent.SaveViewDial, LogElement.Save, "Save", LogTarget.Button);
        setViewIdError(false);
        editView(selectedViewId, buildViewFromFilters(globalFilters.filters)).then(async (response) => {
            if (response.ok) {
                dispatch(loadAllViewsIfNeeded(flights as Flight));
                dispatch(makeEditViewSuceessDialogAction(true));

                return Promise.resolve();
            } else {
                const message = response.text();
                return Promise.reject(message);
            }

        }).catch((exception) => {
            exception.then((message: string | undefined) => {
                dispatch(makeSaveViewFailDialogAction(true, message));
            })
        }).finally(() => {
            setViewName('');
            setViewIdError(false);
            dismissSaveViewCallout();
        });

    }, [selectedViewId, globalFilters.filters, dispatch, flights, dismissSaveViewCallout]);
    
    return (
        <div>
            <ActionButton
                key="save"
                id={saveViewButtonId}
                iconProps={{ iconName: "Save" }}
                menuProps={menuProps}
                disabled={!isSaveViewEnable}
                onClick={onToggleSaveViewCallout}
                style={{ padding: 0, whiteSpace: "nowrap" }}
            >
                <span className={styles.actionButtonLabel} id="custom-bubble-anchor-save-view">
                    Save
                </span>
            </ActionButton>
            {/* save view */}
            {saveViewCalloutVisible && (
                <Dialog hidden={!saveViewCalloutVisible} onDismiss={() => {
                    toggleSaveViewCallout()
                    setViewIdError(false)
                    }} modalProps={modalProps} dialogContentProps={saveViewDialogProps}>
                    <Text>
                        Are you sure you want to save the new filters on &quot;{selectedViewName}&quot;? This will replace any previously saved filters.
                    </Text>
                    {viewIdError && (<MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
                        {ErrorMessage.EditViewWarning}
                    </MessageBar>)} 
                    <Stack horizontal tokens={actionStackTokens} horizontalAlign="start" style={{ marginTop: 75 }}>
                        <PrimaryButton text="Save" onClick={onEditView} />
                        <DefaultButton
                            text="Save as new custom view"
                            onClick={() => {
                                toggleCreateViewCallout();
                                toggleSaveViewCallout();
                            }}
                        />
                    </Stack>
                </Dialog>
            )}
            {/* create view */}
            {createViewCalloutVisible && (
                <Dialog
                    hidden={!createViewCalloutVisible}
                    onDismiss={toggleCreateViewCallout}
                    modalProps={modalProps}
                    dialogContentProps={createViewDialogProps}
                >
                    <Text>Enter a new name for this custom view, so you can select it later from the custom view drop list.</Text>
                    <TextField
                        errorMessage={viewNameError ? ErrorMessage.ViewNameWarning : ""}
                        underlined
                        placeholder="Add name, and No more than 50 characters"
                        className={styles.textField}
                        onChange={onChangeViewName}
                    />
                    <Stack horizontal tokens={actionStackTokens} horizontalAlign="start" style={{ marginTop: 75 }}>
                        <PrimaryButton text="Save" onClick={onCreateView} />
                    </Stack>
                </Dialog>
            )}
        </div>
    );
};

export default SaveView;
