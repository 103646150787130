/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DirectionalHint, IPivotItemProps, IStackTokens, Link, Pivot, PivotItem, Stack, TooltipDelay, TooltipHost } from "@fluentui/react";
import {
    ISubstrateData,
    makeModifySubstrateCostTableAction,
} from "../../../reducers/substrateReducer";
import { ISubstrateTopComponents, SubstrateEntityMetrics } from "../../../models/SubstrateModels";
import LineChart, { LineChartSeries } from "../../common/LineChart";
import { PCMMetric, getPCMMetricDescription, getPCMMetricLearnMoreLink, getPCMMetricString, getPcmMetricCostField } from "../../../models/PCMMetrics";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { metricDescriptions, metricLinks } from "../SubstrateCostTable/PCMMetricsConstants";
import { useDispatch, useSelector } from "react-redux";

import CommonContainer from "../../common/CommonContainer";
import CostCard from "../../common/costCard/CostCard";
import EmptyState from "../../common/state/EmptyState";
import { IAppState } from "../../../store";
import { IServiceTreeData } from "../../../reducers/serviceTreeReducer";
import { ISubstrateTotalCostResponse } from "../../../models/SubstrateTotalCost";
import ResponseBoundary from "../../ResponseBoundary/ResponseBoundary";
import { ServiceTreeItem } from "../../../models/serviceTree";
import SubstrateCostTable from "../SubstrateCostTable/SubstrateCostTable";
import { currencyFormatter } from "../../../utils/currency";
import moment from "moment";
import styles from "./TransactionCostView.less";
import { syncChildrenClassName } from "../../../utils/Constants";
import { toPairs } from "lodash";
import { useLocation } from "react-router";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { trackEventCallback } from "../../../utils/AppInsights";
import {useGetDailyMetricsQuery, useGetScenarioTagCost, useGetSubstrateCostByService, useGetSubstrateTopFiveComponents} from "../../../hooks/useSubstrateQuery";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useParams } from "react-router-dom";
import { useGotoTab } from "../../../hooks/useGotoPage";
import { Pages, SubPages } from "../../../models/Nav";
import PageHeader from "../../common/PageHeader";
import { useTrackHovering } from "../../../hooks/useTrack";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { DataSourceEnum, INotification } from "../../../models/NotificationModels";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { useGetBannersByTabAndDateQuery } from "../../../hooks/useBannerQuery";
import { WarningBanner } from "../../banner/WarningBanner";

const tabMetrics: PCMMetric[][] = [
    [PCMMetric.ItemReads, PCMMetric.ItemWrites, PCMMetric.ItemQueries],
    [PCMMetric.EBA, PCMMetric.TBA, PCMMetric.SWSS],
    [PCMMetric.ServiceInstance],
    [PCMMetric.RequestsProcessed],
    [PCMMetric.DSAPIRequestsCount],
    [PCMMetric.CFMSubmittedCount]
]

const costTypes = tabMetrics.map((metrics, index) => {
    switch(index) {
        case 0:
            return "Item Reads, Writes and Queries"
        case 1:
            return "Assistants";
        default:
            return metrics.map(metric => getPCMMetricString(metric)).join(", ");
    }
});

const costFields = tabMetrics.map((meitrics, index) => {
    switch(index) {
        case 0:
            return "itemRWQCost";
        case 1:
            return "assistantCost";
        default:
            return getPcmMetricCostField(meitrics[0]);
    }
});

const costLogElements = [
    LogElement.ItemRWQ,
    LogElement.Assistants,
    LogElement.ServiceInstance,
    LogElement.RequestsProcessed,
    LogElement.DsapiRequest,
    LogElement.CfmSubmitted
];

const stackToken: IStackTokens = { childrenGap: 48 };

const columnListConfigs = {
    item: [
        SubstrateEntityMetrics.TotalItemReadsWritesQueriesCost,
        SubstrateEntityMetrics.ScenarioTag,
        SubstrateEntityMetrics.ItemReadsCost,
        SubstrateEntityMetrics.ItemWritesCost,
        SubstrateEntityMetrics.ItemQueriesCost,
        SubstrateEntityMetrics.ItemReadsCount,
        SubstrateEntityMetrics.ItemWritesCount,
        SubstrateEntityMetrics.ItemQueriesCount,
        SubstrateEntityMetrics.PeakHourItemReadsCount,
        SubstrateEntityMetrics.OffPeakHourItemReadsCount,
        SubstrateEntityMetrics.PeakHourItemWritesCount,
        SubstrateEntityMetrics.OffPeakHourItemWritesCount,
        SubstrateEntityMetrics.PeakHourItemQueriesCount,
        SubstrateEntityMetrics.OffPeakHourItemQueriesCount,
    ],
    assistants: [
        SubstrateEntityMetrics.TotalAssistantsCost,
        SubstrateEntityMetrics.GriffinProcessor,
        SubstrateEntityMetrics.EBACost,
        SubstrateEntityMetrics.TBACost,
        SubstrateEntityMetrics.SWSSCost,
        SubstrateEntityMetrics.EBACount,
        SubstrateEntityMetrics.TBACount,
        SubstrateEntityMetrics.SWSSCount
    ],
    instance: [
        SubstrateEntityMetrics.ServiceInstanceCost,
        SubstrateEntityMetrics.ServiceInstanceCpu,
        SubstrateEntityMetrics.ServiceInstanceMemory
    ],
    request: [
        SubstrateEntityMetrics.RequestProcessCost,
        SubstrateEntityMetrics.RequestProcessedCount,
        SubstrateEntityMetrics.PeakHourRequestProcessedCount,
        SubstrateEntityMetrics.OffPeakHourRequestProcessedCount
    ],
    dsapi: [
        SubstrateEntityMetrics.DsapiRequestsCost,
        SubstrateEntityMetrics.DsapiRequestsCount
    ],
    cfm: [
        SubstrateEntityMetrics.CfmSubmittedCost,
        SubstrateEntityMetrics.CfmSubmittedCount
    ],
};

const defaultColumnConfigs = {
    item: new Set<SubstrateEntityMetrics>([
        SubstrateEntityMetrics.TotalItemReadsWritesQueriesCost,
        SubstrateEntityMetrics.ScenarioTag,
        SubstrateEntityMetrics.ItemReadsCost,
        SubstrateEntityMetrics.ItemWritesCost,
        SubstrateEntityMetrics.ItemQueriesCost,
        SubstrateEntityMetrics.ItemReadsCount,
        SubstrateEntityMetrics.ItemWritesCount,
        SubstrateEntityMetrics.ItemQueriesCount
    ]),
    assistants: new Set<SubstrateEntityMetrics>([
        SubstrateEntityMetrics.TotalAssistantsCost,
        SubstrateEntityMetrics.GriffinProcessor,
        SubstrateEntityMetrics.EBACost,
        SubstrateEntityMetrics.TBACost,
        SubstrateEntityMetrics.SWSSCost,
        SubstrateEntityMetrics.EBACount,
        SubstrateEntityMetrics.TBACount,
        SubstrateEntityMetrics.SWSSCount
    ]),
    instance: new Set<SubstrateEntityMetrics>([
        SubstrateEntityMetrics.ServiceInstanceCost,
        SubstrateEntityMetrics.ServiceInstanceCpu,
        SubstrateEntityMetrics.ServiceInstanceMemory
    ]),
    request: new Set<SubstrateEntityMetrics>([
        SubstrateEntityMetrics.RequestProcessCost,
        SubstrateEntityMetrics.RequestProcessedCount,
        SubstrateEntityMetrics.PeakHourRequestProcessedCount,
        SubstrateEntityMetrics.OffPeakHourRequestProcessedCount
    ]),
    dsapi: new Set<SubstrateEntityMetrics>([
        SubstrateEntityMetrics.DsapiRequestsCost,
        SubstrateEntityMetrics.DsapiRequestsCount
    ]),
    cfm: new Set<SubstrateEntityMetrics>([
        SubstrateEntityMetrics.CfmSubmittedCost,
        SubstrateEntityMetrics.CfmSubmittedCount
    ]),
    scenarioView: new Set<SubstrateEntityMetrics>([
        SubstrateEntityMetrics.TotalItemReadsWritesQueriesCost,
        SubstrateEntityMetrics.GriffinApp,
        SubstrateEntityMetrics.ItemReadsCost,
        SubstrateEntityMetrics.ItemReadsCount,
        SubstrateEntityMetrics.PeakHourItemReadsCount,
        SubstrateEntityMetrics.OffPeakHourItemReadsCount,
        SubstrateEntityMetrics.ItemWritesCost,
        SubstrateEntityMetrics.ItemWritesCount,
        SubstrateEntityMetrics.PeakHourItemWritesCount,
        SubstrateEntityMetrics.OffPeakHourItemWritesCount,
        SubstrateEntityMetrics.ItemQueriesCost,
        SubstrateEntityMetrics.ItemQueriesCount,
        SubstrateEntityMetrics.PeakHourItemQueriesCount,
        SubstrateEntityMetrics.OffPeakHourItemQueriesCount
    ])
}

function updateColumnList(key: string): SubstrateEntityMetrics[]{
    if (key == "Service Instance") {
        return columnListConfigs.instance;
    }
    if (key == "Assistants") {
        return columnListConfigs.assistants;
    }
    if (key == "Requests Processed") {
        return columnListConfigs.request;
    }
    if (key == "DSAPI Requests") {
        return columnListConfigs.dsapi;
    }
    if (key == "CFM Submitted") {
        return columnListConfigs.cfm;
    }
    if (key == "Item Reads, Writes and Queries") {
        return columnListConfigs.item;
    }

    return columnListConfigs.item;
}

function updateColumns(key: string): Set<SubstrateEntityMetrics>{
    if (key == "Service Instance") {
        return defaultColumnConfigs.instance;
    }
    if (key == "Assistants") {
        return defaultColumnConfigs.assistants;
    }
    if (key == "Requests Processed") {
        return defaultColumnConfigs.request;
    }
    if (key == "DSAPI Requests") {
        return defaultColumnConfigs.dsapi;
    }
    if (key == "CFM Submitted") {
        return defaultColumnConfigs.cfm;
    }
    if (key == "Item Reads, Writes and Queries") {
        return defaultColumnConfigs.item;
    }

    return defaultColumnConfigs.item;
}

function updateScenarioTagView(key: string): boolean {
    if (key == "Item Reads, Writes and Queries") {
        return true;
    }

    return false;
}

function getTrendsTitleType(costType: string, useServiceNameDirectly: boolean): string {
    if(useServiceNameDirectly) {
        switch(costType) {
            case "Item Reads, Writes and Queries":
                return "Apps or Client components";
            case "Assistants":
                return "Processors";
            case "Service Instance":
                return "Processes";
            case "Requests Processed":
                return "Vdirs";
            case "DSAPI Requests":
                return "Apps";
            case "CFM Submitted":
                return "Apps";
            default:
                return "Services"
        }
    }
    return "Services";
    
}

const TransactionCostView: React.FC = () => {
    const trackChartHoveringProps = useTrackHovering(LogComponent.Substrate, LogElement.SubstrateTopFive, "Transaction Top Five Cost", LogTarget.Chart);

    const substrateData = useSelector<IAppState, ISubstrateData>(state => state.substrate);
    const {isLoading: isDailyMetricsLoading, data: dailyMetrics, isError: isDailyMetricsError} = useGetDailyMetricsQuery();
    const {data: substrateScenarioCost} = useGetScenarioTagCost();
    const metricUsage = useMemo(() => dailyMetrics?.distribution.usage, [dailyMetrics?.distribution.usage]);
    const metricCosts = useMemo(() => dailyMetrics?.distribution.cost, [dailyMetrics?.distribution.cost]);
    const transactionCosts = useMemo(() => tabMetrics.map(tab => tab.reduce((prev, cur) => prev + (metricCosts?.[cur] ?? 0), 0)), [metricCosts]);
    const columnsData = { columns: defaultColumnConfigs.item , columnsList: columnListConfigs.item};
    const { tab } = useParams();
    const selectedCostType = useMemo(() => tab || costTypes[0], [tab]);
    const gotoTab = useGotoTab();
    const {isLoading: isCostByServiceLoading, data: costByService, isError: isCostByServiceError} = useGetSubstrateCostByService(selectedCostType);
    const [mounted, setMounted] = useState(false)
    const [columnList, setAllColumnList] = useState(columnsData.columnsList);
    const [columns, setColumns] = useState(columnsData.columns);
    const [displayScenarioView, setDisplayScenarioView] = useState(true);
    const selectedMetrics = useMemo(() => tabMetrics[costTypes.indexOf(selectedCostType)], [selectedCostType])
    const selectedCost = useMemo(() => selectedMetrics.reduce((prev, cur) => prev + (metricCosts?.[cur] ?? 0), 0), [metricCosts, selectedMetrics]);
    const dispatch = useDispatch();

    const handleLinkClick = useCallback((item?: PivotItem) => {
        if (item?.props.itemKey) {
            gotoTab(`${Pages.Substrate}/${SubPages.Transaction}`, item.props.itemKey, `${Pages.Substrate}/${SubPages.Transaction}`);
        }
    }, [gotoTab]);
    const headerRenderer = useCallback((index: number, link?: IPivotItemProps, defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null) => {
        if (!link || !defaultRenderer) {
            return null;
        }

        return (
            <>
                {defaultRenderer({ ...link })}
                &nbsp;&nbsp;
                <span className={styles.costColor}>{isDailyMetricsLoading ? "loading..." : currencyFormatter(transactionCosts[index], 0)}</span>
            </>
        );
    }, [isDailyMetricsLoading, transactionCosts]);

    const pivotItems = useMemo(() => costTypes.map((t, i) => <PivotItem headerText={t} itemKey={t} key={t} onRenderItemLink={(link, defaultRender) => headerRenderer(i, link, defaultRender)} />), [headerRenderer]);
    const serviceTree = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const costKeys = useMemo(() => selectedMetrics.map(m => getPcmMetricCostField(m)), [selectedMetrics]);
    const filtersData = useCategoryFilters().filters;
    const topNMetric = useMemo(() => costFields[costTypes.indexOf(selectedCostType)], [selectedCostType]);
    const topN = 5;
    const topServicesNumber = 5;
    const topServiceData = useGetSubstrateTopFiveComponents(topNMetric, topServicesNumber);
    const useServiceNameDirectly = useMemo(() => {
        return (filtersData.filters.Service?.length == 1 && !filtersData.filters.GriffinApp
            && !filtersData.filters.SSDDataSetName && !filtersData.filters.Process
            && !filtersData.filters.VDir) ||
            (!filtersData.filters.Service && Boolean(filtersData.filters.GriffinApp
                || filtersData.filters.SSDDataSetName || filtersData.filters.Process
                || filtersData.filters.VDir));
    }, [filtersData.filters]);
    const type = getTrendsTitleType(selectedCostType, useServiceNameDirectly);

    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.Substrate);
    const activeAnomalies = useMemo(() => getActiveAnomalies(anomaliesQuery.data, selectedMetrics), [anomaliesQuery.data, selectedMetrics]);
    const { data: allBanners } = useGetBannersByTabAndDateQuery("Transaction");

    const series = useMemo(
        () =>  createLineSeriesBy(useServiceNameDirectly, topServiceData.data, serviceTree.indexMap, costKeys[0] as keyof ISubstrateTotalCostResponse),
        [costKeys, serviceTree.indexMap, topServiceData.data, useServiceNameDirectly]
    );

    const dateRangeFilterData = useDateRange();
    useEffect(() => {
        dispatch(makeModifySubstrateCostTableAction(columns));
    }, [columns, dateRangeFilterData.endDate, dateRangeFilterData.startDate, dispatch, filtersData, topNMetric]);

    useEffect(() => {
        setColumns(updateColumns(selectedCostType));
        setAllColumnList(updateColumnList(selectedCostType))
        setDisplayScenarioView(updateScenarioTagView(selectedCostType));
        trackEventCallback(LogComponent.TransChartingPane, costLogElements[costTypes.indexOf(selectedCostType)], selectedCostType, LogTarget.Tab);
    }, [selectedCostType])

    const state = useLocation()['state'] as Record<string, string>;
    if (!mounted) {
        if (state)
        {
            const key = state['key'] as string
            gotoTab(`${Pages.Substrate}/${SubPages.Transaction}`, key, `${Pages.Substrate}/${SubPages.Transaction}`);
            setColumns(updateColumns(key));
        }
        setMounted(true)
    }
    const isCostCardHorizontal = selectedCostType == "Item Reads, Writes and Queries" || selectedCostType == "Assistants";
    return (
        <Stack tokens={stackToken}>
            <div className={syncChildrenClassName}>
            <PageHeader
                title="Transaction Cost View"
                description="Transaction Cost View shows the compute related cost metrics. Go to the tabs below for more details about each metric."
            />
            </div>
            {
                Boolean(allBanners?.length) &&
                allBanners?.map(item =>
                (
                    <WarningBanner key={item.id} bannerItem={item} />
                ))
            }
            <Pivot
                className={`${styles.pivot} ${syncChildrenClassName}`}
                selectedKey={selectedCostType}
                onLinkClick={handleLinkClick}
                headersOnly={true}
                overflowBehavior="menu"
                styles={{overflowMenuButton: styles.pivotOverflowButton}}
            >
                {pivotItems}
            </Pivot>
            {selectedCost === 0 && !isDailyMetricsLoading
                ? <Stack horizontalAlign="center">
                    <div className={styles.emptySvg}><EmptyState /></div>
                    <div className={styles.noDataTitle}>No Costs yet</div>
                    <div className={styles.noDataDescription}>The selected workload have no cost here yet.</div>
                    <Link className={styles.link}>Learn more about Substrate</Link>
                </Stack>
                : <Stack tokens={stackToken}>

                    <Stack horizontal tokens={stackToken} className={syncChildrenClassName} style={{display: "grid", gridTemplateColumns: "repeat(2, calc(50% - 24px))", minHeight: 320}}>
                        <CommonContainer title={"Total " + selectedCostType + " Cost"}>
                            <ResponseBoundary
                                isLoading={isDailyMetricsLoading}
                                errorHappened={isDailyMetricsError}
                                data={dailyMetrics?.distribution}
                            >
                                {
                                    isCostCardHorizontal ?
                                    <h1><span className={styles.costColor}>{currencyFormatter(selectedCost, 2)}</span></h1> : null
                                }
                                <div className={isCostCardHorizontal ? styles.grid : ""}>
                                    {selectedMetrics.map((metric, i) => {
                                        const metricString = getPCMMetricString(metric);
                                        const description = getPCMMetricDescription(metric);
                                        const learnMoreLink = getPCMMetricLearnMoreLink(metric);
                                        let cards = [
                                        <CostCard
                                            key="0"
                                            title={metricString + " Cost"}
                                            cost={metricCosts?.[metric] ?? 0}
                                            color={i}
                                        />,
                                        <CostCard
                                            key="1"
                                            title={metricString}
                                            cost={metricUsage?.[metric] ?? 0}
                                            color={i}
                                            noPrefix
                                        />];
                                        if (metric == PCMMetric.ServiceInstance) {
                                            cards = cards.slice(0, 1);
                                        }
                                        return (
                                            <React.Fragment key={metric}>
                                                {isCostCardHorizontal ? cards : <div className={styles.grid}>{cards}</div>}
                                                <TooltipHost
                                                    key={metric}
                                                    tooltipProps={{
                                                        onRenderContent: () => (
                                                            <div>
                                                                {metricDescriptions[description]}
                                                            </div>
                                                        ),
                                                    }}
                                                    delay={TooltipDelay.zero}
                                                    id="tooltipId"
                                                    directionalHint={DirectionalHint.bottomCenter}
                                                >
                                                    <span id="tooltipId" className={styles.metricDescription}>{metricDescriptions[description]}</span>
                                                    {learnMoreLink && <Link target="_blank" href={metricLinks[learnMoreLink]}>Learn More</Link>}
                                                </TooltipHost>
                                            </React.Fragment>
                                        );
                                    }
                                    )}
                                </div>
                            </ResponseBoundary>
                        </CommonContainer>
                        <CommonContainer title={`Top ${topN} ${type} of ${selectedCostType} Cost Trends`}>
                            <ResponseBoundary
                                data={topServiceData.data}
                                isLoading={topServiceData.isLoading || serviceTree.isLoading}
                                errorHappened={topServiceData.isError}
                            >
                                <LineChart
                                    series={series}
                                    anomalies={activeAnomalies}
                                    containerProps={trackChartHoveringProps}
                                />
                            </ResponseBoundary>
                        </CommonContainer>
                    </Stack>
                    <ResponseBoundary
                        isLoading={isCostByServiceLoading}
                        errorHappened={isCostByServiceError}
                        data={costByService}
                    >
                        <div>
                            <SubstrateCostTable
                                key={selectedCostType}
                                columns={columnList}
                                defaultSortingColumn={columnList[0]}
                                costByService={costByService}
                                globalSelectedColumns={substrateData.substrateCostTableSelectedColumns}
                                serviceTreeIndexMap={serviceTree.indexMap}
                                fixedColumn="Service - App/Process/Client"
                                makeChangeColumnsAction={makeModifySubstrateCostTableAction}
                                scenarioNoMap={serviceTree.scenarioNoMap}
                                appScenarios={serviceTree.appScenarios}
                                scenarioView={displayScenarioView}
                                costByScenario={substrateScenarioCost}
                                switchedColumns={defaultColumnConfigs.scenarioView}
                                logComponent={LogComponent.TransChartingPane}
                            />
                        </div>
                    </ResponseBoundary>
                </Stack>
            }
        </Stack>
    )
}

function createLineSeriesBy(
    useServiceNameDirectly = false,
    data: ISubstrateTopComponents | undefined,
    serviceTreeMap: Map<string, ServiceTreeItem> | undefined,
    iteratee: keyof ISubstrateTotalCostResponse | ((value: ISubstrateTotalCostResponse) => number),
    anomalies?: INotification[],
    ): LineChartSeries[] {
   const exactIteratee = typeof iteratee === "string" ? (value: ISubstrateTotalCostResponse) => value[iteratee] : iteratee;
   return toPairs(data).map(([id, points]) => {
       const list: [number, number][] = [];
       let sum = 0;
       for (const point of points) {
           const y = exactIteratee(point);
           if (y && typeof y === "number") {
               list.push([moment.utc(point.extractionDate).valueOf(), y]);
               sum += y;
           }
       }
       return {
           name: useServiceNameDirectly ? id : (serviceTreeMap ? serviceTreeMap.get(id)?.n || "unknown" : id),
           data: list,
           anomalies,
           sum
       }
   }).sort((a, b) => b.sum - a.sum);
}

export default TransactionCostView;

