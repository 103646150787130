import { IBreadcrumbItem, Pivot, PivotItem, Separator, Spinner } from '@fluentui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import styles from "../Page.less";

import { useIEfficiencyTrackerProject } from '../../../../hooks/useEfficiencyTrackerProject';
import { useGotoPage } from '../../../../hooks/useGotoPage';
import { BannerModel } from '../../../../models/BannerModels';
import { DownloadRegionPair, EfficiencyStatusEnum, EfficiencyTrackerReductionPlanRegionConfig, IEfficiencyTrackerProject } from '../../../../models/EfficiencyTracker';
import { Pages } from '../../../../models/Nav';
import { WarningBanner } from '../../../banner/WarningBanner';
import PlanDetailsTable from '../../Components/Table/PlanDetailsTable';
import { formatValue } from '../../Components/Tools/ExportFunction';
import PageWrapper from '../PageWrapper';
import EfficiencyStatus, { IEfficiencyStatus } from '../../Components/Tools/EfficiencyStatus';
import RegionDetails from '../../Components/RegionDetails';
import { useMsal } from "@azure/msal-react";

const ETSubProjectViewV2: React.FC = () => {
    //breadcrumb
    const gotoPage = useGotoPage();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const projectlink = pathSegments[2];
    const isBaremetal = pathSegments[3];
    const { instance } = useMsal();
    const account = instance.getActiveAccount();

    //Get project details
    const { planId } = useParams();
    const { plans: plans, project: project, isLoading } = useIEfficiencyTrackerProject();
    const planIdNumber: number = parseInt(planId || "0", 10);
    const isBaremetalFlag = isBaremetal === "Baremetal";

    //Initial
    const [invalidProject, setInvalidProject] = useState<boolean>(false);
    const [currentproject, setCurrentProject] = useState<IEfficiencyTrackerProject>();
    const [currentPlanWithProject, setCurrentPlanWithProject] = useState<IEfficiencyTrackerProject>();
    const [regionList, setRegionList] = useState<IEfficiencyStatus[]>([]);
    const [regionDownloadList, setRegionDownloadList] = useState<DownloadRegionPair[]>([]);
    const [bannerFlag, setBannerFlag] = useState<boolean>(false);
    const [selectRegion, setSelectRegion] = useState<string>();
    const [projectId, setProjectId] = useState<number>();

    useEffect(() => {
        document.title = 'Efficiency Tracker';
    }, []);

    useEffect(() => {
        if (!isLoading) {
            const plan = plans.find(item => item.Id === planIdNumber);
            if (!plan) {
                setInvalidProject(true);
                setCurrentPlanWithProject(undefined);

                return;
            }

            const relateProject = project.find(item => item.Id === plan.ProjectId);
            const relatedPlans = plans.filter(item => item.ProjectId === plan.ProjectId && item.Status !== EfficiencyStatusEnum.Deleted);
            if (relateProject) {
                setProjectId(relateProject.Id);
                const iEfficiencyTrackerProjectCurrent: IEfficiencyTrackerProject = {
                    EfficiencyTrackerProject: relateProject,
                    EfficiencyTrackerReductionPlans: [plan],
                }
                const iEfficiencyTrackerProject: IEfficiencyTrackerProject = {
                    EfficiencyTrackerProject: relateProject,
                    EfficiencyTrackerReductionPlans: relatedPlans,
                }
                setCurrentPlanWithProject(iEfficiencyTrackerProjectCurrent);
                setCurrentProject(iEfficiencyTrackerProject);

                //Set Region list.
                const configs = plan.EfficiencyTrackerReductionPlanRegionConfigs as unknown as EfficiencyTrackerReductionPlanRegionConfig[];
                const allRegionsTmp = configs.map(config => isBaremetalFlag ? config.Region : config.AzureRegion);
                const regionListTmp: IEfficiencyStatus[] = allRegionsTmp.map(region => ({
                    Status: EfficiencyStatusEnum.Loading,
                    text: region,
                }));

                setRegionList(regionListTmp);
            } else {
                setInvalidProject(true);
                setCurrentPlanWithProject(undefined);
            }
        }
    }, [planIdNumber, isLoading]);

    useEffect(() => {
        if (!regionList.length) return;
        if (regionList.find(item => item.Status === EfficiencyStatusEnum.Delayed)) {
            setBannerFlag(true);
        }
    }, [regionList]);

    const handleRegionListChange = useCallback((region: string, status: EfficiencyStatusEnum) => {
        setRegionList((prevRegionList) => {
            const updatedRegionList = prevRegionList.map((item) =>
                item.text === region ? { ...item, Status: status } : item
            );
            return updatedRegionList;
        });
    }, []);

    const handleRegionDownloadListChange = useCallback((region: string, regionPair: DownloadRegionPair) => {
        setRegionDownloadList((prevList) => {
            const regionIndex = prevList.findIndex(item => item.Region === region);

            if (regionIndex !== -1) {
                // Region exists, update the existing entry
                const updatedList = [...prevList];
                updatedList[regionIndex] = { ...updatedList[regionIndex], dataPairList: regionPair.dataPairList };
                return updatedList;
            } else {
                // Region does not exist, add it to the list
                return [...prevList, { Region: region, dataPairList: regionPair.dataPairList }];
            }
        });
    }, []);

    const breadcrumbItems: IBreadcrumbItem[] = [
        { key: 'Project', text: projectlink, onClick: () => gotoPage(`${Pages.EfficiencyTrackerV2}/${projectlink}/${isBaremetal}`) },
        { key: 'isBaremetal', text: isBaremetal, onClick: () => gotoPage(`${Pages.EfficiencyTrackerV2}/${projectlink}/${isBaremetal}`) },
        { key: 'ProjectName', text: currentPlanWithProject?.EfficiencyTrackerProject.Name || "Project Details" },
    ];

    const bannerNotice: BannerModel = {
        id: "EFSubProjectId",
        tab: "Efficiency Tracker",
        startDate: formatValue(currentPlanWithProject?.EfficiencyTrackerProject.CreateTime),
        endDate: formatValue(currentPlanWithProject?.EfficiencyTrackerProject.CreateTime),
        type: "severeWarning",
        metric: "/",
        message: "One or more regions on this efficiency project are above the reduction target.",
        url: "https://msjaws.com/",
        urlText: " Learn how to improve you project's efficiency and get back on track.",
        displayName: "/",
    };

    const handleTabChange = (item?: PivotItem) => {
        if (!item || !item.props.itemKey) return;

        setSelectRegion(item.props.itemKey);
    };

    return (
        <PageWrapper
            key={"PageWrapper_" + planIdNumber}
            title={currentPlanWithProject?.EfficiencyTrackerProject.Name || "Project Details"}
            breadcrumbItems={breadcrumbItems}
            description={currentPlanWithProject?.EfficiencyTrackerProject.Description}
            AddNewPlanLink={`${Pages.EfficiencyTrackerV2}/${projectlink}/${isBaremetal}/${Pages.EFAddPlanPage}/${projectId}`}
            EditLink={`${Pages.EfficiencyTrackerV2}/${projectlink}/${isBaremetal}/${Pages.EFEditPage}/${planIdNumber}`}
            AllowDelete={currentPlanWithProject?.EfficiencyTrackerProject.Owner === account?.username}
            DownloadRegionPair={regionDownloadList}
            ShareProjectId={currentPlanWithProject?.EfficiencyTrackerProject.Id}
            DeleteProjectId={currentPlanWithProject?.EfficiencyTrackerProject.Id}
        >
            <div key={planIdNumber}>
                {invalidProject ? (
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '6px', paddingTop: '12px' }}>
                        Error: Sub Project not found
                    </div>
                ) : (!currentPlanWithProject || ! currentproject) ? (
                        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '6px', paddingTop: '12px' }}>
                            <Spinner label="Loading..." labelPosition="right" />
                        </div>
                    ) : (
                        <>
                            <PlanDetailsTable data={currentproject} currentPlanId={planIdNumber} />
                            {bannerFlag && (
                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '12px' }}>
                                    <WarningBanner bannerItem={bannerNotice} />
                                </div>
                            )}
                            <Pivot className={styles.Pivot} overflowBehavior="menu" onLinkClick={handleTabChange} selectedKey={selectRegion || "Region"}>
                                {
                                    regionList.map(region => (
                                        <PivotItem
                                            key={region.text}
                                            headerText={region.text}
                                            itemKey={region.text}
                                            itemCount={1}
                                            onRenderItemLink={() => (
                                                <div>
                                                    <EfficiencyStatus Status={region.Status} text={region.text} />
                                                </div>
                                            )}
                                        >
                                            <Separator styles={{ root: styles.separator }} />
                                            <div>
                                                <RegionDetails
                                                    isBaremetalFlag={isBaremetalFlag}
                                                    Region={region.text as string}
                                                    data={currentPlanWithProject}
                                                    onRegionStatus={handleRegionListChange}
                                                    onRegionDataPair={handleRegionDownloadListChange} />
                                            </div>
                                        </PivotItem>
                                    ))
                                }
                            </Pivot>
                            { // Load all details to display status (hidden)
                                regionList.map(region => (
                                    <div key={region.text} style={{ display: 'none' }} >
                                        <RegionDetails
                                            isBaremetalFlag={isBaremetalFlag}
                                            Region={region.text as string}
                                            data={currentPlanWithProject}
                                            onRegionStatus={handleRegionListChange}
                                            onRegionDataPair={handleRegionDownloadListChange} />
                                    </div>
                                ))
                            }
                        </>
                )}
            </div>
        </PageWrapper>
    );

};

export default ETSubProjectViewV2;
