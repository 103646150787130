export class LocalStorageUtils {
    static getLocalStorageData<T>(dataKey: string) {
        let result: T | null = null;

        try {
            const strValue = localStorage.getItem(dataKey);

            if (strValue != null) {
                result = JSON.parse(strValue);
            }
        } catch (e) {
            //
        }

        return result;
    }

    static removeLocalStorageData(dataKey: string) {
        localStorage.removeItem(dataKey);
    }

    static setLocalStorageData<T>(dataKey: string, data: T) {
        try {
            localStorage.setItem(dataKey, JSON.stringify(data));
            return true;
        } catch (e) {
            return false;
        }
    }
}
