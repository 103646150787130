import React from "react";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import {
    ESCostDataDimensions,
    ESCostOrganization,
    ESHourlyBuilderStateCountRawResponse,
    ESOverviewTimeSelection,
} from "../../../../models/ESCostModels";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import ChartContainer from "../../../chart/ChartContainer";
import { useEsOrg } from "../../../../hooks/useEsOrg";
import { ESOrgs } from "../../../../models/constants/ESCostConstants";
import moment from "moment";
import { LineChartSeries } from "../../../common/LineChart";
import CommonConstants from "../../../../models/constants/CommonConstants";

interface IBuilderActivityHourlyTrendProps {
    data: ESHourlyBuilderStateCountRawResponse[];
}

function calculateSeries(
    org: ESCostOrganization[],
    year: number,
    data: ESHourlyBuilderStateCountRawResponse[]
): LineChartSeries[] {
    const hours = Array.from({ length: 24 }, (_, index) => index);
    const costs = data.filter(
        (cost) =>
            ESOrgs.includes(cost[ESCostDataDimensions.Organization]) &&
            (org.length === 0 || org.includes(cost[ESCostDataDimensions.Organization])) &&
            year === moment(cost[ESCostDataDimensions.TimeStamp]).year()
    );

    const d: { name: string; values: [number, number][] }[] = Array.from(["Active builders"], (n) => {
        const valueList: [number, number][] = Array.from(hours, (h) => {
            const datetime = moment().set("hour", h).set("minute", 0);
            return [datetime.valueOf(), 0];
        });
        return { name: n, values: valueList };
    });

    costs.forEach((cost) => {
        const j = moment(cost[ESCostDataDimensions.TimeStamp]).hour();
        d[0].values[j][1] += cost[ESCostDataDimensions.Count];
    });

    return d.map((obj, index) => {
        return {
            name: obj.name,
            data: obj.values,
            showDeviation: false,
            color: CommonConstants.DefaultColors[index],
        };
    });
}

export const BuilderActivityHourlyTrend: React.FC<IBuilderActivityHourlyTrendProps> = (props) => {
    const { data } = props;
    const [org] = useEsOrg();
    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );
    const series: LineChartSeries[] = React.useMemo(() => calculateSeries(org, year, data), [org, year, data]);

    return (
        <ChartContainer
            headerProps={{ title: `Count of active CloudBuild builders by hour (FY${String(year).substring(2)})` }}
            chartType="line"
            chartProps={{
                isNotCurrency: true,
                ignoreWeekend: false,
                height: 220,
                series,
                hideEmptySeries: true,
                dateFormatStr: "HH:mm",
                tooltipDateFormatStr: "HH:mm",
            }}
        />
    );
};
