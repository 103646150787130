// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JJzZUFDARlCWFYeSf_Sv {\n  padding: 48px 48px 32px 48px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/substrateplatform/SubstratePlatform.less"],"names":[],"mappings":"AAAA;EACI,4BAAA;AACJ","sourcesContent":[".pageHeader {\n    padding: 48px 48px 32px 48px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": "JJzZUFDARlCWFYeSf_Sv"
};
export default ___CSS_LOADER_EXPORT___;
