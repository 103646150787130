import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey, TimeView } from "../models/SearchKey";

export function useTimeView(): [TimeView, (timeView?: TimeView) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const timeView: TimeView = useMemo(() => {
        const timeViewStr = searchParams.get(SearchKey.TimeView);
        if (!timeViewStr) {
            return TimeView.Daily;
        }
        return timeViewStr as TimeView;
    }, [searchParams]);

    const setTimeView = useCallback((timeView?: TimeView) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (!timeView) {
            newSearchParams.delete(SearchKey.TimeView);
        } else {
            newSearchParams.set(SearchKey.TimeView, timeView);
        }

        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return [timeView, setTimeView];
}