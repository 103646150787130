import * as React from "react";

import {
    ContextualMenuItemType,
    DefaultButton,
    DirectionalHint,
    IButton,
    IContextualMenuItem,
    IContextualMenuProps,
    IStackTokens,
    Stack,
    mergeStyleSets,
    values
} from "@fluentui/react";
import { FiltersAction, FiltersView } from "../../../../reducers/filterReducer";
import { ISavedViewData, shareViewAction } from "../../../../reducers/savedViewReducer";
import { ViewType, makeToggleDeleteDialogAction } from "../../../../reducers/dialogsReducer";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IAppState } from "../../../../store";
import { IFilterView } from "../../../../models/FilterView";
import { makeGlobalFiltersFromView } from "../../../../utils/FiltersUtils";
import styles from "./ViewSelector.less";
import { useCategoryFilters } from "../../../../hooks/useFilters";
import { useDateRange } from "../../../../hooks/useDateSelector";

const outlineStackTokens: IStackTokens = {
    childrenGap: 8,
};

interface IViewSelectorProps {
    inputViewName: string;
    onViewChange: (selectedViewId: number, selectedViewName: string) => void;
}

// Refer to https://o365exchange.visualstudio.com/O365%20Core/_git/Jaws?path=/sources/dev/Frontends/Jaws%20Portal/src/Components/TimeScale.tsx&version=GBmaster&_a=contents
const ViewSelector: React.FC<IViewSelectorProps> = ({ inputViewName, onViewChange}) => {
    const viewButtonRef = React.useRef<IButton>(null);
    const [selectedView, setSelectedView] = useState<string>("Global (Default)");
    const [inputViewNameSave, setinputViewNameSave] = useState<string>("Global (Default)");
    const [selectedViewId, setSelectedViewId] = useState<number>(-1);

    const { startDate, endDate } = useDateRange();
    const [localFilters, setLocalFilters] = useState({ startDate, endDate });


    const updateFilters = useCategoryFilters().updateFilters;
    const dispatch = useDispatch();

    const onHandleShowFilters = useCallback(
        (view) => {
            updateFilters(FiltersAction.Replace, { filters: makeGlobalFiltersFromView(view), view: FiltersView.AddableList });
        },
        [updateFilters]
    );

    const { savedViews, isLoading } = useSelector<IAppState, ISavedViewData>((state) => state.savedViews);
    const [customViews, setCustomViews] = useState<IContextualMenuItem[]>([]);

    React.useEffect(() => {
        if (!isLoading && inputViewName !== inputViewNameSave) { // Avoid loading reload inputViewName
            let viewId:number | null = null;
            values<IFilterView>(savedViews).map((item) => { //Get latest viewName for viewId.
                if (item.viewName === inputViewName) viewId = item.id;
            });

            if (viewId) {
                setSelectedViewId(viewId);
                setSelectedView(inputViewName);
                setinputViewNameSave(inputViewName);
                onViewChange(viewId, inputViewName);
            }
        }
    }, [isLoading, inputViewName]);

    React.useEffect(() => {
        if (!isLoading) {
            const tempViews : IContextualMenuItem[] = [];
            let isCurrentViewDeleted = true;
            values<IFilterView>(savedViews).forEach((view) => {
                if(view.id !== 0) {
                    if (view.id == selectedViewId) {
                        isCurrentViewDeleted = false;
                    }
                    tempViews.push({
                        key: view.viewName + view.id, //Avoid same viewName
                        text: view.viewName,
                        onMouseDown: () => {
                            setSelectedView(view.viewName);
                            setSelectedViewId(view.id)
                            onViewChange(view.id, view.viewName)
                            onHandleShowFilters(view);
                            viewButtonRef.current?.dismissMenu()
                        },
                        subMenuProps: {
                            items: [
                                {
                                    key: "shareView",
                                    text: "Share View",
                                    iconProps: {iconName: "Share"},
                                    onClick: () => {
                                        dispatch(shareViewAction({ startDate: localFilters.startDate, endDate: localFilters.endDate }, view.filterContent));
                                    },
                                },
                                // { key: "editName", text: "Edit Name" },
                                {
                                    key: "delete",
                                    text: "Delete",
                                    iconProps: {iconName: "Delete"},
                                    onClick: () => {
                                        dispatch(makeToggleDeleteDialogAction(true, view.viewName, view.id, ViewType.View));
                                    },
                                },
                            ],
                        },
                    });
                }
            });
            if (isCurrentViewDeleted && selectedViewId != -1) {
                setSelectedView("Global (Default)");
                onClearAll();
                setSelectedViewId(-1);
                onViewChange(-1, "Global (Default)")
            }
            setCustomViews(tempViews)
        }
    }, [isLoading,savedViews, selectedViewId, onHandleShowFilters]);

    const onClearAll = useCallback(() => {
        updateFilters(FiltersAction.Replace);
    }, [updateFilters]);

    const classNames = mergeStyleSets({
        menu: {
            textAlign: "center",
            maxWidth: 180,
            selectors: {
                ".ms-ContextualMenu-item": {
                    height: "auto",
                },
            },
        },
        item: {
            display: "inline-block",
            width: 40,
            height: 40,
            lineHeight: 40,
            textAlign: "center",
            verticalAlign: "middle",
            marginBottom: 8,
            cursor: "pointer",
            selectors: {
                "&:hover": {
                    backgroundColor: "#eaeaea",
                },
            },
        },
        categoriesList: {
            margin: 0,
            padding: 0,
            listStyleType: "none",
        },
        button: {
            width: "40%",
            margin: "2%",
        },
    });

    const menuProps = React.useMemo<IContextualMenuProps>(
        () => ({
            shouldFocusOnMount: true,
            directionalHint: DirectionalHint.bottomLeftEdge,
            className: classNames.menu,
            items: [
                {
                    key: "section1",
                    itemType: ContextualMenuItemType.Section,
                    sectionProps: {
                        topDivider: true,
                        bottomDivider: true,
                        title: "Built-in filter views",
                        items: [
                            {
                                key: "global",
                                text: "Global (default)",
                                onClick: () => {
                                    setSelectedView("Global (Default)");
                                    onClearAll();
                                },
                            },
                        ],
                    },
                },
                {
                    key: "section2",
                    itemType: ContextualMenuItemType.Section,
                    sectionProps: {
                        topDivider: true,
                        bottomDivider: true,
                        title: "Custom filter sets",
                        items: customViews,
                    },
                },
            ],
        }),
        [classNames.menu, customViews, onClearAll]
    );

    return (
        <Stack horizontal verticalAlign="center" tokens={outlineStackTokens}>
            <DefaultButton text={selectedView} menuProps={menuProps} className={styles.dateRangeButton} componentRef={viewButtonRef}/>
        </Stack>
    );
};

export default ViewSelector;
