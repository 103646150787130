// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SuxwUiPoah8pKL0CBQc6 {\n  height: 357px;\n  padding-left: 12px;\n  padding-top: 0;\n  border: 1px solid;\n  border-color: #68768A;\n  border-radius: 4px;\n  width: \"100%\";\n}\n.WXco0yLHOsXwGdrIo2GA {\n  margin: 13px 0px 0px 0px;\n  position: relative;\n  transition: all 0.3s ease;\n  color: #323130;\n  width: \"100%\";\n}\n.ZaAsbd4cdOnTvE7uQRC2 {\n  margin-left: 16px;\n  height: 48px;\n  margin-bottom: 0;\n}\n.DXCoUwAZqYVlWwJJvEHi {\n  background: #E8E8E8;\n  border: none;\n  height: 32px;\n  font-size: 14px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/substrateV2/storeIOAnomaly/InsightsCard.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,cAAA;EACA,iBAAA;EACA,qBAAA;EACA,kBAAA;EACA,aAAA;AACJ;AAEA;EACI,wBAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,aAAA;AAAJ;AAGA;EACI,iBAAA;EACA,YAAA;EACA,gBAAA;AADJ;AAIA;EACI,mBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AAFJ","sourcesContent":[".cardWrapper {\n    height: 357px;\n    padding-left: 12px;\n    padding-top: 0;\n    border: 1px solid;\n    border-color: #68768A;\n    border-radius: 4px;\n    width: \"100%\";\n}\n\n.cardContainer {\n    margin: 13px 0px 0px 0px;\n    position: relative;\n    transition: all 0.3s ease;\n    color: #323130;\n    width: \"100%\";\n}\n\n.cardFooter {\n    margin-left: 16px;\n    height: 48px;\n    margin-bottom: 0;\n}\n\n.viewInsight {\n    background: #E8E8E8;\n    border: none;\n    height: 32px;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardWrapper": "SuxwUiPoah8pKL0CBQc6",
	"cardContainer": "WXco0yLHOsXwGdrIo2GA",
	"cardFooter": "ZaAsbd4cdOnTvE7uQRC2",
	"viewInsight": "DXCoUwAZqYVlWwJJvEHi"
};
export default ___CSS_LOADER_EXPORT___;
