import {
    Column,
    PluginHook,
    Row,
    TableInstance,
    TableOptions,
    TableState,
    UseExpandedInstanceProps,
    UseExpandedOptions,
    UseExpandedRowProps,
    UseExpandedState,
    UseSortByColumnOptions,
    UseSortByOptions,
    UseSortByState,
    useExpanded,
    useSortBy,
    useTable,
} from "react-table";
import React, { useMemo, useState } from "react";

import { IColumnSelectorItem } from "./ColumnSelector";
import { trackEventCallback } from "../../../utils/AppInsights";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";

export const useTableColumnSelection = <T extends object>(tableInstance: ReactTableInstance<T>, logComponent: LogComponent, logElement: LogElement) => {
    const columns: ReactTableColumnOption<T>[] = tableInstance.columns;
    const [visibleColumns, setVisibleColumns] = useState<string[]>(
        columns
            .map((item) => item.id as string)
            .filter((col) => {
                if (!tableInstance.initialState?.hiddenColumns) return true;

                return tableInstance.initialState.hiddenColumns.indexOf(col) < 0;
            })
    );

    const [hiddenColumns, setHiddenColumns] = useState<string[]>(tableInstance.initialState?.hiddenColumns || []);

    const columnOptions = useMemo<IColumnSelectorItem[]>(() => {
        return columns.map((col) => ({
            key: col.id,
            disabled: col.disableHide,
            displayName: col.name || col.id,
        }));
    }, [columns]);

    const onVisibilityChange = (newVisibleColumns: string[]) => {
        const newHiddenColumns = columns.filter((col) => col.id && newVisibleColumns.indexOf(col.id) < 0).map((item) => item.id as string);

        tableInstance.setHiddenColumns(newHiddenColumns);
        setVisibleColumns(newVisibleColumns);
        setHiddenColumns(newHiddenColumns);
        trackEventCallback(logComponent, logElement, "Update Columns", LogTarget.Button, {visibleColumns: newVisibleColumns});
    };

    return {
        hiddenColumns,
        visibleColumns,
        columnOptions,
        onVisibilityChange,
    };
};

const defaultOptions: Partial<ReactTableOptions<object>> = {
    autoResetHiddenColumns: false,
    autoResetExpanded: false,
    autoResetSortBy: false,
    disableSortRemove: true,
};

export type ReactTableColumnOption<T extends object, I extends string = string> = Column<T> &
    UseSortByColumnOptions<T> & {
        disableHide?: boolean;
        headerProps?: React.HTMLProps<HTMLTableCellElement>;
        cellProps?: React.HTMLProps<HTMLTableCellElement>;
        footerProps?: React.HTMLProps<HTMLTableCellElement>;
        columnProps?: React.HTMLProps<HTMLTableCellElement>;
        headerTooltip?: string | JSX.Element | JSX.Element[];
        id: I;
        name?: string;
    };

export interface ReactTableOptions<T extends object> extends TableOptions<T>, Partial<UseSortByOptions<T>>, Partial<UseExpandedOptions<T>> {
    columns: ReactTableColumnOption<T>[];
    initialState: Partial<TableState<T> & UseSortByState<T> & UseExpandedState<T>> | undefined;
}

export type ReactTableInstance<T extends object> = TableInstance<T> &
    Partial<
        UseExpandedInstanceProps<T> & {
            state: TableState<T> & Partial<UseSortByState<T>>;
            columns: ReactTableColumnOption<T>[];
        }
    >;

export type ReactTableExpandableData<T extends object> = {
    data: T;
    depth: number;
    isSubRowsLoading?: boolean;
    isSubRowPlaceHolder?: boolean;
    subRows?: ReactTableExpandableData<T>[];
    parent?: ReactTableExpandableData<T>;
};

export type ReactTableRow<T extends object> = Row<T> & UseExpandedRowProps<T>;

export function useReactTable<T extends object>(options: ReactTableOptions<T>, ...plugins: PluginHook<T>[]) {
    const columns = useMemo(() => {
        return options.columns.map((item) => ({
            Header: item.name,
            ...item,
        }));
    }, [options.columns]);

    const tableInstance = useTable<T>(
        {
            ...defaultOptions,
            ...options,
            columns,
        } as ReactTableOptions<T>,
        useSortBy,
        useExpanded,
        ...plugins
    ) as ReactTableInstance<T>;

    return tableInstance;
}
