/* eslint-disable @typescript-eslint/no-explicit-any */

import { AnyAction, Store, applyMiddleware, combineReducers, createStore } from "redux";
import { FiltersAction, filterReducer } from "./reducers/filterReducer";
import { IActionableItemsData, actionableItemsReducer } from "./reducers/actionableItemsReducer";
import { IDialogsData, dialogsReducer } from "./reducers/dialogsReducer";
import { IRingData, ringReducer } from "./reducers/ringReducer";
import { ISavedViewData, savedViewReducer } from "./reducers/savedViewReducer";
import { ISearchData, searchReducer } from "./reducers/searchReducer";
import { ISearchPanelData, searchPanelReducer } from "./reducers/searchPanelReducer";
import { IServiceTreeData, serviceTreeReducer } from "./reducers/serviceTreeReducer";
import { ISubViewPanelData, subviewPanelReducer } from "./reducers/subviewPanelReducer";
import { ISubViewsData, subViewReducer } from "./reducers/subviewReducer";
import { ISubstrateData, substrateReducer } from "./reducers/substrateReducer";

import thunkMiddleware from "redux-thunk";

export interface IAppState {
    dialogs: IDialogsData;
    serviceTree: IServiceTreeData;
    search: ISearchData;
    searchPanel: ISearchPanelData;
    savedViews: ISavedViewData;
    substrate: ISubstrateData;
    filtersNextAction: FiltersAction;
    actionableElementsRef: IActionableItemsData;
    subviewPanel: ISubViewPanelData;
    subViewsData: ISubViewsData;
    ring: IRingData;
}

export type GetStateFunction = () => IAppState;

const store: Store<IAppState, AnyAction> = createStore(
    combineReducers<IAppState>({
        dialogs: dialogsReducer,
        serviceTree: serviceTreeReducer,
        search: searchReducer,
        searchPanel: searchPanelReducer,
        savedViews: savedViewReducer,
        substrate: substrateReducer,
        filtersNextAction: filterReducer,
        actionableElementsRef: actionableItemsReducer,
        subviewPanel: subviewPanelReducer,
        subViewsData: subViewReducer,
        ring: ringReducer,
    }),
    applyMiddleware<IAppState>(thunkMiddleware)
);

export default store;
