// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KuXh8VoUSqVAWR21LoS2 {\n  padding: 0 48px;\n  margin-top: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/substrateV2/transaction/SubstrateTransaction.less"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,gBAAA;AAAJ","sourcesContent":["\n.pivotList {\n    padding: 0 48px;\n    margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pivotList": "KuXh8VoUSqVAWR21LoS2"
};
export default ___CSS_LOADER_EXPORT___;
