import { EventType, LogComponent, LogElement, LogTarget } from "../../models/LogModel";
import { IIconProps, IconButton } from "@fluentui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { LeadershipMetricNames as metricNames, LeadershipMetrics as metrics } from "../../models/constants/CosmicConstants";

import CommonConstants from "../../models/constants/CommonConstants";
import CostCard from "../common/costCard/CostCard";
import FeedbackPanel from "../feedback/FeedbackPanel";
import { ICosmicDailyMetricReport } from "../../models/CosmicModels";
import { Section } from "../common/Section";
import html2canvas from "html2canvas";
import styles from "./LeadershipMetricsCard.less";
import { trackEventCallback } from "../../utils/AppInsights";
import { useBoolean } from "@fluentui/react-hooks";
import { useDateRange } from "../../hooks/useDateSelector";
import { useFlights } from "../../hooks/useSettings";
import { useGetCosmicDailyMetric } from "../../hooks/useCosmicQuery";
import { useMsal } from "@azure/msal-react";

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

interface ILeadershipMetricsCardProps {
    totalCogs: ICosmicDailyMetricReport[];
    isLoadingTotalCogs: boolean;
}

const getMetricBreakdown = (metricName: string, metrics: ICosmicDailyMetricReport[] = []): number => {
    return metrics.filter((metric) => metric.workload === metricName).reduce((acc, curr) => acc + (curr.total ?? 0), 0);
};

const getTitle = (key: string, metricNames: Record<string, string>, loadingStates: Record<string, boolean>): string => {
    const isLoading = loadingStates[key];
    return isLoading ? `Loading ${metricNames[key]}...` : metricNames[key] || "";
};

const LeadershipMetricsCard: React.FC<ILeadershipMetricsCardProps> = ({ totalCogs, isLoadingTotalCogs }) => {
    const { startDate, endDate } = useDateRange();
    const { instance } = useMsal();
    const { data: flights } = useFlights();

    const { cosmicDailyMetricReport: singleDayTotalCores, isLoading: isLoadingSingleDayTotalCores } = useGetCosmicDailyMetric("TotalCores", true);
    const { cosmicDailyMetricReport: singleDayTotalCogs, isLoading: isLoadingSingleDayTotalCogs } = useGetCosmicDailyMetric("TotalCogs", true);
    const { cosmicDailyMetricReport: singleDayPlatformLevelUtilizationP99, isLoading: isLoadingSingleDayPlatformLevelUtilizationP99 } = useGetCosmicDailyMetric("PlatformLevelAppUtilizationP99", true);

    const loadingStates: Record<string, boolean> = useMemo(() => ({
        "total-cosmic-cost": isLoadingTotalCogs,
        "non-vm-cost": isLoadingTotalCogs,
        "platform-vm-cost": isLoadingTotalCogs,
        "partner-vm-cost": isLoadingTotalCogs,
        "daily-cosmic-cost": isLoadingSingleDayTotalCogs,
        "daily-cosmic-cores": isLoadingSingleDayTotalCores,
        "daily-overhead-cores": isLoadingSingleDayTotalCores,
        "daily-app-cores": isLoadingSingleDayTotalCores,
        "daily-overhead-with-rightsizing": isLoadingSingleDayTotalCores,
        "daily-p99-app-core-utilization": isLoadingSingleDayPlatformLevelUtilizationP99,
    }), [isLoadingTotalCogs, isLoadingSingleDayTotalCogs, isLoadingSingleDayTotalCores, isLoadingSingleDayPlatformLevelUtilizationP99]);

    const cosmicMetricsData: Record<string, number> = useMemo(() => ({
        "total-cosmic-cost": getMetricBreakdown("TotalCosmicCost", totalCogs),
        "non-vm-cost": getMetricBreakdown("NonVMCost", totalCogs),
        "platform-vm-cost": getMetricBreakdown("PlatformVMCost", totalCogs),
        "partner-vm-cost": getMetricBreakdown("AppVMCosts", totalCogs),
        "daily-cosmic-cost": getMetricBreakdown("TotalCosmicCost", singleDayTotalCogs),
        "daily-cosmic-cores": getMetricBreakdown("TotalCores", singleDayTotalCores),
        "daily-overhead-cores": getMetricBreakdown("OverheadCores", singleDayTotalCores),
        "daily-app-cores": getMetricBreakdown("AppCores", singleDayTotalCores),
        "daily-overhead-with-rightsizing": getMetricBreakdown("OverheadWithRightsizingRatio", singleDayTotalCores),
        "daily-p99-app-core-utilization": getMetricBreakdown("CpuUtilizationP99", singleDayPlatformLevelUtilizationP99)
    }), [totalCogs, singleDayTotalCogs, singleDayTotalCores, singleDayPlatformLevelUtilizationP99]);

    const [cardWidth, setCardWidth] = useState<number | undefined>(undefined);
    const hiddenCardListRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (hiddenCardListRef.current) {
            const maxWidth = Array.from(hiddenCardListRef.current.childNodes).reduce((max, elem) => {
                return Math.max(max, (elem as HTMLDivElement).getBoundingClientRect().width);
            }, 0);
            setCardWidth(maxWidth || undefined);
        }
    }, [isLoadingTotalCogs, isLoadingSingleDayTotalCores, isLoadingSingleDayPlatformLevelUtilizationP99, isLoadingSingleDayTotalCogs]);


    // Feedback handling
    const account = instance.getActiveAccount();
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');
    const [currentScreenshotURL, setCurrentScreenshotURL] = useState<string>('');

    const captureScreenshot = async (title: string) => {
        trackEventCallback(LogComponent.CosmicLeadershipView, LogElement.CosmicLeadershipMetricsCards, "Send Feedback Chart", LogTarget.Button, undefined, EventType.Click);

        const url = window.location.href;
        setCurrentURL(url);

        await openFeedbackPanel();

        const targetElement = document.getElementById(`FeedbackSection_${title}`);

        if (targetElement) {
            setTimeout(() => {
                html2canvas(targetElement).then((canvas) => {
                    const screenshotDataUrl = canvas.toDataURL('image/png');
                    setCurrentScreenshotURL(screenshotDataUrl);
                });
            }, 100);

        }
    };

    const handleDismissFeedbackPanel = () => {
        setCurrentScreenshotURL('');
        dismissFeedbackPanel();
    };

    return (
        <div className={styles.cardContainer} id={`FeedbackSection_CosmicLeadershipMetricsCard`} >
            {flights?.enableUserFeedback &&
                <IconButton
                    title="Send feedback"
                    className={styles.feedbackIcon}
                    iconProps={feedbackIconProps}
                    onClick={() => captureScreenshot("CosmicLeadershipMetricsCard")}
                />
            }
            <Section
                headerProps={{ title: "Basic Metrics" }}
                styles={{ content: "" }}
                loading={Object.values(loadingStates).some((loading) => loading)}
            >
                {/** Hidden cards used for calculating card width */}
                <div className={`${styles.cardList} ${styles.hiddenCardList}`} ref={hiddenCardListRef}>
                    {metrics.map((key, index) => (
                        <div key={key}>
                            <CostCard title={metricNames[key] || ""} color={CommonConstants.DefaultColors[index]} cost={cosmicMetricsData[key] || 0} />
                        </div>
                    ))}
                </div>
                <div className={styles.cardList}>
                    {cardWidth !== undefined &&
                        metrics.map((key, index) => (
                            <div key={key} style={{ width: cardWidth }}>
                                <CostCard
                                    classNames={{ costValue: styles.costValue }}
                                    showTooltip
                                    date={key.includes("daily") ? [endDate] : [startDate, endDate]}
                                    title={getTitle(key, metricNames, loadingStates)}
                                    className={cosmicMetricsData[key] ? styles.activeCard : ""}
                                    color={CommonConstants.DefaultColors[index]}
                                    cost={cosmicMetricsData[key] || 0}
                                    noPrefix={!key.includes("cost")}
                                    formatter={key.includes("utilization") || key.includes("Rightsizing") ? (value) => `${(value * 100).toFixed(2)}%` : undefined}
                                />
                            </div>
                        ))}
                </div>
            </Section>
            <FeedbackPanel
                isPanelOpen={isFeedbackPanelOpen}
                onDismiss={handleDismissFeedbackPanel}
                userName={account?.username}
                currentURL={currentURL}
                screenshotInputURL={currentScreenshotURL}
                FeedbackTab={LogComponent.CosmicLeadershipView}
            />
        </div>
    );
};

export default LeadershipMetricsCard;
