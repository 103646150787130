import { PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum } from "../../../models/PcmV2Models";
import React, { useMemo } from "react";

import { SubstrateCostRanking } from "../common/SubstrateCostRanking";
import { SubstrateCostTable } from "../common/table/SubstrateCostTable";
import { SubstrateTopFiveTrends } from "../common/SubstrateTopFiveTrends";
import { SubstrateUtils } from "../utils/SubstrateUtils";
import commonStyles from "../../common/styles/Common.less";
import { useCategoryFilters } from "../../../hooks/useFilters";

export const SubstrateNetworkByScenarioTag: React.FC = () => {
    const categoryFilter = useCategoryFilters();

    const hasScenarioTag = useMemo(() => {
        const filters = categoryFilter.filters.filters;

        return !!(SubstrateUtils.hasServiceLevelAndAboveFilter(categoryFilter.filters) || filters.GriffinAppV2?.length || filters.ScenarioTagV2?.length);
    }, [categoryFilter.filters]);

    return (
        <div>
            {hasScenarioTag && (
                <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`}>
                    <SubstrateTopFiveTrends scene={PcmV2SceneTypeEnum.Network} subScene={PcmV2SubSceneTypeEnum.NetworkScenarioTagCost} />
                    <SubstrateCostRanking scene={PcmV2SceneTypeEnum.Network} subScene={PcmV2SubSceneTypeEnum.NetworkScenarioTagCost} />
                </div>
            )}
            <SubstrateCostTable scene={PcmV2SceneTypeEnum.Network} subScene={PcmV2SubSceneTypeEnum.NetworkScenarioTagCost} />
        </div>
    );
};
