const TeachingBubbleLocalStorageKey = "tb";

export function getTeachingBubbleLocalStorage() {
    let data: Record<string, number> = {};

    try {
        data = JSON.parse(localStorage.getItem(TeachingBubbleLocalStorageKey) || "") || {};
    } catch (e) {
        //
    }

    return data;
}

export function isTeachingBubblesShowed(name: string): boolean {
    return name in getTeachingBubbleLocalStorage();
}

export function setTeachingBubblesShowed(name: string) {
    const data = getTeachingBubbleLocalStorage();

    data[name] = Date.now();

    localStorage.setItem(TeachingBubbleLocalStorageKey, JSON.stringify(data));
}
