/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as React from "react";

import { ActionButton, Dropdown, FontIcon, IDropdownOption, IDropdownStyles, Link, Stack, Toggle, TooltipHost } from "@fluentui/react";
import { IExpandedTableRow, downloadCsv, getColumns, tableHeaders } from "./SubstrateCostTableColumns";
import { ISubstrateCostByServiceGriffinProcessor, ISubstrateEntity, SubstrateEntityMetrics } from "../../../models/SubstrateModels";
import { Row, TableInstance, UseExpandedOptions, UseSortByInstanceProps, UseSortByOptions, UseTableOptions, useExpanded, useSortBy, useTable } from "react-table";
import { metricDescriptions, metricLinks } from "./PCMMetricsConstants";
import { useBoolean, useConst, useId } from "@fluentui/react-hooks";
import { useCallback, useState } from "react";

import { AnyAction } from "redux";
import LoadingState from "../../ResponseBoundary/LoadingState";
import SelectedList from "../../SelectedList/SelectedList";
import { ServiceTreeItem } from "../../../models/serviceTree";
import styles from "./SubstrateCostTable.less";
import { substrateCostTableColumnNames } from "../../../reducers/substrateReducer";
import { syncChildrenClassName } from "../../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { trackEventCallback } from "../../../utils/AppInsights";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { IAppState } from "../../../store";
import { IRingData } from "../../../reducers/ringReducer";
import { ActionTypes } from "../../../actions/ActionTypes";
import { UserPreferenceUtils } from "../../../utils/preference/UserPreferenceUtils";

interface ISubstrateCostTableProps {
    costByService?: ISubstrateCostByServiceGriffinProcessor;
    costByScenario?: ISubstrateCostByServiceGriffinProcessor;
    columns: SubstrateEntityMetrics[];
    globalSelectedColumns: Set<SubstrateEntityMetrics>;
    serviceTreeIndexMap?: Map<string, ServiceTreeItem>;
    fixedColumn: string;
    makeChangeColumnsAction: (items: Set<SubstrateEntityMetrics>) => AnyAction;
    scenarioNoMap: Map<string, number>;
    appScenarios: Map<string, string>;
    scenarioView: boolean;
    defaultSortingColumn: SubstrateEntityMetrics;
    switchedColumns?: Set<SubstrateEntityMetrics>;
    logComponent: LogComponent;
}

const MAX_ROWS = 500;
const MAPPING = new Map<string, number>();
MAPPING.set("Total Substrate Cost", 1);
MAPPING.set("Total Item Reads, Writes, Queries Cost", 2);
MAPPING.set("Total Assistants Cost", 3);
MAPPING.set("Service Instance Cost", 4);
MAPPING.set("Requests Processed Cost", 5);
MAPPING.set("DSAPI Requests Cost", 6);
MAPPING.set("Item Size Cost", 7);
MAPPING.set("SDS Fast Storage Cost", 8);
MAPPING.set("KvCache Cost", 9);
MAPPING.set("CFM Cost", 10);


const SubstrateCostTable: React.FC<ISubstrateCostTableProps> = (props) => {
    const ring = useSelector<IAppState, IRingData>(state => state.ring);
    const services = React.useMemo(() => parseSubstrateCostByServiceGriffinAppProcessor(MAPPING.get(props.defaultSortingColumn)!, props.costByService, props.serviceTreeIndexMap, 1, props.scenarioNoMap, props.appScenarios), [props.costByService, props.serviceTreeIndexMap, props.scenarioNoMap, props.appScenarios, props.defaultSortingColumn]);
    const scenario = React.useMemo(() => parseSubsrateScenarioCost(props.costByScenario, props.serviceTreeIndexMap, 1), [props.costByScenario, props.serviceTreeIndexMap]);
    const [showScenario, setShowScenario] = useState(false);
    const data = (props.scenarioView && showScenario) ? scenario : services;
    const [realUseColumns, setRealUseColumns] = useState(props.columns);

    const columns = React.useMemo(() => getColumns(data, props.logComponent), [data, props.logComponent]);
    const columnOptionsId = useId("service-total-cost-column-options");
    const [columnOptionsSetting, {setFalse: dismissColumnOptionsSetting, toggle: toggleColumnOptionsSetting}] = useBoolean(false);
    const dispatch = useDispatch();
    const hiddenColumns = React.useMemo(() => (Array.from(substrateCostTableColumnNames.filter(
        name => !props.globalSelectedColumns.has(name)))),
         [props.globalSelectedColumns]);
    const [readyToShow, {setTrue: setReadyToShowTrue, setFalse: setReadyToShowFalse}] = useBoolean(false);

    const cachedRows = useConst(new Map<string, {element: JSX.Element, expanded?: boolean}>());
    const cacheTimeout = React.useRef<NodeJS.Timeout>();

    const getRowId = React.useCallback((row: IExpandedTableRow, relativeIndex: number, parent?: Row<IExpandedTableRow>) => {
        if (parent) {
            return parent.id + '/' + row["uniqueId"];
        } else {
            return row["uniqueId"];
        }
    }, []);

    const handleToggleClick = useCallback((event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        setShowScenario(!!checked);
        setReadyToShowFalse();
        setRealUseColumns(checked ? Array.from(props.switchedColumns!.values()) : props.columns)
        checked ? dispatch(props.makeChangeColumnsAction(props.switchedColumns!)) : dispatch(props.makeChangeColumnsAction(new Set(props.columns)));
        trackEventCallback(props.logComponent, LogElement.ScenarioTag, "Scenario Tag View", LogTarget.Toggle);
    }, [dispatch, props, setReadyToShowFalse]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        setHiddenColumns,
        prepareRow,
        setSortBy
    } = useTable<IExpandedTableRow>(
        {
            columns: columns,
            data: data,
            initialState: {
                hiddenColumns,
                sortBy: useConst([{
                    id: props.defaultSortingColumn,
                    desc: true
                }])
            },
            getRowId
        } as UseTableOptions<IExpandedTableRow> & UseSortByOptions<IExpandedTableRow> & UseExpandedOptions<IExpandedTableRow>,
        useSortBy,
        useExpanded
    ) as TableInstance<IExpandedTableRow> & UseSortByInstanceProps<IExpandedTableRow>;

    function renderRow(row: Row<IExpandedTableRow>, prepareRow: (row: Row<IExpandedTableRow>) => void): JSX.Element {
        prepareRow(row);

        return (
            // eslint-disable-next-line react/jsx-key
            <tr {...row.getRowProps({
                className: styles.tableLine
            })}>
                {
                    row.cells.map(cell => {
                        // eslint-disable-next-line react/jsx-key
                        return <td
                            align={getAlign(cell.column.id)}
                            {...cell.getCellProps()}>
                                <div className={cell.column.id === "Service" ? styles.serviceCell : styles.cell} title={typeof(cell.value) === "string" ? cell.value : undefined}>
                                    {cell.render("Cell")}
                                </div>
                            </td>
                    })
                }
            </tr>
        );
    }

    function shouldHovered(id: string): boolean {
        if (SubstrateEntityMetrics.PmOwner === id || SubstrateEntityMetrics.DevOwner === id || SubstrateEntityMetrics.MaxItemSize === id
            || SubstrateEntityMetrics.KvCacheSize === id || SubstrateEntityMetrics.MaxLLCSizeGb === id) {
            return false;
        }
        return true;
    }

    function preCalculateCache(rows: Row<IExpandedTableRow>[], startIndex: number, prepareRow: (row: Row<IExpandedTableRow>) => void, count = 20) {
        if (startIndex < rows.length) {
            for (let i = startIndex; i < rows.length; i ++) {
                cachedRows.set(rows[i].id, {element: renderRow(rows[i], prepareRow), expanded: rows[i].isExpanded});
            }

            cacheTimeout.current = setTimeout(() => preCalculateCache(rows, startIndex + count, prepareRow, count), 100);
        } else {
            cacheTimeout.current = undefined;
            setReadyToShowTrue();
        }
    }

    React.useEffect(() => {
        if (cacheTimeout.current) {
            clearTimeout(cacheTimeout.current);
            cacheTimeout.current = undefined;
        }
        setReadyToShowFalse();

        const newHiddenColumns = substrateCostTableColumnNames.filter(name => !props.globalSelectedColumns.has(name));
        setHiddenColumns(newHiddenColumns);

        if (props.logComponent === LogComponent.SubstrateChartingPane) {
            UserPreferenceUtils.setUserPreferenceData("substrateV1OverviewTable", { hiddenColumns: newHiddenColumns });
        }

        cachedRows.clear();
        preCalculateCache(rows, 0, prepareRow);

        return function() {
            if (cacheTimeout.current) {
                clearTimeout(cacheTimeout.current);
                cacheTimeout.current = undefined;
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.serviceTreeIndexMap, props.globalSelectedColumns, data, prepareRow, setHiddenColumns, showScenario]);

    React.useEffect(() => {
        setSortBy([{
            id: props.defaultSortingColumn,
            desc: true
        }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.defaultSortingColumn, props.costByService, props.costByScenario, showScenario]);

    const downloadClick = React.useCallback(() => {
        downloadCsv(data, tableHeaders, "SubstrateCostByService");
        trackEventCallback(props.logComponent, LogElement.Download, "Download", LogTarget.Button);
    }, [data, props.logComponent]);

    const toggleColumn = React.useCallback(() => {
        toggleColumnOptionsSetting();
        trackEventCallback(props.logComponent, LogElement.Columns, "Columns", LogTarget.Button);
    }, [props.logComponent, toggleColumnOptionsSetting]);

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 100, margin: 10 },
    };
      
    const options: IDropdownOption[] = [
    { key: 'All', text: 'All Rings'},
    { key: 'MSIT', text: 'MSIT' },
    { key: 'SDFV2', text: 'SDFV2' },
    { key: 'SIP', text: 'SIP'},
    { key: 'WW', text: 'WW' },
    ];

    const onRingChange = React.useCallback((event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined) => {
        dispatch( {
            type: ActionTypes.RingChangeAction,
            ring: option!.key || "All"
        } )
    }, [ring]);

    return (
        !readyToShow ?
        <LoadingState /> :
        <>
        <div className={syncChildrenClassName}>
            <div className={styles.separator} />
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                <h4 className={styles.serviceCostHeader}>Substrate Cost by Service</h4>
                {
                    props.scenarioView ?
                        <Toggle label="Scenario Tag View" inlineLabel onChange={handleToggleClick} checked={showScenario} styles={{root: styles.toggleRoot, label: styles.toggleLabel}}/> 
                        : null
                }
                {
                    showScenario ?
                    <Dropdown placeholder="All Rings" options={options} styles={dropdownStyles} onChange={onRingChange}
                        selectedKey={ring.ring || "All"}/> : null                 
                }
                <ActionButton iconProps={{iconName: "Download"}} text="Download" onClick={downloadClick} />
                <ActionButton iconProps={{iconName: "ColumnOptions"}} text="Columns" id={columnOptionsId} onClick={toggleColumn}/>
            </Stack>
        </div>
        <div className={styles.tableWrapper}>
            <table {...getTableProps({
                className: styles.table
            })}>
                <thead>
                    {
                        headerGroups.map(headerGroup => (
                            // eslint-disable-next-line react/jsx-key
                            <tr {...headerGroup.getHeaderGroupProps({
                                className: styles.tableHeader
                            })}>
                                {
                                    headerGroup.headers.map(column => (
                                        // eslint-disable-next-line react/jsx-key
                                        <th align={getAlign(column.id)} {...column.getHeaderProps({
                                            className: column.id === SubstrateEntityMetrics.Name ? styles.serviceColumn : styles.column,
                                            }
                                        )}>
                                        <span
                                            {...column.getSortByToggleProps()}
                                            onClick={() => {
                                                column.toggleSortBy(!column.isSortedDesc, false);
                                                trackEventCallback(props.logComponent, LogElement.Sort, column.id, LogTarget.Button);
                                            }}
                                        >
                                      {column.id === SubstrateEntityMetrics.Name && (props.scenarioView && showScenario) ? column.render("Header") + " - Scenario Tag" : column.render("Header")}
                                            {
                                                shouldHovered(column.id) ? 
                                                <TooltipHost
                                                    content={
                                                        <>
                                                        <span className={styles.tooltipHeader}></span>
                                                        {getColumnTooltip(column.id)}
                                                        </>
                                                    }
                                                    calloutProps={{
                                                        isBeakVisible: false,
                                                        calloutWidth: 344
                                                    }}
                                                >
                                                <FontIcon className={styles.infoIcon} iconName="Info" />
                                                </TooltipHost> : null
                                            }
                                            {
                                                column.isSorted &&
                                                <FontIcon className={styles.sortIcon} iconName={column.isSortedDesc ? "SortDown" : "SortUp"} />
                                            }
                                        </span>
                                        </th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        rows.slice(0, MAX_ROWS).map((row) => {
                            const cachedRow = cachedRows.get(row.id);
                            if (cachedRow && row.isExpanded === cachedRow.expanded) {
                                return cachedRow.element;
                            }

                            const result = renderRow(row, prepareRow);

                            cachedRows.set(row.id, {element: result, expanded: row.isExpanded});

                            return result;
                        })
                    }
                </tbody>
                <tfoot>
                    {
                        footerGroups.map(group => (
                            // eslint-disable-next-line react/jsx-key
                            <tr {...group.getFooterGroupProps({
                                className: styles.tableFooter
                            })}>
                                {group.headers.map(column => (
                                    // eslint-disable-next-line react/jsx-key
                                    <td align={getAlign(column.id)} {...column.getFooterProps({
                                        className: column.id === "Dev Owner" || column.id === "PM Owner" ? styles.owner : undefined
                                    })}>{column.render("Footer")}</td>
                                ))}
                            </tr>
                        ))
                    }
                </tfoot>
            </table>
        </div>
        <SelectedList
            items={realUseColumns}
            globalSelectedItems={props.globalSelectedColumns}
            onDismiss={dismissColumnOptionsSetting}
            onChange={(items) => {dispatch(props.makeChangeColumnsAction(items)); dismissColumnOptionsSetting();}}
            fixedItem={props.fixedColumn}
            title="Customize Columns"
            description={`Select the columns to display in the list view. To change the ordering, use drag -and-drop or the ‘up’ and ‘down’ buttons next to each column`}
            linkText="Select all columns"
            isPanelOpen={columnOptionsSetting}
        />
        </>
    )
}

export default SubstrateCostTable;

function generateUniqueId(service: ISubstrateEntity, level: number) {
    if (level == 2 && service.griffinProcessor != undefined && service.griffinProcessor != null) {
        return service.name + service.griffinProcessor;
    }
    return service.name || "";
}

function parseSubstrateCostByServiceGriffinAppProcessor(
     tab: number,
     services?: ISubstrateCostByServiceGriffinProcessor,
     indexMap?: Map<string, ServiceTreeItem>, level = 1, scenarioNoMap?: Map<string, number>,
     appScenario?: Map<string, string>,
): IExpandedTableRow[] {
    return (services || []).map((service: ISubstrateEntity) => ({
        ...service,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        tags: getTags(service, scenarioNoMap!, level, indexMap),
        name: (level > 1 ? service.name : indexMap?.get(service.name || "")?.n) || service.name || "Other",
        uniqueId: generateUniqueId(service, level),
        subRows: service.children ? parseSubstrateCostByServiceGriffinAppProcessor(tab, service.children, indexMap, level + 1, scenarioNoMap, appScenario) : undefined
    })).filter(item => {
        switch (tab) {
            case 1:
                return item.totalCost > 1;
            case 2:
                return item.totalreadwritesqueriescost > 1;
            case 3:
                return item.totalAssistantsCost > 1;
            case 4:
                return item.serviceInstanceCost > 1;
            case 5:
                return item.requestProcessedCost > 1;
            case 6:
                return item.dsApiRequestsCost > 1;
            case 7:
                return item.itemSizeCost > 1;
            case 8:
                return item.sdsFastStorageCost > 1;
            case 9:
                return item.kvCacheCost > 1;
            case 10:
                return item.cfmSubmittedCost > 1;
        }
    });
}

function parseSubsrateScenarioCost(services?: ISubstrateCostByServiceGriffinProcessor,
    indexMap?: Map<string, ServiceTreeItem>, level = 1): IExpandedTableRow[] {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return (services || []).map((service: ISubstrateEntity) => ({
            ...service,
            name: (level > 1 ? service.scenarioTag : indexMap?.get(service.name || "")?.n) || service.name || "Other",
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            griffinapp: getApps(service),
            uniqueId: (level > 1 ? service.scenarioTag : service.name) || "",
            subRows: service.children ? parseSubsrateScenarioCost(service.children, indexMap, level + 1).filter(item => item.totalreadwritesqueriescost! > 0.01) : undefined
        })).filter(item => item.totalreadwritesqueriescost > 1);
}

function getColumnTooltip(columnId : string) {
    const contentMap = metricDescriptions;
    const linkMap = metricLinks;
    if (contentMap[columnId] != undefined) {
        return (
            <div>
                <p>
                  {contentMap[columnId]}
                </p>
                {
                    linkMap[columnId] &&
                    <Link href={linkMap[columnId]}>Learn More</Link>
                }
            </div>
        )
    }
    else {
        return (
            <p>
                {columnId}
            </p>
        )
    }
}

function getAlign(name: string): "left" | "center" | "right" {
    switch (name) {
        case SubstrateEntityMetrics.Name:
        case SubstrateEntityMetrics.DevOwner:
        case SubstrateEntityMetrics.PmOwner:
        case SubstrateEntityMetrics.ScenarioTag:
            return "left";
        case SubstrateEntityMetrics.GriffinApp:
            return "left";
        default:
            return "right";
    }
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
function getTags(service: ISubstrateEntity, scenarioNoMap: Map<string, number>,
     level: number, indexMap?: Map<string, ServiceTreeItem>) {
    const serviceName = level > 1 ? service.name : indexMap?.get(service.name || "")?.n || "Other";
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (serviceName == "Other") {
        return "-";
    } 
    if(service.scenarioTagCount > 0) {
        return service.scenarioTagCount + " Tags";
    }
    return "0 Tag";
}

function getApps(service: ISubstrateEntity) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return service.griffinApp;
}