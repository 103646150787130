/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Component,
    ComponentTypeEnum,
    CopsResourceTypeEnum,
    ConsumptionDetail,
    EfficiencyTrackerReductionPlan,
    IEfficiencyTrackerProject,
    PlatformTypeEnum,
    AzrueRegionConsumption
} from "../models/EfficiencyTracker";

import { getJson, postJson } from "../utils/apiServiceBase";

import { QueryableClass } from "../hooks/useCustomQuery";
import { IServiceIdName, ISubscription } from "../models/serviceTree";
import { EFTrackerBigBetsDiscussion, EFTrackerBigBetsLatestByProject, EFTrackerBigBetsProject, EFTrackerBigBetsProjectHistory, EFTrackerBigBetsReductionResult } from "../models/EfficiencyTrackerBigBets";
import { SearchproviderV1BaseUrl } from "./serviceTreeService";

export const EfficiencyTrackerBaseUrl = 'api/v2.0/efficiencytracker';

@QueryableClass({ cacheTime: 1800000, retry: 3 })
export class EfficiencyTrackerService {
    static async getAzureRegions(): Promise<string[]> {
        const url = `${SearchproviderV1BaseUrl}/regions`;
        return await getJson(url);
    }

    static async getSubscriptionIDs(): Promise<ISubscription[]> {
        return await getJson<ISubscription[]>(`${SearchproviderV1BaseUrl}/subscriptions`);
    }

    static async getServiceIDs(): Promise<IServiceIdName[]> {
        return await getJson<IServiceIdName[]>(`${SearchproviderV1BaseUrl}/services`);
    }

    static async getAzureConsumption(
        targetDate: Date,
        resourceType: CopsResourceTypeEnum,
        platform: PlatformTypeEnum,
        body: Component[]
    ): Promise<AzrueRegionConsumption[]> {
        const url = `${EfficiencyTrackerBaseUrl}/consumption?targetDate=${targetDate.toISOString().split("T")[0]}&resourceType=${resourceType}&platform=${platform}`;
        return await postJson<AzrueRegionConsumption[]>(url, body);
    }

    static async getComponents(componentType: ComponentTypeEnum): Promise<string[]> {
        const url = `${EfficiencyTrackerBaseUrl}/components?componentType=${componentType}`;
        return await getJson(url);
    }

    static async getTrendByPlanId(planId: number): Promise<ConsumptionDetail> {
        const url = `${EfficiencyTrackerBaseUrl}/consumptionDetails?planId=${planId}`;
        return await getJson<ConsumptionDetail>(url);
    }

    static async getConsumption(targetDate: Date, resourceType: CopsResourceTypeEnum, body: Component[]): Promise<number> {
        const url = `${EfficiencyTrackerBaseUrl}/consumption?targetDate=${targetDate.toISOString().split('T')[0]}&resourceType=${resourceType}`;
        return await postJson<number>(url, body);
    }

    static async getAllProjects(): Promise<IEfficiencyTrackerProject[]> {
        const url = `${EfficiencyTrackerBaseUrl}/projects`;
        const response: any = await getJson(url);
        return response.map((project: any) => ({
            EfficiencyTrackerProject: {
                Id: project.efficiencyTrackerProject.id,
                Name: project.efficiencyTrackerProject.name,
                Owner: project.efficiencyTrackerProject.owner,
                CreateTime: new Date(project.efficiencyTrackerProject.createTime + "Z"), //covertDate from UTC to local
                Description: project.efficiencyTrackerProject.description,
            },
            EfficiencyTrackerReductionPlans: project.efficiencyTrackerReductionPlans.map((plan: any) => parseReductionPlan(plan))
        }));
    }

    static async getPlanStatus(planId: number): Promise<boolean> {
        const url = `${EfficiencyTrackerBaseUrl}/getplanstatus?planId=${planId}`;
        return await getJson<boolean>(url);
    }

    static async getAllBigBetsProjects(): Promise<EFTrackerBigBetsProject[]> {
        const url = `${EfficiencyTrackerBaseUrl}/bigbets/projects`;
        const response: any = await getJson(url);
        return response.map((project: any) => ({
            ProjectID: project.projectID,
            Name: project.name,
            Owner: project.owner,
            CreateTime: new Date(project.createTime + "Z"), //covertDate from UTC to local
            LastModifyTime: new Date(project.lastModifyTime + "Z"), //covertDate from UTC to local
            Category: project.category,
            Workload: project.workload,
            DRI: project.dri,
            ImpactDollar: project.impactDollar,
            ConfidenceInImpact: project.confidenceInImpact,
            ExecutionStatus: project.executionStatus,
            DeliveryETA: new Date(project.deliveryETA + "Z"), //covertDate from UTC to local
            Notes: project.notes,
            LinksToExistingTicketItem: project.linksToExistingTicketItem,
            Metrics: project.metrics,
        }));
    }

    static async getBigBetsReductionResult(projectId: number): Promise<EFTrackerBigBetsReductionResult[]> {
        const url = `${EfficiencyTrackerBaseUrl}/bigbets/reductionresult?projectID=${projectId}`;
        const response: any = await getJson(url);
        return response.map((project: any) => ({
            Id: project.id,
            ProjectID: project.projectID,
            CreateTime: new Date(project.createTime + "Z"), //covertDate from UTC to local
            DateValuePairs: JSON.parse(project.dateValuePairs).map((pair: any)=> ({
                PairDate: new Date(pair.PairDate),
                Target: pair.Target !== null ? parseFloat(pair.Target) : null,
                Actual: pair.Actual !== null ? parseFloat(pair.Actual) : null,
            })),
        }));
    }

    static async getBigBetsLatestByProjectID(projectIds: number[]): Promise<EFTrackerBigBetsLatestByProject[]> {
        const url = `${EfficiencyTrackerBaseUrl}/bigbets/latestbyproject`;
        if (projectIds.length) {
            const response: any = await postJson<number>(url, projectIds);
            return response.map((project: any) => ({
                ProjectID: project.projectID,
                LatestActual: project.latestActual,
                LastTarget: project.lastTarget,
                LastConfidenceInImpact: project.lastConfidenceInImpact,
                LastExecutionStatus: project.lastExecutionStatus,
                Discussion: project.latestNote === null ? null :
                    [project.latestNote].map((item: any) => ({
                    Id: item.id,
                    ProjectID: item.projectID,
                    CreateTime: new Date(item.createTime + "Z"), //covertDate from UTC to local;
                    Owner: item.owner,
                    Note: item.note,
                }))[0],
            }));
        }
        else {
            return [];
        }
    }

    static async getBigBetsDiscussionByProjectId(projectId: number): Promise<EFTrackerBigBetsDiscussion[]> {
        const url = `${EfficiencyTrackerBaseUrl}/bigbets/notes?projectID=${projectId}`;
        const response: any = await getJson(url);
        return response.map((discussion: any) => ({
            Id: discussion.id,
            ProjectID: discussion.projectID,
            CreateTime: new Date(discussion.createTime + "Z"), //covertDate from UTC to local
            Owner: discussion.owner,
            Note: discussion.note,
        }));
    }

    static async getBigBetsProjectHistory(projectId: number): Promise<EFTrackerBigBetsProjectHistory[]> {
        const url = `${EfficiencyTrackerBaseUrl}/bigbets/projecthistory?projectID=${projectId}`;
        const response: any = await getJson(url);
        return response.map((project: any) => ({
            Id: project.id,
            ProjectID: project.projectID,
            Name: project.name,
            Owner: project.owner,
            CreateTime: new Date(project.createTime + "Z"), //covertDate from UTC to local
            LastModifyTime: new Date(project.lastModifyTime + "Z"), //covertDate from UTC to local
            Category: project.category,
            Workload: project.workload,
            DRI: project.dri,
            ImpactDollar: project.impactDollar,
            ConfidenceInImpact: project.confidenceInImpact,
            ExecutionStatus: project.executionStatus,
            DeliveryETA: new Date(project.deliveryETA + "Z"), //covertDate from UTC to local
            Notes: project.notes,
            LinksToExistingTicketItem: project.linksToExistingTicketItem,
            Metrics: project.metrics,
        }));
    }

    static async getBigBetsShareListByProjectId(projectId: number): Promise<string[]> {
        if (projectId == 0) {
            return [];
        }
        const url = `${EfficiencyTrackerBaseUrl}/bigbets/shareList?projectId=${projectId}`;
        const response: any = await postJson(url, null);
        return response;
    }
}

function parseReductionPlan(response: any): EfficiencyTrackerReductionPlan {
    return {
        Id: response.id,
        ProjectId: response.projectId,
        Workload: response.workload,
        Target: response.target,
        EfficiencyTrackerReductionPlanRegionConfigs: JSON.parse(response.efficiencyTrackerReductionPlanRegionConfigs).map((config: any) => ({
            BaseLineTime: new Date(config.BaseLineTime),
            BaseLineValue: config.BaseLineValue,
            Region: config.Region,
            AzureRegion: config.AzureRegion,
            Targets: JSON.parse(config.Targets).map((target: any) => ({
                Month: new Date(target.Month),
                Value: parseFloat(target.Value)
            })),
            EffectivePeriod: new Date(config.EffectivePeriod),
        })),
        Components: JSON.parse(response.components).map((component: any) => ({
            Type: component.Type,
            Value: component.Value
        })),
        Status: response.status,
        ConfidenceLevel: response.confidenceLevel,
        QualityForMap: response.qualityForMap,
        Platform: response.platform,
        Owner: response.owner,
        CreateTime: new Date(response.createTime + "Z"), //covertDate from UTC to local
        ResourceType: response.resourceType,
        EfficiencyTargetCatagory: response.efficiencyTargetCatagory,
        TrackingGap: response.trackingGap,
    };
}