import { Cell, Column, HeaderGroup, TableHeaderProps, TableInstance, TableOptions, TableState, UseSortByOptions, UseSortByState, useSortBy, useTable } from "react-table";
import { IStickyTableCellOption, IStickyTableFooterOption, IStickyTableHeaderOption } from "../../common/table/StickyTable";
import { useCallback, useMemo } from "react";
import React from "react";
import { currencyFormatter, formatResourceUsage } from "../../../utils/currency";
import styles from './AnomalyPivotTableUtils.less';
import { IInsightUsageBO } from "../../../models/InsightsPlatformModels";
import { InsightPlatformDescriptions } from "../../../models/constants/InsightPlatformConstants";

export const AnomalyPivotTableDefaultPageSize = 50;

export const AnomalyPivotTableNumericalColumns = new Set<string>(["resourceUsage", "costSaving"]);

export const AnomalyPivotTableDisplayNames: Record<string, string> = {
    ResourceUri: 'Name',
    ServiceName: 'Service Name',
    PlatformType: 'Platform',
    InsightName: 'Anomaly',
    InsightStatus: 'Status',
    ResourceType: 'Resource Type',
    ResourceUsage: 'Monthly Resource Increase',
    CostSaving: 'Monthly Cost Increase'
}

export const getHeaderOption = (header: HeaderGroup): IStickyTableHeaderOption => {
    const cellProps: TableHeaderProps = { className: '', key: header.id };

    if (AnomalyPivotTableNumericalColumns.has(header.id)) {
        cellProps.className += ' ' + styles.textAlignRight;
    }
    else {
        cellProps.className += ' ' + styles.textAlignLeft;
    }

    return { props: cellProps, tooltip: InsightPlatformDescriptions[header.id as keyof IInsightUsageBO] };
};

export const getFooterOption = (footer: HeaderGroup): IStickyTableFooterOption => {
    return {
        props: {
            key: footer.id,
            className: AnomalyPivotTableNumericalColumns.has(footer.id) ? styles.textAlignRight : '',
        },
    };
};

export const getCellOption = (cell: Cell): IStickyTableCellOption => {
    const cellProps: React.HTMLProps<HTMLTableCellElement> = { className: '', key: cell.column.id, style: {} };

    if (AnomalyPivotTableNumericalColumns.has(cell.column.id)) {
        cellProps.className += ' ' + styles.textAlignRight;
    } else {
        cellProps.className += ' ' + styles.textAlignLeft;
    }

    return { props: cellProps };
};

export const useInsightAnomalyPivotTable = (
    columnKeys: (keyof IInsightUsageBO)[],
    detailData:  IInsightUsageBO[],
    totalCost: number,
    showInsightCards: (id: string) => void,
) => {
    const renderCell = (cell: Cell) => {
        const content = cell.row.original as IInsightUsageBO;
        const insightId = content.insightId.toString();
        if (cell.column.id.includes('ResourceUri')) {
            return (
                <div id="name" className={styles.nameColumn} onClick={function (): void {
                    showInsightCards(insightId);
                } }>
                    <span id="onhover" title={cell.value}>{cell.value}</span>   
                </div>                
            )
        }
        else if (cell.column.id.includes('CostSaving')) {
            return (
                <div className={`${styles.insightMetricCellContent} ${styles.textAlignRight}`} onClick={function (): void {
                    showInsightCards(insightId);
                } }>
                    {currencyFormatter(cell.value, 2)}
                </div>
            )
        }
        else if (cell.column.id.includes('ResourceUsage')) {
            return (
                <div className={`${styles.insightMetricCellContent} ${styles.textAlignRight}`} onClick={function (): void {
                    showInsightCards(insightId);
                } }>
                    {formatResourceUsage(cell.value)}
                </div>
            )
        }

        return (
            <div className={styles.insightMetricCellContent} onClick={function (): void {
                showInsightCards(insightId);
            } }>
                {cell.value || '-'}
            </div>
        )
    };

    const columns: Column<IInsightUsageBO>[] = useMemo(() => {
        return columnKeys.map((id, index) => {
            let footer: string;
            let footerClassName = '';

            if ((id as string).includes('costSaving')) {
                footer = currencyFormatter(totalCost || 0, 2);
                footerClassName = styles.textAlignRight;
            }
            else {
                footer = index == 0 ? 'Total' : '';
            }

            return {
                id: id.charAt(0).toUpperCase() + id.slice(1),
                accessor: id,
                Header: AnomalyPivotTableDisplayNames[id.charAt(0).toUpperCase() + id.slice(1)] || id.charAt(0).toUpperCase() + id.slice(1),
                Footer: () => <div className={footerClassName}>{footer}</div>,
                Cell: renderCell,
                sortDescFirst: true,
                disableSortBy: false,
            };
        });
    }, [columnKeys, totalCost]);

    const getRowId: (data: IInsightUsageBO) => string = useCallback((data: IInsightUsageBO) => {
        return data.resourceUri + '_' + data.serviceName + '_' + data.platformType + '_' + data.insightName + '_' + data.insightStatus + '_' + data.resourceUsage + '_' + data.costSaving;
    }, []);

    const tableInstance = useTable<IInsightUsageBO>(
        {
            columns,
            data: detailData || [],
            totalCost: totalCost,
            manualSortBy: false,
            autoResetHiddenColumns: false,
            getRowId,
        } as TableOptions<IInsightUsageBO> & UseSortByOptions<IInsightUsageBO>,
        useSortBy
    ) as TableInstance<IInsightUsageBO> & { state: TableState<IInsightUsageBO> & UseSortByState<IInsightUsageBO> };

    return tableInstance;
};
