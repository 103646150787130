import { ISingleDayMetric } from "./Metric";
import moment from "moment";

export enum CosmicSourceTypeEnum {
    PartnerView,
    LeadershipView,
    All,
}

export const CosmicSourceTypeName: Record<CosmicSourceTypeEnum, string> = {
    [CosmicSourceTypeEnum.All]: "Cosmic",
    [CosmicSourceTypeEnum.LeadershipView]: "LeadershipView",
    [CosmicSourceTypeEnum.PartnerView]: "PartnerView",
};

export const CosmicPartnerServiceDetailsColumnNames: (keyof ICosmicServiceDetails)[] = [ "date", "workload", "teamGroupName", "serviceGroupName", "serviceName", "serviceId", "namespace", "region", "ring", "nodeOS", "containerCount", "cpuUsageCores", "cpuRequestCores", "cpuLimitCores", "cpuUtilizationP99", "memoryWorkingSetMB", "memoryRequestMB", "memoryLimitMB", "memoryUtilizationP99", "rightsizing", ];

export interface ICosmicDailyMetricsByWorkload {
    extractionDate: moment.Moment;
    workload: string;
    cpuUsage: number;
    cpuRequestCores: number;
}

export interface ICosmicDailyMetrics {
    extractionDate: moment.Moment;
    cost: number;
}

// PartnerView hierarchical: metric -> workload -> data
export interface ICosmicHierarchicalMetricReport {
    metric: string;
    data: ICosmicHierarchicalWorkloadReport[];
    total?: number;
}

export interface ICosmicHierarchicalWorkloadReport {
    workload: string;
    data: ISingleDayMetric[];
}

// LeadershipView: metric -> data
export interface ICosmicDailyMetricReport {
    metric: string;
    data: ISingleDayMetric[];
    total?: number;
}

export interface ICosmicServiceDimension {
    DimensionMetric: string;
}

// Cosmic service details in PartnerView
export interface ICosmicServiceDetails {
    date: moment.Moment;
    workload: string;
    teamGroupName: string;
    serviceGroupName: string;
    serviceName: string;
    serviceId: string;
    namespace: string;
    region: string;
    ring: string;
    nodeOS: string;
    containerCount: number;
    cpuUsageCores: number;
    cpuRequestCores: number;
    cpuLimitCores: number;
    cpuUtilizationP99: number;
    memoryWorkingSetMB: number;
    memoryRequestMB: number;
    memoryLimitMB: number;
    memoryUtilizationP99: number;
    rightsizing: number;
}

export interface ICosmicServiceDetailReport<D extends ICosmicServiceDetails = ICosmicServiceDetails> {
    total: Partial<D>;
    max: Partial<D>;
    min: Partial<D>;
    data: D[];
    rowCount: number,
}
