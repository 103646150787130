import { Pages, SubPages } from "../../../models/Nav";
import { Pivot, PivotItem } from "@fluentui/react";

import { EmpytCostView } from "../common/EmptyCostView";
import PageHeader from "../../common/PageHeader";
import { PcmV2SceneTypeEnum } from "../../../models/PcmV2Models";
import React from "react";
import { SubstrateSsdMcdbDataset } from "./SubstrateSsdMcdbDataset";
import { SubstrateSsdMcdbMailboxCategory } from "./SubstrateSsdMcdbMailboxCategory";
import { SubstrateSsdOverview } from "./SubstrateSsdOverview";
import { SubstrateV2WikiLink } from "../../../models/constants/PcmV2Constants";
import { WarningBanner } from "../../banner/WarningBanner";
import styles from "../Substrate.less";
import { useGetBannersByTabAndDateQuery } from "../../../hooks/useBannerQuery";
import { useGetPcmV2Cost } from "../../../hooks/useSubstrateV2Query";
import { useGotoTab } from "../../../hooks/useGotoPage";
import { useParams } from "react-router-dom";
import { trackEventCallback } from "../../../utils/AppInsights";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";

export const SubstrateSsd: React.FC = () => {
    const { data: pcmV2Cost } = useGetPcmV2Cost();
    const { data: banners } = useGetBannersByTabAndDateQuery(PcmV2SceneTypeEnum.SSD);
    const curTab = useParams().tab || "Overview";

    const gotoTab = useGotoTab();
    const handleLinkClick = (item?: PivotItem) => {
        if (item?.props.itemKey) {
            gotoTab(`${Pages.SubstrateV2}/${SubPages.Ssd}`, item?.props.itemKey, `${Pages.SubstrateV2}/${SubPages.Ssd}`);
            let logElement: LogElement;
            switch (item.props.itemKey) {
                case "Overview":
                    logElement = LogElement.TotalStorageSSD;
                    break;
                case "Dataset":
                    logElement = LogElement.StorageDatasetSSD;
                    break;
                default:
                    logElement = LogElement.StorageByMailboxCategorySSD;
            }
            trackEventCallback(LogComponent.SSDChartingPane, logElement, item.props.itemKey, LogTarget.Tab);
        }
    };
    return (
        <div className={styles.pageWrapper}>
            <PageHeader
                className={styles.pageHeader}
                title="SSD-Storage Cost View"
                description="We use this cost category to attribute the cost associated with the at-rest storage of bytes on SSD storage media (instead of HDD media), toward the goal of delivering low latency."
                link={SubstrateV2WikiLink}
                linkText="Learn More"
                tag="PREVIEW"
                linkLogComponent={LogComponent.SSDChartingPane}
                linkLogElement={LogElement.LearnMoreSSD}
            />
            {!!banners?.length && (
                <div className={styles.bannerWrapper}>
                    {banners.map((item) => (
                        <WarningBanner key={item.id} bannerItem={item} />
                    ))}
                </div>
            )}
            {pcmV2Cost && pcmV2Cost.ssdCost === 0 ? (
                <EmpytCostView />
            ) : (
                <Pivot selectedKey={curTab} onLinkClick={handleLinkClick} focusZoneProps={{ className: styles.pivotList }} overflowBehavior="menu">
                    <PivotItem headerText="Total SSD-Storage Cost" itemKey="Overview">
                        <SubstrateSsdOverview />
                    </PivotItem>
                    <PivotItem headerText="SSD-Storage (MCDB) by Dataset Name" itemKey="Dataset">
                        <SubstrateSsdMcdbDataset />
                    </PivotItem>
                    <PivotItem headerText="SSD-Storage (MCDB) by Mailbox Category" itemKey="MailboxCategory">
                        <SubstrateSsdMcdbMailboxCategory />
                    </PivotItem>
                </Pivot>
            )}
        </div>
    );
};
