import * as React from "react";
import styles from "./ChartContainer.less";
import { Separator } from "@fluentui/react";

interface IChartContainerProps {
    title: string;
}

const ChartContainer: React.FC<IChartContainerProps> = (props) => {
    return (
        <div className={styles.outline}>
            <Separator styles={{root: styles.separator}} />
            <h4 className={styles.title}>{props.title}</h4>
            {props.children}
        </div>
    );
}

export default ChartContainer;