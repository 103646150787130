import { DirectionalHint, ITooltipHostStyles, ITooltipProps, Icon, Stack, TooltipDelay, TooltipHost } from "@fluentui/react";
import { getColor, grayBarColor, grayTextColor } from "./CostCardUtils";

import CostCardTooltipContent from "./CostCardTooltipContent";
import { Moment } from "moment";
import React from "react";
import { currencyFormatter } from "../../../utils/currency";
import styles from "./CostCard.less";

export interface CostCardProps {
    title: string;
    cost: number;
    color: string | number;
    noPrefix?: boolean;
    suffix?: string;
    formatter?: (value: number) => string;
    errors?: Array<{
        title: string;
        message: string;
    }>;
    date?: Moment[];
    onShowMoreError?: () => void;
    onClick?: () => void;
    className?: string;
    classNames?: {
        costValue?: string;
    };
    showTooltip?: boolean;
}

const hostStyles: Partial<ITooltipHostStyles> = { root: { display: "inline-block" } };

const CostCard: React.FC<CostCardProps> = (props) => {
    const { title, cost, color, noPrefix, suffix, formatter, errors } = props;
    const stickColor = { backgroundColor: cost === 0 ? grayBarColor : getColor(color) };
    const textColor = { color: cost === 0 ? grayTextColor : undefined };
    const tooltipProps: ITooltipProps = {
        onRenderContent: () => (
            <ul style={{ margin: 10, padding: 0 }}>
                <h1>No {title} Cost Yet</h1>
                <p>The selected Services, Griffinapps, Processes, Processor, Vdirs have no {title} costs in the selected time period.</p>
            </ul>
        ),
    };

    if (cost === 0) {
        return (
            <TooltipHost
                tooltipProps={tooltipProps}
                delay={TooltipDelay.zero}
                id="tooltipId"
                directionalHint={DirectionalHint.bottomCenter}
                styles={hostStyles}
            >
                <Stack horizontal verticalAlign="stretch" onClick={props.onClick} className={props.className}>
                    <div style={stickColor} className={styles.bar} />
                    <div>
                        <div className={styles.costTitle} style={textColor}>
                            {title}
                        </div>
                        <div className={styles.costValue} style={textColor}>
                            {formatter ? formatter(cost) : currencyFormatter(cost, 2, noPrefix ? "" : "$") + (suffix || "")}
                        </div>
                    </div>
                </Stack>
            </TooltipHost>
        );
    }

    const cardContent = (
        <Stack horizontal verticalAlign="stretch" onClick={props.onClick} className={props.className}>
            <div style={stickColor} className={styles.bar} />
            <div>
                <div className={styles.costTitle} style={textColor}>
                    {title}
                </div>
                <div className={styles.costContent}>
                    <div className={`${styles.costValue} ${props.classNames?.costValue || ""}`} style={textColor}>
                        {formatter ? formatter(cost) : currencyFormatter(cost, 2, noPrefix ? "" : "$") + (suffix || "")}
                    </div>
                    {!!errors?.length && <Icon iconName="warning" className={styles.abnormalIcon} />}
                </div>
            </div>
        </Stack>
    );

    if (props.showTooltip || errors?.length) {
        return (
            <TooltipHost calloutProps={{ styles: { root: { padding: 0 } } }} content={<CostCardTooltipContent {...props} items={[{ title, cost, color }]} />}>
                {cardContent}
            </TooltipHost>
        );
    }

    return cardContent;
};

export default CostCard;
