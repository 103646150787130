import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { HashRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { msalInstance } from "./utils/msalInstance";
import { Provider } from "react-redux";
import store from "./store";
import { registerFluentUIIcons } from "./utils/fluentuiIcons";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "highcharts/modules/boost";
import Highcharts from "highcharts";
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

// Fluent UI icons.
initializeIcons();
registerFluentUIIcons();

Highcharts.setOptions({
    global: {
        useUTC: false
    }
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            retry: false
        }
    }
});

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <HashRouter>
                <MsalProvider instance={msalInstance}>
                    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest} >
                        <FluentProvider theme={webLightTheme} style={{height: '100%'}}>
                            <App />
                        </FluentProvider>
                    </MsalAuthenticationTemplate>
                </MsalProvider>
            </HashRouter>
        </Provider>
        <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

if (module.hot) {
    module.hot.accept();
}