/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { AuthenticationResult, PublicClientApplication } from "@azure/msal-browser";

import { HttpMethods } from "./HttpConstants";
import { msalInstance as defaultMsalInstance } from "./msalInstance";
import { loginRequest } from "../authConfig";

export class ApiServiceBase {
    private readonly baseUrl: string;

    private readonly msalInstance: PublicClientApplication;

    constructor(baseUrl: string, msalInstance?: PublicClientApplication) {
        this.baseUrl = baseUrl;
        this.msalInstance = msalInstance ?? defaultMsalInstance;
    }

    private async getAuthHeader() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const response = await this.msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });

        const headers = new Headers();
        const bearer = `Bearer ${response.accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json; charset=utf-8");
        return headers;
    }

    public async getAuthResult() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        return this.msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });
    }

    public async get(url: string): Promise<Response> {
        const options: RequestInit = {
            method: HttpMethods.GET,
            headers: await this.getAuthHeader(),
        };
        return fetch(`${this.baseUrl}/${url}`, options);
    }

    public async post(url: string, body: any): Promise<Response> {
        const options: RequestInit = {
            method: HttpMethods.POST,
            headers: await this.getAuthHeader(),
            body: JSON.stringify(body)
        };
        return fetch(`${this.baseUrl}/${url}`, options);
    }

    public async getJson<T>(url: string): Promise<T> {
        const response = await this.get(url);
        return response.json();
    }

    public async delete(url: string): Promise<Response> {
        const requestOption: RequestInit = {
            method: HttpMethods.DELETE,
            headers: await this.getAuthHeader(),
        }

        const response = await fetch(`${this.baseUrl}/${url}`, requestOption);

        return response;
    }

    public async postJson<T>(url: string, body: any): Promise<T> {
        const response = await this.post(url, body);
        return response.json();
    }

    public async postMethod<T>(url: string, withbody: T): Promise<Response> {
        const options: RequestInit = {
            method: HttpMethods.POST,
            headers: await this.getAuthHeader(),
            body: JSON.stringify(withbody)
        }

        const response = await fetch(`${this.baseUrl}/${url}`, options);
        return response;
    }

    public async postFormDataMethod(url: string, formData: FormData): Promise<Response> {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const responseToken = await this.msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: account
        });

        const headers = new Headers();
        const bearer = `Bearer ${responseToken.accessToken}`;

        headers.append("Authorization", bearer);
        const options: RequestInit = {
            method: HttpMethods.POST,
            headers: headers,
            body: formData,
        }

        const response = await fetch(`${this.baseUrl}/${url}`, options);
        return response;
    }
}

export const defaultApiServiceBase = new ApiServiceBase(SERVICE_ENDPOINT); // TODO: make the base URL configurable

export function get(url: string): Promise<Response> {
    return defaultApiServiceBase.get(url);
}

export function post(url: string, body: any): Promise<Response> {
    return defaultApiServiceBase.post(url, body);
}

export async function getJson<T>(url: string): Promise<T> {
    const response = await defaultApiServiceBase.getJson<T>(url);
    return response;
}

export async function postJson<T>(url: string, body: any): Promise<T> {
    const response = await defaultApiServiceBase.postJson<T>(url, body);
    return response;
}

export function deleteItem(url: string): Promise<Response> {
    return defaultApiServiceBase.delete(url);
}

export function postMethod<T>(url: string, withbody: T): Promise<Response> {
    return defaultApiServiceBase.postMethod(url, withbody);
}

export function postFormDataMethod(url: string, formData: FormData): Promise<Response> {
    return defaultApiServiceBase.postFormDataMethod(url, formData);
}

export function getAuthResult(): Promise<AuthenticationResult> {
    return defaultApiServiceBase.getAuthResult();
}