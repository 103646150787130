import * as React from "react";
import styles from "./Page.less";
import Highcharts from "highcharts";
import { useEsOverviewTimeRange } from "../../../hooks/useEsOverviewTimeRange";
import { useEsArtifactDataType } from "../../../hooks/useEsArtifactDataType";
import { ESArtifactDataType, ESOverviewTimeSelection } from "../../../models/ESCostModels";
import { useDateRange } from "../../../hooks/useDateSelector";
import { ESCostUtils } from "../Utilities/ESCostUtils";
import PageHeader from "../../common/PageHeader";
import { useFlights } from "../../../hooks/useSettings";

Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.column.prototype.drawLegendSymbol;

interface IProps {
    children: React.ReactNode;
    title: string;
    description: string;
}

const PageWrapper = ({ children, title, description }: IProps) => {
    const { data: flights } = useFlights();
    const [selectedTimeRange, setSelectedTimeRange] = useEsOverviewTimeRange();
    const { setDateRange } = useDateRange();

    React.useEffect(() => {
        if (selectedTimeRange === undefined) {
            setSelectedTimeRange(ESOverviewTimeSelection.CurrentFY);
        }
    }, [selectedTimeRange, setSelectedTimeRange]);

    const [dataType, setDataType] = useEsArtifactDataType();
    React.useEffect(() => {
        if (flights?.enableES365CostArtifacts && dataType === undefined) {
            setDataType(ESArtifactDataType.Cost);
        }
    }, [flights, dataType, setDataType]);

    React.useEffect(() => {
        const [start, end] =
            selectedTimeRange === ESOverviewTimeSelection.LastFY ? ESCostUtils.getLastFiscalYearDates() : ESCostUtils.getCurrentFiscalYearDates();
        setDateRange(start, end);
    }, [selectedTimeRange, setDateRange]);

    return (
        <div className={styles.page}>
            <PageHeader
                title={title}
                description={description}
                // link="https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/31438/JawsPCM"
                // linkText="ES365 Cost Overview link sample"
                // inlineLink
                className={styles.header}
                // linkLogComponent={LogComponent.SubstrateChartingPane}
            />
            {children}
        </div>
    );
};

export default PageWrapper;
