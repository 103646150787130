import { Stack } from "@fluentui/react";
import * as React from "react";
import { useGotoPage } from "../../hooks/useGotoPage";
import { useGetAnomaliesByDataSourceQuery } from "../../hooks/useNotificationQuery";
import { useGetPcmV2Cost } from "../../hooks/useSubstrateV2Query";
import CommonConstants from "../../models/constants/CommonConstants";
import { PcmV2MetricNames, PcmV2SceneMetrics } from "../../models/constants/PcmV2Constants";
import { LogComponent, LogElement, LogTarget } from "../../models/LogModel";
import { PcmV2SceneTypeEnum } from "../../models/PcmV2Models";
import { trackEventCallback } from "../../utils/AppInsights";
import { currencyFormatter } from "../../utils/currency";
import CostCard from "../common/costCard/CostCard";
import { DonutChart } from "../common/DonutChart";
import LoadingState from "../ResponseBoundary/LoadingState";
import { RingsFilter } from "../ring/RingsFilter";
import styles from "./LandingPage.less";
import { getActiveAnomalies } from "../../utils/AnomalyDetectionUtils";
import { DataSourceEnum } from "../../models/NotificationModels";
import { useDateRange } from "../../hooks/useDateSelector";

export const SubstrateCard = () => {
    const { data: substrateV2Cost, isLoading: substrateLoading } = useGetPcmV2Cost();
    const gotoPage = useGotoPage();
    const substrateAnomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.SubstrateV2);
    const substrateCostdata = React.useMemo(
        () =>
            PcmV2SceneMetrics[PcmV2SceneTypeEnum.Overview]
                .map((metric, index) => ({
                    name: PcmV2MetricNames[metric] || "",
                    y: substrateV2Cost?.[metric] || 0,
                    color: CommonConstants.DefaultColors[index],
                    anomalies: getActiveAnomalies(substrateAnomaliesQuery.data, [metric]),
                }))
                .filter((item) => item.y > 0),
        [substrateAnomaliesQuery.data, substrateV2Cost]
    );
    const dateRange1 = useDateRange();
    
    return (
        <>
        <div className={styles.section}>
            <Stack horizontal horizontalAlign="space-between" className={styles.sectionHeaderContent2}>
                {/* <div className={styles.sectionHeaderContent2}> */}
                <h4
                    className={styles.sectionHeaderContent2}
                    onClick={() => {
                        trackEventCallback(LogComponent.LandingPage, LogElement.NavigateToSubstrate, "Navigate to Substrate", LogTarget.Title);
                        gotoPage("/SubstrateV2");
                    }}
                >
                    Substrate
                </h4>
                {/* </div> */}
                <RingsFilter />
            </Stack>
        </div>
        {!substrateLoading ? (
            <>
                <div className={styles.title}>
                    <span className={styles.titleTotalCost}>{currencyFormatter(substrateV2Cost?.cost || 0, 2, "$")}</span> Total Cost
                </div>
                <div className={styles.grid}>
                    <div key="Transaction">
                        <CostCard title="Transaction" color={CommonConstants.DefaultColors[0]} cost={substrateV2Cost?.transactionCost || 0} />
                    </div>
                    <div key="Process Hosting">
                        <CostCard
                            title="Process Hosting"
                            color={CommonConstants.DefaultColors[1]}
                            cost={substrateV2Cost?.processHostingCost || 0}
                        />
                    </div>
                    <div key="Network">
                        <CostCard title="Network" color={CommonConstants.DefaultColors[2]} cost={substrateV2Cost?.networkCost || 0} />
                    </div>
                    <div key="HDD-Storage">
                        <CostCard title="HDD-Storage" color={CommonConstants.DefaultColors[3]} cost={substrateV2Cost?.hddCost || 0} />
                    </div>
                    <div key="SSD-Storage">
                        <CostCard title="SSD-Storage" color={CommonConstants.DefaultColors[4]} cost={substrateV2Cost?.ssdCost || 0} />
                    </div>
                </div>
            </>
        ) : (
            <LoadingState />
        )}
        <div className={styles.sectionHeaderContent}>
            <h4 className={styles.sectionHeaderTitle}>Substrate Cost by Metrics</h4>
            {!substrateLoading ? (
                substrateV2Cost && substrateV2Cost.cost > 0 ? (
                    <DonutChart
                        {...{
                            data: substrateCostdata,
                            dateRange: [dateRange1.startDate, dateRange1.endDate],
                        }}
                    />
                ) : (
                    <></>
                )
            ) : (
                <LoadingState />
            )}
        </div>
        <button
            className={styles.navButton}
            onClick={() => {
                trackEventCallback(LogComponent.LandingPage, LogElement.NavigateToSubstrate, "Navigate to Substrate", LogTarget.Button);
                gotoPage("/SubstrateV2");
            }}
        >
            View Substrate
        </button>
        </>
    )
}