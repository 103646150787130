import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey } from "../models/SearchKey";
import { ESCostOrganization } from "../models/ESCostModels";

export function useEsOrg(): [ESCostOrganization[], (newOrg?: ESCostOrganization[]) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const orgs: ESCostOrganization[] = useMemo(() => {
        const orgStr = searchParams.get(SearchKey.ESOrg);
        if (!orgStr) {
            return [];
        }
        return JSON.parse(orgStr);
    }, [searchParams]);

    const setEsOrg = useCallback((newOrg?: ESCostOrganization[]) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (!newOrg || newOrg.length === 0) {
            newSearchParams.delete(SearchKey.ESOrg);
        } else {
            newSearchParams.set(SearchKey.ESOrg, JSON.stringify(newOrg));
        }

        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return [orgs, setEsOrg];
}