import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import React from "react";
import { useEsOverviewTimeRange } from "../../../hooks/useEsOverviewTimeRange";
import { ESOverviewTimeSelection } from "../../../models/ESCostModels";
import { ESCostUtils } from "../Utilities/ESCostUtils";

export const OverviewTimeDropdown: React.FC = () => {
    const [selectedTimeRange, setSelectedTimeRange] = useEsOverviewTimeRange();
    const lastFY = Number(ESCostUtils.getLastFiscalYearDates()[0].format("YY")) + 1;
    const options = [
        { key: ESOverviewTimeSelection.LastFY, text: `FY${lastFY}` },
        { key: ESOverviewTimeSelection.CurrentFY, text: `FY${lastFY + 1}` },
    ];

    const onChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
        setSelectedTimeRange(option?.key as ESOverviewTimeSelection);
    };

    return (
        <>
            <span style={{ marginRight: 10, fontSize: 14 }}>Fiscal Year:</span>
            <div style={{ height: 40, marginRight: 20 }}>
                <Dropdown
                    selectedKey={selectedTimeRange ? selectedTimeRange : undefined}
                    options={options}
                    onChange={onChange}
                    styles={{ dropdown: { marginTop: 4, width: 150 } }}
                />
            </div>
        </>
    );
};
