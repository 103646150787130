import { Stack } from "@fluentui/react";
import React from "react";
import { OrgFilter } from "./OrgFilter";
import styles from "./OverviewFilterBar.less";
import { OverviewTimeDropdown } from "./OverviewTimeDropdown";
import { UsageScenarioFilter } from "./UsageScenarioFilter";

interface IProps {
    loading: boolean;
}

const CloudBuildUsageScenarioFilterBar: React.FC<IProps> = (props) => {
    return (
        <>
            <Stack horizontal verticalAlign="center" horizontalAlign="end" grow={1} className={styles.outline}>
                <Stack horizontal verticalAlign="center">
                    <OverviewTimeDropdown />
                    <OrgFilter />
                    <UsageScenarioFilter loading={props.loading} />
                </Stack>
            </Stack>
        </>
    );
};

export default CloudBuildUsageScenarioFilterBar;
