import { IUserPreference, UserPreferenceUtils } from "./UserPreferenceUtils";
import { useCallback, useMemo, useState } from "react";

export function useUserPreference<Key extends keyof IUserPreference, Value = IUserPreference[Key]>(
    key: Key,
    deps?: React.DependencyList
): [Value | null, (newValue: Value) => void, () => void] {
    const [status, forceUpdate] = useState(false);
    const data = useMemo(() => UserPreferenceUtils.getUserPreferenceData<Key, Value>(key), [status, ...(deps || [])]);

    const setter = useCallback(
        (value: Value) => {
            if (UserPreferenceUtils.setUserPreferenceData(key, value)) {
                forceUpdate(!status);
            }
        },
        [status, ...(deps || [])]
    );

    return [data, setter, () => forceUpdate(!status)];
}
