import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey } from "../models/SearchKey";

export function useRegion(): [string[], (regions?: string[]) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const regions: string[] = useMemo(() => {
        const regionStr = searchParams.get(SearchKey.Region);
        if (!regionStr) {
            return [];
        }
        
        return JSON.parse(regionStr);
    }, [searchParams]);

    const setRegions = useCallback((regions?: string[]) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (!regions || regions.length === 0) {
            newSearchParams.delete(SearchKey.Region);
        } else {
            newSearchParams.set(SearchKey.Region, JSON.stringify(regions));
        }

        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return [regions, setRegions];
}