import React from "react";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import {
    ESAzureRegionSubscriptionServiceFYCostRawResponse,
    ESAzureRegionSubscriptionMonthlyCostRawResponse,
    ESCostDataDimensions,
    ESCostOrganization,
    ESOverviewTimeSelection,
} from "../../../../models/ESCostModels";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import ChartContainer from "../../../chart/ChartContainer";
import { useEsOrg } from "../../../../hooks/useEsOrg";
import { ESOrgs } from "../../../../models/constants/ESCostConstants";
import moment from "moment";
import { LineChartSeries } from "../../../common/LineChart";
import CommonConstants from "../../../../models/constants/CommonConstants";
import { TargetDimensions, getSortedAzureFYCost } from "./TopAzureCostBarChart";
import { SubscriptionNameMapping } from "../../Views/Azure/AzureDetailsPage";

type TrendTargetDimensions = Exclude<TargetDimensions, ESCostDataDimensions.Service>;

interface IProps {
    yearlyData: ESAzureRegionSubscriptionServiceFYCostRawResponse[];
    monthlyData: ESAzureRegionSubscriptionMonthlyCostRawResponse[];
    description: string;
    targetDimension: TrendTargetDimensions;
    top: number;
    subscriptionMapping?: SubscriptionNameMapping;
}

function getDates(data: ESAzureRegionSubscriptionMonthlyCostRawResponse[], fy?: ESOverviewTimeSelection): string[] {
    const dates = fy === ESOverviewTimeSelection.LastFY ? ESCostUtils.getLastFiscalYearDates() : ESCostUtils.getCurrentFiscalYearDates();
    return [
        ...new Set(
            data
                .filter((d) => {
                    const date = moment(d[ESCostDataDimensions.TimeStamp]);
                    return date >= dates[0] && date < dates[1];
                })
                .map((d) => d[ESCostDataDimensions.TimeStamp])
        ),
    ].sort();
}

function calculateSeries(
    org: ESCostOrganization[],
    dates: string[],
    data: ESAzureRegionSubscriptionMonthlyCostRawResponse[],
    targetDimension: TrendTargetDimensions,
    categories: string[],
    mapping?: SubscriptionNameMapping
): LineChartSeries[] {
    const costs = data.filter(
        (cost) =>
            ESOrgs.includes(cost[ESCostDataDimensions.Organization]) &&
            (org.length === 0 || org.includes(cost[ESCostDataDimensions.Organization])) &&
            dates.includes(cost[ESCostDataDimensions.TimeStamp]) &&
            categories.includes(cost[targetDimension] ?? "")
    );

    const d: { stage: string; values: [number, number][] }[] = Array.from(categories, (stage) => {
        const valueList: [number, number][] = Array.from(dates, (date) => [moment(date).valueOf(), 0]);
        return { stage, values: valueList };
    });

    costs.forEach((cost) => {
        const i = categories.indexOf(cost[targetDimension] ?? "");
        const j = dates.indexOf(cost[ESCostDataDimensions.TimeStamp]);
        d[i].values[j][1] += cost[ESCostDataDimensions.Cost];
    });

    return d.map((obj, index) => {
        return {
            name: targetDimension === ESCostDataDimensions.SubscriptionId && mapping !== undefined && obj.stage in mapping ? mapping[obj.stage] : obj.stage,
            data: obj.values,
            showDeviation: false,
            color: CommonConstants.DefaultColors[index],
        };
    });
}

export const TopRegionSubscriptionCostMonthlyTrend: React.FC<IProps> = (props) => {
    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );
    const [org] = useEsOrg();
    const sortedData = React.useMemo(
        () => getSortedAzureFYCost(props.yearlyData, year, org, props.top, props.targetDimension),
        [props.yearlyData, org, year, props.top, props.targetDimension]
    );
    const categories = React.useMemo(
        () => sortedData.map((d) => d[props.targetDimension]).filter((d) => d !== undefined) as string[],
        [sortedData, props.targetDimension]
    );

    const dates = React.useMemo(() => getDates(props.monthlyData, timeRange), [props.monthlyData, timeRange]);
    const series: LineChartSeries[] = React.useMemo(
        () => calculateSeries(org, dates, props.monthlyData, props.targetDimension, categories, props.subscriptionMapping),
        [org, dates, props.monthlyData, props.targetDimension, categories, props.subscriptionMapping]
    );

    return (
        <ChartContainer
            headerProps={{ title: `${props.description} (FY${String(year).substring(2)})` }}
            chartType="line"
            chartProps={{
                isNotCurrency: false,
                ignoreWeekend: false,
                height: 320,
                series,
                hideEmptySeries: true,
                dateFormatStr: "MMM DD, YYYY",
                tooltipDateFormatStr: "MMM DD, YYYY",
            }}
        />
    );
};
