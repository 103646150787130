import { PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum } from "../../../models/PcmV2Models";

import React from "react";
import { SubstrateCategoryCostTrends } from "../common/SubstrateCategoryCostTrends";
import { SubstrateCostTable } from "../common/table/SubstrateCostTable";
import { SubstrateTotalCostTrends } from "../common/SubstrateTotalCostTrends";
import commonStyles from "../../common/styles/Common.less";

export const SubstrateSsdOverview: React.FC = () => {
    return (
        <div>
            <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`}>
                <SubstrateTotalCostTrends scene={PcmV2SceneTypeEnum.SSD} />
                <SubstrateCategoryCostTrends scene={PcmV2SceneTypeEnum.SSD} />
            </div>
            <SubstrateCostTable scene={PcmV2SceneTypeEnum.SSD} subScene={PcmV2SubSceneTypeEnum.SSDTotalCost} />
        </div>
    );
};
