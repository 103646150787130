import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey } from "../models/SearchKey";

export function useEsManagers(): [string[], (newManagers?: string[]) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const managers: string[] = useMemo(() => {
        const managerStr = searchParams.get(SearchKey.ESManagers);
        if (!managerStr) {
            return [];
        }
        return JSON.parse(managerStr);
    }, [searchParams]);

    const setEsManagers = useCallback((newManagers?: string[]) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (!newManagers || newManagers.length === 0) {
            newSearchParams.delete(SearchKey.ESManagers);
        } else {
            newSearchParams.set(SearchKey.ESManagers, JSON.stringify(newManagers));
        }

        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return [managers, setEsManagers];
}