import { ActionButton, Callout, Checkbox, DirectionalHint, Icon } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useRef } from "react";
import { useEsOrg } from "../../../hooks/useEsOrg";
import styles from "../../ring/RingsFilter.less";
import { ESOrgs } from "../../../models/constants/ESCostConstants";
import { ESCostOrganization } from "../../../models/ESCostModels";

export const OrgFilter: React.FC = () => {
    const buttonRef = useRef<HTMLDivElement>(null);
    const [calloutVisible, { toggle: toggleCalloutVisible, setFalse: hideCallout }] = useBoolean(false);
    const [selectedEsOrg, setSelectedEsOrg] = useEsOrg();

    const handleToggleSelect = (key: ESCostOrganization, checked: boolean) => {
        const newSelectedKeys = [...selectedEsOrg];

        if (checked) {
            newSelectedKeys.push(key);
        } else {
            newSelectedKeys.splice(newSelectedKeys.indexOf(key), 1);
        }

        setSelectedEsOrg(newSelectedKeys);
    };

    const handleClearAll = () => {
        setSelectedEsOrg([]);
    };

    return (
        <div className={styles.ringSelector}>
            <div className={styles.ringSelectorContent} ref={buttonRef} onClick={toggleCalloutVisible}>
                <Icon iconName="Org" style={{ color: "#323130" }} />
                <span className={styles.ringSelectorContentText}>
                    {!selectedEsOrg.length || selectedEsOrg.length === ESOrgs.length
                        ? "All Orgs"
                        : `${selectedEsOrg.length} Org${selectedEsOrg.length > 1 ? "s" : ""}`}
                </span>
                <Icon iconName="ChevronDown" className={styles.downIcon} />
            </div>
            {calloutVisible && (
                <Callout directionalHint={DirectionalHint.bottomRightEdge} isBeakVisible={false} target={buttonRef.current} onDismiss={hideCallout}>
                    <div className={styles.ringListWrapper}>
                        <div className={styles.ringList}>
                            {ESOrgs.map((item) => (
                                <Checkbox
                                    className={styles.ringListItem}
                                    checked={selectedEsOrg.indexOf(item) >= 0}
                                    label={item}
                                    styles={{ label: { width: "100%" } }}
                                    key={item}
                                    id={item}
                                    onChange={(_, checked) => handleToggleSelect(item, !!checked)}
                                />
                            ))}
                        </div>
                        <ActionButton
                            onClick={handleClearAll}
                            primaryDisabled={selectedEsOrg.length === 0}
                            className={styles.clearButton}
                            iconProps={{ iconName: "Cancel" }}
                            disabled={selectedEsOrg.length === 0}
                        >
                            Clear All
                        </ActionButton>
                    </div>
                </Callout>
            )}
        </div>
    );
};
