import React, { useState } from "react";
import { trackEventCallback } from "../../utils/AppInsights";
import { EventType, LogTarget } from "../../models/LogModel";
import { IIconProps, IconButton } from "@fluentui/react";
import { useMsal } from "@azure/msal-react";
import { useFlights } from "../../hooks/useSettings";
import { useBoolean } from "@fluentui/react-hooks";
import styles from "./CosmosCostTable.less";
import useEventBus from "../../hooks/useEventBus";
import { EventTypeEnum, OpenFeedbackPanelEvent } from "../../models/Event";
import FeedbackPanelCosmosTable from "../feedback/FeedbackPanelCosmosTable";
import { CosmosLogComponent, CosmosLogElement, CosmosSourceTypeEnum, CosmosSourceTypeName, ICosmosCost } from "../../models/CosmosModels";

interface ICosmosCostTableFeedbackCellProps {
    data: ICosmosCost;
    columns: (keyof Partial<ICosmosCost>)[];
    scene: CosmosSourceTypeEnum;
}

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

const CosmosCostTableFeedbackCell: React.FC<ICosmosCostTableFeedbackCellProps> = (props) => {
    const { instance } = useMsal();
    const { data: flights } = useFlights();
    const account = instance.getActiveAccount();
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');

    const captureScreenshot = async () => {
        trackEventCallback(CosmosLogComponent[props.scene], CosmosLogElement[props.scene], "Feedback table", LogTarget.Button, undefined, EventType.Click);
        const url = window.location.href;
        setCurrentURL(url);

        console.log(props.data);
        console.log(props.columns);
        await openFeedbackPanel();
    };

    const handleDismissFeedbackPanel = () => {
        dismissFeedbackPanel();
    };

    useEventBus<OpenFeedbackPanelEvent>(EventTypeEnum.OpenFeedbackPanel, openFeedbackPanel, []);

    return (
        <div className={styles.cardContainer} >
            {flights?.enableUserFeedback &&
                <IconButton
                    title="Send feedback"
                    className={styles.feedbackIcon}
                    iconProps={feedbackIconProps}
                    onClick={captureScreenshot}
                />}
            <FeedbackPanelCosmosTable
                isPanelOpen={isFeedbackPanelOpen}
                onDismiss={handleDismissFeedbackPanel}
                userName={account?.username}
                currentURL={currentURL}
                FeedbackTab={CosmosSourceTypeName[props.scene]}
                data={props.data}
                columns={props.columns}
            />
        </div>
    );
};

export default CosmosCostTableFeedbackCell;