import * as React from "react";

import {
    ISubstrateData,
    initialState,
    makeModifySubstrateCostTableAction
} from "../../reducers/substrateReducer";
import { Icon, Separator } from "@fluentui/react";
import { sum, values } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import Highcharts from "highcharts";
import { IAppState } from "../../store";
import { IServiceTreeData } from "../../reducers/serviceTreeReducer";
import OverviewChart from "./OverviewChart/OverviewChart";
import ResponseBoundary from "../ResponseBoundary/ResponseBoundary";
import SubstrateCostTable from "./SubstrateCostTable/SubstrateCostTable";
import SubstrateMOMChart from "./SubstrateMonthOverMonthChart/SubstrateMonthOverMonthChart";
import SubstrateRingsChart from "./SubstrateRingsChart/SubstrateRingsChart";
import TopFiveComponentsChart from "./TopFiveComponentsChart/TopFiveComponentsChart";
import TotalCostTrendingChart from "./TotalCostTrendingChart/TotalCostTrendingChart";
import styles from "./Substrate.less";
import { syncChildrenClassName } from "../../utils/Constants";
import { useEffect, useMemo } from "react";
import { SubstrateEntityMetrics } from "../../models/SubstrateModels";
import { LogComponent, LogElement } from "../../models/LogModel";
import { useGetDailyMetricsQuery, useGetSubstrateCostByService, useGetSubstrateDailyCarbonEmission, useGetSubstrateMonthONMonth, useGetSubstrateRingCost, useGetSubstrateTopFiveComponents } from "../../hooks/useSubstrateQuery";
import { useCategoryFilters } from "../../hooks/useFilters";
import { LineChartContainer } from "../azure/common/LineChartContainer";
import { getTotal } from "../../utils/common";
import PageHeader from "../common/PageHeader";
import { useGetBannersByTabAndDateQuery } from "../../hooks/useBannerQuery";
import { bannerTabs } from "../../models/Nav";
import { WarningBanner } from "../banner/WarningBanner";
import { getActiveAnomalies } from "../../utils/AnomalyDetectionUtils";
import { useGetAnomaliesByDataSourceQuery } from "../../hooks/useNotificationQuery";
import { DataSourceEnum } from "../../models/NotificationModels";
import { BannerModel } from "../../models/BannerModels";

Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.column.prototype.drawLegendSymbol;

export const defaultSubstrateCostTableColumnNames = [
    SubstrateEntityMetrics.TotalCost,
    SubstrateEntityMetrics.TotalCarbonEmission,
    SubstrateEntityMetrics.CarbonScope2Emission,
    SubstrateEntityMetrics.CarbonScope3Emission,
    SubstrateEntityMetrics.ItemReadsCost,
    SubstrateEntityMetrics.ItemWritesCost,
    SubstrateEntityMetrics.ItemQueriesCost,
    SubstrateEntityMetrics.EBACost,
    SubstrateEntityMetrics.TBACost,
    SubstrateEntityMetrics.SWSSCost,
    SubstrateEntityMetrics.ServiceInstanceCost,
    SubstrateEntityMetrics.RequestProcessCost,
    SubstrateEntityMetrics.DsapiRequestsCost,
    SubstrateEntityMetrics.CfmSubmittedCost,
    SubstrateEntityMetrics.ItemSizeCost,
    SubstrateEntityMetrics.KvCacheCost,
    SubstrateEntityMetrics.SdsFastStorageCost,
    SubstrateEntityMetrics.DevOwner,
    SubstrateEntityMetrics.PmOwner,
];

const Substrate: React.FC = () => {
    const { data: allBanners } = useGetBannersByTabAndDateQuery(bannerTabs.Substrate);
    const filtersData = useCategoryFilters().filters;
    const { isLoading: isDailyMetricsLoading, data: dailyMetrics, isError: isDailyMetricsError } = useGetDailyMetricsQuery();
    const { data: substrateTopComponents } = useGetSubstrateTopFiveComponents();
    const { isLoading: isSubstrateMOMLoading, data: substrateMOM, isError: isSubstrateMOMError } = useGetSubstrateMonthONMonth();
    const { isLoading: isSubstrateDailyCarbonEmisionLoading, data: substrateDailyCarbonEmission, isError: isSubstrateDailyCarbonEmissionError } = useGetSubstrateDailyCarbonEmission();
    const { isLoading: isSubstrateRingsLoading, data: substrateRings, isError: isSubstrateRingsError } = useGetSubstrateRingCost();
    const { isLoading: isSubstrateCostByServiceLoading, data: substrateCostByService, isError: isSubstrateCostByServiceError } = useGetSubstrateCostByService();
    const substrateData = useSelector<IAppState, ISubstrateData>(state => state.substrate);
    const serviceTree = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const topServiceData = useGetSubstrateTopFiveComponents();
    const useServiceNameDirectly = useMemo(() => {
        return (filtersData.filters.Service?.length == 1 && !filtersData.filters.GriffinApp
            && !filtersData.filters.SSDDataSetName && !filtersData.filters.Process
            && !filtersData.filters.VDir && !filtersData.filters.GriffinProcessor
            && !filtersData.filters.StoreClient && !filtersData.filters.StoreClientComponent) ||
            (!filtersData.filters.Service && Boolean(filtersData.filters.GriffinApp
                || filtersData.filters.SSDDataSetName || filtersData.filters.Process
                || filtersData.filters.VDir || filtersData.filters.GriffinProcessor
                || filtersData.filters.StoreClient || filtersData.filters.StoreClientComponent));
    }, [filtersData.filters]);
    const total = React.useMemo(() => {
        if (!dailyMetrics?.distribution) {
            return 0;
        }

        return sum(values(dailyMetrics?.distribution.cost));
    }, [dailyMetrics?.distribution]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(makeModifySubstrateCostTableAction(initialState.substrateCostTableSelectedColumns));
    }, [dispatch]);
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.Substrate);
    const activeAnomalies = React.useMemo(() => getActiveAnomalies(anomaliesQuery.data, ["SubstrateCarbonEmissions"]), [anomaliesQuery.data]);
    const banner = {
        "message": "Substrate carbon accounting is in early stage and the data is subject to change. And if there're data loss in upstream data source, we will do interpolation,",
        "type": "info",
        "id": 1,
        "tab": "Substrate",
        "startDate": "2022-01-01",
        "endDate": "2022-10-10",
        "metric": "SubstrateCarbon",
        "url": "https://microsoft.sharepoint.com/:w:/t/szo365fnd/ESUo8Z9lNf9Pv-vEgUTj2IwBr3pR167bCHe5DAzuR6bG6A?e=SEFBxi",
        "urlText": "more"
    }

    return (
        <>
            <div className={syncChildrenClassName}>
                <PageHeader
                    title="Substrate"
                    description="Welcome to the Substrate COGS dashboard! "
                    link="https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/31438/JawsPCM"
                    linkText="Learn more about Substrate"
                    inlineLink
                    className={styles.header}
                    linkLogComponent={LogComponent.SubstrateChartingPane}
                />
                {
                    Boolean(allBanners?.length) &&
                    allBanners?.map(item =>
                    (
                        <WarningBanner key={item.id} bannerItem={item} />
                    ))
                }
                <ResponseBoundary
                    isLoading={isDailyMetricsLoading}
                    errorHappened={isDailyMetricsError}
                    data={dailyMetrics?.distribution}
                >
                    <OverviewChart items={dailyMetrics?.distribution} total={total} />
                </ResponseBoundary>
                <div className={styles.grid}>
                    <ResponseBoundary
                        isLoading={isDailyMetricsLoading}
                        errorHappened={isDailyMetricsError}
                        data={dailyMetrics?.timeSeries}>
                        <TotalCostTrendingChart
                            title="Total Substrate Cost Trends"
                            totalCostTimeSeries={dailyMetrics?.timeSeries}
                            details={dailyMetrics?.details}
                        />
                    </ResponseBoundary>
                    <ResponseBoundary
                        data={topServiceData.data}
                        isLoading={topServiceData.isLoading || serviceTree.isLoading}
                        errorHappened={topServiceData.isError}>
                        <TopFiveComponentsChart
                            useServiceNameDirectly={useServiceNameDirectly}
                            data={substrateTopComponents}
                            serviceIndexMap={serviceTree.indexMap}
                        />
                    </ResponseBoundary>
                    <ResponseBoundary
                        isLoading={isSubstrateMOMLoading}
                        errorHappened={isSubstrateMOMError}
                        data={substrateMOM}>
                        <SubstrateMOMChart
                            title="Month Over Month - Workday Daily Average"
                            data={substrateMOM}
                        />
                    </ResponseBoundary>
                    <ResponseBoundary
                        isLoading={isSubstrateRingsLoading}
                        errorHappened={isSubstrateRingsError}
                        data={substrateRings}>
                        <SubstrateRingsChart
                            title="Cost by Deployment Ring"
                            data={substrateRings}
                        />
                    </ResponseBoundary>
                    <div>
                        <Separator styles={{ root: styles.separator }} />
                        <h4 className={styles.title}>Substrate Carbon Emission Guides</h4>
                        <div className={styles.carbonGuideRow}>
                            <div className={styles.carbonGuideLeft}>
                                <Icon iconName="SubstrateCarbonGuide1" />
                            </div>
                            <div className={styles.carbonGuideRight}>
                                <div className={styles.carbonGuideSubTitle}>Understanding Carbon Emissions in Substrate</div>
                                <div className={styles.carbonGuideParagraph}>Substrate is a global data and intelligence platform that powers hundreds of experiences. The methodology used to apportion carbon emissions to internal services leverages this understanding to create a robust mechanism to attribute emissions. The methodology works by calculating scope 2, and scope 3 carbon emissions across every Substrate rack and then apportioning the emissions to services running on those racks based on their CPU usage.</div>
                            </div>
                        </div>
                        <div className={styles.carbonGuideRow}>
                            <div className={styles.carbonGuideLeft}>
                                <Icon iconName="SubstrateCarbonGuide2" />
                            </div>
                            <div className={styles.carbonGuideRight}>
                                <div className={styles.carbonGuideSubTitle}>Understanding the Math Behind Microsoft’s Commitment to Become Carbon Negative by 2030</div>
                                <div className={styles.carbonGuideParagraph}>What do the Scope2 and Scope3 emissions comprise of and how are they calculated?</div>
                                <a href="https://youtu.be/wj0UrF2T130">Learn more with a video introduction</a>
                            </div>
                        </div>
                    </div>
                    <ResponseBoundary
                        isLoading={isSubstrateDailyCarbonEmisionLoading}
                        errorHappened={isSubstrateDailyCarbonEmissionError}
                        data={substrateDailyCarbonEmission}>
                        <div>
                            <LineChartContainer
                                title="Total Substrate Carbon Emission Trends"
                                isLoading={isSubstrateDailyCarbonEmisionLoading}
                                series={[
                                    {
                                        name: "Carbon Emissions",
                                        data: substrateDailyCarbonEmission?.data.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                                    },
                                ]}
                                isNotCurrency
                                cardProps={[
                                    { title: "Total Carbon Emission", cost: getTotal(substrateDailyCarbonEmission?.data), color: "#0099BC", noPrefix: true, suffix: 'MT' },
                                ]}
                                suffix="MT"
                                logProps={{
                                    logComponent: LogComponent.Substrate,
                                    logElement: LogElement.SubstrateTotalCarbonEmission,
                                }}
                                fillMissingAbnormalData
                                anomalies={activeAnomalies}
                                minValue={0}
                                banner={banner}
                            />
                        </div>
                    </ResponseBoundary>
                </div>
            </div>
            <ResponseBoundary
                isLoading={isSubstrateCostByServiceLoading}
                errorHappened={isSubstrateCostByServiceError}
                data={substrateCostByService}
            >
                <SubstrateCostTable
                    scenarioView={false}
                    columns={defaultSubstrateCostTableColumnNames}
                    costByService={substrateCostByService}
                    globalSelectedColumns={substrateData.substrateCostTableSelectedColumns}
                    serviceTreeIndexMap={serviceTree.indexMap}
                    fixedColumn="Service - App/Process/Client"
                    makeChangeColumnsAction={makeModifySubstrateCostTableAction}
                    scenarioNoMap={serviceTree.scenarioNoMap}
                    appScenarios={serviceTree.appScenarios}
                    defaultSortingColumn={SubstrateEntityMetrics.TotalCost}
                    logComponent={LogComponent.SubstrateChartingPane}
                />
            </ResponseBoundary>
        </>
    )
}

export default Substrate;