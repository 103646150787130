import { Stack } from "@fluentui/react";
import React from "react";
import { OrgFilter } from "./OrgFilter";
import styles from "./OverviewFilterBar.less";
import { OverviewTimeDropdown } from "./OverviewTimeDropdown";
import { IManagerFilterProps, ManagerFilter } from "./ManagerFilter";
import { UsageScenarioFilter } from "./UsageScenarioFilter";

const ArtifactOverviewFilterBar: React.FC<IManagerFilterProps> = (props) => {
    return (
        <Stack horizontal verticalAlign="center" horizontalAlign="end" grow={1} className={styles.outline}>
            <Stack horizontal verticalAlign="center">
                <OverviewTimeDropdown />
                <OrgFilter />
                <ManagerFilter {...props} />
                <UsageScenarioFilter loading={false} />
            </Stack>
        </Stack>
    );
};

export default ArtifactOverviewFilterBar;
