import styles from './Table.less';

import React, { useState } from "react";
import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, IColumn, IColumnReorderOptions, SelectionMode } from "@fluentui/react";

import { EFTrackerBigBetsProject } from '../../../../../models/EfficiencyTrackerBigBets';
import EfficiencyStatus from '../../Tools/EfficiencyStatus';
import { formatValue, parseMetricsBigBets } from '../../Tools/ExportFunction';
import { EfficiencyStatusEnum } from '../../../../../models/EfficiencyTracker';
import Mail2Person from '../../Tools/Mail2Person';
   


const columns: IColumn[] = [
    {
        key: 'ProjectID', name: 'ProjectId', minWidth: 20, maxWidth: 60, isResizable: true, className: styles.PrimaryColumn
    },
    {
        key: 'Category', name: 'Category', minWidth: 20, maxWidth: 100, isResizable: true, className: styles.PrimaryColumn
    },
    {
        key: 'Workload', name: 'Workload', minWidth: 20, maxWidth: 100, isResizable: true, className: styles.PrimaryColumn
    },
    {
        key: 'DRI', name: 'DRI', minWidth: 60, maxWidth: 80, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: 'CVPSponsor', name: 'CVP Sponsor', minWidth: 60, maxWidth: 100, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: 'GEMGPM', name: 'GEM/GPM', minWidth: 60, maxWidth: 80, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: 'ImpactDollar', name: 'Impact($)', minWidth: 20, maxWidth: 120, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: 'DeliveryETA', name: 'Delivery ETA', minWidth: 30, maxWidth: 80, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'Confidence', name: 'Confidence', minWidth: 60, maxWidth: 80, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'Status', name: 'Execution status', minWidth: 60, maxWidth: 110, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'IsMapped', name: 'Is Mapped', minWidth: 60, maxWidth: 110, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'HcCount', name: 'HC Count', minWidth: 60, maxWidth: 110, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'Owner', name: 'Owner', minWidth: 20, maxWidth: 150, isResizable: true, className: styles.nomarlColumn,
    },
    // Temporary hiding
    //{
    //    key: 'LinkToTicket', name: 'Ticket', minWidth: 20, maxWidth: 40, isResizable: false, className: styles.nomarlColumn
    //},
    {
        key: 'CreateTime', name: 'Create Date', minWidth: 50, maxWidth: 80, isResizable: false, className: styles.nomarlColumn
    },
    {
        key: 'LastModifyTime', name: 'Last Modify Date', minWidth: 50, maxWidth: 110, isResizable: false, className: styles.nomarlColumn
    },
];

interface IDetailsTable {
    data: EFTrackerBigBetsProject;
}

const BigBetsProjectDetailsTable: React.FC<IDetailsTable> = (props) => {
    const [newColumns, setnewColumns] = useState<IColumn[]>(columns || []);

    // Handle cell content
    function _renderItemColumn(item: EFTrackerBigBetsProject, index?: number, column?: IColumn): React.ReactNode {
        const fieldContent = item[column?.key as keyof EFTrackerBigBetsProject];

        switch (column?.key) {
            case "Status":
                return <EfficiencyStatus Status={item.ExecutionStatus as EfficiencyStatusEnum} />;
            case "Confidence":
                return <EfficiencyStatus Status={item.ConfidenceInImpact as EfficiencyStatusEnum} />;
            case "IsMapped":
                return <EfficiencyStatus Status={parseMetricsBigBets(item).IsMapped ? EfficiencyStatusEnum.Yes : EfficiencyStatusEnum.No} />;
            case "LinkToTicket":
                return <a href={item.LinksToExistingTicketItem}>Link</a>;
            case "Owner":
                return <Mail2Person users={item.Owner} />
            case "DRI":
                return <Mail2Person users={item.DRI} />
            case "CVPSponsor":
                return <span>{formatValue(parseMetricsBigBets(item).CVPSponsor)}</span>;
            case "GEMGPM":
                return <span>{formatValue(parseMetricsBigBets(item).GEMGPM)}</span>;
            case "HcCount":
                return <span>{formatValue(parseMetricsBigBets(item).HcCount)}</span>;
            default:
                return <span>{formatValue(fieldContent)}</span>;
        }
    }

    // Handle column drag
    const _getColumnReorderOptions = (): IColumnReorderOptions => {
        return {
            handleColumnReorder: _handleColumnReorder,
        };
    }
    const _handleColumnReorder = (draggedIndex: number, targetIndex: number): void => {
        const draggedItems = newColumns[draggedIndex];
        const reorderColumns: IColumn[] = [...newColumns];

        // insert before the dropped item
        reorderColumns.splice(draggedIndex, 1);
        reorderColumns.splice(targetIndex, 0, draggedItems);
        setnewColumns(reorderColumns);
    };

    return (
        <div>
            <DetailsList
                items={[props.data]}
                setKey="BigBetsProjectDetails"
                columns={newColumns}
                onRenderItemColumn={_renderItemColumn}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                checkboxVisibility={CheckboxVisibility.hidden}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                selectionZoneProps={{
                    disableAutoSelectOnInputElements: true,
                    isSelectedOnFocus: false
                }}
                columnReorderOptions={_getColumnReorderOptions()}
            />
        </div>
    );
}

export default BigBetsProjectDetailsTable;