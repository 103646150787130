import {
    ActionButton,
    DefaultPalette,
    IContextualMenuProps,
    IIconProps,
    IIconStyles,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    Icon,
    IconButton,
    Persona,
    PersonaSize,
    Stack
} from "@fluentui/react";
import { EventType, LogComponent, LogElement, LogTarget } from "../../models/LogModel";
import { EventTypeEnum, OpenFeedbackPanelEvent, OpenNotificationPanelEvent } from "../../models/Event";
import React, { useState } from "react";

import FeedbackPanel from "../feedback/FeedbackPanel";
import NotificationPanelList from "../notificationCenter/NotificationPanelList";
import SearchBox from "../search/SearchBox";
import html2canvas from "html2canvas";
import styles from "./AppBanner.less";
import { trackEventCallback } from "../../utils/AppInsights";
import { useBoolean } from "@fluentui/react-hooks";
import useEventBus from "../../hooks/useEventBus";
import { useFlights } from "../../hooks/useSettings";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const stackStyles: IStackStyles = {
    root: {
        backgroundColor: "#484644",
        height: "48px",
        color: DefaultPalette.white,
        padding: "0 24px"
    }
};

const innerStackStyles: IStackStyles = {
    root: {
        alignItems: "stretch",
        height: "100%",
    }
}

const innerStackTokens: IStackTokens = { childrenGap: 5 };

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

const iconNotificationStyleProps: IIconStyles = {
    root: {
        color: DefaultPalette.white
    }
}

const notificationCenterIconProps: IIconProps = {
    iconName: "Ringer",
    styles: iconNotificationStyleProps
};

const stackItemStyles: IStackItemStyles = {
    root: {
        alignItems: "center",
        color: DefaultPalette.white,
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
    },
};


const devToolsIcon: IIconProps = { iconName: 'DeveloperTools' };
const devTools: IContextualMenuProps = {
    items: [
        {
            key: 'emailMessage',
            text: "My Access Token",
            iconProps: { iconName: 'IDBadge' },
            href: "/#/Auth",
        },
        {
            key: 'sqltool',
            text: "Compress SQL",
            iconProps: { iconName: 'IDBadge' },
            href: "/#/SQLTool",
        },
    ],
};

const AppBanner: React.FC = () => {
    const { instance } = useMsal();
    const { data: flights } = useFlights();
    const account = instance.getActiveAccount();
    const [isNotificationPanelOpen, { setTrue: openNotificationPanel, setFalse: dismissNotificationPanel }] = useBoolean(false);
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');
    const [currentScreenshotURL, setCurrentScreenshotURL] = useState<string>('');
    const isDevToolsEnabled = (ENVIRONMENT === "dev" || ENVIRONMENT === "localhost");
    const { pathname } = useLocation();

    const captureScreenshot = async () => {
        trackEventCallback(LogComponent.PivotHeadPane, LogElement.SendFeedback, "Send Feedback", LogTarget.Button, undefined, EventType.Click);

        const url = window.location.href;
        setCurrentURL(url);

        await openFeedbackPanel();

        const targetElement = document.documentElement;
        setTimeout(() => { 
            html2canvas(targetElement).then((canvas) => {
                const screenshotDataUrl = canvas.toDataURL('image/png');
                setCurrentScreenshotURL(screenshotDataUrl);
            });
        }, 100);
    };

    const handleDismissFeedbackPanel = () => {
        setCurrentScreenshotURL('');
        dismissFeedbackPanel();
    };

    useEventBus<OpenNotificationPanelEvent>(EventTypeEnum.OpenNotificationPanel, openNotificationPanel, []);
    useEventBus<OpenFeedbackPanelEvent>(EventTypeEnum.OpenFeedbackPanel, openFeedbackPanel, []);

    return (
        <Stack horizontal horizontalAlign="space-between" styles={stackStyles}>
            <Stack.Item styles={stackItemStyles}>
                <Icon iconName="LineChart" className={styles.logo} />
                <h2>Jaws</h2>
            </Stack.Item>
            {!pathname.startsWith("/CogsCalculator") && <Stack.Item styles={stackItemStyles}><SearchBox /></Stack.Item>}
            <Stack.Item styles={stackItemStyles}>
                <Stack horizontal tokens={innerStackTokens} styles={innerStackStyles}>
                    <NotificationPanelList
                        isPanelOpen={isNotificationPanelOpen}
                        onDismiss={dismissNotificationPanel}
                    />
                    <FeedbackPanel
                        isPanelOpen={isFeedbackPanelOpen}
                        onDismiss={handleDismissFeedbackPanel}
                        userName={account?.username}
                        currentURL={currentURL}
                        screenshotInputURL={currentScreenshotURL}
                        FeedbackTab={undefined}
                    />
                    <ActionButton iconProps={notificationCenterIconProps} className={styles.iconButton} onClick={openNotificationPanel}>
                        Notification center
                    </ActionButton>
                    {
                        flights?.enableUserFeedback &&
                        <IconButton title="Send feedback" className={styles.iconButton} iconProps={feedbackIconProps} onClick={captureScreenshot} />
                    }
                    <Persona
                        hidePersonaDetails
                        size={PersonaSize.size32}
                        text={account?.name}
                        title={account?.username}
                        className={styles.iconButton}
                    />
                    {
                        isDevToolsEnabled &&
                        <IconButton
                            menuProps={devTools}
                            iconProps={devToolsIcon}
                            title="Developer Tools"
                            ariaLabel="Developer Tools"
                            className={styles.iconButton}
                        />
                    }
                </Stack>
            </Stack.Item>
        </Stack>
    );
};

export default AppBanner;