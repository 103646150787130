import React, { useRef, useState, useEffect } from "react";
import AvatarEditor, { type Position } from "react-avatar-editor";
import Dropzone from 'react-dropzone'

interface ImageEditorProps {
    imageSrc: string; 
    onImageEdited: (editedImageDataURL: string) => void;
    onClose: () => void;
}

const ImageEditor: React.FC<ImageEditorProps> = (props) => {
    const maxSize = 800;
    const editorRef = useRef<AvatarEditor | null>(null);
    const [scale, setScale] = useState(1);
    const [image, setImage] = useState<string>(props.imageSrc);
    const [editorWidth, setEditorWidth] = useState(maxSize);
    const [editorHeight, setEditorHeight] = useState(maxSize);
    const [position, setPosistion] = useState( { x: 0.5, y: 0.5 });

    const handleScaleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScale(parseFloat(event.target.value));
    };

    const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditorWidth(parseInt(event.target.value));
    };

    const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditorHeight(parseInt(event.target.value));
    };

    const onImageEdited = () => {
        if (editorRef.current) {
            const canvas = editorRef.current.getImage();
            const editedImageDataURL = canvas.toDataURL();
            props.onImageEdited(editedImageDataURL);
        }
    };

    useEffect(() => {
        setImage(props.imageSrc);
    }, [props.imageSrc]);

    useEffect(() => {
        const img = new Image();
        img.src = image;

        img.onload = () => {
            const width = img.width;
            const height = img.height;

            let adjustedWidth = width;
            let adjustedHeight = height;

            if (width > maxSize || height > maxSize) {
                const aspectRatio = width / height;

                if (width > height) {
                    adjustedWidth = maxSize;
                    adjustedHeight = maxSize / aspectRatio;
                } else {
                    adjustedHeight = maxSize;
                    adjustedWidth = maxSize * aspectRatio;
                }
            }

            setEditorWidth(adjustedWidth);
            setEditorHeight(adjustedHeight);
        };
    }, [image]);

    const handlePositionChange = (position: Position) => {
        setPosistion(position);
    }

    const handleDrop = (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result) {
                    setImage(reader.result as string);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            <div>
                <label>Editor Width:</label>
                <input
                    type="range"
                    min="100"
                    max={maxSize}
                    step="10"
                    value={editorWidth}
                    onChange={handleWidthChange}
                />
            </div>
            <div>
                <label>Editor Height:</label>
                <input
                    type="range"
                    min="100"
                    max={maxSize}
                    step="10"
                    value={editorHeight}
                    onChange={handleHeightChange}
                />
            </div>
            <div>
                <label>Scale:</label>
                <input
                    type="range"
                    min="0.5"
                    max="3"
                    step="0.05"
                    value={scale}
                    onChange={handleScaleChange}
                />
            </div>
            <button onClick={() => onImageEdited()}>Save Crop</button>
            <button onClick={props.onClose}>Close</button>
            <Dropzone
                onDrop={handleDrop}
                noClick
                noKeyboard
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <AvatarEditor
                            width={editorWidth}
                            height={editorHeight}
                            position={position}
                            ref={editorRef}
                            image={image}
                            border={50}
                            scale={scale}
                            onPositionChange={handlePositionChange}
                            disableBoundaryChecks={true}
                        />
                        <input {...getInputProps()} />
                    </div>
                )}
            </Dropzone>
        </div>
    );
};

export default ImageEditor;
