// TODO: optimization

import { Callout, Checkbox, DirectionalHint, IButtonStyles, ICheckboxStyles, IIconProps, IStackTokens, IStyle, Icon, IconButton, Stack } from "@fluentui/react";
import { DataNode, NodeState, NodeWrapper } from "../models/DataNode";
import React, { useCallback, useMemo, useState } from "react";
import { useBoolean, useId } from "@fluentui/react-hooks";

import treeSelectStyles from "./TreeSelect.less";

export interface TreeSelectProps {
    treeData: DataNode[];
    selectedValues: string[];
    onChange: (values: string[], nodes: DataNode[]) => void;
}

interface TreeNodeProps {
    node: NodeWrapper;
    indent: number;
    onChange: (node: NodeWrapper, checked: boolean) => void;
}

const getIndentStyles = (() => {
    const cache = new Map<number, { root: IStyle }>();
    return (indent: number) => {
        let t = cache.get(indent);
        if (t) {
            return t;
        }
        t = { root: { marginLeft: indent * 20 } };
        cache.set(indent, t);
        return t;
    };
})();

const chevronRight: IIconProps = { iconName: "ChevronRight", styles: { root: { fontSize: 14 } } };

const chevronDown: IIconProps = { iconName: "ChevronDown", styles: { root: { fontSize: 14 } } };

const buttonStyles: IButtonStyles = { root: { width: 20, height: 20 } };

const checkBoxWithNoChildStyles: ICheckboxStyles = { root: { marginLeft: 32 } };

const nodeStackToken: IStackTokens = { childrenGap: 12 };

const TreeNode: React.FC<TreeNodeProps> = ({ node, indent, onChange }) => {
    const [isCollapsed, { toggle }] = useBoolean(node.state !== NodeState.Indeterminate);
    const styles = getIndentStyles(indent);
    const handleChange = useCallback((_?: unknown, checked?: boolean) => {
        onChange(node, !!checked);
    }, [node, onChange]);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const iconId = useId('callout-icon');
    const [ hasAncestors ] = useState(!!node.dataNode.ancestors);

    return (
        <>
            <Stack horizontal horizontalAlign={hasAncestors ? "space-between" : "start"} styles={styles} tokens={nodeStackToken}>
                <Stack horizontal tokens={nodeStackToken}>
                    {
                        node.children &&
                        <IconButton
                            iconProps={isCollapsed ? chevronRight : chevronDown}
                            onClick={toggle}
                            styles={buttonStyles}
                        />
                    }
                    <Checkbox
                        checked={node.state === NodeState.Checked}
                        indeterminate={node.state === NodeState.Indeterminate}
                        label={node.dataNode.title}
                        title={node.dataNode.title}
                        styles={node.children ? undefined : checkBoxWithNoChildStyles}
                        onChange={handleChange}
                    />
                </Stack>
                {
                    node.dataNode.ancestors && <Stack.Item>
                        <Icon onMouseOver={toggleIsCalloutVisible} onMouseLeave={toggleIsCalloutVisible} className={treeSelectStyles.calloutIcon} id={iconId} iconName={"Org"} />
                        {
                            isCalloutVisible && <HierarchyCallOut ancestors={node.dataNode.ancestors || []} toggleIsCallOutVisible={toggleIsCalloutVisible} buttonId={iconId} />
                        }
                    </Stack.Item>
                }
            </Stack>
            {
                !isCollapsed && node.children &&
                <>
                    {
                        node.children.map((c) => <TreeNode node={c} indent={indent + 1} key={c.dataNode.key} onChange={onChange} />)
                    }
                </>
            }
        </>
    );
};

const stackToken: IStackTokens = { childrenGap: 20 };

const TreeSelect: React.FC<TreeSelectProps> = ({ treeData, selectedValues, onChange }) => {
    const [nodeWrappers, selected] = useMemo(() => generateNodeWrappers(treeData, selectedValues), [treeData, selectedValues]);
    const handleChange = useCallback((node: NodeWrapper, checked: boolean) => {
        updateSelected(selected, node, checked);
        onChange(Array.from(selected.keys()), Array.from(selected.values()).map(x => x.dataNode));
    }, [onChange, selected]);
    return (
        <Stack tokens={stackToken}>
            {nodeWrappers.map((node) => <TreeNode node={node} indent={0} key={node.dataNode.key} onChange={handleChange} />)}
        </Stack>
    );
};

interface IHierarchyCallOutProps {
    ancestors: DataNode[];
    toggleIsCallOutVisible: () => void;
    buttonId: string;
}

const HierarchyCallOut: React.FC<IHierarchyCallOutProps> = (props) => {
    const labelId = useId('callout-label');
    const descriptionId = useId('callout-description');
    return (
        <Callout
            ariaLabelledBy={labelId}
            ariaDescribedBy={descriptionId}
            target={`#${props.buttonId}`}
            gapSpace={0}
            directionalHint={DirectionalHint.bottomRightEdge}
            className={treeSelectStyles.callout}
            onMouseLeave={props.toggleIsCallOutVisible}
        >
            {
                props.ancestors.map((item, index) => {
                    if (index != props.ancestors.length - 1) {
                        return (<span key={item.key}>{item.title} {'>'} </span>);
                    } else {
                        return (<span key={item.key}>{item.title}</span>);
                    }
                })
            }
        </Callout>
    );
}

function generateNodeWrappers(nodes: DataNode[], selectedValues: string[]): [NodeWrapper[], Map<string, NodeWrapper>] {
    function getStateFromChildren(node: NodeWrapper) {
        if (node.children && node.children.length) {
            if (node.checkedChildrenCount === node.children.length) return NodeState.Checked;
            if (node.indeterminateChildrenCount || node.checkedChildrenCount) return NodeState.Indeterminate;

        }
        return NodeState.Unchecked;
    }

    const initSet = new Set<string>(selectedValues);
    const resultMap = new Map<string, NodeWrapper>();
    function loop(nodes: DataNode[], parentChecked?: boolean, parent?: NodeWrapper): NodeWrapper[] {
        return nodes.map(n => {
            const checked = parentChecked || initSet.has(n.value);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const current: any = { dataNode: n, checkedChildrenCount: 0, indeterminateChildrenCount: 0, parent };
            const children = n.children ? loop(n.children, checked, current) : undefined;
            current.children = children;
            current.state = checked ? NodeState.Checked : getStateFromChildren(current);
            if (parent) {
                current.state === NodeState.Checked && ++parent.checkedChildrenCount;
                current.state === NodeState.Indeterminate && ++parent.indeterminateChildrenCount;
            }
            if (!parentChecked && current.state === NodeState.Checked) {
                resultMap.set(n.value, current);
            }
            return current;
        });
    }
    return [loop(nodes), resultMap];
}

function updateSelected(selected: Map<string, NodeWrapper>, toChange: NodeWrapper, checked: boolean) {
    function removeChildren(selected: Map<string, NodeWrapper>, node: NodeWrapper) {
        if (!node.children || node.state === NodeState.Unchecked) return;
        for (const n of node.children) {
            selected.delete(n.dataNode.value);
            removeChildren(selected, n);
        }
    }
    function shiftUp(selected: Map<string, NodeWrapper>, node: NodeWrapper, newState: NodeState) {
        if (newState === NodeState.Checked) {
            selected.set(node.dataNode.value, node);
        } else {
            selected.delete(node.dataNode.value);
        }

        if (node.parent) {
            const parent = node.parent;
            const newCheckedCount = parent.checkedChildrenCount + (newState === NodeState.Checked ? 1 : -1);
            const newIndeterminateChildrenCount = parent.indeterminateChildrenCount + (newState === NodeState.Indeterminate ? 1 : -1);
            const newParentState = newCheckedCount === parent.children?.length ? NodeState.Checked : (newIndeterminateChildrenCount || newCheckedCount ? NodeState.Indeterminate : NodeState.Unchecked);
            if (newParentState === parent.state) return;
            if (newParentState === NodeState.Checked) {
                for (const c of (parent.children || [])) {
                    selected.delete(c.dataNode.value);
                }
            }
            if (newParentState === NodeState.Indeterminate && parent.state === NodeState.Checked) {
                for (const c of (parent.children || [])) {
                    if (c !== node) {
                        selected.set(c.dataNode.value, c);
                    }
                }
            }
            shiftUp(selected, parent, newParentState);
        }
    }
    if (checked && toChange.state === NodeState.Checked) return;
    if (!checked && toChange.state === NodeState.Unchecked) return;
    removeChildren(selected, toChange);
    shiftUp(selected, toChange, checked ? NodeState.Checked : NodeState.Unchecked);
}

export default TreeSelect;