import * as React from "react";
import styles from "../Page.less";
import PageWrapper from "../PageWrapper";
import ResponseBoundary from "../../../ResponseBoundary/ResponseBoundary";
import {
    useGetArtifactRetentionFYCost,
    useGetManagerFYArtifactCost,
    useGetManagerMonthToMonthArtifactCost,
    useGetArtifactOverviewFYCost,
    useGetTotalFYArtifactCost,
} from "../../../../hooks/useES365CostQuery";
import TopManagerCostBarChart from "../../Charts/Common/TopManagerCostBarChart";
import ArtifactRetentionPieChart from "../../Charts/ESArtifact/ArtifactRetentionPieChart";
import ManagersMonthToMonthCostTable from "../../Charts/CloudBuild/ManagersMonthToMonthCostTable";
import ArtifactOverviewPieChart from "../../Charts/ESArtifact/ArtifactOverviewPieChart";
import { ESArtifactDataType } from "../../../../models/ESCostModels";
import TotalCostInCategoriesStackChart from "../../Charts/ESArtifact/TotalCostInCategoriesStackChart";

interface IProps {
    dataType?: ESArtifactDataType;
}

const ArtifactOverviewPage: React.FC<IProps> = (props) => {
    const { dataType } = props;
    const { data: esFYCost, isLoading, isError } = useGetArtifactOverviewFYCost();
    const { data: esCategoryCost, isLoading: isCategoryCostLoading, isError: isCategoryCostError } = useGetTotalFYArtifactCost();
    const { data: esManagerCost, isLoading: isManagerCostLoading, isError: isManagerCostError } = useGetManagerFYArtifactCost(dataType);
    const { data: esArtifactRetention, isLoading: isArtifactRetentionLoading, isError: isArtifactRetentionError } = useGetArtifactRetentionFYCost(dataType);
    const {
        data: esManagerMonthCost,
        isLoading: isManagerMonthCostLoading,
        isError: isManagerMonthCostError,
    } = useGetManagerMonthToMonthArtifactCost(dataType);

    const dataTypeString = dataType === ESArtifactDataType.Workload ? "workload" : "cost";
    const isCurrency = dataType !== ESArtifactDataType.Workload;
    return (
        <PageWrapper
            title={`Artifact ${dataTypeString} overview`}
            description={`Artifact ${dataTypeString} overview will be beneficial for understanding Artifact ${dataTypeString} better, seeking cost optimization opportunities, and making budget planning accordingly.`}
        >
            <div className={styles.grid}>
                <ResponseBoundary isLoading={isLoading} errorHappened={isError} data={esFYCost}>
                    {esFYCost !== undefined ? (
                        <ArtifactOverviewPieChart data={esFYCost} description="Total Artifact cost" totalCountDesc="Total Cost" isCurrency={true} />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
                <ResponseBoundary isLoading={isCategoryCostLoading} errorHappened={isCategoryCostError} data={esCategoryCost}>
                    {esCategoryCost !== undefined ? <TotalCostInCategoriesStackChart data={esCategoryCost} /> : <></>}
                </ResponseBoundary>
            </div>
            <div>
                <ResponseBoundary isLoading={isManagerCostLoading} errorHappened={isManagerCostError} data={esManagerCost}>
                    {esManagerCost !== undefined ? (
                        <TopManagerCostBarChart
                            data={esManagerCost}
                            description={`Top 10 GEMs on Artifact ${dataTypeString}`}
                            legendDesc={`Artifact ${dataTypeString}`}
                            isCurrency={isCurrency}
                            maxCount={10}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
                <ResponseBoundary isLoading={isArtifactRetentionLoading} errorHappened={isArtifactRetentionError} data={esArtifactRetention}>
                    {esArtifactRetention !== undefined ? (
                        <ArtifactRetentionPieChart
                            data={esArtifactRetention}
                            description={`Artifact ${dataTypeString} on retention`}
                            totalCountDesc={`Total ${dataTypeString}`}
                            isCurrency={isCurrency}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
            <div>
                <ResponseBoundary isLoading={isManagerMonthCostLoading} errorHappened={isManagerMonthCostError} data={esManagerMonthCost}>
                    {esManagerMonthCost !== undefined ? (
                        <ManagersMonthToMonthCostTable
                            data={esManagerMonthCost}
                            description={`Artifact ${dataTypeString} MoM comparison of all GEMs`}
                            isCurrency={isCurrency}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
        </PageWrapper>
    );
};

export default ArtifactOverviewPage;
