import { AzureComputeHeatmapKeys } from '../../common/AzureComputeConstants';
import commonStyles from './DetailTable.less';

export const AzureComputeDetailTableStyles = {
    container: commonStyles.tableContainer,
    bodyContainer: commonStyles.tableSectionContainer,
    headerContainer: commonStyles.tableSectionContainer,
    footerContainer: commonStyles.tableSectionContainer,
    stickyFooterContainer: commonStyles.stickyFooterContainer,
};


export const AzureComputeTableDefaultPageSize = 50;

export const AzureComputeDetailIdColumns = new Set<string>(['subscriptionId', 'deploymentId', 'serviceId']);

export const AzureComputeDetailNumericalColumns = new Set<string>([ 'cores', 'instances', 'costs', 'totalCores', 'averageCores', ...AzureComputeHeatmapKeys, 'isMock', 'isSpotVM' ]);

export const AzureComputeDetailTableOffset = { header: { offsetTop: 0 }, footer: { offsetBottom: 0 } };
