import { FiltersAction, FiltersView } from "../reducers/filterReducer";
import { IServiceTreeData, requestServiceTreeIfNeeded } from "../reducers/serviceTreeReducer";
import { LogComponent, LogElement, LogTarget } from "../models/LogModel";
import { Navigate, Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { Pages, SubPages } from "../models/Nav";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { useDispatch, useSelector } from "react-redux";

import { AddProjectTypeEnum } from "../models/EfficiencyTracker";
import AddProjectView from "./EfficiencyTrackerV2/Views/Project/AddProjectView";
import { ArtifactDetailsContainer } from "./ESCost/Views/ESArtifact/ArtifactDetailsContainer";
import { ArtifactOverviewContainer } from "./ESCost/Views/ESArtifact/ArtifactOverviewContainer";
import AuthView from "./auth/AuthView";
import { Azure } from "./azure/Azure";
import { AzureDetailsContainer } from "./ESCost/Views/Azure/AzureDetailsContainer";
import { AzureOverviewContainer } from "./ESCost/Views/Azure/AzureOverviewContainer";
import { AzureSubPage } from "./azure/subpage/AzureSubPage";
import BigBetsAddProjectView from "./EfficiencyTrackerV2/Views/Project/BigBets/BigBetsAddProjectView";
import BigBetsLandingPageView from "./EfficiencyTrackerV2/Views/Project/BigBets/BigBetsLandingPageView";
import BigBetsSubProjectView from "./EfficiencyTrackerV2/Views/Project/BigBets/BigBetsSubProjectView";
import { Budget } from "./finops/Budget";
import { CloudBuildGemContainer } from "./ESCost/Views/CloudBuild/CloudBuildGemContainer";
import { CloudBuildOverviewContainer } from './ESCost/Views/CloudBuild/CloudBuildOverviewContainer';
import { CloudBuildUsageScenarioContainer } from "./ESCost/Views/CloudBuild/CloudBuildUsageScenarioContainer";
import { CogsCalculator } from "./cogsCalculator/CogsCalculator";
import { CosmicSourceTypeEnum } from "../models/CosmicModels";
import CosmicView from "./cosmic/CosmicView";
import { CosmosSourceTypeEnum } from "../models/CosmosModels";
import CosmosView from "./cosmos/CosmosView";
import ESCostPageLayout from "./ESCost/PageLayout/ESCostPageLayout";
import ETSubProjectViewV2 from "./EfficiencyTrackerV2/Views/Project/ETSubProjectView";
import { IAppState } from "../store";
import { InsightsPortal } from "./InsightPlatform/InsightPortal/InsightsPortal";
import { LandingPage } from "./landingPage/LandingPage";
import LandingPageView from "./EfficiencyTrackerV2/Views/Project/LandingPageView";
import { OverviewContainer } from "./ESCost/Views/OverviewContainer";
import PageLayout from "./PageLayout";
import PartnerView from "./cosmic/PartnerView";
import ReviewBudget from "./finops/ReviewBudget";
import SQLToolView from "./sqltool/SQLToolView";
import { SearchKey } from "../models/SearchKey";
import SsdCostView from "./substrate/Ssd/Ssd";
import Substrate from "./substrate/Substrate";
import { SubstrateHdd } from "./substrateV2/hdd/SubstrateHdd";
import { SubstrateNetwork } from "./substrateV2/network/SubstrateNetwork";
import { SubstrateOverview } from "./substrateV2/overview/SubstrateOverview";
import { SubstratePlatform } from "./substrateplatform/SubstratePlatform";
import { SubstrateProcessHosting } from "./substrateV2/processHosting/SubstrateProcessHosting";
import { SubstrateSsd } from "./substrateV2/ssd/SubstrateSsd";
import { SubstrateTransaction } from "./substrateV2/transaction/SubstrateTransaction";
import { TelemetrySourceTypeEnum } from "../models/TelemetryCogsModel";
import TelemetryView from "./Telemetry/TelemetryView";
import TrackerLayoutV2 from "./EfficiencyTrackerV2/PageLayout/TrackerLayout";
import TransactionCostView from "./substrate/Transaction/TransactionCostView";
import { UnsubscribeCard } from "./monthlyReport/UnsubscribePage";
import { getPageDetailById } from "../services/viewService";
import { loadAllViewsIfNeeded } from "../reducers/savedViewReducer";
import { makeGlobalFiltersFromView } from "../utils/FiltersUtils";
import moment from "moment";
import styles from "./App.less";
import { trackEventCallback } from "../utils/AppInsights";
import { useCategoryFilters } from "../hooks/useFilters";
import { useCustomQueryClient } from "../hooks/useCustomQuery";
import { useDateRange } from "../hooks/useDateSelector";
import { useFlights } from "../hooks/useSettings";
import { FinOpsReport } from "./finops/FinOpsReport";

const App: React.FC = () => {
    const queryClient = useCustomQueryClient();
    const [searchParams] = useSearchParams();
    const updateFilters = useCategoryFilters().updateFilters;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hasViewId = useMemo(() => searchParams.has(SearchKey.ViewId), [searchParams]);
    const prevViewIdRef = useRef<string>();
    const hasDateRange = useMemo(() => searchParams.has(SearchKey.StartDate) && searchParams.has(SearchKey.EndDate), [searchParams]);
    const { setDateRange } = useDateRange();
    const { data: flights } = useFlights();
    const defaultPage = flights?.enableLandingPage ? Pages.LandingPage : (flights?.enableSubstrateV2 ? Pages.SubstrateV2 : Pages.Substrate);
    const serviceTree = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const [ skipLoadServiceTree, setskipLoadServiceTree ] = useState<boolean>(false);

    useEffect(() => {
        if (hasViewId) {
            const viewId = searchParams.get(SearchKey.ViewId);

            if (!viewId || viewId === prevViewIdRef.current) return;

            prevViewIdRef.current = viewId;

            getPageDetailById(viewId).then((response) => {
                setDateRange(moment(response.startDate), moment(response.endDate));
                updateFilters(FiltersAction.Replace, { filters: makeGlobalFiltersFromView(response), view: FiltersView.AddableList });
            });
        } else {
            const pathsToSkipDateRange = [
                Pages.EfficiencyTrackerV2,
                Pages.EfficiencyTracker,
                Pages.Budget
            ];
            const currentPath = window.location.hash;
            if (!hasDateRange && !pathsToSkipDateRange.some(path => currentPath.includes(path))) {
                setDateRange(moment().subtract(37, "days"), moment().subtract(7, "days"));
            }
        }
    }, [dispatch, hasDateRange, hasViewId, searchParams, setDateRange, updateFilters]);

    useEffect(() => {
        trackEventCallback(LogComponent.Url, LogElement.All, "Page load duration", LogTarget.Nav, {
            duration: performance.getEntriesByName("first-contentful-paint")[0]?.startTime.toFixed(0),
        });
        trackEventCallback(LogComponent.Url, LogElement.All, "Page start time", LogTarget.Nav, { timestamp: performance.timeOrigin });
    }, []);

    useEffect(() => {
        if (!flights) {
            return;
        }

        //Skip loading Service Tree
        const pathsToSkipDateRange = [Pages.EfficiencyTrackerV2, Pages.EfficiencyTracker];
        const currentPath = window.location.hash;
        if (!pathsToSkipDateRange.some(path => currentPath.includes(path))) {
            dispatch(requestServiceTreeIfNeeded(flights, queryClient));
            dispatch(loadAllViewsIfNeeded(flights));
        } else {
            setskipLoadServiceTree(true);
        }
    }, [flights]);

    useEffect(() => {
        if (!hasViewId) return;
        if (localStorage.getItem("hashRoutes")) {
            const hashRoutes = localStorage.getItem("hashRoutes");
            localStorage.removeItem("hashRoutes");
            hashRoutes && navigate(hashRoutes);

            if (hashRoutes?.includes("?")) return;
        }
    }, [dispatch, hasViewId, navigate]);

    if (!flights || (!skipLoadServiceTree && !serviceTree.serviceTree && !serviceTree.errorHappened)) {
        return <><Spinner size={SpinnerSize.large} style={{ height: "100%" }} /></>;
    }

    return !hasViewId && hasDateRange ? (
        <Routes>
            {flights.enableSubstrateV1 && (
                <>
                    <Route
                        path={Pages.Substrate}
                        element={
                            <PageLayout>
                                <Substrate />
                            </PageLayout>
                        }
                    />
                    <Route
                        path={`${Pages.Substrate}/${SubPages.Transaction}`}
                        element={
                            <PageLayout>
                                <TransactionCostView />
                            </PageLayout>
                        }
                    />
                    <Route
                        path={`${Pages.Substrate}/${SubPages.Transaction}/:tab`}
                        element={
                            <PageLayout>
                                <TransactionCostView />
                            </PageLayout>
                        }
                    />
                </>
            )}
            {flights?.enableSubstrateV2 && (
                <>
                    {flights?.enableLandingPage && <Route
                        path={Pages.LandingPage}
                        element={
                            <PageLayout containerClassName={styles.landingPageContainer}>
                                <LandingPage />
                            </PageLayout>
                        }
                    />}
                    {flights?.enablePlatformCogsCalculator && <Route
                        path={Pages.CogsCalculator}
                        element={
                            <PageLayout containerClassName={styles.landingPageContainer}>
                                <CogsCalculator />
                            </PageLayout>
                        }
                    />}
                    <Route
                        path={`${Pages.InsightPortal}/${SubPages.InsightPortalOverview}`}
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <InsightsPortal />
                            </PageLayout>
                        }
                    />
                    <Route
                        path={`${Pages.InsightPortal}/${SubPages.InsightPortalOverview}/:tab`}
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <InsightsPortal />
                            </PageLayout>
                        }
                    />
                    <Route
                        path={Pages.SubstrateV2}
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <SubstrateOverview />
                            </PageLayout>
                        }
                    />
                    <Route 
                        path={Pages.UnsubscribeMonthlyReport}
                        element={
                            <UnsubscribeCard/>
                        }
                    />
                    <Route
                        path={`${Pages.SubstrateV2}/${SubPages.Transaction}`}
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <SubstrateTransaction />
                            </PageLayout>
                        }
                    />
                    <Route
                        path={`${Pages.SubstrateV2}/${SubPages.Transaction}/:tab`}
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <SubstrateTransaction />
                            </PageLayout>
                        }
                    />
                    <Route
                        path={`${Pages.SubstrateV2}/${SubPages.ProcessHosting}`}
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <SubstrateProcessHosting />
                            </PageLayout>
                        }
                    />
                    <Route
                        path={`${Pages.SubstrateV2}/${SubPages.Network}`}
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <SubstrateNetwork />
                            </PageLayout>
                        }
                    />
                    <Route
                        path={`${Pages.SubstrateV2}/${SubPages.Network}/:tab`}
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <SubstrateNetwork />
                            </PageLayout>
                        }
                    />
                    <Route
                        path={`${Pages.SubstrateV2}/${SubPages.Hdd}`}
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <SubstrateHdd />
                            </PageLayout>
                        }
                    />
                    <Route
                        path={`${Pages.SubstrateV2}/${SubPages.Hdd}/:tab`}
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <SubstrateHdd />
                            </PageLayout>
                        }
                    />
                    <Route
                        path={`${Pages.SubstrateV2}/${SubPages.Ssd}`}
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <SubstrateSsd />
                            </PageLayout>
                        }
                    />
                    <Route
                        path={`${Pages.SubstrateV2}/${SubPages.Ssd}/:tab`}
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <SubstrateSsd />
                            </PageLayout>
                        }
                    />
                </>
            )}

            <Route
                path={`${Pages.Substrate}/${SubPages.Ssd}`}
                element={
                    <PageLayout>
                        <SsdCostView />
                    </PageLayout>
                }
            />
            <Route
                path={`${Pages.Substrate}/${SubPages.Ssd}/:tab`}
                element={
                    <PageLayout>
                        <SsdCostView />
                    </PageLayout>
                }
            />
            <Route
                path={Pages.SubstratePlatform}
                element={
                    <PageLayout>
                        <SubstratePlatform />
                    </PageLayout>
                }
            />
            <Route
                path={Pages.Azure}
                element={
                    <PageLayout>
                        <Azure />
                    </PageLayout>
                }
            />
            <Route
                path={`${Pages.Azure}/:tab`}
                element={
                    <PageLayout>
                        <Azure />
                    </PageLayout>
                }
            />

            <Route
                path={`${Pages.Azure}/${SubPages.Public}`}
                element={
                    <PageLayout>
                        <AzureSubPage pageTitle="Azure Compute - Public Cost View" type="Public" />
                    </PageLayout>
                }
            />
            <Route
                path={`${Pages.Azure}/${SubPages.Public}/:tab`}
                element={
                    <PageLayout>
                        <AzureSubPage pageTitle="Azure Compute - Public Cost View" type="Public" />
                    </PageLayout>
                }
            />

            <Route
                path={`${Pages.Azure}/${SubPages.AzSC}`}
                element={
                    <PageLayout>
                        <AzureSubPage pageTitle="Azure Compute - AzSC Cost View" type="AZSC" />
                    </PageLayout>
                }
            />
            <Route
                path={`${Pages.Azure}/${SubPages.AzSC}/:tab`}
                element={
                    <PageLayout>
                        <AzureSubPage pageTitle="Azure Compute - AzSC Cost View" type="AZSC" />
                    </PageLayout>
                }
            />

            {!flights.enableTelemetryCostFlight && (
                <>
                    <Route
                        path="/COSMOS"
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <CosmosView type={CosmosSourceTypeEnum.All} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/COSMOS/Storage"
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <CosmosView type={CosmosSourceTypeEnum.Storage} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/COSMOS/Processing"
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <CosmosView type={CosmosSourceTypeEnum.Processing} />
                            </PageLayout>
                        }
                    />
                </>
            )}

            {flights.enableTelemetryCostFlight && (
                <>
                    <Route
                        path="/Telemetry"
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <TelemetryView type={TelemetrySourceTypeEnum.All} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/Telemetry/Cosmos"
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <TelemetryView type={TelemetrySourceTypeEnum.Cosmos} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/Telemetry/BlueShift"
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <TelemetryView type={TelemetrySourceTypeEnum.Blueshift} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/Telemetry/Kusto"
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <TelemetryView type={TelemetrySourceTypeEnum.Kusto} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/Telemetry/GenevaMDS"
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <TelemetryView type={TelemetrySourceTypeEnum.GenevaMds} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/Telemetry/GenevaMDM"
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <TelemetryView type={TelemetrySourceTypeEnum.GenevaMdm} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/Telemetry/Aria"
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <TelemetryView type={TelemetrySourceTypeEnum.Aria} />
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/Telemetry/PassiveMonitoring"
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <TelemetryView type={TelemetrySourceTypeEnum.PassiveMon} />
                            </PageLayout>
                        }
                    />
                </>
            )}

            {flights?.enableCosmicPartnerViewFlight && (
                <>
                    <Route
                        path="/COSMIC"
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <CosmicView type={CosmicSourceTypeEnum.All}/>
                            </PageLayout>
                        }
                    />
                    <Route
                        path="/COSMIC/Partner"
                        element={
                            <PageLayout containerClassName={styles.container}>
                                <CosmicView type={CosmicSourceTypeEnum.PartnerView} />
                            </PageLayout>
                        }
                    />
                </>)}

            <Route
                path="/Auth"
                element={
                    <PageLayout>
                        <AuthView />
                    </PageLayout>
                }
            />
            <Route
                path="/SQLTool"
                element={
                    <PageLayout>
                        <SQLToolView />
                    </PageLayout>
                }
            />
            {flights?.enableES365CostTransparency && (
                <>
                    <Route
                        path={Pages.ESCost}
                        element={
                            <ESCostPageLayout>
                                <OverviewContainer />
                            </ESCostPageLayout>
                        }
                    />
                    {flights?.enableES365CostCloudBuild && (
                        <>
                            <Route
                                path={`${Pages.ESCost}/${Pages.ESCostCloudBuild}`}
                                element={
                                    <ESCostPageLayout>
                                        <CloudBuildOverviewContainer />
                                    </ESCostPageLayout>
                                }
                            />
                            <Route
                                path={`${Pages.ESCost}/${Pages.ESCostCloudBuildGEMs}`}
                                element={
                                    <ESCostPageLayout>
                                        <CloudBuildGemContainer />
                                    </ESCostPageLayout>
                                }
                            />
                            <Route
                                path={`${Pages.ESCost}/${Pages.ESCostCloudBuildUsageScenarios}`}
                                element={
                                    <ESCostPageLayout>
                                        <CloudBuildUsageScenarioContainer />
                                    </ESCostPageLayout>
                                }
                            />
                        </>)}
                    {flights?.enableES365CostArtifacts && (
                        <>
                            <Route
                                path={`${Pages.ESCost}/${Pages.ESCostArtifact}`}
                                element={
                                    <ESCostPageLayout>
                                        <ArtifactOverviewContainer />
                                    </ESCostPageLayout>
                                }
                            />
                            <Route
                                path={`${Pages.ESCost}/${Pages.ESCostArtifactDetails}`}
                                element={
                                    <ESCostPageLayout>
                                        <ArtifactDetailsContainer />
                                    </ESCostPageLayout>
                                }
                            />
                        </>)}
                    {flights?.enableES365CostAzure && (
                        <>
                            <Route
                                path={`${Pages.ESCost}/${Pages.ESCostAzure}`}
                                element={
                                    <ESCostPageLayout>
                                        <AzureOverviewContainer />
                                    </ESCostPageLayout>
                                }
                            />
                            <Route
                                path={`${Pages.ESCost}/${Pages.ESCostAzureDetails}`}
                                element={
                                    <ESCostPageLayout>
                                        <AzureDetailsContainer />
                                    </ESCostPageLayout>
                                }
                            />
                        </>)}
                </>
            )}
            <Route path="/" element={<Navigate to={`${defaultPage}?${searchParams.toString()}`} replace />} />
            <Route path="*" element={<Navigate to={`${defaultPage}?${searchParams.toString()}`} replace />} />
        </Routes>
    ) : (
            <Routes>
            <Route
                path={Pages.EfficiencyTracker}
                    element={
                        <Navigate to={`${Pages.EfficiencyTrackerV2}/${Pages.AllProjects}/${Pages.BaremetalTracker}`} replace={true} />
                    }
            />
            <Route
                path={Pages.EfficiencyTrackerV2}
                element={
                    <Navigate to={`${Pages.EfficiencyTrackerV2}/${Pages.AllProjects}/${Pages.BaremetalTracker}`} replace={true} />
                }
            />
            <Route
                path={`${Pages.EfficiencyTrackerV2}/*`}
                element={
                    <TrackerLayoutV2>
                        <Routes>
                            <Route
                                path={`${Pages.MyProjects}/*`}
                                element={
                                    <Routes>
                                        <Route
                                            path={`${Pages.BaremetalTracker}`}
                                            element={
                                                <LandingPageView IsMyProject={true} IsBaremental={true} />
                                            }
                                        />
                                        <Route
                                            path={`${Pages.BaremetalTracker}/*`}
                                            element={
                                                <Routes>
                                                    <Route
                                                        path={`${Pages.EFSubPage}/:planId`}
                                                        element={
                                                            <ETSubProjectViewV2 />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFAddProjectPage}`}
                                                        element={
                                                            <AddProjectView mode={AddProjectTypeEnum.AddProject} />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFAddPlanPage}/:projectId`}
                                                        element={
                                                            <AddProjectView mode={AddProjectTypeEnum.AddPlan} />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFEditPage}/:planId`}
                                                        element={
                                                            <AddProjectView mode={AddProjectTypeEnum.EditProject} />
                                                        }
                                                    />
                                                </Routes>
                                            }
                                        />
                                        <Route
                                            path={`${Pages.VirtualizedTracker}`}
                                            element={
                                                <LandingPageView IsMyProject={true} IsBaremental={false} />
                                            }
                                        />
                                        <Route
                                            path={`${Pages.VirtualizedTracker}/*`}
                                            element={
                                                <Routes>
                                                    <Route
                                                        path={`${Pages.EFSubPage}/:planId`}
                                                        element={
                                                            <ETSubProjectViewV2 />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFAddProjectPage}`}
                                                        element={
                                                            <AddProjectView mode={AddProjectTypeEnum.AddProject} />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFAddPlanPage}/:projectId`}
                                                        element={
                                                            <AddProjectView mode={AddProjectTypeEnum.AddPlan} />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFEditPage}/:planId`}
                                                        element={
                                                            <AddProjectView mode={AddProjectTypeEnum.EditProject} />
                                                        }
                                                    />
                                                </Routes>
                                            }
                                        />
                                        <Route
                                            path={`${Pages.BigBetsTracker}`}
                                            element={
                                                <BigBetsLandingPageView IsMyProject={true} />
                                            }
                                        />
                                        <Route
                                            path={`${Pages.BigBetsTracker}/*`}
                                            element={
                                                <Routes>
                                                    <Route
                                                        path={`${Pages.EFSubPage}/:projectId`}
                                                        element={
                                                            <BigBetsSubProjectView />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFAddProjectPage}`}
                                                        element={
                                                            <BigBetsAddProjectView mode={AddProjectTypeEnum.AddProject} />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFEditPage}/:projectId`}
                                                        element={
                                                            <BigBetsAddProjectView mode={AddProjectTypeEnum.EditProject} />
                                                        }
                                                    />
                                                </Routes>
                                            }
                                        />
                                    </Routes>
                                }
                            />
                            <Route
                                path={`${Pages.AllProjects}/*`}
                                element={
                                    <Routes>
                                        <Route
                                            path={`${Pages.BaremetalTracker}`}
                                            element={
                                                <LandingPageView IsMyProject={false} IsBaremental={true} />
                                            }
                                        />
                                        <Route
                                            path={`${Pages.BaremetalTracker}/*`}
                                            element={
                                                <Routes>
                                                    <Route
                                                        path={`${Pages.EFSubPage}/:planId`}
                                                        element={
                                                            <ETSubProjectViewV2 />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFAddProjectPage}`}
                                                        element={
                                                            <AddProjectView mode={AddProjectTypeEnum.AddProject} />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFAddPlanPage}/:projectId`}
                                                        element={
                                                            <AddProjectView mode={AddProjectTypeEnum.AddPlan} />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFEditPage}/:planId`}
                                                        element={
                                                            <AddProjectView mode={AddProjectTypeEnum.EditProject} />
                                                        }
                                                    />
                                                </Routes>
                                            }
                                        />
                                        <Route
                                            path={`${Pages.VirtualizedTracker}`}
                                            element={
                                                <LandingPageView IsMyProject={false} IsBaremental={false} />
                                            }
                                        />
                                        <Route
                                            path={`${Pages.VirtualizedTracker}/*`}
                                            element={
                                                <Routes>
                                                    <Route
                                                        path={`${Pages.EFSubPage}/:planId`}
                                                        element={
                                                            <ETSubProjectViewV2 />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFAddProjectPage}`}
                                                        element={
                                                            <AddProjectView mode={AddProjectTypeEnum.AddProject} />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFAddPlanPage}/:projectId`}
                                                        element={
                                                            <AddProjectView mode={AddProjectTypeEnum.AddPlan} />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFEditPage}/:planId`}
                                                        element={
                                                            <AddProjectView mode={AddProjectTypeEnum.EditProject} />
                                                        }
                                                    />
                                                </Routes>
                                            }
                                        />
                                        <Route
                                            path={`${Pages.BigBetsTracker}`}
                                            element={
                                                <BigBetsLandingPageView IsMyProject={false} />
                                            }
                                        />
                                        <Route
                                            path={`${Pages.BigBetsTracker}/*`}
                                            element={
                                                <Routes>
                                                    <Route
                                                        path={`${Pages.EFSubPage}/:projectId`}
                                                        element={
                                                            <BigBetsSubProjectView />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFAddProjectPage}`}
                                                        element={
                                                            <BigBetsAddProjectView mode={AddProjectTypeEnum.AddProject} />
                                                        }
                                                    />
                                                    <Route
                                                        path={`${Pages.EFEditPage}/:projectId`}
                                                        element={
                                                            <BigBetsAddProjectView mode={AddProjectTypeEnum.EditProject} />
                                                        }
                                                    />
                                                </Routes>
                                            }
                                        />
                                    </Routes>
                                }
                            />
                        </Routes>
                    </TrackerLayoutV2>
                }
            />

            <Route path={`${Pages.Budget}/${Pages.SubmitBudget}`} element={<Budget />} />
            <Route path={`${Pages.Budget}/${Pages.ReviewBudget}`} element={<ReviewBudget />} />
            <Route path={`${Pages.Budget}/${Pages.Report}`} element={<Budget showReport />} />
            <Route path={Pages.Budget} element={<Budget />} />
        </Routes>
    );
};

export default App;
