import { Calendar, Callout, Icon, IStackTokens, Stack, Text } from "@fluentui/react";
import { useId, useBoolean } from "@fluentui/react-hooks";
import moment from "moment";
import * as React from "react";

const cardTokens: IStackTokens = {childrenGap: 8};
interface ISingleDatePickerProps {
    selectedDate: moment.Moment;
    onSelectDate: (date: moment.Moment) => void;
    maxDateOffset?: number;
}

export const SingleDatePicker: React.FC<ISingleDatePickerProps> = (props) => {
    const buttonId = useId("button-id");
    const [isCalloutVisible, {toggle: toggleCallout, setFalse: dismissCallout}] = useBoolean(false);
    const maxDateOffset = props.maxDateOffset ? props.maxDateOffset : 7;
    const maxEndDate = React.useMemo(() => moment().subtract(maxDateOffset, 'day').toDate(), [maxDateOffset]);

    return (
        <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <Stack
                horizontal
                verticalAlign="center"
                tokens={cardTokens}
                onClick={toggleCallout}
                id={buttonId}
                styles={{root: {cursor: "pointer"}}}
            >
                <Icon iconName="Calendar" />
                <Text>{`UTC Date: ${props.selectedDate.format("YYYY-MM-DD")}`}</Text>
                <Icon iconName="ChevronDown" />
            </Stack>
            {
                isCalloutVisible &&
                <Callout
                    target={`#${buttonId}`}
                    onDismiss={dismissCallout}
                >
                    <Calendar
                        value={props.selectedDate.toDate()}
                        onSelectDate={(date) => props.onSelectDate(moment(date))}
                        maxDate={maxEndDate}
                    />
                </Callout>
            }
        </Stack>
    )
}