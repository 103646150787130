// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SVbzbBJ0zENag9PMDFkl {\n  width: 100%;\n  border: none;\n}\n.sbacD593RIT36jt16Ddg {\n  text-align: left;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/NumberPicker.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;AACJ;AAEA;EACI,gBAAA;AAAJ","sourcesContent":[".item {\n    width: 100%;\n    border: none;\n}\n\n.label {\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "SVbzbBJ0zENag9PMDFkl",
	"label": "sbacD593RIT36jt16Ddg"
};
export default ___CSS_LOADER_EXPORT___;
