import { useCallback, useMemo } from "react";

import { ICosmicSearchFilters } from "../models/FilterView";
import { SearchKey } from "../models/SearchKey";
import { debounce } from "lodash";
import { useSearchParams } from "react-router-dom";

export function useCosmicFilters(filterKey: string) {
    const [searchParams, setSearchParams] = useSearchParams();

    const getCosmicFiltersJson = useCallback((): ICosmicSearchFilters => {
        const filterStr = searchParams.get(SearchKey.CosmicSearchFilter);
        return filterStr ? JSON.parse(filterStr) : {};
    }, [searchParams]);

    const cosmicSearchFilters = useMemo(() => searchParams.get(SearchKey.CosmicSearchFilter), [searchParams]);

    const selectedKeys = useMemo(() => {
        const cosmicFiltersJson = getCosmicFiltersJson();
        return cosmicFiltersJson[filterKey as keyof ICosmicSearchFilters] || [];
    }, [filterKey, getCosmicFiltersJson]);

    const cosmicSearchFiltersWithoutCurrentSelectedKeys = useMemo(() => {
        const cosmicFiltersJson = { ...getCosmicFiltersJson(), [filterKey]: [] };
        return JSON.stringify(cosmicFiltersJson);
    }, [filterKey, getCosmicFiltersJson]);

    const debouncedSetSearchParams = useMemo(
        () =>
            debounce((newCosmicFiltersJson: ICosmicSearchFilters) => {
                setSearchParams({
                    ...Object.fromEntries(searchParams),
                    [SearchKey.CosmicSearchFilter]: JSON.stringify(newCosmicFiltersJson),
                });
            }, 300),
        [searchParams, setSearchParams]
    );

    const clearSearchParams = useCallback(() => {
        searchParams.delete(SearchKey.CosmicSearchFilter);
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    const setSelectedKeys = useCallback(
        (newValues: string[]) => {
            const cosmicFiltersJson = getCosmicFiltersJson();

            if (newValues.length === 0) {
                delete cosmicFiltersJson[filterKey as keyof ICosmicSearchFilters];

                if (Object.keys(cosmicFiltersJson).length === 0) {
                    clearSearchParams();
                } else {
                    debouncedSetSearchParams(cosmicFiltersJson);
                }
            } else {
                cosmicFiltersJson[filterKey as keyof ICosmicSearchFilters] = newValues;
                debouncedSetSearchParams(cosmicFiltersJson);
            }
        },
        [filterKey, getCosmicFiltersJson, clearSearchParams, debouncedSetSearchParams]
    );

    return {
        selectedKeys,
        cosmicSearchFilters,
        cosmicSearchFiltersWithoutCurrentSelectedKeys,
        setSelectedKeys,
    };
}
