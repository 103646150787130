import React from "react";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import {
    ESCostDataDimensions,
    ESCostOrganization,
    ESOverviewTimeSelection,
    ESWeeklyBuildStageCostRawResponse,
} from "../../../../models/ESCostModels";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import ChartContainer from "../../../chart/ChartContainer";
import { useEsOrg } from "../../../../hooks/useEsOrg";
import { ESOrgs } from "../../../../models/constants/ESCostConstants";
import moment from "moment";
import { LineChartSeries } from "../../../common/LineChart";
import CommonConstants from "../../../../models/constants/CommonConstants";

interface IBuildStageWeeklyTrendProps {
    data: ESWeeklyBuildStageCostRawResponse[];
}

function getDates(data: ESWeeklyBuildStageCostRawResponse[], fy?: ESOverviewTimeSelection): string[] {
    const dates = fy === ESOverviewTimeSelection.LastFY ? ESCostUtils.getLastFiscalYearDates() : ESCostUtils.getCurrentFiscalYearDates();
    return [
        ...new Set(
            data
                .filter((d) => {
                    const date = moment(d[ESCostDataDimensions.TimeStamp]);
                    return date >= dates[0] && date < dates[1];
                })
                .map((d) => d[ESCostDataDimensions.TimeStamp])
        ),
    ].sort();
}

function calculateSeries(
    org: ESCostOrganization[],
    dates: string[],
    data: ESWeeklyBuildStageCostRawResponse[]
): LineChartSeries[] {
    const costs = data.filter(
        (cost) =>
            ESOrgs.includes(cost[ESCostDataDimensions.Organization]) &&
            (org.length === 0 || org.includes(cost[ESCostDataDimensions.Organization])) &&
            dates.includes(cost[ESCostDataDimensions.TimeStamp])
    );

    const buildStages = [...new Set(costs.map((cost) => cost[ESCostDataDimensions.BuildStage]))];
    buildStages.sort();

    const d: { stage: string, values: [number, number][] }[] = Array.from(buildStages, (stage) => {
        const valueList: [number, number][] = Array.from(dates, (date) => ([moment(date).valueOf(), 0]));
        return { stage, values: valueList };
    });

    costs.forEach((cost) => {
        const i = buildStages.indexOf(cost[ESCostDataDimensions.BuildStage]);
        const j = dates.indexOf(cost[ESCostDataDimensions.TimeStamp]);
        d[i].values[j][1] += cost[ESCostDataDimensions.Cost];
    });

    return d.map((obj, index) => {
        return {
            name: obj.stage,
            data: obj.values,
            showDeviation: false,
            color: CommonConstants.DefaultColors[index],
        };
    });
}

export const BuildStageCostWeeklyTrend: React.FC<IBuildStageWeeklyTrendProps> = (props) => {
    const { data } = props;
    const [org] = useEsOrg();
    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );
    const dates = React.useMemo(() => getDates(data, timeRange), [data, timeRange]);
    const series: LineChartSeries[] = React.useMemo(() => calculateSeries(org, dates, data), [org, dates, data]);

    return (
        <ChartContainer
            headerProps={{ title: `Weekly CloudBuild cost on build stages (FY${String(year).substring(2)})` }}
            chartType="line"
            chartProps={{
                isNotCurrency: false,
                ignoreWeekend: false,
                height: 220,
                series,
                hideEmptySeries: true,
                dateFormatStr: "MMM DD, YYYY",
                tooltipDateFormatStr: "MMM DD, YYYY",
            }}
        />
    );
};
