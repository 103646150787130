import React, { useMemo } from 'react';
import { FilterGroupOperator } from './FilterListConstants';
import { FilterItem } from './FilterItem';
import { FilterListAddBtn } from './FilterListAddBtn';
import styles from './FilterList.less';

export const enum FilterType {
    Boolean,
    Number,
    String,
}

export interface IFilterItem {
    key: string;
    operator: FilterGroupOperator;
    value?: string;
    keyword?: string;
    keywords?: string[];
}

export interface IFilterListProps {
    data: IFilterItem[];
    options: IFilterOption[];
    onChange: (filters: IFilterItem[]) => void;
    validator?: (key: string, value: string, filterType: FilterType) => string | null;
    className?: string;
}

export interface IFilterOption {
    key: string;
    displayName: string;
    type: FilterType;
}

export const FilterList : React.FC<IFilterListProps> = (props: IFilterListProps) => {
    const handleDeleteItem = (index: number) => {
        const newList = [...props.data];
        newList.splice(index, 1);
        props.onChange(newList);
    };

    const handleUpdateItem = (data: IFilterItem, index: number) => {
        const newList = [...props.data];
        newList[index] = data;
        props.onChange(newList);
    };

    const handleAddItem = (data: IFilterItem) => {
        props.onChange([...props.data, data]);
    };

    const filterKeyDisplayNames = useMemo(() => {
        return props.options.reduce<Record<string, string>>((acc, cur) => {
            acc[cur.key] = cur.displayName;
            return acc;
        }, {});
    }, [props.options]);

    const filterTypes = useMemo(() => {
        return props.options.reduce<Record<string, FilterType>>((acc, cur) => {
            acc[cur.key] = cur.type;
            return acc;
        }, {});
    }, [props.options]);

    return (
        <div className={`${styles.filterList} ${props.className || ""}`}>
            <div className={styles.filterListPrompt}>Filters:</div>
            {props.data.map((item, index) => (
                <FilterItem
                    validator={props.validator}
                    key={`${item.key}${item.operator}${item.value || item.keyword}`}
                    data={item}
                    displayName={filterKeyDisplayNames[item.key]}
                    type={filterTypes[item.key]}
                    onDelete={() => handleDeleteItem(index)}
                    onUpdate={(data) => handleUpdateItem(data, index)}
                />))}
            <FilterListAddBtn validator={props.validator} options={props.options} onAdd={handleAddItem}/>
        </div>
    )
};

const NumberRegExp = /^[0-9]\d*(\.\d+)?$/;

FilterList.defaultProps = {
    validator: (key: string, value: string, filterType: FilterType) => {
        let error = null;

        if (filterType === FilterType.String) {
            return null;
        } if (value.length > 10) {
            error = '10 characters over the limit';
        } else if (value.length === 0 || !NumberRegExp.test(value)) {
            error = 'Please input a valid number';
        }

        return error;
    }
}