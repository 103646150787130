import styles from './Table.less';

import React, { useEffect, useState } from "react";
import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from "@fluentui/react";
import { currencyFormatterNegativeFirst } from '../../../../../utils/currency';
import { formatValue } from '../../Tools/ExportFunction';
import { EFTrackerBigBetsProjectHistory } from '../../../../../models/EfficiencyTrackerBigBets';
import { EfficiencyStatusEnum } from '../../../../../models/EfficiencyTracker';
import EfficiencyStatus from '../../Tools/EfficiencyStatus';

interface MonthlyDataItem {
    Month: string;
    Target: number;
    Actual: number;
    Confidence: string;
    ExecutionStatus: string;
}

const maxWidthMonth = 125;

const columnKeys = {
    Date: 'Date',
    Target: 'Target',
    Actual: 'Actual',
    Gap: 'Gap',
    Confidence: 'Confidence',
    ExecutionStatus: 'ExecutionStatus'
};

const columns: IColumn[] = [
    {
        key: columnKeys.Date, name: 'Date', minWidth: 50, maxWidth: 70, isResizable: true, className: styles.PrimaryColumn,
    },
    {
        key: columnKeys.Target, name: 'Target Impact', minWidth: 50, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.Actual, name: 'Actual Impact', minWidth: 50, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.Gap, name: 'Impact Gap', minWidth: 50, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.Confidence, name: 'Confidence', minWidth: 50, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
    {
        key: columnKeys.ExecutionStatus, name: 'Execution Status', minWidth: 50, maxWidth: maxWidthMonth, isResizable: true, className: styles.nomarlColumn
    },
];

interface Props {
    Isloading: boolean;
    ActualData: [number, number][];
    TargetData: [number, number][];
    FirstDate: Date;
    DeliveryETA: Date;
    HistoryProject: EFTrackerBigBetsProjectHistory[];
    CurrentConfidence: string;
    CurrentExecutionStatus: string;
}

const BigBetsMonthlyProjectTable: React.FC<Props> = ({ Isloading, ActualData, TargetData, FirstDate, DeliveryETA, HistoryProject, CurrentConfidence, CurrentExecutionStatus }) => {
    //Get Columns
    const [newColumns, setNewColumns] = useState<IColumn[]>(columns || []);

    const [monthItem, setMonthItem] = useState<MonthlyDataItem[]>([]);

    useEffect(() => {
        if (Isloading) return;
        //month Item
        // Removed ETA pair
        const indexToRemove = TargetData.findIndex(item => item[0] === DeliveryETA.valueOf());

        const removedEtaTargetData = [...TargetData];
        // If found, remove the element from the array
        if (indexToRemove !== -1 && ActualData.findIndex(item => item[0] === DeliveryETA.valueOf()) === -1) {
            removedEtaTargetData.splice(indexToRemove, 1);
        }
        const monthItemTmp = calculateMonthlyDifferences(removedEtaTargetData, ActualData, HistoryProject, CurrentConfidence, CurrentExecutionStatus );
        setMonthItem(monthItemTmp);
    }, [Isloading, ActualData, TargetData, FirstDate, HistoryProject, CurrentConfidence, CurrentExecutionStatus, DeliveryETA]);

    function _renderItemColumn(item: MonthlyDataItem, index?: number, column?: IColumn): React.ReactNode {
        switch (column?.key) {
            case columnKeys.Date:
                return <span>{formatValue(item.Month)}</span>;
            case columnKeys.Target:
                return (
                    <div style={{ textAlign: "right" }}>
                        <span>{currencyFormatterNegativeFirst(item.Target, 2, "$")}</span>
                    </div>
                );
            case columnKeys.Actual:
                return (
                    <div style={{ textAlign: "right" }}>
                        <span>{currencyFormatterNegativeFirst(item.Actual, 2, "$")}</span>
                    </div>
                );
            case columnKeys.Gap:
                return (
                    <div style={{ textAlign: "right" }} className={item.Target > item.Actual ? styles.red : styles.green}>
                        <span>{currencyFormatterNegativeFirst(item.Target - item.Actual, 2, "$")}</span>
                    </div>
                );
            case columnKeys.Confidence:
                return <EfficiencyStatus Status={item.Confidence as EfficiencyStatusEnum} />
            case columnKeys.ExecutionStatus:
                return <EfficiencyStatus Status={item.ExecutionStatus as EfficiencyStatusEnum} />
        }
    }

    return (
        <div>
            <DetailsList
                items={monthItem}
                setKey="set"
                columns={newColumns}
                onRenderItemColumn={_renderItemColumn}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                checkboxVisibility={CheckboxVisibility.hidden}
                selectionMode={SelectionMode.none}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                selectionZoneProps={{
                    disableAutoSelectOnInputElements: true,
                    isSelectedOnFocus: false
                }}
            />
        </div>
    );
}

function getLastOfMonthValues(arr: [number, number][]): Map<string, [number, number]> { //arr should be asc
    const lastOfMonthValues = new Map<string, [number, number]>();

    for (const pair of arr) {
        const year = new Date(pair[0]).getFullYear().toString();
        const month = (new Date(pair[0]).getMonth() + 1).toString().padStart(2, '0');
        const monthYear = month + "/" + year;
        lastOfMonthValues.set(monthYear, pair);
    }

    return lastOfMonthValues;
}

function getLastOfMonthStatus(projects: EFTrackerBigBetsProjectHistory[]): Map<string, EFTrackerBigBetsProjectHistory> { //arr should be asc
    const lastOfMonthValues = new Map<string, EFTrackerBigBetsProjectHistory>();

    for (const project of projects) {
        const year = project.LastModifyTime.getFullYear().toString();
        const month = (project.LastModifyTime.getMonth() + 1).toString().padStart(2, '0');
        const monthYear = month + "/" + year;
        lastOfMonthValues.set(monthYear, project);
    }

    return lastOfMonthValues;
}

function calculateMonthlyDifferences(
        targetpairs: [number, number][],
        currentPairs: [number, number][],
        historyProject: EFTrackerBigBetsProjectHistory[],
        LatestConfidence: string,
        LatestExecutionStatus: string,
    ): MonthlyDataItem[]
    {
    const monthlyData: MonthlyDataItem[] = [];
    const lastOfMonthValues = getLastOfMonthValues(currentPairs);
    const lastOfMonthStatus = getLastOfMonthStatus(historyProject);

    const dataByMonth: { [month: string]: MonthlyDataItem } = {};

    let confidence = historyProject.length > 0 ? historyProject[0].ConfidenceInImpact : LatestConfidence;
    let executionStatus = historyProject.length > 0 ? historyProject[0].ExecutionStatus : LatestExecutionStatus;

    for (const pair1 of targetpairs) {
        const year = new Date(pair1[0]).getFullYear().toString();
        const month = (new Date(pair1[0]).getMonth() + 1).toString().padStart(2, '0');
        const monthYear = month + "/" + year;

        if (!dataByMonth[monthYear]) {
            dataByMonth[monthYear] = {
                Month: monthYear,
                Target: pair1[1],
                Actual: 0,
                Confidence: confidence,
                ExecutionStatus: executionStatus,
            };
        } else {
            dataByMonth[monthYear].Target = pair1[1];
        }

        if (lastOfMonthValues.has(monthYear)) {
            const pair2 = lastOfMonthValues.get(monthYear)!;
            dataByMonth[monthYear].Actual = pair2[1];
        }
        if (monthYear === (new Date().getMonth() + 1).toString().padStart(2, '0') + "/" + new Date().getFullYear().toString()) { //newest month
            dataByMonth[monthYear].Confidence = LatestConfidence;
            dataByMonth[monthYear].ExecutionStatus = LatestExecutionStatus;
            confidence = LatestConfidence;
            executionStatus = LatestExecutionStatus;
        }
        else if (lastOfMonthStatus.has(monthYear)) {
            const project = lastOfMonthStatus.get(monthYear)!;
            dataByMonth[monthYear].Confidence = project.ConfidenceInImpact;
            dataByMonth[monthYear].ExecutionStatus = project.ExecutionStatus;
            confidence = project.ConfidenceInImpact;
            executionStatus = project.ExecutionStatus;
        }
        
    }

    const sortedByMonth = Object.keys(dataByMonth).sort(); //asc
    for (const month of sortedByMonth) {
        monthlyData.push(dataByMonth[month]);
    }

    return monthlyData;
}

export default BigBetsMonthlyProjectTable;