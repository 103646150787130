import { useMsal } from "@azure/msal-react";
import { useQuery, UseQueryResult } from "react-query";
import { Flight } from "../models/Flights";
import { getFlights } from "../services/settingService";
import { Endpoints } from "../utils/Constants";
import { CLIENT_ID } from "../authConfig";

export function useFlights(): UseQueryResult<Flight, Error> {
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    return useQuery<
        Flight,
        Error,
        Flight,
        [string, string, string]>([Endpoints.GetFlights, account?.localAccountId || "", account?.idTokenClaims?.aud || CLIENT_ID], getFlights);
}