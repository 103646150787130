import { LogComponent, LogElement } from "./LogModel";

export interface ICosmosCost {
    organizationId: string;
    organizationName: string;
    feedback: string;
    physicalSize: number;
    processingCosts: number;
    serviceDevOwners: string;
    serviceGroupId: string;
    serviceGroupName: string;
    serviceId: string;
    serviceName: string;
    servicePMOwners: string;
    storageCosts: number;
    totalPnHours: number;
    totalCost: number;
    compressionRatio: number;
}

export enum CosmosSourceTypeEnum {
    Storage,
    Processing,
    All,
}

export const CosmosSourceTypeName : Record<CosmosSourceTypeEnum, string> = {
    [CosmosSourceTypeEnum.All]: 'Cosmos',
    [CosmosSourceTypeEnum.Processing]: 'Processing',
    [CosmosSourceTypeEnum.Storage]: 'Storage',
};

export const CosmosLogComponent: Record<CosmosSourceTypeEnum, LogComponent> = {
    [CosmosSourceTypeEnum.All]: LogComponent.CosmosView,
    [CosmosSourceTypeEnum.Processing]: LogComponent.Processing,
    [CosmosSourceTypeEnum.Storage]: LogComponent.Storage,
};

export const CosmosLogElement: Record<CosmosSourceTypeEnum, LogElement> = {
    [CosmosSourceTypeEnum.All]: LogElement.CosmosTable,
    [CosmosSourceTypeEnum.Processing]: LogElement.ProcessingTable,
    [CosmosSourceTypeEnum.Storage]: LogElement.StorageTable,
};

export enum CosmosMetricEnum {
    LogicalSize,
    Costs,
    PhysicalSize,
    Token,
    Ratio,
}

export const CosmosMetricName : Record<CosmosMetricEnum, string> = {
    [CosmosMetricEnum.Costs]: 'Cost',
    [CosmosMetricEnum.LogicalSize]: 'Logical Size',
    [CosmosMetricEnum.PhysicalSize]: 'Physical Size',
    [CosmosMetricEnum.Token]: 'Token',
    [CosmosMetricEnum.Ratio]: 'Ratio',
};

export const CosmosCostNumericalKeys : (TypedKeyOf<ICosmosCost, number>)[] = ['totalCost', 'storageCosts', 'physicalSize', 'processingCosts', 'totalPnHours', 'compressionRatio'];

export const CosmosCostPaymentKeys: (TypedKeyOf<ICosmosCost, number>)[] = ['totalCost', 'processingCosts', 'storageCosts'];

export const CosmosCostTableKeys: Record<CosmosSourceTypeEnum, (keyof Partial<ICosmosCost>)[]> = {
    [CosmosSourceTypeEnum.All]: ['serviceName', 'organizationName', 'serviceGroupName', 'feedback', 'totalCost', 'storageCosts', 'physicalSize', 'processingCosts', 'totalPnHours', 'serviceDevOwners', 'servicePMOwners'],
    [CosmosSourceTypeEnum.Processing]: ['serviceName', 'organizationName', 'serviceGroupName', 'feedback', 'processingCosts', 'totalPnHours', 'serviceDevOwners', 'servicePMOwners'],
    [CosmosSourceTypeEnum.Storage]: ['serviceName', 'organizationName', 'serviceGroupName', 'feedback', 'storageCosts', 'physicalSize', 'compressionRatio', 'serviceDevOwners', 'servicePMOwners'],
};

export const CosmosCostDisplayNames : Record<keyof ICosmosCost, string> = {
    organizationId: 'Organization Id',
    organizationName: 'Organization',
    physicalSize: 'Physical Size (TB)',
    processingCosts: 'Processing Cost',
    serviceDevOwners: 'Dev Owners',
    serviceGroupId: 'Service Group Id',
    serviceGroupName: 'Service Group',
    serviceId: 'serviceId',
    serviceName: 'Service',
    servicePMOwners: 'PM Owners',
    storageCosts: 'Storage Cost',
    totalPnHours: 'Total Pn Hours',
    totalCost: 'Total Cosmos Cost',
    compressionRatio: 'Ratio',
    feedback: 'Feedback',
};
