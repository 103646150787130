import commonStyles from './PartnerServiceDetailTable.less';

export const CosmicServiceDetailsTableStyles = {
    container: commonStyles.tableContainer,
    bodyContainer: commonStyles.tableSectionContainer,
    headerContainer: commonStyles.tableSectionContainer,
    footerContainer: commonStyles.tableSectionContainer,
    stickyFooterContainer: commonStyles.stickyFooterContainer,
};

export const CosmicServiceDetailsTableDefaultPageSize = 50;

export const CosmicServiceDetailsTableIdColumns = new Set<string>(['serviceId', 'namespace', 'region', 'ring', 'nodeOS']);

export const CosmicServiceDetailsTableNumericalColumns = new Set<string>(["containerCount", "cpuUsageCores", "cpuRequestCores", "cpuLimitCores", "cpuUtilizationP99", "memoryWorkingSetMB", "memoryRequestMB", "memoryLimitMB", "memoryUtilizationP99", "rightsizing"]);

export const CosmicServiceDetailsTableOffset = { header: { offsetTop: 0 }, footer: { offsetBottom: 0 } };

export const CosmicServiceDetailTableDisplayNames: Record<string, string> = {
    date: 'Date',
    workload: 'Workload',
    teamGroupName: 'Team Group Name',
    serviceGroupName: 'Service Group Name',
    serviceName: 'Service Name',
    serviceId: 'Service ID',
    namespace: 'Namespace',
    region: 'Region',
    ring: 'Ring',
    nodeOS: 'Node OS',
    containerCount: 'Container Count',
    cpuUsageCores: 'CPU Usage Cores',
    cpuRequestCores: 'CPU Request Cores',
    cpuLimitCores: 'CPU Limit Cores',
    cpuUtilizationP99: 'CPU Utilization P99',
    memoryWorkingSetMB: 'Memory Working Set MB',
    memoryRequestMB: 'Memory Request MB',
    memoryLimitMB: 'Memory Limit MB',
    memoryUtilizationP99: 'Memory Utilization P99',
    rightsizing: 'Rightsizing'
}