import {
    Combobox,
    Option,
    useId,
} from "@fluentui/react-components";
import { ComboboxProps, makeStyles } from "@fluentui/react-components";
import {
    Virtualizer,
    useStaticVirtualizerMeasure,
} from "@fluentui/react-components/unstable";

import React from 'react';
import { TooltipHost } from "@fluentui/react";
import styles from './CosmicView.less';
import { useCosmicFilters } from '../../hooks/useCosmicFilters';
import { useGetCosmicServiceDimensionMetric } from '../../hooks/useCosmicQuery';

interface CosmicComboBoxProps {
    label: string;
    filterKey: string;
    description?: string;
}

const CosmicComboBox: React.FC<CosmicComboBoxProps> = ({ label, filterKey, description }) => {
    const { selectedKeys, cosmicSearchFiltersWithoutCurrentSelectedKeys, setSelectedKeys } = useCosmicFilters(filterKey);

    const { cosmicServiceDimension, isLoading } = useGetCosmicServiceDimensionMetric(label, cosmicSearchFiltersWithoutCurrentSelectedKeys || "");
    const optionsData = ["Select All", ...cosmicServiceDimension];

    const [matchingOptions, setMatchingOptions] = React.useState([...optionsData]);
    const [customSearch, setCustomSearch] = React.useState<string | undefined>();
    const [placeholder, setPlaceholder] = React.useState(selectedKeys.length ? selectedKeys.sort().join(', ') : `Type to filter ${label}`);
    const [comboSelectedOptions, setComboSelectedOptions] = React.useState<string[]>(selectedKeys);

    const onChange: ComboboxProps["onChange"] = (event) => {
        const value = event.target.value.trim();
        const matches = optionsData.filter(
            (option) => option.toLowerCase().indexOf(value.toLowerCase()) === 0
        );
        setMatchingOptions(matches);
        if (value.length && matches.length > 1) {
            setCustomSearch(value);
        } else {
            setCustomSearch(undefined);
        }
    };

    const onOptionSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
        if (!data.selectedOptions) {
            return;
        }

        setComboSelectedOptions(data.selectedOptions);
    };

    const handleOpenChange: ComboboxProps["onOpenChange"] = (e, data) => {
        if (!data.open) {
            if (comboSelectedOptions.includes('Select All')) {
                setPlaceholder(`Type to filter ${label}`);
                setSelectedKeys([]);
                setComboSelectedOptions([]);
                return;
            }

            setMatchingOptions([...optionsData]);
            setCustomSearch(undefined);
            setSelectedKeys(comboSelectedOptions);

            if (comboSelectedOptions.length) {
                setPlaceholder(comboSelectedOptions.sort().join(', '));
            } else {
                setPlaceholder(`Type to filter ${label}`);
            }
        }
        else {
            setMatchingOptions([...optionsData]);
            setCustomSearch(undefined);
        }
    }

    const comboId = useId(`combo-filter-${filterKey}`);

    //This should include the item height (32px) and account for rowGap (2px)
    const itemHeight = 34;
    const numberOfItems = matchingOptions.length;

    const min = (a: number, b: number) => (a < b ? a : b);
    const perPage = min(10, numberOfItems);

    const {
        virtualizerLength,
        bufferItems,
        bufferSize,
        scrollRef,
        containerSizeRef,
    } = useStaticVirtualizerMeasure({
        defaultItemSize: itemHeight,
        direction: "vertical",
        // We want at least 10 additional items on each side of visible items for page up/down (+ 1 buffer)
        bufferItems: perPage + 1,
        // We need to recalculate index when at least 10 items (+1px) from the bottom or top for page up/down
        bufferSize: itemHeight * perPage + 1,
    });

    return (
        <div>
            <TooltipHost content={description}>
                <label id={comboId}>{label}</label>
            </TooltipHost>
            <Combobox
                aria-labelledby={comboId}
                placeholder={isLoading ? "Loading..." : placeholder}
                onOptionSelect={onOptionSelect}
                onChange={onChange}
                multiselect={true}
                onOpenChange={handleOpenChange}
                defaultValue={''}
                defaultSelectedOptions={selectedKeys}
                freeform
                selectedOptions={comboSelectedOptions}
                disabled={isLoading}
                positioning={{ autoSize: "width", position: "below" }}
                listbox={{ ref: scrollRef, style: { maxHeight: '250px'} }}
            >
                <Virtualizer
                    numItems={numberOfItems}
                    virtualizerLength={virtualizerLength}
                    bufferItems={bufferItems}
                    bufferSize={bufferSize}
                    itemSize={itemHeight}
                    containerSizeRef={containerSizeRef}
                >
                    {/* {matchingOptions.map((option) => (
                        <Option key={option} >{option}</Option>
                    ))} */}
                    {(index) => {
                        return <Option aria-posinset={index} aria-setsize={numberOfItems} key={matchingOptions[index]}>{matchingOptions[index]}</Option>;
                    }}
                </Virtualizer>
            </Combobox>
        </div>
    );
};

const CosmicComboBoxes = () => {
    const comboBoxConfigs = [
        { label: "Workload", filterKey: "Workloads", description: "Workload is derived from Namespace by taking the Namespace prefix(e.g., ic3-media-audiencebot → ic3)" },
        { label: "ServiceGroupName", filterKey: "ServiceGroupNames" },
        { label: "TeamGroupName", filterKey: "TeamGroupNames" },
        { label: "ServiceName", filterKey: "ServiceNames" },
        { label: "Namespace", filterKey: "Namespaces" },
        { label: "Region", filterKey: "Regions" },
        { label: "Ring", filterKey: "Rings" },
        { label: "NodeOS", filterKey: "NodeOS" },
    ];

    return (
        <div className={styles.filterContainer}>
            {comboBoxConfigs.map(config => (
                <CosmicComboBox key={config.filterKey} label={config.label} filterKey={config.filterKey} description={config.description} />
            ))}
        </div>
    );
};

export default CosmicComboBoxes;
