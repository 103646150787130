import * as React from "react";
import { useGetAzureBudgetDetailsQuery, useGetTopAzureWorkloadsMonthlyCostQuery, useGetTotalBudgetQuery } from "../../../hooks/useAzureQuery";
import { BudgetChartContainer } from "./BudgetChartContainer";
import { BudgetTable } from "./BudgetTable";
import EmptyState from "../../common/state/EmptyState";
import { useDateRange } from "../../../hooks/useDateSelector";

interface IBudgetProps {
    type?: "Public" | "AZSC";
}

export const Budget: React.FC<IBudgetProps> = (props) => {
    const { fiscalYear } = useDateRange();
    const {isLoading: isTotalBudgetLoading, data: totalBudget} = useGetTotalBudgetQuery(fiscalYear);
    const {isLoading: isGetTop8WorkloadLoading, data: top8Workloads} = useGetTopAzureWorkloadsMonthlyCostQuery(props.type || "Total", fiscalYear, 8);
    const {isLoading: isBudgetDetailsLoading, data: budgetDetails} = useGetAzureBudgetDetailsQuery(fiscalYear);

    return !isBudgetDetailsLoading && budgetDetails && budgetDetails.length === 0 ?
        <div style={{padding: 200, textAlign: "center"}}>
            <EmptyState width={200} />
            <p>{"The selected services don't have the budget data"}</p>
        </div> :
        <>
        <BudgetChartContainer
            type={props.type}
            isTotalBudgetLoading={isTotalBudgetLoading}
            totalBudget={totalBudget}
            isGetTop8WorkloadLoading={isGetTop8WorkloadLoading}
            top8Workloads={top8Workloads}
            fiscalYear={fiscalYear}
        />
        <BudgetTable
            type={props.type}
            isBudgetDetailsLoading={isBudgetDetailsLoading}
            budgetDetails={budgetDetails}
            fiscalYear={fiscalYear}
        />
        </>;
}