import { ISubstratePlatformBackendServiceIdPivot, ISubstratePlatformBackendTotalCostCard } from "../models/SubstratePlatform";
import { getSubstratePlatformBackendPivotCostTable, getSubstratePlatformBackendTotalCost, getSubstratePlatformDailyReport } from "../services/substratePlatformService";

import { CategoryDivision } from "../models/CategoryDivision";
import { Endpoints } from "../utils/Constants";
import { IAppState } from "../store";
import { IDailyMetricReport } from "../models/Metric";
import { IDateRangeFilterData } from "../models/FilterView";
import { ServiceTreeItem } from "../models/serviceTree";
import { useCategoryFilters } from "./useFilters";
import { useDateRange } from "./useDateSelector";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

export function useGetSubstratePlatformDailyReportQuery(pivot: string, isCOGSCalculator: boolean) {
    const dateRangeFilterData = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    const rings: string[] = [];

    return useQuery<
        IDailyMetricReport[],
        Error,
        IDailyMetricReport[],
        [string, IDateRangeFilterData, string, string[], Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, boolean]>([Endpoints.GetSubstratePlatformDailyReport, dateRangeFilterData, pivot, rings, filtersData.filters, serviceIdMap, isCOGSCalculator], getSubstratePlatformDailyReport);
}

export function useGetSubstratePlatformBackendTotalCost() {
    const dateRangeFilterData = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    const rings: string[] = [];

    return useQuery<
        ISubstratePlatformBackendTotalCostCard[],
        Error,
        ISubstratePlatformBackendTotalCostCard[],
        [string, IDateRangeFilterData, string[], Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>]>([Endpoints.GetSubstratePlatformBackendTotalCost, dateRangeFilterData, rings, filtersData.filters, serviceIdMap], getSubstratePlatformBackendTotalCost);
}

export function useGetSubstratePlatformBackendPivotCostTable(pivotBy: string) {
    const dateRangeFilterData = useDateRange();
    const filtersData = useCategoryFilters().filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.indexMap);
    const rings: string[] = [];

    return useQuery<
        ISubstratePlatformBackendServiceIdPivot[],
        Error,
        ISubstratePlatformBackendServiceIdPivot[],
        [string, IDateRangeFilterData, string, string[], Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>]>([Endpoints.GetSubstratePlatformBackendTotalCost, dateRangeFilterData, pivotBy, rings, filtersData.filters, serviceIdMap], getSubstratePlatformBackendPivotCostTable);
}