import { EmpytCostView } from "../common/EmptyCostView";
import PageHeader from "../../common/PageHeader";
import { PcmV2SceneTypeEnum } from "../../../models/PcmV2Models";
import React from "react";
import { SubstrateCostTable } from "../common/table/SubstrateCostTable";
import { SubstrateTopFiveTrends } from "../common/SubstrateTopFiveTrends";
import { SubstrateTotalCostTrends } from "../common/SubstrateTotalCostTrends";
import { SubstrateV2WikiLink } from "../../../models/constants/PcmV2Constants";
import { WarningBanner } from "../../banner/WarningBanner";
import commonStyles from "../../common/styles/Common.less";
import styles from "../Substrate.less";
import { useGetBannersByTabAndDateQuery } from "../../../hooks/useBannerQuery";
import { useGetMemoryRedundancyData, useGetPcmV2Cost } from "../../../hooks/useSubstrateV2Query";
import { LogComponent, LogElement } from "../../../models/LogModel";
import { MemoryRedundancyCard } from "../memoryRedundancy/MemoryRedundancyCard";
import { useFlights } from "../../../hooks/useSettings";

export const SubstrateProcessHosting: React.FC = () => {
    const {data: flights} = useFlights();
    const { data: pcmV2Cost } = useGetPcmV2Cost();
    const { data: banners } = useGetBannersByTabAndDateQuery(PcmV2SceneTypeEnum.ProcessHosting);
    const query = useGetMemoryRedundancyData(PcmV2SceneTypeEnum.ProcessHosting).data;

    return (
        <div className={styles.pageWrapper}>
            <PageHeader
                className={styles.pageHeader}
                title="Process Hosting Cost View"
                description="We use this cost category to allocate and pass on the cost associated with hosting model A/B/B2 feature services/processes on Substrate bare metal capacity."
                link={SubstrateV2WikiLink}
                linkText="Learn More"
                tag="PREVIEW"
                linkLogComponent={LogComponent.ProcessHostingChartingPane}
                linkLogElement={LogElement.LearnMoreProcessHosting}
            />
            {!!banners?.length && (
                <div className={styles.bannerWrapper}>
                    {banners.map((item) => (
                        <WarningBanner key={item.id} bannerItem={item} />
                    ))}
                </div>
            )}
            {pcmV2Cost && pcmV2Cost.processHostingCost === 0 ? (
                <EmpytCostView />
            ) : (
                <>
                    <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`}>
                        { flights?.enableMemoryRedundancy && query && query?.length > 0 && <MemoryRedundancyCard query={query} />}
                        <SubstrateTotalCostTrends scene={PcmV2SceneTypeEnum.ProcessHosting} />
                        <SubstrateTopFiveTrends scene={PcmV2SceneTypeEnum.ProcessHosting} />
                    </div>
                    <SubstrateCostTable scene={PcmV2SceneTypeEnum.ProcessHosting} />
                </>
            )}
        </div>
    );
};
