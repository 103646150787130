import * as React from "react";
import {
    ESCostBillingType,
    ESCostDataDimensions,
    ESCostOrganization,
    ESOverviewFiscalYearValues,
    ESOverviewTimeSelection,
} from "../../../../models/ESCostModels";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import PieChart, { IPieChartData } from "./PieChart";

type IOverviewCost = IPieChartData & {
    [key in ESCostOrganization]?: number;
};

interface IOverviewPieChartProps {
    data: ESOverviewFiscalYearValues[];
    description: string;
    totalCountDesc: string;
    isCurrency: boolean;
    billingType: ESCostBillingType;
}

function calculateOverviewValues(year: number, rawData: ESOverviewFiscalYearValues[], billingType: ESCostBillingType): IOverviewCost {
    const values = rawData.filter(
        (data) =>
            data[ESCostDataDimensions.BillingType] === billingType &&
            year === data[ESCostDataDimensions.ReturnFiscalYear]
    );

    const sum = values.reduce(
        (acc, curr) => {
            const org = curr[ESCostDataDimensions.Organization];
            const v = ESCostDataDimensions.Cost in curr ? curr[ESCostDataDimensions.Cost] : curr[ESCostDataDimensions.Capacity];
            acc[org] = (acc[org] || 0) + (v ?? 0);
            return acc;
        },
        { [ESCostOrganization.OfficeEngineering]: 0, [ESCostOrganization.SubstrateEngineering]: 0 }
    );

    return sum;
}

const OverviewPieChart: React.FC<IOverviewPieChartProps> = (props) => {
    const { data: rawData, description, totalCountDesc, isCurrency, billingType } = props;
    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );
    const overviewValues = React.useMemo(() => calculateOverviewValues(year, rawData, billingType), [rawData, year, billingType]);
    const total = Object.values(overviewValues).reduce((acc, curr) => acc + curr, 0);
    return <PieChart data={overviewValues} description={description} totalCountDesc={totalCountDesc} isCurrency={isCurrency} total={total} />;
};

export default OverviewPieChart;
