// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YppHDpS3O7aora7zmCwA {\n  color: #323130;\n  font-family: \"Segoe UI\";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 22px;\n  padding-top: 6px;\n  padding-bottom: 6px;\n}\n.g7HmbdyfoLtZ9DQA9ryT {\n  color: #484644;\n  font-family: \"Segoe UI\";\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n}\n.VRJ4HhG86Ni6OCMp7UfW {\n  color: #900;\n}\n.Y9shHLevkMoMhnQsKMFX {\n  color: #107C10;\n}\n.g9A_l815vKZjOjYcs4BR {\n  color: #484644;\n}\n.jLi3cOC7M5qyCQ6O7QNh {\n  padding: 6px;\n  font-family: \"Segoe UI\";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EfficiencyTrackerV2/Components/Tools/ConclusionBar.less"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AACJ;AAEA;EACI,cAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;AAGA;EACI,WAAA;AADJ;AAIA;EACI,cAAA;AAFJ;AAKA;EACI,cAAA;AAHJ;AAMA;EACI,YAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAJJ","sourcesContent":[".title {\n    color: #323130;\n    font-family: \"Segoe UI\";\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 22px;\n    padding-top: 6px;\n    padding-bottom: 6px;\n}\n\n.content {\n    color: #484644;\n    font-family: \"Segoe UI\";\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n}\n\n.red {\n    color: #900;\n}\n\n.green {\n    color: #107C10;\n}\n\n.black {\n    color: #484644;\n}\n\n.trend {\n    padding: 6px;\n    font-family: \"Segoe UI\";\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "YppHDpS3O7aora7zmCwA",
	"content": "g7HmbdyfoLtZ9DQA9ryT",
	"red": "VRJ4HhG86Ni6OCMp7UfW",
	"green": "Y9shHLevkMoMhnQsKMFX",
	"black": "g9A_l815vKZjOjYcs4BR",
	"trend": "jLi3cOC7M5qyCQ6O7QNh"
};
export default ___CSS_LOADER_EXPORT___;
