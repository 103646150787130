import React from "react";
import {
    ESAllUsageScenariosFYReturnTypeCostRawResponse,
    ESCostDataDimensions,
    ESCostOrganization,
    ESOverviewTimeSelection,
    ESUsageScenarios,
} from "../../../../models/ESCostModels";
import { useEsUsageScenarios } from "../../../../hooks/useEsUsageScenarios";
import { useEsOrg } from "../../../../hooks/useEsOrg";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import { ESAllUsageScenario, ESOrgs } from "../../../../models/constants/ESCostConstants";
import { Histogram } from "../Common/Histogram";

export interface IHistogramProps {
    data: ESAllUsageScenariosFYReturnTypeCostRawResponse[];
}

function reduceCost(costs: ESAllUsageScenariosFYReturnTypeCostRawResponse[], returnTypes: string[]): number[] {
    const result = Array.from({ length: returnTypes.length }, () => 0);
    costs.forEach((cost) => {
        const i = returnTypes.indexOf(cost[ESCostDataDimensions.ReturnType]);
        result[i] += cost[ESCostDataDimensions.Cost];
    });

    return result;
}

function calculateCosts(org: ESCostOrganization[], usageScenarios: ESUsageScenarios[], year: number, data: ESAllUsageScenariosFYReturnTypeCostRawResponse[]) {
    return data.filter(
        (cost) =>
            ESOrgs.includes(cost[ESCostDataDimensions.Organization]) &&
            ESAllUsageScenario.includes(cost[ESCostDataDimensions.UsageScenario]) &&
            (usageScenarios.length === 0 || usageScenarios.includes(cost[ESCostDataDimensions.UsageScenario])) &&
            (org.length === 0 || org.includes(cost[ESCostDataDimensions.Organization])) &&
            year === cost[ESCostDataDimensions.ReturnFiscalYear]
    );
}

export const CloudBuildReturnTypeHistogram: React.FC<IHistogramProps> = (props) => {
    const { data } = props;
    const [org] = useEsOrg();
    const [usageScenarios] = useEsUsageScenarios();
    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );

    const filterCost = React.useMemo(() => calculateCosts(org, usageScenarios, year, data), [org, usageScenarios, year, data]);
    const returnTypes = React.useMemo(() => {
        const rt: string[] = [...new Set(filterCost.map((cost) => cost[ESCostDataDimensions.ReturnType]))];
        rt.sort();
        return rt;
    }, [filterCost]);
    const values = React.useMemo(() => reduceCost(filterCost, returnTypes), [filterCost, returnTypes]);

    return (
        <Histogram
            values={values}
            xAxisLabels={returnTypes}
            seriesHeader="CloudBuild cost ($)"
            description={`CloudBuild cost on return types (FY${String(year).substring(2)})`}
            showValue={true}
            isCurrency={true}
        />
    );
};
