import { IFilters } from "../../../models/FilterView";
import { PcmV2AggeratedLevel } from "../../../models/PcmV2Models";

export class SubstrateUtils {
    static getAggeratedLevelByCategoryFilters(filters: IFilters): PcmV2AggeratedLevel {
        if (!this.hasServiceLevelAndBelowFilter(filters)) {
            return PcmV2AggeratedLevel.Service;
        }

        return PcmV2AggeratedLevel.Workload;
    }

    static isFiltersEmpty(filters: IFilters): boolean {
        return !this.hasServiceLevelAndAboveFilter(filters) && !this.hasWorkloadLevelFilter(filters);
    }

    static hasWorkloadLevelFilter(filters: IFilters): boolean {
        const filterValue = filters.filters;

        return !!(
            filterValue.GriffinAppV2?.length ||
            filterValue.GriffinProcessorV2?.length ||
            filterValue.ProcessV2?.length ||
            filterValue.VdirV2?.length ||
            filterValue.DataSetV2?.length ||
            filterValue.StoreClientV2?.length ||
            filterValue.StoreClientComponentV2?.length ||
            filterValue.ScenarioTagV2?.length
        );
    }

    static hasServiceLevelAndBelowFilter(filters: IFilters): boolean {
        const filterValue = filters.filters;

        return !!(filterValue.Service?.length || this.hasWorkloadLevelFilter(filters));
    }

    static hasServiceLevelAndAboveFilter(filters: IFilters): boolean {
        const filterValue = filters.filters;

        return !!(filterValue.Service?.length || this.hasAboveServiceLevelFilter(filters));
    }

    static hasAboveServiceLevelFilter(filters: IFilters): boolean {
        const filterValue = filters.filters;

        return !!(filterValue.Division?.length || filterValue.Organization?.length || filterValue.TeamGroup?.length || filterValue.ServiceGroup?.length);
    }
}
