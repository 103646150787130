import { EventType, LogTarget } from "../../models/LogModel";
import { Link, MessageBar, MessageBarType } from "@fluentui/react";
import React, { useEffect, useState } from "react";

import { BannerModel } from "../../models/BannerModels";
import styles from "./WarningBanner.less";
import { trackEventCallback } from "../../utils/AppInsights";

interface IWarningBanner {
    bannerItem: BannerModel;
}

export const WarningBanner: React.FC<IWarningBanner> = (props) => {
    const item = props.bannerItem;
    const [isClosed, setIsClosed] = useState<boolean>(() => {
        return sessionStorage.getItem("bannerClosed" + item.id) === "true";
    });
    const barType = getBarType(item.type);
    const trackEvent = () => {
        if (props.bannerItem.logComoonent && props.bannerItem.logElement) {
            trackEventCallback(props.bannerItem.logComoonent, props.bannerItem.logElement, props.bannerItem.displayName || "CarbonMore", LogTarget.Button, undefined, EventType.Click);
        }
    }

    const closeBanner = () => {
        setIsClosed(true);
        sessionStorage.setItem("bannerClosed" + item.id, "true");
    };

    return (
        <>
            {!isClosed && <MessageBar className={styles.banner} messageBarType={barType} onDismiss={() => closeBanner()}>
                <div key={item.id} >
                    {item.message}
                    <Link href={item.url} target="_blank" onClick={trackEvent}>{item.urlText}</Link>
                </div>
            </MessageBar>
            }
        </>
    );
};

function getBarType(type:string) {
    switch (type) {
        case 'info':
            return MessageBarType.info;
        case 'warning':
            return MessageBarType.warning;
        case 'severeWarning':
            return MessageBarType.severeWarning;
        case 'error':
            return MessageBarType.error;
    }
    
}
