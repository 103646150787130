import { DirectionalHint } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import TeachingBubbleSteps from './TeachingBubbleSteps';

const CustomViewTeachingBubbles: React.FC = () => {
    return (
        <TeachingBubbleSteps
            name='customView'
            steps={[{
                title: 'Support Save View',
                description: 'You can save your search results as a saved view.',
                target: '#custom-bubble-anchor-save-view',
                calloutProps: {
                    gapSpace: 8,
                    directionalHint: DirectionalHint.topCenter,
                }
            }, {
                title: 'Support Share Search Results',
                description: 'You can share the search results by directly copying or pasting the url.',
                target: '#custom-bubble-anchor-share',
                calloutProps: {
                    gapSpace: 8,
                    directionalHint: DirectionalHint.topCenter,
                }
            }]}
        />
    );
};

export default CustomViewTeachingBubbles;