import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey } from "../models/SearchKey";

export function useRemap(): [boolean, (showRemap?: boolean) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const showRemap: boolean = useMemo(() => {
        const showRemap = searchParams.get(SearchKey.ShowRemap);
        if (showRemap == null || typeof showRemap == 'undefined') {
            return true;
        }
        return showRemap === "true";
    }, [searchParams]);

    const setShowRemap = useCallback((showRemap?: boolean) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(SearchKey.ShowRemap, JSON.stringify(showRemap));
        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return [showRemap, setShowRemap];
}