import React, { useState } from 'react';
import { IComboBoxOption } from '@fluentui/react/lib/ComboBox';
import { TextField } from '@fluentui/react/lib/TextField';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { FilterGroupOperator, FilterGroupOperatorDisplayText } from './FilterListConstants';
import { Dropdown } from '@fluentui/react/lib/Dropdown';
import styles from './FilterForm.less';
import { Stack } from '@fluentui/react';
import { FilterType } from './FilterList';
import { useFlights } from '../../../hooks/useSettings';

export interface FilterFormProps {
    onConfirm: (operator: FilterGroupOperator, type: FilterType, value: string) => void,
    onCancel: () => void;

    filterName: string;
    filterKey: string;
    filterType: FilterType;

    initData?: {
        operator: FilterGroupOperator;
        value?: string;
        keyword?: string;
    };
    validator?: (key: string, value: string, filterType: FilterType) => string | null;
}

export const FilterForm : React.FC<FilterFormProps> = (props: FilterFormProps) => {
    const enableMycroftData = useFlights().data?.enableMycroftData || false;
    const operatorOptions: IComboBoxOption[] = getComboBoxOptions(props.filterType, enableMycroftData);
    const [curOperator, setCurOperator] = useState(props.initData?.operator || FilterGroupOperator.EQ);
    const [curValue, setCurValue] = useState<string>((props.filterType !== FilterType.String ? props.initData?.value : props.initData?.keyword) || '');
    const [errorMsg, setErrorMsg] = useState<string>('');

    const handleValueChange = (value: string) => {
        setCurValue(value);

        const error = props.validator ? props.validator(props.filterKey, value, props.filterType) : null;

        if (error) {
            setErrorMsg(error);
        } else {
            setErrorMsg('');
        }
    };

    return (
        <div className={styles.filterForm}>
            <div className={styles.filterFormTitle}>{props.filterName}</div>
            <Stack horizontal>
                <Stack.Item>
                    <Dropdown className={styles.filterFormOperator} selectedKey={curOperator} options={operatorOptions} dropdownWidth="auto" onChange={(e, option) => option && setCurOperator(option.key as FilterGroupOperator)} />
                </Stack.Item>
                <Stack.Item>
                    <TextField errorMessage={errorMsg} value={curValue} onChange={(_, value) => handleValueChange(value || '')} />
                </Stack.Item>
            </Stack>
            <div className={styles.filterFormActions}>
                <PrimaryButton disabled={!!errorMsg || !curValue} onClick={() => props.onConfirm(curOperator, props.filterType, curValue)}>Apply</PrimaryButton>
                <DefaultButton onClick={props.onCancel}>Cancel</DefaultButton>
            </div>
        </div>
    );
};

function getComboBoxOptions(filterType: FilterType, enableMycroftData: boolean): IComboBoxOption[] {
    switch (filterType) {
        case FilterType.Number:
            return [
                { key: FilterGroupOperator.EQ, text: FilterGroupOperatorDisplayText[FilterGroupOperator.EQ] },
                { key: FilterGroupOperator.GT, text: FilterGroupOperatorDisplayText[FilterGroupOperator.GT] },
                { key: FilterGroupOperator.LT, text: FilterGroupOperatorDisplayText[FilterGroupOperator.LT] },
                { key: FilterGroupOperator.GTE, text: FilterGroupOperatorDisplayText[FilterGroupOperator.GTE] },
                { key: FilterGroupOperator.LTE, text: FilterGroupOperatorDisplayText[FilterGroupOperator.LTE] },
            ];
        case FilterType.String:
            return enableMycroftData ?
                [
                    { key: FilterGroupOperator.EQ, text: FilterGroupOperatorDisplayText[FilterGroupOperator.EQ] },
                    { key: FilterGroupOperator.CN, text: FilterGroupOperatorDisplayText[FilterGroupOperator.CN] },
                ] :
                [
                    { key: FilterGroupOperator.EQ, text: FilterGroupOperatorDisplayText[FilterGroupOperator.EQ] },
                    { key: FilterGroupOperator.CN, text: FilterGroupOperatorDisplayText[FilterGroupOperator.CN] },
                    { key: FilterGroupOperator.BW, text: FilterGroupOperatorDisplayText[FilterGroupOperator.BW] },
                    { key: FilterGroupOperator.EW, text: FilterGroupOperatorDisplayText[FilterGroupOperator.EW] },
                ];
        case FilterType.Boolean:
            return [
                { key: FilterGroupOperator.EQ, text: FilterGroupOperatorDisplayText[FilterGroupOperator.EQ] },
            ];
        default:
            return [];
    }
}