// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bzhDp1fcweatQC2OIC20 {\n  overflow: hidden;\n  color: #900;\n  text-overflow: ellipsis;\n  font-family: \"Segoe UI\";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 22px;\n}\n.Cx0xoBeBLVluowV9llet {\n  color: #900;\n  font-family: \"Segoe UI\";\n  font-size: 10px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 14px;\n}\n.X96mYJ4x2cg23vSc6EGp {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  font-family: \"Segoe UI\";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 16px;\n}\n.b9S08QcQO11Zctcf1J0o {\n  color: #900;\n}\n.L5RS01MT8_E7w3RvlCYs {\n  color: #107C10;\n}\n.eR_4xVIduDf_TH_W904G {\n  color: #484644;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EfficiencyTrackerV2/Components/BigBets/Tools/MonthTableComponent.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,WAAA;EACA,uBAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AAEA;EACI,WAAA;EAEA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAIA;EACI,gBAAA;EACA,uBAAA;EAEA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAHJ;AAMA;EACI,WAAA;AAJJ;AAOA;EACI,cAAA;AALJ;AAQA;EACI,cAAA;AANJ","sourcesContent":[".gap {\n    overflow: hidden;\n    color: #900;\n    text-overflow: ellipsis;\n    font-family: \"Segoe UI\";\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 22px;\n}\n\n.trend {\n    color: #900;\n\n    font-family: \"Segoe UI\";\n    font-size: 10px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 14px;\n}\n\n.content {\n    overflow: hidden;\n    text-overflow: ellipsis;\n\n    font-family: \"Segoe UI\";\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 16px;\n}\n\n.red {\n    color: #900;\n}\n\n.green {\n    color: #107C10;\n}\n\n.black {\n    color: #484644;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gap": "bzhDp1fcweatQC2OIC20",
	"trend": "Cx0xoBeBLVluowV9llet",
	"content": "X96mYJ4x2cg23vSc6EGp",
	"red": "b9S08QcQO11Zctcf1J0o",
	"green": "L5RS01MT8_E7w3RvlCYs",
	"black": "eR_4xVIduDf_TH_W904G"
};
export default ___CSS_LOADER_EXPORT___;
