import React from "react";

interface IInsightsChartDescription {
    metric: string;
    cost: string;
    rca: string;
}

export const InsightsChartDescription: React.FC<IInsightsChartDescription> = (props) => {
    return (
        <table>
            <thead>
                <tr>
                    <td><span style={{fontWeight:600}}>Affected Metric</span></td>
                    <td><span style={{paddingLeft:5}}>{props.metric}</span></td>
                </tr>
                <tr>
                    <td><span style={{fontWeight:600}}>Anomaly Cost</span></td>
                    <td><span style={{paddingLeft:5}}>{props.cost} monthly cost increase</span></td>
                </tr>
                <tr>
                    <td><span style={{fontWeight:600}}>Root Cause</span></td>
                    <td><span style={{paddingLeft:5}}>{props.rca}</span></td>
                </tr>
            </thead>
        </table>
    );
};