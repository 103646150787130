import moment, { Moment } from "moment";
import { CategoryDivision } from "../models/CategoryDivision";
import { CosmosCostNumericalKeys, CosmosMetricEnum, CosmosSourceTypeEnum, ICosmosCost } from "../models/CosmosModels";
import { parseFiltersToJson } from "../models/FilterView";
import { IDailyMetricReport } from "../models/Metric";
import { ServiceTreeItem } from "../models/serviceTree";
import { postJson } from "../utils/apiServiceBase";

export const getCosmosCostSummary = (data: ICosmosCost[]) : Record<TypedKeyOf<ICosmosCost, number>, number> => {
    const result : Record<TypedKeyOf<ICosmosCost, number>, number> = {
        storageCosts: 0,
        totalCost: 0,
        totalPnHours: 0,
        physicalSize: 0,
        processingCosts: 0,
        compressionRatio: 0,
    };

    data.forEach((item) => {
        CosmosCostNumericalKeys.forEach((key) => {
            result[key] += (item[key] || 0);
        });
    });

    return result;
};

export async function getCosmosCostByServices({ queryKey } : { queryKey: [_: string, filters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, start: Moment, end: Moment, type: CosmosSourceTypeEnum]}) : Promise<ICosmosCost[]> {
    const [, filters, serviceIdMap, start, end, cosmosSourceType] = queryKey;

    const url = `api/v1.0/cosmos/servicelevelheatmap?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}&cosmosDataSource=${cosmosSourceType}`;

    const data: ICosmosCost[] = await postJson(url, parseFiltersToJson(filters, serviceIdMap));

    data.forEach(item => {
        item.totalCost = (item.storageCosts || 0) + (item.processingCosts || 0);

        if (item.physicalSize) {
            item.physicalSize = (item.physicalSize || 0) / (1 << 20); // MB -> TB
        }

        if (item.serviceDevOwners) {
            item.serviceDevOwners = item.serviceDevOwners.split(';').join('; ');
        }

        if (item.servicePMOwners) {
            item.servicePMOwners = item.servicePMOwners.split(';').join('; ');
        }
    });

    return data;
}

export async function getCosmosCostByGem({ queryKey }: { queryKey: [_: string, start: Moment, end: Moment, type: CosmosSourceTypeEnum] }) : Promise<Record<string, number>> {
    const [, start, end, type] = queryKey;

    const url = `api/v1.0/cosmos/costbygem?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}&cosmosDataSource=${type}`;

    const data: Record<string, number> = await postJson(url, null);

    return data;
}

export async function getCosmosDailyMetrics({ queryKey }: { queryKey: [_: string, filters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, start: Moment, end: Moment, type: CosmosSourceTypeEnum, metricType: CosmosMetricEnum] }) : Promise<IDailyMetricReport> {
    const [, filters, serviceIdMap, start, end, sourceType, metricType] = queryKey;

    const url = `api/v1.0/cosmos/dailymetrics?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}&cosmosDataSource=${sourceType}&cosmosMetric=${metricType}`;

    const data: IDailyMetricReport = await postJson(url, parseFiltersToJson(filters, serviceIdMap));

    data?.data.forEach(item => {
        item.date = moment(item.date);
    });

    data?.data.sort((a, b) => a.date.valueOf() - b.date.valueOf());

    return data;
}

export async function batchGetCosmosDailyMetric({ queryKey }: { queryKey: [string, Partial<Record<CategoryDivision, string[]>>, Map<string, ServiceTreeItem>, CosmosSourceTypeEnum[], CosmosMetricEnum, Moment, Moment] }) : Promise<IDailyMetricReport[]> {
    const [_, filters, serviceIdMap, sources, metric, start, end] = queryKey;

    const result = Promise.all(sources.map((source) => getCosmosDailyMetrics({ queryKey: [_, filters, serviceIdMap, start, end, source, metric] })));

    return result;
}
