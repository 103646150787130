import * as React from "react";
import { Stack } from "@fluentui/react";
import { outerStackStyles, filterStackStyles } from "../ViewsCommonLayout";
import AzureOverviewPage from "./AzureOverviewPage";
import AzureOverviewFilterBar from "../../Filter/AzureOverviewFilterBar";

export const AzureOverviewContainer: React.FC = () => {
    return (
        <Stack styles={outerStackStyles} disableShrink>
            <Stack horizontal styles={filterStackStyles}>
                <AzureOverviewFilterBar />
            </Stack>
            <AzureOverviewPage />
        </Stack>
    );
};
