import { IFilterItem } from "../../filter/filterList/FilterList";
import { AzureComputeHeatmapDegree } from "./AzureComputeConstants";

export function getAzureComputeDegree(ratio: number) {
    let index = Math.ceil(ratio / 0.2) - 1;

    if (index < 0) index = 0;
    if (index >= AzureComputeHeatmapDegree.length) index = AzureComputeHeatmapDegree.length - 1;

    return AzureComputeHeatmapDegree[index];
}

export function filterItemsToHeatmapLocalFilters(filterItem: IFilterItem[]) {
    return filterItem.map((item) => ({
        metric: item.key,
        operator: item.operator,
        data: item.value,
        keyword: item.keyword,
        keywords: item.keywords,
    }));
}
