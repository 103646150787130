import React from "react";
import styles from "./ESBanner.less";
import { Stack, Icon } from "@fluentui/react";


const ESBanner: React.FC = () => {
    return (
        <Stack horizontal horizontalAlign="start" className={styles.banner}>
            <Stack.Item className={styles.item}>
                <Icon iconName="AnalyticsView" className={styles.logo} />
                <h2>ES365 Cost Transparency</h2>
            </Stack.Item>
        </Stack>
    );
};

export default ESBanner;