import { isArray } from "lodash";
import * as React from "react";
import LoadingState from "./LoadingState";

interface IResponseBoundary {
    isLoading: boolean;
    errorHappened: boolean;
    data?: unknown;
}

const ResponseBoundary: React.FC<IResponseBoundary> = (props) => {
    if (props.errorHappened) {
        return (
            <div>Error Happened!</div>
        )
    } else if (props.isLoading) {
        return (
            <LoadingState />
        )
    } else if (!props.data) {
        return (
            <div>No data</div>
        )
    } else if (isArray(props.data) && props.data.length <= 0) {
        return (
            <div>No data</div>
        )
    } else if (isEmpty(props.data)) {
        return (
            <div>No data</div>
        )
    }

    return <>{props.children}</>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEmpty(object: any):boolean { 
    for (const key in object) {
        return false;
    }
    return true;
}

export default ResponseBoundary;