// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DHs6xqncvkJBnZSuCqjx {\n  height: 400px;\n  padding-left: auto;\n  padding-top: auto;\n  border: 1px solid;\n  border-color: #68768A;\n  border-radius: 4px;\n  width: 700px;\n}\n.hKYiGtULAxBCi4ODgSpr {\n  height: 400px;\n  transition: all 0.3s ease;\n  color: #323130;\n  width: 700px;\n  margin-top: 200px;\n  margin-left: auto;\n  margin-right: auto;\n  display: flex;\n}\n.cy8DWFxJpwIpMlRA5dh9 {\n  margin-left: 0px;\n  height: 48px;\n  margin-top: 40px;\n}\n.vH6bGnu4cNHG8Nv1c4JE {\n  background: #E8E8E8;\n  border: none;\n  height: 32px;\n  font-size: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/monthlyReport/unsubscribePage.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,qBAAA;EACA,kBAAA;EACA,YAAA;AACJ;AAEA;EACI,aAAA;EACA,yBAAA;EACA,cAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;AAAJ;AAGA;EACI,gBAAA;EACA,YAAA;EACA,gBAAA;AADJ;AAIA;EACI,mBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AAFJ","sourcesContent":[".cardWrapper {\n    height: 400px;\n    padding-left: auto;\n    padding-top: auto;\n    border: 1px solid;\n    border-color: #68768A;\n    border-radius: 4px;\n    width: 700px;\n}\n\n.cardContainer {\n    height: 400px;\n    transition: all 0.3s ease;\n    color: #323130;\n    width: 700px;\n    margin-top: 200px;\n    margin-left: auto;\n    margin-right: auto;\n    display: flex;\n}\n\n.cardFooter {\n    margin-left: 0px;\n    height: 48px;\n    margin-top: 40px;\n}\n\n.unsubscribeBtn {\n    background: #E8E8E8;\n    border: none;\n    height: 32px;\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardWrapper": "DHs6xqncvkJBnZSuCqjx",
	"cardContainer": "hKYiGtULAxBCi4ODgSpr",
	"cardFooter": "cy8DWFxJpwIpMlRA5dh9",
	"unsubscribeBtn": "vH6bGnu4cNHG8Nv1c4JE"
};
export default ___CSS_LOADER_EXPORT___;
