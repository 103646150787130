export const ErrorMessage = {
    SubViewNameWarning: "Please input the saved sub-view name",
    ViewNameWarning: "Please input the saved view name",
    EditViewWarning: "You can't edit the default view",
}

export class ViewInputValidator {
    public static validateViewName(name: string) : boolean {
        if (!name) {
            return false;
        }

        return name.length <= 50;
    }

    public static validateSubViewName(name: string) : boolean {
        if (!name) {
            return false;
        }

        return name.length <= 60;
    }

    public static validateViewId(id: number) : boolean {
        if (!id) {
            return false;
        }

        return id > 0;
    }
}