import { ServiceTree, ServiceTreeItem } from "../models/serviceTree";

import { ISingleDayMetric } from "../models/Metric";

export function getAverage (metrics?: ISingleDayMetric[]) {
    let total = 0;
    let count = 0;
    metrics?.forEach(metric => {
        if (metric.date.day() % 6 !== 0) {
            total += (metric.metricValue || 0);
            count ++;
        }
    });

    return count === 0 ? 0 : total / count;
}

export function getTotal (metrics?: ISingleDayMetric[]) {
    let total = 0;
    metrics?.forEach(metric => {
        total += (metric.metricValue || 0);
    });

    return total;
}

export function getServiceTreePath(tree: ServiceTree, cmp: (node: ServiceTreeItem) => boolean) : ServiceTreeItem[] | null {
    for (const root of tree.items) {
        const path = getServiceTreePathHelper(root, cmp);

        if (path != null) return path;
    }

    return null;
}

function getServiceTreePathHelper(node: ServiceTreeItem,  cmp: (node: ServiceTreeItem) => boolean) : ServiceTreeItem[] | null {
    if (cmp(node)) {
        return [node];
    }

    if (!node.c) return null;

    for (const child of node.c) {
        const childPath = getServiceTreePathHelper(child, cmp);

        if (childPath != null) return [node, ...childPath];
    }

    return null;
}

export function exportToCsv<T extends Record<any, any>>(
    data: Array<T>,
    filename?: string,
    columns?: Array<string>,
    columnNames?: Partial<Record<keyof T, string>>,
    formatter?: (key: string, value: any, row: T) => any,
) {
    if (!data || !data.length) return;

    if (!columns) columns = Object.keys(data[0]);

    const nameRowData : string[] = [];

    columns.forEach(col => {
        nameRowData.push((columnNames && columnNames[col]) || String(col));
    });

    let result = nameRowData.join(',') + '\r\n';

    data.forEach((item) => {
        const rowData : string[] = [];

        columns?.forEach(col => {
            const value = formatter ? formatter(col, item[col], item) : String(item[col]);
            rowData.push(`"${value}"`);
        });

        result += rowData.join(',') + '\r\n';
    });

    const url = window.URL.createObjectURL(new Blob([result], { type: "text/csv" }));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", url);
    downloadAnchorNode.setAttribute("download",`${filename}.csv`);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
    window.URL.revokeObjectURL(url);
}

export function exportStringToCsv(
    data: string,
    filename: string,
) {
    const url = window.URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", url);
    downloadAnchorNode.setAttribute("download",`${filename}.csv`);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
    window.URL.revokeObjectURL(url);
}

export const GuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export function sleep(ts: number) {
    return new Promise(resove => {
        setTimeout(resove, ts);
    });
}

export function getPercentage (value: number, digit = 2) {
    if (value * 100 * Math.pow(10, digit) < 1) 
    {
        return " < 0.01%";
    }
    return (value * 100).toFixed(digit) + "%";
}
