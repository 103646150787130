import { EventTypeEnum, OpenNotificationPanelEvent } from "../../../models/Event";
import { Pages, SubPages } from "../../../models/Nav";
import { PcmV2CostMetrics, PcmV2MetricNames, SubstrateV2WikiLink } from "../../../models/constants/PcmV2Constants";
import React, { useEffect, useRef, useState } from "react";
import { getActiveAnomalies, getAnomalyDescription } from "../../../utils/AnomalyDetectionUtils";

import CommonConstants from "../../../models/constants/CommonConstants";
import CostCard from "../../common/costCard/CostCard";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { PcmV2MetricKeys } from "../../../models/PcmV2Models";
import { Section } from "../../common/Section";
import commonStyles from "../../common/styles/Common.less";
import { currencyFormatter } from "../../../utils/currency";
import styles from "./SubstrateCostCards.less";
import { sum } from "lodash";
import { useDateRange } from "../../../hooks/useDateSelector";
import useEventBus from "../../../hooks/useEventBus";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useGetPcmV2Cost } from "../../../hooks/useSubstrateV2Query";
import { useGotoPage } from "../../../hooks/useGotoPage";
import { useMsal } from "@azure/msal-react";
import { useFlights } from "../../../hooks/useSettings";
import { useBoolean } from "@fluentui/react-hooks";
import { IIconProps, IconButton } from "@fluentui/react";
import html2canvas from "html2canvas";
import FeedbackPanel from "../../feedback/FeedbackPanel";
import { EventType, LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { trackEventCallback } from "../../../utils/AppInsights";

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

const SubstrateCostCards: React.FC = () => {
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.SubstrateV2);
    const eventBus = useEventBus<OpenNotificationPanelEvent>(undefined, undefined, []);

    const { startDate, endDate } = useDateRange();
    const { data: totalPcmCost, isLoading } = useGetPcmV2Cost();

    const totalCost = sum(PcmV2CostMetrics.map((key) => (totalPcmCost && totalPcmCost[key] ? totalPcmCost[key] : 0)));

    const [cardWidth, setCardWidth] = useState<number | undefined>(undefined);
    const hiddenCardListRef = useRef<HTMLDivElement>(null);
    const gotoPage = useGotoPage();

    const handleViewMoreAnormalyEvents = () => {
        eventBus.emit<OpenNotificationPanelEvent>(EventTypeEnum.OpenNotificationPanel, undefined);
    };

    const handleCardClick = (key: PcmV2MetricKeys) => {
        if (key === "itemReadCost" || key === "itemWriteCost" || key === "itemQueryCost") {
            gotoPage(`${Pages.SubstrateV2}/${SubPages.Transaction}`);
        } else if (key === "processHostingCost") {
            gotoPage(`${Pages.SubstrateV2}/${SubPages.ProcessHosting}`);
        } else if (key === "networkCost") {
            gotoPage(`${Pages.SubstrateV2}/${SubPages.Network}`);
        } else if (key === "hddCost") {
            gotoPage(`${Pages.SubstrateV2}/${SubPages.Hdd}`);
        } else if (key === "ssdKvCacheCost" || key === "ssdmcdbCost") {
            gotoPage(`${Pages.SubstrateV2}/${SubPages.Ssd}`);
        }
    };

    const onLinkClick =()=> {
        trackEventCallback(LogComponent.SubstrateChartingPane, LogElement.SubstrateWiki, "Substrate COGS Wiki", LogTarget.Button);
    }

    useEffect(() => {
        let maxCardWidth = 0;

        hiddenCardListRef.current?.childNodes.forEach((elem) => {
            maxCardWidth = Math.max(maxCardWidth, (elem as HTMLDivElement).getBoundingClientRect().width);
        });

        setCardWidth(maxCardWidth || undefined);
    }, [isLoading]);

    // User Feedback
    const { instance } = useMsal();
    const { data: flights } = useFlights();
    const account = instance.getActiveAccount();
    const [isFeedbackPanelOpen, { setTrue: openFeedbackPanel, setFalse: dismissFeedbackPanel }] = useBoolean(false);
    const [currentURL, setCurrentURL] = useState<string>('');
    const [currentScreenshotURL, setCurrentScreenshotURL] = useState<string>('');

    const captureScreenshot = async (title: string) => {
        trackEventCallback(LogComponent.Substrate, LogElement.SubstrateOverviewCostCards, "Send Feedback Chart", LogTarget.Button, undefined, EventType.Click);

        const url = window.location.href;
        setCurrentURL(url);

        await openFeedbackPanel();

        const targetElement = document.getElementById(`FeedbackSection_${title}`);

        if (targetElement) {
            setTimeout(() => {
                html2canvas(targetElement).then((canvas) => {
                    const screenshotDataUrl = canvas.toDataURL('image/png');
                    setCurrentScreenshotURL(screenshotDataUrl);
                });
            }, 100);

        }
    };

    const handleDismissFeedbackPanel = () => {
        setCurrentScreenshotURL('');
        dismissFeedbackPanel();
    };

    return (
        <div className={styles.cardContainer} id={`FeedbackSection_TotalSubstrateCost`} >
            {flights?.enableUserFeedback &&
                <IconButton
                    title="Send feedback"
                    className={styles.feedbackIcon}
                    iconProps={feedbackIconProps}
                onClick={() => captureScreenshot("TotalSubstrateCost")}
                />}
            <Section headerProps={{ title: "Total Substrate Cost" }} styles={{ content: "" }} loading={isLoading}>
                <div className={styles.title}>
                    <span className={styles.titleTotalCost}>{currencyFormatter(totalCost)}</span> Total Substrate Cost
                </div>
                {/** Hidden cards used for calculating card width */}
                <div className={`${styles.cardList} ${styles.hiddenCardList}`} ref={hiddenCardListRef}>
                    {PcmV2CostMetrics.map((key, index) => (
                        <div key={key}>
                            <CostCard
                                title={PcmV2MetricNames[key] || ""}
                                color={CommonConstants.DefaultColors[index]}
                                cost={(totalPcmCost && totalPcmCost[key]) || 0}
                            />
                        </div>
                    ))}
                </div>
                <div className={styles.cardList}>
                    {cardWidth !== undefined &&
                        PcmV2CostMetrics.map((key, index) => (
                            <div key={key} style={{ width: cardWidth }}>
                                <CostCard
                                    showTooltip
                                    date={[startDate, endDate]}
                                    title={PcmV2MetricNames[key] || ""}
                                    className={totalPcmCost?.[key] ? styles.activeCard : ""}
                                    color={CommonConstants.DefaultColors[index]}
                                    cost={totalPcmCost?.[key] || 0}
                                    classNames={{ costValue: styles.costValue }}
                                    onClick={() => totalPcmCost?.[key] && handleCardClick(key)}
                                    errors={getActiveAnomalies(anomaliesQuery.data, [key]).map((event) => ({
                                        title: event.category,
                                        message: getAnomalyDescription(event.category, [event.dataSourceName], event.startDate, event.endDate),
                                    }))}
                                    onShowMoreError={handleViewMoreAnormalyEvents}
                                />
                            </div>
                        ))}
                </div>
                <div className={styles.description}>
                    <div>
                        Substrate Platform Cost Metrics (PCM) is a set of metrics that help partners understand the cost incurred on Substrate platform and inform
                        actionable COGS reduction practices. In this new version of PCM model (v2), we improved the pricing methodology to more accurately reflect
                        the actual cost incurred by the features built on Substrate platform.
                    </div>
                    <div>
                        <a href={SubstrateV2WikiLink} className={commonStyles.link} target="_blank" rel="noreferrer" onClick={onLinkClick}>
                            Substrate COGS Wiki
                        </a>
                    </div>
                </div>
            </Section>
            <FeedbackPanel
                isPanelOpen={isFeedbackPanelOpen}
                onDismiss={handleDismissFeedbackPanel}
                userName={account?.username}
                currentURL={currentURL}
                screenshotInputURL={currentScreenshotURL}
                FeedbackTab={LogComponent.SubstrateV2}
            />
        </div>
    );
};

export default SubstrateCostCards;
