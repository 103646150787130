import { ComponentItem, ComponentItemResponse, ISubscription, PriceModel, ServiceTree, ServiceTreeBusinessOwners, ServiceTreeItem } from "../models/serviceTree";

import { getJson } from "../utils/apiServiceBase";

export const SearchproviderV1BaseUrl = 'api/v1.0/searchprovider';
export const SearchproviderV2BaseUrl = 'api/v2.0/searchprovider';
export const ServicetreeV1BaseUrl = 'api/v1.0/servicetree';
export const BusinessOwnersMappingV1BaseUrl = 'api/v1.0/businessownersmapping';

export async function getServiceTree(): Promise<ServiceTree> {
    const items = await getJson<ServiceTreeItem[]>(`${ServicetreeV1BaseUrl}/latestversion`);
    const compareFn = (a: ServiceTreeItem, b: ServiceTreeItem) => a.n.localeCompare(b.n);
    for (let toSort = [items], cur = toSort.pop(); cur; cur = toSort.pop()) {
        cur.sort(compareFn);
        for (const item of cur) {
            if (item.c) {
                item.c.forEach(child => child.p = item);
                toSort.push(item.c);
            }
        }
    }
    return { items };
}

export async function getVdirs(): Promise<Map<string, ComponentItem[]>> {
    return await getComponentMap(`${SearchproviderV1BaseUrl}/vdirs`);
}

export async function getProcesses(): Promise<Map<string, ComponentItem[]>> {
    return await getComponentMap(`${SearchproviderV1BaseUrl}/processes`);
}

export async function getScenarioTags(): Promise<Map<string, ComponentItem[]>> {
    return await getComponentMap(`${SearchproviderV1BaseUrl}/scenariotags`);
}

export async function getGriffinApps(): Promise<Map<string, ComponentItem[]>> {
    return await getComponentMap(`${SearchproviderV1BaseUrl}/griffinapps`);
}

export async function getStoreClients(): Promise<Map<string, ComponentItem[]>> {
    return await getComponentMap(`${SearchproviderV1BaseUrl}/cilents`);
}

export async function getStoreClientComponents(): Promise<Map<string, ComponentItem[]>> {
    return await getComponentMap(`${SearchproviderV1BaseUrl}/clientcomponents`);
}

export async function getSSDSetName(): Promise<Map<string, ComponentItem[]>> {
    return await getComponentMap(`${SearchproviderV1BaseUrl}/ssdsets`);
}

export async function getPriceModel(): Promise<PriceModel> {
    const response = await getJson<PriceModel>(`${SearchproviderV1BaseUrl}/pricemodel`);
    return response;
}

export async function getGriffinProcessors(): Promise<Map<string, ComponentItem[]>> {
    return await getComponentMap(`${SearchproviderV1BaseUrl}/griffinprocessors`);
}

export async function getAppScenarios(): Promise<Map<string, string>> {
    return await getAppScenarioDetails(`${SearchproviderV1BaseUrl}/appscenarios`);
}

export async function getOwners(): Promise<Map<string, ComponentItem[]>> {
    const owners = await getJson<string[]>(`${SearchproviderV1BaseUrl}/owners`);
    const result = new Map<string, ComponentItem[]>();

    for (const owner of owners) {
        const item: ComponentItem = {
            name: owner.toLowerCase(),
            serviceName: "",
            serviceGroupName: "",
            teamGroupName: "",
            organizationName: "",
            divisionName: ""
        };
        result.set(item.name.toLowerCase(), [...(result.get(item.name) || []), item]);
    }

    return result;
}

export async function getAzureSubscriptions(): Promise<ISubscription[]> {
    return await getJson<ISubscription[]>(`${SearchproviderV1BaseUrl}/subscriptions`);
}

export async function getClusterIds(): Promise<string[]> {
    return await getJson<string[]>(`${SearchproviderV1BaseUrl}/clusterids`);
}

async function getAppScenarioDetails(url: string): Promise<Map<string, string>> {
    const list = await getJson<{appName: string, tags: string}[]>(url);
    const map = new Map<string, string>();
    for (const item of list) {
        map.set(item.appName, item.tags);
        map.set(item.appName.toLocaleLowerCase(), item.tags);
    }
    return map;
}

async function getComponentMap(url: string): Promise<Map<string, ComponentItem[]>> {
    const list = await getJson<ComponentItemResponse[]>(url);
    const result = new Map<string, ComponentItem[]>();

    for (const responseItem of list) {
        const item: ComponentItem = {
            name: responseItem.n,
            serviceName: responseItem.sn,
            serviceGroupName: responseItem.sgn,
            teamGroupName: responseItem.tgn,
            organizationName: responseItem.on,
            divisionName: responseItem.dn
        };
        
        result.set(item.name, [...(result.get(item.name) || []), item]);
    }

    return result;
}

export async function listAllBusinessOwners(): Promise<ServiceTreeBusinessOwners[]> {
    const url = `${BusinessOwnersMappingV1BaseUrl}/listall`;
    const response = await getJson<ServiceTreeBusinessOwners[]>(url);
    return response;
}

export async function getPlatformServiceIds(): Promise<string[]> {
    return await getJson<string[]>(`${SearchproviderV2BaseUrl}/platform/serviceids`);
}

export async function getPlatformProcesses(): Promise<string[]> {
    return await getJson<string[]>(`${SearchproviderV2BaseUrl}/platform/processes`);
}

export async function getPlatformApps(): Promise<string[]> {
    return await getJson<string[]>(`${SearchproviderV2BaseUrl}/platform/appnames`);
}

export async function getPlatformSubApps(): Promise<string[]> {
    return await getJson<string[]>(`${SearchproviderV2BaseUrl}/platform/subapps`);
}

export async function getPlatformClients(): Promise<string[]> {
    return await getJson<string[]>(`${SearchproviderV2BaseUrl}/platform/clients`);
}

export async function getPlatformClientComponents(): Promise<string[]> {
    return await getJson<string[]>(`${SearchproviderV2BaseUrl}/platform/clientcomponents`);
}