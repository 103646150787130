// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OqpBnCUXOQ_Rf0tOEtXR {\n  overflow: hidden;\n  color: #900;\n  text-overflow: ellipsis;\n  font-family: \"Segoe UI\";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 22px;\n}\n.zHzc5u5ThCBoOBT16st9 {\n  color: #900;\n  font-family: \"Segoe UI\";\n  font-size: 10px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 14px;\n}\n.v1ZeljUOJtxanXrFBeiN {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  font-family: \"Segoe UI\";\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 16px;\n}\n.gGlAK4Z8BMquDOlQEkZB {\n  color: #900;\n}\n.F9GmduHozJbflFnHiKIw {\n  color: #107C10;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EfficiencyTrackerV2/Components/Tools/MonthTableComponent.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,WAAA;EACA,uBAAA;EACA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AAEA;EACI,WAAA;EAEA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAIA;EACI,gBAAA;EACA,uBAAA;EAEA,uBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAHJ;AAMA;EACI,WAAA;AAJJ;AAOA;EACI,cAAA;AALJ","sourcesContent":[".gap {\n    overflow: hidden;\n    color: #900;\n    text-overflow: ellipsis;\n    font-family: \"Segoe UI\";\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 22px;\n}\n\n.trend {\n    color: #900;\n\n    font-family: \"Segoe UI\";\n    font-size: 10px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 14px;\n}\n\n.content {\n    overflow: hidden;\n    text-overflow: ellipsis;\n\n    font-family: \"Segoe UI\";\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 16px;\n}\n\n.red {\n    color: #900;\n}\n\n.green {\n    color: #107C10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gap": "OqpBnCUXOQ_Rf0tOEtXR",
	"trend": "zHzc5u5ThCBoOBT16st9",
	"content": "v1ZeljUOJtxanXrFBeiN",
	"red": "gGlAK4Z8BMquDOlQEkZB",
	"green": "F9GmduHozJbflFnHiKIw"
};
export default ___CSS_LOADER_EXPORT___;
