import { PcmV2AggeratedLevel, PcmV2Cost, PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum, SubstrateCarbonEmissionData, SubstrateCostV2 } from "../models/PcmV2Models";
import { useEffect, useMemo } from "react";

import { IAppState } from "../store";
import { ServiceTreeItem } from "../models/serviceTree";
import { SubstrateV2Service } from "../services/SubstrateV2Service";
import { UseQueryResult } from "react-query";
import { parseFiltersToJson } from "../models/FilterView";
import { sumCosts } from "../utils/PcmV2Utils";
import { useCategoryFilters } from "./useFilters";
import { useCustomQuery } from "./useCustomQuery";
import { useDateRange } from "./useDateSelector";
import { useFlights } from "./useSettings";
import { useRings } from "./useRings";
import { useSelector } from "react-redux";
import { Moment } from "moment";
import { AbnormalIssue, CogsInfo, MetricCostReport } from "../models/IOInsightsModels"

export function useGetPcmV2Cost() {
    const { data: flights } = useFlights();
    const enabled = flights?.enableSubstrateV2 ?? false;
    const { startDate, endDate } = useDateRange();
    const { filters } = useCategoryFilters();
    const [rings] = useRings();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);

    return useCustomQuery(SubstrateV2Service.getPCMV2Cost, parseFiltersToJson(filters.filters, serviceIdMap), startDate, endDate, rings, enabled);
}

export function useGetPcmV2CostByRing() {
    const { startDate, endDate } = useDateRange();
    const { filters } = useCategoryFilters();
    const [rings] = useRings();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);

    return useCustomQuery(SubstrateV2Service.getPCMV2CostByRing, parseFiltersToJson(filters.filters, serviceIdMap), startDate, endDate, rings);
}

export function useGetPcmV2CostByCategory() {
    const { startDate, endDate } = useDateRange();
    const { filters } = useCategoryFilters();
    const [rings] = useRings();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);

    return useCustomQuery(SubstrateV2Service.getPCMV2CostByCategory, parseFiltersToJson(filters.filters, serviceIdMap), startDate, endDate, rings);
}

export function useGetPcmV2MonthlyCostByCategory() {
    const { lastYear, lastMonth } = useDateRange();
    const { filters } = useCategoryFilters();
    const [rings] = useRings();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);

    return useCustomQuery(SubstrateV2Service.getPCMV2MonthlyCostByCategory, parseFiltersToJson(filters.filters, serviceIdMap), lastYear, lastMonth, rings);
}

export function useGetPCMV2CostByService(scene: PcmV2SceneTypeEnum, subScene?: PcmV2SubSceneTypeEnum) {
    const { startDate, endDate } = useDateRange();
    const { filters } = useCategoryFilters();
    const [rings] = useRings();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);
    const filterRequestData = parseFiltersToJson(filters.filters, serviceIdMap);

    let targetGetter = SubstrateV2Service.getPCMV2CostByService;

    if (subScene === PcmV2SubSceneTypeEnum.TransactionScenarioTagCost) {
        targetGetter = SubstrateV2Service.getPCMV2TransactionCostByService;
    } else if (subScene === PcmV2SubSceneTypeEnum.NetworkScenarioTagCost) {
        targetGetter = SubstrateV2Service.getPCMV2NetworkCostByService;
    }

    return useCustomQuery(
        targetGetter,
        filterRequestData,
        startDate,
        endDate,
        rings,
    );
}

export function useGetPcmV2TopCostTrends(scene: PcmV2SceneTypeEnum, subScene: PcmV2SubSceneTypeEnum | undefined, type: PcmV2AggeratedLevel) {
    const { startDate, endDate } = useDateRange();
    const { filters } = useCategoryFilters();
    const [rings] = useRings();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);

    return useCustomQuery(
        type == PcmV2AggeratedLevel.Workload ? SubstrateV2Service.getTopPcmV2CostTrendByWorkload : SubstrateV2Service.getTopPcmV2CostTrendByService,
        parseFiltersToJson(filters.filters, serviceIdMap),
        startDate,
        endDate,
        rings,
        scene,
        subScene
    );
}

export function useGetPcmV2TopCost(scene: PcmV2SceneTypeEnum, subScene: PcmV2SubSceneTypeEnum | undefined, type: PcmV2AggeratedLevel) {
    const query = useGetPcmV2TopCostTrends(scene, subScene, type);

    const serviceCosts: PcmV2Cost[] = useMemo<PcmV2Cost[]>(() => {
        if (!query.data) {
            return [];
        }

        const result = Object.keys(query.data)
            .map((id) => {
                const cost = sumCosts(query.data[id]);

                if (cost) {
                    cost.identity.workload = id;
                }

                return cost;
            })
            .filter((item) => !!item) as PcmV2Cost[];

        return result.sort((a, b) => b.cost - a.cost);
    }, [query.data]);

    return {
        ...query,
        data: serviceCosts,
    };
}

export function useGetStorageCost(scene: PcmV2SceneTypeEnum, subScene?: PcmV2SubSceneTypeEnum) {
    const { startDate, endDate } = useDateRange();
    const { filters } = useCategoryFilters();
    const [rings] = useRings();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);

    return useCustomQuery(
        SubstrateV2Service.getAggregatedStorageCost,
        parseFiltersToJson(filters.filters, serviceIdMap),
        startDate,
        endDate,
        rings,
        scene,
        subScene
    );
}

export function useGetSubStorageCost(scene: PcmV2SceneTypeEnum, subScene: PcmV2SubSceneTypeEnum | undefined, targetComponent: string) {
    const { startDate, endDate } = useDateRange();
    const { filters } = useCategoryFilters();
    const [rings] = useRings();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);

    return useCustomQuery(
        SubstrateV2Service.getSubStorageCost,
        parseFiltersToJson(filters.filters, serviceIdMap),
        startDate,
        endDate,
        rings,
        scene,
        subScene,
        targetComponent
    );
}

export function useGetCarbonEmission(scene: PcmV2SceneTypeEnum, enabled = false) {
    const { startDate, endDate } = useDateRange();
    const { filters } = useCategoryFilters();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);
    const filterRequestData = parseFiltersToJson(filters.filters, serviceIdMap);

    const carbonQuery = useCustomQuery(SubstrateV2Service.getCarbonEmission, filterRequestData, startDate, endDate, scene, enabled);

    const data = useMemo(() => {
        if (!carbonQuery.data) {
            return undefined;
        }

        const carbonEmissionByService: Record<string, SubstrateCarbonEmissionData> = {};
        const carbonEmissionByGriffinApp: Record<string, SubstrateCarbonEmissionData> = {};

        for (const serviceId in carbonQuery.data) {
            const serviceCarbonData: SubstrateCarbonEmissionData = {
                appName: serviceId,
                substrateSource: "",
                totalCarbonEmission: 0,
                carbonScope2Emission: 0,
                carbonScope3Emission: 0,
            };

            for (const appCarbonData of carbonQuery.data[serviceId]) {
                const substrateSource = appCarbonData.substrateSource;
                carbonEmissionByGriffinApp[substrateSource + "#" + serviceId + "#" + appCarbonData.appName?.toUpperCase()] = appCarbonData;
                serviceCarbonData.totalCarbonEmission += appCarbonData.totalCarbonEmission;
                serviceCarbonData.carbonScope2Emission += appCarbonData.carbonScope2Emission;
                serviceCarbonData.carbonScope3Emission += appCarbonData.carbonScope3Emission;
            }

            carbonEmissionByService[serviceId] = serviceCarbonData;
        }

        return { carbonEmissionByService, carbonEmissionByGriffinApp };
    }, [carbonQuery.data]);

    return {
        ...carbonQuery,
        data,
    };
}

export function useGetIOInsights(scene: PcmV2SceneTypeEnum) {
    const { startDate, endDate } = useDateRange();
    const [rings] = useRings();
    const { filters } = useCategoryFilters();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);

    return useCustomQuery(
        SubstrateV2Service.getIOInsights,
        parseFiltersToJson(filters.filters, serviceIdMap),
        startDate,
        endDate,
        rings,
        scene,
    );
}
export function useGetIOInsightsByServiceId(scene: PcmV2SceneTypeEnum) {
    const anomalies = useGetIOInsights(scene);
    const results = [] as Array<anomalyResult>
    if (anomalies.data != undefined)
    {
        for (const abnormalIssue of anomalies.data as AbnormalIssue[]) {
            const iRWQAbnormalCosts: MetricCostReport = JSON.parse(abnormalIssue.abnormalCost.toString());
            const iRWQBaselineCosts: MetricCostReport = JSON.parse(abnormalIssue.baselineCost.toString());

            const readsAbnormalCosts: Map<Moment, CogsInfo> = JSON.parse(JSON.stringify(iRWQAbnormalCosts.ItemReadTotalCost));
            const writesAbnormalCosts: Map<Moment, CogsInfo> = JSON.parse(JSON.stringify(iRWQAbnormalCosts.ItemWriteTotalCost));
            const queriesAbnormalCosts: Map<Moment, CogsInfo> = JSON.parse(JSON.stringify(iRWQAbnormalCosts.ItemQueryTotalCost));
            const readsBaselineCosts: Map<Moment, CogsInfo> = JSON.parse(JSON.stringify(iRWQBaselineCosts.ItemReadTotalCost));
            const writesBaselineCosts: Map<Moment, CogsInfo> = JSON.parse(JSON.stringify(iRWQBaselineCosts.ItemWriteTotalCost));
            const queriesBaselineCosts: Map<Moment, CogsInfo> = JSON.parse(JSON.stringify(iRWQBaselineCosts.ItemQueryTotalCost));

            let itemReadTotalCost = 0;
            let itemWriteTotalCost = 0;
            let itemQueryTotalCost = 0;

            let iRBaselineCost = 0;
            let iWBaselineCost = 0;
            let iQBaselineCost = 0;

            for (const [momentString, costInfo] of Array.from(Object.entries(readsAbnormalCosts))) {
                itemReadTotalCost += costInfo.Cost;
            }
            itemReadTotalCost = itemReadTotalCost / Object.keys(readsAbnormalCosts).length * 30;

            for (const [momentString, costInfo] of Array.from(Object.entries(writesAbnormalCosts))) {
                itemWriteTotalCost += costInfo.Cost;
            }
            itemWriteTotalCost = itemWriteTotalCost / Object.keys(writesAbnormalCosts).length * 30;

            for (const [momentString, costInfo] of Array.from(Object.entries(queriesAbnormalCosts))) {
                itemQueryTotalCost += costInfo.Cost;
            }
            itemQueryTotalCost = itemQueryTotalCost / Object.keys(queriesAbnormalCosts).length * 30;

            for (const [momentString, costInfo] of Array.from(Object.entries(readsBaselineCosts))) {
                iRBaselineCost += costInfo.Cost;
            }
            iRBaselineCost = iRBaselineCost / Object.keys(readsBaselineCosts).length * 30;

            for (const [momentString, costInfo] of Array.from(Object.entries(writesBaselineCosts))) {
                iWBaselineCost += costInfo.Cost;
            }
            iWBaselineCost = iWBaselineCost / Object.keys(writesBaselineCosts).length * 30;

            for (const [momentString, costInfo] of Array.from(Object.entries(queriesBaselineCosts))) {
                iQBaselineCost += costInfo.Cost;
            }
            iQBaselineCost = iQBaselineCost / Object.keys(queriesBaselineCosts).length * 30;

            const result: anomalyResult = {
            startDate : abnormalIssue.startTime,
            endDate : abnormalIssue.endTime,
            ring : abnormalIssue.ring,
            serviceId : abnormalIssue.serviceId,
            workloadName : abnormalIssue.workloadName,
            workloadType : abnormalIssue.workloadType,
            effectedPCMMetrics: abnormalIssue.effectedPCMMetrics,
            itemReadTotalCost : itemReadTotalCost - iRBaselineCost,
            itemWriteTotalCost : itemWriteTotalCost - iWBaselineCost,
            itemQueryTotalCost : itemQueryTotalCost - iQBaselineCost,
            externalId : abnormalIssue.externalID 
            }

            results.push(result);
        }
    }
    return results;
}

export interface anomalyResult {
    startDate: Moment,
    endDate: Moment,
    ring: string,
    serviceId: string,
    workloadName: string,
    workloadType: string,
    effectedPCMMetrics: string,
    itemReadTotalCost: number,
    itemWriteTotalCost: number,
    itemQueryTotalCost: number,
    externalId: string,
}

export enum AnomalyTotalCostTypeEnum {
    Read = "Item Read Cost",
    Write = "Item Write Cost",
    Query = "Item Query Cost",
}

export function useGetMemoryRedundancyData(scene: PcmV2SceneTypeEnum) {
    const { startDate, endDate } = useDateRange();
    const [rings] = useRings();
    const { filters } = useCategoryFilters();
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);

    return useCustomQuery(
        SubstrateV2Service.getMemoryRedundancyData,
        parseFiltersToJson(filters.filters, serviceIdMap),
        startDate,
        endDate,
        rings,
        scene,
    );
}