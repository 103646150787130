// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h98qOOsH8YwgWvWFPuE3 {\n  font-size: 14px;\n  width: 614px;\n  max-width: 100%;\n}\n.CAI7DFsfAJxCQaMWvj43 {\n  margin-bottom: 24px;\n}\n.denpYlg3nxlGQSrykPuE {\n  font-weight: 600;\n  font-size: 9px;\n  padding: 5px 8px;\n  color: #FFFFFF;\n  background: #0078D4;\n  margin-left: 8px;\n  border-radius: 2px;\n  margin-top: 1px;\n}\n.m2xyABQs6jsYm90GTT2I {\n  font-size: 28px;\n  line-height: 36px;\n  font-weight: 700;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/PageHeader.less"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,YAAA;EACA,eAAA;AAAJ;AAGA;EACI,mBAAA;AADJ;AAIA;EACI,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;AAFJ;AAKA;EACI,eAAA;EACA,iBAAA;EACA,gBAAA;AAHJ","sourcesContent":["\n.content {\n    font-size: 14px;\n    width: 614px;\n    max-width: 100%;\n}\n\n.header {\n    margin-bottom: 24px;\n}\n\n.tag {\n    font-weight: 600;\n    font-size: 9px;\n    padding: 5px 8px;\n    color: #FFFFFF;\n    background: #0078D4;\n    margin-left: 8px;\n    border-radius: 2px;\n    margin-top: 1px;\n}\n\n.title {\n    font-size: 28px;\n    line-height: 36px;\n    font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "h98qOOsH8YwgWvWFPuE3",
	"header": "CAI7DFsfAJxCQaMWvj43",
	"tag": "denpYlg3nxlGQSrykPuE",
	"title": "m2xyABQs6jsYm90GTT2I"
};
export default ___CSS_LOADER_EXPORT___;
