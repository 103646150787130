import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey } from "../models/SearchKey";
import { ESCostBillingType } from "../models/ESCostModels";

export function useEsCostResource(): [ESCostBillingType[], (newResource?: ESCostBillingType[]) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const resources: ESCostBillingType[] = useMemo(() => {
        const resourceStr = searchParams.get(SearchKey.ESCostResource);
        if (!resourceStr) {
            return [];
        }
        return JSON.parse(resourceStr);
    }, [searchParams]);

    const setEsCostResource = useCallback((newResource?: ESCostBillingType[]) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (!newResource || newResource.length === 0) {
            newSearchParams.delete(SearchKey.ESCostResource);
        } else {
            newSearchParams.set(SearchKey.ESCostResource, JSON.stringify(newResource));
        }

        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return [resources, setEsCostResource];
}