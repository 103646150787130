import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey } from "../models/SearchKey";
import { ESUsageScenarios } from "../models/ESCostModels";

export function useEsUsageScenarios(): [ESUsageScenarios[], (newUsageScenarios?: ESUsageScenarios[]) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const usageScenarios: ESUsageScenarios[] = useMemo(() => {
        const usageScenarioStr = searchParams.get(SearchKey.ESUsageScenarios);
        if (!usageScenarioStr) {
            return [];
        }
        return JSON.parse(usageScenarioStr);
    }, [searchParams]);

    const setEsUsageScenarios = useCallback((newUsageScenarios?: ESUsageScenarios[]) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (!newUsageScenarios || newUsageScenarios.length === 0) {
            newSearchParams.delete(SearchKey.ESUsageScenarios);
        } else {
            newSearchParams.set(SearchKey.ESUsageScenarios, JSON.stringify(newUsageScenarios));
        }

        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return [usageScenarios, setEsUsageScenarios];
}