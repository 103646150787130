import { INavLinkGroup, Stack, Nav, INavLink, IRenderGroupHeaderProps, INavStyles, calculatePrecision } from "@fluentui/react";
import React, { useCallback } from "react";
import styles from "./ESNavigation.less";
import { Pages } from "../../models/Nav";
import { getTabCache } from "../../utils/pageUtils";
import { useGotoPage } from "../../hooks/useGotoPage";
import { LogComponent, LogTarget } from "../../models/LogModel";
import { trackEventCallback } from "../../utils/AppInsights";
import { useLocation } from "react-router-dom";
import { useFlights } from "../../hooks/useSettings";

const jawsLink: INavLinkGroup[] = [
    {
        links: [
            {
                name: "Jaws",
                icon: "LineChart",
                url: "/",
            },
        ],
    },
];

const esLinks: INavLinkGroup[] = [
    {
        links: [
            {
                name: "ES365 Cost Overview",
                key: Pages.ESCost,
                url: Pages.ESCost,
            },
        ],
    },
];

const cbLinks: INavLinkGroup[] = [
    {
        name: "CloudBuild",
        links: [
            {
                name: "Overview",
                key: `${Pages.ESCost}/${Pages.ESCostCloudBuild}`,
                url: `${Pages.ESCost}/${Pages.ESCostCloudBuild}`,
            },
            {
                name: "Users and infra",
                key: `${Pages.ESCost}/${Pages.ESCostCloudBuildGEMs}`,
                url: `${Pages.ESCost}/${Pages.ESCostCloudBuildGEMs}`,
            },
            {
                name: "Usage Scenarios",
                key: `${Pages.ESCost}/${Pages.ESCostCloudBuildUsageScenarios}`,
                url: `${Pages.ESCost}/${Pages.ESCostCloudBuildUsageScenarios}`,
            },
        ],
    },
];

const artifactLinks: INavLinkGroup[] = [
    {
        name: "Artifact",
        links: [
            {
                name: "Overview",
                key: `${Pages.ESCost}/${Pages.ESCostArtifact}`,
                url: `${Pages.ESCost}/${Pages.ESCostArtifact}`,
            },
            {
                name: "Details",
                key: `${Pages.ESCost}/${Pages.ESCostArtifactDetails}`,
                url: `${Pages.ESCost}/${Pages.ESCostArtifactDetails}`,
            }
        ],
    },
];

const azureLinks: INavLinkGroup[] = [
    {
        name: "Azure",
        links: [
            {
                name: "Overview",
                key: `${Pages.ESCost}/${Pages.ESCostAzure}`,
                url: `${Pages.ESCost}/${Pages.ESCostAzure}`,
            },
            {
                name: "Details",
                key: `${Pages.ESCost}/${Pages.ESCostAzureDetails}`,
                url: `${Pages.ESCost}/${Pages.ESCostAzureDetails}`,
            }
        ],
    },
];

const jawLinkStyles: Partial<INavStyles> = {
    root: {
        width: "100%",
        boxSizing: "border-box",
        border: "1px solid #eee",
        overflowY: "auto",
    },
    groupContent: {
        marginBottom: 12,
    },
    linkText: {
        fontWeight: 600,
        fontSize: "16px",
    },
    link: {
        paddingLeft: 16,
    },
};

const linkStyles: Partial<INavStyles> = {
    groupContent: {
        marginBottom: 12,
        marginTop: 12,
    },
    linkText: {
        left: "48px",
        top: calculatePrecision((50 % -"20px") / 2),
        height: "20px",
        width: "184px",
        fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
    },
    link: {
        paddingLeft: 0,
        selectors: {
            "&:after": {
                "border-left": "4px solid",
                "border-left-color": "#0078D4",
            },
        },
        ".ms-Icon": {
            "font-size": "14px",
            color: "#323130",
            width: 16,
        },
        ".ms-Button-flexContainer": {
            "margin-left": "12px",
        },
    },
};

const cbLinkStyles: Partial<INavStyles> = {
    groupContent: {
        marginBottom: 12,
    },
    linkText: {
        left: "48px",
        top: calculatePrecision((50 % -"20px") / 2),
        height: "20px",
        width: "184px",
        fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
    },
    link: {
        selectors: {
            "&:after": {
                "border-left": "4px solid",
                "border-left-color": "#0078D4",
            },
        },
        ".ms-Icon": {
            "font-size": "14px",
            color: "#323130",
            width: 16,
        },
        ".ms-Button-flexContainer": {
            "margin-left": "12px",
        },
    },
};

const _onRenderGroupHeader = (group?: IRenderGroupHeaderProps): JSX.Element | null => {
    if (!group) return null;
    return <div className={styles.groupHeader}>{group.name}</div>;
};

const getActivePage = (path: string): string => {
    if (path.startsWith(`${Pages.ESCost}/${Pages.ESCostCloudBuild}`)) {
        return `${Pages.ESCost}/${Pages.ESCostCloudBuild}`;
    } else if (path.startsWith(`${Pages.ESCost}/${Pages.ESCostCloudBuildGEMs}`)) {
        return `${Pages.ESCost}/${Pages.ESCostCloudBuildGEMs}`;
    } else if (path.startsWith(`${Pages.ESCost}/${Pages.ESCostCloudBuildUsageScenarios}`)) {
        return `${Pages.ESCost}/${Pages.ESCostCloudBuildUsageScenarios}`;
    } else if (path.startsWith(`${Pages.ESCost}/${Pages.ESCostArtifact}`)) {
        return `${Pages.ESCost}/${Pages.ESCostArtifact}`;
    } else if (path.startsWith(`${Pages.ESCost}/${Pages.ESCostArtifactDetails}`)) {
        return `${Pages.ESCost}/${Pages.ESCostArtifactDetails}`;
    } else if (path.startsWith(`${Pages.ESCost}/${Pages.ESCostAzureDetails}`)) {
        return `${Pages.ESCost}/${Pages.ESCostAzureDetails}`;
    } else if (path.startsWith(`${Pages.ESCost}/${Pages.ESCostAzure}`)) {
        return `${Pages.ESCost}/${Pages.ESCostAzure}`;
    }
    if (path.startsWith(Pages.ESCost)) {
        return Pages.ESCost;
    } else {
        return path;
    }
};

const ESNavigation: React.FC = () => {
    const { data: flights } = useFlights();
    const location = useLocation();
    const gotoPage = useGotoPage();
    const handleClick = useCallback(
        (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
            if (item?.target !== "_blank") {
                ev?.preventDefault();
                if (item?.url) {
                    const tab = getTabCache(item.tabKey || item.url);
                    gotoPage(item.url + (tab ? `/${tab}` : ""));
                }
            }
            trackEventCallback(LogComponent.PivotNavPane, item?.logElement, item?.title || "", LogTarget.Nav);
        },
        [gotoPage]
    );

    return (
        <Stack className={styles.navigation} disableShrink>
            <Nav selectedKey={getActivePage(location.pathname)} styles={jawLinkStyles} groups={jawsLink} onLinkClick={handleClick} />
            <Stack.Item shrink={0}>
                <div className={styles.separator} />
            </Stack.Item>
            <Nav
                selectedKey={getActivePage(location.pathname)}
                styles={linkStyles}
                onRenderGroupHeader={_onRenderGroupHeader}
                groups={esLinks}
                onLinkClick={handleClick}
            />
            {flights?.enableES365CostCloudBuild && (
                <Nav
                    selectedKey={getActivePage(location.pathname)}
                    styles={cbLinkStyles}
                    onRenderGroupHeader={_onRenderGroupHeader}
                    groups={cbLinks}
                    onLinkClick={handleClick}
                />
            )}
            {flights?.enableES365CostArtifacts && (
                <Nav
                    selectedKey={getActivePage(location.pathname)}
                    styles={cbLinkStyles}
                    onRenderGroupHeader={_onRenderGroupHeader}
                    groups={artifactLinks}
                    onLinkClick={handleClick}
                />
            )}
            {flights?.enableES365CostAzure && (
                <Nav
                    selectedKey={getActivePage(location.pathname)}
                    styles={cbLinkStyles}
                    onRenderGroupHeader={_onRenderGroupHeader}
                    groups={azureLinks}
                    onLinkClick={handleClick}
                />
            )}
        </Stack>
    );
};

export default ESNavigation;
