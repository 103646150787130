import React from "react";
import { useEsOrg } from "../../../../hooks/useEsOrg";
import {
    ESCostDataDimensions,
    ESCostOrganization,
    ESManagerFYReturnTypeCostRawResponse,
    ESOverviewTimeSelection,
    ESCostRawUserType,
} from "../../../../models/ESCostModels";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import { useEsManagers } from "../../../../hooks/useEsManagers";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import StackChart, { IStackChartDataProps } from "../Common/StackChart";
import { ESOrgs } from "../../../../models/constants/ESCostConstants";

const top = 5;

interface IManagerReturnTypeStackChartProps {
    data: ESManagerFYReturnTypeCostRawResponse[];
}

function reduceCosts(costs: ESManagerFYReturnTypeCostRawResponse[], managers: string[]): IStackChartDataProps[] {
    const returnTypes: string[] = [...new Set(costs.map((cost) => cost[ESCostDataDimensions.ReturnType]))];
    returnTypes.sort();
    const result = returnTypes.map((returnType) => ({ name: returnType, values: Array.from({ length: managers.length }, () => 0) }));

    costs.forEach((cost) => {
        const i = returnTypes.indexOf(cost[ESCostDataDimensions.ReturnType]);
        const j = managers.indexOf(cost[ESCostDataDimensions.UserName]);
        result[i].values[j] += cost[ESCostDataDimensions.Cost];
    });

    return result;
}

function filterTopTotalCostManagers(cost: ESManagerFYReturnTypeCostRawResponse[], managers: string[]): string[]  {
    const sums: { [key: string]: number } = {};
    if (managers.length === 0) {
        const s = new Set<string>();
        cost.forEach((c) => {
            s.add(c[ESCostDataDimensions.UserName]);
        });
        managers = Array.from(s);
    }
    managers.forEach((m) => (sums[m] = 0));
    cost.forEach((c) => {
        sums[c[ESCostDataDimensions.UserName]] += c[ESCostDataDimensions.Cost];
    });
    const sortedKeys = Object.keys(sums).sort((a, b) => sums[b] - sums[a]);
    return sortedKeys.slice(0, top) as string[];
}

function getDataAndOptions(rawCost: ESManagerFYReturnTypeCostRawResponse[], org: ESCostOrganization[], managers: string[], year: number) {
    const costs = rawCost.filter(
        (cost) =>
            cost[ESCostDataDimensions.UserType] === ESCostRawUserType.Human &&
            ESOrgs.includes(cost[ESCostDataDimensions.Organization]) &&
            (org.length === 0 || org.includes(cost[ESCostDataDimensions.Organization])) &&
            (managers.length === 0 || managers.includes(cost[ESCostDataDimensions.UserName])) &&
            cost[ESCostDataDimensions.ReturnFiscalYear] === year
    );
    const topManagers: string[] = filterTopTotalCostManagers(costs, managers);

    return {
        data: reduceCosts(costs.filter((c) => topManagers.includes(c[ESCostDataDimensions.UserName])), topManagers),
        categories: topManagers,
    };
}

const ManagersReturnTypeStackChart = (props: IManagerReturnTypeStackChartProps) => {
    const { data: rawCost } = props;
    const [org] = useEsOrg();
    const [managers] = useEsManagers();
    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );

    const { data, categories } = React.useMemo(() => getDataAndOptions(rawCost, org, managers, year), [managers, org, rawCost, year]);
    return (
        <StackChart 
            data={data}
            categories={categories}
            description={`Top${categories.length} cost of selected managers (FY${String(year).substring(2)})`}
            yAxisTitle="Actual cost" />
    );
};

export default ManagersReturnTypeStackChart;
