import {
    ChoiceGroup,
    ComboBox,
    DefaultButton,
    Dialog,
    DialogType,
    Dropdown,
    IButtonStyles,
    IChoiceGroupOption,
    IComboBox,
    IComboBoxOption,
    IComboBoxStyles,
    IDialog,
    IDropdownOption,
    IDropdownStyles,
    IPanel,
    IPanelStyles,
    Panel,
    PanelType,
    PrimaryButton,
} from "@fluentui/react";
import { FiltersAction, FiltersView } from "../../../reducers/filterReducer";
import { IServiceTreeData, getCategoryByServiceTreeLevel } from "../../../reducers/serviceTreeReducer";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { ServiceTree, ServiceTreeBusinessOwners, ServiceTreeItem } from "../../../models/serviceTree";
import { getServiceItemListByKeys, getViewItemKeyFromFilterCategory } from "../../../utils/FiltersUtils";
import { useDispatch, useSelector } from "react-redux";

import { CategoryDivision } from "../../../models/CategoryDivision";
import { DataNode } from "../../../models/DataNode";
import { IAppState } from "../../../store";
import { IPartialFilterViewContent } from "../../../models/FilterView";
import SavedViews from "../../savedViews/SavedViews";
import TreeSelect from "../../TreeSelect";
import { entries } from "lodash";
import { makeUpdateServiceTreePanelRefAction } from "../../../reducers/actionableItemsReducer";
import { shareViewAction } from "../../../reducers/savedViewReducer";
import styles from "./ShareView.less";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useServiceTreeBusinessOwnersQuery } from "../../../hooks/useServiceTreeQuery";
import { useFlights } from "../../../hooks/useSettings";

interface ShareViewServiceTreeDialog {
    updateStep: Dispatch<SetStateAction<number>>;
    onCancel: (viewType: string) => void;
}

const panelWidthSetting: Record<string, string> = {
    ["Init"]: "550px",
    ["OpenEdit"]: "1000px",
};

const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 300 } };

const ShareViewList: React.FC<ShareViewServiceTreeDialog> = (props) => {
    const { updateStep, onCancel } = props;

    const buttonStyles: IButtonStyles = { root: { marginRight: 16 } };
    const businessOwners = useServiceTreeBusinessOwnersQuery();
    console.log(businessOwners);
    const businessOwnersMap = new Map<string, ServiceTreeBusinessOwners[]>();
    businessOwners.data?.forEach((item) => {
        const key = item.displayName ? item.displayName : item.userAlias;
        if (businessOwnersMap.has(key)) {
            businessOwnersMap.get(key)?.push(item);
        } else {
            businessOwnersMap.set(key, [item]);
        }
    });
    const dropDownOptions = [...businessOwnersMap.keys()].map((item) => ({ key: item, text: item }));
    console.log(dropDownOptions);

    const globalFilters = useCategoryFilters();
    const filtersNextAction = useSelector<IAppState, FiltersAction>((state) => state.filtersNextAction);

    const handleServicesChange = useCallback(
        (businessOwners: string[]) => {
            if (businessOwners.length) {
                const filters: Partial<Record<CategoryDivision, string[]>> = {};
                businessOwners.forEach((item) => {
                    const entitiesOfBusinessOwner = businessOwnersMap.get(item);
                    if (entitiesOfBusinessOwner) {
                        entitiesOfBusinessOwner.forEach((item) => {
                            const category = getServiceTreeCategory(item.entityLevel);
                            if (!filters[category]) {
                                filters[category] = [];
                            }
                            filters[category]?.push(item.entityId);
                        });
                    }
                });
                globalFilters.updateFilters(filtersNextAction, { filters, view: FiltersView.Summary });
                return filters;
            }
        },
        [filtersNextAction, globalFilters]
    );

    function getServiceTreeCategory(category: string) {
        switch (category) {
            case "Division":
                return CategoryDivision.Division;
            case "Organization":
                return CategoryDivision.Organization;
            case "ServiceGroup":
                return CategoryDivision.ServiceGroup;
            case "TeamGroup":
                return CategoryDivision.TeamGroup;
            default:
                return CategoryDivision.Service;
        }
    }

    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: "Share a view",
        styles: {
            innerContent: {height: 500}
        }
    };
    const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

    const onShareViewCallout = function () {
        updateStep(3);
        if (selectedKeys) {
            handleServicesChange(selectedKeys);
        }
    };

    function buildViewFromFilters(filters: Partial<Record<CategoryDivision, string[]>>): IPartialFilterViewContent {
        const filterView: IPartialFilterViewContent = {};
        entries(filters).forEach(([category, value]) => {
            if (!value || value.length === 0) {
                return;
            }

            const key: keyof IPartialFilterViewContent = getViewItemKeyFromFilterCategory(category as CategoryDivision);
            if (key === "Subscription") {
                filterView.Subscription = value.map((filter) => filter.substring(0, filter.indexOf("(")));
            } else {
                filterView[key] = [...value];
            }
        });

        return filterView;
    }
    const onChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        const selected = option?.selected;
        if (option) {
            setSelectedKeys((prevSelectedKeys) =>
                selected ? [...prevSelectedKeys, option!.key as string] : prevSelectedKeys.filter((k) => k !== option!.key)
            );
        }
    };

    return (
        <Dialog hidden={false} modalProps={{ containerClassName: styles.dialogContainer }} maxWidth={1000} dialogContentProps={dialogContentProps}>
            <SavedViews></SavedViews>
            <button
                className={styles.dialogCancelButton}
                onClick={() => {
                    updateStep(0);
                    onCancel("ServiceTree");
                }}
            >
                Cancel
            </button>
        </Dialog>
    );
};

export default ShareViewList;
