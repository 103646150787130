import CostCardTooltipContent, { ICostCardTooltipContentProps } from "../common/costCard/CostCardTooltipContent";
import Highcharts, { SeriesBarOptions, SeriesOptionsType } from "highcharts";
import { chartLegendOptions, chartStyles, chartTooltipOptions, onChartSeriesLegendClick } from "../../utils/chartOptions";

import CommonConstants from "../../models/constants/CommonConstants";
import HighchartsReact from "highcharts-react-official";
import { INotification } from "../../models/NotificationModels";
import { Moment } from "moment";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { getAnomalyDescription } from "../../utils/AnomalyDetectionUtils";
import styles from "./BarChart.less";

export interface IBarChartProps {
    categories: string[];
    series: SeriesBarOptions[];
    containerProps?: React.HTMLProps<HTMLElement>;
    anomalies?: INotification[];
    startDate?: Moment;
    endDate?: Moment;
    height?: number;
    tooltipProps?: Partial<ICostCardTooltipContentProps>;
    yAxis?: {
        labelFormatter?: (value: number) => string;
    };
}

const BarChart: React.FC<IBarChartProps> = (props) => {
    const options: Highcharts.Options = {
        chart: {
            type: "bar",
            style: chartStyles,
            height: props.height,
            spacingLeft: 0,
        },
        title: undefined,
        xAxis: {
            categories: props.categories,
            lineWidth: 0,
            labels: {
                align: "left",
                reserveSpace: true,
                style: {
                    maxWidth: 120,
                    textOverflow: "ellipsis",
                },
            },
        },
        yAxis: {
            title: {
                text: undefined,
            },
            min: 0,
            labels: {
                enabled: false,
            },
            lineWidth: 0,
            gridLineColor: "transparent",
            stackLabels: {
                enabled: true,
                crop: false,
                align: "right",
                allowOverlap: false,
                overflow: "allow",
                style: {
                    fontWeight: "normal",
                },
                formatter: function () {
                    return props.yAxis?.labelFormatter?.call(null, this.cumulative) || this.cumulative.toFixed(2);
                },
            },
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            ...chartTooltipOptions,
            shared: true,
            outside: true,
            padding: 0,
            formatter: function () {
                return ReactDOMServer.renderToString(
                    <CostCardTooltipContent
                        items={(this.points || [])
                            .slice()
                            .reverse()
                            .map((item) => ({ title: item.series.name, cost: item.y, color: item.color as string }))}
                        errors={props.anomalies?.map((event) => ({
                            title: event.category,
                            message: getAnomalyDescription(event.category, event.affectedMetrics, event.startDate, event.endDate, event.dataSourceName),
                        }))}
                        {...props.tooltipProps}
                    />
                );
            },
        },
        legend: {
            ...chartLegendOptions,
            padding: 0,
        },
        series: props.series.map((item, index) => ({ color: CommonConstants.DefaultColors[index], ...item })).reverse(),
        colors: CommonConstants.DefaultColors,
        plotOptions: {
            bar: {
                states: {
                    inactive: {
                        enabled: false,
                    },
                    hover: {
                        enabled: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stacking: "normal",
                pointWidth: 8,
                minPointLength: 4,
                point: {
                    events: {
                        mouseOver: function () {
                            const chart = this.series.chart;

                            chart.series.forEach((s) => {
                                s.points.forEach((p) => {
                                    if (p.category != this.category) {
                                        (p as any).graphic?.css({ opacity: "0.1" });
                                    }
                                });
                            });
                        },
                        mouseOut: function () {
                            const chart = this.series.chart;

                            chart.series.forEach((s) => {
                                s.points.forEach((p) => {
                                    (p as any).graphic?.css({ opacity: "1" });
                                });
                            });
                        },
                    },
                },
            },
            series: {
                events: {
                    legendItemClick: onChartSeriesLegendClick,
                },
            },
        },
    };
    return (
        <div className={styles.barChartWrapper}>
            <HighchartsReact highcharts={Highcharts} options={options} containerProps={props.containerProps} />
        </div>
    );
};

BarChart.defaultProps = {
    height: 260,
};

export default React.memo(BarChart);
