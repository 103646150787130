import { ActionButton, Checkbox, DefaultButton, Panel, PrimaryButton, Stack } from '@fluentui/react';
import React, { useState } from 'react';

import styles from './ColumnSelector.less';
import { useBoolean } from '@fluentui/react-hooks';

export interface IColumnSelectorItem {
    key: string;
    displayName: string;
    disabled?: boolean;
}

export interface IColumnSelectorProps {
    columns: IColumnSelectorItem[]

    onConfirm: (selectedKeys: string[]) => void;
    initSelectedKeys: string[];
}

export const ColumnSelector : React.FC<IColumnSelectorProps> = (props) => {
    const [columnFilterVisible, { toggle: toggleColumnFilterVisible }] = useBoolean(false);
    const [selectedKeys, setSelectedKeys] = useState(props.initSelectedKeys);

    const handleConfirm = () => {
        props.onConfirm(selectedKeys);
        toggleColumnFilterVisible();
    };

    const handleCancel = () => {
        toggleColumnFilterVisible();
        setSelectedKeys(props.initSelectedKeys);
    };

    const handleRenderFooterContent = () => {
        return (<>
            <PrimaryButton onClick={handleConfirm} style={{ marginRight: 12 }}>Apply</PrimaryButton>
            <DefaultButton onClick={handleCancel}>Cancel</DefaultButton>
        </>)
    };

    const handleToggleSelect = (key: string, checked: boolean) => {
        const newSelectedKeys = [...selectedKeys];

        if (checked) {
            newSelectedKeys.push(key);
        } else {
            newSelectedKeys.splice(newSelectedKeys.indexOf(key), 1);
        }

        setSelectedKeys(newSelectedKeys);
    };

    return (
        <div>
            <ActionButton iconProps={{ iconName: 'ColumnOptions' }} onClick={toggleColumnFilterVisible}>Columns</ActionButton>
            <Panel
                isOpen={columnFilterVisible}
                hasCloseButton={false}
                headerText="Customize Columns"
                isBlocking={false}
                isFooterAtBottom
                onRenderFooterContent={handleRenderFooterContent}
            >
                <p>Select the columns to display in the list view.</p>
                <Stack>
                    {
                        props.columns.map(col => (
                            <Checkbox
                                className={styles.checkBox}
                                checked={selectedKeys.indexOf(col.key) >= 0}
                                label={col.displayName}
                                key={col.key}
                                id={col.key}
                                disabled={col.disabled}
                                onChange={(_, checked) => handleToggleSelect(col.key, !!checked)}
                            />
                        ))
                    }
                </Stack>
            </Panel>
        </div>
    )
};