import { GroupBase, OptionsOrGroups } from "react-select";

export type OptionType = {
    value: string;
    label: string;
};

export function getLoadOptions(items: OptionType[]) {
    return function(search: string, prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>) {
        let filteredOptions: OptionType[];
        if (!search) {
            filteredOptions = items;
        } else {
            const searchLower = search.toLowerCase();
            filteredOptions = items.filter(({ label }) => label.toLowerCase().includes(searchLower));
        }

        const hasMore = filteredOptions.length > prevOptions.length + 20;
        const slicedOptions = filteredOptions.slice(prevOptions.length, prevOptions.length + 20);

        return {options: slicedOptions, hasMore};
    }
}

const allOption = { label: 'Select All', value: 'all' };

export function getLoadOptionsAll(items: OptionType[]) {
    return function (search: string, prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>) {
        let filteredOptions: OptionType[];
        if (!search) {
            filteredOptions = [allOption, ...items];
        } else {
            const searchLower = search.toLowerCase();
            filteredOptions = items.filter(({ label }) => label.toLowerCase().includes(searchLower));
        }

        const hasMore = filteredOptions.length > prevOptions.length + 20;
        const slicedOptions = filteredOptions.slice(prevOptions.length, prevOptions.length + 20);

        return { options: slicedOptions, hasMore };
    }
}