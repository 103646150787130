import { CosmicSourceTypeEnum, ICosmicHierarchicalMetricReport, ICosmicHierarchicalWorkloadReport } from "../../models/CosmicModels";
import { Link, MessageBar, MessageBarBody, MessageBarTitle } from "@fluentui/react-components";
import { LogComponent, LogElement } from "../../models/LogModel";

import CosmicSelector from "./CosmicSelector";
import { LineChartContainer } from "../azure/common/LineChartContainer";
import PartnerMetricsCard from "./PartnerMetricsCard";
import PartnerServiceDetailTable from "./PartnerServiceDetailTable";
import React from "react";
import commonStyles from "../common/styles/Common.less";
import styles from './CosmicView.less';
import { useGetCosmicWorkloadDailyAppMetric } from "../../hooks/useCosmicQuery";

interface IPartnerViewProps {
    type: CosmicSourceTypeEnum;
}

const getMetricData = (metricName: string, metricsType: ICosmicHierarchicalMetricReport[]) => {
    if (!metricsType) return [];

    return metricsType
        .filter((singleMetric) => singleMetric.metric === metricName)
        .flatMap((singleMetric) => singleMetric.data);
};

const PartnerView: React.FC<IPartnerViewProps> = (props) => {
    const { cosmicHierarchicalMetricReport: appCoresWorkloadMetrics, isLoading: isLoadingAppCores } = useGetCosmicWorkloadDailyAppMetric("AppCores");
    const { cosmicHierarchicalMetricReport: appCoreCogsWorkloadMetrics, isLoading: isLoadingAppCoreCogs } = useGetCosmicWorkloadDailyAppMetric("AppCoreCogs");

    const metricDataConfig = [
        { title: "App Request Cores by Cosmic Workload", metric: "CpuRequestCores", logElement: LogElement.CosmicAppRequestCores, workloads: getMetricData("CpuRequestCores", appCoresWorkloadMetrics) },
        { title: "App Core Cost by Cosmic Workload", metric: "AppCoreCogs", logElement: LogElement.CosmicAppCoreCOGS, workloads: getMetricData("AppCoreCogs", appCoreCogsWorkloadMetrics) },
        { title: "App Limit Cores by Cosmic Workload", metric: "CpuLimitCores", logElement: LogElement.CosmicAppLimitCores, workloads: getMetricData("CpuLimitCores", appCoresWorkloadMetrics) },
        { title: "App Empty Cores by Cosmic Workload", metric: "EmptyAppCores", logElement: LogElement.CosmicAppEmptyCores, workloads: getMetricData("EmptyAppCores", appCoresWorkloadMetrics) }
    ];

    const utilizationMetricDataConfig = { title: "App P99 Utilization by Cosmic Workload", metric: "CpuUtilizationP99", logElement: LogElement.CosmicAppP99CpuUtilization, data: getMetricData("CpuUtilizationP99", appCoresWorkloadMetrics) };

    return (
        <div className={styles.cardContainer}>
            {/*<InfoMessage />*/}
             <LinkMessage />
            <CosmicSelector />
            <PartnerMetricsCard appCoreCogs={appCoreCogsWorkloadMetrics} isLoadingAppCoreCogs={isLoadingAppCoreCogs} />

            <MetricsCharts
                metricsConfig={metricDataConfig}
                isLoadingAppCores={isLoadingAppCores}
                isLoadingAppCoreCogs={isLoadingAppCoreCogs}
                type={props.type}
            />

            <LineChartContainer
                key={utilizationMetricDataConfig.title}
                title={utilizationMetricDataConfig.title}
                isLoading={!utilizationMetricDataConfig.data}
                isNotCurrency
                series={utilizationMetricDataConfig.data.map(singleMetric => ({
                    name: singleMetric.workload,
                    data: singleMetric.data.map(metric => [metric.date.valueOf(), (metric.metricValue ?? 0) * 100])
                })) || []}
                logProps={{
                    logComponent: LogComponent.CosmicPartnerView,
                    logElement: utilizationMetricDataConfig.logElement,
                    customProperties: { type: props.type }
                }}
                fillMissingAbnormalData
                suffix="%"
                anomalies={[]}
            />
            <PartnerServiceDetailTable defaultSorting="CpuRequestCores" />
        </div>
    );
};

const MetricsCharts: React.FC<{ metricsConfig: { title: string; metric: string; logElement: LogElement; workloads: ICosmicHierarchicalWorkloadReport[] }[], isLoadingAppCores: boolean, isLoadingAppCoreCogs: boolean, type: CosmicSourceTypeEnum }> = ({ metricsConfig, isLoadingAppCores, isLoadingAppCoreCogs, type }) => (
    <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`} style={{ padding: "0 0 24px 0" }}>
        {metricsConfig.map(({ title, logElement, workloads }) => (
            <LineChartContainer
                key={title}
                title={title}
                isLoading={title.includes("Cost") ? isLoadingAppCoreCogs : isLoadingAppCores}
                isNotCurrency={!title.includes("Cost")}
                series={workloads.map(singleMetric => ({
                    name: singleMetric.workload,
                    data: singleMetric.data.map(metric => [metric.date.valueOf(), metric.metricValue])
                })) || []}
                logProps={{
                    logComponent: LogComponent.CosmicPartnerView,
                    logElement,
                    customProperties: { type }
                }}
                fillMissingAbnormalData
                showArea
                anomalies={[]}
            />
        ))}
    </div>
);

// const InfoMessage = () => (
//     <MessageBar intent="info" style={{ marginBottom: "24px" }}>
//         <MessageBarBody>
//             <MessageBarTitle>Important</MessageBarTitle>
//             AIRS typically locks the data for the last month on a specific date. Therefore, please be aware that the COGS values for the last month may be refreshed on certain dates.<br />
//         </MessageBarBody>
//     </MessageBar>
// );
const LinkMessage = () => (
    <MessageBar intent="success" style={{ marginBottom: "14px" }}>
        <MessageBarBody>
            <MessageBarTitle>Sovereign Cloud Dashboard: </MessageBarTitle>
            <Link href="https://o365exchange.visualstudio.com/O365%20Core/_wiki/wikis/O365%20Core.wiki/456975/Cosmic-Jaws-Sovereign-Cloud-Dashboard" target="__blank">
                More details
            </Link>
            </MessageBarBody>
    </MessageBar>
);
export default PartnerView;