import { ISubscription, ServiceTreeBusinessOwners } from "../models/serviceTree";
import { UseQueryResult, useQuery } from "react-query";
import { getAzureSubscriptions, getClusterIds, listAllBusinessOwners } from "../services/serviceTreeService";

import { Endpoints } from "../utils/Constants";

export function useAzureSubscriptionsQuery(): UseQueryResult<ISubscription[], Error> {
    return useQuery<ISubscription[], Error>(Endpoints.GetAzureSubscriptions, getAzureSubscriptions);
}

export function useAzureClusterIdsQuery(): UseQueryResult<string[], Error> {
    return useQuery<string[], Error>(Endpoints.GetAzureClusterIds, getClusterIds);
}

export function useServiceTreeBusinessOwnersQuery(): UseQueryResult<ServiceTreeBusinessOwners[], Error> {
    return useQuery<ServiceTreeBusinessOwners[], Error>(Endpoints.GetServiceTreeBusinessOwners, listAllBusinessOwners);
}
