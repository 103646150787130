import { Pivot, PivotItem } from "@fluentui/react";
import React, {  } from "react";
import { SubComputeCost } from "./SubComputeCost";
import { SubCores } from "./SubCores";
import { SubUtilization } from "./SubUtilization";
import styles from "../Azure.less";
import ServiceDetail from "../detail/ServiceDetail";
import RegionDetail from "../detail/RegionDetail";
import SubscriptionDetail from "../detail/SubscriptionDetail";
import RegionSelector from "../common/RegionSelector";
import { Budget } from "../budget/Budget";
import { useParams } from "react-router-dom";
import { useGotoTab } from "../../../hooks/useGotoPage";
import { Pages, SubPages } from "../../../models/Nav";
import PageHeader from "../../common/PageHeader";
import { useRegion } from "../../../hooks/useRegion";
import { useGetBannersByTabAndDateQuery } from "../../../hooks/useBannerQuery";
import { WarningBanner } from "../../banner/WarningBanner";
import { useFlights } from "../../../hooks/useSettings";
import { useGetAzureCostOverview } from "../../../hooks/useAzureQuery";
import { EmpytCostView } from "../../substrateV2/common/EmptyCostView";

interface IAzureSubPageProps {
    type: "Public" | "AZSC";
    pageTitle: string;
}

export const AzureSubPage: React.FC<IAzureSubPageProps> = (props) => {
    const { tab: curTab } = useParams();
    const gotoTab = useGotoTab();

    const handleTabChange = (item?: PivotItem) => {
        if (!item || !item.props.itemKey) return;

        gotoTab(props.type === "Public" ? `${Pages.Azure}/${SubPages.Public}` : `${Pages.Azure}/${SubPages.AzSC}`, item.props.itemKey, Pages.Azure);
    };

    const hasExtra = curTab === "RegionDetail" || curTab === "SubscriptionDetail";

    const [regions, setRegions] = useRegion();
    const {data: allBanners } = useGetBannersByTabAndDateQuery(props.type);
    const { data: flights } = useFlights();
    const {publicCost, azscCost, isLoading} = useGetAzureCostOverview();
    const totalCost = props.type === "Public" ? publicCost : azscCost;

    return (
        <div className={styles.page}>
        <PageHeader
            title={props.pageTitle}
            inlineLink
            description={props.type === "Public" ?
                "Below you will find details regarding Public Azure costs for a specified date range." :
                "Below you will find details regarding AzSC costs for a specified date range."}
            />
        {
            Boolean(allBanners?.length) &&
            allBanners?.map(item =>
            (
                <WarningBanner key={item.id} bannerItem={item} />
            ))
        }
        { hasExtra && <RegionSelector selectedKeys={regions} onChange={setRegions} /> }
        {
            isLoading || totalCost > 0 ?
            <Pivot className={`${styles.pivot} ${hasExtra ? styles.pivotWithExtra : ''}`} overflowBehavior="menu" onLinkClick={handleTabChange} selectedKey={curTab || "Overview"} >
                <PivotItem itemKey="Overview" headerText="Overview">
                    <SubComputeCost type={props.type} />
                    <SubCores type={props.type} />
                    <SubUtilization type={props.type} />
                </PivotItem>
                <PivotItem itemKey="ServiceDetail" headerText="Service details"><ServiceDetail type={props.type} /></PivotItem>
                <PivotItem itemKey="RegionDetail" headerText="Region details"><RegionDetail regions={regions} type={props.type} /></PivotItem>
                <PivotItem itemKey="SubscriptionDetail" headerText="Subscription details"><SubscriptionDetail regions={regions} type={props.type} /></PivotItem>
                {flights?.enableBudgets && <PivotItem itemKey="BudgetDetail" headerText="Budget details"><Budget type={props.type} /></PivotItem>}
            </Pivot> :
            <EmpytCostView type="azure" />
        }
        </div>
    )
}