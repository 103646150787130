import * as React from "react";
import { ESCostDataDimensions, ESCostOrganization, ESOverviewTimeSelection, ESAzureDataSourceFYCostRawResponse } from "../../../../models/ESCostModels";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import { useEsOrg } from "../../../../hooks/useEsOrg";
import PieChart, { IPieChartData } from "../Common/PieChart";
import { ESOrgs } from "../../../../models/constants/ESCostConstants";

interface IProps {
    data: ESAzureDataSourceFYCostRawResponse[];
}

function calculateOverviewValues(
    rawData: ESAzureDataSourceFYCostRawResponse[],
    year: number,
    org: ESCostOrganization[],
): IPieChartData {
    const values = rawData.filter(
        (data) =>
            ESOrgs.includes(data[ESCostDataDimensions.Organization]) &&
            year === data[ESCostDataDimensions.ReturnFiscalYear] &&
            (org.length === 0 || org.includes(data[ESCostDataDimensions.Organization]))
    );

    const sum = values.reduce((acc, curr) => {
        const retention = curr[ESCostDataDimensions.DataSource];
        const v = curr[ESCostDataDimensions.Cost];
        acc[retention] = (acc[retention] || 0) + v;
        return acc;
    }, {} as { [key: string]: number });

    return Object.keys(sum).reduce((r, k) => {
        if (sum[k] > 0) {
            r[k] = sum[k];
        }
        return r;
    }, {} as IPieChartData);
}

const AzureDataSourcePieChart: React.FC<IProps> = (props) => {
    const { data: rawData } = props;
    const [timeRange] = useEsOverviewTimeRange();
    const [org] = useEsOrg();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );
    const overviewValues = React.useMemo(() => calculateOverviewValues(rawData, year, org), [org, rawData, year]);
    const total = Object.values(overviewValues).reduce((acc, curr) => acc + curr, 0);
    return (
        <PieChart
            data={overviewValues}
            description={"Azure cost in different data sources"}
            totalCountDesc={"Total Cost"}
            isCurrency={true}
            total={total}
        />
    );
};

export default AzureDataSourcePieChart;
