import PageHeader, { IPageHeaderProps } from '../common/PageHeader';

import React from 'react';
import TelemetryChart from './chart/TelemetryChart';
import TelemetryCostTable from './TelemetryCostTable';
import { TelemetrySourceTypeEnum } from '../../models/TelemetryCogsModel';
import { WarningBanner } from '../banner/WarningBanner';
import { bannerTabs } from '../../models/Nav';
import styles from './TelemetryView.less';
import { useGetBannersByTabAndDateQuery } from '../../hooks/useBannerQuery';

const TelemetryViewHeadersProps: Record<TelemetrySourceTypeEnum, IPageHeaderProps> = {
    [TelemetrySourceTypeEnum.All]: {
        title: "Telemetry",
        description: "Major telemetry data fabrics (Cosmos, Blueshift, Kusto, Geneva, Aria), and all corresponding data assets.",
        link: "https://cosman.azurewebsites.net/cost/servicetree/Tenant/Overview",
        linkText: "Get an in-depth analysis of telemetry cost details.",
    },
    [TelemetrySourceTypeEnum.Cosmos]: {
        title: "Cosmos",
        description: "The Cosmos cost shown in this view is based on usage. It is not the actual billed cost but is displayed to illustrate the usage.",
        link: "https://cosman.azurewebsites.net/cost/servicetree/Tenant/Overview",
        linkText: "Get an in-depth analysis of telemetry cost details.",
    },
    [TelemetrySourceTypeEnum.Blueshift]: {
        title: "Blueshift",
        description: "The Blueshift cost shown in this view is based on usage. It is not the actual billed cost but is displayed to illustrate the usage.",
        link: "https://cosman.azurewebsites.net/cost/servicetree/Tenant/Overview",
        linkText: "Get an in-depth analysis of telemetry cost details.",
    },
    [TelemetrySourceTypeEnum.Kusto]: {
        title: "Kusto",
        description: "Kusto service is running on VM and using multiple resources in Azure. From the COGS point of view, we want to list all COGS caused by Kusto, so we summarized all COST including VM, Storage, Bandwidth, etc. together as the Kusto COGS.",
        link: "https://cosman.azurewebsites.net/cost/servicetree/Tenant/Overview",
        linkText: "Get an in-depth analysis of telemetry cost details.",
    },
    [TelemetrySourceTypeEnum.GenevaMds]: {
        title: "GenevaMds",
        description: "Geneva Mds COGS = the cost of Azure Storage account + Azure Event hub which created by Geneva service.",
        link: "https://cosman.azurewebsites.net/cost/servicetree/Tenant/Overview",
        linkText: "Get an in-depth analysis of telemetry cost details.",
    },
    [TelemetrySourceTypeEnum.GenevaMdm]: {
        title: "GenevaMdm",
        description: "Geneva Mdm has 2 stamps, dedicated stamp and shared stamp. Currently Geneva MDM does not charge from shared stamp, so our COGS does not include shared part. So, the Geneva MDM cost is the subscription cost of dedicated stamp.",
        link: "https://cosman.azurewebsites.net/cost/servicetree/Tenant/Overview",
        linkText: "Get an in-depth analysis of telemetry cost details.",
    },
    [TelemetrySourceTypeEnum.Aria]: {
        title: "Aria",
        notification: '',
        description: "",
        link: "https://cosman.azurewebsites.net/cost/servicetree/Tenant/Overview",
        linkText: "Get an in-depth analysis of telemetry cost details.",
    },
    [TelemetrySourceTypeEnum.PassiveMon]: {
        title: "PassiveMonitoring",
        description: "The cost of Passive Monitoring is not actually billed but is displayed to illustrate the utilization of Passive Monitoring.",
        link: "https://cosman.azurewebsites.net/cost/servicetree/Tenant/Overview",
        linkText: "Get an in-depth analysis of telemetry cost details.",
    }
};

interface ITelemetryViewProps {
    type: TelemetrySourceTypeEnum
}

const TelemetryView: React.FC<ITelemetryViewProps> = (props) => {
    const { data: allBanners } = useGetBannersByTabAndDateQuery(bannerTabs.Telemetry);

    return (
        <div className={styles.telemetryView}>
            <PageHeader {...TelemetryViewHeadersProps[props.type]} className={styles.header} />
            <div className={styles.Bannerheader} >
                {
                    Boolean(allBanners?.length) &&
                    allBanners?.map(item =>
                    (
                        <WarningBanner key={item.id} bannerItem={item} />
                    ))
                }
            </div>
            <TelemetryChart type={props.type}/>
            <TelemetryCostTable type={props.type} />
        </div>
    );
};

export default TelemetryView;