export function escapeRegex(value?: string): string {
    if (value === undefined) {
        return "";
    }
    
    return value.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function stringOverflowWithEllipsis(value: string, limitLength: number): string {
    if (value === undefined) {
        return "";
    }
    
    if (value.length <= limitLength) {
        return value;
    } else {
        return value.substring(0, limitLength) + "..."
    }
}