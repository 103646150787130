import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey } from "../models/SearchKey";

export function useSpotVM(): [boolean, (showSpotVM?: boolean) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const showSpotVM: boolean = useMemo(() => {
        const showSpotVM = searchParams.get(SearchKey.ShowSpotVM);
        if (showSpotVM == null || typeof showSpotVM == 'undefined') {
            return true;
        }
        return showSpotVM === "true";
    }, [searchParams]);

    const setShowSpotVM = useCallback((showSpotVM?: boolean) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(SearchKey.ShowSpotVM, JSON.stringify(showSpotVM));
        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return [showSpotVM, setShowSpotVM];
}