import React from "react";
import { ESCostDataDimensions, ESCostOrganization, ESCostRawUserType, ESManagerMonthToMonthCostRawResponse } from "../../../../models/ESCostModels";
import { Column, TableInstance, TableOptions, UseExpandedInstanceProps, UseSortByOptions, useExpanded, useSortBy, useTable } from "react-table";
import { useEsOrg } from "../../../../hooks/useEsOrg";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import moment from "moment";
import DataTable, { ITableRow, LastMonthAccessor, MonthBeforeLastAccessor, RelativeChangeAccessor } from "../Common/DataTable";
import { ESOrgs } from "../../../../models/constants/ESCostConstants";
import { TableColumnRenderer } from "../../../common/table/TableColumnRenderer";

interface ISimpleTableProps {
    data: ESManagerMonthToMonthCostRawResponse[];
    description: string;
    isCurrency?: boolean;
}

function filterAndChangeToTableData(rawData: ESManagerMonthToMonthCostRawResponse[], org: ESCostOrganization[], isCurrency?: boolean): ITableRow[] {
    const dates = ESCostUtils.getMonthToMonthDates().map((d) => d.format("YYYYMMDD"));
    const d = rawData
        .map((item) => {
            return {
                ...item,
                [ESCostDataDimensions.TimeStamp]: moment(item[ESCostDataDimensions.TimeStamp]).date(1).format("YYYYMMDD"),
            };
        })
        .filter(
            (data) =>
                data[ESCostDataDimensions.UserType] === ESCostRawUserType.Human &&
                ESOrgs.includes(data[ESCostDataDimensions.Organization]) &&
                (org.length === 0 || org.includes(data[ESCostDataDimensions.Organization])) &&
                dates.includes(data[ESCostDataDimensions.TimeStamp])
        );

    const tempData = {} as { [key: string]: { [key: string]: number } };
    d.forEach((data) => {
        const t = data[ESCostDataDimensions.TimeStamp];
        const user = data[ESCostDataDimensions.UserName];
        if (!(user in tempData)) {
            tempData[user] = {};
        }
        if (!(t in tempData[user])) {
            tempData[user][t] = 0;
        }
        tempData[user][t] += isCurrency === false ? data[ESCostDataDimensions.ArtifactWorkload] : data[ESCostDataDimensions.Cost];
    });
    const tableData: ITableRow[] = [];
    Object.keys(tempData).forEach((user) => {
        const obj = tempData[user];
        const row: ITableRow = {
            [ESCostDataDimensions.UserName]: user,
            [MonthBeforeLastAccessor]: obj[dates[0]] ?? 0,
            [LastMonthAccessor]: obj[dates[1]] ?? 0,
            [RelativeChangeAccessor]: ESCostUtils.getRelativeChange(obj[dates[0]], obj[dates[1]]),
        };
        tableData.push(row);
    });
    tableData.sort((a, b) => b[LastMonthAccessor] - a[LastMonthAccessor]);
    return tableData;
}

const ManagersMonthToMonthCostTable: React.FC<ISimpleTableProps> = (props) => {
    const [org] = useEsOrg();
    const tableData: ITableRow[] = React.useMemo(() => {
        return filterAndChangeToTableData(props.data, org, props.isCurrency);
    }, [org, props.data, props.isCurrency]);
    const months = React.useMemo(() => ESCostUtils.getMonthToMonthDates().map((d) => d.format("MMM")), []);
    const columns = React.useMemo<Column<ITableRow>[]>(
        () => [
            { Header: ESCostDataDimensions.UserName, accessor: ESCostDataDimensions.UserName },
            {
                Header: months[0],
                accessor: MonthBeforeLastAccessor,
                Cell: (cell) => (props.isCurrency === false ? TableColumnRenderer.renderNumber(cell.value) : TableColumnRenderer.renderCurrency(cell.value)),
            },
            {
                Header: months[1],
                accessor: LastMonthAccessor,
                Cell: (cell) => (props.isCurrency === false ? TableColumnRenderer.renderNumber(cell.value) : TableColumnRenderer.renderCurrency(cell.value)),
            },
            { Header: "Relative change", accessor: RelativeChangeAccessor },
        ],
        [props.isCurrency, months]
    );
    const tableInstance: TableInstance<ITableRow> = useTable(
        {
            data: tableData,
            columns,
        } as TableOptions<ITableRow> & UseSortByOptions<ITableRow>,
        useSortBy,
        useExpanded
    ) as TableInstance<ITableRow> & UseExpandedInstanceProps<ITableRow>;

    return <DataTable tableInstance={tableInstance} description={props.description} />;
};

export default ManagersMonthToMonthCostTable;
