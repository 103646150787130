import styles from "./Page.less";

import * as React from "react";
import Highcharts from "highcharts";
import { Breadcrumb, CommandBar, IBreadcrumbItem, ICommandBarItemProps, IconButton, MessageBar, MessageBarType, Modal, PrimaryButton, Spinner, TextField } from "@fluentui/react";

import { useGotoPage } from "../../../hooks/useGotoPage";
import { deleteItem, postMethod } from "../../../utils/apiServiceBase";
import { useEffect, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { Pages } from "../../../models/Nav";
import { useLocation } from "react-router-dom";
import { TargetActualGapPair, DownloadRegionPair } from "../../../models/EfficiencyTracker";
import { EfficiencyTrackerBaseUrl } from "../../../services/EfficiencyTrackerService";


Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.column.prototype.drawLegendSymbol;

interface IProps {
    children: React.ReactNode;
    title: string;
    breadcrumbItems: IBreadcrumbItem[];
    description?: React.ReactNode;
    AddNewPlanLink?: string;
    EditLink?: string;
    AllowDelete?: boolean;
    DownloadRegionPair?: DownloadRegionPair[];
    ShareProjectId?: number;
    DeleteProjectId?: number;
}

const PageWrapper = (props: IProps) => {
    // Get location
    const gotoPage = useGotoPage();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const projectlink = pathSegments[2];
    const isBaremetal = pathSegments[3];

    // Set regionDownloadList
    const [regionDownloadList, setRegionDownloadList] = useState<DownloadRegionPair[]>([]);

    useEffect(() => {
        if (props.DownloadRegionPair) {
            setRegionDownloadList(props.DownloadRegionPair);
        }
    }, [props.DownloadRegionPair]); // Specify DownloadRegionPair as a dependency

    // add share/delete for project
    const [showModal, setShowModal] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [email, setEmail] = useState('');
    const [showModalRequest, setShowModalRequest] = useState(false);
    const [requestState, setRequestState] = useState<string>('');
    const [isSubmitting, { setTrue: submitting, setFalse: submitDone }] = useBoolean(false);

    const farItems: ICommandBarItemProps[] = [
        props.AddNewPlanLink
            ? {
                key: 'AddPlan',
                text: 'Add Plan',
                iconProps: { iconName: 'Add' },
                onClick: () => {
                    gotoPage(props.AddNewPlanLink as string)
                },
            }
            : null,
        props.EditLink
            ? {
                key: 'EditProject',
                text: 'Edit',
                iconProps: { iconName: 'Edit' },
                onClick: () => {
                    gotoPage(props.EditLink as string)
                },
            }
            : null,
        props.DownloadRegionPair?.length || 0 > 0
            ? {
                key: 'ExportProject',
                text: 'Export',
                iconProps: { iconName: 'Download' },
                onClick: () => {
                    download();
                },
            }
            : null,
        props.ShareProjectId
            ? {
                key: 'ShareProject',
                text: 'Share',
                iconProps: { iconName: 'Share' },
                onClick: () => {
                    setIsDelete(false);
                    setShowModal(true);
                    setRequestState('');
                },
            }
            : null,
        props.DeleteProjectId && props.AllowDelete
            ? {
                key: 'DeleteProject',
                text: 'Delete',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setIsDelete(true);
                    setShowModal(true);
                    setRequestState('');
                },
            }
            : null,
    ].filter(Boolean) as ICommandBarItemProps[];

    //Modal
    const handleModalClose = () => {
        setShowModal(false);
        setRequestState('');
        setShowModalRequest(false);
    };

    const handleEmailChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string | undefined) => {
        setEmail(newText || "");
        setShowModalRequest(false);
        setRequestState('');
    };

    //Share project by email
    const handleEmailSubmit = () => {
        submitting();
        postMethod<any>(`${EfficiencyTrackerBaseUrl}/share?projectId=` + props.ShareProjectId + "&targetUser=" + email, null)
            .then((response) => {
                if (response.ok) {
                    setRequestState('Submit successfully!');
                } else {
                    setRequestState('Submit failed!');
                }
            })
            .catch(() => {
                setRequestState('Error when Submitting');
            }).finally(() => {
                submitDone();
                setEmail('');
                setShowModalRequest(true);
            });
    };

    // Delete project
    const handleDeleteProject = () => {
        submitting();
        deleteItem(`${EfficiencyTrackerBaseUrl}/delete?projectId=` + props.DeleteProjectId)
            .then((response) => {
                if (response.ok) {
                    setRequestState('Submit successfully!');
                    setTimeout(() => {
                        gotoPage(`${Pages.EfficiencyTrackerV2}/${projectlink}/${isBaremetal}`);
                    }, 5000);
                } else {
                    setRequestState('Submit failed!');
                }
            })
            .catch(() => {
                setRequestState('Error when Submitting');
            }).finally(() => {
                submitDone();
                setShowModalRequest(true);
            });
    };

    //download plan details.
    const download = () => {
        // Get all the date
        const allTimesNumber = new Set<number>();
        regionDownloadList.forEach(regionPair => {
            regionPair.dataPairList.forEach(dataPair => {
                const timeValue = dataPair.Time.getTime();
                if (!allTimesNumber.has(timeValue)) { // Check unique
                    allTimesNumber.add(timeValue);
                }
            });
        });

        const allTimes = new Set<Date>();
        allTimesNumber.forEach(timeValue => {
            const time = new Date(timeValue);
            allTimes.add(time);
        });

        // sort asc
        const sortedTimes = Array.from(allTimes).sort((a, b) => a.getTime() - b.getTime());

        // Set CSV header
        const csvHeader = `Region,Metric\\Time,${sortedTimes.map(time => {
            const dateString = time.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });
            return dateString;
        }).join(',')}`;

        // Set CSV Rows
        const csvRows: string[] = [];
        regionDownloadList.forEach(regionPair => {
            const region = regionPair.Region;
            (['target', 'actual', 'gap'] as Array<keyof TargetActualGapPair>).forEach((metric, index) => {
                const metricValues = sortedTimes.map(time => {
                    const dataPair = regionPair.dataPairList.find(pair => pair.Time.getTime() === time.getTime());
                    if (dataPair) {
                        return dataPair[metric] !== undefined ? (dataPair[metric]!).toString() : '';
                    } else {
                        return '';
                    }
                });
                csvRows.push(`${region},${metric},${metricValues.join(',')}`);
            });
        });

        const csvContent = "data:text/csv;charset=utf-8," + [csvHeader, ...csvRows].join('\n');

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", props.title + "- Region Download List.csv");
        document.body.appendChild(link);
        link.click();
    };

    return (
        <div className={styles.page}>
            <Breadcrumb items={props.breadcrumbItems} className={styles.breadcrumb} />
            <div className={styles.header}>
                <div className={styles.title}>{props.title}</div>
                <div className={styles.buttons}>
                    <CommandBar
                        items={[]}
                        farItems={farItems}
                    />
                </div>
            </div>
            <div className={styles.content}>
               {props.description}
            </div>
            <Modal isOpen={showModal} onDismiss={handleModalClose} containerClassName={isDelete ? styles.modalContainerDelete : styles.modalContainer}>
                {isDelete ? (
                    <>
                        <div className={styles.modalHeader}>
                            <span >{"Delete this project"}</span>
                            <IconButton
                                className={styles.iconButton}
                                iconProps={{ iconName: "Cancel" }}
                                ariaLabel="Close"
                                onClick={handleModalClose}
                            />
                        </div>
                        {
                            showModalRequest && !isSubmitting && (
                                <MessageBar
                                    messageBarType={requestState === 'Submit successfully!' ? MessageBarType.success : MessageBarType.error}>
                                    {requestState}
                                </MessageBar>
                            )
                        }
                        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: 0 }}>
                            <PrimaryButton onClick={handleDeleteProject} style={{ margin: '8px' }}>Confirm</PrimaryButton>
                            <PrimaryButton onClick={handleModalClose} style={{ margin: '8px' }}>Cancel</PrimaryButton>
                            {isSubmitting && <Spinner label="Deleting project..." labelPosition="right" />}
                        </div>
                    </>
                ): (<>
                        <div className={styles.modalHeader}>
                            <span >{"Share project to other"}</span>
                            <IconButton
                                className={styles.iconButton}
                                iconProps={{ iconName: "Cancel" }}
                                ariaLabel="Close"
                                onClick={handleModalClose}
                            />
                        </div>
                        <div className={styles.modalBody}>
                            <TextField
                                label="MS Account Email"
                                value={email}
                                placeholder="Format:alias@microsoft.com"
                                onChange={handleEmailChange}
                            />
                        </div>
                        {
                            showModalRequest && !isSubmitting && (
                                <MessageBar
                                    messageBarType={requestState === 'Submit successfully!' ? MessageBarType.success : MessageBarType.error}>
                                    {requestState}
                                </MessageBar>
                            )
                        }
                        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: 0 }}>
                            <PrimaryButton onClick={handleEmailSubmit} style={{ margin: '8px' }}>Confirm</PrimaryButton>
                            <PrimaryButton onClick={handleModalClose} style={{ margin: '8px' }}>Cancel</PrimaryButton>
                            {isSubmitting && <Spinner label="Submitting share request..." labelPosition="right" />}
                        </div>
                    </>)
                }
               
            </Modal>
            {props.children}
        </div>
    );
};

export default PageWrapper;