import { CategoryDivision, CategoryDivisionDisplayName } from "../../../../models/CategoryDivision";
import { Panel, PanelType } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { useAzureClusterIdsQuery, useAzureSubscriptionsQuery } from "../../../../hooks/useServiceTreeQuery";

import DateRangePickerV2 from "../../../DateRangePickerV2/DateRangePickerV2";
import { IAppState } from "../../../../store";
import { IServiceTreeData } from "../../../../reducers/serviceTreeReducer";
import ServiceFilter from "../ServiceFilter/ServiceFilter";
import WorkloadFilter from "../WorkloadFilter/WorkloadFilter";
import { getServiceTreeOptionsList } from "../../../../utils/ServiceTreeUtils";
import styles from "./FiltersPanel.less";
import { useSelector } from "react-redux";

interface IFeedbackPanelProps {
    isPanelOpen: boolean;
    onDismiss: () => void;
    selectedFilters: CategoryDivision[];
}

const panelWidthSetting: Record<string, string> = {
    ["Init"]: "300px",
};

const FiltersPanel: React.FC<IFeedbackPanelProps> = (props) => {
    const { onDismiss } = props;
    const { data: subscriptions } = useAzureSubscriptionsQuery();
    const { data: clusterIds } = useAzureClusterIdsQuery();
    const serviceTree = useSelector<IAppState, IServiceTreeData>((state) => state.serviceTree);
    const [panelWidth, setpanelWidth] = useState<string>(panelWidthSetting.Init);

    const getPlatformServiceLabel = useCallback(
        (key: string) => {
            return serviceTree.indexMap.get(key)?.n || key;
        },
        [serviceTree.indexMap]
    );

    return (
        <Panel
            type={PanelType.custom}
            className={styles.container}
            customWidth={panelWidth}
            headerText="All Filters"
            headerClassName={styles.title}
            isOpen={props.isPanelOpen}
            onDismiss={onDismiss}
            closeButtonAriaLabel="Close"
            isFooterAtBottom={true}
            isLightDismiss={true}
            layerProps={{ eventBubblingEnabled: true }}
        >
            <ServiceFilter buttonId="panelServiceFilterButton" isUsedInPanel={true}/>
            <DateRangePickerV2 isUsedInPanel={true}/>
            {props.selectedFilters.map((filter) => (
                <WorkloadFilter
                    key={filter}
                    hidden={!props.selectedFilters.includes(filter)}
                    category={filter}
                    optionList={getServiceTreeOptionsList(filter, serviceTree, clusterIds, subscriptions)}
                    displayName={CategoryDivisionDisplayName[filter]}
                    getCheckboxLabel={filter === CategoryDivision.Service ? getPlatformServiceLabel : undefined}
                    isSearchable={filter !== CategoryDivision.Service}
                    isUsedInPanel={true}
                />
            ))}
        </Panel>
    );
};

export default FiltersPanel;
