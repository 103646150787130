import { Reducer } from "redux";
import { ISearchByIdAction, ISearchKeyChangeAction } from "../actions/SearchActions";
import { ComponentItem, ISubscription, ServiceTree, ServiceTreeItem } from "../models/serviceTree";
import { ActionTypes } from "../actions/ActionTypes";
import { calcSearchData, filterSearchData, searchClusterIds, searchSubscriptions } from "./SearchUtils";
import { CategoryDivision, ServiceTreeCategory, TagCategory } from "../models/CategoryDivision";
import { escapeRegex } from "./stringUtils";
import { getCategoryByServiceTreeLevel } from "./serviceTreeReducer";
import { Dispatch } from "redux";

export type IServiceTreeSearchResult = [ServiceTreeCategory, ServiceTreeItem[]][];

export type ITagSearchResult = [TagCategory, ComponentItem[][]][];

export type ITagSearchResultDescription = [CategoryDivision, ITagSearchResultDescriptionItem[]][];

export interface ITagSearchResultDescriptionItem {
    title: string;
    description: string;
}

export interface ISearchData {
    searchKey?: string;
    serviceTreeSearchResult?: IServiceTreeSearchResult;
    tagSearchResult?: ITagSearchResult;
    tagSearchResultDescription?: ITagSearchResultDescription;
}

export const searchReducer: Reducer<ISearchData, ISearchKeyChangeAction> = function(state: ISearchData = {}, action: ISearchKeyChangeAction | ISearchByIdAction): ISearchData {
    if (action.type === ActionTypes.SearchByIdAction && action.key !== state.searchKey) {
        return {
            searchKey: action.key,
            serviceTreeSearchResult: [
                [getCategoryByServiceTreeLevel(action.serviceTreeNode.l), [action.serviceTreeNode]]
            ]
        }
    }
    if (action.type != ActionTypes.SearchKeyChangeAction || action.key == state.searchKey) {
        return state;
    }
    if (action.key.length === 0) {
        return {};
    }

    const searchResult = filterSearchData(action.key, action.serviceTree.items);

    return calcSearchData(action.key, searchResult,
        getTagListsByLists(action.key, action.vdirs, action.processes, action.scenarioTags, action.griffinApps,
            action.owners, action.griffinProcessors, action.storeCilents, action.storeCilentComponents, action.ssdDataSetNames,
            action.griffinAppV2,
            action.griffinProcessorV2,
            action.storeClientComponentV2,
            action.storeClientV2,
            action.scenarioTagV2,
            action.vdirV2,
            action.processV2,
            action.datasetV2),
        getAzureResult(action.key, action.subscriptions, action.clusterIds));
}

export function searchByKey(
    dispatch: Dispatch,
    key: string,
    serviceTree: ServiceTree,
    vdirs: Map<string, ComponentItem[]>,
    processes: Map<string, ComponentItem[]>,
    scenarioTags: Map<string, ComponentItem[]>,
    griffinApps: Map<string, ComponentItem[]>,
    griffinProcessors: Map<string, ComponentItem[]>,
    owners: Map<string, ComponentItem[]>,
    storeCilents: Map<string, ComponentItem[]>,
    storeCilentComponents: Map<string, ComponentItem[]>,
    ssdDataSetNames: Map<string, ComponentItem[]>,
    serviceTreeIndexMap: Map<string, ServiceTreeItem>,

    griffinAppV2: Map<string, ComponentItem[]>,
    griffinProcessorV2: Map<string, ComponentItem[]>,
    storeClientComponentV2: Map<string, ComponentItem[]>,
    storeClientV2: Map<string, ComponentItem[]>,
    scenarioTagV2: Map<string, ComponentItem[]>,
    vdirV2: Map<string, ComponentItem[]>,
    processV2: Map<string, ComponentItem[]>,
    datasetV2: Map<string, ComponentItem[]>,

    clusterIds?: string[],
    subscriptions?: ISubscription[]) {
        if (serviceTreeIndexMap.has(key)) {
            dispatch({type: ActionTypes.SearchByIdAction, key, serviceTreeNode: serviceTreeIndexMap.get(key)!});
        } else {
            dispatch({
                type: ActionTypes.SearchKeyChangeAction,
                key,
                serviceTree,
                vdirs,
                processes,
                scenarioTags,
                griffinApps,
                griffinProcessors,
                owners,
                subscriptions,
                clusterIds,
                storeCilents,
                storeCilentComponents,
                ssdDataSetNames,
                griffinAppV2,
                griffinProcessorV2,
                storeClientComponentV2,
                storeClientV2,
                scenarioTagV2,
                vdirV2,
                processV2,
                datasetV2,
            });
        }
}

function getTagListsByLists(key: string, vdirs: Map<string, ComponentItem[]>,
    processes: Map<string, ComponentItem[]>, scenarioTags: Map<string, ComponentItem[]>,
    griffinApps: Map<string, ComponentItem[]>,
    owners: Map<string, ComponentItem[]>,
    griffinProcessors: Map<string, ComponentItem[]>,
    storeClients: Map<string, ComponentItem[]>,
    storeClientComponents: Map<string, ComponentItem[]>,
    ssdDataSetNames: Map<string, ComponentItem[]>,

    griffinAppV2: Map<string, ComponentItem[]>,
    griffinProcessorV2: Map<string, ComponentItem[]>,
    storeClientComponentV2: Map<string, ComponentItem[]>,
    storeClientV2: Map<string, ComponentItem[]>,
    scenarioTagV2: Map<string, ComponentItem[]>,
    vdirV2: Map<string, ComponentItem[]>,
    processV2: Map<string, ComponentItem[]>,
    datasetV2: Map<string, ComponentItem[]>): ITagSearchResult {
    const regexps = key.trim().split(/\s+/).map(item => new RegExp(`${escapeRegex(item)}`, "i"));

    return [
        [CategoryDivision.Owner, Array.from(owners.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.VDir, Array.from(vdirs.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.Process, Array.from(processes.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.ScenarioTag, Array.from(scenarioTags.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.GriffinApp, Array.from(griffinApps.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.GriffinProcessor, Array.from(griffinProcessors.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.StoreClient, Array.from(storeClients.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.StoreClientComponent, Array.from(storeClientComponents.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.SSDDataSetName, Array.from(ssdDataSetNames.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.GriffinAppV2, Array.from(griffinAppV2.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.GriffinProcessorV2, Array.from(griffinProcessorV2.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.StoreClientComponentV2, Array.from(storeClientComponentV2.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.StoreClientV2, Array.from(storeClientV2.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.ScenarioTagV2, Array.from(scenarioTagV2.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.VdirV2, Array.from(vdirV2.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.ProcessV2, Array.from(processV2.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
        [CategoryDivision.DataSetV2, Array.from(datasetV2.values()).filter(item => regexps.every(keyRegex => keyRegex.test(item[0].name)))],
    ];
}

function getAzureResult(key: string, subscription?: ISubscription[], clusterIds?: string[]): ITagSearchResultDescription {
    const regexps = key.trim().split(/\s+/).map(item => new RegExp(`${escapeRegex(item)}`, "i"));
    return [
        [
            CategoryDivision.Subscription,
            searchSubscriptions(regexps, subscription).map(subscription => ({
                title: `${subscription.subscriptionId}(${subscription.subscriptionName})`,
                description: ""
            }))
        ], [
            CategoryDivision.Cluster,
            searchClusterIds(regexps, clusterIds).map(clusterId => ({
                title: clusterId,
                description: ""
            }))
        ]
        
    ];
}