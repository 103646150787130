import { CategoryDivision } from "./CategoryDivision";
import { FiltersView } from "../reducers/filterReducer";
import { ServiceTreeItem } from "./serviceTree";
import { concat } from "lodash";

export interface IFilterViewContent {
    DivisionId: string[];
    DivisionName: string[];
    OrganizationId: string[];
    OrganizationName: string[];
    ServiceGroupId: string[];
    ServiceGroupName: string[];
    TeamGroupId: string[];
    TeamGroupName: string[];
    ServiceId?: string[];
    ServiceName: string[];
    ServiceOwner: string[];
    BusinessOwner: string[];
    GriffinApplicationId: string[];
    GriffinApplicationName: string[];
    GriffinProcessor: string[];
    Process: string[];
    VDir: string[];
    ScenarioTag: string[];
    PredefinedViewName: string[];
    Subscription: string[];
    ClusterId: string[];
    StoreClient: string[];
    StoreClientComponent: string[];
    SSDDataSetName: string[];

    GriffinApplicationV2: string[];
    GriffinProcessorV2: string[];
    VdirV2: string[];
    ProcessV2: string[];
    ScenarioTagV2: string[];
    StoreClientV2: string[];
    StoreClientComponentV2: string[];
    DataSetV2: string[];

    PlatformService: string[];
    PlatformProcess: string[];
    PlatformApp: string[];
    PlatformSubApp: string[];
    PlatformClient: string[];
    PlatformClientComponent: string[];

    Category: string[];
    Workload: string[];
    Confidence: string[];
    ExecutionStatus: string[];

    FiscalYear: string[];
    ReviewStatus: string[];
}

export type IPartialFilterViewContent = { 
    [category in keyof IFilterViewContent]?: string[];
}
export interface IFilterView {
    filterContent: IFilterViewContent | IPartialFilterViewContent;
    viewName: string;
    id: number;
    userId: string;
    parentId: number;
    status: number;
    hasChild: boolean;
    children?: IFilterView[];
    isOpen?: boolean;
}

export interface ISharedView {
    id: string;
    filterContent: IFilterViewContent | IPartialFilterViewContent;
    startDate: string;
    endDate: string;
}

export interface IFiltersPostJsonData {
    DivisionNames: string[];
    DivisionIds: string[];
    OrganizationNames: string[];
    OrganizationIds: string[];
    TeamGroupNames: string[];
    TeamGroupIds: string[];
    ServiceGroupNames: string[];
    ServiceGroupIds: string[];
    ServiceNames: string[];
    ServiceIds: string[];
    Owners: string[];
    ScenarioTags: string[];
    GriffinApplicationNames: string[];
    GriffinApplicationIds: string[];
    Processes: string[];
    Vdirs: string[];
    PredefinedViewName?: string[];
    SubscriptionIds: string[];
    ClusterIds: string[];
    GriffinProcessors: string[];
    StoreClients: string[];
    StoreClientComponents: string[];
    SSDDataSetNames: string[];
    Ring: string;
    Regions: string[];
    Rings: string[];
    AppName: string[];
    SubApp: string[];
    PlatformServices: string[];
    PlatformProcesses: string[];
    PlatformApps: string[];
    PlatformSubApps: string[];
    PlatformClients: string[];
    PlatformClientComponents: string[];
}

export interface ICosmicSearchFilters {
    Workloads: string[];
    TeamGroupNames: string[];
    ServiceGroupNames: string[];
    ServiceNames: string[];
    Namespaces: string[];
    Regions: string[];
    Rings: string[];
    NodeOS: string[];
}

export interface IFilters {
    filters: Partial<Record<CategoryDivision, string[]>>;
    view: FiltersView;
}

export interface IDateRangeFilterData {
    startDate: moment.Moment;
    endDate: moment.Moment;
}

export function parseFiltersToJson(filtersData: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, ring?: string, regions?: string[], rings?: string[]): IFiltersPostJsonData {
    const jsonData: IFiltersPostJsonData = {
        "DivisionNames": filtersData.Division?.map(divisionId => serviceIdMap.get(divisionId)?.n || divisionId) || [],
        "DivisionIds": filtersData.Division || [],
        "OrganizationNames": filtersData.Organization?.map(organizationId => serviceIdMap.get(organizationId)?.n || organizationId) || [],
        "OrganizationIds": filtersData.Organization || [],
        "TeamGroupNames": filtersData.TeamGroup?.map(teamGroupId => serviceIdMap.get(teamGroupId)?.n || teamGroupId) || [],
        "TeamGroupIds": filtersData.TeamGroup || [],
        "ServiceGroupNames": filtersData.ServiceGroup?.map(serviceGroupId => serviceIdMap.get(serviceGroupId)?.n || serviceGroupId) || [],
        "ServiceGroupIds": filtersData.ServiceGroup || [],
        "ServiceNames": filtersData.Service?.map(serviceId => serviceIdMap.get(serviceId)?.n || serviceId) || [],
        "ServiceIds": filtersData.Service || [],
        "Owners": filtersData.Owner || [],
        "ScenarioTags": [...(filtersData.ScenarioTag || []), ...(filtersData.ScenarioTagV2 || [])],
        "GriffinApplicationIds": filtersData.GriffinAppV2?.map(app => app.substring(app.length - 37, app.length - 1)) || [],
        "GriffinApplicationNames": filtersData.GriffinApp || [],
        "Processes": concat(filtersData.Process || [], filtersData.ProcessV2 || []),
        "Vdirs": [...(filtersData.VDir || []), ...(filtersData.VdirV2 || [])],
        "SubscriptionIds": filtersData.Subscription?.map(subscription => subscription.substring(0, subscription.includes('(') ? subscription.indexOf('(') : undefined)) || [],
        "ClusterIds": filtersData.Cluster || [],
        "GriffinProcessors": [...(filtersData.GriffinProcessor || []), ...(filtersData.GriffinProcessorV2 || [])],
        "StoreClients": concat(filtersData.StoreClient || [], filtersData.StoreClientV2 || []),
        "StoreClientComponents": concat(filtersData.StoreClientComponent || [], filtersData.StoreClientComponentV2 || []),
        "SSDDataSetNames": [...(filtersData.SSDDataSetName || []), ...(filtersData.DataSetV2 || [])],
        "Ring": ring || "All",
        "Regions": regions || [],
        "Rings": rings || [],
        "AppName": filtersData.PlatformApp || [],
        "SubApp": filtersData.PlatformSubApp || [],
        "PlatformServices": filtersData.Service || [],
        "PlatformProcesses": filtersData.PlatformProcess || [],
        "PlatformApps": filtersData.PlatformApp || [],
        "PlatformSubApps": filtersData.PlatformSubApp || [],
        "PlatformClients": filtersData.PlatformClient || [],
        "PlatformClientComponents": filtersData.PlatformClientComponent || []
    }

    if (filtersData.PredefinedViewName?.length) {
        jsonData.PredefinedViewName = filtersData.PredefinedViewName;
    }

    return jsonData;
}

export function parseCosmicSearchFiltersJson(cosmicSearchFilters: string) {
    let cosmicSearchFiltersJson: ICosmicSearchFilters;
    try {
        cosmicSearchFiltersJson = JSON.parse(cosmicSearchFilters);
    } catch (error) {
        return {
            Workloads: [],
            TeamGroupNames: [],
            ServiceGroupNames: [],
            ServiceNames: [],
            Namespaces: [],
            Regions: [],
            Rings: [],
            NodeOS: [],
        } as ICosmicSearchFilters;
    }
    
    return cosmicSearchFiltersJson;
}