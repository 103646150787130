import * as React from "react";
import PageWrapper from "../PageWrapper";
import styles from "../Page.less";
import {
    useGetAllBuildRoleFYCloudBuildCost,
    useGetAllUsageScenarioFYCloudBuildCost,
    useGetManagerFYReturnTypeCost,
    useGetManagerMonthToMonthCloudBuildCost,
} from "../../../../hooks/useES365CostQuery";
import ResponseBoundary from "../../../ResponseBoundary/ResponseBoundary";
import ManagersReturnTypeStackChart from "../../Charts/CloudBuild/ManagersReturnTypeStackChart";
import ManagersUsageScenarioOverviewPieChart from "../../Charts/CloudBuild/ManagersUsageScenarioOverviewPieChart";
import ManagersBuildRoleOverviewPieChart from "../../Charts/CloudBuild/ManagersBuildRoleOverviewPieChart";
import ManagersMonthToMonthCostTable from "../../Charts/CloudBuild/ManagersMonthToMonthCostTable";

const CloudBuildGemPage: React.FC = () => {
    const { data: esReturnTypeCost, isLoading: isReturnTypeLoading, isError: isReturnTypeError } = useGetManagerFYReturnTypeCost();
    const { data: esUsageScenarioCost, isLoading: isUsageScenarioLoading, isError: isUsageScenarioError } = useGetAllUsageScenarioFYCloudBuildCost();
    const { data: esBuildRoleCost, isLoading: isBuildRoleLoading, isError: isBuildRoleError } = useGetAllBuildRoleFYCloudBuildCost();
    const { data: esManagerMonthCost, isLoading: isManagerMonthCostLoading, isError: isManagerMonthCostError } = useGetManagerMonthToMonthCloudBuildCost();
    return (
        <PageWrapper
            title="CloudBuild drill down cost on GEMs"
            description="CloudBuild cost drill down on GEMs view will bring awareness to GEM level and trigger usage optimization so that drive down the cost."
        >
            <div className={styles.grid}>
                <ResponseBoundary isLoading={isReturnTypeLoading} errorHappened={isReturnTypeError} data={esReturnTypeCost}>
                    {esReturnTypeCost !== undefined ? <ManagersReturnTypeStackChart data={esReturnTypeCost} /> : <></>}
                </ResponseBoundary>
                <ResponseBoundary isLoading={isUsageScenarioLoading} errorHappened={isUsageScenarioError} data={esUsageScenarioCost}>
                    {esUsageScenarioCost !== undefined ? <ManagersUsageScenarioOverviewPieChart data={esUsageScenarioCost} /> : <></>}
                </ResponseBoundary>
            </div>
            <div className={styles.grid}>
                <ResponseBoundary isLoading={isBuildRoleLoading} errorHappened={isBuildRoleError} data={esBuildRoleCost}>
                    {esBuildRoleCost !== undefined ? <ManagersBuildRoleOverviewPieChart data={esBuildRoleCost} /> : <></>}
                </ResponseBoundary>
            </div>
            <div>
                <ResponseBoundary isLoading={isManagerMonthCostLoading} errorHappened={isManagerMonthCostError} data={esManagerMonthCost}>
                    {esManagerMonthCost !== undefined ? (
                        <ManagersMonthToMonthCostTable data={esManagerMonthCost} description="CloudBuild cost MoM comparison of all GEMs" />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
        </PageWrapper>
    );
};

export default CloudBuildGemPage;
