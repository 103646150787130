import React from 'react';

import LoadingState from "../../ResponseBoundary/LoadingState";
import LineChart from "../../common/LineChart";

interface LinearChartProps {
    isLoading: boolean;
    isDelay: boolean;
    targetData: [number, number][];
    actualData: [number, number][];
    gapData: [number, number][];
}

const LinearChart: React.FC<LinearChartProps> = (props) => {
    return (
        <>
             {
                !props.isLoading ?
                    <div style={{ height: '250px' }}>
                        <LineChart
                            minValue={findMinSecondNumber(props.actualData, props.targetData)}
                            series={[
                                {
                                    name: "Target",
                                    data: props.targetData,
                                    color: "#0078D4",
                                    type: "area",
                                },
                                {
                                    name: "Actual Value",
                                    data: props.actualData,
                                    color: props.isDelay ? "#990000" : "#107C10",
                                    type: "area",
                                },
                            ]}
                            ignoreWeekend={false}
                            isNotCurrency={true}
                            dateFormatStr={"MMM Y"}
                        />
                    </div>
                     :
                    <LoadingState />
            }
        </>
    );
};

function findMinSecondNumber(data1: [number, number][], data2: [number, number][]): number {
    const secondNumbers1 = data1.map(tuple => tuple[1]);
    const secondNumbers2 = data2.map(tuple => tuple[1]);

    const allSecondNumbers = secondNumbers1.concat(secondNumbers2);

    const minSecondNumber = Math.min(...allSecondNumbers);

    return minSecondNumber;
}

export default LinearChart;
