import { Event } from "../hooks/useEventBus";

export enum EventTypeEnum {
    OpenNotificationPanel = "OpenNotificationPanel",
    ScrollToTableEvent = "ScrollToTableEvent",
    OpenInsightsDetails = "OpenInsightsDetails",
    OpenFeedbackPanel = "OpenFeedbackPanel",
}

export type OpenNotificationPanelEvent = Event<EventTypeEnum.OpenNotificationPanel>;
export type ScrollToTableEvent = Event<EventTypeEnum.ScrollToTableEvent>;
export type OpenInsightsDetailsEvent = Event<EventTypeEnum.OpenInsightsDetails>;
export type OpenFeedbackPanelEvent = Event<EventTypeEnum.OpenFeedbackPanel>;
