import {
    PCMv2UnitPrice,
    PcmV2AggeratedLevel,
    PcmV2SceneTypeEnum,
    PcmV2SubSceneTypeEnum,
    PcmV2WorkloadType,
    SubstrateCostV2,
} from "../../../../models/PcmV2Models";
import { ReactTableColumnOption, ReactTableExpandableData } from "../../../common/table/TableUtils";
import { isStorageDatasetSubScene, isStorageMailboxSubScene, isStorageSubScene, sumCosts } from "../../../../utils/PcmV2Utils";

import { Cell, Row } from "react-table";
import { DefaultPcmV2Cost } from "../../../../models/constants/PcmV2Constants";
import { Moment } from "moment";
import React from "react";
import { ServiceTreeItem } from "../../../../models/serviceTree";
import { SubstrateCostTableGriffinAppCell } from "./SubstrateCostTableGriffinAppCell";
import { SubstrateCostTableScenarioTagCell } from "./SubstrateCostTableScenarioTagCell";
import { SubstrateCostTableTooltip } from "./SubstrateCostTableTooltip";
import { TableColumnRenderer } from "../../../common/table/TableColumnRenderer";
import { IIconProps, Icon, TooltipHost } from "@fluentui/react";
import { formatNumber } from "../../../../utils/currency";
import substrateStyles from "../../Substrate.less";
import { SubstrateCostTableFeedbackCell } from "./SubstrateCostTableFeedbackCell";

export type SubstrateCostTableColumnId =
    | keyof SubstrateCostV2
    | "devOwner"
    | "pmOwner"
    | "scenarioTags"
    | "Feedback"
    | "griffinApps"
    | "dataset"
    | "mailboxCategory"
    | "workload";

const PeakHourDescription = [
    "If your workload is classified as 'discretionary' or 'Internal Maintenance' in WLM priority on Substrate, or running at off peak hours, off-peak rates apply to you. ",
    "Peak hours are the hottest 3-hour frame at forest level.",
];

const OffPeakHourDescription = "Traffic outside of peak hour frame.";

const TruDescription = [
    "Transaction request units. This is a singular metric to cover both CPU and IO consumed at Store.",
    "TRU = (milliseconds of CPU-Core consumed in Store * 1) + (IOs consumed in Store * 9)",
];

const LogBytesDescription = "Networking bytes sending into Substrate for logging purpose.";

const BytesReturnedDescription = "Networking bytes sending out of Substrate due to read Substrate contents.";

export const SubstrateCostTableFieldDescriptions: Partial<Record<SubstrateCostTableColumnId, string[]>> = {
    totalCarbonEmission: [
        "In JAWS we want to empower every engineer, PM, and leader across E+D to understand the emissions associated with the services they own. That is why we have invested in creating views that enable carbon costs to be easily visible for service owners. Our methodolgy looks at emissions across 2 scopes and apportions them to services based on their relative usage. We make it a priority to be grounded in the best available math and science and thus will continue to iterate and refine the methodolgy moving forward.",
    ],
    carbonScope2Emission: [
        "Scope 2 emissions are indirect emissions that come from the production of the electricity or heat we use. For Microsoft, this can be thought of as the emissions from all of the power, heating, and cooling we have across our campuses and data centers.",
    ],
    carbonScope3Emission: [
        "Scope 3 emissions are the indirect emissions that come from all of the other activities in which we are engaged. This includes emissions associated with manufacturing the products we buy. For Microsoft, a large portion of this comes from the full lifecycle of our data centers. Given the breadth of scope 3 emissions, they are often larger than scope 2.",
    ],
    itemReadCost: ["Read an item from Substrate Store. Every Item Read incurs CPU and DRAM in REST/XSO/Store/ESE, and HDD IO to read off-page data."],
    cost: ["Total Substrate cost incurred across all metrics."],
    itemWriteCost: [
        "Every Item Write (create, update, delete) to Substrate items incurs CPU and DRAM in XSO/Store/ESE, and both read and write HDD IO, as well as redundant replication costs.",
    ],
    itemQueryCost: ["Query items from Substrate Store. Item Queries incur CPU and DRAM in REST/XSO/Store/ESE, and HDD IO if hit off-page data from HDD. "],
    processHostingCost: [
        "Hosting cost for Model A/B/B2 features on Substrate backend. If you have standalone process or vdirs running on Substrate backend, you incur CPU and DRAM cost. ",
    ],
    networkCost: ["Network cost calculates the bytes written into (logbytes) and bytes send out (bytes returned) of Substrate through network. "],
    hddCost: ["ItemStore space consumed on HDD. All item/data on Substrate have a copy in HDD storage. "],
    ssdmcdbCost: ["SSD storage cost in MCDB database."],
    ssdKvCacheCost: [
        "A simple key-value cache available to Model B2 services for caching data that is anchored-to Substrate data-shards. KV Cache is persisted in SSD and offers Substrate's fundamental features including HA/DR, at-rest data encryption, physical data security, geo residency, etc.",
    ],
    offPeakItemReadsCount: [OffPeakHourDescription],
    offPeakItemWritesCount: [OffPeakHourDescription],
    offPeakItemQueriesCount: [OffPeakHourDescription],
    peakHourItemReadsCount: [...PeakHourDescription],
    peakHourItemWritesCount: [...PeakHourDescription],
    peakHourItemQueriesCount: [...PeakHourDescription],
    itemReadOffPeakUsage: [...TruDescription, OffPeakHourDescription],
    itemReadPeakUsage: [...TruDescription, ...PeakHourDescription],
    itemWriteOffPeakUsage: [...TruDescription, OffPeakHourDescription],
    itemWritePeakUsage: [...TruDescription, ...PeakHourDescription],
    itemQueryOffPeakUsage: [...TruDescription, OffPeakHourDescription],
    itemQueryPeakUsage: [...TruDescription, ...PeakHourDescription],
    peakLogBytes: [LogBytesDescription, ...PeakHourDescription],
    offPeakLogBytes: [LogBytesDescription, OffPeakHourDescription],
    peakBytesReturned: [BytesReturnedDescription, ...PeakHourDescription],
    offPeakBytesReturned: [BytesReturnedDescription, OffPeakHourDescription],
    processHostingUsage: [
        "Process Hosting Request Unit. Singular metric to calculate the process hosting cost on Substrate backend, for CPU and DRAM consumptions. PRU = (milliseconds of CPU-Core consumed by Feature process * 1) + (GB-Second of DRAM consumed by Feature process * 107)",
    ],
};

export const SubstrateCostTableColumnNames: Partial<Record<SubstrateCostTableColumnId, string>> = {
    identity: "Service",
    pmOwner: "PM Owners",
    devOwner: "Dev Owners",
    cost: "Total Substrate Cost",
    transactionCost: "Total Transaction Cost",
    itemReadCost: "Item Reads Cost",
    itemWriteCost: "Item Writes Cost",
    itemQueryCost: "Item Queries Cost",
    itemReadPeakUsage: "Item Reads TRU (Peak)",
    itemReadOffPeakUsage: "Item Reads TRU (Off-Peak)",
    itemWritePeakUsage: "Item Writes TRU (Peak)",
    itemWriteOffPeakUsage: "Item Writes TRU (Off-Peak)",
    itemQueryPeakUsage: "Item Queries TRU (Peak)",
    itemQueryOffPeakUsage: "Item Queries TRU (Off-Peak)",
    itemReadCount: "Item Reads Count",
    itemWriteCount: "Item Writes Count",
    itemQueryCount: "Item Queries Count",
    offPeakItemQueriesCount: "Item Queries Count (Off-Peak)",
    offPeakItemReadsCount: "Item Reads Count (Off-Peak)",
    offPeakItemWritesCount: "Item Writes Count (Off-Peak)",
    peakHourItemQueriesCount: "Item Queries Count (Peak)",
    peakHourItemReadsCount: "Item Reads Count (Peak)",
    peakHourItemWritesCount: "Item Writes Count (Peak)",
    networkCost: "Network Cost",
    logBytesCost: "Log Bytes Cost",
    bytesReturnedCost: "Bytes Returned Cost",
    peakLogBytes: "Log Bytes (Peak)",
    peakBytesReturned: "Bytes Returned (Peak)",
    offPeakLogBytes: "Log Bytes (Off-Peak)",
    offPeakBytesReturned: "Bytes Returned (Off-Peak)",
    processHostingCost: "Process Hosting Cost",
    processHostingCpuCost: "CPU Cost",
    processHostingMemoryCost: "Memory Cost",
    dailyCpuCores: "CPU (Cores)",
    dailyMemoryMB: "Memory (MB)",
    hddCost: "HDD Storage Cost",
    hddUsage: "Average HDD Size (GB)",
    ssdCost: "SSD Cost",
    ssdKvCacheCost: "SSD KvCache Cost",
    ssdKvCacheUsage: "Average KvCache Size(GB)",
    ssdmcdbCost: "SSD MCDB Cost",
    ssdmcdbUsage: "Average MCDB Size(GB)",
    totalCarbonEmission: "Total Carbon Emission",
    carbonScope2Emission: "Carbon Scope2 Emission",
    carbonScope3Emission: "Carbon Scope3 Emission",
    scenarioTags: "Scenario Tags",
    Feedback: "Feedback",
    griffinApps: "Griffin Apps",
    dataset: "Dataset Name",
    mailboxCategory: "Mailbox Category",
    workload: "Workload",
};

export const SubstrateCostTableSceneColumnNames: Partial<
    Record<PcmV2SubSceneTypeEnum | PcmV2SceneTypeEnum, Partial<Record<SubstrateCostTableColumnId, string>>>
> = {
    [PcmV2SubSceneTypeEnum.TransactionScenarioTagCost]: {
        identity: "Service - Scenario Tag",
    },
    [PcmV2SubSceneTypeEnum.NetworkScenarioTagCost]: {
        identity: "Service - Scenario Tag",
    },
    [PcmV2SubSceneTypeEnum.SSDTotalCost]: {
        ssdCost: "Total SSD Storage Cost",
    },
    [PcmV2SceneTypeEnum.Network]: {
        networkCost: "Total Network Cost",
    },
    [PcmV2SubSceneTypeEnum.SSDDatasetCost]: {
        ssdCost: "Total SSD Storage Cost",
    },
    [PcmV2SceneTypeEnum.ProcessHosting]: {
        processHostingCost: "Total Process Hosting Cost",
    },
};

export const SubstrateCostTableSceneColumnIds: Record<PcmV2SceneTypeEnum, SubstrateCostTableColumnId[]> = {
    [PcmV2SceneTypeEnum.Overview]: [
        "identity",
        "Feedback",
        "cost",
        "itemReadCost",
        "itemWriteCost",
        "itemQueryCost",
        "processHostingCpuCost",
        "processHostingMemoryCost",
        "networkCost",
        "hddCost",
        "ssdmcdbCost",
        "ssdKvCacheCost",
        "totalCarbonEmission",
        "carbonScope2Emission",
        "carbonScope3Emission",
        "devOwner",
        "pmOwner",
    ],
    [PcmV2SceneTypeEnum.ProcessHosting]: [
        "identity",
        "Feedback",
        "processHostingCost",
        "processHostingCpuCost",
        "dailyCpuCores",
        "processHostingMemoryCost",
        "dailyMemoryMB",
        "pmOwner",
        "devOwner",
    ],
    [PcmV2SceneTypeEnum.Network]: [],
    [PcmV2SceneTypeEnum.SSD]: [],
    [PcmV2SceneTypeEnum.Transaction]: [],
    [PcmV2SceneTypeEnum.HDD]: [],
};

export const SubstrateCostTableSubSceneColumnIds: Record<PcmV2SubSceneTypeEnum, SubstrateCostTableColumnId[]> = {
    [PcmV2SubSceneTypeEnum.TransactionCost]: [
        "identity",
        "scenarioTags",
        "Feedback",
        "transactionCost",
        "itemReadCost",
        "itemWriteCost",
        "itemQueryCost",
        "itemReadCount",
        "itemWriteCount",
        "itemQueryCount",
        "peakHourItemReadsCount",
        "offPeakItemReadsCount",
        "itemReadPeakUsage",
        "itemReadOffPeakUsage",
        "peakHourItemWritesCount",
        "offPeakItemWritesCount",
        "itemWritePeakUsage",
        "itemWriteOffPeakUsage",
        "peakHourItemQueriesCount",
        "offPeakItemQueriesCount",
        "itemQueryPeakUsage",
        "itemQueryOffPeakUsage",
        "devOwner",
        "pmOwner",
    ],
    [PcmV2SubSceneTypeEnum.TransactionScenarioTagCost]: [
        "identity",
        "griffinApps",
        "Feedback",
        "transactionCost",
        "itemReadCost",
        "itemWriteCost",
        "itemQueryCost",
        "itemReadCount",
        "itemWriteCount",
        "itemQueryCount",
        "peakHourItemReadsCount",
        "offPeakItemReadsCount",
        "itemReadPeakUsage",
        "itemReadOffPeakUsage",
        "peakHourItemWritesCount",
        "offPeakItemWritesCount",
        "itemWritePeakUsage",
        "itemWriteOffPeakUsage",
        "peakHourItemQueriesCount",
        "offPeakItemQueriesCount",
        "itemQueryPeakUsage",
        "itemQueryOffPeakUsage",
        "devOwner",
        "pmOwner",
    ],
    [PcmV2SubSceneTypeEnum.NetworkCost]: [
        "identity",
        "scenarioTags",
        "Feedback",
        "networkCost",
        "logBytesCost",
        "bytesReturnedCost",
        "peakLogBytes",
        "peakBytesReturned",
        "offPeakLogBytes",
        "offPeakBytesReturned",
        "devOwner",
        "pmOwner",
    ],
    [PcmV2SubSceneTypeEnum.NetworkScenarioTagCost]: [
        "identity",
        "griffinApps",
        "Feedback",
        "networkCost",
        "logBytesCost",
        "bytesReturnedCost",
        "peakLogBytes",
        "peakBytesReturned",
        "offPeakLogBytes",
        "offPeakBytesReturned",
        "devOwner",
        "pmOwner",
    ],
    [PcmV2SubSceneTypeEnum.HDDDatasetCost]: ["dataset", "Feedback", "mailboxCategory", "identity", "hddCost", "hddUsage", "devOwner", "pmOwner"],
    [PcmV2SubSceneTypeEnum.HDDMailboxCost]: ["mailboxCategory", "Feedback", "identity", "dataset", "hddCost", "hddUsage", "devOwner", "pmOwner"],
    [PcmV2SubSceneTypeEnum.SSDTotalCost]: ["identity", "Feedback", "ssdCost", "ssdmcdbCost", "ssdmcdbUsage", "ssdKvCacheCost", "ssdKvCacheUsage", "devOwner", "pmOwner"],
    [PcmV2SubSceneTypeEnum.SSDDatasetCost]: ["dataset", "Feedback", "mailboxCategory", "identity", "ssdmcdbCost", "ssdmcdbUsage", "devOwner", "pmOwner"],
    [PcmV2SubSceneTypeEnum.SSDMailboxCost]: ["mailboxCategory", "Feedback", "identity", "dataset", "ssdmcdbCost", "ssdmcdbUsage", "devOwner", "pmOwner"],
};

export const SubstrateCostTableDefaultHiddenColumns: Record<string, SubstrateCostTableColumnId[]> = {
    [PcmV2SubSceneTypeEnum.TransactionCost]: [
        "peakHourItemReadsCount",
        "offPeakItemReadsCount",
        "itemReadPeakUsage",
        "itemReadOffPeakUsage",
        "peakHourItemWritesCount",
        "offPeakItemWritesCount",
        "itemWritePeakUsage",
        "itemWriteOffPeakUsage",
        "peakHourItemQueriesCount",
        "offPeakItemQueriesCount",
        "itemQueryPeakUsage",
        "itemQueryOffPeakUsage",
        "pmOwner",
        "devOwner",
    ],
    [PcmV2SubSceneTypeEnum.TransactionScenarioTagCost]: [
        "peakHourItemReadsCount",
        "offPeakItemReadsCount",
        "itemReadPeakUsage",
        "itemReadOffPeakUsage",
        "peakHourItemWritesCount",
        "offPeakItemWritesCount",
        "itemWritePeakUsage",
        "itemWriteOffPeakUsage",
        "peakHourItemQueriesCount",
        "offPeakItemQueriesCount",
        "itemQueryPeakUsage",
        "itemQueryOffPeakUsage",
        "pmOwner",
        "devOwner",
    ],
};

export const SubstrateTableSubRowsPlaceHolder: ReactTableExpandableData<SubstrateCostV2>[] = [
    {
        data: DefaultPcmV2Cost,
        isSubRowPlaceHolder: true,
        depth: 1,
    },
];

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

function getDefaultColumnConfig(
    key: SubstrateCostTableColumnId,
    scene: PcmV2SceneTypeEnum,
    subScene?: PcmV2SubSceneTypeEnum
): ReactTableColumnOption<ReactTableExpandableData<SubstrateCostV2>, SubstrateCostTableColumnId> {
    return {
        id: key,
        sortType: 'basic',
        name: SubstrateCostTableSceneColumnNames[subScene || scene]?.[key] || SubstrateCostTableColumnNames[key] || "-",
        headerTooltip: SubstrateCostTableFieldDescriptions[key] && (
            <SubstrateCostTableTooltip id={key} descriptions={SubstrateCostTableFieldDescriptions[key]} />
        ),
        Footer: "-",
        sortDescFirst: true,
    };
}

function getNumericColumnConfig(
    key: SubstrateCostTableColumnId,
    scene: PcmV2SceneTypeEnum,
    subScene?: PcmV2SubSceneTypeEnum
): ReactTableColumnOption<ReactTableExpandableData<SubstrateCostV2>, SubstrateCostTableColumnId> {
    return {
        id: key,
        sortType: (rowA, rowB, id, desc) => {
            const valueA = rowA.values[id];
            const valueB = rowB.values[id];
            if (desc == true) {
                if (typeof valueA != "number" && typeof valueB != "number") return 0;
                if (typeof valueA != "number" && typeof valueB == "number") return -1;
                if (typeof valueA == "number" && typeof valueB != "number") return 1;
            } else {
                if (typeof valueA != "number" && typeof valueB != "number") return 0;
                if (typeof valueA != "number" && typeof valueB == "number") return 1;
                if (typeof valueA == "number" && typeof valueB != "number") return -1;
            }
            const a = Number.parseFloat(rowA.values[id]);
            const b = Number.parseFloat(rowB.values[id]);
            if (a > b) return 1; 
            if (b > a) return -1;
             return 0;
     },
        name: SubstrateCostTableSceneColumnNames[subScene || scene]?.[key] || SubstrateCostTableColumnNames[key] || "-",
        headerTooltip: SubstrateCostTableFieldDescriptions[key] && (
            <SubstrateCostTableTooltip id={key} descriptions={SubstrateCostTableFieldDescriptions[key]} />
        ),
        Footer: "-",
        sortDescFirst: true,
    };
}

export function getSubstrateTableColumns(
    data: SubstrateCostV2[],
    startDate: Moment,
    endDate: Moment,
    scene: PcmV2SceneTypeEnum,
    subScene: PcmV2SubSceneTypeEnum | undefined,
    unitPrice: PCMv2UnitPrice | undefined,
    serviceTreeIdMap: Map<string, ServiceTreeItem>,
    serviceNameScenarioCountMap: Map<string, number>,
    appIdScenarioTagCountMap: Map<string, number>,
    serviceNameGriffinAppCountMap: Map<string, number>
): ReactTableColumnOption<ReactTableExpandableData<SubstrateCostV2>, SubstrateCostTableColumnId>[] {
    const totalCost = sumCosts(data);
    const totalDays = endDate.diff(startDate, "days") + 1;

    const allColumns: ReactTableColumnOption<ReactTableExpandableData<SubstrateCostV2>, SubstrateCostTableColumnId>[] = [
        {
            ...getNumericColumnConfig("totalCarbonEmission", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.totalCarbonEmission,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCarbonEmission(cell.value),
            Footer: TableColumnRenderer.renderCarbonEmission(totalCost?.totalCarbonEmission),
        },
        {
            ...getNumericColumnConfig("carbonScope2Emission", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.carbonScope2Emission,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCarbonEmission(cell.value),
            Footer: TableColumnRenderer.renderCarbonEmission(totalCost?.carbonScope2Emission),
        },
        {
            ...getNumericColumnConfig("carbonScope3Emission", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.carbonScope3Emission,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCarbonEmission(cell.value),
            Footer: TableColumnRenderer.renderCarbonEmission(totalCost?.carbonScope3Emission),
        },
        {
            ...getDefaultColumnConfig("identity", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => {
                let name: string | undefined;

                if (data.identity?.aggregatedLevel === PcmV2AggeratedLevel.Workload && (subScene === PcmV2SubSceneTypeEnum.TransactionScenarioTagCost || subScene === PcmV2SubSceneTypeEnum.NetworkScenarioTagCost)) {
                    name = data.identity.workload?.substring(data.identity.workload.indexOf(".") + 1);
                } else if (data.identity?.aggregatedLevel === PcmV2AggeratedLevel.Service || isStorageSubScene(subScene)) {
                    name =
                        data.identity.identity && serviceTreeIdMap.has(data.identity.identity)
                            ? serviceTreeIdMap.get(data.identity.identity)?.n
                            : data.identity.identity;
                } else {
                    name = data.identity?.workload;
                }

                return name || "-";
            },
            disableHide: true,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, string>) => {
                if (isStorageSubScene(subScene) && cell.row.original.depth === 0) {
                    return "";
                }

                if (
                    (cell.row.original.data.identity?.aggregatedLevel === PcmV2AggeratedLevel.Service || isStorageSubScene(subScene)) &&
                    cell.value.endsWith("*")
                ) {
                    return (
                        <TooltipHost
                            content="This service name is recently been removed on Service Tree. The old service name is shown here until new name is updated."
                            calloutProps={{
                                isBeakVisible: false,
                                calloutWidth: 180,
                            }}
                            styles={{
                                root: {
                                    height: 16,
                                },
                            }}
                        >
                            <span title={cell.value}>{cell.value}</span>
                        </TooltipHost>
                    );
                }

                return <span title={cell.value}>{cell.value}</span>;
            },
            Footer: isStorageSubScene(subScene) ? "-" : `Total - ${formatNumber(data.length)} Service${data.length > 1 ? "s" : ""}`,
            columnProps: { className: substrateStyles.tableTextColumn },
            cellProps: { className: substrateStyles.tableTextCell },
        },
        {
            ...getDefaultColumnConfig("griffinApps", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, SubstrateCostV2>) => {
                const {
                    value: { identity },
                } = cell;

                if (identity.aggregatedLevel == PcmV2AggeratedLevel.Service) {
                    const serviceName = serviceTreeIdMap.get(identity.identity || "")?.n || "";
                    const griffinAppsCount = serviceNameGriffinAppCountMap.get(serviceName) || 0;

                    return <SubstrateCostTableGriffinAppCell count={griffinAppsCount} cost={cell.value} scene={scene} subScene={subScene!} />;
                } else if (identity.workloadType === PcmV2WorkloadType.ScenarioTag) {
                    return identity.workload?.split(".")[0] || "-";
                }

                return "-";
            },
            columnProps: { className: substrateStyles.tableTextColumn },
            cellProps: { className: substrateStyles.tableTextCell },
        },
        {
            ...getDefaultColumnConfig("pmOwner", scene, subScene),
            accessor: ({ data, parent }: ReactTableExpandableData<SubstrateCostV2>) => {
                const serviceId =
                    data.identity?.aggregatedLevel === PcmV2AggeratedLevel.Service || isStorageSubScene(subScene)
                        ? data.identity?.identity
                        : parent?.data.identity?.identity;
                let name: string | undefined;

                if (serviceId && serviceTreeIdMap.has(serviceId)) {
                    name = serviceTreeIdMap.get(serviceId)?.PmOwner;
                }

                return name || "-";
            },
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, string>) => cell.value,
            columnProps: { className: substrateStyles.tableTextColumn },
            cellProps: { className: substrateStyles.tableTextCell },
        },
        {
            ...getDefaultColumnConfig("devOwner", scene, subScene),
            accessor: ({ data, parent }: ReactTableExpandableData<SubstrateCostV2>) => {
                const serviceId =
                    data.identity?.aggregatedLevel === PcmV2AggeratedLevel.Service || isStorageSubScene(subScene)
                        ? data.identity?.identity
                        : parent?.data.identity?.identity;
                let name: string | undefined;

                if (serviceId && serviceTreeIdMap.has(serviceId)) {
                    name = serviceTreeIdMap.get(serviceId)?.o;
                }

                return name || "-";
            },
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, string>) => cell.value,
            columnProps: { className: substrateStyles.tableTextColumn },
            cellProps: { className: substrateStyles.tableTextCell },
        },
        {
            ...getDefaultColumnConfig("scenarioTags", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => {
                if (data.identity.aggregatedLevel === PcmV2AggeratedLevel.Service) {
                    const serviceId = data.identity.identity;
                    let name = "";

                    if (serviceId && serviceTreeIdMap.has(serviceId)) {
                        name = serviceTreeIdMap.get(serviceId)?.n || "";
                    }

                    return serviceNameScenarioCountMap.get(name) || 0;
                } else {
                    const appId = data.identity.identity;

                    return appId ? appIdScenarioTagCountMap.get(appId) || 0 : 0;
                }
            },
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => (
                <SubstrateCostTableScenarioTagCell cost={cell.row.original.data} value={cell.value} scene={scene} subScene={subScene!} />
            ),
            columnProps: { className: substrateStyles.tableTextColumn },
            cellProps: { className: substrateStyles.tableTextCell },
        },
        {
            ...getDefaultColumnConfig("Feedback", scene, subScene),
            Header: () => <Icon {...feedbackIconProps} />,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => (
                <SubstrateCostTableFeedbackCell
                    columns={cell.row.cells}
                    cell={cell}
                    scene={scene}
                    subScene={subScene}
                    totalDays={totalDays}
                    serviceTreeIdMap={serviceTreeIdMap}
                    serviceNameGriffinAppCountMap={serviceNameGriffinAppCountMap}
                />
            ),
            columnProps: { className: substrateStyles.tableTextColumn },
            cellProps: { className: substrateStyles.tableTextCell },
        },
        {
            ...getDefaultColumnConfig("cost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.cost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.cost),
        },
        {
            ...getDefaultColumnConfig("transactionCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.transactionCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            headerTooltip: (
                <>
                    <div>
                        Transaction cost view gives you the cost of Item Read, Item Write, Item Query. At a hardware level, it covers the cost of CPU, IOPS, and
                        Memory by Substrate platform components (Store, REST, CSO, Cafe, AD, etc.) on baremetal fleet.
                    </div>
                    <div>Transaction cost metrics have Peak/Off-peak rates.</div>
                </>
            ),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.transactionCost),
        },
        {
            ...getDefaultColumnConfig("itemReadCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.itemReadCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.itemReadCost),
            headerTooltip: (
                <SubstrateCostTableTooltip
                    id="itemReadCost"
                    descriptions={SubstrateCostTableFieldDescriptions["itemReadCost"]}
                    unitPrices={
                        unitPrice && [
                            `Peak Hrs: $${unitPrice.peakTRU.toExponential(2).toUpperCase()} per TRU`,
                            `Off-Peak Hrs: $${unitPrice.offPeakTRU.toExponential(2).toUpperCase()} per TRU`,
                        ]
                    }
                />
            ),
        },
        {
            ...getDefaultColumnConfig("itemReadPeakUsage", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.itemReadPeakUsage,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.itemReadPeakUsage),
        },
        {
            ...getDefaultColumnConfig("itemReadOffPeakUsage", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.itemReadOffPeakUsage,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.itemReadOffPeakUsage),
        },
        {
            ...getDefaultColumnConfig("itemWriteCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.itemWriteCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.itemWriteCost),
            headerTooltip: (
                <SubstrateCostTableTooltip
                    id="itemWriteCost"
                    descriptions={SubstrateCostTableFieldDescriptions["itemWriteCost"]}
                    unitPrices={
                        unitPrice && [
                            `Peak Hrs: $${unitPrice.peakTRU.toExponential(2).toUpperCase()} per TRU`,
                            `Off-Peak Hrs: $${unitPrice.offPeakTRU.toExponential(2).toUpperCase()} per TRU`,
                        ]
                    }
                />
            ),
        },
        {
            ...getDefaultColumnConfig("itemWritePeakUsage", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.itemWritePeakUsage,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.itemWritePeakUsage),
        },
        {
            ...getDefaultColumnConfig("itemWriteOffPeakUsage", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.itemWriteOffPeakUsage,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.itemWriteOffPeakUsage),
        },
        {
            ...getDefaultColumnConfig("itemQueryCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.itemQueryCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.itemQueryCost),
            headerTooltip: (
                <SubstrateCostTableTooltip
                    id="itemQueryCost"
                    descriptions={SubstrateCostTableFieldDescriptions["itemQueryCost"]}
                    unitPrices={
                        unitPrice && [
                            `Peak Hrs: $${unitPrice.peakTRU.toExponential(2).toUpperCase()} per TRU`,
                            `Off-Peak Hrs: $${unitPrice.offPeakTRU.toExponential(2).toUpperCase()} per TRU`,
                        ]
                    }
                />
            ),
        },
        {
            ...getDefaultColumnConfig("itemQueryPeakUsage", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.itemQueryPeakUsage,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.itemQueryPeakUsage),
        },
        {
            ...getDefaultColumnConfig("itemQueryOffPeakUsage", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.itemQueryOffPeakUsage,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.itemQueryOffPeakUsage),
        },
        {
            ...getDefaultColumnConfig("processHostingCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.processHostingCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.processHostingCost),
            headerTooltip: (
                <SubstrateCostTableTooltip
                    id="processHostingCost"
                    descriptions={SubstrateCostTableFieldDescriptions["processHostingCost"]}
                    unitPrices={
                        unitPrice && [
                            `$${unitPrice.cpuCores.toExponential(2).toUpperCase()} per CPU Core`,
                            `$${unitPrice.memoryMB.toExponential(2).toUpperCase()} per MB of Memory`,
                        ]
                    }
                />
            ),
        },
        {
            ...getDefaultColumnConfig("processHostingCpuCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.processHostingCpuCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.processHostingCpuCost),
            headerTooltip: (
                <SubstrateCostTableTooltip
                    id="processHostingCpuCost"
                    descriptions={SubstrateCostTableFieldDescriptions["processHostingCpuCost"]}
                    unitPrices={unitPrice && [`$${unitPrice.cpuCores.toExponential(2).toUpperCase()} per CPU Core`]}
                />
            ),
        },
        {
            ...getDefaultColumnConfig("processHostingMemoryCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.processHostingMemoryCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.processHostingMemoryCost),
            headerTooltip: (
                <SubstrateCostTableTooltip
                    id="processHostingMemoryCost"
                    descriptions={SubstrateCostTableFieldDescriptions["processHostingMemoryCost"]}
                    unitPrices={unitPrice && [`$${unitPrice.memoryMB.toExponential(2).toUpperCase()} per MB of Memory`]}
                />
            ),
        },
        {
            ...getDefaultColumnConfig("itemReadCount", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.itemReadCount,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.itemReadCount),
        },
        {
            ...getDefaultColumnConfig("itemWriteCount", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.itemWriteCount,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.itemWriteCount),
        },
        {
            ...getDefaultColumnConfig("itemQueryCount", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.itemQueryCount,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.itemQueryCount),
        },
        {
            ...getDefaultColumnConfig("peakHourItemReadsCount", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.peakHourItemReadsCount,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.peakHourItemReadsCount),
        },
        {
            ...getDefaultColumnConfig("offPeakItemReadsCount", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.offPeakItemReadsCount,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.offPeakItemReadsCount),
        },
        {
            ...getDefaultColumnConfig("peakHourItemWritesCount", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.peakHourItemWritesCount,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.peakHourItemWritesCount),
        },
        {
            ...getDefaultColumnConfig("offPeakItemWritesCount", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.offPeakItemWritesCount,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.offPeakItemWritesCount),
        },
        {
            ...getDefaultColumnConfig("peakHourItemQueriesCount", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.peakHourItemQueriesCount,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.peakHourItemQueriesCount),
        },
        {
            ...getDefaultColumnConfig("offPeakItemQueriesCount", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.offPeakItemQueriesCount,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.offPeakItemQueriesCount),
        },
        {
            ...getDefaultColumnConfig("dailyCpuCores", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.dailyCpuCores,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.dailyCpuCores),
        },
        {
            ...getDefaultColumnConfig("dailyMemoryMB", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.dailyMemoryMB,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.dailyMemoryMB),
        },
        {
            ...getDefaultColumnConfig("networkCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.networkCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.networkCost),
            headerTooltip: (
                <SubstrateCostTableTooltip
                    id="networkCost"
                    descriptions={SubstrateCostTableFieldDescriptions["networkCost"]}
                    unitPrices={
                        unitPrice && [
                            `Peak Hrs: $${unitPrice.peakNetworking.toExponential(2).toUpperCase()} per LogBytes`,
                            `Off Peak Hrs: $${unitPrice.offPeakNetworking.toExponential(2).toUpperCase()} per LogBytes`,
                        ]
                    }
                />
            ),
        },
        {
            ...getDefaultColumnConfig("logBytesCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.logBytesCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.logBytesCost),
        },
        {
            ...getDefaultColumnConfig("peakLogBytes", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.peakLogBytes,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.peakLogBytes),
        },
        {
            ...getDefaultColumnConfig("offPeakLogBytes", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.offPeakLogBytes,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.offPeakLogBytes),
        },
        {
            ...getDefaultColumnConfig("bytesReturnedCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.bytesReturnedCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.bytesReturnedCost),
        },
        {
            ...getDefaultColumnConfig("peakBytesReturned", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.peakBytesReturned,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.peakBytesReturned),
        },
        {
            ...getDefaultColumnConfig("offPeakBytesReturned", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.offPeakBytesReturned,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value),
            Footer: TableColumnRenderer.renderNumber(totalCost?.offPeakBytesReturned),
        },
        {
            ...getDefaultColumnConfig("hddCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.hddCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.hddCost),
            headerTooltip: (
                <SubstrateCostTableTooltip
                    id="hddCost"
                    descriptions={SubstrateCostTableFieldDescriptions["hddCost"]}
                    unitPrices={unitPrice && [`$${(unitPrice.hddStorage * 30).toExponential(2).toUpperCase()} per GB per Month`]}
                />
            ),
        },
        {
            ...getDefaultColumnConfig("hddUsage", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.hddUsage,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value / totalDays),
            Footer: TableColumnRenderer.renderNumber(totalCost?.hddUsage / totalDays),
            headerTooltip: (
                <div>
                Average storage size across the time range selection.
                </div>
            ),
        },
        {
            ...getDefaultColumnConfig("ssdCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.ssdCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.ssdCost),
        },
        {
            ...getDefaultColumnConfig("ssdKvCacheCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.ssdKvCacheCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.ssdKvCacheCost),
            headerTooltip: (
                <SubstrateCostTableTooltip
                    id="ssdKvCacheCost"
                    descriptions={SubstrateCostTableFieldDescriptions["ssdKvCacheCost"]}
                    unitPrices={unitPrice && [`$${(unitPrice.ssdStorage * 30).toExponential(2).toUpperCase()} per GB per Month`]}
                />
            ),
        },
        {
            ...getDefaultColumnConfig("ssdKvCacheUsage", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.ssdKvCacheUsage,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value / totalDays),
            Footer: TableColumnRenderer.renderNumber(totalCost?.ssdKvCacheUsage / totalDays),
            headerTooltip: (
                <div>
                Average storage size across the time range selection.
                </div>
            ),
        },
        {
            ...getDefaultColumnConfig("ssdmcdbCost", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.ssdmcdbCost,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderCurrency(cell.value),
            Footer: TableColumnRenderer.renderCurrency(totalCost?.ssdmcdbCost),
            headerTooltip: (
                <SubstrateCostTableTooltip
                    id="ssdmcdbCost"
                    descriptions={SubstrateCostTableFieldDescriptions["ssdmcdbCost"]}
                    unitPrices={unitPrice && [`$${(unitPrice.ssdStorage * 30).toExponential(2).toUpperCase()} per GB per Month`]}
                />
            ),
        },
        {
            ...getDefaultColumnConfig("ssdmcdbUsage", scene, subScene),
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data.ssdmcdbUsage,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, number>) => TableColumnRenderer.renderNumber(cell.value / totalDays),
            Footer: TableColumnRenderer.renderNumber(totalCost?.ssdmcdbUsage / totalDays),
            headerTooltip: (
                <div>
                Average storage size across the time range selection.
                </div>
            ),
        },
        {
            ...getDefaultColumnConfig("dataset", scene, subScene),
            columnProps: { className: substrateStyles.tableTextColumn },
            cellProps: { className: substrateStyles.tableTextCell },
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, SubstrateCostV2>) => {
                if (
                    (isStorageMailboxSubScene(subScene) && cell.row.original.depth === 0) ||
                    (isStorageDatasetSubScene(subScene) && cell.row.original.depth > 0)
                ) {
                    return "";
                }

                return cell.value.identity.workload || "-";
            },
            Footer: isStorageDatasetSubScene(subScene) ? `Total - ${data.length} Dataset${data.length > 1 ? "s" : ""}` : "-",
            disableHide: true,
        },
        {
            ...getDefaultColumnConfig("mailboxCategory", scene, subScene),
            columnProps: { className: substrateStyles.tableTextColumn },
            cellProps: { className: substrateStyles.tableTextCell },
            accessor: ({ data }: ReactTableExpandableData<SubstrateCostV2>) => data,
            Cell: (cell: Cell<ReactTableExpandableData<SubstrateCostV2>, SubstrateCostV2>) => {
                if (
                    (isStorageMailboxSubScene(subScene) && cell.row.original.depth === 1) ||
                    (isStorageDatasetSubScene(subScene) && cell.row.original.depth === 0)
                ) {
                    return "";
                }

                return cell.value.identity.workload || "-";
            },
            Footer: isStorageMailboxSubScene(subScene) ? `Total - ${data.length} Mailbox ${data.length > 1 ? "Categories" : "Category"}` : "-",
            disableHide: true,
        },
    ];

    const sceneColumnsKeys = subScene ? SubstrateCostTableSubSceneColumnIds[subScene] : SubstrateCostTableSceneColumnIds[scene];

    const result: ReactTableColumnOption<ReactTableExpandableData<SubstrateCostV2>, SubstrateCostTableColumnId>[] = [];

    sceneColumnsKeys.map((key) => {
        const targetColumn = allColumns.find((col) => col.id === key);

        if (targetColumn) {
            result.push(targetColumn);
        }
    });

    return result;
}
