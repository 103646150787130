// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nP27PdjLowJdhttCrCuw {\n  flex: 1;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 200px;\n}\n.nP27PdjLowJdhttCrCuw svg {\n  height: 200px;\n}\n.p73BoTmwsQprRScUuOPo {\n  font-weight: 700;\n  font-size: 16px;\n  margin: 12px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/substrateV2/common/EmptyCostView.less"],"names":[],"mappings":"AACA;EACI,OAAA;EACA,uBAAA;EACA,mBAAA;EACA,oBAAA;AAAJ;AAJA;EAOQ,aAAA;AAAR;AAIA;EACI,gBAAA;EACA,eAAA;EACA,cAAA;AAFJ","sourcesContent":["\n.emptyCostView {\n    flex: 1;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 200px;\n\n    :global(svg) {\n        height: 200px;\n    }\n}\n\n.description {\n    font-weight: 700;\n    font-size: 16px;\n    margin: 12px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyCostView": "nP27PdjLowJdhttCrCuw",
	"description": "p73BoTmwsQprRScUuOPo"
};
export default ___CSS_LOADER_EXPORT___;
