import { CategoryDivision } from "../models/CategoryDivision";
import { ISubscription, ServiceTreeItem, ServiceTreeLevel } from "../models/serviceTree";
import { ISearchData, ITagSearchResultDescription, ITagSearchResult, ITagSearchResultDescriptionItem, IServiceTreeSearchResult } from "./searchReducer";
import { escapeRegex } from "./stringUtils";

export interface ISearchResultItem {
    id: string;
    title: string;
    type: CategoryDivision;
}

export interface ISearchRichResultItem extends ISearchResultItem {
    description: string;
}

interface IServiceTreeSearchMiddleResult {
    service: ServiceTreeItem[];
    teamGroup: ServiceTreeItem[];
    serviceGroup: ServiceTreeItem[];
    organization: ServiceTreeItem[];
    division: ServiceTreeItem[];
}

export function calcSearchData(key: string, serviceTreeSearchResult: IServiceTreeSearchResult, tagSearchResult: ITagSearchResult, azureResultDescription: ITagSearchResultDescription): ISearchData {
    return {
        searchKey: key,
        serviceTreeSearchResult: serviceTreeSearchResult,
        tagSearchResultDescription: getTagDescription(key, tagSearchResult).concat(azureResultDescription),
        tagSearchResult
    }
}

export function filterSearchData(key: string, serviceTree?: ServiceTreeItem[]): IServiceTreeSearchResult {
    const regexps = key.trim().split(/\s+/).map(item => new RegExp(`${escapeRegex(item)}`, "i"));
    const result = filterSearchDataInternal(regexps, serviceTree);
    const searchArrayResult: IServiceTreeSearchResult = [];

    if (result.service.length > 0) {
        searchArrayResult.push([CategoryDivision.Service, result.service]);
    }
    if (result.teamGroup.length > 0) {
        searchArrayResult.push([CategoryDivision.TeamGroup, result.teamGroup]);
    }
    if (result.serviceGroup.length > 0) {
        searchArrayResult.push([CategoryDivision.ServiceGroup, result.serviceGroup]);
    }
    if (result.organization.length > 0) {
        searchArrayResult.push([CategoryDivision.Organization, result.organization]);
    }
    if (result.division.length > 0) {
        searchArrayResult.push([CategoryDivision.Division, result.division]);
    }

    return searchArrayResult;
}

function filterSearchDataInternal(regexps: RegExp[], serviceTree?: ServiceTreeItem[]): IServiceTreeSearchMiddleResult {
    const result: IServiceTreeSearchMiddleResult = {
        service: [],
        teamGroup: [],
        serviceGroup: [],
        organization: [],
        division: []
    };

    serviceTree?.forEach(item => {
        const nextLevel = filterSearchDataInternal(regexps, item.c);
        result.service = result.service.concat(nextLevel.service);
        result.teamGroup = result.teamGroup.concat(nextLevel.teamGroup);
        result.serviceGroup = result.serviceGroup.concat(nextLevel.serviceGroup);
        result.organization = result.organization.concat(nextLevel.organization);
        result.division = result.division.concat(nextLevel.division);

        if (regexps.every(regex => regex.test(item.n))) {
            switch (item.l) {
                case ServiceTreeLevel.Service:
                    result.service.push(item);
                    break;
                case ServiceTreeLevel.ServiceGroup:
                    result.serviceGroup.push(item);
                    break;
                case ServiceTreeLevel.TeamGroup:
                    result.teamGroup.push(item);
                    break;
                case ServiceTreeLevel.Organization:
                    result.organization.push(item);
                    break;
                case ServiceTreeLevel.Division:
                    result.division.push(item);
                    break;
            }
        }
    });

    return result;
}

export function getTagDescription(key: string, tagSearchResult: ITagSearchResult): ITagSearchResultDescription {
    const tagResultDescriptions: ITagSearchResultDescription = tagSearchResult.map(item => [item[0], item[1].map(innerItem => {
        if (item[0] === CategoryDivision.Owner) {
            return {
                title: innerItem[0].name,
                description: ""
            };
        }
        
        const result: ITagSearchResultDescriptionItem = {
            title: innerItem[0].name,
            description: innerItem.length === 1 ?
                `${innerItem[0].divisionName} > ${innerItem[0].organizationName} > ${innerItem[0].teamGroupName} > ${innerItem[0].serviceGroupName} > ${innerItem[0].serviceName}` :
                innerItem.map(component => component.serviceName).join(" > ")
        };
        return result;
    })]);

    return tagResultDescriptions;
}

export function getChildServiceCount(item: ServiceTreeItem, rememberedSet = new Set<string>()): number {
    if (rememberedSet.has(item.id + "@" + item.l)) {
        return 0;
    }
    rememberedSet.add(item.id + "@" + item.l);
    let count = (item.l === ServiceTreeLevel.Service) ? 1 : 0;
    
    item.c?.forEach(innerItem => count += getChildServiceCount(innerItem, rememberedSet));

    return count;
}

export function getServiceString(num: number): string {
    if (num === 1) {
        return "1 Service";
    } else {
        return `${num} Services`;
    }
}

export function searchSubscriptions(regexps: RegExp[], subscriptions?: ISubscription[]): ISubscription[] {
    if (!subscriptions) {
        return [];
    }

    return subscriptions.filter(subscription => regexps.every(regex => regex.test(subscription.subscriptionId)) || regexps.every(regex => regex.test(subscription.subscriptionName)));
}

export function searchClusterIds(regexps: RegExp[], clusterIds?: string[]): string[] {
    if (!clusterIds) {
        return [];
    }

    return clusterIds.filter(clusterId => regexps.every(regex => regex.test(clusterId)));
}