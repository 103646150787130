import React from "react";
import { useGetAzureDailyMetricsQuery } from "../../../hooks/useAzureQuery";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { LogElement } from "../../../models/LogModel";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { Endpoints } from "../../../utils/Constants";
import { LineChartContainer } from "../common/LineChartContainer";
import { SubComputeLogComponent } from "../../../models/AzureComputeModels";

interface ISubComputeCostProps {
    type: "Public" | "AZSC";
}

export const SubComputeCost: React.FC<ISubComputeCostProps> = (props) => {
    const subAzureDailyCost = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, "VMCost", props.type, "Sum");
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.AzureCompute);
    const activeAnomalies = React.useMemo(() => getActiveAnomalies(anomaliesQuery.data, ["TotalCores"]), [anomaliesQuery.data]);

    return (
        <LineChartContainer
            title="Azure Compute Cost"
            isLoading={subAzureDailyCost.isLoading}
            series={[
                {
                    name: props.type,
                    data: subAzureDailyCost.data?.data.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                },
            ]}
            minValue={0}
            logProps={{
                logComponent: SubComputeLogComponent[props.type],
                logElement: LogElement.AzureCostChart,
                customProperties: {
                    type: props.type,
                }
            }}
            fillMissingAbnormalData
            anomalies={activeAnomalies}
        />
    )
}