import { IFinOpsBudget, IFinOpsBudgetFilters } from "../models/FinOpsBudgets";
import { get, post, postJson } from "../utils/apiServiceBase";
import { entries, forEach, groupBy, isNil, keys, max, maxBy, omitBy, orderBy, sortBy, toNumber, uniq, values } from "lodash";
import moment, { Moment } from "moment";

export async function initializeNewFinOpsBudgets(data: IFinOpsBudget[]): Promise<void> {
    const url = `api/v2.0/finops/initializeNewBudget`;

    try {
        await post(url, data);
        console.log('Budget inserted successfully');
    } catch (error) {
        console.error('Error inserting data:', error);
        throw new Error('Failed to insert budget. ' + error);
    }
}

export async function updateFinOpsBudgets(data: IFinOpsBudget): Promise<void> {
    const url = `api/v2.0/finops/updateExistingBudget`;

    try {
        await post(url, data);
        console.log('Budget updated successfully');
    } catch (error) {
        console.error('Error updating data:', error);
        throw new Error('Failed to update budget. ' + error);
    }
}

export async function reviewFinOpsBudgets(data: IFinOpsBudget): Promise<void> {
    const url = `api/v2.0/finopsAdmin/reviewBudget`;

    try {
        await post(url, data);
        console.log('Review status updated successfully');
    } catch (error) {
        console.error('Error reviewing data:', error);
        throw new Error('Failed to update review status. ' + error);
    }
}

export async function getAllPendingBudgetAsks(finOpsFilters: IFinOpsBudgetFilters): Promise<IFinOpsBudget[]> {
    const url = `api/v2.0/finopsAdmin/getAllPendingBudgetAsks?${new URLSearchParams(omitBy(finOpsFilters, isNil) as any).toString()}`;

    try {
        const response = await get(url);

        if (!response.ok) {
            throw new Error(`Failed to fetch pending review budget asks: ${response.statusText}`);
        }

        const data: IFinOpsBudget[] = await response.json();
        console.log('Pending review budget asks retrieved successfully');
        return data;
    } catch (error) {
        console.error('Error fetching pending review budget asks:', error);
        throw new Error('Failed to fetch pending review budget asks. ' + error);
    }
}

export async function getAllHistoricalBudgetVersion(finOpsFilters: IFinOpsBudgetFilters): Promise<IFinOpsBudget[]> {
    const url = `api/v2.0/finopsAdmin/getAllHistoricalBudgetVersion?${new URLSearchParams(omitBy(finOpsFilters, isNil) as any).toString()}`;

    try {
        const response = await get(url);

        if (!response.ok) {
            throw new Error(`Failed to fetch historical budget versions: ${response.statusText}`);
        }

        const data: IFinOpsBudget[] = await response.json();
        console.log('Historical budget versions retrieved successfully');
        return data;
    } catch (error) {
        console.error('Error fetching historical budget versions:', error);
        throw new Error('Failed to fetch historical budget versions. ' + error);
    }
}

export async function getLatestBudgetVersion(finOpsFilters: IFinOpsBudgetFilters): Promise<IFinOpsBudget[]> {
    const url = `api/v2.0/finops/getLatestBudgetVersion?${new URLSearchParams(omitBy(finOpsFilters, isNil) as any).toString()}`;

    try {
        const response = await get(url);

        if (!response.ok) {
            throw new Error(`Failed to fetch latest budget version: ${response.statusText}`);
        }

        const data: IFinOpsBudget[] = await response.json();
        data.forEach(budget => {
            if (!budget.submitter) {
                budget.version = undefined;
                budget.approvedTime = undefined;
            } else {
                budget.version = moment(budget.version);
                budget.approvedTime = moment(budget.approvedTime);
            }
        })
        console.log('Latest budget version retrieved successfully');
        return data;
    } catch (error) {
        console.error('Error fetching latest budget version:', error);
        throw new Error('Failed to fetch latest budget version. ' + error);
    }
}

export async function getSubmitOverview(finOpsFilters: IFinOpsBudgetFilters, startTime:Moment, endTime:Moment): Promise<IFinOpsBudget[]> {
    const url = `api/v2.0/finops/getSubmitOverview?${new URLSearchParams(omitBy(finOpsFilters, isNil) as any).toString()}&startTime=${startTime.format('YYYY-MM-DD')}&endTime=${endTime.format('YYYY-MM-DD')}`;

    try {
        const response = await get(url);

        if (!response.ok) {
            throw new Error(`Failed to fetch submit overview: ${response.statusText}`);
        }

        const data: IFinOpsBudget[] = await response.json();
        data.forEach(budget => {
            // If the budget is not submitted, set the default increase rate to 4% and calculate the budget based on last year's cost and quantity
            if (budget.increaseRate === 0
                && budget.quantityBudget === 0
                && budget.costBudget === 0) {
                budget.increaseRate = 4;
                budget.costBudget = parseFloat((budget.lastYearCost * 1.04).toFixed(2));
                budget.quantityBudget = budget.lastYearQuantity ? parseFloat((budget.lastYearQuantity * 1.04).toFixed(2)) : undefined;
            }

            if (!budget.submitter) {
                budget.version = undefined;
                budget.approvedTime = undefined;
            } else {
                budget.version = moment(budget.version);
                budget.approvedTime = moment(budget.approvedTime);
            }
        })
        console.log('Submit overview retrieved successfully');
        return orderBy(data, 'lastYearCost', 'desc');
    } catch (error) {
        console.error('Error fetching submit overview:', error);
        throw new Error('Failed to fetch submit overview. ' + error);
    }
}

export async function getCostAndBudget(finOpsFilters: IFinOpsBudgetFilters): Promise<IFinOpsBudget[]> {
    const url = `api/v2.0/finops/getCostAndBudget?${new URLSearchParams(omitBy(finOpsFilters, isNil) as any).toString()}`;

    try {
        const response = await get(url);

        if (!response.ok) {
            throw new Error(`Failed to fetch submit overview: ${response.statusText}`);
        }

        const data: IFinOpsBudget[] = await response.json();
        data.forEach(budget => {
            // If the budget is not submitted, set the default increase rate to 4% and calculate the budget based on last year's cost and quantity
            if (budget.increaseRate === 0
                && budget.quantityBudget === 0
                && budget.costBudget === 0) {
                budget.increaseRate = 4;
                budget.costBudget = parseFloat((budget.lastYearCost * 1.04).toFixed(2));
                budget.quantityBudget = budget.lastYearQuantity ? parseFloat((budget.lastYearQuantity * 1.04).toFixed(2)) : undefined;
            }

            if (!budget.submitter) {
                budget.version = undefined;
                budget.approvedTime = undefined;
            } else {
                budget.version = moment(budget.version);
                budget.approvedTime = moment(budget.approvedTime);
            }
        })
        console.log('Submit overview retrieved successfully');
        return orderBy(data, 'lastYearCost', 'desc');
    } catch (error) {
        console.error('Error fetching submit overview:', error);
        throw new Error('Failed to fetch submit overview. ' + error);
    }
}

export async function getServiceLevelBudget(finOpsFilters: IFinOpsBudgetFilters): Promise<IFinOpsBudget[]> {
    const url = `api/v2.0/finops/getServiceLevelBudget?${new URLSearchParams(omitBy(finOpsFilters, isNil) as any).toString()}`;

    try {
        const response = await get(url);

        if (!response.ok) {
            throw new Error(`Failed to fetch service level budget: ${response.statusText}`);
        }

        const data: IFinOpsBudget[] = await response.json();
        data.forEach(budget => {
            budget.lastYearCost = Math.round(budget.lastYearCost);
            budget.lastYearQuantity = budget.lastYearQuantity && Math.round(budget.lastYearQuantity);

            // If the budget is not submitted, set the default increase rate to 4% and calculate the budget based on last year's cost and quantity
            if (budget.increaseRate === 0
                && budget.quantityBudget === 0
                && budget.costBudget === 0) {
                budget.increaseRate = 4;
                budget.costBudget = Math.round(budget.lastYearCost * 1.04);
                budget.quantityBudget = budget.lastYearQuantity && Math.round(budget.lastYearQuantity * 1.04);
            }

            if (!budget.submitter) {
                budget.version = undefined;
                budget.approvedTime = undefined;
            } else {
                budget.version = moment(budget.version);
                budget.approvedTime = moment(budget.approvedTime);
            }
        })

        const res = orderBy(entries(groupBy(data, 'serviceId')).map(([key, categoryDetails]) => {
            // Do another filtering to get the latest version of each budget, defensive programming
            const value = values(groupBy(categoryDetails, b => b.category + b.type + b.unit))
                .map(sameBudgets => maxBy(sameBudgets, b => b.version?.unix() || 0)) as IFinOpsBudget[];
            const lastYearServiceCost = value.reduce((sum, budget) => sum + budget.lastYearCost, 0);
            const serviceBudget = value.reduce((sum, budget) => sum + budget.costBudget, 0);
            const serviceId = (key === 'null' || !key) ? '' : key;
            return {
                id: value[0].id,
                fiscalYear: value[0].fiscalYear,
                category: "",
                type: "",
                unit: "",
                serviceId: serviceId,
                lastYearCost: lastYearServiceCost,
                costBudget: serviceBudget,
                increaseRate: lastYearServiceCost !== 0 ? toNumber((serviceBudget / lastYearServiceCost * 100 - 100).toFixed(1)) : 0,
                submitter: uniq(value.map(b => b.submitter)).join(', '),
                children: orderBy(
                    values(groupBy(value, b => b.category + b.type + b.unit))
                    .map(sameBudgets => maxBy(sameBudgets, b => b.version?.unix() || 0)),
                    b => b?.lastYearCost, 'desc').filter(b => b) as IFinOpsBudget[],
            }
        }), serviceLevelBudget => serviceLevelBudget.lastYearCost, 'desc');
        console.log('Service level budget retrieved successfully');
        return res;
    } catch (error) {
        console.error('Error fetching service level budget:', error);
        throw new Error('Failed to fetch service level budget. ' + error);
    }
}