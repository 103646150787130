import { LineChartSeries } from "../components/common/LineChart";

export function filterDeviation(seriesSet: Map<string, LineChartSeries[]>, threshold = 1.5): Map<string, LineChartSeries[]> {
    const newMap: Map<string,  LineChartSeries[]> = new Map<string, LineChartSeries[]>();
    for (const [key, value] of seriesSet)
    {
        const trendData = value[0].data || [];
        const medianData = value[1].data || [];
        let maxDeviation = 0;
        for (let i = 0; i < trendData.length; i++)
        {
            const cost = trendData[i]? trendData[i][1] : 0;
            const deviationFrom = medianData[i]? medianData[i][1] : 0;
            const  deviation = deviationFrom != undefined ? deviationFrom == 0 ? 0 : ((cost || 0) - deviationFrom) / deviationFrom : 0;
            if (deviation > maxDeviation)
            {
                maxDeviation = deviation;
            }
        }
        if (maxDeviation >= threshold)
        {
            newMap.set(key, value);
        }
    }
    return newMap;
}