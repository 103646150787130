import { ICosmicDailyMetrics, ICosmicDailyMetricsByWorkload, ICosmicServiceDetailReport, ICosmicServiceDetails } from "../models/CosmicModels";
import { parseCosmicSearchFiltersJson, parseFiltersToJson } from "../models/FilterView";
import { post, postJson } from "../utils/apiServiceBase";

import { CategoryDivision } from "../models/CategoryDivision";
import { Moment } from "moment";
import { QueryableClass } from "../hooks/useCustomQuery";
import { ServiceTreeItem } from "../models/serviceTree";

// cache time: 5 mins
@QueryableClass({ cacheTime: 1800000, retry: 3 })
export class CosmicService {
    static async getTopNCosmicDailyMetricsByWorkload(start: Moment, end: Moment, cosmicSearchFilters: string, filterData: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, n: number, metricType: string) {
        const url = `api/v1.0/cosmic/topNCosmicWorkloadDailyMetric?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}&metricType=${metricType}&n=${n}`;
        const data: ICosmicDailyMetricsByWorkload[] = await postJson(url, {
            cosmicSearchFilters: parseCosmicSearchFiltersJson(cosmicSearchFilters),
            searchFilters: parseFiltersToJson(filterData, serviceIdMap)
        });
        return data;
    }

    static async getCosmicDailyCost(start: Moment, end: Moment, cosmicSearchFilters: string, filterData: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>) {
        const url = `api/v1.0/cosmic/totalCogs?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}`;
        const data: ICosmicDailyMetrics[] = await postJson(url, {
            cosmicSearchFilters: parseCosmicSearchFiltersJson(cosmicSearchFilters),
            searchFilters: parseFiltersToJson(filterData, serviceIdMap)
        });
        return data;
    }

    static async getCosmicServiceDimensionMetric(start: Moment, end: Moment, cosmicSearchFilters: string, globalFilters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, metricType: string) {
        const url = `api/v1.0/cosmic/cosmicServiceDimensionMetric?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}&metricType=${metricType}`;
        return await postJson(url, {
            cosmicSearchFilters: parseCosmicSearchFiltersJson(cosmicSearchFilters),
            searchFilters: parseFiltersToJson(globalFilters, serviceIdMap)
        });
    }

    static async getCosmicWorkloadDailyAppMetric(start: Moment, end: Moment, cosmicSearchFilters: string, globalFilters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, metricType: string) {
        const url = `api/v1.0/cosmic/cosmicWorkloadDailyAppMetric?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}&metricType=${metricType}`;
        return await postJson(url, {
            cosmicSearchFilters: parseCosmicSearchFiltersJson(cosmicSearchFilters),
            searchFilters: parseFiltersToJson(globalFilters, serviceIdMap)
        });
    }

    static async getCosmicServiceDetails(start: Moment, end: Moment, cosmicSearchFilters: string, globalFilters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>, limit?: number, orderBy?: string, desc?: boolean) : Promise<ICosmicServiceDetailReport<ICosmicServiceDetails>>{
        let url = `api/v1.0/cosmic/cosmicServiceDetails?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}`;

        if (limit) url += `&limit=${limit}`;
        if (orderBy) url += `&orderBy=${orderBy}`;
        if (desc) url += `&desc=${desc}`;

        return await postJson(url, {
            cosmicSearchFilters: parseCosmicSearchFiltersJson(cosmicSearchFilters),
            searchFilters: parseFiltersToJson(globalFilters, serviceIdMap)
        });
    }

    static async downloadCosmicServiceDetails(start: Moment, end: Moment, cosmicSearchFilters: string, globalFilters: Partial<Record<CategoryDivision, string[]>>, serviceIdMap: Map<string, ServiceTreeItem>) : Promise<
        {
            data: Blob,
            type: string,
            size: number,
            fileName: string
        }
    >{
        const url = `api/v1.0/cosmic/downloadCosmicServiceDetails?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}`;
        const response = await post(url, {
            cosmicSearchFilters: parseCosmicSearchFiltersJson(cosmicSearchFilters),
            searchFilters: parseFiltersToJson(globalFilters, serviceIdMap)
        });
        return {
            data: await response.blob(),
            type: response.headers.get('content-type') || 'application/octet-stream',
            size: parseInt(response.headers.get('content-length') || '0'),
            fileName: response.headers.get('content-disposition')?.split('filename=')[1] || `cosmic_service_details_${start.format('YYYY-MM-DD')}_${end.format('YYYY-MM-DD')}.xlsx`
        }
    }

    static async getCosmicDailyMetric(start: Moment, end: Moment, metricType: string) {
        const url = `api/v1.0/cosmic/cosmicDailyMetric?startDate=${start.format('YYYY-MM-DD')}&endDate=${end.format('YYYY-MM-DD')}&metricType=${metricType}`;
        return await postJson(url, []);
    }
}
