import * as React from "react";
import { Stack } from "@fluentui/react";
import { outerStackStyles, filterStackStyles } from "../ViewsCommonLayout";
import AzureDetailsFilterBar from "../../Filter/AzureDetailsFilterBar";
import AzureDetailsPage from "./AzureDetailsPage";

export const AzureDetailsContainer: React.FC = () => {
    return (
        <Stack styles={outerStackStyles} disableShrink>
            <Stack horizontal styles={filterStackStyles}>
                <AzureDetailsFilterBar />
            </Stack>
            <AzureDetailsPage />
        </Stack>
    );
};
