import { ActionButton, Separator, Stack } from '@fluentui/react';
import React, { useCallback, useMemo } from 'react';
import { Row } from 'react-table';
import styles from './SubstratePlatformBackendTable.less';

import { ISubstratePlatformBackendServiceIdPivot, ISubstratePlatformBackendTableItem, SubstratePlatformPivotEnum } from '../../../models/SubstratePlatform';
import StickyTable from '../../common/table/StickyTable';
import { useGetSubstratePlatformBackendPivotCostTable } from '../../../hooks/useSubstratePlatformQuery';
import { ReactTableExpandableData, useReactTable } from '../../common/table/TableUtils';
import { SubstratePlatformBackendTableSubRowsPlaceHolder, getSubstratePlatformBackendTableColumns } from './SubstratePlatformBackendTableConfig';
import { trackEventCallback } from '../../../utils/AppInsights';
import { LogComponent, LogElement, LogTarget } from '../../../models/LogModel';
interface ISubstratePlatformBackendCostTableProps {
    pivotBy: SubstratePlatformPivotEnum;
}

const SubstratePlatformBackendTable: React.FC<ISubstratePlatformBackendCostTableProps> = (props) => {
    const { data: query, isLoading } = useGetSubstratePlatformBackendPivotCostTable(props.pivotBy);

    const queryData = useMemo<ISubstratePlatformBackendTableItem[]>(() => {
        if (!query) {
            return [];
        }

        return convertData(query, props.pivotBy);
    }, [props.pivotBy, query]);

    const tableData = useMemo<ReactTableExpandableData<ISubstratePlatformBackendTableItem>[]>(() => {
        if (!queryData) {
            return [];
        }

        const groupedData: Record<string, ISubstratePlatformBackendTableItem> = {};
        queryData.map((cost) => {
            const serviceId = cost.serviceId;
            if (!groupedData[serviceId]) {
                groupedData[serviceId] = { ...cost };
            } else {
                groupedData[serviceId].pivotByItem += ',' + (cost.pivotByItem || "Others");
                groupedData[serviceId].totalProcessCpuCores = (groupedData[serviceId].totalProcessCpuCores || 0) + (cost.totalProcessCpuCores || 0);
                groupedData[serviceId].totalStoreCpuCores = (groupedData[serviceId].totalStoreCpuCores || 0) + (cost.totalStoreCpuCores || 0);
                groupedData[serviceId].totalRestCpuCores = (groupedData[serviceId].totalRestCpuCores || 0) + (cost.totalRestCpuCores || 0);
                groupedData[serviceId].totalMemoryGb = (groupedData[serviceId].totalMemoryGb || 0) + (cost.totalMemoryGb || 0);
                groupedData[serviceId].totalIOCount = (groupedData[serviceId].totalIOCount || 0) + (cost.totalIOCount || 0);
                groupedData[serviceId].totalHddStorageGb = (groupedData[serviceId].totalHddStorageGb || 0) + (cost.totalHddStorageGb || 0);
                groupedData[serviceId].totalSsdStorageGb = (groupedData[serviceId].totalSsdStorageGb || 0) + (cost.totalSsdStorageGb || 0);
                groupedData[serviceId].totalProcessCpuCost = (groupedData[serviceId].totalProcessCpuCost || 0) + (cost.totalProcessCpuCost || 0);
                groupedData[serviceId].totalStoreCpuCost = (groupedData[serviceId].totalStoreCpuCost || 0) + (cost.totalStoreCpuCost || 0);
                groupedData[serviceId].totalRestCpuCost = (groupedData[serviceId].totalRestCpuCost || 0) + (cost.totalRestCpuCost || 0);
                groupedData[serviceId].totalMemoryCost = (groupedData[serviceId].totalMemoryCost || 0) + (cost.totalMemoryCost || 0);
                groupedData[serviceId].totalIOCost = (groupedData[serviceId].totalIOCost || 0) + (cost.totalIOCost || 0);
                groupedData[serviceId].totalHddCost = (groupedData[serviceId].totalHddCost || 0) + (cost.totalHddCost || 0);
                groupedData[serviceId].totalSsdCost = (groupedData[serviceId].totalSsdCost || 0) + (cost.totalSsdCost || 0);
                groupedData[serviceId].totalNetworkT0Cost += (cost.totalNetworkT0Cost || 0);
                groupedData[serviceId].totalNetworkT2Cost += (cost.totalNetworkT2Cost || 0);
                groupedData[serviceId].totalCost += cost.totalCost;
            }
        });

        const result: ReactTableExpandableData<ISubstratePlatformBackendTableItem>[] = [];
        Object.values(groupedData).map((group) => {
            result.push({ data: group, depth: 0 });
        });

        return result;
    }, [queryData]);


    const columns = useMemo(
        () =>
            getSubstratePlatformBackendTableColumns(
                queryData,
                props.pivotBy,
                tableData.length,
            ),
        [props.pivotBy, queryData, tableData.length]
    );

    const getSubRows = useMemo(
        () => (originalRow: ReactTableExpandableData<ISubstratePlatformBackendTableItem>): ReactTableExpandableData<ISubstratePlatformBackendTableItem>[] => {
            if (originalRow.depth !== 0 || props.pivotBy === SubstratePlatformPivotEnum.Resource || props.pivotBy === SubstratePlatformPivotEnum.Service) {
                return [];
            }

            let subRowsData: ISubstratePlatformBackendTableItem[] | undefined = [];

            subRowsData = queryData.filter((item) => item.serviceId === originalRow.data.serviceId);

            if (subRowsData.length) {
                return subRowsData.map((cost) => ({ data: cost, parent: originalRow, depth: 1 }));
            } else {
                return SubstratePlatformBackendTableSubRowsPlaceHolder;
            }
        },
        [props.pivotBy, queryData]
    );

    const getRowId = useCallback(
        (
            { data: { serviceId, pivotBy, pivotByItem }, isSubRowPlaceHolder }: ReactTableExpandableData<ISubstratePlatformBackendTableItem>,
            relativeIndex: number,
            parent?: Row<ReactTableExpandableData<ISubstratePlatformBackendTableItem>>
        ) => {
            if (!serviceId && !parent) {
                return relativeIndex.toString();
            }

            return `${parent?.id}_${serviceId}_${pivotBy}_${pivotByItem}_${!!isSubRowPlaceHolder}`;
        },
        []
    );

    const tableInstance = useReactTable<ReactTableExpandableData<ISubstratePlatformBackendTableItem>>({
        initialState: {
            sortBy: [
                {
                    id: 'totalCost',
                    desc: true,
                },
            ],
        },
        data: isLoading ? [] : tableData,
        columns,
        getSubRows,
        getRowId,
    });

    const handleDownload = async () => {
        if (!queryData.length) return;

        trackEventCallback(LogComponent.PCMPlatformBackend, LogElement.DownloadBackendTable, "Downloard", LogTarget.Button);

        const header = Object.keys(queryData[0]).join(',');
        const rows = sortQueryData(queryData).map(obj => Object.values(obj).join(',')).join('\n');
        const csvContent =  header + '\n' + rows;

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `PCMPlatformBackendTable_PivotBy_${props.pivotBy}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div>
            <Separator styles={{ root: styles.separator }} />
            <Stack horizontal verticalAlign="center">
                <ActionButton
                    iconProps={{ iconName: "Download" }}
                    onClick={handleDownload}
                    style={{ marginRight: 'auto' }}
                >
                    Download
                </ActionButton>
            </Stack>
            <StickyTable
                styles={{
                    container: styles.tableContainer,
                }}
                key={props.pivotBy}
                loading={isLoading}
                loadMore={false}
                emptyText="Selected services don't have data"
                expandable
                table={tableInstance}
                stickyPositon={{ header: { offsetTop: -40 }, footer: { offsetBottom: 0 } }}
            />
        </div>
    );
};

function convertData(query: ISubstratePlatformBackendServiceIdPivot[], pivotBy: SubstratePlatformPivotEnum): ISubstratePlatformBackendTableItem[] {
    const newData: ISubstratePlatformBackendTableItem[] = [];

    query.forEach(item => {
        let totalCost = 0;
        let totalProcessCpuCores = 0;
        let totalStoreCpuCores = 0;
        let totalRestCpuCores = 0;
        let totalMemoryGb = 0;
        let totalIOCount = 0;
        let totalHddStorageGb = 0;
        let totalSsdStorageGb = 0;
        let totalNetworkT0GB = 0;
        let totalNetworkT2GB = 0;
        let totalProcessCpuCost = 0;
        let totalStoreCpuCost = 0;
        let totalRestCpuCost = 0;
        let totalMemoryCost = 0;
        let totalIOCost = 0;
        let totalHddCost = 0;
        let totalSsdCost = 0;
        let totalNetworkT0Cost = 0;
        let totalNetworkT2Cost = 0;
        switch (pivotBy) {
            case SubstratePlatformPivotEnum.Service:
            case SubstratePlatformPivotEnum.Resource:
                totalCost = (item.totalProcessCpuCost ?? 0) + (item.totalStoreCpuCost ?? 0) + (item.totalRestCpuCost ?? 0) + (item.totalMemoryCost ?? 0) + (item.totalIOCost ?? 0) + (item.totalHddCost ?? 0) + (item.totalSsdCost ?? 0) + (item.totalNetworkT0Cost ?? 0) + (item.totalNetworkT2Cost ?? 0);
                totalProcessCpuCores = item.totalProcessCpuCores ?? 0;
                totalStoreCpuCores = item.totalStoreCpuCores ?? 0;
                totalRestCpuCores = item.totalRestCpuCores ?? 0;
                totalMemoryGb = item.totalMemoryGb ?? 0;
                totalIOCount = item.totalIOCount ?? 0;
                totalHddStorageGb = item.totalHddStorageGb ?? 0;
                totalSsdStorageGb = item.totalSsdStorageGb ?? 0;
                totalNetworkT0GB = item.totalNetworkT0Gb ?? 0;
                totalNetworkT2GB = item.totalNetworkT2Gb ?? 0;
                totalProcessCpuCost = item.totalProcessCpuCost ?? 0;
                totalStoreCpuCost = item.totalStoreCpuCost ?? 0;
                totalRestCpuCost = item.totalRestCpuCost ?? 0;
                totalMemoryCost = item.totalMemoryCost ?? 0;
                totalIOCost = item.totalIOCost ?? 0;
                totalHddCost = item.totalHddCost ?? 0;
                totalSsdCost = item.totalSsdCost ?? 0;
                totalNetworkT0Cost = item.totalNetworkT0Cost ?? 0;
                totalNetworkT2Cost = item.totalNetworkT2Cost ?? 0;
                break;
            case SubstratePlatformPivotEnum.Ring:
                totalCost = (item.totalProcessCpuCost ?? 0) + (item.totalStoreCpuCost ?? 0) + (item.totalRestCpuCost ?? 0) + (item.totalMemoryCost ?? 0) + (item.totalIOCost ?? 0) + (item.totalHddCost ?? 0) + (item.totalSsdCost ?? 0);
                totalProcessCpuCores = item.totalProcessCpuCores ?? 0;
                totalStoreCpuCores = item.totalStoreCpuCores ?? 0;
                totalRestCpuCores = item.totalRestCpuCores ?? 0;
                totalMemoryGb = item.totalMemoryGb ?? 0;
                totalIOCount = item.totalIOCount ?? 0;
                totalHddStorageGb = item.totalHddStorageGb ?? 0;
                totalSsdStorageGb = item.totalSsdStorageGb ?? 0;
                totalProcessCpuCost = item.totalProcessCpuCost ?? 0;
                totalStoreCpuCost = item.totalStoreCpuCost ?? 0;
                totalRestCpuCost = item.totalRestCpuCost ?? 0;
                totalMemoryCost = item.totalMemoryCost ?? 0;
                totalIOCost = item.totalIOCost ?? 0;
                totalHddCost = item.totalHddCost ?? 0;
                totalSsdCost = item.totalSsdCost ?? 0;
                break;
            case SubstratePlatformPivotEnum.ProcessName:
                totalCost = (item.totalProcessCpuCost ?? 0) + (item.totalStoreCpuCost ?? 0) + (item.totalRestCpuCost ?? 0) + (item.totalMemoryCost ?? 0) + (item.totalNetworkT0Cost ?? 0) + (item.totalNetworkT2Cost ?? 0);
                totalProcessCpuCores = item.totalProcessCpuCores ?? 0;
                totalStoreCpuCores = item.totalStoreCpuCores ?? 0;
                totalRestCpuCores = item.totalRestCpuCores ?? 0;
                totalMemoryGb = item.totalMemoryGb ?? 0;
                totalNetworkT0GB = item.totalNetworkT0Gb ?? 0;
                totalNetworkT2GB = item.totalNetworkT2Gb ?? 0;
                totalProcessCpuCost = item.totalProcessCpuCost ?? 0;
                totalStoreCpuCost = item.totalStoreCpuCost ?? 0;
                totalRestCpuCost = item.totalRestCpuCost ?? 0;
                totalMemoryCost = item.totalMemoryCost ?? 0;
                totalNetworkT0Cost = item.totalNetworkT0Cost ?? 0;
                totalNetworkT2Cost = item.totalNetworkT2Cost ?? 0;
                break;
            case SubstratePlatformPivotEnum.AppName:
            case SubstratePlatformPivotEnum.SubApp:
                totalCost = (item.totalRestCpuCost ?? 0) + (item.totalIOCost ?? 0);
                totalRestCpuCores = item.totalRestCpuCores ?? 0;
                totalIOCount = item.totalIOCount ?? 0;
                totalRestCpuCost = item.totalRestCpuCost ?? 0;
                totalIOCost = item.totalIOCost ?? 0;
                break;
            case SubstratePlatformPivotEnum.Client:
            case SubstratePlatformPivotEnum.ClientComponent:
                totalCost = (item.totalStoreCpuCost ?? 0) + (item.totalIOCost ?? 0);
                totalStoreCpuCores = item.totalStoreCpuCores ?? 0;
                totalIOCount = item.totalIOCount ?? 0;
                totalStoreCpuCost = item.totalStoreCpuCost ?? 0;
                totalIOCost = item.totalIOCost ?? 0;
                break;
        }

        const newItem: ISubstratePlatformBackendTableItem = {
            serviceId: item.serviceId,
            pivotBy: item.pivotBy,
            pivotByItem: item.pivotByItem || "Others",
            totalCost: totalCost,
            totalProcessCpuCores: totalProcessCpuCores,
            totalStoreCpuCores: totalStoreCpuCores,
            totalRestCpuCores: totalRestCpuCores,
            totalMemoryGb: totalMemoryGb,
            totalIOCount: totalIOCount,
            totalHddStorageGb: totalHddStorageGb,
            totalSsdStorageGb: totalSsdStorageGb,
            totalNetworkT0Gb: totalNetworkT0GB,
            totalNetworkT2Gb: totalNetworkT2GB,
            totalProcessCpuCost: totalProcessCpuCost,
            totalStoreCpuCost: totalStoreCpuCost,
            totalRestCpuCost: totalRestCpuCost,
            totalMemoryCost: totalMemoryCost,
            totalIOCost: totalIOCost,
            totalHddCost: totalHddCost,
            totalSsdCost: totalSsdCost,
            totalNetworkT0Cost: totalNetworkT0Cost,
            totalNetworkT2Cost: totalNetworkT2Cost,
        };
        newData.push(newItem);
    });

    return newData;
}

const sortQueryData = (data: ISubstratePlatformBackendTableItem[]) => {
    return data.sort((a, b) => {
        if (a.serviceId !== b.serviceId) {
            return a.serviceId.localeCompare(b.serviceId);
        } else {
            return a.pivotByItem.localeCompare(b.pivotByItem);
        }
    });
};

export default SubstratePlatformBackendTable;
