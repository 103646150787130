import { FiltersAction, FiltersView } from "../../../../reducers/filterReducer";
import { Pages, SubPages } from "../../../../models/Nav";
import { PcmV2AggeratedLevel, PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum, SubstrateCostV2 } from "../../../../models/PcmV2Models";

import React from "react";
import commonStyles from "../../../common/styles/Common.less";
import { useCategoryFilters } from "../../../../hooks/useFilters";
import { useGotoTab } from "../../../../hooks/useGotoPage";
import { trackEventCallback } from "../../../../utils/AppInsights";
import { LogComponent, LogElement, LogTarget } from "../../../../models/LogModel";

interface ISubstrateCostTableScenarioTagCellProps {
    value: number;
    cost: SubstrateCostV2;
    scene: PcmV2SceneTypeEnum;
    subScene: PcmV2SubSceneTypeEnum;
}

export const SubstrateCostTableScenarioTagCell: React.FC<ISubstrateCostTableScenarioTagCellProps> = ({ value, cost, scene }) => {
    const gotoTab = useGotoTab();
    const updateFilters = useCategoryFilters().updateFilters;

    const handleClick = () => {
        if (!value) return;

        let searchParams: URLSearchParams | undefined = undefined;

        if (cost.identity.aggregatedLevel === PcmV2AggeratedLevel.Service && cost.identity.identity) {
            searchParams = updateFilters(FiltersAction.Replace, { filters: { Service: [cost.identity.identity] }, view: FiltersView.AddableList });
        } else if (cost.identity.aggregatedLevel === PcmV2AggeratedLevel.Workload && cost.identity.workload) {
            searchParams = updateFilters(FiltersAction.Replace, {
                filters: { GriffinAppV2: [`${cost.identity.workload}(${cost.identity.identity})`] },
                view: FiltersView.AddableList,
            });
        }

        if (scene === PcmV2SceneTypeEnum.Network) {
            gotoTab(
                `${Pages.SubstrateV2}/${SubPages.Network}`,
                "ScenarioTag",
                `${Pages.SubstrateV2}/${SubPages.Network}`,
                searchParams && `?${searchParams?.toString()}`
            );
            trackEventCallback(LogComponent.NetworkChartingPane, LogElement.TableScenarioTagsNetwork, "Scenario Tag", LogTarget.Link);
        } else if (scene === PcmV2SceneTypeEnum.Transaction) {
            gotoTab(
                `${Pages.SubstrateV2}/${SubPages.Transaction}`,
                "ScenarioTag",
                `${Pages.SubstrateV2}/${SubPages.Transaction}`,
                searchParams && `?${searchParams?.toString()}`
            );
            trackEventCallback(LogComponent.NetworkChartingPane, LogElement.TableScenarioTagsTrans, "Scenario Tag", LogTarget.Link);
        }
    };

    return (
        <div className={value > 0 ? commonStyles.link : ""} onClick={handleClick} title={value > 0 ? "View the service's or griffin app's tags" : ""}>
            {value} Tag{value > 1 ? "s" : ""}
        </div>
    );
};
