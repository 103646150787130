import { Moment } from "moment";

export enum PcmV2AggeratedLevel {
    Service = "ServiceLevel",
    Workload = "WorkloadLevel",
    Organization = "OrganizationLevel",
    Division = "DivisionLevel",
    Team = "TeamLevel",
    Group = "ServiceGroupLevel",
}

export enum PcmV2WorkloadType {
    Process = "Process",
    Vdir = "Vdir",
    GriffinApplication = "GriffinApplication",
    GriffinProcessor = "GriffinProcessor",
    StoreClient = "StoreClient",
    StoreClientComponent = "StoreClientComponent",
    DataSetName = "DataSetName",
    ScenarioTag = "ScenarioTag",
}

export interface PcmV2Identity {
    identity: string;
    aggregatedLevel?: PcmV2AggeratedLevel;
    ring: string;
    workload: string;
    workloadType?: PcmV2WorkloadType;
}

export interface PcmV2Cost {
    cost: number;
    transactionCost: number;
    processHostingCost: number;
    processHostingCpuCost: number;
    processHostingMemoryCost: number;
    networkCost: number;
    hddCost: number;
    ssdCost: number;
    ssdmcdbCost: number;
    ssdKvCacheCost: number;
    itemReadCost: number;
    itemWriteCost: number;
    itemQueryCost: number;
    itemReadPeakUsage: number;
    itemWritePeakUsage: number;
    itemQueryPeakUsage: number;
    itemReadOffPeakUsage: number;
    itemWriteOffPeakUsage: number;
    itemQueryOffPeakUsage: number;
    itemReadCount: number;
    itemWriteCount: number;
    itemQueryCount: number;
    offPeakItemQueriesCount: number;
    offPeakItemReadsCount: number;
    offPeakItemWritesCount: number;
    peakHourItemQueriesCount: number;
    peakHourItemReadsCount: number;
    peakHourItemWritesCount: number;
    ssdmcdbUsage: number;
    ssdKvCacheUsage: number;
    logBytesCost: number;
    bytesReturnedCost: number;
    peakLogBytes: number;
    peakBytesReturned: number;
    offPeakLogBytes: number;
    offPeakBytesReturned: number;
    processHostingUsage: number;
    dailyCpuCores: number;
    dailyMemoryMB: number;
    hddUsage: number;
    startDate: Moment;
    endDate: Moment;
    extractionDate: Moment;
    identity: PcmV2Identity;
}

export type PcmV2Response =
    | PcmV2Cost
    | {
          startDate: string;
          endDate: string;
          extractionDate: string;
      };

export enum PcmV2SceneTypeEnum {
    Overview = "SubstrateOverview",
    Transaction = "SubstrateTransaction",
    ProcessHosting = "SubstrateProcessHosting",
    Network = "SubstrateNetwork",
    SSD = "SubstrateSSDStorage",
    HDD = "SubstrateHDDStorage",
}

export enum PcmV2SubSceneTypeEnum {
    TransactionCost = "TransactionCost",
    TransactionScenarioTagCost = "TransactionScenarioTagCost",
    NetworkCost = "NetworkCost",
    NetworkScenarioTagCost = "NetworkScenarioTagCost",
    HDDMailboxCost = "HDDMailboxCost",
    HDDDatasetCost = "HDDDatasetCost",
    SSDTotalCost = "SSDTotalCost",
    SSDDatasetCost = "SSDDatasetCost",
    SSDMailboxCost = "SSDMailboxCost",
}

export type PcmV2Keys = keyof Partial<PcmV2Cost>;

export type PcmV2MetricKeys = TypedKeyOf<PcmV2Cost, number | undefined>;

export interface PCMv2UnitPrice {
    peakTRU: number;
    offPeakTRU: number;
    ssdStorage: number;
    cpuCores: number;
    memoryMB: number;
    hddStorage: number;
    peakNetworking: number;
    offPeakNetworking: number;
    version: string;
    id: number;
    pru: number;
}

//// Substrate Carbon
export interface SubstrateCarbonEmissionData {
    appName: string;
    substrateSource: string;
    totalCarbonEmission: number;
    carbonScope2Emission: number;
    carbonScope3Emission: number;
}

export type SubstrateCarbonReponse = Record<string, SubstrateCarbonEmissionData[]>;

//// Pcm V2 Execution Framework
export enum PcmV2ResponseType {
    Entity,
    List,
    Dictionary,
}

//// Substrate V2 Cost (PCMV2 + Carbon)
export interface SubstrateCarbonCost {
    totalCarbonEmission?: number;
    carbonScope2Emission?: number;
    carbonScope3Emission?: number;
}

export interface SubstrateCostV2 extends PcmV2Cost, SubstrateCarbonCost {
    //
}
