import { ComponentItem, ServiceTreeItem } from "../../../models/serviceTree";
import LineChart, { LineChartSeries } from "../../common/LineChart";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { PcmV2AggeratedLevel, PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum } from "../../../models/PcmV2Models";
import React, { useMemo } from "react";
import { map, sum } from "lodash";

import ChartContainer from "../../chart/ChartContainer";
import CommonConstants from "../../../models/constants/CommonConstants";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { IAppState } from "../../../store";
import { PcmV2SceneMetrics, SubstrateLogComponent } from "../../../models/constants/PcmV2Constants";
import { SubstrateUtils } from "../utils/SubstrateUtils";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useGetPcmV2TopCostTrends } from "../../../hooks/useSubstrateV2Query";
import { useSelector } from "react-redux";
import { useTrackHovering } from "../../../hooks/useTrack";

interface ISubstrateTopFiveProps {
    scene: PcmV2SceneTypeEnum;
    subScene?: PcmV2SubSceneTypeEnum;
}

const SubstrateTopFiveServiceTrendsTitle: Record<string, string> = {
    [PcmV2SceneTypeEnum.Overview]: "Top 5 Services of Substrate and Carbon Cost",
    [PcmV2SceneTypeEnum.Transaction]: "Top 5 Services of Transaction Cost Trends",
    [PcmV2SceneTypeEnum.Network]: "Top 5 Services of Network Cost Trends",
    [PcmV2SceneTypeEnum.ProcessHosting]: "Top 5 Services of Process Hosting Cost Trends",
    [PcmV2SceneTypeEnum.SSD]: "Top 5 Services of SSD Cost Trends",
    [PcmV2SceneTypeEnum.HDD]: "Top 5 Services of HDD Cost Trends",
};

const SubstrateTopFiveWorkloadTrendsTitle: Record<string, string> = {
    [PcmV2SceneTypeEnum.ProcessHosting]: "Process Hosting Cost Trends (Show up to TOP5)",
    [PcmV2SceneTypeEnum.HDD]: "HDD Cost Trends (Show up to TOP5)",
    [PcmV2SceneTypeEnum.SSD]: "SSD Cost Trends (Show up to TOP5)",
    [PcmV2SubSceneTypeEnum.TransactionCost]: "Top 5 Griffin App/Store Client Cost Trends",
    [PcmV2SubSceneTypeEnum.TransactionScenarioTagCost]: 'TOP 5 "Griffin Apps - Scenario Tags" Cost Trends',
    [PcmV2SubSceneTypeEnum.NetworkCost]: "Top 5 Griffin App/Store Client Cost Trends",
    [PcmV2SubSceneTypeEnum.NetworkScenarioTagCost]: 'TOP 5 "Griffin Apps - Scenario Tags" Cost Trends',
};

export const SubstrateTopFiveTrends: React.FC<ISubstrateTopFiveProps> = (props) => {
    const categoryFilter = useCategoryFilters().filters;
    const aggeratedLevel = useMemo(() => SubstrateUtils.getAggeratedLevelByCategoryFilters(categoryFilter), [categoryFilter]);
    const query = useGetPcmV2TopCostTrends(props.scene, props.subScene, aggeratedLevel);
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.SubstrateV2);
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);
    const trackingProps = useTrackHovering(LogComponent.TransactionV2, LogElement.Top5Services, "Cost Trends", LogTarget.Chart);

    const series: LineChartSeries[] = useMemo(() => {
        if (!query.data) {
            return [];
        }

        return Object.keys(query.data)
            .sort((serviceA, serviceB) => sum(query.data[serviceB].map((item) => item.cost)) - sum(query.data[serviceA].map((item) => item.cost)))
            .slice(0, 5)
            .map((id, index) => {
                let name;
                if (aggeratedLevel == PcmV2AggeratedLevel.Service) {
                    name = serviceIdMap.get(id)?.n;
                } else {
                    name = id;

                    if (props.subScene == PcmV2SubSceneTypeEnum.TransactionScenarioTagCost || props.subScene === PcmV2SubSceneTypeEnum.NetworkScenarioTagCost) {
                        name = name?.substring(name.indexOf(".") + 1);
                    }
                }

                return {
                    name: name || id,
                    data: query.data[id]?.map((item) => [item.extractionDate.valueOf(), item.cost]),
                    color: CommonConstants.DefaultColors[index],
                };
            });
    }, [query.data, serviceIdMap]);

    return (
        <ChartContainer
            loading={query.isLoading}
            headerProps={{
                title:
                    aggeratedLevel == PcmV2AggeratedLevel.Service
                        ? SubstrateTopFiveServiceTrendsTitle[props.scene]
                        : SubstrateTopFiveWorkloadTrendsTitle[props.subScene || props.scene],
            }}
            chartType="line"
            chartProps={{
                height: 240,
                series,
                hideEmptySeries: true,
                anomalies: getActiveAnomalies(anomaliesQuery.data, PcmV2SceneMetrics[props.scene]),
                containerProps: trackingProps,
            }}
            logProps={{
                logComponent: SubstrateLogComponent[props.scene],
                logElement: LogElement.Top5Services,
            }}
        />
    );
};
