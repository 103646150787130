import * as React from "react";

import {
    CellValue,
    Column,
    Row,
    TableInstance,
    TableOptions,
    UseExpandedInstanceProps,
    UseExpandedRowProps,
    UseSortByOptions,
    useExpanded,
    useSortBy,
    useTable,
} from "react-table";
import { FontIcon, IconButton, Link, OverflowSet, Separator } from "@fluentui/react";
import { ServiceTreeItem } from "../../../models/serviceTree";
import { sum, uniq } from "lodash";
import { useDispatch, useSelector } from "react-redux";


import { IAzureBudgetDetails } from "../../../models/AzureComputeModels";
import StickyTable from "../../common/table/StickyTable";

import { IFilters } from "../../../models/FilterView";
import { FiltersAction, FiltersView } from "../../../reducers/filterReducer";
import { getCategoryByServiceTreeLevel } from "../../../reducers/serviceTreeReducer";
import { IAppState } from "../../../store";
import { formatNumber } from "../../../utils/currency";
import styles from "../Azure.less";
import tableStyles from "./BudgetTable.less";

enum RowType {
    Summary,
    Budget,
    SingleServiceGroupCost,
    TotalCost,
    SingleCost,
    Variance
}

interface ITableRow {
    type: RowType;
    product?: string; //RowType: Summary, Budget
    organizations?: string[]; // RowType: Summary
    serviceGroups?: string[]; // RowType: Summary
    serviceGroup?: string; // RowType: SingleServiceGroupCost
    subRows?: ITableRow[]; // RowType: Summary
    
    // RowType: Budget, SingleServiceGroupCost, TotalCost, SingleCost, Variance
    total?: number;
    july?: number;
    august?: number;
    september?: number;
    october?: number;
    november?: number;
    december?: number;
    january?: number;
    february?: number;
    march?: number;
    april?: number;
    may?: number;
    june?: number;
}

interface IBudgetTableProps {
    type?: "Public" | "AZSC";
    isBudgetDetailsLoading: boolean;
    budgetDetails?: IAzureBudgetDetails[];
    fiscalYear: number;
}

export const BudgetTable: React.FC<IBudgetTableProps> = (props) => {
    const dispatch = useDispatch();
    const serviceNodeByName = useSelector<IAppState, Map<string, ServiceTreeItem>>(state => state.serviceTree.serviceNodeMapByName);
    const { isBudgetDetailsLoading, budgetDetails } = props;
    const calcTotal: (publicPart: number, azscPart: number) => number = React.useMemo(() => {
        switch (props.type) {
            case "Public":
                return (publicPart: number) => publicPart;
            case "AZSC":
                return (publicPart: number, azscPart: number) => azscPart;
            default:
                return (publicPart: number, azscPart: number) => publicPart + azscPart;
        }
    }, [props.type]);

    const data: ITableRow[] = React.useMemo(() => {
        if (isBudgetDetailsLoading || !budgetDetails) {
            return [];
        }

        return budgetDetails.map<ITableRow>(budgetPerProduct => {
            const budgetOverview = {
                type: RowType.Budget,
                product: budgetPerProduct.product,
                total: calcTotal(sum(budgetPerProduct.publicBudgets), sum(budgetPerProduct.azscBudgets)),
                july: calcTotal(budgetPerProduct.publicBudgets[0], budgetPerProduct.azscBudgets[0]),
                august: calcTotal(budgetPerProduct.publicBudgets[1], budgetPerProduct.azscBudgets[1]),
                september: calcTotal(budgetPerProduct.publicBudgets[2], budgetPerProduct.azscBudgets[2]),
                october: calcTotal(budgetPerProduct.publicBudgets[3], budgetPerProduct.azscBudgets[3]),
                november: calcTotal(budgetPerProduct.publicBudgets[4], budgetPerProduct.azscBudgets[4]),
                december: calcTotal(budgetPerProduct.publicBudgets[5], budgetPerProduct.azscBudgets[5]),
                january: calcTotal(budgetPerProduct.publicBudgets[6], budgetPerProduct.azscBudgets[6]),
                february: calcTotal(budgetPerProduct.publicBudgets[7], budgetPerProduct.azscBudgets[7]),
                march: calcTotal(budgetPerProduct.publicBudgets[8], budgetPerProduct.azscBudgets[8]),
                april: calcTotal(budgetPerProduct.publicBudgets[9], budgetPerProduct.azscBudgets[9]),
                may: calcTotal(budgetPerProduct.publicBudgets[10], budgetPerProduct.azscBudgets[10]),
                june: calcTotal(budgetPerProduct.publicBudgets[11], budgetPerProduct.azscBudgets[11])
            };

            const costOverview = {
                type: budgetPerProduct.serviceGroupCosts.length === 1 ? RowType.SingleServiceGroupCost : RowType.TotalCost,
                serviceGroup: budgetPerProduct.serviceGroupCosts[0]?.serviceGroupName || "No mapped service group",
                total: sum(budgetPerProduct.serviceGroupCosts.map(cost => calcTotal(sum(cost.publicCosts), sum(cost.azscCosts)))),
                july: sum(budgetPerProduct.serviceGroupCosts.map(cost => calcTotal(cost.publicCosts[0], cost.azscCosts[0]))),
                august: sum(budgetPerProduct.serviceGroupCosts.map(cost => calcTotal(cost.publicCosts[1], cost.azscCosts[1]))),
                september: sum(budgetPerProduct.serviceGroupCosts.map(cost => calcTotal(cost.publicCosts[2], cost.azscCosts[2]))),
                october: sum(budgetPerProduct.serviceGroupCosts.map(cost => calcTotal(cost.publicCosts[3], cost.azscCosts[3]))),
                november: sum(budgetPerProduct.serviceGroupCosts.map(cost => calcTotal(cost.publicCosts[4], cost.azscCosts[4]))),
                december: sum(budgetPerProduct.serviceGroupCosts.map(cost => calcTotal(cost.publicCosts[5], cost.azscCosts[5]))),
                january: sum(budgetPerProduct.serviceGroupCosts.map(cost => calcTotal(cost.publicCosts[6], cost.azscCosts[6]))),
                february: sum(budgetPerProduct.serviceGroupCosts.map(cost => calcTotal(cost.publicCosts[7], cost.azscCosts[7]))),
                march: sum(budgetPerProduct.serviceGroupCosts.map(cost => calcTotal(cost.publicCosts[8], cost.azscCosts[8]))),
                april: sum(budgetPerProduct.serviceGroupCosts.map(cost => calcTotal(cost.publicCosts[9], cost.azscCosts[9]))),
                may: sum(budgetPerProduct.serviceGroupCosts.map(cost => calcTotal(cost.publicCosts[10], cost.azscCosts[10]))),
                june: sum(budgetPerProduct.serviceGroupCosts.map(cost => calcTotal(cost.publicCosts[11], cost.azscCosts[11])))
            };

            const varianceRow = {
                type: RowType.Variance,
                total: budgetOverview.total - costOverview.total,
                july: budgetOverview.july - costOverview.july,
                august: budgetOverview.august - costOverview.august,
                september: budgetOverview.september - costOverview.september,
                october: budgetOverview.october - costOverview.october,
                november: budgetOverview.november - costOverview.november,
                december: budgetOverview.december - costOverview.december,
                january: budgetOverview.january - costOverview.january,
                february: budgetOverview.february - costOverview.february,
                march: budgetOverview.march - costOverview.march,
                april: budgetOverview.april - costOverview.april,
                may: budgetOverview.may - costOverview.may,
                june: budgetOverview.june - costOverview.june
            };

            return {
                type: RowType.Summary,
                product: budgetPerProduct.product,
                serviceGroups: budgetPerProduct.serviceGroupCosts.map(i => i.serviceGroupName),
                organizations: uniq(budgetPerProduct.serviceGroupCosts.map(i => i.organizationName)),
                subRows: [
                    budgetOverview,
                    costOverview,
                    ...(budgetPerProduct.serviceGroupCosts.length === 1 ? [] : budgetPerProduct.serviceGroupCosts.map(cost => ({
                        type: RowType.SingleCost,
                        serviceGroup: cost.serviceGroupName,
                        total: calcTotal(sum(cost.publicCosts), sum(cost.azscCosts)),
                        july: calcTotal(cost.publicCosts[0], cost.azscCosts[0]),
                        august: calcTotal(cost.publicCosts[1], cost.azscCosts[1]),
                        september: calcTotal(cost.publicCosts[2], cost.azscCosts[2]),
                        october: calcTotal(cost.publicCosts[3], cost.azscCosts[3]),
                        november: calcTotal(cost.publicCosts[4], cost.azscCosts[4]),
                        december: calcTotal(cost.publicCosts[5], cost.azscCosts[5]),
                        january: calcTotal(cost.publicCosts[6], cost.azscCosts[6]),
                        february: calcTotal(cost.publicCosts[7], cost.azscCosts[7]),
                        march: calcTotal(cost.publicCosts[8], cost.azscCosts[8]),
                        april: calcTotal(cost.publicCosts[9], cost.azscCosts[9]),
                        may: calcTotal(cost.publicCosts[10], cost.azscCosts[10]),
                        june: calcTotal(cost.publicCosts[11], cost.azscCosts[11])
                    }))),
                    varianceRow
                ]
            }});
    }, [budgetDetails, calcTotal, isBudgetDetailsLoading]);
    const columns = React.useMemo(() => getColumns(props.fiscalYear), [props.fiscalYear]);

    const tableInstance = useTable({
        data,
        columns,
        disableSortBy: true
    } as (TableOptions<ITableRow> & UseSortByOptions<ITableRow>),
    useSortBy,
    useExpanded) as (TableInstance<ITableRow> & UseExpandedInstanceProps<ITableRow>);

    React.useEffect(() => {
        tableInstance.toggleAllRowsExpanded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const onRenderServiceNode = React.useCallback((nodes?: string[]): JSX.Element => (
        <OverflowSet
            items={nodes?.slice(0, 5).map(node => ({key: node, name: node, onClick: () => onClickServiceTreeNode(node, dispatch, serviceNodeByName)}))}
            overflowItems={nodes?.slice(5).map(node => ({key: node, name: node, onClick: () => onClickServiceTreeNode(node, dispatch, serviceNodeByName)}))}
            onRenderItem={(item) => (<Link styles={{root: {marginRight: 10}}} onClick={item.onClick} underline>{item.name}</Link>)}
            onRenderOverflowButton={(overflowItems) => (<IconButton menuIconProps={{ iconName: "More" }} menuProps={{items: overflowItems!}} />)}
        />
    ), [dispatch, serviceNodeByName]);

    const rowRenderer = React.useCallback((row: Row<ITableRow> & UseExpandedRowProps<ITableRow>): React.ReactNode => {
        if (row.original.type === RowType.Summary) {
            return (
                <td colSpan={14}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        {
                            row.canExpand &&
                                <FontIcon
                                    iconName={row.isExpanded ? "ChevronDown" : "ChevronRight"}
                                    onClick={function() {
                                        row.toggleRowExpanded();
                                    }}
                                    className={tableStyles.chevronIcon}
                                />
                        }
                        Workload: {row.original.product}
                        <span style={{margin: "0 10px 0 20px"}}>Organization:</span>{onRenderServiceNode(row.original.organizations)}
                        <span style={{margin: "0 10px 0 20px"}}>Service Group:</span>{onRenderServiceNode(row.original.serviceGroups)}
                    </div>
                </td>
            )
        } else {
            return row.cells.map((cell) => (
                // eslint-disable-next-line react/jsx-key
                <td {...cell.getCellProps()} align={cell.column.id === "Name" ? "left" : "right"} className={tableStyles.tableCell}>{cell.render('Cell')}</td>
            ));
        }
    }, [onRenderServiceNode]);
    
    return (
        <>
        <Separator styles={{root: styles.separator}} />
        <h4 className={styles.title}>Workload Budget vs Cost</h4>
            <StickyTable
                table={tableInstance}
                stickyPositon={{ header: { offsetTop: 0 }, footer: { offsetBottom: 0 } }}
                styles={{footerContainer: tableStyles.tableFooter, header: tableStyles.tableHeader}}
                renderRow={rowRenderer}
                loading={isBudgetDetailsLoading}
                loadMore={false}
                footerVisible={false}
            />
        </>
    )
}

const numberCellRender = ({row, value}: {row: Row<ITableRow>, value: CellValue<number>}) => {
    const data = row.original as ITableRow;
    switch(data.type) {
        case RowType.Budget:
        case RowType.SingleServiceGroupCost:
        case RowType.TotalCost:
        case RowType.SingleCost:
            return (
                value > 0 ? "$" + formatNumber(value) : "-"
            );
        case RowType.Variance:
            return (
                value > 0 ?
                <>
                <FontIcon iconName="CompletedSolid" className={tableStyles.completedIcon} />+${formatNumber(value)}
                </> :
                <>
                <FontIcon iconName="WarningSolid" className={tableStyles.warningIcon} />-${formatNumber(Math.abs(value))}
                </>
            );
        default:
            throw "unrecognized row type";
    }
};

function getColumns(fiscalYear: number): Column<ITableRow>[] {
    return [
        {
            id: "Name",
            Header: <div style={{textAlign: "center", fontWeight: "bold"}}>Budget vs Cost</div>,
            Cell: ({row}: {row: Row<ITableRow>}) => {
                const data = row.original as ITableRow;
                switch(data.type) {
                    case RowType.Budget:
                        return (
                            <>
                            <FontIcon iconName="CostControl2" className={tableStyles.rowIcon} />
                            {"Budget: " + data.product}
                            </>
                        );
                    case RowType.SingleServiceGroupCost:
                        return (
                            <>
                            <FontIcon iconName="Money" className={tableStyles.rowIcon} />
                            {"Total Actual Cost: " + data.serviceGroup}
                            </>
                        );
                    case RowType.TotalCost:
                        return (
                            <>
                            <FontIcon iconName="Money" className={tableStyles.rowIcon} />
                            Total Actual Cost
                            </>
                        );
                    case RowType.SingleCost:
                        return (
                            <>
                            <FontIcon iconName="Page" className={tableStyles.detailsRowIcon} />
                            {data.serviceGroup + " cost"}
                            </>
                        );
                    case RowType.Variance:
                        return (
                            <>
                            <FontIcon iconName="CalculatorNegate" className={tableStyles.rowIcon} />
                            Variance to Budget
                            </>
                        );
                    default:
                        throw "unrecognized row type";
                }
            }
        },
        {
            id: "Total",
            Header: `FY${fiscalYear}`,
            Cell: numberCellRender,
            accessor: "total"
        },
        {
            id: "July",
            Header: "July",
            Cell: numberCellRender,
            accessor: "july"
        },
        {
            id: "August",
            Header: "August",
            Cell: numberCellRender,
            accessor: "august"
        },
        {
            id: "September",
            Header: "September",
            Cell: numberCellRender,
            accessor: "september"
        },
        {
            id: "October",
            Header: "October",
            Cell: numberCellRender,
            accessor: "october"
        },
        {
            id: "November",
            Header: "November",
            Cell: numberCellRender,
            accessor: "november"
        },
        {
            id: "December",
            Header: "December",
            Cell: numberCellRender,
            accessor: "december"
        },
        {
            id: "January",
            Header: "January",
            Cell: numberCellRender,
            accessor: "january"
        },
        {
            id: "February",
            Header: "February",
            Cell: numberCellRender,
            accessor: "february"
        },
        {
            id: "March",
            Header: "March",
            Cell: numberCellRender,
            accessor: "march"
        },
        {
            id: "April",
            Header: "April",
            Cell: numberCellRender,
            accessor: "april"
        },
        {
            id: "May",
            Header: "May",
            Cell: numberCellRender,
            accessor: "may"
        },
        {
            id: "June",
            Header: "June",
            Cell: numberCellRender,
            accessor: "june"
        }
    ]
}

function onClickServiceTreeNode(name: string, updateFilters: (action: FiltersAction, filters: IFilters) => void, serviceNodeByName: Map<string, ServiceTreeItem>) {
    const serviceNode = serviceNodeByName.get(name);
    if (!serviceNode) return;
    updateFilters(FiltersAction.Replace, {filters: {[getCategoryByServiceTreeLevel(serviceNode.l)]: [serviceNode.id]}, view: FiltersView.Breadcrumb});
}