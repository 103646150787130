import { PcmV2Keys } from "./PcmV2Models";
import moment from "moment";

export interface IServiceTreeDataResponse {
    serviceId: string;
    serviceName: string;
    organizationId: string;
    organizationName: string;
}

export interface IDataAnomalyResponse {
    startDate: string;
    endDate: string;
    detectionDate: string;
    category: string;
    status: string;
    affectedMetrics: string[];
    dataSourceName: DataSourceEnum;
}

export interface INotification {
    startDate: moment.Moment;
    endDate: moment.Moment;
    detectionDate: moment.Moment;
    category: string;
    status: string;
    affectedMetrics: string[];
    dataSourceName: DataSourceEnum;
}

export enum DataSourceEnum {
    Substrate = "Substrate",
    SubstrateV2 = "SubstrateV2",
    Cosmos = "Cosmos",
    AzureCompute = "AzureCompute",
    Telemetry = "TelemetryUsageCost",
}

export const SubstrateDataSourceNames = [
    "Store",
    "Assistant",
    "DSAPI",
    "CFM",
    "KvCache",
    "RequestsProcessed",
    "ServiceInstance",
    "SWSS",
    "ItemCount and LLC",
    "SubstrateCarbon"
];

export const AzureComputeDataSourceNames = [
    "AzureCost",
    "AzureCompute",
    "AzSCBilling",
    "AzureOfficeDaily",
    "AzureRdos",
    "AzureRevive",
    "AzureSubstrateDaily"
];

export const CosmosDataSourceNames = [
    "CosmosStorage",
    "CosmosProcessing",
];

export const TelemetryDataSourceNames = [
    "Cosmos",
    "Blueshift",
    "Kusto",
    "GenevaMds",
    "GenevaMdm",
    "Aria",
    "PassiveMon",
];

export const SubstrateV2DataSourceNames = [
    "SubstrateStoreTransaction",
    "SubstrateStoreNetwork",
    "SubstrateAspnet",
    "SubstrateProcesses",
    "SubstrateStorage",
    "SubstrateKVCache",
];

export const SubstrateV2DataSourceNameToMetricMap : Record<string, PcmV2Keys[]> = {
    "SubstrateStoreTransaction": ["itemReadCost", "itemWriteCost", "itemQueryCost"],
    "SubstrateStoreNetwork": ["networkCost", "logBytesCost", "bytesReturnedCost"],
    "SubstrateAspnet": ["processHostingCost"],
    "SubstrateProcesses": ["processHostingCost"],
    "SubstrateStorage": ["ssdKvCacheCost", "ssdmcdbCost", "hddCost"],
    "SubstrateKVCache": ["ssdKvCacheCost"],
}
