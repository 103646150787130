import React from "react";
import { SectionHeader } from "../../common/SectionHeader";

export interface IRCATableProps {
    insightRCA: string[];
}

export const IRCATable: React.FunctionComponent<IRCATableProps> = (props) => {
    return (
        <div>
            <SectionHeader title="Root Cause"/>
            <p style={{wordBreak:"break-word", fontWeight:600}}>The following dimensions are correlated with the increase in usage. Please check OperationMethod and ClientDiagActionCutTail dimensions (if available), as they often indicate inefficiencies in the corresponding APIs you are calling.</p>
            <table>
                <tbody>
                    {props.insightRCA.map((rca, index) => (
                        <tr key={index}>
                            <td>{rca}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
