import { CosmicPartnerServiceDetailsColumnNames, ICosmicServiceDetails } from '../../models/CosmicModels';
import { CosmicServiceDetailsTableOffset, CosmicServiceDetailsTableStyles } from './CosmicServiceDetailTableConstants';
import React, { useEffect } from 'react';
import { getCellOption, getFooterOption, getHeaderOption, useCosmicDetailTable, useCosmicServiceDetailReportParams, useCosmicServiceDetailReportQuery, useDetailTableColumnSelection } from './PartnerServiceDetailTableUtils';

import { ColumnSelector } from '../common/table/ColumnSelector';
import { SectionHeader } from '../common/SectionHeader';
import { Stack } from '@fluentui/react';
import StickyTable from '../common/table/StickyTable';
import commonStyles from './PartnerServiceDetailTable.less';
import { difference } from 'lodash';
import { useRemap } from '../../hooks/useRemap';
import { useUserPreference } from '../../utils/preference/useUserPreference';

interface IPartnerServiceDetailTableProps {
    defaultSorting?: string;
}

const PartnerServiceDetailTable: React.FC<IPartnerServiceDetailTableProps> = ({ defaultSorting }) => {
    const columns = CosmicPartnerServiceDetailsColumnNames;
    const heatmapParams = useCosmicServiceDetailReportParams(defaultSorting);
    const query = useCosmicServiceDetailReportQuery(heatmapParams);

    const [preferedTableConfig, setPreferedTableConfig] = useUserPreference("cosmicServiceDetail");

    const tableInstance = useCosmicDetailTable(
        columns,
        query.data,
        query.sort.setSort,
        query.sort.initialSortDesc,
        query.sort.initialSortKey || "CpuRequestCores",
        preferedTableConfig?.hiddenColumns || []
    );
    const { visibleColumns, changeVisibleColumns, columnSelectorOptions } = useDetailTableColumnSelection(tableInstance);

    useEffect(() => {
        setPreferedTableConfig({ hiddenColumns: difference(columns, visibleColumns) as (keyof ICosmicServiceDetails)[] });
    }, [visibleColumns]);

    const [] = useRemap();

    return (
        <div>
            <SectionHeader
                title="Service Details"
                extra={(
                    <Stack horizontal verticalAlign="center" horizontalAlign="end" className={commonStyles.headerExtra}>
                        <ColumnSelector onConfirm={changeVisibleColumns} columns={columnSelectorOptions} initSelectedKeys={visibleColumns} />
                    </Stack>
                )}
            />
            <StickyTable
                styles={CosmicServiceDetailsTableStyles}
                cellOption={getCellOption}
                headerOption={getHeaderOption}
                footerOption={getFooterOption}
                loading={query.isInitialLoading}
                loadMoreText="Show more services"
                emptyText="The selected services don't have data"
                loadMoreLoadingText={`Loading more ${query.paging.pageSize} services...`}
                loadMoreLoading={query.isLoadingMore}
                loadMore={query.hasMore}
                onLoadMore={query.paging.loadMore}
                table={tableInstance}
                stickyTarget="#pageContainer"
                stickyPositon={CosmicServiceDetailsTableOffset}
            />
        </div>
    );
};

PartnerServiceDetailTable.defaultProps = {
    defaultSorting: "CpuRequestCores",
}

export default PartnerServiceDetailTable;
