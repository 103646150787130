import { AzureComputeDescriptions, AzureComputeDisplayNames, AzureComputeFilterTypes, AzureComputeHeatmapKeys, CostCategoryDisplayNames } from "../../common/AzureComputeConstants";
import { AzureComputeDetailIdColumns, AzureComputeDetailNumericalColumns, AzureComputeTableDefaultPageSize } from './DetailTableConstants';
import {
    AzureCostTableTag,
    IAzureComputeDetail,
    IAzureComputeDetailData,
} from "../../../../models/AzureComputeModels";
import { Cell, Column, HeaderGroup, TableHeaderProps, TableInstance, TableOptions, TableState, UseSortByOptions, UseSortByState, useSortBy, useTable } from 'react-table';
import { FilterType, IFilterItem, IFilterOption } from "../../../filter/filterList/FilterList";
import { IIconProps, Icon } from '@fluentui/react';
import { IStickyTableCellOption, IStickyTableFooterOption, IStickyTableHeaderOption } from "../../../common/table/StickyTable";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { currencyFormatter, formatNumber } from "../../../../utils/currency";

import AzureCostTableFeedbackCell from "./AzureCostTableFeedbackCell";
import { IColumnSelectorItem } from "../../../common/table/ColumnSelector";
import { UseQueryResult } from "react-query";
import { getAzureComputeDegree } from "../../common/AzureComputeUtils";
import styles from './DetailTable.less';
import { useCategoryFilters } from "../../../../hooks/useFilters";
import { useDateRange } from "../../../../hooks/useDateSelector";

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

export const getHeaderOption = (header: HeaderGroup) : IStickyTableHeaderOption => {
    const cellProps : TableHeaderProps = { className: '', key: header.id };

    if (AzureComputeDetailNumericalColumns.has(header.id)) {
        cellProps.className += ' ' + styles.textAlignRight;
    }

    return { props: cellProps, tooltip: AzureComputeDescriptions[header.id as keyof IAzureComputeDetail] };
};

export const getFooterOption = (footer: HeaderGroup) : IStickyTableFooterOption => {
    return {
        props: {
            key: footer.id,
            className: AzureComputeDetailNumericalColumns.has(footer.id) ? styles.textAlignRight : '',
        },
    };
};

export const getCellOption = (cell: Cell) : IStickyTableCellOption => {
    const cellProps : React.HTMLProps<HTMLTableCellElement> = { className: '', key: cell.column.id, style: {} };

    if (AzureComputeDetailNumericalColumns.has(cell.column.id)) {
        cellProps.className += ' ' + styles.textAlignRight;
    }

    if (AzureComputeHeatmapKeys.indexOf(cell.column.id as keyof IAzureComputeDetail) >= 0) {
        cellProps.className += ' ' + styles.azureComputeMetricCell;
    }

    if (AzureComputeDetailIdColumns.has(cell.column.id)) {
        cellProps.className += ' ' + styles.azureComputeIdCell;
    }

    return { props: cellProps};
};


type IUseDetailTableSortSetter =  (key?: string, desc?: boolean) => void;

export const useDetailTable = <T extends IAzureComputeDetail>(
    type: 'Total' | 'Public' | 'AZSC',
    Ttype: AzureCostTableTag,
    columnKeys: (keyof T)[],
    detailData: IAzureComputeDetailData<T> | undefined,
    setSort: IUseDetailTableSortSetter,
    initialDesc: boolean,
    initialSort?: string,
    defaultHiddenColumns?: string[],
    customGetRowId?: (data: T) => string
) => {
    const { min, max, total, data } = detailData || {};

    const renderCell = (cell: Cell) => {
        const id = cell.column.id as keyof T;

        if (AzureComputeHeatmapKeys.indexOf(id as keyof IAzureComputeDetail) >= 0) {

            let ratio = 0;

            if (min && max && min[id] != undefined && max[id] != undefined && cell.value) {
                const minVal = min[id] as unknown as number;
                const maxVal = max[id] as unknown as number;
                ratio = !(maxVal - minVal) ? 0 : ((cell.value - minVal) / (maxVal - minVal));
            }

            return (
                <div className={styles.azureComputeMetricCellContent} style={{ backgroundColor: getAzureComputeDegree(ratio)?.color }}>
                    {
                        cell.column.id === "vmCost" ?
                        currencyFormatter(cell.value) : (cell.column.id == "billingCost" ? (cell.value || cell.value == 0 ? currencyFormatter(cell.value) : '-') :
                        formatNumber(cell.value || 0, 0))}{cell.column.id.endsWith('Percentage') ? '%' : ''}
                </div>
            );
        } else if (AzureComputeDetailNumericalColumns.has(cell.column.id)) {
            return formatNumber(cell.value, 0);
        } else if (cell.column.id === 'costCategory') {
            return CostCategoryDisplayNames[cell.value] || '-';
        } else if (cell.column.id === 'feedback') {
            return <AzureCostTableFeedbackCell type={type} Ttype={Ttype} data={data ? data[cell.row.index] : undefined} columns={columnKeys} defaultHiddenColumns={defaultHiddenColumns} />;
        }
        return cell.value || '-';
    };

    const columns: Column<T>[] = useMemo(() => {
        return columnKeys.map((id, index) => {
            let footer : string;

            if (AzureComputeHeatmapKeys.indexOf(id as keyof IAzureComputeDetail) >= 0 && total) {
                if ((id as string).endsWith('Percentage') || (id as string).startsWith('memory') || (id as string).startsWith('network')) {
                    footer = '-';
                } else if (id === 'vmCost') {
                    footer = currencyFormatter((total && total[id] as any as number) || 0, 2);
                } else {
                    footer = formatNumber((total[id] as any as number) || 0, 0);
                }
            } else if (id === 'totalCores' || id === 'averageCores') {
                footer = formatNumber((total && total[id] as any as number) || 0, 0);
            }
            else {
                footer = index == 0 ? 'Total' : '-';
            }

            return {
                id: id as string,
                accessor: id,
                Header: (
                    id === 'feedback' ? <Icon {...feedbackIconProps} /> : AzureComputeDisplayNames[id as string] || id
                ),
                Footer: footer,
                Cell: renderCell,
                sortDescFirst: true,
                disableSortBy: id === 'feedback' || AzureComputeFilterTypes[id as string] === FilterType.String,
            };
        });
    }, [columnKeys, total, renderCell]);

    let getRowId: (data: T) => string = useCallback((data: IAzureComputeDetail) =>  {
        if (!data.id) {
            console.error("no id found in data", data);
        }
        
        return data.id?.toString() || `${data.serviceId || ''}_${data.region || ''}_${data.subscriptionId || ''}`;
    }, []);

    if (customGetRowId) {
        getRowId = customGetRowId;
    }

    const tableInstance = useTable<T>(
        {
            columns,
            data: data || [],
            manualSortBy: true,
            autoResetHiddenColumns: false,
            getRowId,
            initialState: {
                sortBy: initialSort ? [
                    {
                        id: initialSort,
                        desc: initialDesc,
                    }
                ] : [],
                hiddenColumns: defaultHiddenColumns
            }
        } as TableOptions<T> & UseSortByOptions<T>,
        useSortBy
    ) as TableInstance<T> & { state: TableState<T> & UseSortByState<T> };

    useEffect(() => {
        if (tableInstance.state.sortBy && tableInstance.state.sortBy.length) {
            setSort(tableInstance.state.sortBy[0].id, !!tableInstance.state.sortBy[0].desc);
        } else {
            setSort(undefined);
        }
    }, [tableInstance.state.sortBy]);

    return tableInstance;
};

export const useDetailTableColumnSelection = <D extends Record<any, any>>(tableInstance: TableInstance<D>) => {
    const { columns } = tableInstance;
    const [visibleColumns, setVisibleColumns] = useState<string[]>(columns
        .map(item => item.id as string)
        .filter((col) => {
            if (!tableInstance.initialState?.hiddenColumns) return true;

            return tableInstance.initialState.hiddenColumns.indexOf(col) < 0;
        }));

    const columnSelectorOptions = useMemo<IColumnSelectorItem[]>(() => {
        return columns.map((({ id = '' }, index) => ({ key: id, disabled: index == 0, displayName: AzureComputeDisplayNames[id] })));
    }, [columns]);

    const changeVisibleColumns = (selectedColumns: string[]) => {
        setVisibleColumns(selectedColumns);
        tableInstance.setHiddenColumns((columns.filter(col => col.id && selectedColumns.indexOf(col.id) < 0)).map(item => item.id as string));
    };

    return {
        visibleColumns,
        columnSelectorOptions,
        changeVisibleColumns,
    }
};

export interface IUseQueryDetailCustomQueryType <T extends IAzureComputeDetail>{
    (...args: [string, IFilterItem[], string | undefined, boolean, number, ...any[]]): UseQueryResult<IAzureComputeDetailData<T>>;
}


export interface IUseQueryDetailSummaryCustomQueryType <T extends IAzureComputeDetail>{
    (...args: [string, IFilterItem[], string | undefined, boolean, ...any[]]): UseQueryResult<IAzureComputeDetailData<T>>;
}

export const useAzureComputeHeatmapParams = (
    type: string,
    columnKeys: string[],
    regions?: string[],
    defaultSortKey?: string,
    additionalFilterMetrics?: string[]
) => {
    const globalFilters = useCategoryFilters().filters.filters;
    const {singleDate: date} = useDateRange();

    const [filtersData, setFilters] = useState<IFilterItem[]>([]);
    const filterOptions : IFilterOption[] = useMemo(() => [...(additionalFilterMetrics || []), 'totalCores', ...columnKeys].map(key => ({ key, displayName: AzureComputeDisplayNames[key], type: AzureComputeFilterTypes[key] })), [additionalFilterMetrics]);

    const [limit, setLimit] = useState(AzureComputeTableDefaultPageSize);

    const [sortByKey, setSortByKey] = useState<string | undefined>(defaultSortKey);
    const [sortDesc, setSortDesc] = useState(true);

    const setSort = useCallback((key?: string, desc?: boolean) => {
        setSortByKey(key);
        setSortDesc(!!desc);
    }, []);

    useEffect(() => {
        setLimit(AzureComputeTableDefaultPageSize);
    }, [globalFilters, date, filtersData, sortByKey, sortDesc, type, regions]);

    return { filtersData, filterOptions, sortByKey, defaultSortKey, sortDesc, limit, setLimit, setSort, setFilters };
};

export const useAzureComputeHeatmapQuery = <T extends IAzureComputeDetail>(
    useCustomQuery: IUseQueryDetailCustomQueryType<T>,
    type: string,
    options: ReturnType<typeof useAzureComputeHeatmapParams>,
    regions?: string[],
    summary?: IAzureComputeDetailData<T>,
) => {
    const { filterOptions, filtersData, sortByKey, defaultSortKey, sortDesc, limit, setLimit, setSort, setFilters } = options;

    const query = useCustomQuery(type, filtersData, sortByKey, sortDesc, limit, regions);

    if (summary && query.data) {
        query.data.max = summary.max;
        query.data.min = summary.min;
        query.data.total = summary.total;
        query.data.rowCount = summary.rowCount;
    }

    const [isLoading, setLoading] = useState(query.isLoading);
    const [data, setData] = useState<IAzureComputeDetailData<T> | undefined>(query.data);

    useEffect(() => {
        if (!query.isLoading) {
            setData(query.data);
        }
        setLoading(query.isLoading);
    }, [query.isLoading, query.data]);

    return {
        isLoading,
        isLoadingMore: isLoading && limit != AzureComputeTableDefaultPageSize,
        hasMore: data ? (data.data.length < data.rowCount) : false,
        data,
        isInitialLoading: isLoading && limit == AzureComputeTableDefaultPageSize,

        paging: {
            pageSize: AzureComputeTableDefaultPageSize,
            loadMore: () => setLimit(limit + AzureComputeTableDefaultPageSize),
        },

        localFilters: { data: filtersData, setFilters, options: filterOptions },
        sort: { setSort, initialSortKey: defaultSortKey, initialSortDesc: sortDesc },
    };
}
