import React from 'react';
import { Separator } from '@fluentui/react';
import styles from './SectionHeader.less';

export interface ISectionHeaderProps {
    title: string;
    extra?: React.ReactElement;
    className?: string;
}

export const SectionHeader : React.FC<ISectionHeaderProps> = (props) => {
    return (
        <div className={props.className}>
            <Separator styles={{ root: styles.separator }} />
            <div className={styles.sectionHeaderContent}>
                <h4 className={styles.sectionHeaderTitle}>{props.title}</h4>
                { props.extra }
            </div>
        </div>
    )
};
