import * as React from "react";
import styles from "../Page.less";
import PageWrapper from "../PageWrapper";
import ResponseBoundary from "../../../ResponseBoundary/ResponseBoundary";
import {
    useGetOverviewFYCost,
    useGetAzureDataSourceFYCost,
    useGetAzureRegionFYCost,
    useGetAzureSubscriptionFYCost,
    useGetSubscriptionMapping,
} from "../../../../hooks/useES365CostQuery";
import { ESCostBillingType, ESCostDataDimensions } from "../../../../models/ESCostModels";
import OverviewPieChart from "../../Charts/Common/OverviewPieChart";
import AzureDataSourcePieChart from "../../Charts/Azure/AzureDataSourcePieChart";
import TopAzureCostBarChart from "../../Charts/Azure/TopAzureCostBarChart";
import { changeToDict } from "./AzureDetailsPage";

const AzureOverviewPage: React.FC = () => {
    const { data: esFYCost, isLoading, isError } = useGetOverviewFYCost();
    const { data: esAzureDataSourceCost, isLoading: isAzureDataSourceCostLoading, isError: isAzureDataSourceCostError } = useGetAzureDataSourceFYCost();
    const { data: esAzureRegionCost, isLoading: isAzureRegionLoading, isError: isAzureRegionError } = useGetAzureRegionFYCost();
    const { data: esAzureSubscriptionCost, isLoading: isAzureSubscriptionLoading, isError: isAzureSubscriptionError } = useGetAzureSubscriptionFYCost();
    const { data: subscriptionMapping, isLoading: isSubscriptionMappingLoading, isError: isSubscriptionMappingError } = useGetSubscriptionMapping();
    const mapping = React.useMemo(() => changeToDict(subscriptionMapping), [subscriptionMapping]);
    return (
        <PageWrapper
            title={`Azure cost overview`}
            description={`Azure cost overview will be beneficial for understanding Azure cost better, seeking cost optimization opportunities, and making budget planning accordingly.`}
        >
            <div className={styles.grid}>
                <ResponseBoundary isLoading={isLoading} errorHappened={isError} data={esFYCost}>
                    {esFYCost !== undefined ? (
                        <OverviewPieChart
                            data={esFYCost}
                            description="Total cost of Azure"
                            totalCountDesc="Total Cost"
                            isCurrency={true}
                            billingType={ESCostBillingType.Azure}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
                <ResponseBoundary isLoading={isAzureDataSourceCostLoading} errorHappened={isAzureDataSourceCostError} data={esAzureDataSourceCost}>
                    {esAzureDataSourceCost !== undefined ? <AzureDataSourcePieChart data={esAzureDataSourceCost} /> : <></>}
                </ResponseBoundary>
            </div>
            <div className={styles.gridForSingleCell}>
                <ResponseBoundary isLoading={isAzureSubscriptionLoading} errorHappened={isAzureSubscriptionError} data={esAzureRegionCost}>
                    {esAzureRegionCost !== undefined ? (
                        <TopAzureCostBarChart
                            data={esAzureRegionCost}
                            description={"Top 10 Azure cost in different regions"}
                            targetDimension={ESCostDataDimensions.Region}
                            top={10}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
            <div className={styles.gridForSingleCell}>
                <ResponseBoundary
                    isLoading={isAzureRegionLoading || isSubscriptionMappingLoading}
                    errorHappened={isAzureRegionError || isSubscriptionMappingError}
                    data={esAzureSubscriptionCost && subscriptionMapping}
                >
                    {esAzureSubscriptionCost !== undefined ? (
                        <TopAzureCostBarChart
                            data={esAzureSubscriptionCost}
                            description={"Top 10 Azure cost in different subscriptions"}
                            targetDimension={ESCostDataDimensions.SubscriptionId}
                            top={10}
                            subscriptionMapping={mapping}
                        />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
        </PageWrapper>
    );
};

export default AzureOverviewPage;
