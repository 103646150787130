// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OEDJ0qGG1hB_gkmAJiFw {\n  min-height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.SJTJBiprYnKuQOp51FGm::before {\n  background-color: #323130;\n}\n.YpeSUH_pc8Defn4DG1B4 {\n  margin-top: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/sqltool/SQLToolView.less"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAEA;EACI,yBAAA;AAAJ;AAGA;EACI,gBAAA;AADJ","sourcesContent":[".sqltoolView {\n    min-height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.separator::before {\n    background-color: #323130;\n}\n\n.clipboardButton {\n    margin-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sqltoolView": "OEDJ0qGG1hB_gkmAJiFw",
	"separator": "SJTJBiprYnKuQOp51FGm",
	"clipboardButton": "YpeSUH_pc8Defn4DG1B4"
};
export default ___CSS_LOADER_EXPORT___;
