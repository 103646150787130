import { DefaultButton, Separator } from "@fluentui/react";
import * as React from "react";
import { useGetTopAzureWorkloadsMonthlyCostQuery, useGetTotalBudgetQuery } from "../../../hooks/useAzureQuery";
import { useDateRange } from "../../../hooks/useDateSelector";
import { fiscalMonthsList } from "../../../utils/Constants";
import EmptyState from "../../common/state/EmptyState";
import LoadingState from "../../ResponseBoundary/LoadingState";
import styles from "../Azure.less";
import { BudgetChart } from "../budget/BudgetChart";

interface IAzureBudgetProps {
    updateSelectedTab: (tab: string) => void;
}

export const AzureBudget: React.FC<IAzureBudgetProps> = (props) => {
    const { fiscalYear } = useDateRange();
    const {isLoading: isTotalBudgetLoading, data: totalBudget} = useGetTotalBudgetQuery(fiscalYear);
    const {isLoading: isGetTop8WorkloadLoading, data: top8Workloads} = useGetTopAzureWorkloadsMonthlyCostQuery("Total", fiscalYear, 8);
    const isDataLoading = isTotalBudgetLoading && isGetTop8WorkloadLoading;

    const hasBudget = React.useMemo(
        () => (isTotalBudgetLoading || !totalBudget) ? true : totalBudget.publicBudgets.find(x => x > 0) || totalBudget.azscBudgets.find(x => x > 0),
        [isTotalBudgetLoading, totalBudget]);

    return (
        <>
        <Separator styles={{root: styles.separator}} />
        <h4 className={styles.title}>Budget and Top Workloads Cost Trends (Monthly)</h4>
        <div className={styles.chart}>
            {
                !hasBudget ?
                <div style={{padding: 20, textAlign: "center"}}>
                    <EmptyState width={200} />
                    <p>{"The selected services don't have the budget data"}</p>
                </div> :
                !isDataLoading && totalBudget && top8Workloads ?
                <BudgetChart
                    budgetPlan={totalBudget && totalBudget.publicBudgets.map((value, i) => value + totalBudget.azscBudgets[i]).map((budget, i) => [fiscalMonthsList[i], budget])}
                    cost={totalBudget && totalBudget.publicCosts.map((value, i) => (value === null && totalBudget.azscCosts[i] === null) ? null : ((value || 0) + (totalBudget.azscCosts[i] || 0))).map((cost, i) => [fiscalMonthsList[i], cost])}
                    top8Workloads={top8Workloads}
                    monthsList={fiscalMonthsList}
                /> :
                <LoadingState />
            }
        </div>
        {
            hasBudget &&
            <DefaultButton
                className={styles.linkButton}
                text="View Azure Budget Details"
                onClick={() => props.updateSelectedTab("BudgetDetail")} />
        }
        </>
    )
}