import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { Pages, SubPages } from "../../../models/Nav";
import { Pivot, PivotItem } from "@fluentui/react";

import { EmpytCostView } from "../common/EmptyCostView";
import PageHeader from "../../common/PageHeader";
import { PcmV2SceneTypeEnum } from "../../../models/PcmV2Models";
import React from "react";
import { SubstrateNetworkByScenarioTag } from "./SubstrateNetworkByScenarioTag";
import { SubstrateNetworkByService } from "./SubstrateNetworkByService";
import { SubstrateV2WikiLink } from "../../../models/constants/PcmV2Constants";
import { WarningBanner } from "../../banner/WarningBanner";
import styles from "./SubstrateNetwork.less";
import substrateStyle from "../Substrate.less";
import { trackEventCallback } from "../../../utils/AppInsights";
import { useGetBannersByTabAndDateQuery } from "../../../hooks/useBannerQuery";
import { useGetPcmV2Cost } from "../../../hooks/useSubstrateV2Query";
import { useGotoTab } from "../../../hooks/useGotoPage";
import { useParams } from "react-router-dom";

export const SubstrateNetwork: React.FC = () => {
    const { data: pcmV2Cost } = useGetPcmV2Cost();
    const { data: banners } = useGetBannersByTabAndDateQuery(PcmV2SceneTypeEnum.Network);
    const curTab = useParams().tab || "Service";

    const gotoTab = useGotoTab();
    const handleLinkClick = (item?: PivotItem) => {
        if (item?.props.itemKey) {
            gotoTab(`${Pages.SubstrateV2}/${SubPages.Network}`, item?.props.itemKey, `${Pages.SubstrateV2}/${SubPages.Network}`);
            trackEventCallback(
                LogComponent.NetworkChartingPane,
                item.props.itemKey === "Service" ? LogElement.NetworkByService : LogElement.NetworkByScenarioTag,
                item.props.itemKey,
                LogTarget.Tab
            );
        }
    };

    return (
        <div className={substrateStyle.pageWrapper}>
            <PageHeader
                className={substrateStyle.pageHeader}
                title="Network Cost View"
                description="We use this cost category to attribute the cost associated with WAN (majority) and LAN (minority) usage of features built on the Substrate platform. We get the total cost for this bucket directly from the MCIO statements."
                link={SubstrateV2WikiLink}
                linkText="Learn More"
                tag="PREVIEW"
                linkLogComponent={LogComponent.NetworkChartingPane}
                linkLogElement={LogElement.LearnMoreNetwork}
            />
            {!!banners?.length && (
                <div className={substrateStyle.bannerWrapper}>
                    {banners.map((item) => (
                        <WarningBanner key={item.id} bannerItem={item} />
                    ))}
                </div>
            )}
            {pcmV2Cost && pcmV2Cost.networkCost === 0 ? (
                <EmpytCostView />
            ) : (
                <Pivot selectedKey={curTab} onLinkClick={handleLinkClick} focusZoneProps={{ className: styles.pivotList }} overflowBehavior="menu">
                    <PivotItem headerText="Network by Service" itemKey="Service">
                        <SubstrateNetworkByService />
                    </PivotItem>
                    <PivotItem headerText="Network by Scenario Tag" itemKey="ScenarioTag">
                        <SubstrateNetworkByScenarioTag />
                    </PivotItem>
                </Pivot>
            )}
        </div>
    );
};
