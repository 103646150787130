import * as React from "react";

import { DonutChart, IDonutChartProps } from "../../common/DonutChart";
import { PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum } from "../../../models/PcmV2Models";

import ChartContainer from "../../chart/ChartContainer";
import CommonConstants from "../../../models/constants/CommonConstants";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useGetStorageCost } from "../../../hooks/useSubstrateV2Query";
import { useTrackHovering } from "../../../hooks/useTrack";
import { SubstrateLogComponent } from "../../../models/constants/PcmV2Constants";
import { LogElement, LogTarget, LogComponent } from "../../../models/LogModel";

export interface ISubstrateTopCostProportionProps {
    scene: PcmV2SceneTypeEnum;
    subScene?: PcmV2SubSceneTypeEnum;
}

const ChartTitle: Partial<Record<PcmV2SceneTypeEnum | PcmV2SubSceneTypeEnum, string>> = {
    [PcmV2SubSceneTypeEnum.HDDDatasetCost]: "HDD-Storage Dataset Cost Proportion (Show up to TOP10)",
    [PcmV2SubSceneTypeEnum.HDDMailboxCost]: "HDD-Storage Mailbox Category Cost Proportion (Show up to TOP10)",
    [PcmV2SubSceneTypeEnum.SSDDatasetCost]: "SSD-Storage (MCDB) Dataset Cost Proportion (Show up to TOP10)",
    [PcmV2SubSceneTypeEnum.SSDMailboxCost]: "SSD-Storage (MCDB) Mailbox Category Cost Proportion (Show up to TOP10)",
};

const LogComponentName: Partial<Record<PcmV2SceneTypeEnum | PcmV2SubSceneTypeEnum, LogComponent>> = {
    [PcmV2SubSceneTypeEnum.HDDDatasetCost]: LogComponent.HddV2,
    [PcmV2SubSceneTypeEnum.HDDMailboxCost]: LogComponent.HddV2,
    [PcmV2SubSceneTypeEnum.SSDDatasetCost]: LogComponent.SsdV2,
    [PcmV2SubSceneTypeEnum.SSDMailboxCost]: LogComponent.SsdV2,
};

export const SubstrateTopCostProportion: React.FC<ISubstrateTopCostProportionProps> = (props) => {
    const query = useGetStorageCost(props.scene, props.subScene);
    const dateRange = useDateRange();
    const trackingProps = useTrackHovering(SubstrateLogComponent[props.scene], LogElement.SubstrateTopCostProportion, "Cost proportion", LogTarget.Chart);


    const data = React.useMemo<IDonutChartProps["data"]>(() => {
        if (!query.data) return [];

        return query.data
            .sort((a, b) => b.cost - a.cost)
            .slice(0, 10)
            .map((cost, index) => ({
                name: cost.identity.workload || "-",
                y: cost.cost,
                color: CommonConstants.DefaultColors[index],
            }));
    }, [query.data]);

    return (
        <ChartContainer
            chartType="donut"
            chartProps={{
                data,
                dateRange: [dateRange.startDate, dateRange.endDate],
                containerProps: trackingProps,
            }}
            headerProps={{ title: ChartTitle[props.subScene || props.scene] || "" }}
            loading={query.isLoading}
            
            logProps={{
                logComponent: LogComponentName[props.subScene || props.scene] || LogComponent.HddV2,
                logElement: LogElement.Top10,
            }}
        />
    );
};
