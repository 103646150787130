import { Stack } from "@fluentui/react";
import { DonutChart, IChartDataPoint } from "@fluentui/react-charting";
import { toPairs } from "lodash";
import * as React from "react";
import styles from "./PieChart.less";
import { currencyFormatter } from "../../../../utils/currency";
import CostCard from "../../../common/costCard/CostCard";
import { ESOverviewTimeSelection } from "../../../../models/ESCostModels";
import CostCardTooltipContent from "../../../common/costCard/CostCardTooltipContent";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import { getColor } from "../../../common/costCard/CostCardUtils";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import ChartContainer from "../../../chart/ChartContainer";
import { EmptyModule } from "../../../common/state/EmptyModule";

export interface IPieChartData {
    [key: string]: number;
}

interface IOverviewPieChartProps<T extends IPieChartData> {
    data: T;
    description: string;
    totalCountDesc: string;
    isCurrency: boolean;
    total: number;
}

const PieChart = <T extends IPieChartData>(props: IOverviewPieChartProps<T>) => {
    const { data, description, totalCountDesc, isCurrency, total } = props;
    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );
    const points: IChartDataPoint[] = React.useMemo(() => {
        const d = toPairs(data)
            .map(([k, v], index) => ({
                legend: k,
                data: v,
                yAxisCalloutData: currencyFormatter(v || 0, 2, isCurrency ? "$" : ""),
                originData: v,
                xAxisCalloutData: k,
                color: getColor(index),
            }))
            .filter((item) => item.data > 0);
        return d;
    }, [data, isCurrency]);
    const getValueByKey = (key?: string): number => {
        if (!data || !key) return 0;
        return data[key] || 0;
    };
    const isEmpty = React.useMemo(() => points === null || points.length === 0 || points.every((p) => p.data === 0), [points]);

    return (
        <ChartContainer headerProps={{ title: `${description} (FY${String(year).substring(2)})` }}>
            {isEmpty ? (
                <EmptyModule iconName="EmptyDonutChart" />
            ) : (
                <Stack className={styles.overviewChart} horizontal verticalAlign="center">
                    <div className={styles.metricBlockContainer}>
                        <div className={styles.metricBlockContainerTitle}>
                            <span style={{ color: "#0078D4" }}>{currencyFormatter(total, 2, isCurrency ? "$" : "")}</span> {totalCountDesc}
                        </div>
                        <div className={styles.metricBlockList}>
                            {toPairs(data).map(([costKey, costValue], index) => (
                                <div className={styles.metricBlock} key={costKey}>
                                    <CostCard title={costKey} cost={costValue} color={index} noPrefix={!isCurrency} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.donutChartContainer}>
                        <DonutChart
                            showXAxisLablesTooltip
                            onRenderCalloutPerDataPoint={(dataPoint) => (
                                <CostCardTooltipContent
                                    items={[{ title: dataPoint?.legend || "", cost: getValueByKey(dataPoint?.xAxisCalloutData), color: dataPoint?.color || 0 }]}
                                    noPrefix={!isCurrency}
                                />
                            )}
                            hideLegend
                            innerRadius={60}
                            height={220}
                            width={176}
                            data={{ chartData: points }}
                        />
                    </div>
                </Stack>
            )}
        </ChartContainer>
    );
};

export default PieChart;
