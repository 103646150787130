import * as React from "react";
import {
    ESCostDataDimensions,
    ESCostOrganization,
    ESOverviewTimeSelection,
    ESBuildRoleFYCostRawResponse,
    ESCostRawUserType,
    ESArtifactDataType,
} from "../../../../models/ESCostModels";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import PieChart, { IPieChartData } from "../Common/PieChart";
import { useEsManagers } from "../../../../hooks/useEsManagers";
import { useEsOrg } from "../../../../hooks/useEsOrg";
import { ESOrgs } from "../../../../models/constants/ESCostConstants";

interface IUsageScenarioPieChartProps {
    data: ESBuildRoleFYCostRawResponse[];
    isCurrency?: boolean;
}

function calculateOverviewValues(rawData: ESBuildRoleFYCostRawResponse[], org: ESCostOrganization[], managers: string[], year: number, isCurrency?: boolean): IPieChartData {
    const values = rawData.filter(
        (data) =>
            data[ESCostDataDimensions.UserType] === ESCostRawUserType.Human &&
            ESOrgs.includes(data[ESCostDataDimensions.Organization]) &&
            (org.length === 0 || org.includes(data[ESCostDataDimensions.Organization])) &&
            (managers.length === 0 || managers.includes(data[ESCostDataDimensions.UserName])) &&
            year === data[ESCostDataDimensions.ReturnFiscalYear]
    );
    const sum = values.reduce((acc, curr) => {
        const buildRole = curr[ESCostDataDimensions.BuildRole];
        const v = isCurrency === false ? curr[ESCostDataDimensions.ArtifactWorkload] : curr[ESCostDataDimensions.Cost];
        acc[buildRole] = (acc[buildRole] || 0) + v;
        return acc;
    }, {} as IPieChartData);
    return sum;
}

const ManagersBuildRoleOverviewPieChart: React.FC<IUsageScenarioPieChartProps> = (props) => {
    const { data: rawData, isCurrency } = props;
    const [org] = useEsOrg();
    const [managers] = useEsManagers();
    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );
    const overviewValues = React.useMemo(() => calculateOverviewValues(rawData, org, managers, year, isCurrency), [managers, org, rawData, year, isCurrency]);
    const total = Object.values(overviewValues).reduce((acc, curr) => acc + curr, 0);
    const str = isCurrency === false ? ESArtifactDataType.Workload : ESArtifactDataType.Cost;
    return (
        <PieChart
            data={overviewValues}
            description={`The ${str} of different build roles of selected managers`}
            totalCountDesc={`Total ${str}`}
            isCurrency={isCurrency !== undefined ? isCurrency : true}
            total={total}
        />
    );
};

export default ManagersBuildRoleOverviewPieChart;
