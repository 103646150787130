// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#pageContainer[pathname^=\"/Telemetry\"] {\n  padding-bottom: 0;\n}\n#pageContainer[pathname^=\"/Telemetry\"] #overviewUse {\n  height: 100%;\n}\n.aoFEl0LfnAcZjcunOaTM {\n  min-height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.IIOh6SlRlhKrIBxV3vR4 {\n  font-size: 14px;\n  width: 614px;\n  max-width: 100%;\n  margin-bottom: 28px;\n}\n.nwgt3Cm6ZqUiAKcVWOe6 {\n  padding: 48px 48px 18px 48px;\n}\n.aQcswMqDZe86EYxjoso1 {\n  padding: 0px 48px 0px 48px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Telemetry/TelemetryView.less"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AAFA;EAIQ,YAAA;AACR;AAGA;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;AADJ;AAIA;EACI,eAAA;EACA,YAAA;EACA,eAAA;EACA,mBAAA;AAFJ;AAKA;EACI,4BAAA;AAHJ;AAMA;EACI,0BAAA;AAJJ","sourcesContent":[":global(#pageContainer[pathname^=\"/Telemetry\"]) {\n    padding-bottom: 0;\n\n    :global(#overviewUse) {\n        height: 100%;\n    }\n}\n\n.telemetryView {\n    min-height: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.headerDesc {\n    font-size: 14px;\n    width: 614px;\n    max-width: 100%;\n    margin-bottom: 28px;\n}\n\n.header {\n    padding: 48px 48px 18px 48px;\n}\n\n.Bannerheader {\n    padding: 0px 48px 0px 48px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"telemetryView": "aoFEl0LfnAcZjcunOaTM",
	"headerDesc": "IIOh6SlRlhKrIBxV3vR4",
	"header": "nwgt3Cm6ZqUiAKcVWOe6",
	"Bannerheader": "aQcswMqDZe86EYxjoso1"
};
export default ___CSS_LOADER_EXPORT___;
