import { IIconProps, Icon, Link } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionButton } from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Cell, Column, HeaderGroup, Row, TableInstance, TableOptions, useSortBy, UseSortByInstanceProps, UseSortByOptions, useTable } from 'react-table';
import { ActionTypes } from '../../actions/ActionTypes';
import { useGetCosmosCostByServices } from '../../hooks/useCosmosQuery';
import { CosmosCostDisplayNames, CosmosCostTableKeys, CosmosCostNumericalKeys, CosmosCostPaymentKeys, CosmosSourceTypeEnum, ICosmosCost, CosmosSourceTypeName } from '../../models/CosmosModels';
import { FiltersAction, FiltersView } from '../../reducers/filterReducer';
import { getCategoryByServiceTreeLevel, IServiceTreeData } from '../../reducers/serviceTreeReducer';
import { getCosmosCostSummary } from '../../services/cosmosService';
import { IAppState } from '../../store';
import { getServiceTreePath } from '../../utils/common';
import { formatNumber } from '../../utils/currency';
import { exportToCsv } from '../../utils/common';
import { SectionHeader } from '../common/SectionHeader';
import StickyTable, { IStickyTableCellOption, IStickyTableFooterOption, IStickyTableHeaderOption } from '../common/table/StickyTable';
import { FilterList, FilterType, IFilterItem } from '../filter/filterList/FilterList';
import { match } from '../filter/filterList/FilterUtils';
import styles from './CosmosCostTable.less';
import { useCategoryFilters } from '../../hooks/useFilters';
import { ServiceTree, ServiceTreeItem, ServiceTreeLevel } from '../../models/serviceTree';
import { buildBreadcrumbFromServiceItem } from '../../utils/FiltersUtils';
import CosmosCostTableFeedbackCell from './CosmosCostTableFeedbackCell';

export interface ICosmosCostTableProps {
    type: CosmosSourceTypeEnum;
}

const feedbackIconProps: IIconProps = { iconName: "Feedback" };

const getCellOptions = (cell: Cell) : IStickyTableCellOption => {
    return {
        props: {
            key: cell.column.id,
            className: CosmosCostNumericalKeys.find((item) => item === cell.column.id) ? styles.textAlignRight : '',
        }
    }
};

const getHeaderOption = (header: HeaderGroup): IStickyTableHeaderOption => {
    return {
        tooltip: getHeaderTooltip(header.id as keyof ICosmosCost),
        props: {
            key: header.id,
            className: CosmosCostNumericalKeys.find((item) => item === header.id) ? styles.textAlignRight : '',
        }
    }
};

const getFooterOption = (footer: HeaderGroup): IStickyTableFooterOption => {
    return {
        props: {
            key: footer.id,
            className: CosmosCostNumericalKeys.find((item) => item === footer.id) ? styles.textAlignRight : '',
        }
    }
};

const getHeaderTooltip = (key: keyof ICosmosCost) => {
    switch (key) {
        case 'totalCost': return "Total Cosmos cost is the sum of storage cost and processing cost.";
        case 'storageCosts': return (<div style={{ fontWeight: 600 }}>Storage pricing model<br />(FY25/FY24) 35.4/(TB * Year)</div>);
        case 'processingCosts': return (<div style={{ fontWeight: 600 }}>Processing pricing model<br />(FY25) $175.2/(Token * Year) <br />(FY24) $178.68/(Token * Year)</div>);
    }
    return undefined;
}

const getDefaultSortColumn = (type: CosmosSourceTypeEnum) : keyof ICosmosCost => {
    if (type === CosmosSourceTypeEnum.Processing) return 'processingCosts';
    if (type === CosmosSourceTypeEnum.Storage) return 'storageCosts';

    return 'totalCost';
};

const intlFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
});

const filterOptions: Record<CosmosSourceTypeEnum, (keyof ICosmosCost)[]> = {
    [CosmosSourceTypeEnum.All]: ['totalCost', 'storageCosts', 'physicalSize', 'processingCosts', 'totalPnHours'],
    [CosmosSourceTypeEnum.Storage]: ['storageCosts', 'physicalSize', 'compressionRatio'],
    [CosmosSourceTypeEnum.Processing]: ['processingCosts', 'totalPnHours'],
}

const CosmosCostTable: React.FC<ICosmosCostTableProps> = (props) => {
    const [filters, setFilters] = useState<IFilterItem[]>([]);
    const query = useGetCosmosCostByServices(props.type);
    const [isLoading, setLoading] = useState(query.isLoading);
    const [filteredTotal, setFilteredTotal] = useState<Record<TypedKeyOf<ICosmosCost, number>, number>>();
    const [filteredData, setFilteredData] = useState<ICosmosCost[]>(query.data || []);
    const serviceTreeData = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const updateFilters = useCategoryFilters().updateFilters;

    useEffect(() => {
        if (!query.data) return;

        const newData = query.data?.filter(item => !filters.find(filter => !match(item[filter.key as TypedKeyOf<ICosmosCost, number>], filter.operator, parseFloat(filter.value || "0")))) || [];
        const newTotal = getCosmosCostSummary(newData);

        setFilteredData(newData);
        setFilteredTotal(newTotal);
    }, [query.data, filters]);

    const handleDownload = () => {
        exportToCsv(query.data || [], 'Cosmos BDP Cost', CosmosCostTableKeys[props.type], CosmosCostDisplayNames, (key: string, value: string | number) => {
            if (value === undefined || value === null) return '-';

            return String(value);
        });
    };

    const handleLinkToServiceTree = useCallback((orgId?: string) => {
        if (!orgId) return;
        const serviceTreeNode = serviceTreeData.indexMap.get(orgId);
        if (!serviceTreeNode) return;

        if (serviceTreeNode.l === ServiceTreeLevel.Organization) {
            updateFilters(FiltersAction.Replace, {filters: {[getCategoryByServiceTreeLevel(serviceTreeNode.l)]: [serviceTreeNode.id]}, view: FiltersView.Breadcrumb});
        } else if (serviceTreeNode.l === ServiceTreeLevel.ServiceGroup) {
            updateFilters(FiltersAction.Replace, {filters: {[getCategoryByServiceTreeLevel(serviceTreeNode.l)]: [serviceTreeNode.id]}, view: FiltersView.Breadcrumb});
        }
    }, [serviceTreeData.indexMap, updateFilters]);

    const renderCell = useCallback((cell: Cell) =>  {
        const key = cell.column.id as keyof ICosmosCost;
        if (key === 'compressionRatio') {
            return cell.value ? formatNumber(cell.value, 2) : '-';
        } else if (key === 'organizationName') {
            return <Link onClick={() => handleLinkToServiceTree(filteredData[cell.row.index]?.organizationId)}>{cell.value}</Link>;
        } else if (key === 'serviceGroupName') {
            return <Link onClick={() => handleLinkToServiceTree(filteredData[cell.row.index]?.serviceGroupId)}>{cell.value}</Link>;
        } else if (key === 'feedback') {
            return <CosmosCostTableFeedbackCell data={filteredData[cell.row.index]} columns={CosmosCostTableKeys[props.type]} scene={props.type} />;
        } else if (CosmosCostPaymentKeys.find(item => item === key)) {
            return intlFormatter.format(cell.value);
        } else if (CosmosCostNumericalKeys.find((item) => item === key)) {
            return formatNumber(cell.value, 0);
        }

        return !cell.value ? '-' : cell.value;
    }, [filteredData, handleLinkToServiceTree]);

    const renderFooter = useCallback((key: string, index: number, value?: number) => {
        if (index === 0) return 'Total';
        if (value === undefined) return '-';

        if (CosmosCostPaymentKeys.find(item => key === item)) return intlFormatter.format(value);
        if (CosmosCostNumericalKeys.find(item => key === item)) return formatNumber(value, 0);

        return '-';
    }, []);

    useEffect(() => {
        setLoading(query.isLoading);
    }, [query.isLoading]);

    useEffect(() => {
        setFilters([]);
    }, [props.type]);

    const columns : Column<ICosmosCost>[] = useMemo(() => {
        return CosmosCostTableKeys[props.type].map((key, index) => ({
            id: key,
            accessor: key,
            sortType: 'basic',
            Header: (
                key === 'feedback' ? <Icon {...feedbackIconProps} /> : CosmosCostDisplayNames[key]
            ),
            Footer: () => renderFooter(key, index, filteredTotal && filteredTotal[key as keyof typeof filteredTotal]),
            Cell: renderCell,
            sortDescFirst: true,
        }));
    }, [filteredTotal, serviceTreeData.serviceTree, props.type]);

    const getRowId = useCallback((data: ICosmosCost) => `${props.type}_${data.serviceDevOwners}_${data.servicePMOwners}_${data.serviceId}_${data.serviceName}_${data.organizationId}_${data.organizationName}_${data.serviceGroupId}_${data.serviceGroupName}`, [props.type]);

    const tableInstance = useTable({
        columns,
        data: filteredData,
        getRowId,
        initialState: {
            sortBy: [{
                id: getDefaultSortColumn(props.type),
                desc: true,
            }],
        },
        disableSortRemove: true,
    } as TableOptions<ICosmosCost> & UseSortByOptions<ICosmosCost>, useSortBy) as (TableInstance<ICosmosCost> & UseSortByInstanceProps<ICosmosCost>);

    useEffect(() => {
        tableInstance.setSortBy([{
            id: getDefaultSortColumn(props.type),
            desc: true,
        }]);
    }, [props.type]);

    return (
        <div className={styles.costTableView}>
            <SectionHeader
                className={styles.sectionHeader}
                title="Cosmos BDP Cost"
                extra={
                    <ActionButton
                        text="Download"
                        iconProps={{ iconName: 'Download' }}
                        onClick={handleDownload}
                    />}
            />
            <FilterList className={styles.filterList} data={filters} onChange={setFilters} options={filterOptions[props.type].map((key) => ({ key, displayName: CosmosCostDisplayNames[key], type: FilterType.Number }))} />
            <StickyTable
                styles={{
                    container: styles.tableContainer,
                    bodyContainer: styles.tableBodyContainer,
                    footerContainer: styles.tableSectionContainer,
                    headerContainer: styles.tableSectionContainer,
                    stickyFooterContainer: styles.tableStickyFooterContainer,
                }}
                key={props.type}
                cellOption={getCellOptions}
                headerOption={getHeaderOption}
                footerOption={getFooterOption}
                loading={query.isLoading || isLoading}
                loadMore={false}
                emptyText="Selected services don't have data"
                table={tableInstance}
                stickyPositon={{ header: { offsetTop: -40 }, footer: { offsetBottom: 0 } }}
            />
        </div>
    );
};

export default CosmosCostTable;
