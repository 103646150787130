import { ActionButton, DefaultButton, IButtonStyles, INavLink, INavLinkGroup, IPanel, IPanelStyles, IStackStyles, IStyle, ITextStyles, Nav, Panel, PanelType, PrimaryButton, Stack, Text } from "@fluentui/react";
import { EventType, LogComponent, LogElement, LogTarget } from "../models/LogModel";
import { FiltersAction, FiltersView } from "../reducers/filterReducer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ServiceTree, ServiceTreeItem } from "../models/serviceTree";

import { DataNode } from "../models/DataNode";
import { IFilters } from "../models/FilterView";
import TreeSelect  from "./TreeSelect";
import { getCategoryByServiceTreeLevel } from "../reducers/serviceTreeReducer";
import { makeUpdateServiceTreePanelRefAction } from "../reducers/actionableItemsReducer";
import styles from "./ServiceTreeNav.less";
import { trackEventCallback } from "../utils/AppInsights";
import { useCategoryFilters } from "../hooks/useFilters";
import { useDispatch } from "react-redux";
import { useFlights } from "../hooks/useSettings";

export interface ServiceTreeNavProps {
    serviceTree: ServiceTree;
    selectedIds: string[];
    onChange: (selectedItems: ServiceTreeItem[]) => void;
}

const outerStackStyles: IStackStyles = {
    root: {
        height: "100%"
    }
};

const titleStackStyles: IStackStyles = {
    root: {
        marginLeft: 16,
        marginRight: 16
    }
};

const titleStyles: ITextStyles = {
    root: {
        fontWeight: 500,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
    }
};

const serviceTreeStyles: IStackStyles = {
    root: {
        height: "100%",
        overflowY: "auto",
        marginLeft: 8
    }
};

const navLink: IStyle = {
    color: "rgb(50, 49, 48)"
};

const buttonStyles: IButtonStyles = { root: { marginRight: 16 } };

const panelStyles: Partial<IPanelStyles> = { scrollableContent: { display: "flex", flexDirection: "column", height:"100%", overflow: "unset" }, content: styles.panelContent };

const ServiceTreeNav: React.FC<ServiceTreeNavProps> = ({ serviceTree, selectedIds, onChange }) => {
    const dispatch = useDispatch();
    const {updateFilters, filters} = useCategoryFilters();
    const navLinkGroups = useMemo(() => createNavLinkGroups(serviceTree, updateFilters, filters), [serviceTree, updateFilters, filters]);
    const handleLinkClick = useCallback((ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
        ev?.preventDefault();
        if (onChange && item) {
            onChange([item.serviceTreeItem]);
        }
    }, [onChange]);
    const {data: flights} = useFlights()
    const treeData = useMemo(() => createTreeData(serviceTree), [serviceTree]);
    const [internalSelectedIds, setInternalSelectedIds] = useState<string[]>(selectedIds);
    const [internalSelectedItems, setInternalSelectedItems] = useState<ServiceTreeItem[] | undefined>(undefined);
    const handleChange = useCallback((values: string[], nodes: DataNode[]) => {
        setInternalSelectedIds(values);
        setInternalSelectedItems(nodes.map(x => x.data));
    }, []);
    const openAndClear = useCallback(() => {
        setInternalSelectedIds(selectedIds);
        setInternalSelectedItems(undefined);
        serviceTreePanelRef.current?.open();
        trackEventCallback(LogComponent.PivotTreePane, LogElement.MultiSelect, "Multi-Select", LogTarget.Button, undefined, EventType.Click);
    }, [selectedIds]);
    const apply = useCallback(() => {
        if (internalSelectedItems) {
            onChange(internalSelectedItems);
        }
        serviceTreePanelRef.current?.dismiss();
    }, [internalSelectedItems, onChange]);
    const handleRenderFooterContent = useCallback(() => (
        <div>
            <PrimaryButton onClick={apply} styles={buttonStyles}>Apply</PrimaryButton>
            <DefaultButton onClick={() => serviceTreePanelRef.current?.dismiss()}>Cancel</DefaultButton>
        </div>
    ), [apply]);
    const serviceTreePanelRef = React.useRef<IPanel>(null);
    useEffect(() => serviceTreePanelRef.current?.dismiss(), []);
    useEffect(() => {dispatch(makeUpdateServiceTreePanelRefAction(serviceTreePanelRef.current))}, [dispatch]);

    return (
        <Stack styles={outerStackStyles}>
            <Stack horizontal horizontalAlign="space-between" styles={titleStackStyles}>
                <Text variant={"medium"} styles={titleStyles}>Service Tree</Text>
                {!flights?.enableCreateView && (
                    <ActionButton onClick={openAndClear}>Multi-Select</ActionButton>
                )}
            </Stack>
            <Stack.Item styles={serviceTreeStyles} >
                <Nav groups={navLinkGroups} onLinkClick={handleLinkClick} styles={{link: navLink}} />
            </Stack.Item>
            <Panel
                componentRef={serviceTreePanelRef}
                styles={panelStyles}
                headerText="Service Tree"
                type={PanelType.medium}
                onRenderFooterContent={handleRenderFooterContent}
                isFooterAtBottom
                defaultChecked={false}
            >
                <TreeSelect
                    treeData={treeData}
                    selectedValues={internalSelectedIds}
                    onChange={handleChange}
                />
            </Panel>
        </Stack>
    );
};

function createNavLinkGroups(serviceTree: ServiceTree, updateFilters: (nextAction: FiltersAction, filters: IFilters) => void, filters: IFilters): INavLinkGroup[] {
    function createLinks(items: ServiceTreeItem[], ancestors: ServiceTreeItem[] = []) {
        const g = new Array<INavLink>(items.length);
        for (let i = 0; i < items.length; ++i) {
            ancestors.push(items[i]);
            g[i] = { name: items[i].n, url: "", key: items[i].id, serviceTreeItem: items[i], onClick: () => updateFilters(FiltersAction.Replace, {filters: {[getCategoryByServiceTreeLevel(items[i].l)]: [items[i].id]}, view: FiltersView.Breadcrumb}) };
            const children = items[i].c;
            if (children) {
                g[i].links = createLinks(children, ancestors);
            }
            if (filters.filters[getCategoryByServiceTreeLevel(items[i].l)]?.includes(items[i].id)) {
                g[i].isSelected = true;
            }
            if (g[i].links?.find(link => link.isExpanded || link.isSelected)) {
                g[i].isExpanded = true;
            }
            ancestors.pop();
        }
        return g;
    }
    return [{ links: createLinks(serviceTree.items) }];
}

function createTreeData(serviceTree: ServiceTree): DataNode[] {
    function getValue(item: ServiceTreeItem) {
        return item.id === "00000000-0000-0000-0000-000000000000" ? `${item.id}(${item.l})` : item.id;
    }

    function createDataNodes(items: ServiceTreeItem[]): DataNode[] {
        const nodes = new Array<DataNode>(items.length);
        for (let i = 0; i < items.length; ++i) {
            const value = getValue(items[i]);
            nodes[i] = { title: items[i].n, key: value, value, data: items[i] };
            const children = items[i].c;
            if (children) {
                nodes[i].children = createDataNodes(children);
            }
        }
        return nodes;
    }

    return createDataNodes(serviceTree.items);
}

export default ServiceTreeNav;