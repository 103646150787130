import { ISubscription, ServiceTreeItem, ServiceTreeLevel } from "../models/serviceTree";

import { CategoryDivision } from "../models/CategoryDivision";
import { IServiceTreeData } from "../reducers/serviceTreeReducer";

export function getServiceTreeBreadcrumb(serviceTreeNode?: ServiceTreeItem): string {
    if (!serviceTreeNode) { return ""; }
    if (serviceTreeNode.l === ServiceTreeLevel.Division) { return serviceTreeNode.n; }
    return getServiceTreeBreadcrumb(serviceTreeNode.p) + " > " + serviceTreeNode.n;
}

export function getServiceTreeOptionsList(
    category: CategoryDivision,
    serviceTree: IServiceTreeData,
    cluster: string[] | undefined,
    subscriptions: ISubscription[] | undefined) {
    switch (category) {
        case CategoryDivision.Owner:
            return [...serviceTree.owners.keys()];
        case CategoryDivision.DataSetV2:
            return [...serviceTree.datasetV2.keys()];
        case CategoryDivision.VdirV2:
            return [...serviceTree.vdirV2.keys()];
        case CategoryDivision.ProcessV2:
            return [...serviceTree.processV2.keys()];
        case CategoryDivision.Division:
            return Array.from(serviceTree.indexMap).filter(item => item[1].l == ServiceTreeLevel.Division).map(item => item[1].n);
        case CategoryDivision.Organization:
            return Array.from(serviceTree.indexMap).filter(item => item[1].l == ServiceTreeLevel.Organization).map(item => item[1].n);
        case CategoryDivision.ServiceGroup:
            return Array.from(serviceTree.indexMap).filter(item => item[1].l == ServiceTreeLevel.ServiceGroup).map(item => item[1].n);
        case CategoryDivision.TeamGroup:
            return Array.from(serviceTree.indexMap).filter(item => item[1].l == ServiceTreeLevel.TeamGroup).map(item => item[1].n);
        case CategoryDivision.ScenarioTagV2:
            return [...serviceTree.scenarioTagV2.keys()];
        case CategoryDivision.GriffinAppV2:
            return [...serviceTree.griffinAppV2.keys()];
        case CategoryDivision.GriffinProcessorV2:
            return [...serviceTree.griffinProcessorV2.keys()];
        case CategoryDivision.StoreClientV2:
            return [...serviceTree.storeClientV2.keys()];
        case CategoryDivision.StoreClientComponentV2:
            return [...serviceTree.storeClientComponentV2.keys()];
        case CategoryDivision.Cluster:
            return cluster || [];
        case CategoryDivision.Subscription:
            return subscriptions?.map(item => item.subscriptionId) || [];
        case CategoryDivision.Service:
            return serviceTree.platformServices;
        case CategoryDivision.PlatformProcess:
            return serviceTree.platformProcesses;
        case CategoryDivision.PlatformApp:
            return serviceTree.platformApps;
        case CategoryDivision.PlatformSubApp:
            return serviceTree.platformSubApps;
        case CategoryDivision.PlatformClient:
            return serviceTree.platformClients;
        case CategoryDivision.PlatformClientComponent:
            return serviceTree.platformClientComponents;
        default:
            return [];
    }
}