import { ActionButton, Separator, Stack } from '@fluentui/react';
import { AzureComputeDetailTableOffset, AzureComputeDetailTableStyles } from './common/DetailTableConstants';
import { AzureCostTableTag, IAzureComputeRegionDetail } from '../../../models/AzureComputeModels';
import { AzureMycroftRegionDetailsColumnNames, AzureMycroftServiceDetailsColumnNames, AzureRegionDetailsColumnNames, AzureRegionDetailsColumnNamesV3, CpuUtilizationRange, RdosCpuBinRange } from '../common/AzureComputeConstants';
import { LogComponent, LogElement, LogTarget } from '../../../models/LogModel';
import React, { useCallback, useEffect } from 'react';
import { difference, isEmpty } from 'lodash';
import { getCellOption, getFooterOption, getHeaderOption, useAzureComputeHeatmapParams, useAzureComputeHeatmapQuery, useDetailTable, useDetailTableColumnSelection } from './common/DetailTableUtils';
import { useAzureSubscriptionSearchInfo, useGetAzureComputeRegionDetail, useGetAzureDailyCpuQuery, useGetRdosCpuBinQuery } from '../../../hooks/useAzureQuery';

import ColumnChart from '../../common/ColumnChart';
import { ColumnSelector } from '../../common/table/ColumnSelector';
import { Endpoints } from '../../../utils/Constants';
import { FilterList } from "../../filter/filterList/FilterList";
import { HeatmapIndicator } from './HeatmapIndicator';
import { IAppState } from '../../../store';
import LineChart from '../../common/LineChart';
import { LineChartContainer } from '../common/LineChartContainer';
import LoadingState from '../../ResponseBoundary/LoadingState';
import { SectionHeader } from '../../common/SectionHeader';
import { ServiceTreeItem } from '../../../models/serviceTree';
import { SingleDatePicker } from '../../common/SingleDatePicker';
import StickyTable from '../../common/table/StickyTable';
import commonStyles from './common/DetailTable.less';
import { downloadAzureComputeRegionHeatmap } from '../../../services/azureComputeService';
import moment from 'moment';
import { some } from 'lodash';
import styles from "../Azure.less";
import { useBillingCost } from '../../../hooks/useBillingCost';
import { useCategoryFilters } from '../../../hooks/useFilters';
import { useDateRange } from '../../../hooks/useDateSelector';
import { useFlights } from '../../../hooks/useSettings';
import { useRemap } from '../../../hooks/useRemap';
import { useBoolean } from "@fluentui/react-hooks";
import { useUserPreference } from '../../../utils/preference/useUserPreference';
import { useSelector } from 'react-redux';
import { useTrackHovering } from '../../../hooks/useTrack';
import { useSpotVM } from '../../../hooks/useSpotVM';

interface IAzureComputeRegionDetailProps {
    type: 'Total' | 'Public' | 'AZSC';
    regions: string[];
    defaultSorting?: string;
}

const RegionDetail: React.FC<IAzureComputeRegionDetailProps> = ({ type, regions, defaultSorting }) => {
    const enableMycroftData = useFlights().data?.enableMycroftData;
    const enableBillingCost = useFlights().data?.enableBillingCost;

    const enableAzureV3 = useFlights().data?.enableV3;
    const columns = React.useMemo(() => {
        const columns = enableAzureV3 ? AzureRegionDetailsColumnNamesV3 :
        (enableMycroftData ? AzureMycroftRegionDetailsColumnNames : AzureRegionDetailsColumnNames);
        if (!enableBillingCost) {
            return columns.filter(x => x !== 'billingCost');
        } else {
            return columns;
        }
    }, [enableBillingCost, enableMycroftData]);
    const heatmapParams = useAzureComputeHeatmapParams(type, columns, regions, defaultSorting, ['serviceName', 'region', 'serviceDevOwners', 'servicePMOwners']);
    const query = useAzureComputeHeatmapQuery(useGetAzureComputeRegionDetail, type, heatmapParams, regions);
    const subscriptions = useAzureSubscriptionSearchInfo();
    const [isDownloadingEnabled, {setFalse: disableDownloading, setTrue: enableDownloading}] = useBoolean(true);

    const [preferedTableConfig, setPreferedTableConfig] = useUserPreference("azureComputeRegionDetail");

    const tableInstance = useDetailTable<IAzureComputeRegionDetail>(
        type,
        AzureCostTableTag.Region,
        columns,
        query.data, query.sort.setSort, query.sort.initialSortDesc, query.sort.initialSortKey || "totalCores",
        preferedTableConfig?.hiddenColumns || []
    );
    const { visibleColumns, changeVisibleColumns, columnSelectorOptions } = useDetailTableColumnSelection(tableInstance);

    useEffect(() => {
        setPreferedTableConfig({ hiddenColumns: difference(columns, visibleColumns) as (keyof IAzureComputeRegionDetail)[] });
    }, [visibleColumns]);

    const {singleDate: date, startDate, endDate} = useDateRange();
    const [showRemap] = useRemap();  
    const [showSpotVM] = useSpotVM();
    const { data: flights } = useFlights();
    const [rdosCpuBinDay, setRdosCpuBinDay] = React.useState(moment().subtract(7, "days"));
    const [cpuUtilizationDay, setCpuUtilizationDay] = React.useState(moment().startOf("date").subtract(7, "days"));

    const rdosCpuBin = useGetRdosCpuBinQuery(Endpoints.GetAzureRdosCpuBin, rdosCpuBinDay, regions);
    const searchFilters = useCategoryFilters().filters.filters;
    const serviceIdMap = useSelector<IAppState, Map<string, ServiceTreeItem>>((state) => state.serviceTree.indexMap);

    const cpuUtilizationSingleDay = useGetAzureDailyCpuQuery(Endpoints.GetAzureDailyCpuUtilization, cpuUtilizationDay, type, regions);
    const trackCpuCoresHoveringProps = useTrackHovering(LogComponent.AzureComputeRegionDetail, LogElement.AzureCpuCoresCountP99, "CPU Utilization - Core Count Histogram (P99)", LogTarget.Chart);

    const handleDownload = useCallback(() => {
        disableDownloading();
        downloadAzureComputeRegionHeatmap(date, heatmapParams.filtersData, searchFilters, serviceIdMap, subscriptions, heatmapParams.sortByKey || "TotalCores", heatmapParams.sortDesc, type, columns, startDate, endDate, showRemap, showSpotVM, flights?.enableDateRange, enableDownloading);
    }, [disableDownloading, date, heatmapParams.filtersData, heatmapParams.sortByKey, heatmapParams.sortDesc, searchFilters, serviceIdMap, subscriptions, type, columns, startDate, endDate, showRemap, showSpotVM, flights?.enableDateRange, enableDownloading]);

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [rdosCpuBin.data, cpuUtilizationSingleDay.data]);

    return (
        <div className={commonStyles.detailTableView}>
            <div>
            {
                type === "Total" && !rdosCpuBin.isLoading && !isEmpty(rdosCpuBin.data) &&
                <div style={{display: "inline-block", boxSizing: "border-box", width: "50%", padding: "0 8px"}}><Stack horizontal horizontalAlign="space-between" styles={{ root: styles.title }}>
                        <h4 className={styles.titleText}>CPU Utilization - Sample Points Count (Rdos)</h4>
                        <SingleDatePicker
                            selectedDate={rdosCpuBinDay}
                            onSelectDate={setRdosCpuBinDay} />
                    </Stack><div className={styles.chart}>
                            {!rdosCpuBin.isLoading && rdosCpuBin.data ?
                                <LineChart
                                    series={RdosCpuBinRange.map(rdosCpuBinSingleSeries => ({
                                        name: rdosCpuBinSingleSeries,
                                        data: rdosCpuBin.data[rdosCpuBinSingleSeries]?.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                                    }))}
                                    isNotCurrency
                                    showArea
                                    keepOrder
                                    ignoreWeekend={false}
                                    tooltipDateFormatStr="hh:mm"
                                    dateFormatStr="hh:mm"
                                    colors={["#5B003B", "#88105E", "#B34F90", "#D696C0", "#E9C4DC", "#A5DEEB", "#60C4DA", "#0099BC", "#0099BC", "#00566A"]}
                                     /> :
                                <LoadingState />}
                        </div></div>
            }
            {
                !cpuUtilizationSingleDay.isLoading && cpuUtilizationSingleDay.data && some(cpuUtilizationSingleDay.data, item => item > 0) &&
                <div style={{display: "inline-block", boxSizing: "border-box", width: "50%", padding: "0 8px"}}><Stack horizontal horizontalAlign="space-between" styles={{ root: styles.title }}>
                    <h4 className={styles.titleText}>CPU Utilization - Core Count Histogram (P99)</h4>
                    <SingleDatePicker
                        selectedDate={cpuUtilizationDay}
                        onSelectDate={setCpuUtilizationDay} />
                </Stack><div className={styles.chart}>
                        {!cpuUtilizationSingleDay.isLoading && cpuUtilizationSingleDay.data ?
                            <ColumnChart
                                seriesHeader="CPU Cores"
                                x={CpuUtilizationRange.map(key => `${key}%`)}
                                y={CpuUtilizationRange.map(key => cpuUtilizationSingleDay.data[key])}
                                containerProps={trackCpuCoresHoveringProps} /> :
                            <LoadingState />}
                    </div></div>
            }
            </div>
            <SectionHeader
                title="Region Details"
                extra={(
                    <Stack horizontal verticalAlign="center" horizontalAlign="end" className={commonStyles.headerExtra}>
                        <HeatmapIndicator />
                        <ActionButton onClick={handleDownload} iconProps={{ iconName: 'Download' }} text={isDownloadingEnabled ? "Download" : "Downloading"} disabled={!isDownloadingEnabled} />
                        <ColumnSelector onConfirm={changeVisibleColumns} columns={columnSelectorOptions} initSelectedKeys={visibleColumns} />
                    </Stack>
                )}
            />
            <FilterList data={query.localFilters.data} options={query.localFilters.options} onChange={query.localFilters.setFilters} />
            <StickyTable
                styles={AzureComputeDetailTableStyles}
                cellOption={getCellOption}
                headerOption={getHeaderOption}
                footerOption={getFooterOption}
                loading={query.isInitialLoading}
                loadMoreText="Show more regions"
                emptyText="The selected services don't have data"
                loadMoreLoadingText={`Loading more ${query.paging.pageSize} regions...`}
                loadMoreLoading={query.isLoadingMore}
                loadMore={query.hasMore}
                onLoadMore={query.paging.loadMore}
                table={tableInstance}
                stickyTarget="#pageContainer"
                stickyPositon={AzureComputeDetailTableOffset}
            />
        </div>
    );
};

RegionDetail.defaultProps = {
    defaultSorting: "TotalCores",
}

export default RegionDetail;
