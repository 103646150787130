
export enum FilterGroupOperator {
    EQ,
    NE,
    GT,
    LT,
    GTE,
    LTE,
    BW,
    EW,
    CN,
    IN,
}

export const FilterGroupOperatorDisplayText: Record<FilterGroupOperator, string> = {
    [FilterGroupOperator.EQ]: '=',
    [FilterGroupOperator.NE]: '!=',
    [FilterGroupOperator.GT]: '>',
    [FilterGroupOperator.LT]: '<',
    [FilterGroupOperator.GTE]: '>=',
    [FilterGroupOperator.LTE]: '<=',
    [FilterGroupOperator.BW]: 'Begins with',
    [FilterGroupOperator.EW]: 'Ends with',
    [FilterGroupOperator.CN]: 'Contains',
    [FilterGroupOperator.IN]: 'In',
}
