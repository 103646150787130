import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey } from "../models/SearchKey";

export function useBillingCost(): [boolean, (useBillingCost?: boolean) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const showBillingCost: boolean = useMemo(() => {
        const billingCost = searchParams.get(SearchKey.UseBillingCost);
        if (billingCost == null || typeof billingCost == 'undefined') {
            return false;
        }
        return billingCost === "true";
    }, [searchParams]);

    const setShowBillingCost = useCallback((useBillingCost?: boolean) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(SearchKey.UseBillingCost, JSON.stringify(useBillingCost));
        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return [showBillingCost, setShowBillingCost];
}