// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HTpR9Lgo7MBiGfIwXs6I:first-child {\n  margin-top: 0;\n}\n.HTpR9Lgo7MBiGfIwXs6I {\n  margin-top: 16px;\n}\n.r8JgWxdHYkQyCCE0fHPm {\n  line-height: 20px;\n}\n.T2DYgga8mRO_2HuTmzeD > div {\n  margin-bottom: 8px;\n}\n.T2DYgga8mRO_2HuTmzeD > div:last-child {\n  margin-bottom: 0px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/substrateV2/common/table/SubstrateCostTableTooltip.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,iBAAA;AADJ;AAIA;EACI,kBAAA;AAFJ;AAKA;EACI,kBAAA;AAHJ","sourcesContent":[".tableHeaderTooltipUnitPrice:first-child {\n    margin-top: 0;\n}\n\n.tableHeaderTooltipUnitPrice {\n    margin-top: 16px;\n}\n\n.tableHeaderTooltipUnitPriceItem {\n    line-height: 20px;\n}\n\n.tableHeaderTooltipDescs > div {\n    margin-bottom: 8px;\n}\n\n.tableHeaderTooltipDescs > div:last-child {\n    margin-bottom: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableHeaderTooltipUnitPrice": "HTpR9Lgo7MBiGfIwXs6I",
	"tableHeaderTooltipUnitPriceItem": "r8JgWxdHYkQyCCE0fHPm",
	"tableHeaderTooltipDescs": "T2DYgga8mRO_2HuTmzeD"
};
export default ___CSS_LOADER_EXPORT___;
