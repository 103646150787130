import * as React from "react";
import ChartContainer from "../../../chart/ChartContainer";
import { SeriesBarOptions } from "highcharts";
import { currencyFormatter } from "../../../../utils/currency";
import {
    ESCostDataDimensions,
    ESCostOrganization,
    ESCostRawUserType,
    ESManagerFYCostRawResponse,
    ESOverviewTimeSelection,
} from "../../../../models/ESCostModels";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import { useEsOrg } from "../../../../hooks/useEsOrg";

interface IHorizontalBarChartProps {
    data: ESManagerFYCostRawResponse[];
    description: string;
    legendDesc: string;
    isCurrency: boolean;
    maxCount?: number;
}

function filterCosts(esManagerCost: ESManagerFYCostRawResponse[], year: number, org?: ESCostOrganization[]): ESManagerFYCostRawResponse[] {
    let managerCosts = esManagerCost.filter(
        (cost) => cost[ESCostDataDimensions.UserType] === ESCostRawUserType.Human && cost[ESCostDataDimensions.ReturnFiscalYear] === year
    );
    if (org !== undefined && org.length > 0) {
        managerCosts = managerCosts.filter((data) => org.includes(data[ESCostDataDimensions.Organization]))
    }
    
    const accumulator: { [name: string]: number } = {};
    managerCosts.forEach(item => {
        const name = item[ESCostDataDimensions.UserName];
        const v = item[ESCostDataDimensions.Cost];
        if (accumulator[name] === undefined) {
            accumulator[name] = v;
        } else {
            accumulator[name] += v;
        }
    });

    return Object.keys(accumulator).map(name => ({
        [ESCostDataDimensions.UserName]: name,
        [ESCostDataDimensions.Cost]: accumulator[name],
    })) as ESManagerFYCostRawResponse[];
}

const TopManagerCostBarChart: React.FC<IHorizontalBarChartProps> = (props) => {
    const dimension = props.isCurrency === false ? ESCostDataDimensions.ArtifactWorkload : ESCostDataDimensions.Cost;

    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );
    const dates = React.useMemo(
        () => (timeRange === ESOverviewTimeSelection.LastFY ? ESCostUtils.getLastFiscalYearDates() : ESCostUtils.getCurrentFiscalYearDates()),
        [timeRange]
    );
    const [org] = useEsOrg();
    const managerCost = React.useMemo(() => filterCosts(props.data, year, org), [props.data, org, year]);
    const sortedData = React.useMemo(() => {
        const l = [...managerCost].sort((a, b) => b[dimension] - a[dimension]);
        if (props.maxCount !== undefined && props.maxCount > 0) {
            return l.slice(0, props.maxCount);
        } else {
            return l;
        }
    }, [managerCost, props.maxCount, dimension]);
    const series = React.useMemo<SeriesBarOptions[]>(() => {
        return [
            {
                type: "bar",
                name: props.legendDesc,
                data: sortedData.map((d) => ({ y: d[dimension] })),
            },
        ];
    }, [props.legendDesc, sortedData, dimension]);
    const managers = React.useMemo(() => sortedData.map((d) => d[ESCostDataDimensions.UserName]), [sortedData]);

    return (
        <ChartContainer
            headerProps={{ title: props.description.concat(" (FY", String(year).substring(2), ")") }}
            chartType="bar"
            chartProps={{
                tooltipProps: {
                    date: [dates[0], dates[1]],
                    noPrefix: props.isCurrency === false,
                },
                yAxis: { labelFormatter: (num: number) => currencyFormatter(num, 2, props.isCurrency ? "$" : "") },
                categories: managers,
                series,
            }}
        />
    );
};

export default TopManagerCostBarChart;
