// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".piTseWBqjZ0joh6RHBys {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n.QBO4KXaBdHJjpE1j_xzW {\n  overflow-y: hidden;\n  overflow-x: hidden;\n  flex: 1 1 80%;\n  height: 100%;\n  border-left: 3px solid #ccc;\n}\n.YtR4mQRzw9a_hRVyKJx4 {\n  width: 100%;\n  overflow: hidden;\n}\n._5hMQkhzDdsjHf8nw67U {\n  height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/components/EfficiencyTrackerV2/PageLayout/TrackerLayout.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;AACJ;AAEA;EACI,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,2BAAA;AAAJ;AAGA;EACI,WAAA;EACA,gBAAA;AADJ;AAIA;EACI,YAAA;EACA,gBAAA;EACA,kBAAA;AAFJ","sourcesContent":[".page {\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n}\n\n.pageContainer {\n    overflow-y: hidden;\n    overflow-x: hidden;\n    flex: 1 1 80%;\n    height: 100%;\n    border-left: 3px solid #ccc\n}\n\n.stack {\n    width: 100%;\n    overflow: hidden;\n}\n\n.navigation {\n    height: 100%;\n    overflow-y: auto;\n    overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "piTseWBqjZ0joh6RHBys",
	"pageContainer": "QBO4KXaBdHJjpE1j_xzW",
	"stack": "YtR4mQRzw9a_hRVyKJx4",
	"navigation": "_5hMQkhzDdsjHf8nw67U"
};
export default ___CSS_LOADER_EXPORT___;
