import { ActionButton, Callout, Checkbox, DirectionalHint, Icon } from "@fluentui/react";
import { EventType, LogComponent, LogElement, LogTarget } from "../../models/LogModel";
import React, { useRef } from "react";

import { SubstrateRings } from "../../models/constants/PcmV2Constants";
import styles from "./RingsFilter.less";
import { trackEventCallback } from "../../utils/AppInsights";
import { useBoolean } from "@fluentui/react-hooks";
import { useRings } from "../../hooks/useRings";

export const RingsFilter: React.FC = () => {
    const buttonRef = useRef<HTMLDivElement>(null);
    const [calloutVisible, { toggle: toggleCalloutVisible, setFalse: hideCallout }] = useBoolean(false);
    const [selectedRings, setSelectedRings] = useRings();

    const handleToggleSelect = (key: string, checked: boolean) => {
        const newSelectedKeys = [...selectedRings];

        if (checked) {
            newSelectedKeys.push(key);
        } else {
            newSelectedKeys.splice(newSelectedKeys.indexOf(key), 1);
        }
        trackEventCallback(LogComponent.PivotHeadPane, LogElement.AllRings, "RingsFilter", LogTarget.Toggle, undefined, EventType.Click);
        setSelectedRings(newSelectedKeys);
    };

    const handleClearAll = () => {
        setSelectedRings([]);
    };

    return (
        <div className={styles.ringSelector}>
            <div className={styles.ringSelectorContent} ref={buttonRef} onClick={toggleCalloutVisible}>
                <Icon iconName="RingsIcon" style={{ color: "#005A9E" }} />
                <span className={styles.ringSelectorContentText}>
                    {!selectedRings.length || selectedRings.length === SubstrateRings.length
                        ? "All Rings"
                        : `${selectedRings.length} Ring${selectedRings.length > 1 ? "s" : ""}`}
                </span>
                <Icon iconName="ChevronDown" className={styles.downIcon} />
            </div>
            {calloutVisible && (
                <Callout directionalHint={DirectionalHint.bottomRightEdge} isBeakVisible={false} target={buttonRef.current} onDismiss={hideCallout}>
                    <div className={styles.ringListWrapper}>
                        <div className={styles.ringList}>
                            {SubstrateRings.map((item) => (
                                <Checkbox
                                    className={styles.ringListItem}
                                    checked={selectedRings.indexOf(item) >= 0}
                                    label={item}
                                    styles={{ label: { width: "100%" } }}
                                    key={item}
                                    id={item}
                                    onChange={(_, checked) => handleToggleSelect(item, !!checked)}
                                />
                            ))}
                        </div>
                        <ActionButton
                            onClick={handleClearAll}
                            primaryDisabled={selectedRings.length === 0}
                            className={styles.clearButton}
                            iconProps={{ iconName: "Cancel" }}
                            disabled={selectedRings.length === 0}
                        >
                            Clear All
                        </ActionButton>
                    </div>
                </Callout>
            )}
        </div>
    );
};
