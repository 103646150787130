import * as React from "react";

import { ActionButton, Label } from "@fluentui/react";
import { CategoryDivision, CategoryDivisionDisplayName } from "../../../models/CategoryDivision";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { useAzureClusterIdsQuery, useAzureSubscriptionsQuery } from "../../../hooks/useServiceTreeQuery";
import { useCallback, useState } from "react";

import DateRangePickerV2 from "../../DateRangePickerV2/DateRangePickerV2";
import { FiltersAction } from "../../../reducers/filterReducer";
import FiltersDropDown from "./FiltersDropDown/FiltersDropDown";
import FiltersPanel from "./FiltersPanel/FiltersPanel";
import { Hidden } from "@mui/material";
import { IAppState } from "../../../store";
import { IServiceTreeData } from "../../../reducers/serviceTreeReducer";
import SaveView from "./SaveView/SaveView";
import ServiceFilter from "./ServiceFilter/ServiceFilter";
import ViewSelector from "./ViewSelector/ViewSelector";
import WorkloadFilter from "./WorkloadFilter/WorkloadFilter";
import { fontSize } from "@mui/system";
import { getServiceTreeOptionsList } from "../../../utils/ServiceTreeUtils";
import moment from "moment";
import styles from "./FiltersBanner.less";
import { trackEventCallback } from "../../../utils/AppInsights";
import { useBoolean } from "@fluentui/react-hooks";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useSelector } from "react-redux";

interface IFiltersBannerProps {
    supportedFilters: CategoryDivision[];
    showServiceFilter?: boolean;
    showViewComponents?: boolean;
    scenario?: string;
}

export const FiltersBanner: React.FC<IFiltersBannerProps> = ({ supportedFilters, showServiceFilter, showViewComponents = true, scenario }) => {
    const [selectedViewId, setSelectedViewId] = useState<number>(-1);
    const [selectedViewName, setSelectedViewName] = useState<string>("Global (Default)");
    const [inputNewViewName, setInputNewViewName] = useState<string>("Global (Default)");
    const [selectedFilters, setSelectedFilters] = useState<CategoryDivision[]>([]);
    const { data: subscriptions } = useAzureSubscriptionsQuery();
    const { data: clusterIds } = useAzureClusterIdsQuery();
    const serviceTree = useSelector<IAppState, IServiceTreeData>((state) => state.serviceTree);
    const { filters: globalFilters, updateFilters, clearAllFiltersAndResetDateRange } = useCategoryFilters();
    const { startDate, endDate, setDateRange } = useDateRange();
    function onViewChange(selectedViewId: number, selectedViewName: string) {
        setSelectedViewId(selectedViewId);
        setSelectedViewName(selectedViewName);
    }


    const onClearAll = useCallback(() => {
        clearAllFiltersAndResetDateRange()
        trackEventCallback(LogComponent.PivotHeadPane, LogElement.ClearAll, "Clear all", LogTarget.Button);
    }, [clearAllFiltersAndResetDateRange]);

    React.useEffect(() => {
        setSelectedFilters([...getSelectedFiltersFromGlobalFilter()]);
    }, [globalFilters.filters, supportedFilters]);

    function getSelectedFiltersFromGlobalFilter() {
        const filters = globalFilters.filters;
        const tempSelectedFilters = new Set<CategoryDivision>([]);
        supportedFilters.forEach((filterKey) => {
            if ((filters[filterKey]?.length || 0) > 0) {
                tempSelectedFilters.add(filterKey);
            }
        });
        selectedFilters.forEach((item) => {
            tempSelectedFilters.add(item);
        });
        return tempSelectedFilters;
    }

    const getPlatformServiceLabel = useCallback(
        (key: string) => {
            return serviceTree.indexMap.get(key)?.n || key;
        },
        [serviceTree.indexMap]
    );

    // A ref to the container element
    const containerRef = React.useRef<HTMLDivElement>(null);
    const fakeDivRef = React.useRef<HTMLDivElement>(null);

    const [visibleFiltersCount, setVisibleFiltersCount] = useState<number>(selectedFilters.length);

    React.useEffect(() => {
        setTimeout(() => {
            calculateVisibleFiltersCount();
        }, 500);
    }, []);

    React.useEffect(() => {
        calculateVisibleFiltersCount();
    }, [selectedFilters, globalFilters.filters]);

    function calculateVisibleFiltersCount() {
        const tempSelectedFilters = getSelectedFiltersFromGlobalFilter();
        let visibleFiltersCount = [...tempSelectedFilters].length;
        if (containerRef.current && fakeDivRef.current) {
            fakeDivRef.current.style.display = "block";
            const current = containerRef.current;
            const children = current.children;
            const maxWidth = current.offsetWidth;
            let childrenWidth = 42;
            for (let i = 0; i <= 3; i++) {
                childrenWidth += children[i].clientWidth;
            }
            for (let i = children.length - 1; i >= children.length - 4; i--) {
                childrenWidth += children[i].clientWidth;
            }
            for (let i = 4; i <= children.length - 5; i++) {
                childrenWidth += children[i].clientWidth;
                if (childrenWidth + 61 >= maxWidth) {
                    visibleFiltersCount = i - 4;
                    break;
                }
            }
            setVisibleFiltersCount(visibleFiltersCount);
            fakeDivRef.current.style.display = "none";
        }
        return visibleFiltersCount;
    }

    const [isFiltersPanelOpen, { setTrue: openFiltersPanel, setFalse: dismissFiltersPanel }] = useBoolean(false);
    const handleDismissFiltersPanel = React.useCallback(
        () => {
            dismissFiltersPanel();
        },
        [dismissFiltersPanel],
      );
      
    function onClickMore() {
        openFiltersPanel();
    }

    const handleSucceedChangeView = useCallback((inputViewName: string) => {
        setInputNewViewName(inputViewName);
    }, []);

    function buildFiltersComponents(divRef?: React.RefObject<HTMLDivElement>, isFake?: boolean) {
        return (
            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} className={styles.layoutFilterBarCard} ref={divRef}>
                {showViewComponents && (
                    <>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "stretch" }}>
                            <Label style={{ fontWeight: 400, whiteSpace: "nowrap" }}>Select view: </Label>
                            <ViewSelector onViewChange={onViewChange} inputViewName={inputNewViewName}/>
                        </div>
                        <div key="splitter1" className={styles.splitter} style={{ marginLeft: 8, marginRight: 8 }} />
                    </>
                )}
                {showServiceFilter && <ServiceFilter />}
                <DateRangePickerV2 />
                {selectedFilters.slice(0, isFake ? selectedFilters.length : visibleFiltersCount).map((filter) => (
                    <WorkloadFilter
                        key={filter}
                        hidden={!selectedFilters.includes(filter)}
                        category={filter}
                        optionList={getServiceTreeOptionsList(filter, serviceTree, clusterIds, subscriptions)}
                        displayName={CategoryDivisionDisplayName[filter]}
                        getCheckboxLabel={filter === CategoryDivision.Service ? getPlatformServiceLabel : undefined}
                    />
                ))}
                {visibleFiltersCount < selectedFilters.length && (
                    <div className={styles.showMoreFilters} onClick={onClickMore}>
                        +{selectedFilters.length - visibleFiltersCount} more
                    </div>
                )}
                <div key="splitter2" className={styles.splitter} style={{ marginLeft: 8, marginRight: 8 }} />
                <div style={{ maxWidth: window.screen.width * window.devicePixelRatio > 1920 ? "20%" : "30%" }}>
                    <FiltersDropDown selectedKeys={selectedFilters} onChange={setSelectedFilters} supportedFilters={supportedFilters} scenario={scenario}/>
                </div>
                <ActionButton key="clear" iconProps={{ iconName: "ClearFilter" }} onClick={onClearAll} style={{ padding: 0 }}>
                    Reset all
                </ActionButton>
                {showViewComponents && <SaveView selectedViewId={selectedViewId} selectedViewName={selectedViewName} onSucceedChangeView={handleSucceedChangeView} />}
            </div>
        );
    }

    return (
        <>
            
            {buildFiltersComponents()}
            <FiltersPanel isPanelOpen={isFiltersPanelOpen} onDismiss={handleDismissFiltersPanel} selectedFilters={selectedFilters} />

            {/* fake div for width calculation */}
            <div style={{ display: "none" }} ref={fakeDivRef}>
                {buildFiltersComponents(containerRef, true)}
            </div>
        </>
    );
};
