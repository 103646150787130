import * as React from "react";
import { useGetAzureDailyMetricsQuery } from "../../../hooks/useAzureQuery";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useBillingCost } from "../../../hooks/useBillingCost";
import { useTrackHovering } from "../../../hooks/useTrack";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { Endpoints } from "../../../utils/Constants";
import { LineChartContainer } from "../common/LineChartContainer";
import { useDateRange } from "../../../hooks/useDateSelector";

const AzureComputeCostChart: React.FC = () => {
    const trackChartHoveringProps = useTrackHovering(LogComponent.Azure, LogElement.AzureCostChart, 'Azure Compute Cost', LogTarget.Chart);
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.AzureCompute);
    const activeAnomalies = React.useMemo(() => getActiveAnomalies(anomaliesQuery.data, ["TotalCores"]), [anomaliesQuery.data]);
    const { startDate, endDate } = useDateRange();
    const [showBillingCost] = useBillingCost();
    const dateRange = [startDate, endDate];
    const publicAzureDailyCost = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, showBillingCost ? "BillingCost" : "VMCost", "Public", "Sum");
    const azscDailyCost = useGetAzureDailyMetricsQuery(Endpoints.GetAzureDailyMetrics, showBillingCost ? "BillingCost" : "VMCost", "AZSC", "Sum");

    if (publicAzureDailyCost.data?.total === 0 && azscDailyCost.data?.total === 0) {
        return null;
    }

    return (
        <LineChartContainer
            title="Azure Compute Cost"
            isLoading={(publicAzureDailyCost.isLoading || azscDailyCost.isLoading)}
            cardProps={[
                {title: "Total Cost", cost: (publicAzureDailyCost.data?.total || 0) + (azscDailyCost.data?.total || 0), color: "#000000", showTooltip: true, date: dateRange},
                {title: "Total Public Cost", cost: publicAzureDailyCost.data?.total || 0, color: "#0099BC", showTooltip: true, date: dateRange},
                {title: "Total AzSC Cost", cost: azscDailyCost.data?.total || 0, color: "#982570", showTooltip: true, date: dateRange},
            ]}
            series={[
                {
                    name: "Public",
                    data: publicAzureDailyCost.data?.data.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                },
                {
                    name: "AzSC",
                    data: azscDailyCost.data?.data.map(singleMetric => [singleMetric.date.valueOf(), singleMetric.metricValue])
                },
            ]}
            fillMissingAbnormalData
            anomalies={activeAnomalies}
            minValue={0}
            containerProps={trackChartHoveringProps}
            logProps={{
                logComponent: LogComponent.AzureTabs,
                logElement: LogElement.AzureCostChart,
            }}
        />
    )
}

export default AzureComputeCostChart;