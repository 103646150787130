import { ActionTypes, RequestStatus } from "./ActionTypes";

import { IFilterView } from "../models/FilterView";

export interface IGetAllViewsAction {
    type: SavedViewsActions.GetAll;
    status?: RequestStatus;
    data?: IFilterView[];
}

export interface IGetAllSubViewsAction {
    type:  ActionTypes.GetAllSubViewDataAction;
    status?: RequestStatus;
    data?: IFilterView[];
}

export interface IUpdateViewAction {
    type: SavedViewsActions.Update;
    expanded: boolean;
    view: IFilterView;
}

export interface IGetViewAction {
    type: SavedViewsActions.Get;
    view: IFilterView;
}

export enum SavedViewsActions {
    Save = "Save",
    Delete = "Delete",
    GetAll = "GetAll",
    Update = "Update",
    Get = "Get",
}