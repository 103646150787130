import React from "react";

const EmptyState: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg fill="none" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="m118.61 99.781-1.987 1.1554c0.575-0.336 0.933-1.0455 0.936-2.0569l1.986-1.155c-3e-3 1.0114-0.358 1.7213-0.935 2.0565z" fill="#D2D2D2" />
            <path d="m117.64 72.516-0.076 26.363c-6e-3 2.037-1.445 2.857-3.217 1.834l-43.548-25.146c-1.7691-1.0226-3.203-3.5017-3.1974-5.5357l0.0761-26.363 49.962 28.847z" fill="#FAFAFA" />
            <path d="m117.64 72.516 1.986-1.1551-0.076 26.363-1.986 1.1522 0.076-26.36z" fill="#D2D2D2" />
            <path d="m67.678 43.669 1.9861-1.1522 49.962 28.845-1.986 1.155-49.962-28.847z" fill="#D2D2D2" />
            <path d="m68.612 35.232 1.9861-1.155c0.5831-0.3381 1.3916-0.293 2.2819 0.2225l-1.9861 1.1551c-0.8902-0.5128-1.6987-0.5607-2.2819-0.2226z" fill="#0078D4" />
            <path d="m117.64 66.136 1.986-1.155-0.02 6.2765-1.983 1.1522 0.017-6.2737z" fill="#005DB7" />
            <path d="m70.894 35.458 1.9861-1.1551 43.547 25.143-1.986 1.155-43.547-25.143z" fill="#0078D4" />
            <path d="m114.44 60.6c1.772 1.0227 3.203 3.5017 3.198 5.5357l-0.017 6.2737-49.965-28.844 0.0169-6.2738c0.0057-2.0339 1.448-2.8565 3.2172-1.8339l43.55 25.143z" fill="#0078D4" />
            <path d="m114.44 60.6c1.772 1.0227 3.203 3.5017 3.198 5.5357l-0.017 6.2737-49.965-28.844 0.0169-6.2738c0.0057-2.0339 1.448-2.8565 3.2172-1.8339l43.55 25.143z" fill="url(#c)" />
            <path d="m114.44 60.6 1.986-1.155c1.772 1.0226 3.204 3.5017 3.198 5.5357l-1.986 1.155c5e-3 -2.034-1.426-4.513-3.198-5.5357z" fill="#0078D4" />
            <path d="m67.567 113.03-0.0891 31.302-27.241-15.727 0.0891-31.302 27.241 15.727z" fill="#0078D4" />
            <path d="m67.567 113.03 27.064-15.729-0.0891 31.304-27.064 15.727 0.0891-31.302z" fill="#185A97" />
            <path d="m40.326 97.299 27.064-15.728 27.241 15.727-27.064 15.729-27.241-15.727z" fill="#0D67AA" />
            <path d="m171 93.311-0.089 31.233-27.181-15.693 0.089-31.232 27.181 15.692z" fill="#185A97" />
            <path d="m171 93.311 27.004-15.694-0.089 31.235-27.004 15.692 0.089-31.233z" fill="#243A5E" />
            <path d="m143.82 77.619 27.004-15.694 27.181 15.692-27.004 15.694-27.181-15.692z" fill="#27446D" />
            <path d="m193.69 186.43-1.986 1.155c0.575-0.335 0.933-1.045 0.935-2.056l1.987-1.155c-3e-3 1.011-0.358 1.721-0.936 2.056z" fill="#D2D2D2" />
            <path d="m192.71 159.17-0.076 26.363c-6e-3 2.037-1.446 2.856-3.218 1.834l-43.547-25.146c-1.769-1.022-3.203-3.501-3.197-5.535l0.076-26.363 49.962 28.847z" fill="#FAFAFA" />
            <path d="m192.71 159.17 1.986-1.155-0.076 26.363-1.986 1.152 0.076-26.36z" fill="#D2D2D2" />
            <path d="m142.75 130.32 1.986-1.152 49.962 28.844-1.986 1.155-49.962-28.847z" fill="#D2D2D2" />
            <path d="m143.68 121.88 1.986-1.155c0.583-0.338 1.392-0.293 2.282 0.223l-1.986 1.155c-0.89-0.513-1.699-0.561-2.282-0.223z" fill="#0078D4" />
            <path d="m192.71 152.78 1.986-1.155-0.02 6.276-1.983 1.153 0.017-6.274z" fill="#005DB7" />
            <path d="m145.97 122.11 1.986-1.155 43.547 25.143-1.986 1.155-43.547-25.143z" fill="#0078D4" />
            <path d="m189.51 147.25c1.772 1.023 3.203 3.502 3.197 5.536l-0.016 6.274-49.965-28.845 0.017-6.273c6e-3 -2.034 1.448-2.857 3.217-1.834l43.55 25.142z" fill="#0078D4" />
            <path d="m189.51 147.25c1.772 1.023 3.203 3.502 3.197 5.536l-0.016 6.274-49.965-28.845 0.017-6.273c6e-3 -2.034 1.448-2.857 3.217-1.834l43.55 25.142z" fill="url(#b)" />
            <path d="m189.51 147.25 1.986-1.155c1.772 1.023 3.203 3.502 3.198 5.536l-1.986 1.155c5e-3 -2.034-1.426-4.513-3.198-5.536z" fill="#0078D4" />
            <path d="m53.012 154.51-1.986 1.155c0.5747-0.335 0.9324-1.045 0.9353-2.057l1.986-1.155c-0.0028 1.012-0.3577 1.721-0.9353 2.057z" fill="#D2D2D2" />
            <path d="m52.038 127.24-0.076 26.363c-0.0057 2.036-1.4452 2.856-3.2172 1.834l-43.547-25.146c-1.7692-1.023-3.2031-3.502-3.1974-5.536l0.07606-26.362 49.962 28.848z" fill="#FAFAFA" />
            <path d="m52.038 127.24 1.9861-1.155-0.0761 26.363-1.9861 1.152 0.0761-26.36z" fill="#D2D2D2" />
            <path d="m2.0767 98.395 1.9861-1.1522 49.962 28.844-1.9861 1.155-49.962-28.847z" fill="#D2D2D2" />
            <path d="m3.0112 89.958 1.9861-1.155c0.58314-0.3381 1.3917-0.293 2.2819 0.2225l-1.9861 1.155c-0.89022-0.5127-1.6987-0.5606-2.2819-0.2225z" fill="#0078D4" />
            <path d="m52.038 120.86 1.9861-1.155-0.0197 6.276-1.9833 1.152 0.0169-6.273z" fill="#005DB7" />
            <path d="m5.293 90.183 1.9861-1.155 43.547 25.143-1.9861 1.155-43.547-25.143z" fill="#0078D4" />
            <path d="m48.84 115.33c1.772 1.023 3.2031 3.502 3.1974 5.536l-0.0169 6.274-49.965-28.845 0.01691-6.2737c0.00563-2.034 1.448-2.8566 3.2172-1.8339l43.55 25.143z" fill="#0078D4" />
            <path d="m48.84 115.33c1.772 1.023 3.2031 3.502 3.1974 5.536l-0.0169 6.274-49.965-28.845 0.01691-6.2737c0.00563-2.034 1.448-2.8566 3.2172-1.8339l43.55 25.143z" fill="url(#a)" />
            <path d="m48.84 115.33 1.9861-1.155c1.772 1.023 3.2031 3.502 3.1975 5.536l-1.9861 1.155c0.0056-2.034-1.4255-4.513-3.1975-5.536z" fill="#0078D4" />
            <path d="m121.94 166.7-0.09 31.301-27.24-15.727 0.0891-31.301 27.241 15.727z" fill="#7FF0FF" />
            <path d="m121.94 166.7 27.064-15.728-0.089 31.303-27.064 15.727 0.089-31.302z" fill="#51DAF9" />
            <path d="m94.702 150.97 27.064-15.728 27.241 15.727-27.064 15.728-27.241-15.727z" fill="#55E9FD" />
            <defs>
                <linearGradient id="c" x1="92.648" x2="92.648" y1="35.018" y2="72.41" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#69E2FF" offset="0" />
                    <stop stopColor="#fff" stopOpacity="0" offset="1" />
                </linearGradient>
                <linearGradient id="b" x1="167.72" x2="167.72" y1="121.67" y2="159.06" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#69E2FF" offset="0" />
                    <stop stopColor="#fff" stopOpacity="0" offset="1" />
                </linearGradient>
                <linearGradient id="a" x1="27.047" x2="27.047" y1="89.744" y2="127.14" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#69E2FF" offset="0" />
                    <stop stopColor="#fff" stopOpacity="0" offset="1" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default EmptyState;