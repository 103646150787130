import * as React from "react";
import { Stack } from "@fluentui/react";
import { outerStackStyles, filterStackStyles } from "../ViewsCommonLayout";
import CloudBuildOverviewFilterBar from "../../Filter/CloudBuildOverviewFilterBar";
import CloudBuildOverviewPage from "./CloudBuildOverviewPage";

export const CloudBuildOverviewContainer: React.FC = () => {
    return (
        <Stack styles={outerStackStyles} disableShrink>
            <Stack horizontal styles={filterStackStyles}>
                <CloudBuildOverviewFilterBar />
            </Stack>
            <CloudBuildOverviewPage />
        </Stack>
    );
};