import * as React from "react";
import styles from "./OverviewPage.less";
import ResponseBoundary from "../../ResponseBoundary/ResponseBoundary";
import { useGetOverviewFYBudget, useGetOverviewFYCost, useGetOverviewMonthlyCost } from "../../../hooks/useES365CostQuery";
import YearToYearStackChart from "../Charts/YearToYearStackChart";
import MonthlyTrendingChart from "../Charts/MonthlyTrendingChart";
import Last2MonthComparisonChart from "../Charts/Last2MonthComparisonChart";
import PageWrapper from "./PageWrapper";
import OverviewChart from "../Charts/OverviewChart";

const OverviewPage: React.FC = () => {
    const { data: esFYCost, isLoading, isError } = useGetOverviewFYCost();
    const { data: esMonthlyCost, isLoading: isMonthlyCostLoading, isError: isMonthlyCostError } = useGetOverviewMonthlyCost();
    const { data: esFYBudget, isLoading: isFYBudgetLoading, isError: isFYBudgetError } = useGetOverviewFYBudget();
    return (
        <PageWrapper
            title="ES365 Cost Overview"
            description="The overall views for ES365 resource cost are beneficial to understand the cost better, seek opportunities to reduce the cost as well as making budget planning accordingly."
        >
            <div className={styles.grid}>
                <ResponseBoundary isLoading={isLoading} errorHappened={isError} data={esFYCost}>
                    {esFYCost !== undefined ? <OverviewChart data={esFYCost} /> : <></>}
                </ResponseBoundary>
                <ResponseBoundary isLoading={isLoading} errorHappened={isError} data={esFYCost}>
                    {esFYCost !== undefined && esFYBudget !== undefined ? <YearToYearStackChart data={esFYCost} budget={esFYBudget} /> : <></>}
                </ResponseBoundary>
            </div>
            <div className={styles.grid}>
                <ResponseBoundary isLoading={isMonthlyCostLoading} errorHappened={isMonthlyCostError} data={esMonthlyCost}>
                    {esMonthlyCost !== undefined ? <Last2MonthComparisonChart data={esMonthlyCost} /> : <></>}
                </ResponseBoundary>
                <ResponseBoundary
                    isLoading={isMonthlyCostLoading || isFYBudgetLoading}
                    errorHappened={isMonthlyCostError || isFYBudgetError}
                    data={esMonthlyCost}
                >
                    {esMonthlyCost !== undefined && esFYBudget !== undefined ? (
                        <MonthlyTrendingChart data={esMonthlyCost} budget={esFYBudget} fixedToCurrentFY={true} />
                    ) : (
                        <></>
                    )}
                </ResponseBoundary>
            </div>
        </PageWrapper>
    );
};

export default OverviewPage;
