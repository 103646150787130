import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { SearchKey } from "../models/SearchKey";
import { ESOverviewTimeSelection } from "../models/ESCostModels";

export function useEsOverviewTimeRange(): [ESOverviewTimeSelection | undefined, (newTimeRange?: ESOverviewTimeSelection) => void] {
    const [searchParams, setSearchParams] = useSearchParams();

    const timeRange: ESOverviewTimeSelection | undefined = useMemo(() => {
        const range = searchParams.get(SearchKey.ESOverviewTimeRange);
        if (!range) {
            return undefined;
        }
        return range as ESOverviewTimeSelection;
    }, [searchParams]);

    const setTimeRange = useCallback((newTimeRange?: ESOverviewTimeSelection) => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (!newTimeRange) {
            newSearchParams.delete(SearchKey.ESOverviewTimeRange);
        } else {
            newSearchParams.set(SearchKey.ESOverviewTimeRange, newTimeRange);
        }

        setSearchParams(newSearchParams);
    }, [searchParams, setSearchParams]);

    return [timeRange, setTimeRange];
}