import * as React from "react";

import CostCard from "../common/costCard/CostCard";
import LineChart from "../common/LineChart";
import { SubstratePlatformPivotEnum } from "../../models/SubstratePlatform";
import { currencyFormatter } from "../../utils/currency";
import { reduce } from "lodash";
import styles from "./SubstratePlatformChart.less";
import { useGetSubstratePlatformDailyReportQuery } from "../../hooks/useSubstratePlatformQuery";

const PlatformColors = [
    "#0099BC",
    "#77004D",
    "#4F6BED",
    "#AE8C00",
    "#004E8C",
    "#E43BA6"
];

export interface ISubstratePlatformChartProps {
    pivotBy: SubstratePlatformPivotEnum;
}

export const SubstratePlatformChart: React.FC<ISubstratePlatformChartProps> = (props) => {

    const { data: dailyReports, isLoading } = useGetSubstratePlatformDailyReportQuery(props.pivotBy, false);
    
    const totalCost = React.useMemo(() => reduce(dailyReports, (acc, cur) => acc + (cur.total || 0), 0), [dailyReports]);

    return (
        <div>
            <div className={styles.chartContainer}>
                {
                    dailyReports && !isLoading && (
                        <>
                            <div className={styles.totalCost}><span className={styles.totalValue}>{currencyFormatter(totalCost)}</span> Total Cost</div>
                            <div className={styles.chartTitle}>Daily Cost trend</div>
                            <LineChart
                                series={dailyReports.map(report => ({
                                    name: report.metric,
                                    data: report.data.map(singleDayMetric => [singleDayMetric.date.valueOf(), singleDayMetric.metricValue])
                                }))}
                                totalTextPrefix="Total Costs: "
                                colors={PlatformColors}
                                height={300}
                            />
                        </>
                    )
                }
                
            </div>
        </div>
    );
}