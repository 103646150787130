// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RRaij2cEVLRkjzN1SXOx {\n  display: grid;\n  grid-template-columns: 40% 60%;\n  column-gap: 40px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ESCost/Views/OverviewPage.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;AACJ","sourcesContent":[".grid {\n    display: grid;\n    grid-template-columns: 40% 60%;\n    column-gap: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": "RRaij2cEVLRkjzN1SXOx"
};
export default ___CSS_LOADER_EXPORT___;
