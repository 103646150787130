// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrbBeimyBnR0uhXvqSxt {\n  width: 4px;\n  margin-right: 8px;\n  min-width: 4px;\n}\n.Q7esk8JXUzk4_70ewoeK {\n  position: absolute;\n  right: -21px;\n  bottom: 2px;\n  color: #D83B01;\n}\n.sIBQRctz0L9U_zyuAELu {\n  position: relative;\n  display: inline-block;\n}\n.OJPuWxHbwBJ3FCb2kEZN {\n  font-size: 12px;\n  font-weight: 400px;\n}\n.QVmUThQIcDvGF_6ksTIY {\n  font-size: 16px;\n  font-weight: 700;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/costCard/CostCard.less"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,iBAAA;EACA,cAAA;AACJ;AAEA;EACI,kBAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;AAAJ;AAGA;EACI,kBAAA;EACA,qBAAA;AADJ;AAIA;EACI,eAAA;EACA,kBAAA;AAFJ;AAKA;EACI,eAAA;EACA,gBAAA;AAHJ","sourcesContent":[".bar {\n    width: 4px;\n    margin-right: 8px;\n    min-width: 4px;\n}\n\n.abnormalIcon {\n    position: absolute;\n    right: -21px;\n    bottom: 2px;\n    color: #D83B01;\n}\n\n.costContent {\n    position: relative;\n    display: inline-block;\n}\n\n.costTitle {\n    font-size: 12px;\n    font-weight: 400px;\n}\n\n.costValue {\n    font-size: 16px;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": "wrbBeimyBnR0uhXvqSxt",
	"abnormalIcon": "Q7esk8JXUzk4_70ewoeK",
	"costContent": "sIBQRctz0L9U_zyuAELu",
	"costTitle": "OJPuWxHbwBJ3FCb2kEZN",
	"costValue": "QVmUThQIcDvGF_6ksTIY"
};
export default ___CSS_LOADER_EXPORT___;
