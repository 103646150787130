// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GlSZIw0abxrZJ7IQeCCg {\n  padding: 20px;\n  box-shadow: 0px 4px 4px 0px #00000040;\n}\n.dNHbcJ8L4buX_8oTaGvI {\n  font-family: 'Segoe UI', sans-serif;\n  font-size: 20px;\n  font-weight: 700;\n  line-height: 28px;\n  letter-spacing: 0em;\n  text-align: left;\n  margin-bottom: 50px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/FiltersBanner/FiltersPanel/FiltersPanel.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qCAAA;AACJ;AAEA;EACI,mCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AAAJ","sourcesContent":[".container {\n    padding: 20px;\n    box-shadow: 0px 4px 4px 0px #00000040;\n}\n\n.title {\n    font-family: 'Segoe UI', sans-serif;\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 28px;\n    letter-spacing: 0em;\n    text-align: left;\n    margin-bottom: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GlSZIw0abxrZJ7IQeCCg",
	"title": "dNHbcJ8L4buX_8oTaGvI"
};
export default ___CSS_LOADER_EXPORT___;
