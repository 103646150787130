import * as React from "react";
import ChartContainer from "../../../chart/ChartContainer";
import { SeriesBarOptions } from "highcharts";
import { currencyFormatter } from "../../../../utils/currency";
import {
    ESCostDataDimensions,
    ESCostOrganization,
    ESAzureRegionSubscriptionServiceFYCostRawResponse,
    ESOverviewTimeSelection,
} from "../../../../models/ESCostModels";
import { useEsOverviewTimeRange } from "../../../../hooks/useEsOverviewTimeRange";
import { ESCostUtils } from "../../Utilities/ESCostUtils";
import { useEsOrg } from "../../../../hooks/useEsOrg";
import { SubscriptionNameMapping } from "../../Views/Azure/AzureDetailsPage";

export type TargetDimensions = ESCostDataDimensions.Region | ESCostDataDimensions.SubscriptionId | ESCostDataDimensions.Service;

interface IHorizontalBarChartProps {
    data: ESAzureRegionSubscriptionServiceFYCostRawResponse[];
    description: string;
    targetDimension: TargetDimensions;
    top: number;
    subscriptionMapping?: SubscriptionNameMapping;
}

function filterCosts(
    cost: ESAzureRegionSubscriptionServiceFYCostRawResponse[],
    year: number,
    targetDimension: TargetDimensions,
    org?: ESCostOrganization[]
): ESAzureRegionSubscriptionServiceFYCostRawResponse[] {
    let filteredCosts = cost.filter((cost) => cost[ESCostDataDimensions.ReturnFiscalYear] === year);
    if (org !== undefined && org.length > 0) {
        filteredCosts = filteredCosts.filter((data) => org.includes(data[ESCostDataDimensions.Organization]));
    }

    const accumulator: { [target: string]: number } = {};
    filteredCosts.forEach((item) => {
        const target = item[targetDimension];
        if (target !== undefined) {
            const v = item[ESCostDataDimensions.Cost];
            if (accumulator[target] === undefined) {
                accumulator[target] = v;
            } else {
                accumulator[target] += v;
            }
        }
    });

    return Object.keys(accumulator).map((target) => ({
        [targetDimension]: target,
        [ESCostDataDimensions.Cost]: accumulator[target],
        [ESCostDataDimensions.Organization]: ESCostOrganization.OfficeEngineering, // Hardcode
        [ESCostDataDimensions.ReturnFiscalYear]: year,
    })) as ESAzureRegionSubscriptionServiceFYCostRawResponse[];
}

export function getSortedAzureFYCost(
    data: ESAzureRegionSubscriptionServiceFYCostRawResponse[],
    year: number,
    org: ESCostOrganization[],
    top: number,
    targetDimension: TargetDimensions
): ESAzureRegionSubscriptionServiceFYCostRawResponse[] {
    const filteredCosts = filterCosts(data, year, targetDimension, org);
    const sortedData = [...filteredCosts].sort((a, b) => b[ESCostDataDimensions.Cost] - a[ESCostDataDimensions.Cost]);
    return sortedData.slice(0, top);
}

const TopAzureCostBarChart: React.FC<IHorizontalBarChartProps> = (props) => {
    const [timeRange] = useEsOverviewTimeRange();
    const year = React.useMemo(
        () =>
            (timeRange === ESOverviewTimeSelection.LastFY
                ? ESCostUtils.getLastFiscalYearDates()[0].year()
                : ESCostUtils.getCurrentFiscalYearDates()[0].year()) + 1,
        [timeRange]
    );
    const dates = React.useMemo(
        () => (timeRange === ESOverviewTimeSelection.LastFY ? ESCostUtils.getLastFiscalYearDates() : ESCostUtils.getCurrentFiscalYearDates()),
        [timeRange]
    );
    const [org] = useEsOrg();
    const sortedData = React.useMemo(
        () => getSortedAzureFYCost(props.data, year, org, props.top, props.targetDimension),
        [props.data, org, year, props.top, props.targetDimension]
    );
    const series = React.useMemo<SeriesBarOptions[]>(() => {
        return [
            {
                type: "bar",
                name: "Azure cost",
                data: sortedData.map((d) => ({ y: d[ESCostDataDimensions.Cost] })),
            },
        ];
    }, [sortedData]);
    const categories = React.useMemo(
        () =>
            sortedData
                .map((d) => {
                    if (props.targetDimension === ESCostDataDimensions.SubscriptionId && props.subscriptionMapping !== undefined) {
                        const id = d[props.targetDimension];
                        if (id !== undefined && id in props.subscriptionMapping) {
                            return props.subscriptionMapping[id];
                        }
                    }
                    return d[props.targetDimension];
                })
                .filter((d) => d !== undefined) as string[],
        [sortedData, props.targetDimension, props.subscriptionMapping]
    );

    return (
        <ChartContainer
            headerProps={{ title: `${props.description} (FY${String(year).substring(2)})` }}
            chartType="bar"
            chartProps={{
                tooltipProps: {
                    date: [dates[0], dates[1]],
                    noPrefix: false,
                },
                yAxis: { labelFormatter: (num: number) => currencyFormatter(num, 2, "$") },
                categories: categories,
                series,
            }}
        />
    );
};

export default TopAzureCostBarChart;
