import ChartContainer, { ILineChartContainerProps } from "../../chart/ChartContainer";
import { EventType, LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { PcmV2MetricKeys, PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum } from "../../../models/PcmV2Models";
import { PcmV2MetricNames, PcmV2SceneMetrics, PcmV2SubSceneMetrics, SubstrateLogComponent } from "../../../models/constants/PcmV2Constants";
import React, { useMemo } from "react";

import CommonConstants from "../../../models/constants/CommonConstants";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { LineChartSeries } from "../../common/LineChart";
import { getActiveAnomalies } from "../../../utils/AnomalyDetectionUtils";
import { sum } from "lodash";
import { trackEventCallback } from "../../../utils/AppInsights";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import { useGetPcmV2CostByCategory } from "../../../hooks/useSubstrateV2Query";
import { useTrackHovering } from "../../../hooks/useTrack";

export interface ISubstrateCategoryCostTrends {
    scene: PcmV2SceneTypeEnum;
    subScene?: PcmV2SubSceneTypeEnum;
}

const CategoryCostTitle: Partial<Record<PcmV2MetricKeys, string>> = {
    itemReadCost: "Total Item Read Cost",
    itemWriteCost: "Total Item Write Cost",
    itemQueryCost: "Total Item Query Cost",
    ssdKvCacheCost: "Total KvCache Cost",
    ssdmcdbCost: "Total MCDB Cost",
    logBytesCost: "Total Log Bytes Cost",
    bytesReturnedCost: "Total Bytes Returned Cost",
};

const CategoryChartTitle: Partial<Record<PcmV2SceneTypeEnum | PcmV2SubSceneTypeEnum, string>> = {
    [PcmV2SceneTypeEnum.Overview]: "Substrate Cost Category Trends",
    [PcmV2SceneTypeEnum.Transaction]: "Item Reads, Writes, Queries Cost Trends",
    [PcmV2SceneTypeEnum.SSD]: "SSD-Storage MCDB and KvCache Cost Trends",
    [PcmV2SubSceneTypeEnum.SSDDatasetCost]: "SSD-Storage MCDB Cost Trends",
    [PcmV2SubSceneTypeEnum.SSDMailboxCost]: "SSD-Storage MCDB Cost Trends",
    [PcmV2SceneTypeEnum.Network]: "Network Cost Trends",
};

export const SubstrateCategoryCostTrends: React.FC<ISubstrateCategoryCostTrends> = (props) => {
    const query = useGetPcmV2CostByCategory();
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.SubstrateV2);
    const metrics = props.subScene ? PcmV2SubSceneMetrics[props.subScene] : PcmV2SceneMetrics[props.scene];
    const trackingProps = useTrackHovering(LogComponent.SubstrateChartingPane, LogElement.SubstrateCategoryCostTrends, "Substrate Category Cost Trends", LogTarget.Chart);

    const series = useMemo<LineChartSeries[]>(() => {
        if (!query.data || !metrics) {
            return [];
        }

        return metrics.map((metric, index) => ({
            name: PcmV2MetricNames[metric] || metric,
            color: CommonConstants.DefaultColors[index],
            data: query.data[metric]?.map((item) => [item.extractionDate.valueOf(), item.cost]) || [],
            showDeviation: true,
            anomalies: getActiveAnomalies(anomaliesQuery.data, [metric]),
        }));
    }, [props.scene, props.subScene, query.data, anomaliesQuery.data]);
    const dateRange = useDateRange();

    const cards = useMemo<ILineChartContainerProps["cards"]>(() => {
        if (query.data && props.scene !== PcmV2SceneTypeEnum.Overview) {
            return metrics?.map((metric, index) => ({
                cost: sum(query.data?.[metric]?.map((item) => item.cost || 0)),
                title: CategoryCostTitle[metric] || "-",
                color: CommonConstants.DefaultColors[index],
                showTooltip: true,
                date: [dateRange.startDate, dateRange.endDate]
            }));
        }

        return undefined;
    }, [dateRange.endDate, dateRange.startDate, metrics, props.scene, query.data]);

    return (
        <ChartContainer
            headerProps={{ title: CategoryChartTitle[props.subScene || props.scene] || "-" }}
            loading={query.isLoading}
            cards={cards}
            chartType="line"
            chartProps={{
                height: 240,
                series,
                hideEmptySeries: true,
                anomalies: getActiveAnomalies(anomaliesQuery.data, metrics),
                containerProps: trackingProps,
            }}
            logProps={{
                logComponent: SubstrateLogComponent[props.scene],
                logElement: LogElement.Category,
            }}
        />
    );
};
