import { IPanel, ISearchBox } from "@fluentui/react";
import { Reducer } from "redux";
import { ActionTypes } from "../actions/ActionTypes";
import { IUpdateSearchRefAction, IUpdateServiceTreePanelRefAction } from "../actions/ComponentRefActions";

export interface IActionableItemsData {
    searchBox?: ISearchBox;
    serviceTreePanel?: IPanel;
}

export const actionableItemsReducer: Reducer<IActionableItemsData, IUpdateSearchRefAction | IUpdateServiceTreePanelRefAction> = function(state = {}, action): IActionableItemsData {
    switch(action.type) {
        case ActionTypes.UpdateSearchBoxRef:
            return {...state, searchBox: action.ref};
        case ActionTypes.UpdateServiceTreePanelRef:
            return {...state, serviceTreePanel: action.ref}
        default:
            return state;
    }
}

export function makeUpdateSearchBoxRefAction(ref: ISearchBox | null): IUpdateSearchRefAction {
    return {
        type: ActionTypes.UpdateSearchBoxRef,
        ref: ref || undefined
    };
}

export function makeUpdateServiceTreePanelRefAction(ref: IPanel | null): IUpdateServiceTreePanelRefAction {
    return {
        type: ActionTypes.UpdateServiceTreePanelRef,
        ref: ref || undefined
    };
}