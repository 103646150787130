/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable prefer-const */

import * as React from "react";

import ChartContainer from "../ChartContainer/ChartContainer";
import Highcharts from "highcharts";
import { ISubstrateRingData } from "../../../models/SubstrateModels";
import { currencyFormatter } from "../../../utils/currency";
import styles from "./SubstrateRingsChart.less";
import { useId } from "@fluentui/react-hooks";
import { getDateSeriesByRange, isWeekend } from "../../../utils/DateUtils";
import CostCardTooltipContent from "../../common/costCard/CostCardTooltipContent";
import { getAnomalyDescription, getAnomaliesByDate, getAnomalyLegendPlaceholderSeries, getAnomalySeries } from "../../../utils/AnomalyDetectionUtils";
import { renderToString } from "react-dom/server";
import moment from "moment";
import { useGetAnomaliesByDataSourceQuery } from "../../../hooks/useNotificationQuery";
import CommonConstants from "../../../models/constants/CommonConstants";
import chartStyles from '../../common/Chart.less';
import { useDateRange } from "../../../hooks/useDateSelector";
import { useTrackHovering } from "../../../hooks/useTrack";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import HighchartsReact from "highcharts-react-official";
import { DataSourceEnum } from "../../../models/NotificationModels";
import { onChartSeriesLegendClick } from "../../../utils/chartOptions";

interface ISubstrateRingsProps {
    title: string;
    data?: ISubstrateRingData;
}

const SubstrateRingsChart: React.FC<ISubstrateRingsProps> = (props) => {
    const chartSeriesId = useId("substrate-rings-chart");
    const dateRange = useDateRange();
    const rings: string[] = [];
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    for (let ring in props.data!) {
        rings.push(ring);
    }

    const categories = getDateSeriesByRange(dateRange.startDate, dateRange.endDate);
    const anomaliesQuery = useGetAnomaliesByDataSourceQuery(DataSourceEnum.Substrate);
    const anomalies = anomaliesQuery.data?.filter(item => item.status != 'Resolved' && !item.affectedMetrics.includes("SubstrateCarbonEmissions")) || [];

    const trackRingChartHoveringProps = useTrackHovering(LogComponent.Substrate, LogElement.SubstrateRingCost, props.title, LogTarget.Chart);

    const options: Highcharts.Options = React.useMemo(() => {
        const series: Highcharts.Options['series'] = [];

        let index = 0;

        for (let ring of rings) {
            const ringDataPoints: [moment.Moment, number?][] = [];

            categories.forEach((curDate) => {
                const ringData = props.data![ring] || [];
                const dailyData = ringData.find(data => curDate.isSame(data[0], 'date'));

                if (!dailyData || isWeekend(curDate)) {
                    ringDataPoints.push([curDate, undefined]);
                } else {
                    ringDataPoints.push([curDate, dailyData[1]])
                }
            });

            if (!ringDataPoints.find(([, val]) => val != undefined)) {
                continue;
            }

            series.push(...getAnomalySeries(anomalies, ringDataPoints, {
                name : ring,
                color : CommonConstants.DefaultColors[index++],
            }));
        }

        if (anomalies.length) {
            series.push(getAnomalyLegendPlaceholderSeries());
        }

        return {
            chart: {
                style: {
                    fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
                    fontSize: "10px",
                    fontWeight: "semibold"
                }
            },
            title: {
                text: undefined
            },
            credits: {
                enabled: false
            },
            tooltip: {
                shape: "square",
                borderWidth: 0,
                borderRadius: 0,
                backgroundColor: "white",
                padding: 12,
                useHTML: true,
                shared: true,
                outside: true,
                formatter: function() {
                    const abnormalEvents = getAnomaliesByDate(anomalies, moment(this.x));
                    const visiblePoints = this.points?.filter(point => !point.series.name.startsWith('hidden')) || [];

                    return renderToString(
                        <CostCardTooltipContent
                            items={visiblePoints.map(point => ({ title: point.series.name, cost: point.y || 0, color: (point.series as any).color }))}
                            date={[moment(this.x)]}
                            errors={
                                abnormalEvents.map(event => ({
                                    title: event.category,
                                    message: getAnomalyDescription(event.category, event.affectedMetrics, event.startDate, event.endDate),
                                }))
                            }
                            errorCollapseLimit={0}
                        />);
                }
            },
            legend: {
                align: "left",
                squareSymbol: true,
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 0,
                className: anomalies.length > 0 ? chartStyles.abnormalChartLegend : undefined,
            },
            plotOptions: {
                series: {
                    events: {
                        legendItemClick: onChartSeriesLegendClick,
                    }
                }
            },
            xAxis: {
                categories: categories.map(item => item.format(CommonConstants.DateFormatterString)),
                minTickInterval: categories.length / 5,
                labels: {
                    formatter: function() {
                        return this.value.toString().substring(0, 5);
                    }
                }
            },
            yAxis: {
                title: {
                    text: undefined
                },
                plotLines:[
                ],
                labels: {
                    formatter: function() {
                        if (typeof(this.value) === "string") {
                            return this.value;
                        }
                        return currencyFormatter(this.value);
                    }
                }
            },
            series,
        };
    }, [props.data, chartSeriesId, anomaliesQuery.data]);

    return (
        <ChartContainer title={props.title}>
            <HighchartsReact highcharts={Highcharts} options={options} containerProps={trackRingChartHoveringProps} />
        </ChartContainer>
    )
}

export default SubstrateRingsChart;