import { Caption1, Card, CardHeader, Text } from "@fluentui/react-components";
import { CosmicSourceTypeEnum, CosmicSourceTypeName } from '../../models/CosmicModels';
import PageHeader, { IPageHeaderProps } from '../common/PageHeader';
import { PeopleQueueFilled, PeopleTeamToolboxFilled } from "@fluentui/react-icons";
import PartnerView from "./PartnerView";
import React from 'react';
import styles from './CosmicView.less';
import { useGetBannersByTabAndDateQuery } from '../../hooks/useBannerQuery';
import { useNavigate } from 'react-router-dom';

const CosmicViewHeadersProps: Record<CosmicSourceTypeEnum, IPageHeaderProps> = {
    [CosmicSourceTypeEnum.All]: {
        title: "Cosmic L&P",
        description: "Please select the view you would like to see.",
        link: "",
        linkText: "",
    },
    [CosmicSourceTypeEnum.LeadershipView]: {
        title: "Cosmic - Leadership View",
        description: "Below you will find details relevant to the Leadership statistics and information of Cosmic for the specified time period.",
        link: "",
        linkText: "",
        tag: "PREVIEW",
    },
    [CosmicSourceTypeEnum.PartnerView]: {
        title: "Cosmic - Partner View",
        notification: "",
        description: "Below you will find details relevant to the Partner statistics and information of Cosmic for the specified time period. ",
        link: "",
        linkText: "",
        tag: "PREVIEW",
    }
};

interface ICosmicViewProps {
    type: CosmicSourceTypeEnum
}

const CosmicView: React.FC<ICosmicViewProps> = (props) => {
    const { data: allBanners } = useGetBannersByTabAndDateQuery(CosmicSourceTypeName[props.type].toString());
    const navigate = useNavigate();

    return (
        <div className={styles.cosmicView}>
            <PageHeader {...CosmicViewHeadersProps[props.type]} className={styles.header} />
            <div>
                {
                    props.type === CosmicSourceTypeEnum.All &&
                    <div className={styles.cardContainer}>
                        <Card appearance="filled" className={styles.card} onClick={() => { navigate("/COSMIC/Partner") }}>
                            <CardHeader
                                header={<Text weight="semibold">Partner View</Text>}
                                description={<Caption1 className={styles.caption}>COSMIC</Caption1>}
                                image={<PeopleQueueFilled className={styles.icon} />}
                            />
                            <p className={styles.text}>
                                Details relevant to the Partner statistics and information of Cosmic.
                            </p>
                        </Card>
                        <Card appearance="filled" className={styles.card} onClick={() => { navigate("/COSMIC/Leadership") }}>
                            <CardHeader
                                header={<Text weight="semibold">Leadership View</Text>}
                                description={<Caption1 className={styles.caption}>COSMIC</Caption1>}
                                image={<PeopleTeamToolboxFilled className={styles.icon} />}
                            />
                            <p className={styles.text}>
                                Details relevant to the Leadership statistics and information of Cosmic.
                            </p>
                        </Card>
                    </div>
                }
            </div>
            <div>
                {
                    props.type === CosmicSourceTypeEnum.PartnerView && < PartnerView type={props.type} />
                }
            </div>
            {/*<div>*/}
            {/*    {*/}
            {/*        props.type === CosmicSourceTypeEnum.LeadershipView &&*/}
            {/*        <div className={styles.cardContainer}>*/}
            {/*            <MessageBar intent="info" style={{ marginBottom: "24px" }}>*/}
            {/*                <MessageBarBody>*/}
            {/*                    <MessageBarTitle>Important</MessageBarTitle>*/}
            {/*                        Inaccurate or missing data? Feature request? Contact*/}
            {/*                    <Link href="mailto:cosmicjawsfeaturecrew@service.microsoft.com" target="__blank"> cosmicjawsfeaturecrew@service.microsoft.com</Link> and let us know!*/}
            {/*                </MessageBarBody>*/}
            {/*            </MessageBar>*/}
            {/*            <div style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "24px", paddingBottom: "24px" }}>*/}
            {/*                /!* <LeadershipMetricsCard /> *!/*/}
            {/*            </div>*/}
            {/*            <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`} style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "24px" }}>*/}
            {/*                <LineChartContainer*/}
            {/*                    title="Total Cosmic Cores"*/}
            {/*                    isLoading={false}*/}
            {/*                    series={totalCores?.map(singleService => ({*/}
            {/*                        name: singleService.name,*/}
            {/*                        data: singleService.data.map(metric => [metric.date.valueOf(), metric.metricValue])*/}
            {/*                    })) || []}*/}
            {/*                    isNotCurrency*/}
            {/*                    logProps={{*/}
            {/*                        logComponent: LogComponent.CosmicLeadershipView,*/}
            {/*                        logElement: LogElement.CosmicTotalCores,*/}
            {/*                        customProperties: {*/}
            {/*                            type: props.type,*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                    fillMissingAbnormalData*/}
            {/*                    showArea*/}
            {/*                    anomalies={[]}*/}
            {/*                />*/}
            {/*                <LineChartContainer*/}
            {/*                    title="Total Cosmic COGS"*/}
            {/*                    isLoading={false}*/}
            {/*                    series={totalCOGS?.map(singleService => ({*/}
            {/*                        name: singleService.name,*/}
            {/*                        data: singleService.data.map(metric => [metric.date.valueOf(), metric.metricValue])*/}
            {/*                    })) || []}*/}
            {/*                    logProps={{*/}
            {/*                        logComponent: LogComponent.CosmicLeadershipView,*/}
            {/*                        logElement: LogElement.CosmicTotalCOGS,*/}
            {/*                        customProperties: {*/}
            {/*                            type: props.type,*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                    fillMissingAbnormalData*/}
            {/*                    showArea*/}
            {/*                    anomalies={[]}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`} style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "24px" }}>*/}
            {/*                <LineChartContainer*/}
            {/*                        title="Platform Overhead with Rightsizing"*/}
            {/*                        isLoading={false}*/}
            {/*                        isNotCurrency*/}
            {/*                        series={cosmicPlatformOverheadWithRightsizing?.map(singleService => ({*/}
            {/*                            name: "Platform Overhead with Rightsizing(%)",*/}
            {/*                            data: singleService.data.map(metric => [metric.date.valueOf(), metric.metricValue * 100])*/}
            {/*                        })) || []}*/}
            {/*                        logProps={{*/}
            {/*                            logComponent: LogComponent.CosmicLeadershipView,*/}
            {/*                            logElement: LogElement.CosmicPlatformOverheadWithRightsizing,*/}
            {/*                            customProperties: {*/}
            {/*                                type: props.type,*/}
            {/*                            }*/}
            {/*                        }}*/}
            {/*                        fillMissingAbnormalData*/}
            {/*                        suffix="%"*/}
            {/*                        // maxValue={100}*/}
            {/*                        // minValue={0}*/}
            {/*                        anomalies={[]}*/}
            {/*                    />*/}
            {/*                <LineChartContainer*/}
            {/*                    title="Rightsizing Saved App Cores"*/}
            {/*                    isLoading={false}*/}
            {/*                    isNotCurrency*/}
            {/*                    series={cosmicRightsizingSavedAppCores?.map(singleService => ({*/}
            {/*                        name: "Rightsizing Saved Cores",*/}
            {/*                        data: singleService.data.map(metric => [metric.date.valueOf(), metric.metricValue])*/}
            {/*                    })) || []}*/}
            {/*                    logProps={{*/}
            {/*                        logComponent: LogComponent.CosmicLeadershipView,*/}
            {/*                        logElement: LogElement.CosmicRightsizingSavedAppCores,*/}
            {/*                        customProperties: {*/}
            {/*                            type: props.type,*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                    fillMissingAbnormalData*/}
            {/*                    // suffix="%"*/}
            {/*                    // maxValue={100}*/}
            {/*                    // minValue={0}*/}
            {/*                    anomalies={[]}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "24px", paddingBottom: "24px" }}>*/}
            {/*                <LineChartContainer*/}
            {/*                    title="P99 App Core CPU Utliization"*/}
            {/*                    isLoading={false}*/}
            {/*                    isNotCurrency*/}
            {/*                    series={cosmicPlatformLevelP99CPUUitlization?.map(singleService => ({*/}
            {/*                        name: "P99 App Core CPU Utilization(%)",*/}
            {/*                        data: singleService.data.map(metric => [metric.date.valueOf(), metric.metricValue * 100])*/}
            {/*                    })) || []}*/}
            {/*                    logProps={{*/}
            {/*                        logComponent: LogComponent.CosmicLeadershipView,*/}
            {/*                        logElement: LogElement.CosmicPlatformLevelAppP99CPUUtliization,*/}
            {/*                        customProperties: {*/}
            {/*                            type: props.type,*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                    fillMissingAbnormalData*/}
            {/*                    suffix="%"*/}
            {/*                    // maxValue={100}*/}
            {/*                    // minValue={0}*/}
            {/*                    anomalies={[]}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            /!* <div>*/}
            {/*                <iframe title="Cosmic Jaws v2" src="https://msit.powerbi.com/reportEmbed?reportId=262f632f-f91f-43d9-8ef1-2d808eb414a4&autoAuth=true&ctid=72f988bf-86f1-41af-91ab-2d7cd011db47" frameBorder="0" allowFullScreen={true} style={{width: "100%", height: "100vh"}}></iframe>*/}
            {/*            </div> *!/*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*</div>*/}
        </div>
    );
};

export default CosmicView