import * as React from 'react';
import { StackedBarChart, IChartDataPoint, IChartProps } from '@fluentui/react-charting';
import CommonConstants from '../../../models/constants/CommonConstants';
import { currencyFormatter } from "../../../utils/currency";

interface IInsightBarChartProps {
    metricValueRecord: Record<string, number>;
}

export const InsightBarChart: React.FunctionComponent<IInsightBarChartProps> = (props) => {

    const colors: Record<string, string> = {};
    const metrics = Object.keys(props.metricValueRecord);

    for (const metric of metrics) {
        colors[metric] = CommonConstants.DefaultColors[metrics.indexOf(metric) % CommonConstants.DefaultColors.length];
    }

    const dataPoints: IChartDataPoint[] = metrics.map((metric) => ({
        legend: metric,
        data: props.metricValueRecord[metric],
        color: colors[metric],
        xAxisCalloutData: metric,
        yAxisCalloutData: currencyFormatter(props.metricValueRecord[metric]),
    }));

    
    const chartData: IChartProps = {
        chartData: dataPoints,
    };

    return <StackedBarChart data={chartData} ignoreFixStyle={true}/>;

};