import { IFilterView, IPartialFilterViewContent, ISharedView } from "../models/FilterView";
import { deleteItem, getJson, postMethod } from "../utils/apiServiceBase";

import { Flight } from "../models/Flights";
import { Moment } from "moment";

export const ViewV1BaseUrl = 'api/v1.0/views';

export async function saveSubView(viewId: number, subviewName: string, body: IPartialFilterViewContent): Promise<Response> {
    const url = `${ViewV1BaseUrl}/mysubviews?viewId=${encodeURIComponent(viewId)}&viewName=${encodeURIComponent(subviewName)}`;
    return await postMethod<IPartialFilterViewContent>(url, body);
}

export async function getAllViews(flight: Flight): Promise<IFilterView[]> {
    const url = `${ViewV1BaseUrl}/myviews`;
    const response = await getJson<IFilterView[]>(url);

    if (flight.enableSubstrateV2) {
        response.push({
            userId: "Admin",
            filterContent:
            {
                ProcessV2: ["portablerankerservice", "portablerankerserviceb2", "ahcoreb2", "applicationhost", "flowcontrol"],
                VdirV2: ["tenantsearchprocessors", "ssms", "searchinsights"],
                GriffinApplicationV2: [
                    "TenantSearchProcessors(766ef332-38e5-4cb4-920c-baa478e39fd9)",
                    "GrainSTIPolicy(b7bf0456-8c41-4efc-a2a9-d043812812f9)",
                    "Office 365 Search Service(66a88757-258c-4c72-893c-3e8bed4d6899)"],
                ScenarioTagV2: ["b7bf0456-8c41-4efc-a2a9-d043812812f9.RMP_AscShardsPropagation"],
                PredefinedViewName: ["Greenland"],
            },
            viewName: "Sample view",
            hasChild: false,
            id: 0,
            parentId: 0,
            status: 0,
        });
    } else {
        response.push({
            userId: "Admin",
            filterContent:
            {
                Process: ["portablerankerservice", "portablerankerserviceb2", "ahcoreb2", "applicationhost", "flowcontrol"],
                VDir: ["tenantsearchprocessors", "ssms", "searchinsights"],
                GriffinApplicationName: ["TenantSearchProcessors", "GrainSTIPolicy", "Office 365 Search Service"],
                ScenarioTag: ["ascshardspropagation"],
                PredefinedViewName: ["Greenland"],
            },
            viewName: "Sample view",
            hasChild: false,
            id: 0,
            parentId: 0,
            status: 0,
        });
    }

    return response;
}

export async function loadSubViewsById(viewId: number): Promise<IFilterView[]> {
    const url = `${ViewV1BaseUrl}/mysubviews?viewId=${encodeURIComponent(viewId)}`; 
    const response = await getJson<IFilterView[]>(url);
    return response;
}

export async function loadViewById(viewId: number): Promise<IFilterView> {
    const url = `${ViewV1BaseUrl}/details?viewId=${encodeURIComponent(viewId)}`;
    const response = await getJson<IFilterView>(url);
    return response;
}

export async function createView(viewName: string, body: IPartialFilterViewContent) : Promise<Response> {
    const url = `${ViewV1BaseUrl}/myviews?viewName=${encodeURIComponent(viewName)}`;
    return await postMethod<IPartialFilterViewContent>(url, body);
}

export async function editView(viewId: number, body: IPartialFilterViewContent) : Promise<Response> {
    const url = `${ViewV1BaseUrl}/edit?viewId=${encodeURIComponent(viewId)}`;
    return await postMethod<IPartialFilterViewContent>(url, body);
}

export async function deleteView(viewId: number, type: number) : Promise<Response>{
    const url = `${ViewV1BaseUrl}/delete?viewId=${encodeURIComponent(viewId)}&type=${encodeURIComponent(type)}`;
    return await deleteItem(url);
}

export async function shareView(startDate: Moment, endDate: Moment, body: IPartialFilterViewContent) : Promise<Response> {
    const url = `api/pcm/pages/sharedpages?StartDate=${startDate.format("YYYY-MM-DD")}&EndDate=${endDate.format("YYYY-MM-DD")}`;
    return await postMethod<IPartialFilterViewContent>(url, body);
}

export async function getPageDetailById(id: string) : Promise<ISharedView> {
    const url = `api/pcm/pages/sharedpages?Id=${id}`;
    return await getJson<ISharedView>(url);
}

