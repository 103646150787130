import { Callout, Checkbox, DirectionalHint, Icon, DefaultButton, Separator, Stack, SearchBox } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "../../ring/RingsFilter.less";
import filterStyles from "./ManagerFilter.less";
import { useEsManagers } from "../../../hooks/useEsManagers";
import { ESCostDataDimensions, ESManagerLevels, ESManagersResponse } from "../../../models/ESCostModels";

export interface IManagerFilterProps {
    managers: ESManagersResponse[];
}

interface ManagerItem {
    name: string;
    level: string;
    checked: boolean;
}

type ManagerCounts = {
    [key in ESManagerLevels]: number;
};

type ManagerLevelGroups = {
    [key in ESManagerLevels]?: ManagerItem[];
};

function removeRedundant(esManagers: ESManagersResponse[]): ESManagersResponse[] {
    const uniqueNameLevelSet = new Set<string>();

    esManagers.forEach((m) => {
        const nameLevelCombination = `${m[ESCostDataDimensions.Name]}_${m[ESCostDataDimensions.Level]}`;
        uniqueNameLevelSet.add(nameLevelCombination);
    });

    return Array.from(uniqueNameLevelSet).map((combination) => {
        const [name, level] = combination.split("_");
        return { [ESCostDataDimensions.Name]: name, [ESCostDataDimensions.Level]: level as ESManagerLevels };
    });
}

function getManagerCount(allManagers: ESManagersResponse[]): ManagerCounts {
    const counts = {} as ManagerCounts;

    Object.values(ESManagerLevels).forEach((level) => {
        counts[level] = 0;
    });

    allManagers.forEach((m) => {
        counts[m[ESCostDataDimensions.Level]] += 1;
    });

    return counts;
}

function checkConfirmDisabled(selectedManagers: string[], rightManagers: ManagerItem[]): boolean {
    if (selectedManagers === undefined || selectedManagers.length === 0) {
        return rightManagers === undefined || rightManagers.length === 0;
    }
    const sortedSelectedManagers = selectedManagers.slice().sort();
    const sortedRightManagers = rightManagers.map((m) => m.name).sort();
    return JSON.stringify(sortedSelectedManagers) === JSON.stringify(sortedRightManagers);
}

export const ManagerFilter: React.FC<IManagerFilterProps> = (props) => {
    const { managers } = props;
    const allManagers: ESManagersResponse[] = useMemo(() => {
        return removeRedundant(managers);
    }, [managers]);

    const levelCounts: ManagerCounts = useMemo(() => {
        return getManagerCount(allManagers);
    }, [allManagers]);

    const buttonRef = useRef<HTMLDivElement>(null);
    const [calloutVisible, { toggle: toggleCalloutVisible, setFalse: hideCallout }] = useBoolean(false);

    const [selectedManagers, setSelectedManagers] = useEsManagers();
    const getSelectedManagerItems = () => {
        return allManagers
            .filter((m) => selectedManagers.includes(m[ESCostDataDimensions.Name]))
            .map((m) => ({
                name: m[ESCostDataDimensions.Name],
                level: m[ESCostDataDimensions.Level],
                checked: true,
            }));
    };
    const [rightManagers, setRightManagers] = useState<ManagerItem[]>(getSelectedManagerItems);
    const [leftManagers, setLeftManagers] = useState<ManagerItem[]>([]);

    const [confirmDisabled, setConfirmDisabled] = useState<boolean>(true);
    useEffect(() => {
        setConfirmDisabled(checkConfirmDisabled(selectedManagers, rightManagers));
    }, [rightManagers, selectedManagers]);

    const getHeaderText = (text: string) => {
        return <h4 className={filterStyles.headerText}>{text}</h4>;
    };

    const handleClearAll = () => {
        const rightNames = rightManagers.map((m) => m.name);
        const updatedLeftData = leftManagers.map((d) => {
            if (rightNames.includes(d.name)) {
                return { ...d, checked: false };
            }
            return d;
        });
        setLeftManagers(updatedLeftData);
        setRightManagers([]);
    };

    const handleLeftCheckboxChange = (item?: ManagerItem) => {
        if (item) {
            const updatedLeftData = leftManagers.map((d) => {
                if (d.name === item.name) {
                    return { ...d, checked: !d.checked };
                }
                return d;
            });
            setLeftManagers(updatedLeftData);

            // When checking the item, the parameter of item.checked is still false
            // Thus, item should be added when it is unchecked.
            if (!item.checked) {
                // Force to set item.checked to true
                setRightManagers((prevRightData) => [...prevRightData, { ...item, checked: true }]);
            } else {
                setRightManagers((prevRightData) => prevRightData.filter((d) => d.name !== item.name));
            }
        }
    };

    const handleRightCheckboxChange = (item?: ManagerItem) => {
        if (item) {
            setRightManagers((prevRightData) => prevRightData.filter((d) => d.name !== item.name));

            const updatedLeftData = leftManagers.map((d) => {
                if (d.name === item.name) {
                    return { ...d, checked: false };
                }
                return d;
            });
            setLeftManagers(updatedLeftData);
        }
    };

    const handleConfirm = () => {
        hideCallout();
        setSelectedManagers(rightManagers.map((m) => m.name));
        onSearchTextChanged();
    };

    const getItemRenderList = (items: ManagerItem[], handleCheckboxChange: (item?: ManagerItem) => void) => {
        return items.map((item) => (
            <Checkbox
                className={filterStyles.checkbox}
                checked={item ? item.checked : false}
                key={item.name}
                label={item ? item.name : ""}
                onChange={() => handleCheckboxChange(item)}
            />
        ));
    };

    const getItemListComponent = (items: ManagerItem[], handleCheckboxChange: (item?: ManagerItem) => void, isRight: boolean) => {
        if (items.length === 0) {
            return (
                <div className={filterStyles.listWrapper}>
                    <span className={isRight ? filterStyles.rightText : filterStyles.leftText}>{isRight ? "All managers are selected." : "No result."}</span>
                </div>
            );
        } else {
            const levelGroups: ManagerLevelGroups = {};
            items.forEach((item) => {
                const level = item.level as ESManagerLevels;
                if (levelGroups[level] !== undefined) {
                    (levelGroups[level] as ManagerItem[]).push(item);
                } else {
                    levelGroups[level] = [item];
                }
            });
            return (
                <div className={filterStyles.listWrapper}>
                    {Object.entries(levelGroups).map(([level, group]) => {
                        return (
                            <Stack key={level}>
                                <span className={filterStyles.managerLevel}>{`${level}`}</span>
                                {getItemRenderList(group, handleCheckboxChange)}
                                <div style={{ marginBottom: "10px" }} />
                            </Stack>
                        );
                    })}
                </div>
            );
        }
    };

    const onSearchTextChanged = (_?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue === undefined || newValue.length === 0) {
            setLeftManagers([]);
        } else {
            const rightNames = rightManagers.map((m) => m.name);
            setLeftManagers(
                allManagers
                    .filter((m) => m[ESCostDataDimensions.Name].includes(newValue.toUpperCase()))
                    .map((m) => ({
                        name: m[ESCostDataDimensions.Name],
                        level: m[ESCostDataDimensions.Level],
                        checked: rightNames.includes(m[ESCostDataDimensions.Name]),
                    }))
            );
        }
    };

    const onCalloutDismiss = () => {
        hideCallout();
        setRightManagers(getSelectedManagerItems());
        onSearchTextChanged();
    };

    return (
        <div className={styles.ringSelector}>
            <div className={styles.ringSelectorContent} ref={buttonRef} onClick={toggleCalloutVisible}>
                <Icon iconName="People" style={{ color: "#323130" }} />
                <span className={styles.ringSelectorContentText}>
                    {selectedManagers === undefined || selectedManagers.length === 0
                        ? "All Managers"
                        : `${selectedManagers.length} Manager${selectedManagers.length > 1 ? "s" : ""}`}
                </span>
                <Icon iconName="ChevronDown" className={styles.downIcon} />
            </div>
            {calloutVisible && (
                <Callout
                    className={filterStyles.callout}
                    directionalHint={DirectionalHint.bottomRightEdge}
                    isBeakVisible={false}
                    target={buttonRef.current}
                    onDismiss={onCalloutDismiss}
                >
                    <Stack horizontalAlign="center">
                        <div className={filterStyles.header}>
                            {getHeaderText(
                                `${levelCounts[ESManagerLevels.L5]} L5 managers and ${
                                    levelCounts[ESManagerLevels.L6]
                                } L6 managers are available for the filter.`
                            )}
                        </div>
                        <Stack horizontal verticalAlign="center">
                            <SearchBox className={filterStyles.searchBox} placeholder="Search with alias" onChange={onSearchTextChanged} />
                            <DefaultButton
                                text="Clear All"
                                ariaLabel="Clear All"
                                iconProps={{ iconName: "Cancel" }}
                                onClick={handleClearAll}
                                allowDisabledFocus
                                disabled={selectedManagers.length === 0}
                            />
                        </Stack>
                        <Separator styles={{ root: { width: "100%" } }} />
                        <Stack horizontal verticalAlign="center" className={filterStyles.section}>
                            {getItemListComponent(leftManagers, handleLeftCheckboxChange, false)}
                            <Separator vertical styles={{ root: { height: "100%" } }} />
                            {getItemListComponent(rightManagers, handleRightCheckboxChange, true)}
                        </Stack>
                        <Separator styles={{ root: { width: "100%" } }} />
                        <DefaultButton
                            className={filterStyles.confirmButton}
                            text="Confirm"
                            ariaLabel="Confirm"
                            onClick={handleConfirm}
                            allowDisabledFocus
                            disabled={confirmDisabled}
                        />
                    </Stack>
                </Callout>
            )}
        </div>
    );
};
