// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IqtlBlg2vOTIwBuRq0go {\n  padding: 4px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/table/ColumnSelector.less"],"names":[],"mappings":"AACA;EACI,cAAA;AAAJ","sourcesContent":["\n.checkBox {\n    padding: 4px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkBox": "IqtlBlg2vOTIwBuRq0go"
};
export default ___CSS_LOADER_EXPORT___;
