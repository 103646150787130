// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tYMJvB19hYTRbOFWI8kH {\n  width: 580px;\n}\n.v8Hhxy44hDnHG4VXx5WV {\n  color: #005A9E;\n}\n.nhM4Gn5TplUxlBtTWboe {\n  background-color: #EDEBE9;\n}\n.nhM4Gn5TplUxlBtTWboe:hover {\n  background-color: #EDEBE9 !important;\n}\n.w0KsQUJ8Z0SN732UpmbI {\n  line-height: 20px;\n  padding: 8px;\n  height: 36px;\n  cursor: pointer;\n}\n.w0KsQUJ8Z0SN732UpmbI:hover {\n  background-color: #f3f2f1;\n}\n.LBUrNP1pRbIzSfeogm0g {\n  flex: 1;\n  padding-left: 8px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./src/components/search/SearchHistory.less"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAEA;EACI,cAAA;AAAJ;AAGA;EACI,yBAAA;AADJ;AAGI;EACI,oCAAA;AADR;AAKA;EACI,iBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;AAHJ;AAKI;EACI,yBAAA;AAHR;AAOA;EACI,OAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AALJ","sourcesContent":[".searchHistory {\n    width: 580px;\n}\n\n.logItemIcon {\n    color: #005A9E;\n}\n\n.activeLogItem {\n    background-color: #EDEBE9;\n\n    &:hover {\n        background-color: #EDEBE9 !important;\n    }\n}\n\n.logItem {\n    line-height: 20px;\n    padding: 8px;\n    height: 36px;\n    cursor: pointer;\n\n    &:hover {\n        background-color: rgb(243, 242, 241);\n    }\n}\n\n.logItemValue {\n    flex: 1;\n    padding-left: 8px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchHistory": "tYMJvB19hYTRbOFWI8kH",
	"logItemIcon": "v8Hhxy44hDnHG4VXx5WV",
	"activeLogItem": "nhM4Gn5TplUxlBtTWboe",
	"logItem": "w0KsQUJ8Z0SN732UpmbI",
	"logItemValue": "LBUrNP1pRbIzSfeogm0g"
};
export default ___CSS_LOADER_EXPORT___;
