// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TkymKtsmKuseSybzSswl [data-icon-name^=\"Chevron\"] {\n  font-size: 12px;\n  margin-left: 8px;\n}\n.LovcZX4BBvh3aRzRnoTu {\n  width: 14px;\n  height: 14px;\n  margin-right: 8px;\n}\n.jhzWOA3YxzyGTf5SaSe2 {\n  padding: 10px 16px;\n  font-size: 14px;\n  box-sizing: border-box;\n}\n.jhzWOA3YxzyGTf5SaSe2 .ms-Stack {\n  margin: 9px 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/azure/detail/HeatmapIndicator.less"],"names":[],"mappings":"AACA;EAEQ,eAAA;EACA,gBAAA;AADR;AAKA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;AAHJ;AAMA;EACI,kBAAA;EACA,eAAA;EACA,sBAAA;AAJJ;AACA;EAMQ,aAAA;AAJR","sourcesContent":["\n.heatmapIndicatorBtn {\n    :global([data-icon-name^=\"Chevron\"]) {\n        font-size: 12px;\n        margin-left: 8px;\n    }\n}\n\n.heatmapIndicatorColor {\n    width: 14px;\n    height: 14px;\n    margin-right: 8px;\n}\n\n.degreeList { \n    padding: 10px 16px;\n    font-size: 14px;\n    box-sizing: border-box;\n\n    :global(.ms-Stack) {\n        margin: 9px 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heatmapIndicatorBtn": "TkymKtsmKuseSybzSswl",
	"heatmapIndicatorColor": "LovcZX4BBvh3aRzRnoTu",
	"degreeList": "jhzWOA3YxzyGTf5SaSe2"
};
export default ___CSS_LOADER_EXPORT___;
