import { QueryableClass } from "../hooks/useCustomQuery";
import {
    ESBuildQueueFYCostRawResponse,
    ESArtifactRetentionFYCostRawResponse,
    ESManagerFYCostRawResponse,
    ESManagerMonthToMonthCostRawResponse,
    ESRepoFYArtifactCostRawResponse,
    ESAllUsageScenariosFYReturnTypeCostRawResponse,
    ESBuildRoleFYCostRawResponse,
    ESCloudBuildOverviewCapacityRawResponse,
    ESHourlyBuilderStateCountRawResponse,
    ESManagerFYReturnTypeCostRawResponse,
    ESOverviewFYBudgetRawResponse,
    ESOverviewFiscalYearValues,
    ESOverviewMonthlyCostRawResponse,
    ESMonthToMonthCostTableRawResponse,
    ESStampIdFYCostRawResponse,
    ESUsageScenarioFYCostRawResponse,
    ESUserTypeFYCostRawResponse,
    ESWeeklyBuildStageCostRawResponse,
    ESWeeklyBuilderStateCountRawResponse,
    ESArtifactOverviewFiscalYearValues,
    ESManagersResponse,
    ESTotalFYCostRawResponse,
    ESAzureDataSourceFYCostRawResponse,
    ESAzureRegionSubscriptionServiceFYCostRawResponse,
    ESAzureRegionSubscriptionMonthlyCostRawResponse,
    SubscriptionMapping,
} from "../models/ESCostModels";
import { IFilterES365CostOverview } from "../models/ESFilterView";
import { getJson, postJson } from "../utils/ESCostApiService";

const budgetAPI = "query/budget";
const capacityAPI = "query/capacity";
const countAPI = "query/count";
const costAPI = "query/cost";
const managerAPI = "metadata/manager";
const subscriptionAPI = "metadata/subscription";

@QueryableClass({ cacheTime: 1800000, retry: 3 })
export class ES365CostService {
    static async getCostOverview(filters: IFilterES365CostOverview): Promise<ESOverviewFiscalYearValues[]> {
        const url = costAPI;
        return await postJson<ESOverviewFiscalYearValues[]>(url, filters);
    }

    static async getArtifactCostOverview(filters: IFilterES365CostOverview): Promise<ESArtifactOverviewFiscalYearValues[]> {
        const url = costAPI;
        return await postJson<ESArtifactOverviewFiscalYearValues[]>(url, filters);
    }

    static async getCapacity(filters: IFilterES365CostOverview): Promise<ESCloudBuildOverviewCapacityRawResponse[]> {
        const url = capacityAPI;
        return await postJson<ESCloudBuildOverviewCapacityRawResponse[]>(url, filters);
    }

    static async getMonthlyCost(filters: IFilterES365CostOverview): Promise<ESOverviewMonthlyCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESOverviewMonthlyCostRawResponse[]>(url, filters);
    }

    static async getBudgetOverview(filters: IFilterES365CostOverview): Promise<ESOverviewFYBudgetRawResponse[]> {
        const url = budgetAPI;
        return await postJson<ESOverviewFYBudgetRawResponse[]>(url, filters);
    }

    static async getManagerFYCloudBuildCost(filters: IFilterES365CostOverview): Promise<ESManagerFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESManagerFYCostRawResponse[]>(url, filters);
    }

    static async getRepoFYArtifactCost(filters: IFilterES365CostOverview): Promise<ESRepoFYArtifactCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESRepoFYArtifactCostRawResponse[]>(url, filters);
    }

    static async getManagerFYArtifactCost(filters: IFilterES365CostOverview): Promise<ESManagerFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESManagerFYCostRawResponse[]>(url, filters);
    }

    static async getTotalFYArtifactCost(filters: IFilterES365CostOverview): Promise<ESTotalFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESTotalFYCostRawResponse[]>(url, filters);
    }

    static async getManagerFYReturnTypeCost(filters: IFilterES365CostOverview): Promise<ESManagerFYReturnTypeCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESManagerFYReturnTypeCostRawResponse[]>(url, filters);
    }

    static async getAllUsageScenariosFYReturnTypeCost(filters: IFilterES365CostOverview): Promise<ESAllUsageScenariosFYReturnTypeCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESAllUsageScenariosFYReturnTypeCostRawResponse[]>(url, filters);
    }

    static async getAllUsageScenariosFYBuildQueueCost(filters: IFilterES365CostOverview): Promise<ESBuildQueueFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESBuildQueueFYCostRawResponse[]>(url, filters);
    }

    static async getArtifactFYBuildQueueCost(filters: IFilterES365CostOverview): Promise<ESBuildQueueFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESBuildQueueFYCostRawResponse[]>(url, filters);
    }

    static async getStampIdFYCost(filters: IFilterES365CostOverview): Promise<ESStampIdFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESStampIdFYCostRawResponse[]>(url, filters);
    }

    static async getReposMonthToMonthCloudBuildCost(filters: IFilterES365CostOverview): Promise<ESMonthToMonthCostTableRawResponse[]> {
        const url = costAPI;
        return await postJson<ESMonthToMonthCostTableRawResponse[]>(url, filters);
    }

    static async getReposMonthToMonthArtifactCost(filters: IFilterES365CostOverview): Promise<ESMonthToMonthCostTableRawResponse[]> {
        const url = costAPI;
        return await postJson<ESMonthToMonthCostTableRawResponse[]>(url, filters);
    }

    static async getAzureServiceMonthToMonthCost(filters: IFilterES365CostOverview): Promise<ESMonthToMonthCostTableRawResponse[]> {
        const url = costAPI;
        return await postJson<ESMonthToMonthCostTableRawResponse[]>(url, filters);
    }

    static async getManagerMonthToMonthCloudBuildCost(filters: IFilterES365CostOverview): Promise<ESManagerMonthToMonthCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESManagerMonthToMonthCostRawResponse[]>(url, filters);
    }

    static async getManagerMonthToMonthArtifactCost(filters: IFilterES365CostOverview): Promise<ESManagerMonthToMonthCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESManagerMonthToMonthCostRawResponse[]>(url, filters);
    }

    static async getAllUserTypeFYCost(filters: IFilterES365CostOverview): Promise<ESUserTypeFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESUserTypeFYCostRawResponse[]>(url, filters);
    }

    static async getArtifactRetentionFYCost(filters: IFilterES365CostOverview): Promise<ESArtifactRetentionFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESArtifactRetentionFYCostRawResponse[]>(url, filters);
    }

    static async getAzureDataSourceFYCost(filters: IFilterES365CostOverview): Promise<ESAzureDataSourceFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESAzureDataSourceFYCostRawResponse[]>(url, filters);
    }

    static async getAzureRegionSubscriptionServiceFYCost(filters: IFilterES365CostOverview): Promise<ESAzureRegionSubscriptionServiceFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESAzureRegionSubscriptionServiceFYCostRawResponse[]>(url, filters);
    }

    static async getAllUsageScenarioFYCloudBuildCost(filters: IFilterES365CostOverview): Promise<ESUsageScenarioFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESUsageScenarioFYCostRawResponse[]>(url, filters);
    }

    static async getAllUsageScenarioFYArtifactCost(filters: IFilterES365CostOverview): Promise<ESUsageScenarioFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESUsageScenarioFYCostRawResponse[]>(url, filters);
    }

    static async getAllBuildRoleFYCloudBuildCost(filters: IFilterES365CostOverview): Promise<ESBuildRoleFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESBuildRoleFYCostRawResponse[]>(url, filters);
    }

    static async getAllBuildRoleFYArtifactCost(filters: IFilterES365CostOverview): Promise<ESBuildRoleFYCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESBuildRoleFYCostRawResponse[]>(url, filters);
    }

    static async getWeeklyBuilderStateCount(filters: IFilterES365CostOverview): Promise<ESWeeklyBuilderStateCountRawResponse[]> {
        const url = countAPI;
        return await postJson<ESWeeklyBuilderStateCountRawResponse[]>(url, filters);
    }

    static async getWeeklyBuildStageCost(filters: IFilterES365CostOverview): Promise<ESWeeklyBuildStageCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESWeeklyBuildStageCostRawResponse[]>(url, filters);
    }

    static async getAzureSubscriptionMonthlyCost(filters: IFilterES365CostOverview): Promise<ESAzureRegionSubscriptionMonthlyCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESAzureRegionSubscriptionMonthlyCostRawResponse[]>(url, filters);
    }

    static async getAzureRegionMonthlyCost(filters: IFilterES365CostOverview): Promise<ESAzureRegionSubscriptionMonthlyCostRawResponse[]> {
        const url = costAPI;
        return await postJson<ESAzureRegionSubscriptionMonthlyCostRawResponse[]>(url, filters);
    }

    static async getHourlyBuilderStateCount(filters: IFilterES365CostOverview): Promise<ESHourlyBuilderStateCountRawResponse[]> {
        const url = countAPI;
        return await postJson<ESHourlyBuilderStateCountRawResponse[]>(url, filters);
    }

    static async getManagers(): Promise<ESManagersResponse[]> {
        const url = managerAPI;
        return await getJson<ESManagersResponse[]>(url);
    }

    static async getSubscriptionMapping(): Promise<SubscriptionMapping[]> {
        const url = subscriptionAPI;
        return await getJson<SubscriptionMapping[]>(url);
    }
}
