import { Stack } from "@fluentui/react";
import React from "react";
import { OrgFilter } from "./OrgFilter";
import styles from "./OverviewFilterBar.less";
import { OverviewTimeDropdown } from "./OverviewTimeDropdown";

const ArtifactOverviewFilterBar: React.FC = () => {
    return (<>
        <Stack horizontal verticalAlign="center" horizontalAlign="end" grow={1} className={styles.outline}>
            <Stack horizontal verticalAlign="center">
                <OverviewTimeDropdown />
                <OrgFilter />
            </Stack>
        </Stack>
    </>);
}

export default ArtifactOverviewFilterBar;
