import { Callout, DirectionalHint, Icon } from '@fluentui/react';
import React, { useState } from 'react';
import { FilterForm } from './FilterForm';
import { FilterGroupOperator, FilterGroupOperatorDisplayText } from './FilterListConstants';
import styles from './FilterItem.less';
import { FilterType, IFilterItem } from './FilterList';


export interface IFilterItemProps {
    data: IFilterItem;
    displayName: string;
    type: FilterType;
    onDelete: () => void;
    onUpdate: (data: IFilterItem) => void;
    validator?: (key: string, value: string, filterType: FilterType) => string | null;
}

export const FilterItem : React.FC<IFilterItemProps> = (props: IFilterItemProps) => {
    const [editing, setEditing] = useState(false);

    const handleUpdate = (operator: FilterGroupOperator, type: FilterType, value: string) => {
        props.onUpdate(type !== FilterType.String ? { ...props.data, operator, value } : { ...props.data, operator, keyword: value });
        setEditing(false);
    };

    const keyElemRef = React.useRef<HTMLDivElement>(null);

    return (
        <div className={styles.filterItem}>
            <div className={styles.filterItemContentWrapper}>
                <div className={styles.filterItemContent} ref={keyElemRef} onClick={() => setEditing(true)}>
                    {props.displayName}: <strong>{FilterGroupOperatorDisplayText[props.data.operator]} {props.data.value || props.data.keyword}</strong>
                </div>
                <div className={styles.filterItemDeleteBtn} onClick={() => props.onDelete()}><Icon iconName="Clear" /></div>
            </div>
            { editing && (
                <Callout gapSpace={8} onDismiss={() => setEditing(false)} target={keyElemRef} isBeakVisible={false} directionalHint={DirectionalHint.bottomLeftEdge}>
                    <FilterForm
                        validator={props.validator}
                        filterKey={props.data.key}
                        filterName={props.displayName}
                        filterType={props.type}
                        initData={{ operator: props.data.operator, value: props.data.value }}
                        onCancel={() => setEditing(false)}
                        onConfirm={handleUpdate}
                    />
                </Callout>
            )}
        </div>
    )
};

