import { ITelemetryResourceCost, TelemetrySourceTypeEnum, TelemetrySourceTypeName } from "../../../models/TelemetryCogsModel";

import React from "react";
import TelemetryTrendChart from "./TelemetryTrendChart";
import commonStyles from "../../common/styles/Common.less";

interface ITelemetryChartProps {
    type: TelemetrySourceTypeEnum;
}

const TelemetryChart: React.FC<ITelemetryChartProps> = (props) => {
    return (
        <div className={`${commonStyles.gridWrapper}`}>
                {(props.type === TelemetrySourceTypeEnum.All) ?
                    (
                        <TelemetryTrendChart sources={[TelemetrySourceTypeEnum.Cosmos,
                            TelemetrySourceTypeEnum.Blueshift,
                            TelemetrySourceTypeEnum.Kusto,
                            TelemetrySourceTypeEnum.GenevaMds,
                            TelemetrySourceTypeEnum.GenevaMdm,
                            TelemetrySourceTypeEnum.Aria,
                            TelemetrySourceTypeEnum.PassiveMon,
                            TelemetrySourceTypeEnum.All,]} title={`Telemetry Overview Cost Trends`} />
                ) :
                    (<TelemetryTrendChart sources={[props.type]} title={`${TelemetrySourceTypeName[props.type]} Cost Trends`} />)
                }
        </div>
    );
};

export default TelemetryChart;
