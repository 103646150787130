import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store";
import { Callout, DirectionalHint, FontIcon, Icon, ISearchBox, Link, List, SearchBox as FluentUISearchBox, Stack, StackItem } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { IServiceTreeData } from "../../reducers/serviceTreeReducer";
import { ISearchData, searchByKey } from "../../reducers/searchReducer";
import { debounce } from "lodash";
import styles from "./SearchBox.less";
import SearchResultPanel from "./SearchResultPanel";
import { CategoryDivision, getCategoryString } from "../../models/CategoryDivision";
import { ISearchPanelData, makeTogglePanelAction } from "../../reducers/searchPanelReducer";
import { FiltersAction, FiltersView } from "../../reducers/filterReducer";
import { makeUpdateSearchBoxRefAction } from "../../reducers/actionableItemsReducer";
import { ActionTypes } from "../../actions/ActionTypes";
import { trackEventCallback } from "../../utils/AppInsights";
import { LogComponent, LogElement, LogTarget } from "../../models/LogModel";
import { editDistance } from "../../utils/editDistance";
import { escapeRegex } from "../../reducers/stringUtils";
import { useAzureClusterIdsQuery, useAzureSubscriptionsQuery } from "../../hooks/useServiceTreeQuery";
import { useCategoryFilters } from "../../hooks/useFilters";
import { ISearchResultItem } from "../../reducers/SearchUtils";
import { UserPreferenceUtils } from "../../utils/preference/UserPreferenceUtils";
import { SearchHistory } from "./SearchHistory";
import { useFlights } from "../../hooks/useSettings";
import { useGetAppIdNameMap } from "../../hooks/useSearchMetadataQuery";
import { parseScenarioTag } from "../../utils/PcmV2Utils";


interface ICategoryData {
    category: CategoryDivision;
    data?: ISearchResultItem[];
}

const SearchBox: React.FC = () => {
    const serviceTree = useSelector<IAppState, IServiceTreeData>(state => state.serviceTree);
    const {data: subscriptions} = useAzureSubscriptionsQuery();
    const {data: clusterIds} = useAzureClusterIdsQuery();
    const searchData = useSelector<IAppState, ISearchData>(state => state.search);
    const searchPanelData = useSelector<IAppState, ISearchPanelData>(state => state.searchPanel);
    const updateFilters = useCategoryFilters().updateFilters;
    const filtersNextAction = useSelector<IAppState, FiltersAction>(state => state.filtersNextAction);
    const dispatch = useDispatch();
    const [isCalloutVisible, setCalloutVisible] = React.useState(false);
    const [searchBoxValue, setSearchBoxValue] = React.useState<string>("");
    const searchBoxRef = React.useRef<ISearchBox>(null);
    const [isInputFocused, setInputFocused] = React.useState(false);
    const [isHistoryMannualDismiss, setIsHistorMannualDismiss] = React.useState(false);
    const searchBoxId = useId("search-box");
    const flights = useFlights();
    const appIdNameMap = useGetAppIdNameMap();

    React.useEffect(() => {
        dispatch(makeUpdateSearchBoxRefAction(searchBoxRef.current));
    }, [dispatch]);

    const onChangeKey = React.useMemo(() => {
        return debounce((newValue: string): void => {
            if (serviceTree.serviceTree)
                searchByKey(dispatch, newValue, serviceTree.serviceTree, serviceTree.vdirs, serviceTree.processes,
                 serviceTree.scenarioTags, serviceTree.griffinApps,
                 serviceTree.griffinProcessors, serviceTree.owners,
                 serviceTree.storeClients, serviceTree.storeClientComponents,
                 serviceTree.ssdDataSetNames, serviceTree.indexMap,
                 serviceTree.griffinAppV2,
                 serviceTree.griffinProcessorV2,
                 serviceTree.storeClientComponentV2,
                 serviceTree.storeClientV2,
                 serviceTree.scenarioTagV2,
                 serviceTree.vdirV2,
                 serviceTree.processV2,
                 serviceTree.datasetV2,
                 clusterIds, subscriptions);
        }, 500);
    }, [serviceTree.serviceTree, serviceTree.vdirs, serviceTree.processes, serviceTree.scenarioTags, serviceTree.griffinApps, serviceTree.griffinProcessors, serviceTree.owners, serviceTree.storeClients, serviceTree.storeClientComponents, serviceTree.ssdDataSetNames, serviceTree.indexMap, dispatch, clusterIds, subscriptions]);

    const dismissCallout = React.useCallback(
        (nextAction = FiltersAction.Replace): void => {
            setCalloutVisible(false);
            setSearchBoxValue("");

            if (nextAction !== filtersNextAction) {
                dispatch({ type: ActionTypes.UpdateSearchFiltersNextAction, nextAction });
            }
        },
        [filtersNextAction, dispatch]
    );

    const dismissCalloutDirectly = React.useCallback((): void => {
        dismissCallout();
        onChangeKey("");
    }, [dismissCallout, onChangeKey]);

    const openCallout = React.useCallback((): void => {
        setCalloutVisible(true);
        dispatch(makeTogglePanelAction(false));
    }, [dispatch]);

    const onChangeSearchBox = React.useCallback((event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        newValue !== undefined && setSearchBoxValue(newValue);
    }, []);

    React.useEffect(() => {
        if (isCalloutVisible) {
            onChangeKey(searchBoxValue);
        }
    }, [isCalloutVisible, onChangeKey, searchBoxValue]);

    const categories: ICategoryData[] = React.useMemo(() => {
        const categoriesResult: ICategoryData[] = (searchData.serviceTreeSearchResult || [])
            .map((categoryData) => ({
                category: categoryData[0] as CategoryDivision,
                data: categoryData[1].map((node) => ({ id: node.id, title: node.n, type: categoryData[0] as CategoryDivision })),
            }))
            .concat(
                (searchData.tagSearchResultDescription || []).map((description) => ({
                    category: description[0],
                    data: description[1].map((tag) => ({ id: tag.title, title: tag.title, type: description[0] })),
                }))
            )
            .filter((item) => item.data?.length)
            .filter((item) => {
                if (flights.data?.enableSubstrateV2) {
                    return true;
                }

                return item.category != CategoryDivision.GriffinAppV2 &&
                    item.category != CategoryDivision.GriffinProcessorV2 &&
                    item.category != CategoryDivision.StoreClientComponentV2 &&
                    item.category != CategoryDivision.StoreClientV2 &&
                    item.category != CategoryDivision.ScenarioTagV2 &&
                    item.category != CategoryDivision.VdirV2 &&
                    item.category != CategoryDivision.ProcessV2 &&
                    item.category != CategoryDivision.DataSetV2;
            })

        if (searchData.searchKey?.length && searchData.searchKey?.length > 1) {
            categoriesResult.forEach((arr) => {
                arr.data?.sort((a, b) => {
                    if (searchData.searchKey) {
                        return editDistance(a.title, searchData.searchKey) - editDistance(b.title, searchData.searchKey);
                    } else {
                        return 0;
                    }
                });
            });
        }

        return categoriesResult;
    }, [searchData.serviceTreeSearchResult, searchData.tagSearchResultDescription, searchData.searchKey, !!flights.data?.enableSubstrateV2]);

    const totalCount = React.useMemo(() => categories.reduce((prev, currentValue) => (currentValue.data?.length || 0) + prev, 0), [categories]);

    const addSearchLog = React.useCallback((value?: string, category?: CategoryDivision) => {
            if (!value) {
                UserPreferenceUtils.addHistoryLog(searchBoxValue);
            } else {
                UserPreferenceUtils.addHistoryLog(value, category);
            }
        },
        [searchBoxValue]
    );

    const selectItem = React.useCallback(
        (category?: CategoryDivision, item?: ISearchResultItem): void => {
            if (!category) {
                return;
            }

            if (item) {
                addSearchLog(item.title, category);
            }

            dismissCallout(filtersNextAction);

            if (item) {
                updateFilters(filtersNextAction, { filters: { [category]: [item.id] }, view: FiltersView.AddableList });
            }
        },
        [dismissCallout, filtersNextAction, updateFilters, addSearchLog]
    );

    const openSearchPanel = React.useCallback(
        (category?: CategoryDivision): void => {
            addSearchLog();
            selectItem(category);
            dispatch(makeTogglePanelAction(true, category));
            dismissCallout(filtersNextAction);

            if (category) {
                trackEventCallback(LogComponent.SearchPane, LogElement.SeeAll, "See all", LogTarget.Link, { category });
            } else {
                trackEventCallback(LogComponent.SearchPane, LogElement.ShowMoreResults, "Show More Results", LogTarget.Link);
            }
        },
        [dispatch, selectItem, addSearchLog, filtersNextAction]
    );

    const onRenderInnerListCell = React.useCallback(
        (item?: { data: ISearchResultItem; type: CategoryDivision }): JSX.Element => {
            if (!item) {
                return <></>;
            }

            const words =
                searchData.searchKey
                    ?.trim()
                    .split(/\s+/)
                    .filter((w) => w.length > 0) || [];
            const pattern = new RegExp(`(${words?.map((w) => escapeRegex(w)).join("|")})`, "ig");
            let title = item?.data.title;

            if (item.type === CategoryDivision.ScenarioTagV2 || item.type === CategoryDivision.GriffinProcessorV2) {
                const [appId, scenarioTagName] = parseScenarioTag(title);
                const appName = appIdNameMap.get(appId) || appId;
                title = `${scenarioTagName}${appName ? `(${appName})` : ""}`;
            }

            const content = title.replaceAll(pattern, '<span style="font-weight:bold;">$1</span>') || "";

            return (
                <Stack horizontal className={styles.innerListCell} onClick={() => selectItem(item?.type, item?.data)}>
                    <StackItem>
                        <FontIcon iconName={getIconByCategory(item?.type)} className={styles.itemIcon} />
                    </StackItem>
                    <StackItem className={styles.searchResultTitleWrapper}>
                        <div className={styles.searchResultTitle} dangerouslySetInnerHTML={{ __html: content }}></div>
                    </StackItem>
                </Stack>
            );
        },
        [selectItem, searchData.searchKey, appIdNameMap]
    );

    const onRenderOuterListCell = React.useCallback(
        (item?: ICategoryData): JSX.Element => {
            if (!item) return <></>;

            const length = item.data?.length;
            return (
                <div className={styles.outerListCell} data-is-focusable={true}>
                    <Stack horizontal horizontalAlign="space-between" className={styles.outerListCellHeader} style={{ marginBottom: length ? 0 : 8 }}>
                        <span style={{ fontWeight: 500 }}>{`${getCategoryString(item.category)} (${length})`}</span>
                        {length !== 0 && totalCount > 0 && (
                            <Link underline={false} onClick={() => openSearchPanel(item?.category)}>
                                See all
                            </Link>
                        )}
                    </Stack>
                    <List items={item?.data?.slice(0, 2).map((value) => ({ data: value, type: item.category }))} onRenderCell={onRenderInnerListCell} />
                </div>
            );
        },
        [onRenderInnerListCell, openSearchPanel, totalCount]
    );

    const [isHistoryHovering, setIsHistoryHovering] = React.useState(false);

    React.useEffect(() => {
        if (!isInputFocused) {
            setTimeout(() => setIsHistoryHovering(false), 500);
        } else {
            setIsHistoryHovering(true);
        }
    }, [isInputFocused]);

    const isSearchResultCalloutVisible = !serviceTree.isLoading && searchBoxValue && isCalloutVisible;
    const isSearchHistoryVisible = !serviceTree.isLoading && (isHistoryHovering || isInputFocused) && !searchBoxValue && !isSearchResultCalloutVisible && isCalloutVisible;

    // Clear search key when search result callout and panel are both closed.
    React.useEffect(() => {
        if (!isSearchResultCalloutVisible && !searchPanelData.isOpen) {
            onChangeKey("");
        }
    }, [isSearchResultCalloutVisible, searchPanelData.isOpen]);

    // Reset filter action to "replace" when applying new filters or doing nothing after clicking "Add from search".
    React.useEffect(() => {
        if (!isSearchHistoryVisible && filtersNextAction === FiltersAction.Add && !isInputFocused && !isSearchResultCalloutVisible && !searchPanelData.isOpen) {
            dismissCallout(FiltersAction.Replace);
        }
    }, [isSearchHistoryVisible, filtersNextAction, isInputFocused, isSearchResultCalloutVisible, searchPanelData.isOpen]);

    // Reset IsMannual when visibility changes.
    React.useEffect(() => {
        setIsHistorMannualDismiss(false);
    }, [isSearchHistoryVisible]);

    // If category is not null, add the history log to global filters, otherwise search metadata with value as key.
    const handleApplySearchHistory = (value: string, category?: CategoryDivision) => {
        if (!category) {
            onChangeKey(value);
            openSearchPanel();
        } else {
            UserPreferenceUtils.addHistoryLog(value, category);
            onChangeKey("");
            dismissCallout(FiltersAction.Replace);
            updateFilters(filtersNextAction, { filters: { [category]: [value] }, view: FiltersView.AddableList });
        }
    };

    return (
        <>
            <div id={searchBoxId}>
                <div id="portal-bubble-anchor-search">
                    <FluentUISearchBox
                        componentRef={searchBoxRef}
                        styles={{ root: styles.searchBox, icon: styles.icon }}
                        placeholder={
                            serviceTree.errorHappened
                                ? "Error Happened!"
                                : serviceTree.isLoading
                                ? "Loading..."
                                : "Search people, organizations, services, apps, products and subscriptions..."
                        }
                        onChange={onChangeSearchBox}
                        onFocus={() => setInputFocused(true)}
                        onBlur={() => setInputFocused(false)}
                        value={searchBoxValue}
                        onClick={openCallout}
                        onSearch={() => openSearchPanel()}
                        showIcon
                    />
                </div>
            </div>
            {isSearchHistoryVisible && !isHistoryMannualDismiss && (
                <Callout
                    target="#portal-bubble-anchor-search"
                    isBeakVisible={false}
                    gapSpace={4}
                    directionalHint={DirectionalHint.bottomCenter}
                    onMouseLeave={() => setIsHistoryHovering(false)}
                    onMouseEnter={() => setIsHistoryHovering(true)}
                >
                    {filtersNextAction === FiltersAction.Add && (
                        <Stack horizontal className={styles.addFromSearchTooltip} verticalAlign="center">
                            <Stack.Item>
                                <Icon iconName="AddFromSearch" />
                            </Stack.Item>
                            <Stack.Item>
                                <div className={styles.addFromSearchTooltipTitle}>Add from search</div>
                                <div>You can add more conditions to the current search results</div>
                            </Stack.Item>
                        </Stack>
                    )}
                    <SearchHistory onClick={handleApplySearchHistory} onEscape={() => setIsHistorMannualDismiss(true)} />
                </Callout>
            )}
            {isSearchResultCalloutVisible && (
                <Callout
                    styles={{ root: { width: 580, maxHeight: "calc(100vh - 96px)", overflowY: "auto" } }}
                    gapSpace={4}
                    directionalHint={DirectionalHint.bottomCenter}
                    target="#portal-bubble-anchor-search"
                    isBeakVisible={false}
                    onDismiss={dismissCalloutDirectly}
                >
                    {totalCount ? (
                        <>
                            <List items={categories} onRenderCell={onRenderOuterListCell} />
                            {totalCount > 1 && (
                                <Stack horizontal horizontalAlign="space-around" style={{ margin: 8 }}>
                                    <Link underline={false} onClick={() => openSearchPanel()}>
                                        Show more results ({totalCount})
                                    </Link>
                                </Stack>
                            )}
                        </>
                    ) : (
                        <div className={styles.noResult}>No results found in Jaws</div>
                    )}
                </Callout>
            )}
            {searchPanelData.isOpen && <SearchResultPanel />}
        </>
    );
};

function getIconByCategory(type?: CategoryDivision): string {
    switch (type) {
        case CategoryDivision.Owner:
            return "UserBoard";
        case CategoryDivision.Division:
        case CategoryDivision.Organization:
            return "Org";
        case CategoryDivision.ServiceGroup:
            return "EngineeringGroup";
        case CategoryDivision.TeamGroup:
            return "Teamwork";
        case CategoryDivision.Service:
            return "References";
        case CategoryDivision.GriffinApp:
        case CategoryDivision.GriffinProcessor:
        case CategoryDivision.StoreClient:
        case CategoryDivision.StoreClientComponent:
        case CategoryDivision.SSDDataSetName:
        case CategoryDivision.VDir:
        case CategoryDivision.Process:
            return "ServerProcesses";
        case CategoryDivision.ScenarioTag:
            return "Tag";
        default:
            return "Home";
    }
}

export default SearchBox;
