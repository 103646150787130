import { PcmV2SceneTypeEnum, PcmV2SubSceneTypeEnum } from "../../../models/PcmV2Models";

import React from "react";
import { SubstrateCategoryCostTrends } from "../common/SubstrateCategoryCostTrends";
import { SubstrateCostRanking } from "../common/SubstrateCostRanking";
import { SubstrateCostTable } from "../common/table/SubstrateCostTable";
import { SubstrateTopFiveTrends } from "../common/SubstrateTopFiveTrends";
import { SubstrateTotalCostTrends } from "../common/SubstrateTotalCostTrends";
import commonStyles from "../../common/styles/Common.less";

export const SubstrateNetworkByService: React.FC = () => {
    return (
        <div>
            <div className={`${commonStyles.gridWrapper} ${commonStyles.gridColumn2}`}>
                <SubstrateTotalCostTrends scene={PcmV2SceneTypeEnum.Network} />
                <SubstrateCategoryCostTrends scene={PcmV2SceneTypeEnum.Network} />
                <SubstrateTopFiveTrends scene={PcmV2SceneTypeEnum.Network} subScene={PcmV2SubSceneTypeEnum.NetworkCost} />
                <SubstrateCostRanking scene={PcmV2SceneTypeEnum.Network} subScene={PcmV2SubSceneTypeEnum.NetworkCost} />
            </div>
            <SubstrateCostTable scene={PcmV2SceneTypeEnum.Network} subScene={PcmV2SubSceneTypeEnum.NetworkCost} />
        </div>
    );
};
