// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".if4kVWiDGCyukxvrmhHF {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.iVeODV6BSTrh25fZKIpW {\n  height: 1px;\n  background-color: #323130;\n}\n.sYVUJxE2ZdaNd7HMvJl_ {\n  margin-top: 12px;\n  font-size: 14px;\n  font-weight: 600;\n}\n.Z3Huz9jw0aalaeiN5Q4I {\n  padding-top: 18px;\n  flex-grow: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/CommonContainer.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;AACJ;AAEA;EACI,WAAA;EACA,yBAAA;AAAJ;AAGA;EACI,gBAAA;EACA,eAAA;EACA,gBAAA;AADJ;AAIA;EACI,iBAAA;EACA,YAAA;AAFJ","sourcesContent":[".outline {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n}\n\n.separator {\n    height: 1px;\n    background-color: #323130;\n}\n\n.title {\n    margin-top: 12px;\n    font-size: 14px;\n    font-weight: 600;\n}\n\n.inner {\n    padding-top: 18px;\n    flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outline": "if4kVWiDGCyukxvrmhHF",
	"separator": "iVeODV6BSTrh25fZKIpW",
	"title": "sYVUJxE2ZdaNd7HMvJl_",
	"inner": "Z3Huz9jw0aalaeiN5Q4I"
};
export default ___CSS_LOADER_EXPORT___;
