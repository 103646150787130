import { UseQueryResult } from "react-query";
import { IFilterES365CostOverview } from "../models/ESFilterView";
import { useCustomQuery } from "./useCustomQuery";
import {
    ESBuildQueueFYCostRawResponse,
    ESAllUsageScenariosFYReturnTypeCostRawResponse,
    ESArtifactRetentionFYCostRawResponse,
    ESBuildRoleFYCostRawResponse,
    ESCloudBuildOverviewCapacityRawResponse,
    ESCostDataDimensions,
    ESCostRawUserType,
    ESHourlyBuilderStateCountRawResponse,
    ESManagerFYCostRawResponse,
    ESManagerFYReturnTypeCostRawResponse,
    ESManagerMonthToMonthCostRawResponse,
    ESOverviewFYBudgetRawResponse,
    ESOverviewFiscalYearValues,
    ESOverviewMonthlyCostRawResponse,
    ESRepoFYArtifactCostRawResponse,
    ESMonthToMonthCostTableRawResponse,
    ESStampIdFYCostRawResponse,
    ESUsageScenarioFYCostRawResponse,
    ESUserTypeFYCostRawResponse,
    ESWeeklyBuildStageCostRawResponse,
    ESWeeklyBuilderStateCountRawResponse,
    ESArtifactOverviewFiscalYearValues,
    ESArtifactDataType,
    ESSceneType,
    ESManagersResponse,
    ESTotalFYCostRawResponse,
    ESAzureDataSourceFYCostRawResponse,
    ESAzureRegionSubscriptionServiceFYCostRawResponse,
    ESAzureRegionSubscriptionMonthlyCostRawResponse,
    SubscriptionMapping,
} from "../models/ESCostModels";
import { ES365CostService } from "../services/ES365CostService";
import { ESGranularity } from "../models/constants/ESCostConstants";
import { ESCostUtils } from "../components/ESCost/Utilities/ESCostUtils";

export function useGetOverviewFYCost(): UseQueryResult<ESOverviewFiscalYearValues[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        // For FY23, the start data is 20220701
        // But when Granularity === FiscalYearly, BE only use YYYY as the FY number, which means FY23 should be 2023xxxx
        // This comment is universal for all APIs of FY data
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Billing,
        SelectDimensions: [ESCostDataDimensions.BillingType, ESCostDataDimensions.Organization, ESCostDataDimensions.Cost, ESCostDataDimensions.FiscalYear],
    };

    return useCustomQuery(ES365CostService.getCostOverview, requestBody);
}

export function useGetArtifactOverviewFYCost(): UseQueryResult<ESArtifactOverviewFiscalYearValues[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.TotalArtifactCost,
        SelectDimensions: [ESCostDataDimensions.Organization, ESCostDataDimensions.Cost, ESCostDataDimensions.FiscalYear],
    };

    return useCustomQuery(ES365CostService.getArtifactCostOverview, requestBody);
}

export function useGetCloudBuildCapacityOverview(): UseQueryResult<ESCloudBuildOverviewCapacityRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Capacity,
        SelectDimensions: [ESCostDataDimensions.Organization, ESCostDataDimensions.Capacity, ESCostDataDimensions.FiscalYear],
    };

    return useCustomQuery(ES365CostService.getCapacity, requestBody);
}

export function useGetOverviewMonthlyCost(): UseQueryResult<ESOverviewMonthlyCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [, end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.format("YYYYMMDD"),
        // In order to not ignore the data on last day of the fiscal year, we need to add 1 day to the end date
        EndDate: end.add(1, "day").format("YYYYMMDD"),
        Granularity: ESGranularity.Monthly,
        SceneType: ESSceneType.Billing,
        SelectDimensions: [ESCostDataDimensions.BillingType, ESCostDataDimensions.Organization, ESCostDataDimensions.Cost, ESCostDataDimensions.TimeStamp],
    };

    return useCustomQuery(ES365CostService.getMonthlyCost, requestBody);
}

export function useGetOverviewFYBudget(): UseQueryResult<ESOverviewFYBudgetRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Budget,
        SelectDimensions: [ESCostDataDimensions.BillingType, ESCostDataDimensions.Organization, ESCostDataDimensions.Budget, ESCostDataDimensions.FiscalYear],
    };

    return useCustomQuery(ES365CostService.getBudgetOverview, requestBody);
}

export function useGetManagerFYCloudBuildCost(): UseQueryResult<ESManagerFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Job,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.UserName,
            ESCostDataDimensions.UserType,
        ],
    };

    return useCustomQuery(ES365CostService.getManagerFYCloudBuildCost, requestBody);
}

export function useGetManagerFYArtifactCost(dataType?: ESArtifactDataType): UseQueryResult<ESManagerFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Artifact,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            dataType === ESArtifactDataType.Workload ? ESCostDataDimensions.ArtifactWorkload : ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.UserName,
            ESCostDataDimensions.UserType,
        ],
    };

    return useCustomQuery(ES365CostService.getManagerFYArtifactCost, requestBody);
}

export function useGetTotalFYArtifactCost(): UseQueryResult<ESTotalFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Artifact,
        SelectDimensions: [ESCostDataDimensions.Organization, ESCostDataDimensions.Cost, ESCostDataDimensions.FiscalYear, ESCostDataDimensions.Category],
    };

    return useCustomQuery(ES365CostService.getTotalFYArtifactCost, requestBody);
}

export function useGetRepoFYArtifactCost(dataType?: ESArtifactDataType): UseQueryResult<ESRepoFYArtifactCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Artifact,
        SelectDimensions: [
            dataType === ESArtifactDataType.Workload ? ESCostDataDimensions.ArtifactWorkload : ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.Repository,
        ],
    };

    return useCustomQuery(ES365CostService.getRepoFYArtifactCost, requestBody);
}

export function useGetManagerFYReturnTypeCost(): UseQueryResult<ESManagerFYReturnTypeCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Job,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.UserName,
            ESCostDataDimensions.UserType,
            ESCostDataDimensions.ReturnType,
        ],
    };

    return useCustomQuery(ES365CostService.getManagerFYReturnTypeCost, requestBody);
}

export function useGetAllUsageScenariosFYReturnTypeCost(): UseQueryResult<ESAllUsageScenariosFYReturnTypeCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Job,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.UsageScenario,
            ESCostDataDimensions.ReturnType,
        ],
    };

    return useCustomQuery(ES365CostService.getAllUsageScenariosFYReturnTypeCost, requestBody);
}

export function useGetAllUsageScenariosFYBuildQueueCost(): UseQueryResult<ESBuildQueueFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Job,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.UsageScenario,
            ESCostDataDimensions.BuildQueue,
        ],
    };

    return useCustomQuery(ES365CostService.getAllUsageScenariosFYBuildQueueCost, requestBody);
}

export function useGetArtifactFYBuildQueueCost(dataType?: ESArtifactDataType): UseQueryResult<ESBuildQueueFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Artifact,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            dataType === ESArtifactDataType.Workload ? ESCostDataDimensions.ArtifactWorkload : ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.UsageScenario,
            ESCostDataDimensions.BuildQueue,
        ],
    };

    return useCustomQuery(ES365CostService.getArtifactFYBuildQueueCost, requestBody);
}

export function useGetStampIdFYCost(): UseQueryResult<ESStampIdFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Job,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.UsageScenario,
            ESCostDataDimensions.StampID,
        ],
    };

    return useCustomQuery(ES365CostService.getStampIdFYCost, requestBody);
}

export function useGetReposMonthToMonthCloudBuildCost(): UseQueryResult<ESMonthToMonthCostTableRawResponse[], Error> {
    const requestBody: IFilterES365CostOverview = {
        Granularity: ESGranularity.Monthly,
        SceneType: ESSceneType.TopRepoMoM,
        SelectDimensions: [ESCostDataDimensions.Organization, ESCostDataDimensions.Cost, ESCostDataDimensions.TimeStamp, ESCostDataDimensions.Repository],
    };

    return useCustomQuery(ES365CostService.getReposMonthToMonthCloudBuildCost, requestBody);
}

export function useGetReposMonthToMonthArtifactCost(dataType?: ESArtifactDataType): UseQueryResult<ESMonthToMonthCostTableRawResponse[], Error> {
    const requestBody: IFilterES365CostOverview = {
        SceneType: ESSceneType.ArtifactTopRepoMoM,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            dataType === ESArtifactDataType.Workload ? ESCostDataDimensions.ArtifactWorkload : ESCostDataDimensions.Cost,
            ESCostDataDimensions.TimeStamp,
            ESCostDataDimensions.Repository,
        ],
    };

    return useCustomQuery(ES365CostService.getReposMonthToMonthArtifactCost, requestBody);
}

export function useGetAzureServiceMonthToMonthCost(): UseQueryResult<ESMonthToMonthCostTableRawResponse[], Error> {
    const [monthBeforeLastMonth, lastMonth] = ESCostUtils.getMonthToMonthDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: monthBeforeLastMonth.startOf("month").format("YYYYMMDD"),
        // In order to not ignore the data on last day of the last month, we need to add 1 day to the end date
        EndDate: lastMonth.endOf("month").add(1, "day").format("YYYYMMDD"),
        Granularity: ESGranularity.Monthly,
        SceneType: ESSceneType.Azure,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            ESCostDataDimensions.Cost,
            ESCostDataDimensions.TimeStamp,
            ESCostDataDimensions.Service,
        ],
    };

    return useCustomQuery(ES365CostService.getAzureServiceMonthToMonthCost, requestBody);
}

export function useGetManagerMonthToMonthCloudBuildCost(): UseQueryResult<ESManagerMonthToMonthCostRawResponse[], Error> {
    const [monthBeforeLastMonth, lastMonth] = ESCostUtils.getMonthToMonthDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: monthBeforeLastMonth.startOf("month").format("YYYYMMDD"),
        // In order to not ignore the data on last day of the last month, we need to add 1 day to the end date
        EndDate: lastMonth.endOf("month").add(1, "day").format("YYYYMMDD"),
        Granularity: ESGranularity.Monthly,
        SceneType: ESSceneType.Job,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            ESCostDataDimensions.Cost,
            ESCostDataDimensions.TimeStamp,
            ESCostDataDimensions.UserName,
            ESCostDataDimensions.UserType,
        ],
    };

    return useCustomQuery(ES365CostService.getManagerMonthToMonthCloudBuildCost, requestBody);
}

export function useGetManagerMonthToMonthArtifactCost(dataType?: ESArtifactDataType): UseQueryResult<ESManagerMonthToMonthCostRawResponse[], Error> {
    const [monthBeforeLastMonth, lastMonth] = ESCostUtils.getMonthToMonthDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: monthBeforeLastMonth.startOf("month").format("YYYYMMDD"),
        // In order to not ignore the data on last day of the last month, we need to add 1 day to the end date
        EndDate: lastMonth.endOf("month").add(1, "day").format("YYYYMMDD"),
        Granularity: ESGranularity.Monthly,
        SceneType: ESSceneType.Artifact,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            dataType === ESArtifactDataType.Workload ? ESCostDataDimensions.ArtifactWorkload : ESCostDataDimensions.Cost,
            ESCostDataDimensions.TimeStamp,
            ESCostDataDimensions.UserName,
            ESCostDataDimensions.UserType,
        ],
    };

    return useCustomQuery(ES365CostService.getManagerMonthToMonthArtifactCost, requestBody);
}

export function useGetAllUserTypeFYCost(): UseQueryResult<ESUserTypeFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Job,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.UserType,
            ESCostDataDimensions.UserName,
        ],
    };

    return useCustomQuery(ES365CostService.getAllUserTypeFYCost, requestBody);
}

export function useGetArtifactRetentionFYCost(dataType?: ESArtifactDataType): UseQueryResult<ESArtifactRetentionFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Retention,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            dataType === ESArtifactDataType.Workload ? ESCostDataDimensions.ArtifactWorkload : ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.Retention,
        ],
    };

    return useCustomQuery(ES365CostService.getArtifactRetentionFYCost, requestBody);
}

export function useGetAzureDataSourceFYCost(): UseQueryResult<ESAzureDataSourceFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Azure,
        SelectDimensions: [ESCostDataDimensions.Organization, ESCostDataDimensions.Cost, ESCostDataDimensions.FiscalYear, ESCostDataDimensions.DataSource],
    };

    return useCustomQuery(ES365CostService.getAzureDataSourceFYCost, requestBody);
}

export function useGetAzureRegionFYCost(): UseQueryResult<ESAzureRegionSubscriptionServiceFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Azure,
        SelectDimensions: [ESCostDataDimensions.Organization, ESCostDataDimensions.Cost, ESCostDataDimensions.FiscalYear, ESCostDataDimensions.Region],
    };

    return useCustomQuery(ES365CostService.getAzureRegionSubscriptionServiceFYCost, requestBody);
}

export function useGetAzureSubscriptionFYCost(): UseQueryResult<ESAzureRegionSubscriptionServiceFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Azure,
        SelectDimensions: [ESCostDataDimensions.Organization, ESCostDataDimensions.Cost, ESCostDataDimensions.FiscalYear, ESCostDataDimensions.SubscriptionId],
    };

    return useCustomQuery(ES365CostService.getAzureRegionSubscriptionServiceFYCost, requestBody);
}

export function useGetAzureServiceFYCost(): UseQueryResult<ESAzureRegionSubscriptionServiceFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Azure,
        SelectDimensions: [ESCostDataDimensions.Organization, ESCostDataDimensions.Cost, ESCostDataDimensions.FiscalYear, ESCostDataDimensions.Service],
    };

    return useCustomQuery(ES365CostService.getAzureRegionSubscriptionServiceFYCost, requestBody);
}

export function useGetAllUsageScenarioFYCloudBuildCost(): UseQueryResult<ESUsageScenarioFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Job,
        UserType: [ESCostRawUserType.Human, ESCostRawUserType.ES],
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.UsageScenario,
            ESCostDataDimensions.UserName,
            ESCostDataDimensions.UserType,
        ],
    };

    return useCustomQuery(ES365CostService.getAllUsageScenarioFYCloudBuildCost, requestBody);
}

export function useGetAllUsageScenarioFYArtifactCost(dataType?: ESArtifactDataType): UseQueryResult<ESUsageScenarioFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        UserType: [ESCostRawUserType.Human, ESCostRawUserType.ES],
        SceneType: ESSceneType.Artifact,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            dataType === ESArtifactDataType.Workload ? ESCostDataDimensions.ArtifactWorkload : ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.UsageScenario,
            ESCostDataDimensions.UserName,
            ESCostDataDimensions.UserType,
        ],
    };

    return useCustomQuery(ES365CostService.getAllUsageScenarioFYArtifactCost, requestBody);
}

export function useGetAllBuildRoleFYCloudBuildCost(): UseQueryResult<ESBuildRoleFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Job,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.BuildRole,
            ESCostDataDimensions.UserName,
            ESCostDataDimensions.UserType,
        ],
    };

    return useCustomQuery(ES365CostService.getAllBuildRoleFYCloudBuildCost, requestBody);
}

export function useGetAllBuildRoleFYArtifactCost(dataType?: ESArtifactDataType): UseQueryResult<ESBuildRoleFYCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.add(1, "year").format("YYYYMMDD"),
        EndDate: end.add(1, "year").format("YYYYMMDD"),
        Granularity: ESGranularity.FiscalYearly,
        SceneType: ESSceneType.Artifact,
        SelectDimensions: [
            ESCostDataDimensions.Organization,
            dataType === ESArtifactDataType.Workload ? ESCostDataDimensions.ArtifactWorkload : ESCostDataDimensions.Cost,
            ESCostDataDimensions.FiscalYear,
            ESCostDataDimensions.BuildRole,
            ESCostDataDimensions.UserName,
            ESCostDataDimensions.UserType,
        ],
    };

    return useCustomQuery(ES365CostService.getAllBuildRoleFYArtifactCost, requestBody);
}

export function useGetWeeklyBuilderStateCount(usageScenarios: string[]): UseQueryResult<ESWeeklyBuilderStateCountRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [, end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.format("YYYYMMDD"),
        EndDate: end.add(1, "day").format("YYYYMMDD"),
        Granularity: ESGranularity.Weekly,
        SceneType: ESSceneType.BuilderState,
        UsageScenario: usageScenarios,
        SelectDimensions: [ESCostDataDimensions.Organization, ESCostDataDimensions.Count, ESCostDataDimensions.TimeStamp],
    };

    return useCustomQuery(ES365CostService.getWeeklyBuilderStateCount, requestBody);
}

export function useGetWeeklyBuildStageCost(usageScenarios: string[]): UseQueryResult<ESWeeklyBuildStageCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [, end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.format("YYYYMMDD"),
        EndDate: end.add(1, "day").format("YYYYMMDD"),
        Granularity: ESGranularity.Weekly,
        SceneType: ESSceneType.BuilderStage,
        UsageScenario: usageScenarios,
        SelectDimensions: [ESCostDataDimensions.Organization, ESCostDataDimensions.Cost, ESCostDataDimensions.TimeStamp, ESCostDataDimensions.BuildStage],
    };

    return useCustomQuery(ES365CostService.getWeeklyBuildStageCost, requestBody);
}

export function useGetAzureSubscriptionMonthlyCost(): UseQueryResult<ESAzureRegionSubscriptionMonthlyCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [, end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.format("YYYYMMDD"),
        EndDate: end.add(1, "day").format("YYYYMMDD"),
        Granularity: ESGranularity.Monthly,
        SceneType: ESSceneType.Azure,
        SelectDimensions: [ESCostDataDimensions.Organization, ESCostDataDimensions.Cost, ESCostDataDimensions.TimeStamp, ESCostDataDimensions.SubscriptionId],
    };

    return useCustomQuery(ES365CostService.getAzureSubscriptionMonthlyCost, requestBody);
}

export function useGetAzureRegionMonthlyCost(): UseQueryResult<ESAzureRegionSubscriptionMonthlyCostRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [, end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.format("YYYYMMDD"),
        EndDate: end.add(1, "day").format("YYYYMMDD"),
        Granularity: ESGranularity.Monthly,
        SceneType: ESSceneType.Azure,
        SelectDimensions: [ESCostDataDimensions.Organization, ESCostDataDimensions.Cost, ESCostDataDimensions.TimeStamp, ESCostDataDimensions.Region],
    };

    return useCustomQuery(ES365CostService.getAzureSubscriptionMonthlyCost, requestBody);
}

export function useGetHourlyBuilderStateCount(usageScenarios: string[]): UseQueryResult<ESHourlyBuilderStateCountRawResponse[], Error> {
    const [start] = ESCostUtils.getLastFiscalYearDates();
    const [, end] = ESCostUtils.getCurrentFiscalYearDates();
    const requestBody: IFilterES365CostOverview = {
        StartDate: start.format("YYYYMMDD"),
        EndDate: end.add(1, "day").format("YYYYMMDD"),
        Granularity: ESGranularity.Hourly,
        SceneType: ESSceneType.BuilderState,
        UsageScenario: usageScenarios,
        SelectDimensions: [ESCostDataDimensions.Organization, ESCostDataDimensions.Count, ESCostDataDimensions.TimeStamp],
    };

    return useCustomQuery(ES365CostService.getHourlyBuilderStateCount, requestBody);
}

export function useGetManagers(): UseQueryResult<ESManagersResponse[], Error> {
    return useCustomQuery(ES365CostService.getManagers);
}

export function useGetSubscriptionMapping(): UseQueryResult<SubscriptionMapping[], Error> {
    return useCustomQuery(ES365CostService.getSubscriptionMapping);
}
