import * as React from "react";
import { Stack } from "@fluentui/react";
import { outerStackStyles, filterStackStyles } from "../ViewsCommonLayout";
import CloudBuildUsageScenarioFilterBar from "../../Filter/CloudBuildUsageScenarioFilterBar";
import CloudBuildUsageScenarioPage from "./CloudBuildUsageScenarioPage";
import {
    useGetWeeklyBuilderStateCount,
    useGetWeeklyBuildStageCost,
    useGetHourlyBuilderStateCount,
    useGetCloudBuildCapacityOverview,
} from "../../../../hooks/useES365CostQuery";
import { useEsUsageScenarios } from "../../../../hooks/useEsUsageScenarios";

export const CloudBuildUsageScenarioContainer: React.FC = () => {
    const [usageScenario] = useEsUsageScenarios();
    const { data: esWeeklyBuilderState, isLoading, isError } = useGetWeeklyBuilderStateCount(usageScenario);
    const { data: esWeeklyBuildStage, isLoading: isBuildStageLoading, isError: isBuildStageError } = useGetWeeklyBuildStageCost(usageScenario);
    const {
        data: esHourlyBuilderState,
        isLoading: isHourlyBuilderStateLoading,
        isError: isHourlyBuilderStateError,
    } = useGetHourlyBuilderStateCount(usageScenario);
    const { data: esFYCapacity, isLoading: isCapacityLoading, isError: isCapacityError } = useGetCloudBuildCapacityOverview();

    return (
        <Stack styles={outerStackStyles} disableShrink>
            <Stack horizontal styles={filterStackStyles}>
                <CloudBuildUsageScenarioFilterBar loading={isLoading || isBuildStageLoading || isHourlyBuilderStateLoading || isCapacityLoading} />
            </Stack>
            <CloudBuildUsageScenarioPage
                weeklyBuilderState={esWeeklyBuilderState}
                weeklyBuildStage={esWeeklyBuildStage}
                hourlyBuilderState={esHourlyBuilderState}
                capacity={esFYCapacity}
                weeklyBuilderStateLoading={isLoading}
                weeklyBuildStageLoading={isBuildStageLoading}
                hourlyBuilderStateLoading={isHourlyBuilderStateLoading}
                capacityLoading={isCapacityLoading}
                weeklyBuilderStateError={isError}
                weeklyBuildStageError={isBuildStageError}
                hourlyBuilderStateError={isHourlyBuilderStateError}
                capacityError={isCapacityError}
            />
        </Stack>
    );
};
