import { CosmosLogComponent, CosmosMetricEnum, CosmosMetricName, CosmosSourceTypeEnum, CosmosSourceTypeName } from "../../../models/CosmosModels";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { currencyFormatter, formatNumber } from "../../../utils/currency";

import ChartContainer from "../../chart/ChartContainer";
import CommonConstants from "../../../models/constants/CommonConstants";
import { CostCardProps } from "../../common/costCard/CostCard";
import { LineChartSeries } from "../../common/LineChart";
import React from "react";
import { useBatchGetCosmosDailyMetrics } from "../../../hooks/useCosmosQuery";
import { useTrackHovering } from "../../../hooks/useTrack";

interface ICosmosTrendChartProps {
    title: string;
    sources: CosmosSourceTypeEnum[];
    metric: CosmosMetricEnum;
}

const formatData = (value: number, metric: CosmosMetricEnum) => {
    switch (metric) {
        case CosmosMetricEnum.Token:
            return formatNumber(value / 24, 0);
        case CosmosMetricEnum.PhysicalSize:
            return `${formatNumber(value / 1024 / 1024, 0)} TB`;
        default: {
            return currencyFormatter(value, 2, "$");
        }
    }
};

const formatAxisData = (value: number, metric: CosmosMetricEnum) => {
    switch (metric) {
        case CosmosMetricEnum.Token:
            return currencyFormatter(value / 24, 0, "");
        case CosmosMetricEnum.PhysicalSize:
            return currencyFormatter(value / 1024 / 1024, 0, "");
        default: {
            return currencyFormatter(value, 2, "$");
        }
    }
};

const CosmosTrendChart: React.FC<ICosmosTrendChartProps> = ({ sources, metric, title }) => {
    const query = useBatchGetCosmosDailyMetrics(sources, metric);

    const cards: CostCardProps[] = sources.map((source, index) => ({
        title: `Total ${CosmosSourceTypeName[source]} ${CosmosMetricName[metric]}`,
        cost: (query.data && query.data[index].data.reduce((sum, val) => sum + (val.metricValue || 0), 0)) || 0,
        color: CommonConstants.DefaultColors[index],
        noPrefix: metric != CosmosMetricEnum.Costs,
        formatter: (value: number) => formatData(value, metric),
    }));

    const series: LineChartSeries[] = sources.map((source, index) => ({
        color: CommonConstants.DefaultColors[index],
        name: metric === CosmosMetricEnum.Costs ? CosmosSourceTypeName[source] : CosmosMetricName[metric],
        data: query.data && query.data[index].data.map((singleMetric) => [singleMetric.date.valueOf(), singleMetric.metricValue]),
    }));

    const trackChartHovering = useTrackHovering(LogComponent.CosmosView, LogElement.CosmosTrendsChart, title, LogTarget.Chart, {
        dataSources: sources.map((item) => CosmosSourceTypeName[item]).join(","),
        metric: CosmosMetricName[metric],
    });

    return (
        <ChartContainer
            headerProps={{ title }}
            loading={query.isLoading}
            cards={cards}
            chartType="line"
            chartProps={{
                series,
                height: 220,
                tooltipYFormatter: (value: number) => formatData(value, metric),
                yFormatter: (value: number) => formatAxisData(value, metric),
                isNotCurrency: metric != CosmosMetricEnum.Costs,
                containerProps: trackChartHovering,
            }}
            logProps={{
                logComponent: CosmosLogComponent[sources.length > 1 ? CosmosSourceTypeEnum.All : sources[0]],
                logElement: LogElement.CosmosTrendsChart,
            }}
        />
    );
};

export default CosmosTrendChart;
