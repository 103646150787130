import { PcmV2Cost, PcmV2Response, PcmV2ResponseType, PcmV2SubSceneTypeEnum, SubstrateCostV2 } from "../models/PcmV2Models";

import { DefaultPcmV2Cost } from "../models/constants/PcmV2Constants";
import { cloneDeep } from "lodash";
import moment from "moment";

function transformPcmV2ResponseToPcmV2Cost(response: PcmV2Response) {
    const result: PcmV2Cost = {
        ...DefaultPcmV2Cost,
        ...response,
        endDate: moment(response.endDate),
        startDate: moment(response.startDate),
        extractionDate: moment(response.extractionDate),
    };

    return result;
}

export function transformPcmV2Response(responseType: PcmV2ResponseType.Entity, response: PcmV2Response): PcmV2Cost;
export function transformPcmV2Response(responseType: PcmV2ResponseType.List, response: PcmV2Response[]): PcmV2Cost[];
export function transformPcmV2Response(responseType: PcmV2ResponseType.Dictionary, response: Record<string, PcmV2Response[]>): Record<string, PcmV2Cost[]>;
export function transformPcmV2Response(
    responseType: PcmV2ResponseType,
    response: PcmV2Response | PcmV2Response[] | Record<string, PcmV2Response[]>
): PcmV2Cost | PcmV2Cost[] | Record<string, PcmV2Cost[]> {
    switch (responseType) {
        case PcmV2ResponseType.Entity:
            return transformPcmV2ResponseToPcmV2Cost(response as PcmV2Response);
        case PcmV2ResponseType.List:
            return (response as PcmV2Response[]).map((item) => transformPcmV2ResponseToPcmV2Cost(item));
        case PcmV2ResponseType.Dictionary: {
            const result: Record<string, PcmV2Cost[]> = {};

            Object.entries(response as Record<string, PcmV2Response[]>).forEach(([key, value]) => {
                result[key] = value.map((item) => transformPcmV2ResponseToPcmV2Cost(item));
            });

            return result;
        }
    }
}

export function sumCosts(costs?: SubstrateCostV2[]): SubstrateCostV2 {
    const result: SubstrateCostV2 = cloneDeep(DefaultPcmV2Cost);

    result.totalCarbonEmission = 0;
    result.carbonScope2Emission = 0;
    result.carbonScope3Emission = 0;
    result.identity = costs?.[0]?.identity || result.identity;

    if (costs) {
        for (const item of costs) {
            result.cost += item.cost;
            result.hddCost += item.hddCost;
            result.hddUsage += item.hddUsage;
            result.transactionCost += item.transactionCost;
            result.itemReadCost += item.itemReadCost;
            result.itemReadOffPeakUsage += item.itemReadOffPeakUsage;
            result.itemReadPeakUsage += item.itemReadPeakUsage;
            result.itemQueryCost += item.itemQueryCost;
            result.itemQueryOffPeakUsage += item.itemQueryOffPeakUsage;
            result.itemQueryPeakUsage += item.itemQueryPeakUsage;
            result.itemWriteCost += item.itemWriteCost;
            result.itemWriteOffPeakUsage += item.itemWriteOffPeakUsage;
            result.itemWritePeakUsage += item.itemWritePeakUsage;
            result.offPeakItemQueriesCount += item.offPeakItemQueriesCount;
            result.offPeakItemReadsCount += item.offPeakItemReadsCount;
            result.offPeakItemWritesCount += item.offPeakItemWritesCount;
            result.peakHourItemQueriesCount += item.peakHourItemQueriesCount;
            result.peakHourItemReadsCount += item.peakHourItemReadsCount;
            result.peakHourItemWritesCount += item.peakHourItemWritesCount;
            result.itemReadCount += item.itemReadCount;
            result.itemWriteCount += item.itemWriteCount;
            result.itemQueryCount += item.itemQueryCount;
            result.networkCost += item.networkCost;
            result.peakLogBytes += item.peakLogBytes;
            result.peakBytesReturned += item.peakBytesReturned;
            result.offPeakLogBytes += item.peakLogBytes;
            result.peakBytesReturned += item.peakBytesReturned;
            result.logBytesCost += item.logBytesCost;
            result.bytesReturnedCost += item.bytesReturnedCost;
            result.processHostingCost += item.processHostingCost;
            result.processHostingCpuCost += item.processHostingCpuCost;
            result.processHostingMemoryCost += item.processHostingMemoryCost;
            result.processHostingUsage += item.processHostingUsage;
            result.dailyMemoryMB += item.dailyMemoryMB;
            result.dailyCpuCores += item.dailyCpuCores;
            result.ssdCost += item.ssdCost;
            result.ssdKvCacheCost += item.ssdKvCacheCost;
            result.ssdKvCacheUsage += item.ssdKvCacheUsage;
            result.ssdmcdbCost += item.ssdmcdbCost;
            result.ssdmcdbUsage += item.ssdmcdbUsage;
            result.totalCarbonEmission += item.totalCarbonEmission || 0;
            result.carbonScope2Emission += item.carbonScope2Emission || 0;
            result.carbonScope3Emission += item.carbonScope3Emission || 0;
        }
    }

    return result;
}

export function isStorageSubScene(subScene?: PcmV2SubSceneTypeEnum) {
    return (
        subScene === PcmV2SubSceneTypeEnum.HDDDatasetCost ||
        subScene === PcmV2SubSceneTypeEnum.HDDMailboxCost ||
        subScene === PcmV2SubSceneTypeEnum.SSDDatasetCost ||
        subScene === PcmV2SubSceneTypeEnum.SSDMailboxCost
    );
}

export function isStorageMailboxSubScene(subScene?: PcmV2SubSceneTypeEnum) {
    return subScene === PcmV2SubSceneTypeEnum.HDDMailboxCost || subScene === PcmV2SubSceneTypeEnum.SSDMailboxCost;
}

export function isStorageDatasetSubScene(subScene?: PcmV2SubSceneTypeEnum) {
    return subScene === PcmV2SubSceneTypeEnum.HDDDatasetCost || subScene === PcmV2SubSceneTypeEnum.SSDDatasetCost;
}

export function parseScenarioTag(value: string) {
    if (!value.includes(".")) {
        return ["", value];
    }

    const index = value.indexOf(".");

    return [value.substring(0, index).trim(), value.substring(index + 1)];
}
