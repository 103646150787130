import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ESCostBillingType, ESCostDataDimensions, ESCostOrganization, ESOverviewMonthlyCostRawResponse } from "../../../models/ESCostModels";
import { useEsCostResource } from "../../../hooks/useEsCostResource";
import { useEsOrg } from "../../../hooks/useEsOrg";
import { getColor } from "../../common/costCard/CostCardUtils";
import { currencyFormatter } from "../../../utils/currency";
import moment from "moment";
import { ESCostUtils } from "../Utilities/ESCostUtils";
import { ESCostResource, ESOrgs } from "../../../models/constants/ESCostConstants";
import ChartContainer from "../../chart/ChartContainer";

interface IMoMStackChartProps {
    data: ESOverviewMonthlyCostRawResponse[];
}

interface IStackChartDataProps {
    name: string; // BillingType
    values: number[]; // [MMM YYYY]
}

function reduceCosts(costs: ESOverviewMonthlyCostRawResponse[], months: string[], resources: string[]): IStackChartDataProps[] {
    const result = resources.map((resource) => ({ name: resource, values: Array.from({ length: months.length }, () => 0) }));

    costs.forEach((cost) => {
        const i = resources.indexOf(cost[ESCostDataDimensions.BillingType]);
        const j = months.indexOf(cost[ESCostDataDimensions.TimeStamp]);
        result[i].values[j] += cost[ESCostDataDimensions.Cost];
    });

    return result;
}

function getDataAndOptions(org: ESCostOrganization[], billingType: ESCostBillingType[], rawCost: ESOverviewMonthlyCostRawResponse[]) {
    const last2Month: string[] = ESCostUtils.getMonthToMonthDates().map((date) => moment(date).format("YYYYMMDD"));
    const costs = rawCost.map((item) => {
        return {
            ...item,
            [ESCostDataDimensions.TimeStamp]: moment(item[ESCostDataDimensions.TimeStamp]).date(1).format("YYYYMMDD")
        }
    }).filter(
        (cost) =>
            ESOrgs.includes(cost[ESCostDataDimensions.Organization]) &&
            ESCostResource.includes(cost[ESCostDataDimensions.BillingType]) &&
            (org.length === 0 || org.includes(cost[ESCostDataDimensions.Organization])) && 
            (billingType.length === 0 || billingType.includes(cost[ESCostDataDimensions.BillingType])) && 
            last2Month.includes(cost[ESCostDataDimensions.TimeStamp])
    );

    const allResources = ESCostResource;
    const resourceList =
        billingType.length === 0
            ? allResources
            : allResources.filter((o) => billingType.includes(o)).sort((a, b) => allResources.indexOf(a) - allResources.indexOf(b));

    return { data: reduceCosts(costs, last2Month, resourceList), categories: last2Month.map((month) => moment(month).format("MMM YYYY")) };
}

export function customFormat(this: Highcharts.TooltipFormatterContextObject) {
    const { point, series } = this;
    const { total } = point;
    const percentage = (point === undefined || total === undefined) ? 0 : ((point.y || 0) / total) * 100;
    return `<b>${this.x}</b><br>${series.name}: ${currencyFormatter(point.y ?? 0, 2, "$")}<br/>Total: ${currencyFormatter(total ?? 0, 2, "$")}<br/>Percentage: ${percentage.toFixed(2)}%`;
}

function getOptions(data: IStackChartDataProps[], categories: string[]) {
    return {
        chart: {
            type: "column",
            style: {
                fontFamily: "Segoe UI, sans-serif",
                fontSize: "14px",
                paddingTop: "10px",
            },
        },
        title: {
            text: undefined,
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            categories,
        },
        yAxis: {
            min: 0,
            title: {
                text: "Actual cost",
            },
            stackLabels: {
                enabled: false,
            },
        },
        legend: {
            align: "right",
            x: 0,
            verticalAlign: "top",
            y: -10,
            floating: true,
            backgroundColor: "white",
            borderColor: "#CCC",
            borderWidth: 1,
            shadow: true,
        },
        tooltip: {
            formatter: customFormat,
        },
        plotOptions: {
            column: {
                stacking: "normal",
                dataLabels: {
                    enabled: false,
                },
            },
        },
        series: data.map(({ name, values }, i) => ({
            name,
            data: values,
            color: getColor(i),
        })),
    };
}

const Last2MonthComparisonChart = (props: IMoMStackChartProps) => {
    const { data: rawCost } = props;
    const [org] = useEsOrg();
    const [billingType] = useEsCostResource();
    const { data, categories } = React.useMemo(() => getDataAndOptions(org, billingType, rawCost), [billingType, org, rawCost]);
    return (
        <div>
            <ChartContainer headerProps={{ title: "MoM (Last 2 months)" }}>
                <HighchartsReact highcharts={Highcharts} options={getOptions(data, categories)} />
            </ChartContainer>
        </div>
    );
};

export default Last2MonthComparisonChart;
