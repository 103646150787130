import { ActionButton, DirectionalHint, ITooltipProps, Icon, TooltipHost } from "@fluentui/react";
import { EventType, LogComponent, LogElement, LogTarget } from "../../../models/LogModel";
import { FiltersAction, FiltersView } from "../../../reducers/filterReducer";
import { IDateRangeFilterData, IFilterView } from "../../../models/FilterView";
import { ISubViewPanelData, makeTogglePanelAction } from "../../../reducers/subviewPanelReducer";
import { ISubViewsData, getAllSubViewsUnderSelectedView } from "../../../reducers/subviewReducer";
import React, { useCallback, useEffect, useState } from "react";
import { ViewType, makeToggleDeleteDialogAction } from "../../../reducers/dialogsReducer";
import { makeToggleFilterViewExpandedAction, shareViewAction } from "../../../reducers/savedViewReducer";
import { useDispatch, useSelector } from "react-redux";

import { IAppState } from "../../../store";
import SubViewPanel from "../SubViewPanel";
import { makeGlobalFiltersFromView } from "../../../utils/FiltersUtils";
import styles from "./SavedViewsItem.less";
import { trackEventCallback } from "../../../utils/AppInsights";
import { useCategoryFilters } from "../../../hooks/useFilters";
import { useDateRange } from "../../../hooks/useDateSelector";
import { useId } from "@fluentui/react-hooks";
import { values } from "lodash";

interface ISavedViewsItemProps {
    view: IFilterView;
}

const SavedViewsItem: React.FC<ISavedViewsItemProps> = (props) => {
    const dispatch = useDispatch();
    const subviewPanelData = useSelector<IAppState, ISubViewPanelData>(state => state.subviewPanel);
    const subviewsData = useSelector<IAppState, ISubViewsData>(state => state.subViewsData);
    const dateRangeFilterData = useDateRange();
    const updateFilters = useCategoryFilters().updateFilters;
    const [expanded, setExpanded] = useState(!!props.view.isOpen);
    const [childViews, setChildViews] = useState<IFilterView[]>([]); 
    
    useEffect(() => {
        setChildViews(values(subviewsData.subviews).filter((view) => (view.parentId == props.view.id)));
    }, [props.view.id, subviewsData]);

    const onHandleShowFilters = useCallback((view) => {
        updateFilters(FiltersAction.Replace, {filters: makeGlobalFiltersFromView(view), view: FiltersView.AddableList});
        trackEventCallback(LogComponent.PivotTreePane, LogElement.SavedView, "Saved View List", LogTarget.Button, {viewId: view.id}, EventType.Click);
    }, [updateFilters])
    
    const toggleExpanded = useCallback(() => {
        if (!expanded && childViews.length === 0) {
            dispatch(getAllSubViewsUnderSelectedView(props.view.id));
        }
        
        dispatch(makeToggleFilterViewExpandedAction(!expanded, props.view));
        setExpanded(prev => !prev);
    }, [childViews.length, dispatch, expanded, props.view]);


    return (
        <div>
            <div className={expanded ? styles.expandedHeader : styles.header} onClick={() => onHandleShowFilters(props.view)}>
                {props.view.hasChild ? <Icon iconName={expanded ? "ChevronDown" : "ChevronRight"} onClick={toggleExpanded} className={styles.expandedIcon} /> : <Icon iconName={"AnalyticsView"} className={styles.noExpandedIcon}/>}
                <span className={styles.headerText}>{props.view.viewName}</span>
                {
                    (!props.view.filterContent.PredefinedViewName || props.view.filterContent.PredefinedViewName.length ===0) && <ViewTooltipHost
                        view={props.view}
                        viewType={ViewType.View}
                        dateRangeFilterData={dateRangeFilterData}
                    />
                }
            </div>
            {
                expanded && props.view.hasChild &&
                <ul className={styles.list}>
                    {
                        (childViews || []).map(view => (
                            <li key={view.id} className={styles.listItem} onClick={() => onHandleShowFilters(view)}>{view.viewName}
                                <ViewTooltipHost
                                    view={view}
                                    viewType={ViewType.SubView}
                                    dateRangeFilterData={dateRangeFilterData}
                                />
                            </li>
                        ))
                    }
                </ul>
            }
            { subviewPanelData.isOpen && subviewPanelData.viewId === props.view.id && <SubViewPanel view={props.view} />}
        </div>
    );
}

interface ViewTooltipHostProps {
    view: IFilterView;
    viewType: ViewType;
    dateRangeFilterData: IDateRangeFilterData;
}

const ViewTooltipHost : React.FC<ViewTooltipHostProps> = ({view, viewType, dateRangeFilterData}) => {
    const dispatch = useDispatch();
    const tooltipId = useId('tooltip');

    const openSubviewPanel = useCallback((): void => {
        dispatch(makeTogglePanelAction(true, view.id));
        trackEventCallback(LogComponent.PivotTreePane, LogElement.AddSubView, "Add Sub-view", LogTarget.Button, {viewId: view.id});
    }, [dispatch, view.id]);

    const onHandleShareView = useCallback(() => {
        dispatch(shareViewAction(dateRangeFilterData, view.filterContent, view.viewName));
        trackEventCallback(LogComponent.PivotTreePane, LogElement.ShareSavedView, "Share", LogTarget.Button, {viewName: view.viewName});
    }, [dateRangeFilterData, dispatch, view.filterContent, view.viewName])

    const onHandleDeleteView = useCallback(() => {
        dispatch(makeToggleDeleteDialogAction(true, view.viewName, view.id, viewType));
        trackEventCallback(LogComponent.PivotTreePane, LogElement.DeleteSavedView, "Delete", LogTarget.Button, {viewId: view.id})
    }, [dispatch, view.id, view.viewName, viewType]);

    const tooltipProps: ITooltipProps = {
        onRenderContent: () => (
            <div className={styles.tooltip}>
                {viewType === ViewType.View && <ActionButton iconProps={{iconName: "Add"}} onClick={openSubviewPanel}>Add Sub-view</ActionButton>}
                <ActionButton iconProps={{iconName: "Share"}} onClick={onHandleShareView}>Share</ActionButton>
                <ActionButton iconProps={{iconName: "Delete"}} onClick={onHandleDeleteView}>Delete</ActionButton>
            </div>
        )
    };

    return (
        <TooltipHost
            tooltipProps={tooltipProps}
            calloutProps={{directionalHint: DirectionalHint.rightTopEdge, isBeakVisible: false}}
            styles={{root: styles.moreIcon}}
            id={tooltipId}
        >
            <Icon className={styles.rightArrowIcon} aria-describedby={tooltipId} iconName="ChevronRight" />
        </TooltipHost>    
    );
}

export default SavedViewsItem;