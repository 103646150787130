// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f9PdsTZ9_RGlLqs5bAYi {\n  padding: 0 48px;\n  margin-top: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/substrateV2/network/SubstrateNetwork.less"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,gBAAA;AAAJ","sourcesContent":["\n.pivotList {\n    padding: 0 48px;\n    margin-top: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pivotList": "f9PdsTZ9_RGlLqs5bAYi"
};
export default ___CSS_LOADER_EXPORT___;
