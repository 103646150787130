/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable prefer-const */
import * as React from "react";
import Highcharts from "highcharts";
import { ISubstrateMonthOnMonthData } from "../../../models/SubstrateModels";
import { currencyFormatter } from "../../../utils/currency";
import styles from "./SubstrateMonthOverMonthChart.less";
import ChartContainer from "../ChartContainer/ChartContainer";
import { useId } from "@fluentui/react-hooks";

import { useTrackHovering } from "../../../hooks/useTrack";
import { LogComponent, LogElement, LogTarget } from "../../../models/LogModel";

interface ISubstrateMOMProps {
    title: string;
    data?: ISubstrateMonthOnMonthData;
}

const SubstrateMOMChart: React.FC<ISubstrateMOMProps> = (props) => {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const chartSeriesId = useId("substrate-mom-chart");
    const names = props.data!.map(item => item[0]);
    const last = props.data!.map(item => item[2]);
    const mbl = props.data!.map(item => item[4]);

    const trackChartHoveringProps = useTrackHovering(LogComponent.Substrate, LogElement.SubstrateMonthOverMonth, props.title, LogTarget.Chart);

    const options: Highcharts.Options = React.useMemo(() => ({
        chart: {
            style: {
                fontFamily: `"Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif`,
                fontSize: "10px",
                fontWeight: "semibold",
            }
        },
        title: {
            text: undefined,
            align: "left",
            style: {"fontSize": "14px"},
            useHTML: true,
        },
        credits: {
            enabled: false
        },
        tooltip: {
            shape: "square",
            borderWidth: 0,
            borderRadius: 0,
            backgroundColor: "white",
            padding: 12,
            useHTML: true,
            headerFormat: `<span class="${styles.tooltipHeader}">{point.key}</span>`,
            outside: true,
            pointFormatter: function() {
                return `
                    <div class="${styles.tooltipContent}">
                        <div class="${styles.tooltipBarGrey}"></div>
                        <span class="${styles.tooltipTitle}">${getShowName(1)}</span>
                        <span class="${styles.tooltipValueGrey}">${currencyFormatter(getShowData(this.index, 2) || 0)}</span>
                    </div>
                    <div class="${styles.tooltipContent}">
                        <div class="${styles.tooltipBar}"></div>
                        <span class="${styles.tooltipTitle}">${getShowName(3)}</span>
                        <span class="${styles.tooltipValue}">${currencyFormatter(getShowData(this.index, 4) || 0)}</span>
                    </div>`;
            }
        },
        legend: {
            align: "left",
            squareSymbol: true,
            symbolHeight: 12,
            symbolWidth: 12,
            symbolRadius: 0
        },
        xAxis: {
            categories: names,
            labels: {
                formatter: function() {
                    const value = String(this.value);
                    let index = value.indexOf(" ");
                    return value.substring(0, index) + "</br>" + value.substring(index + 1, value.length);
                },
                rotation: 0,
                allowOverlap: true
            }
        },
        yAxis: {
            title: {
                text: undefined
            },
            plotLines:[
            ],
            labels: {
                formatter: function() {
                    if (typeof(this.value) === "string") {
                        return this.value;
                    }
                    return currencyFormatter(this.value);
                }
            }
        },
        plotOptions: {
            column: {
                dataLabels: {
                    enabled: false,
                    inside: false
                }
            }
        },
        series: [],
    }), [props.data, chartSeriesId]);
    const colors = ["#949494", "#0099BC"];
    options.series?.push({ type: "column", data: last, color: colors[0], name: props.data![0][1]});
    options.series?.push({ type: "column", data: mbl, color: colors[1], name: props.data![0][3]});
    
    React.useEffect(() => {
        containerRef.current && Highcharts.chart(
            containerRef.current,
            options
        )
    }, [options]);

    return (
        <ChartContainer title={props.title} >
            <div ref={containerRef} className={styles.chartContainer} {...trackChartHoveringProps} />
        </ChartContainer>
    )

    function getShowName(order: number): string {
        return "" + props.data![0][order];
    }

    function getShowData(index: number, order: number): number {
        return parseFloat(props.data![index][order] + "");
    }
}

export default SubstrateMOMChart;